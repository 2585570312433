import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { activeRoleList, adminUserDetailByID, updateAdminUser } from '../../actions/AdminUserAction'
import Add from '../../component/Administration/adminUser/AddAdminUser'
import constant from '../../constant'

const AddAdminUser = () => {

    const navigate = useNavigate()
    const param = useParams()
    const [data, setData] = useState(null)
    const [roleList, setRoleList] = useState([])
    const [response, setResponse] = useState({})
    const [showMsg, setShowMsg] = useState(false)

    useEffect(() => {
        document.title = constant.title.UpdateAdminUSer
        adminUserDetailByID(param.id).then(res => {
            if (res.status) {
                setData(res.result)
            }
            else {
                setResponse(res)
                setShowMsg(true)
                setTimeout(() => {
                    setShowMsg(false)
                }, 7000)
            }

        }).catch(err => {
            alert(err)
        })
        activeRoleList().then(res => {
            if (res.status) {
                setRoleList(res.result)
            }
            else {
                setResponse(res)
                setShowMsg(true)
                setTimeout(() => {
                    setShowMsg(false)
                }, 7000)
            }
        }).catch((err) => {
            alert(err)
        })
    }, [])

    const onSubmit = (model) => {
        updateAdminUser(model).then(res => {
            if (res.status) {
                navigate(constant.component.adminuserlist.url)
            }
            else {
                setResponse(res)
                setShowMsg(true)
                setTimeout(() => {
                    setShowMsg(false)
                }, 7000)
            }
        }).catch((err) => {
            alert(err)
        })
    }
    return (
        <React.Fragment>
            {data && <Add
                roleList={roleList}
                onSubmit={onSubmit}
                response={response}
                showMsg={showMsg}
                detail={data}
            />}
        </React.Fragment>
    )
}
export default AddAdminUser;


