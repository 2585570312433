import React, { useEffect } from 'react'
import { useState } from 'react';
import { createEmployerBenefit } from '../../actions/employer';
import AddEmployeebenefit from '../../component/Employers/AddEmployeeBenefit'
import constant from '../../constant';
export default function AddEmployeeBenefit() {

    const [response, setResponse] = useState({})
    const [showMsg, setShowMsg] = useState(false)


    useEffect(() => {
        document.title = constant.title.AddEmployeeBenefits;
    }, [])


    const onSubmit = (model) => {
        createEmployerBenefit(model).then(res => {
            setResponse(res)
            setShowMsg(true)
            setTimeout(() => {
                setShowMsg(false)
            }, 7000)
        })
            .catch(err => {
                alert(err)
            })
    }

    return (
        <React.Fragment>
            <AddEmployeebenefit onSubmit={onSubmit} response={response} showMsg={showMsg} />
        </React.Fragment>
    )
}
