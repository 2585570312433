import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { blogCategorybyId, updateBlogCategory } from '../../../actions/utilitiesAction'
import Add from '../../../component/Utilities/Blog/AddCategory'
import constant from '../../../constant'
const AddBlogCategory = (props) => {
    const [response, setResponse] = React.useState({})
    const [showMsg, setShowMsg] = React.useState(false)
    const navigate = useNavigate()
    const params = useParams()
    const [data, setData] = useState(null)

    useEffect(() => {

        blogCategorybyId(params.id).then((res) => {
            if (res.status) {
                setData(res.result)
            }
            else {
                setResponse(res)
                setShowMsg(true)
                setTimeout(() => {
                    setShowMsg(false)
                }, 7000)
            }
        }).catch(err => {
            alert(err)
        })
    }, [])

    const onSubmit = (model) => {

        updateBlogCategory(model).then((res) => {
            if (res.status) {
                navigate(constant.component.blogcategorylist.url)
            } else {
                setResponse(res)
                setShowMsg(true)
                setTimeout(() => {
                    setShowMsg(false)
                }, 7000)
            }
        }).catch((err) => {
            alert(err)
        })
    }

    return (
        <React.Fragment>
            {data && <Add onSubmit={onSubmit} detail={data} response={response} showMsg={showMsg} />}
        </React.Fragment>
    )
}

export default AddBlogCategory