import React from 'react'
import List from '../../../component/Utilities/Article/ArticleList'
import { useState, useEffect } from 'react'
import constant from '../../../constant'
import { archiveArticle, archiveBlog, articleList, getActiveArticleCategory } from '../../../actions/utilitiesAction'

export default function ArticleList() {

    const [list, setList] = useState([])
    const [categoryList, setCategoryList] = useState([])
    const [count, setCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [showLoader, setShowLoader] = useState(false)

    useEffect(() => {
        articleLists(currentPage)
        getActiveArticleCategorys(currentPage)
        document.title = constant.title.ListArticle
    }, [currentPage])

    const articleLists = (page) => {
        setShowLoader(true)
        articleList({ page: page }).then((res) => {
            if (res.status) {
                setShowLoader(false)
                setList(res.result.list)
                setCount(res.result.count)
            }
        }).catch((err) => {
            alert(err)
        })
    }

    const getActiveArticleCategorys = () => {
        setShowLoader(true)
        getActiveArticleCategory().then((res) => {
            if (res.status) {
                setShowLoader(false)
                setCategoryList(res.result)
                setCount(res.result.count)
            }
        }).catch((err) => {
            alert(err)
        })
    }

    const updateStatus = (model) => {
        archiveArticle(model).then((res) => {
            if (res.status) {
                articleLists(currentPage, "")

            } else {
                alert(res.error)
            }
        }).catch((err) => {
            alert(err)
        })
    }

    return (
        <React.Fragment>
            <List
                list={list}
                setList={setList}
                categoryList={categoryList}
                //setCategoryList={setCategoryList}
                articleLists={(page => articleLists(page))}
                getActiveArticleCategorys={getActiveArticleCategorys}
                count={count}
                setPage={setCurrentPage}
                showLoader={showLoader}
                updateStatus={updateStatus}
            />
        </React.Fragment>
    )
}
