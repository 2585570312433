
import * as axios from "axios";
import { getAuthHeader } from "./../utils";

export const addRole = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/create-role`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const getAdminRoleList = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/list-role`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const activeRoleList = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/active-admin-role`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const createAdminUser = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/create-admin-user`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};


export const updateAdminUser = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/update-admin-user`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};


export const adminUserList = (page) => {
    const url = `${process.env.REACT_APP_BASE_URL}/admin-user-list?page=${page}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};
export const adminRoleById = (id) => {
    const url = `${process.env.REACT_APP_BASE_URL}/AdminRole-By-Id?ROLE_ID=${id}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}
export const updateRole = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/update-role`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}
export const archiveAdminRoleList = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/archive-admin-role`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const adminUserDetailByID = (ADMIN_ID) => {
    const url = `${process.env.REACT_APP_BASE_URL}/admin-user-by-id?ADMIN_ID=${ADMIN_ID}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};



export const updateAdminStatus = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/archive-admin-user`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};
