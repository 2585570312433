import React, {useState,useEffect} from 'react'
import Employer from '../../component/Employers/EmployerSubscription'
import { TransactionList } from '../../actions/Subscription'
import { useParams } from 'react-router-dom'
import constant from '../../constant'

export default function EmployerSubscription() {
const [Subscription, setSubscription] = useState([])
const [count, setCount] = useState(0)
const [currentPage, setCurrentPage] = useState(1)
const params = useParams()


useEffect(() => {
  getSubscription(currentPage)
document.title = constant.title.EmployerSubscription
}, [currentPage])

const getSubscription = (page) => {
   TransactionList({USER_ID: parseInt( params.id),page:page}).then((res) => {
   
    if (res.status) {
 setSubscription(res.result.list)
  
 setCount(res.result.count.total)

}
}).catch(err => {
alert(err)
})
}

  return (
    <React.Fragment>
      <Employer
        Subscription = { Subscription  }
        count = {count}
        setPage={setCurrentPage}
        getSubscription={(page => getSubscription(page))}

      />
    </React.Fragment>
  )
}


