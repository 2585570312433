import React from 'react'
import { useEffect } from 'react'
import { addgovtJobs, addgovtresult } from '../../../actions/utilitiesAction'
import constant from '../../../constant'
// import { addGovtDepartment } from '../../../actions/utilitiesAction'
import AddGovtResults from '../../../component/Utilities/GovernmentResult/AddGovtResult'

const AddGovtResult = () => {
    const [response, setResponse] = React.useState({})
    const [showMsg, setShowMsg] = React.useState(false)

    useEffect(() => {
        document.title = constant.title.AddGovtResult
    })

    const onSubmit = (model) => {
        addgovtresult(model).then((res) => {
            setResponse(res)
            setShowMsg(true)
            setTimeout(() => {
                setShowMsg(false)
            }, 2000)
        }).catch((err) => {
            alert(err)
        })
    }

    

    return (
        <React.Fragment>
            <AddGovtResults
            onSubmit={onSubmit}
            response={response} showMsg={showMsg}
            />
        </React.Fragment>
    )
}
export default AddGovtResult;