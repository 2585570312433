import React, { useEffect, useState } from 'react'
import Pagination from 'react-js-pagination'
import constant from '../../constant'
import Loader from '../common/Loader'
import { ReportIssueReply } from '../../actions/utilitiesAction'
import Swal from 'sweetalert2'
import { useLocation, useNavigate } from 'react-router-dom'
import queryString from 'query-string'

export default function ReportIssueList(props) {

    const [currentPage, setCurrentPage] = useState(1)
    const location = useLocation()
    const navigate = useNavigate()

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber)
        navigate(location.pathname + '?p=' + pageNumber)
        props.setPage(pageNumber)
    }
    const qParams = queryString.parse(location.search)


    const updateStatus = (TICKET_ID, SOLVED) => {

        // console.log(SOLVED, TICKET_ID);

        const model = {
            TICKET_ID: TICKET_ID,
            SOLVED: SOLVED === 'Y' ? 'N' : 'Y'
        }

        props.updateStatus(model)
    }

    useEffect(() => {
        document.title = constant.title.ReportIssueList
    }, [])

    const [replymessage, setreplymessage] = useState("")
    const [error, setError] = useState(false)
    const handleChange = e => {
        const { name, value } = e.target
        setreplymessage(value)

    }
    const onclose = () => {
        setreplymessage("")
        setError(false)

    }
    const onReply = (TICKET_ID) => {
        if (replymessage.length > 0) {
            ReportIssueReply({ TICKET_ID: TICKET_ID, REPLY: replymessage }).then(res => {
                if (res.status) {
                    Swal.fire({
                        icon: "success",
                        text: res.result.message,
                        timer: 2000,
                        showCancelButton: false,
                        showConfirmButton: false,
                    });
                    onclose()
                } else {
                    Swal.fire({
                        icon: "error",
                        text: res.error
                            ? res.error
                            : "Something went wrong!! Please contact system administrator.",
                        timer: 2000,
                        showCancelButton: false,
                        showConfirmButton: false,
                    });
                }
            }).catch(err => {
                alert(err)
            })
        }
        else {
            setError(true)
        }
    }



    return (
        <React.Fragment>
            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0" style={{ fontSize: "18px" }}>Report A Problem</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="#">Operations</a></li>
                                            <li className="breadcrumb-item active">Report A Problem </li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table align-middle table-nowrap table-hover">
                                                {
                                                    props.showLoader &&
                                                    <Loader />
                                                }
                                                {!props.showLoader && <thead className="table-light">
                                                    <tr>
                                                        <th scope="col" style={{ width: "70px" }}>#</th>
                                                        <th scope="col">User Information</th>
                                                        <th scope="col">Subject/ Issue Type</th>
                                                        <th scope="col">Description</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>}
                                                <tbody>
                                                    {props.list && props.list.length > 0 &&
                                                        props.list.map((item, index, list) => {
                                                            return (
                                                                <tr>
                                                                    <td>
                                                                        <div className="avatar-xs">
                                                                            <span className="avatar-title rounded-circle">
                                                                                {item?.RAISED_BY_NAME?.charAt(0)?.toUpperCase()}
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <h5 className="mb-1" style={{ fontSize: "14px", textTransform: "capitalize" }}><a href="javascript:void(0);" className="text-dark">{item.RAISED_BY_NAME}</a></h5>
                                                                        <span><i class="fa fa-envelope" style={{ color: "orange" }}></i> {item.RAISED_BY_EMAIL}</span><br />
                                                                        <i class="fa fa-mobile" style={{ color: "orange" }}></i> <span>{item.RAISED_BY_CONTACT_NUMBER}</span>

                                                                    </td>
                                                                    <td> <span style={{ color: "blue", fontSize: "16px", whiteSpace: "break-spaces" }}>{item.SUBJECT}</span><br />
                                                                        <span >{item.ISSUE_TYPE}</span>

                                                                    </td>

                                                                    <td>
                                                                        <button type="button" data-bs-toggle="modal" data-bs-target={"#MODELID" + item.TICKET_ID} class="btn btn-info waves-effect btn-label waves-light"><i class="bx bx-street-view label-icon "></i> View Details </button>


                                                                        <div className="modal fade" id={"MODELID" + item.TICKET_ID} tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                                                                                <div className="modal-content">
                                                                                    <div className="modal-header">
                                                                                        <h5 className="modal-title" id="staticBackdropLabel">{item.SUBJECT}</h5>
                                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                    </div>

                                                                                    <div className="modal-body">
                                                                                        <p>
                                                                                            <table className="table table-striped mb-0">
                                                                                                <tbody>
                                                                                                    <tr>
                                                                                                        <td><span style={{ whiteSpace: 'initial' }}> {item.DESCRIPTION} </span></td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                         <td>{item.SCREENSHOT != "" ? <img src={`${process.env.REACT_APP_BASE_URL}/get/issues-ss/${item.SCREENSHOT}`} style={{ width: "100%", height: "100%" }} alt=" " className="img-fluid" /> : "No image"} </td>
                                                                                                </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className="modal-footer">
                                                                                        <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <ul className="list-inline contact-links mb-0" style={{ fontSize: "20px" }}>
                                                                            <li className="list-inline-item px-2">

                                                                                <td>
                                                                                    {/* <button type="button" data-bs-toggle="modal" data-bs-target={"#MODELID" + item.TICKET_ID} class="btn btn-info waves-effect btn-label waves-light"><i class="bx bx-street-view label-icon "></i> View Details </button> */}

                                                                                    <a title="Click here" data-bs-toggle="modal" style={{ cursor: "pointer", color: "blue" }} data-bs-target={"#REPLYID" + item.TICKET_ID} ><i class="fa fa-reply" style={{ color: "red" }}></i></a>
                                                                                    <div className="modal fade" id={"REPLYID" + item.TICKET_ID} tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                                        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                                                                                            <div className="modal-content">
                                                                                                <div className="modal-header">
                                                                                                    <h5 className="modal-title" id="staticBackdropLabel" style={{ color: "blue" }}>{item.SUBJECT}</h5>

                                                                                                    <button type="button" className="btn-close" onClick={onclose} data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                                </div>

                                                                                                <div className="modal-body">
                                                                                                    <p>
                                                                                                        <table className="table table-striped mb-0">
                                                                                                            <tbody>

                                                                                                                <tr>
                                                                                                                    <td><p > Message: {item.DESCRIPTION}</p></td>
                                                                                                                </tr>

                                                                                                                <tr>
                                                                                                                    <td><textarea
                                                                                                                        name='replymessage'
                                                                                                                        value={replymessage}
                                                                                                                        onChange={handleChange}
                                                                                                                        class={error && replymessage.length === 0 ? "form-control is-invalid col-xs-12" : "form-control col-xs-12"}></textarea>

                                                                                                                    </td>
                                                                                                                </tr>

                                                                                                            </tbody>

                                                                                                        </table>
                                                                                                    </p>
                                                                                                </div>
                                                                                                <div className="modal-footer">
                                                                                                    <button type="button" className="btn btn-light"
                                                                                                        onClick={() => onReply(item.TICKET_ID)}
                                                                                                        style={{ backgroundColor: "red", color: "#fff", borderColor: "black" }}>Reply</button>
                                                                                                    <button type="button" onClick={onclose} className="btn btn-light" data-bs-dismiss="modal">Close</button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>

                                                                                <a onClick={() => { updateStatus(item.TICKET_ID, item.SOLVED) }} style={{ color: "#f38b08", fontSize: "15px", fontWeight: "500", cursor: "pointer" }} title={item.SOLVED == 'Y' ? "Solved" : "Pending"}>
                                                                                    {
                                                                                        item.SOLVED == 'Y' ? <span style={{ color: "green" }}>Solved</span> : <span style={{ color: "#f38b08" }}>Pending</span>
                                                                                    }
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        <>
                                                            {
                                                                props.list && props.list.length == 0 && props.showLoader == false &&
                                                                <td colSpan={10} className="text-center text-danger pt-3">No Record Found</td>
                                                            }
                                                        </>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <ul className="pagination pagination-rounded justify-content-center mt-4">
                                                    <Pagination
                                                        activePage={qParams?.p ? +qParams?.p : currentPage}
                                                        itemsCountPerPage={10}
                                                        totalItemsCount={props.count}
                                                        pageRangeDisplayed={5}
                                                        onChange={handlePageChange}
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                    />
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
