import React from 'react'
import { useEffect, useState } from 'react'
import { deletePersonalRecruiterEnquiry, recruiterEnquiryList } from '../../actions/utilitiesAction'
import List from '../../component/Enquiry/RecruiterEnquiryList'
import constant from '../../constant'
import Swal from 'sweetalert2'


export default function EnquiryList() {

    const [list, setList] = useState([])
    const [count, setCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [showLoader, setShowLoader] = useState(false)

    useEffect(() => {
        EnquiryLists(currentPage)
        document.title = constant.title.EnquiryList
    }, [currentPage])

    const EnquiryLists = (page) => {
        setShowLoader(true)
        recruiterEnquiryList({ page: page }).then((res) => {
            
            if (res.status) {
                setShowLoader(false)
                setList(res.result.list)
                setCount(res.result.count)
            }
            else {
                alert(res.error)
            }
        }).catch((err) => {
            alert(err)
        })
    }

    const deleteRecruiterEnquiryList = (ENQUIRY_ID) => {
        deletePersonalRecruiterEnquiry({ ENQUIRY_ID: ENQUIRY_ID }).then((res) => {
            
            if (res.status) {
                
                Swal.fire(
                    'Deleted!',
                    'Your enquiry has been deleted.',
                    'success'
                )
                EnquiryLists(currentPage)
            }
            else {
                Swal.fire(
                    (res.result.error)
                )
            }
        })
    }


    return (
        <React.Fragment>
            <List
                list={list}
                setList={setList}
                EnquiryLists={(page => EnquiryLists(page))}
                count={count}
                setPage={setCurrentPage}
                showLoader={showLoader}
                deleteRecruiterEnquiryList={deleteRecruiterEnquiryList}
            />
        </React.Fragment>
    )
}
