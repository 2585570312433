import React, { useEffect } from 'react'
import Add from '../../../component/Utilities/CountryCityAndState/AddCity'
import { addCity, getCountryList, getStateLists } from '../../../actions/utilitiesAction'
import { useState } from 'react'
import constant from '../../../constant'
import { useParams } from 'react-router-dom'

const AddCity = () => {

    const [response, setResponse] = React.useState({})
    const [showMsg, setShowMsg] = React.useState(false)
    const [list, setList] = useState([])



    const onSubmit = (model) => {
        addCity(model).then((res) => {
            setResponse(res)
            setShowMsg(true)
            setTimeout(() => {
                setShowMsg(false)
            }, 2000)
        }).catch((err) => {
            alert(err)
        })
    }

    useEffect(() => {
        //  getStateList()
        document.title = constant.title.addCities
        getCountryList().then((res) => {
            setList(res.result.list)
        }).catch((err) => {
            alert(err)
        })
    }, [])

    // const getStateList = (COUNTRY_ID) => {
    //     getStateLists(COUNTRY_ID).then((res) => {
    //         if (res.status) {
    //             setState(res.result.list)
    //         }
    //     }).catch((err) => {
    //         alert(err)
    //     })
    // }

    return (
        <React.Fragment>
            <Add
                onSubmit={onSubmit}
                response={response}
                showMsg={showMsg}
                list={list}
            />
        </React.Fragment>
    )
}

export default AddCity
