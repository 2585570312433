import React, { useState } from 'react'
import LocalityList from '../../../component/Utilities/CountryCityAndState/Localities'
import constant from '../../../constant'
import { useEffect } from 'react'
import { getLoacalityList } from '../../../actions/utilitiesAction'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

export default function Localities() {


    const [List, setList] = useState([])
    const [count, setCount] = useState(0)
    const [currentPage, setCurrentPage] = useState([1])
    const location = useLocation()

    useEffect(() => {
        const qParams = queryString.parse(location.search)
        getLoacalityLists(qParams?.p ? qParams?.p : currentPage)
        document.title = constant.title.Localities
    }, [currentPage])


    const getLoacalityLists = (page) => {

        getLoacalityList(page).then((res) => {
            // console.log(res, 'ssfd');
            if (res.status) {
                setList(res.result.list)
                setCount(res.result.count.total)
            }
        }).catch((err) => {
            alert(err)
        })
    }


    return (
        <React.Fragment>
            <LocalityList
                List={List}
                setList={setList}
                getLoacalityLists={(page) => { getLoacalityLists(page) }}
                count={count}
                setPage={setCurrentPage}


            />
        </React.Fragment>
    )
}
