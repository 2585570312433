import React from 'react'
import List from '../../component/SubscriptionManagement/SubscriptionPackageList'
import { getSubscriptionList, archiveSubsCriptionPackage } from '../../actions/Subscription'
import { useState, useEffect } from 'react'
import constant from '../../constant'


const SubscriptionPackageList = () => {


    const [list, setList] = useState([])
    const [count, setCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [keyword, setKeyword] = useState('')

    useEffect(() => {
        subscriptionlists(currentPage)
        document.title = constant.title.subscriptionList
    }, [currentPage])

    const subscriptionlists = (page) => {
        getSubscriptionList({ page: page }).then((res) => {
            setList(res.result.list)
            setCount(res.result.count)
        }).catch((err) => {
            alert(err)
        })
    }

    const updateStatus = (model) => {

        archiveSubsCriptionPackage(model).then((res) => {
            if (res.status) {
                subscriptionlists(currentPage)
            }
            else {
                alert(res.error)
            }
        }).catch((err) => {
            alert(err)
        })
    }
    return (
        <React.Fragment>
            <List
                List={list}
                setList={setList}
                subscriptionlists={(page) => { subscriptionlists(page) }}
                updateStatus={updateStatus}
                count={count}
                setPage={setCurrentPage} />
        </React.Fragment>
    )
}

export default SubscriptionPackageList
