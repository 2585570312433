import React, { useEffect } from 'react'
import { addSalary } from '../../../actions/utilitiesAction'
import Add from '../../../component/Utilities/Salary/AddSalary'
import constant from '../../../constant'
const AddSalary = () => {
    const [response, setResponse] = React.useState({})
    const [showMsg, setShowMsg] = React.useState(false)

    const onSubmit = (model) => {
        addSalary(model).then((res) => {
            // if (res.status)
            //     alert(res.messageCode)
            setResponse(res)
            setShowMsg(true)
            setTimeout(() => {
                setShowMsg(false)
            }, 7000)
        }).catch((err) => {
            alert(err)
        })
    }
    useEffect(() => {
        document.title = constant.title.AddSalary
    }, [])
    return (
        <React.Fragment>
            <Add onSubmit={onSubmit} response={response} showMsg={showMsg} />
        </React.Fragment>
    )
}
export default AddSalary;