import React, { useEffect, useState } from 'react'
import NumberFormat from 'react-number-format'
import { cityList, stateList } from '../../../actions/utilitiesAction'
import { uploadCollegeLogo } from '../../../actions/ProfileAction'
import { validateFunctionalForm } from '../../../utils'

const EditCollegeDetail = ({ details, onSubmit }) => {
    const [state, setState] = useState({
        COLLEGE_NAME: { name: 'COLLEGE_NAME', value: details ? details.COLLEGE_NAME : '', isRequired: true, error: '' },
        COLLEGE_EMAIL: { name: 'COLLEGE_EMAIL', value: details ? details.EMAIL : '', isRequired: true, error: '' },
        COLLEGE_PHONE: { name: 'COLLEGE_PHONE', value: details ? details.PHONE : '', isRequired: true, error: '' },
        COLLEGE_PIN_CODE: { name: 'COLLEGE_PIN_CODE', value: details ? details.PIN_CODE : '', isRequired: true, error: '' },
        COLLEGE_ADDRESS: { name: 'COLLEGE_ADDRESS', value: details ? details.ADDRESS : '', isRequired: true, error: '' },
        COLLEGE_CITY: { name: 'COLLEGE_CITY', value: details ? details.CITY_ID : '', options: [], isRequired: true, error: '' },
        COLLEGE_STATE: { name: 'COLLEGE_STATE', value: details ? details.STATE_ID : '', options: [], isRequired: true, error: '' },
        SPOC_NAME: { name: 'SPOC_NAME', value: details ? details.SPOC_NAME : '', isRequired: true, error: '' },
        SPOC_EMAIL: { name: 'SPOC_EMAIL', value: details ? details.SPOC_EMAIL : '', isRequired: true, error: '' },
        SPOC_PHONE: { name: 'SPOC_PHONE', value: details ? details.SPOC_PHONE : '', isRequired: true, error: '' },
        LOGO: { name: 'LOGO', value: details ? details.LOGO : '', isRequired: true, error: '' },
        COLLEGE_CODE: { name: 'COLLEGE_CODE', value: details ? details.COLLEGE_CODE : '', isRequired: true, error: '' },
        COLLEGE_TYPE: { name: 'COLLEGE_TYPE', value: details ? details.COLLEGE_TYPE : '', isRequired: true, error: '' },
        WEBSITE: { name: 'WEBSITE', value: details ? details.WEBSITE : '', isRequired: true, error: '' },

    })

    const onUploadCollegeLogo = (e) => {
        debugger
        const formData = new FormData()
        formData.append('file', e.target.files[0])
        uploadCollegeLogo(formData).then((res) => {
            if (res.status) {
                setState(prevState => ({
                    ...prevState,
                    ['LOGO']: {
                        ...prevState['LOGO'],
                        value: res.result.fileName
                    }
                }));
            }
        })
    }

    useEffect(() => {
        stateList('102').then(res => {
            setOptions('COLLEGE_STATE', res.result.list)
        }).catch(err => {
            alert(err)
        })
    }, [])

    useEffect(() => {
        if (state.COLLEGE_STATE.value) {
            cityList(state.COLLEGE_STATE.value).then(res => {
                setOptions('COLLEGE_CITY', res.result.list)
            }).catch(err => {
                alert(err)
            })
        }
    }, [state.COLLEGE_STATE.value])

    const onChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value: value
            }
        }));


    };

    const setOptions = (name, options) => {
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                options: options
            }
        }));
    };
    const onsubmit = () => {

        if (validateFunctionalForm(state, setState)) {
            const model = {
                COLLEGE_NAME: state.COLLEGE_NAME.value,
                EMAIL: state.COLLEGE_EMAIL.value,
                PHONE: state.COLLEGE_PHONE.value,
                PIN_CODE: state.COLLEGE_PIN_CODE.value,
                ADDRESS: state.COLLEGE_ADDRESS.value,
                CITY: state.COLLEGE_CITY.value,
                STATE: state.COLLEGE_STATE.value,
                SPOC_NAME: state.SPOC_NAME.value,
                SPOC_EMAIL: state.SPOC_EMAIL.value,
                SPOC_PHONE: state.SPOC_PHONE.value,
                LOGO: state.LOGO.value,
                COLLEGE_TYPE: state.COLLEGE_TYPE.value,
                WEBSITE: state.WEBSITE.value,
                COLLEGE_CODE: state.COLLEGE_CODE.value
            }
            onSubmit(model)
        }
    }


    return (
        <React.Fragment>
            <div className='main-content'>
                <div className='page-content'>
                    <div className='container-fluid'>
                        <div class="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Edit University</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="javascript: void(0);">Campus</a></li>
                                            <li className="breadcrumb-item active">Edit College</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div id="basic-example">
                                            <section>

                                                <h4 className="card-title mb-4" style={{ marginTop: "16px", color: "#f46d6d" }}>COLLEGE INFORMATION </h4>
                                                <div className="row">
                                                    <div className="col-lg-3">
                                                        <div className="mb-3">
                                                            <label for="basicpill-firstname-input">College Name <span className='text-danger'>*</span></label>
                                                            <input
                                                                type="text"
                                                                className={!state.COLLEGE_NAME.error.length ? "form-control" : "form-control is-invalid"}
                                                                name={state.COLLEGE_NAME.name}
                                                                value={state.COLLEGE_NAME.value}
                                                                onChange={(e) => { onChange(e) }}
                                                                placeholder="Enter College Name"
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="mb-3">
                                                            <label for="basicpill-firstname-input">College Website <span className='text-danger'>*</span></label>
                                                            <input
                                                                type="text"
                                                                className={!state.WEBSITE.error.length ? "form-control" : "form-control is-invalid"}
                                                                name={state.WEBSITE.name}
                                                                value={state.WEBSITE.value}
                                                                onChange={(e) => { onChange(e) }}
                                                                placeholder="Enter College Website"
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="mb-3">
                                                            <label for="basicpill-firstname-input">College Email <span className='text-danger'>*</span></label>
                                                            <input
                                                                type="text"
                                                                className={!state.COLLEGE_EMAIL.error.length ? "form-control" : "form-control is-invalid"}
                                                                name={state.COLLEGE_EMAIL.name}
                                                                value={state.COLLEGE_EMAIL.value}
                                                                onChange={(e) => { onChange(e) }}
                                                                placeholder="Enter Email"
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="mb-3">
                                                            <label for="basicpill-firstname-input">College Phone No<span className='text-danger'>*</span></label>
                                                            <input
                                                                type="text"
                                                                className={!state.COLLEGE_PHONE.error.length ? "form-control" : "form-control is-invalid"}
                                                                name={state.COLLEGE_PHONE.name}
                                                                value={state.COLLEGE_PHONE.value}
                                                                onChange={(e) => { onChange(e) }}

                                                                placeholder="Enter Phone No"
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="mb-3">
                                                            <label for="basicpill-address-input">SPOC Name<span className='text-danger'>*</span></label>
                                                            <input
                                                                type="text"
                                                                className={!state.SPOC_NAME.error.length ? "form-control" : "form-control is-invalid"}
                                                                name={state.SPOC_NAME.name}
                                                                value={state.SPOC_NAME.value}
                                                                onChange={(e) => { onChange(e) }}

                                                                placeholder="Enter SPOC Name"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="mb-3">
                                                            <label for="basicpill-address-input">SPOC Email<span className='text-danger'>*</span></label>
                                                            <input
                                                                type="text"
                                                                className={!state.SPOC_EMAIL.error.length ? "form-control" : "form-control is-invalid"}
                                                                name={state.SPOC_EMAIL.name}
                                                                value={state.SPOC_EMAIL.value}
                                                                onChange={(e) => { onChange(e) }}

                                                                placeholder="Enter SPOC Email"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-3">
                                                        <div className="mb-3">
                                                            <label for="basicpill-address-input">SPOC Phone<span className='text-danger'>*</span></label>
                                                            <input
                                                                type="text"
                                                                className={!state.SPOC_PHONE.error.length ? "form-control" : "form-control is-invalid"}
                                                                name={state.SPOC_PHONE.name}
                                                                value={state.SPOC_PHONE.value}
                                                                onChange={(e) => { onChange(e) }}
                                                                placeholder="Enter SPOC Phone"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="mb-3">
                                                            <label for="basicpill-address-input">College Type<span className='text-danger'>*</span></label>
                                                            <div className="row">
                                                                <div className='col-1'>
                                                                    <input
                                                                        type="radio"
                                                                        className={"form-check"}
                                                                        checked={state.COLLEGE_TYPE.value === 'AFFILIATED'}
                                                                        name={state.COLLEGE_TYPE.name}
                                                                        value={'AFFILIATED'}
                                                                        onChange={(e) => { onChange(e) }}

                                                                    />
                                                                    <input
                                                                        type="radio"
                                                                        className={"form-check"}
                                                                        checked={state.COLLEGE_TYPE.value === 'INDIVIDUAL'}
                                                                        name={state.COLLEGE_TYPE.name}
                                                                        value={'INDIVIDUAL'}
                                                                        onChange={(e) => { onChange(e) }}
                                                                    />
                                                                </div>
                                                                <div className='col-11'>
                                                                    <span>Affilated</span>
                                                                    <br />
                                                                    <span>Individual</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-3">
                                                        <div className="mb-3">
                                                            <label for="basicpill-address-input">Address<span className='text-danger'>*</span></label>
                                                            <input
                                                                type="text"
                                                                className={!state.COLLEGE_ADDRESS.error.length ? "form-control" : "form-control is-invalid"}
                                                                name={state.COLLEGE_ADDRESS.name}
                                                                value={state.COLLEGE_ADDRESS.value}
                                                                onChange={(e) => { onChange(e) }}

                                                                placeholder="Enter Address"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="mb-3">
                                                            <label for="basicpill-address-input">State<span className='text-danger'>*</span></label>
                                                            <select
                                                                type="text"
                                                                className={!state.COLLEGE_STATE.error.length ? "form-select" : "form-select is-invalid"}
                                                                name={state.COLLEGE_STATE.name}
                                                                value={state.COLLEGE_STATE.value}
                                                                onChange={(e) => { onChange(e) }}
                                                            >
                                                                <option value=''>Select State</option>
                                                                {state.COLLEGE_STATE.options.map((item) => {
                                                                    return (<option value={item.STATE_ID}>{item.STATE}</option>)
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="mb-3">
                                                            <label for="basicpill-address-input">City<span className='text-danger'>*</span></label>
                                                            <select
                                                                type="text"
                                                                className={!state.COLLEGE_CITY.error.length ? "form-select" : "form-select is-invalid"}
                                                                name={state.COLLEGE_CITY.name}
                                                                value={state.COLLEGE_CITY.value}
                                                                onChange={(e) => { onChange(e) }}
                                                            >
                                                                <option value=''>Select City</option>
                                                                {state.COLLEGE_CITY.options.map((item) => {
                                                                    return (<option value={item.CITY_ID}>{item.CITY}</option>)
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="mb-3">
                                                            <label for="basicpill-address-input">Pin Code<span className='text-danger'>*</span></label>
                                                            <NumberFormat
                                                                className={!state.COLLEGE_PIN_CODE.error.length ? "form-control" : "form-control is-invalid"}
                                                                name={state.COLLEGE_PIN_CODE.name}
                                                                value={state.COLLEGE_PIN_CODE.value}
                                                                onChange={(e) => { onChange(e) }}
                                                                placeholder="Enter Pin Code"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-3">
                                                        <div className="mb-3">
                                                            <label for="basicpill-address-input">Logo<span className='text-danger'>*</span></label>
                                                            <input
                                                                type="file"
                                                                className={!state.LOGO.error.length ? "form-control" : "form-control is-invalid"}
                                                                name={state.LOGO.name}
                                                                onChange={(e) => { onUploadCollegeLogo(e) }}
                                                            />
                                                            <a href={`${process.env.REACT_APP_BASE_URL}/get/college-logo/${state.LOGO.value}`} target='_blank'>{state.LOGO.value}</a>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="mb-3">
                                                            <button type="submit" onClick={() => { onsubmit() }} className="btn btn-info waves-effect btn-label waves-light" ><i className="bx bx-check-double label-icon"></i> Save</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}

export default EditCollegeDetail