import React from 'react'
import { useEffect } from 'react'
import { addBanner, addScriptBanner } from '../../actions/utilitiesAction'
import AddThird from '../../component/Banner/AddThirdPartyBanner'
import constant from '../../constant'
const AddThirdPartyBanner = () => {

    const [response, setResponse] = React.useState({})
    const [showMsg, setShowMsg] = React.useState(false)

    const onSubmit = (model) => {
        addScriptBanner(model).then((res) => {
            // if (res.status)
            //     alert(res.messageCode)
            setResponse(res)
            setShowMsg(true)
            setTimeout(() => {
                setShowMsg(false)
            }, 7000)
        }).catch((err) => {
            alert(err)
        })
    }
    useEffect(() => {
        document.title = constant.title.AddBanner
    }, [])


    return (
        <React.Fragment>
            <AddThird response={response} showMsg={showMsg} onSubmit={onSubmit}/>
        </React.Fragment>
    )
}

export default AddThirdPartyBanner
