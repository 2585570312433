import React, { useState, useEffect, useRef } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { Navigate, useParams } from "react-router-dom";
import parse from 'html-react-parser';
import {
  createJob,
  employmentTypeListsForJob,
  employmentTypeListsForJobs,
  getCityListsForJobs,
  getCourseSpeczListForJobs,
  getEduQualificationTypeListsForJobs,
  getEmployerCompanyListsForJobs,
  getFunctionalAreaListsForJobs,
  getIndustryListsForJobs,
  getJobCityListsForJobs,
  getJobDetailById,
  getKeywordSkillsListsForJobs,
  getLocalityListsForJobs,
  getQualificationTypeListsForJobs,
  getQuestionListsForJobs,
  getRoleListsForJobs,
  getStateListsForJobs,
  removeJobCity,
  removeJobCourseSpecialization,
  removeJobEducationQualification,
  removeJobLocality,
  salaryLists,
} from "../../actions/JobsAction";
import { getSalaryList } from "../../actions/utilitiesAction";
import constant from "../../constant";
import Footer from "../common/Footer";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import SuccessMessage from "../common/SuccessMessage";
import ErrorMessage from "../common/ErrorMessage";

function AddJobs(props) {
  const { id } = useParams();
const params = useParams()
  //job information variables
  const [max_sal_list, set_max_sal_list] = useState([])
  const [designation, setDesignation] = useState("");
  const [Vacancies, setVacancies] = useState("");
  const [employmentType, setEmploymentType] = useState("");
  const [employmentTypeList, setEmploymentTypeList] = useState([]);
  const [isWFHOrPermanantRem, setIsWFHOrPermanantRem] = useState(false);
  const [isWFHOrPermanantRemCheck, setIsWFHOrPermanantRemCheck] = useState(false);
  const [workExpMin, setWorkExpMin] = useState("");
  const [workExpMax, setWorkExpMax] = useState("");
  const [skillsKeyword, setSkillsKeyword] = useState([]);
  const [jobDisc, setJobDisc] = useState("");
  const [annualCtcMin, setAnnualCtcMin] = useState("");
  const [annualCtcMax, setAnnualCtcMax] = useState("");
  const [currency, setCurrency] = useState("");
  const [isHideSalaryFromCandidate, setIsHideSalaryFromCandidate] = useState(false);
  const [otherSalaryDet, setOtherSalaryDet] = useState("");
  const [cityList, setCityList] = useState([]);
  const [localityLists, setLocality] = useState([]);
  const [location, setLocation] = useState([]);
  const [industry, setIndustry] = useState("");
  const [industryList, setIndustryList] = useState([]);
  const [functional, setFunctional] = useState("");
  const [functionalList, setFunctionalList] = useState([]);
  const [role, setRole] = useState("");
  const [rolelList, setRoleList] = useState([]);
  const [edu_qualification, setEdu_Qualification] = useState([]);
  const [qualificationList, setQualificationList] = useState([]);
  const [selectedQualification, setSelectedQualification] = useState([]);
  const [selectedEduQualificationList, setSelectedEduQualificationList] = useState([]);
  const [eduQualificationList, setEduQualificationList] = useState([]);
  const [qualificationDetails, setQualificationDetails] = useState([]);
  const [selectedCourseSpeczList, setSelectedCourseSpezList] = useState([]);
  const [courseSpeczList, setCourseSpezList] = useState([]);
  const [refCode, setRefCode] = useState("");
  const [skillsKeywordLists, setSkillsKeywordLists] = useState([]);
  const [selectedSkillsKeyword, setSelectedSkillsKeyword] = useState([]);
  const [selectedLocality, setSelectedLocality] = useState([]);
  const [cityDetails, setCityDetails] = useState(null);
  const [courseSpeczDetails, setCourseSpeczDetails] = useState(null);
  const [STATUS, setStatus] = useState("");
  const [JOB_ID, setJobId] = useState("");
  const [salaryList, setSalaryList] = useState([])
  const [experienceList, setExperienceList] = useState([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 20, 25, 30])
  const [stateList, setStateList] = useState([]);




  // const [selectedStateList, setSelectedStateList] = useState([]);
  // const [stateDetails, setStateDetails] = useState(null);
  const [listingType, setListingType] = useState("");
  //walk in details

  const [isWalkIn, setIsWalkIn] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [duration, setDuration] = useState("");
  const [startTiming, setStartTiming] = useState("");
  const [endTiming, setEndTiming] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [mobile, setMobile] = useState("");
  const [isMobNumValid, setIsMobNumValid] = useState(false)
  const [Venue, setVenue] = useState("");
  const [googleMapUrl, setGoogleMapUrl] = useState("");
  const [isValidGoogleMapUrl, setIsValidGoogleMapUrl] = useState(false)

  //Company and client Details

  const [company, setCompany] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [clientName, setClientName] = useState("");
  const [clientDetails, setClientDetails] = useState("");
  const [address, setAddress] = useState("");

  //Question

  const [questionList, setQuestionList] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState([]);
  const [prevSelectedQuestion, setPrevSelectedQuestion] = useState([])
  const [questDetails, setQuestDetails] = useState(null);

  //error
  const [error, setError] = useState({});

  const editor = useRef();
  useEffect(() => {
    getQuestionLists()
    getSalaryList()
    getEmploymentTypeListForJob();
    getIndustryListForJob();
    getFunctionalListForJob();
    functional.length > 0 && getRoleForJob(functional);
    // getStateListForJob();
    getCityListForJob();
    getQualificationTypeListForJob();
    getSkillsKeyWordList();
    getEmployerCompanyLists();
    // getJobDetailFromId();
    id && getJobDetailFromId(id);
    if (id) {
      document.title = constant.title.EditJobs
    } else {
      document.title = constant.title.AddJobs
    }
  }, []);

  const getSunEditorInstance = (sunEditor) => {
    editor.current = sunEditor;
  };




  const getSalaryList = () => {
    salaryLists()
      .then((res) => {
        if (res.status) {
          setSalaryList(res.result.list);
        }
      })
      .catch((err) => {
        alert(err);
      });
  }



  const getJobDetailFromId = (id) => {
    if (id !== undefined) {
      getJobDetailById({ JOB_ID: id })
        .then((res) => {

          if (res.status) {
            let q = res.result.QUESTIONS && res.result.QUESTIONS.map((d, i) => {
              return d.QUESTION_ID
            })

            setSelectedQuestion(q);
            setPrevSelectedQuestion(res.result.QUESTIONS)
            setAddress(res.result.ADDRESS);
            setClientDetails(res.result.CLIENT_DETAILS);
            setClientName(res.result.CLIENT_NAME);
            setContactPerson(res.result.CONTACT_PERSON);
            setCurrency(res.result.CTC_CURRENCY);
            setAnnualCtcMax(res.result.CTC_MAX);
            setAnnualCtcMin(res.result.CTC_MIN);
            setDuration(res.result.DURATION);
            setEdu_Qualification(res.result.QUALIFICATION);

            let eduQualif = [];
            let ed = res.result.COURSE_SPECIALIZATION
              ? res.result.COURSE_SPECIALIZATION.map((data, index) => {
                return data.map((d, i) => {
                  return eduQualif.push({
                    EDUCATION_QUALIFICATION_ID: d.EDUCATION_QUALIFICATION_ID,
                    COURSE_STREAM: d.COURSE_STREAM,
                    label: d.COURSE_STREAM,
                  });
                });
              })
              : [];
            setSelectedEduQualificationList(eduQualif);

            let specz = [];
            let s = res.result.COURSE_SPECIALIZATION
              ? res.result.COURSE_SPECIALIZATION.map((data, index) => {
                return data.map((d, i) => {
                  return d.COURSES.map((course, cid) => {
                    return specz.push({
                      SPECIALIZATION_ID: course.SPECIALIZATION_ID,
                      SPECIALIZATION: course.SPECIALIZATION,
                    });
                  });
                });
              })
              : [];
            setSelectedCourseSpezList(specz);
            setCompany(res.result.EMPLOYER_ID);
            setEmploymentType(res.result.EMPLOYMENT_TYPE_ID);
            setFunctional(res.result.FUNCTIONAL_AREA_ID);
            setGoogleMapUrl(res.result.GOOGLE_MAP_URL);
            setIndustry(res.result.INDUSTRY_ID);
            setIsHideSalaryFromCandidate(
              res.result.IS_HIDE_SALARY_FROM_CANDIDATE == "N" ? false : true
            );
            setIsWalkIn(
              res.result.IS_INCLUDE_WALK_IN == "N" ? false : true
            );
            setIsWFHOrPermanantRemCheck(
              res.result.IS_WORK_FROM_HOME == "N" ? false : true
            );
            setIsWFHOrPermanantRem(
              res.result.IS_WORK_FROM_HOME_DURING_COVID == "N"
                ? false
                : true
            );
            setJobId(res.result.JOB_ID);
            setJobDisc(res.result.JOB_DETAILS);
            //Locality
            let k = [];
            res.result.JOB_LOCATIONS &&
              res.result.JOB_LOCATIONS.map((d, index) => {
                return d.Localities.map((dat, i) => {
                  k.push(dat);
                });
              });
            setSelectedLocality(k);

            setLocation(
              res.result.JOB_LOCATIONS
                ? res.result.JOB_LOCATIONS.map((data, index) => {
                  return {
                    CITY_ID: data.CITY_ID,
                    CITY: data.CITY,
                    label: data.CITY,
                  };
                })
                : []
            );
            setDesignation(res.result.JOB_TITLE);

            setSelectedSkillsKeyword(res.result.KEYWORDS.split(","));
            setMobile(res.result.MOBILE_NUMBER);
            setVacancies(res.result.NO_OF_VACANCIES);
            setOtherSalaryDet(res.result.OTHER_SALARY_DETAILS);
            setSelectedQualification(res.result.QUALIFICATION_ID);
            setRefCode(res.result.REFERENCE_CODE);
            setRole(res.result.ROLE_ID);
            setStatus(res.result.STATUS);
            // res.result.TYPE_OF_WORK_FROM_HOME,
            setVenue(res.result.VENUE);
            setEndTiming(res.result.WALK_IN_END_TIME);
            setStartDate(res.result.WALK_IN_START_DATE);
            setStartTiming(res.result.WALK_IN_START_TIME);
            setWorkExpMax(res.result.WORK_EXP_MAX);
            setWorkExpMin(res.result.WORK_EXP_MIN);
            getRoleForJob(res.result.FUNCTIONAL_AREA_ID);
            setListingType(res.result.LISTNING_TYPE)
          }
        })
        .catch((err) => {
          alert(err);
        });
    }
  };

  const getEmployerCompanyLists = () => {
    getEmployerCompanyListsForJobs()
      .then((res) => {
        if (res.status) {
          let r = res.result.list.map((data, index) => {
            return {
              COMPANY_NAME: data.COMPANY_NAME,
              CUSTOM_EMPLOYER_ID: data.CUSTOM_EMPLOYER_ID,
              EMPLOYER_ID: data.EMPLOYER_ID,
              label: data.COMPANY_NAME,
            };
          });

          setCompanyList(r);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const getQuestionLists = () => {
    getQuestionListsForJobs()
      .then((res) => {
        if (res.status) {
          let r = res.result.res.map((data, index) => {
            let { OPTION1, OPTION2, OPTION3, OPTION4 } = JSON.parse(
              data.ANSWER
            );
            return {
              ANSWER: { OPTION1, OPTION2, OPTION3, OPTION4 },
              CREATED_BY: data.CREATED_BY,
              CREATED_ON: data.CREATED_ON,
              IS_MANADATORY: data.IS_MANADATORY,
              MODIFIED_BY: data.MODIFIED_BY,
              MODIFIED_ON: data.MODIFIED_ON,
              QUESTION: data.QUESTION,
              QUESTION_ID: data.QUESTION_ID,
              QUESTION_TYPE_ID: data.QUESTION_TYPE_ID,
              SORT_NUMBER: data.SORT_NUMBER,
              STATUS: data.STATUS,
              checked: false,
            };
          });

          setQuestionList(r);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const getSkillsKeyWordList = () => {
    getKeywordSkillsListsForJobs()
      .then((res) => {
        if (res.status) {
          let d = res.result.res.map((data, index) => {
            return {
              CREATED_BY: data.CREATED_BY,
              CREATED_ON: data.CREATED_ON,
              HOME_VISIBILITY: data.HOME_VISIBILITY,
              MODIFIED_BY: data.MODIFIED_BY,
              MODIFIED_ON: data.MODIFIED_ON,
              SKILL: data.SKILL,
              SKILLS_DETAILS: data.SKILLS_DETAILS,
              SKILL_ID: data.SKILL_ID,
              SKILL_TYPE: data.SKILL_TYPE,
              SORT_NUMBER: data.SORT_NUMBER,
              STATUS: data.STATUS,
              URL: data.URL,
              label: data.SKILL,
            };
          });
          setSkillsKeywordLists(d);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const getEmploymentTypeListForJob = () => {
    employmentTypeListsForJobs()
      .then((res) => {
        if (res.status) {
          setEmploymentTypeList(res.result);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const getIndustryListForJob = () => {
    getIndustryListsForJobs()
      .then((res) => {
        if (res.status) {
          setIndustryList(res.result);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const getFunctionalListForJob = () => {
    getFunctionalAreaListsForJobs()
      .then((res) => {
        if (res.status) {
          setFunctionalList(res.result);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const getRoleForJob = (FUNCTIONAL_AREA_ID) => {
    getRoleListsForJobs({ FUNCTIONAL_AREA_ID: FUNCTIONAL_AREA_ID })
      .then((res) => {
        if (res.status) {
          setRoleList(res.result);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const getStateListForJob = () => {
    getStateListsForJobs()
      .then((res) => {
        if (res.status) {
          let d = res.result.map((data, index) => {
            return {
              COUNTRY_ID: data.COUNTRY_ID,
              CREATED_BY: data.CREATED_BY,
              CREATED_ON: data.CREATED_ON,
              MODIFIED_BY: data.MODIFIED_BY,
              MODIFIED_ON: data.MODIFIED_ON,
              SORT_NUMBER: data.SORT_NUMBER,
              STATE_CODE: data.STATE_CODE,
              URL: data.URL,
              STATE: data.STATE,
              STATE_ID: data.STATE_ID,
              label: data.STATE,
            };
          });
          setStateList(d);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const getCityListForJob = (STATE_ID) => {
    getCityListsForJobs({ STATE_ID: STATE_ID })
      .then((res) => {
        if (res.status) {
          let d = res.result.map((data, index) => {
            return {
              ABOUT_CITY: data.ABOUT_CITY,
              ABOUT_CITY_TITLE: data.ABOUT_CITY_TITLE,
              CITY: data.CITY,
              CITY_HEADLINE: data.CITY_HEADLINE,
              CITY_ID: data.CITY_ID,
              CITY_IMAGE: data.CITY_IMAGE,
              COUNTRY_ID: data.COUNTRY_ID,
              CREATED_ON: data.CREATED_ON,
              IS_PREMIUM_CITY: data.IS_PREMIUM_CITY,
              MODIFIED_BY: data.MODIFIED_BY,
              MODIFIED_ON: data.MODIFIED_ON,
              SORT_NUMBER: data.SORT_NUMBER,
              STATE_ID: data.STATE_ID,
              label: data.CITY,
            };
          });
          setCityList(d);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const getLocalityListForJob = (CITY_ID) => {
    getLocalityListsForJobs({ CITY_ID: CITY_ID })
      .then((res) => {
        if (res.status) {
          let d = res.result.map((data, index) => {
            return {
              CITY_ID: data.CITY_ID,
              COUNTRY_ID: data.COUNTRY_ID,
              CREATED_BY: data.CREATED_BY,
              CREATED_ON: data.CREATED_ON,
              LOCALITY: data.LOCALITY,
              LOCALITY_ID: data.LOCALITY_ID,
              MODIFIED_BY: data.MODIFIED_BY,
              MODIFIED_ON: data.MODIFIED_ON,
              SORT_NUMBER: data.SORT_NUMBER,
              STATE_ID: data.STATE_ID,
              label: data.LOCALITY,
            };
          });
          setLocality(d);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const getQualificationTypeListForJob = () => {
    getQualificationTypeListsForJobs()
      .then((res) => {
        if (res.status) {
          let d = res.result.map((data, index) => {
            return {
              CREATED_BY: data.CREATED_BY,
              CREATED_ON: data.CREATED_ON,
              MODIFIED_BY: data.MODIFIED_BY,
              MODIFIED_ON: data.MODIFIED_ON,
              QUALIFICATION_ID: data.QUALIFICATION_ID,
              QUALIFICATION_NAME: data.QUALIFICATION_NAME,
              SORT_NUMBER: data.SORT_NUMBER,
              label: data.QUALIFICATION_NAME,
            };
          });
          setQualificationList(d);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const getEducationQualificationTypeListForJob = (QUALIFICATION_ID) => {
    getEduQualificationTypeListsForJobs({ QUALIFICATION_ID: QUALIFICATION_ID })
      .then((res) => {
        if (res.status) {
          let d = res.result.map((data, index) => {
            return {
              COURSE_STREAM: data.COURSE_STREAM,
              COURSE_STREAM_DETAILS: data.COURSE_STREAM_DETAILS,
              CREATED_BY: data.CREATED_BY,
              CREATED_ON: data.CREATED_ON,
              EDUCATION_QUALIFICATION_ID: data.EDUCATION_QUALIFICATION_ID,
              MODIFIED_BY: data.MODIFIED_BY,
              MODIFIED_ON: data.MODIFIED_ON,
              QUALIFICATION_ID: data.QUALIFICATION_ID,
              SORT_NUMBER: data.SORT_NUMBER,
              STATUS: data.STATUS,
              label: data.COURSE_STREAM,
            };
          });
          setEduQualificationList(d);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const getCourseSpeczTypeListForJob = (EDUCATION_QUALIFICATION_ID) => {
    getCourseSpeczListForJobs({
      EDUCATION_QUALIFICATION_ID: EDUCATION_QUALIFICATION_ID,
    })
      .then((res) => {
        if (res.status) {
          let d = res.result.map((data, index) => {
            return {
              CREATED_BY: data.CREATED_BY,
              CREATED_ON: data.CREATED_ON,
              EDUCATION_QUALIFICATION_ID: data.EDUCATION_QUALIFICATION_ID,
              MODIFIED_BY: data.MODIFIED_BY,
              MODIFIED_ON: data.MODIFIED_ON,
              QUALIFICATION_ID: data.QUALIFICATION_ID,
              SORT_NUMBER: data.SORT_NUMBER,
              SPECIALIZATION: data.SPECIALIZATION,
              SPECIALIZATION_DETAILS: data.SPECIALIZATION_DETAILS,
              SPECIALIZATION_ID: data.SPECIALIZATION_ID,
              STATUS: data.STATUS,
              label: data.SPECIALIZATION,
            };
          });
          setCourseSpezList(d);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const validationHandler = (model) => {
    let isValid = true;
    let error = {};

    if (!model["JOB_TITLE"]) {
      error["JOB_TITLE"] = "Please enter Job Title";
      isValid = false;
    }
    if (!model["NO_OF_VACANCIES"]) {
      error["NO_OF_VACANCIES"] = "Please enter number of Vacancies";
      isValid = false;
    }
    if (!model["EMPLOYMENT_TYPE_ID"]) {
      error["EMPLOYMENT_TYPE_ID"] = "Please select Employment Type";
      isValid = false;
    }
    if (model["IS_WORK_FROM_HOME_DURING_COVID"] == "Y") {
      if (model["IS_WORK_FROM_HOME"] == "N") {
        error["isWFHOrPermanantRem"] = "Please select either one option";
        isValid = false;
      }
    }
    if (!model["WORK_EXP_MIN"]) {
      error["WORK_EXP_MIN"] = "Please select Min Experience";
      isValid = false;
    }
    if (!model["WORK_EXP_MAX"]) {
      error["WORK_EXP_MAX"] = "Please select Max Experience";
      isValid = false;
    }
    if (model["KEYWORDS"] && model["KEYWORDS"].length == 0) {
      error["KEYWORDS"] = "Please select skills Keyword";
      isValid = false;
    }
    if (!model["JOB_DETAILS"]) {
      error["JOB_DETAILS"] = "Please select Job Details";
      isValid = false;
    }
    if (!model["CTC_CURRENCY"]) {
      error["CTC_CURRENCY"] = "Please select Currency";
      isValid = false;
    }
    if (!model["CTC_MIN"]) {
      error["CTC_MIN"] = "Please select Min CTC";
      isValid = false;
    }
    if (!model["CTC_MAX"]) {
      error["CTC_MAX"] = "Please select Max CTC";
      isValid = false;
    }
    // if (!model["OTHER_SALARY_DETAILS"]) {
    //   error["OTHER_SALARY_DETAILS"] = "Please select Other Salary Details";
    //   isValid = false;
    // }
    if (model["CITY_ID"] && model["CITY_ID"] == 0) {
      error["CITY_ID"] = "Please select City";
      isValid = false;
    }
    if (!model["INDUSTRY_ID"]) {
      error["INDUSTRY_ID"] = "Please select Industry";
      isValid = false;
    }
    if (!model["FUNCTIONAL_AREA_ID"]) {
      error["FUNCTIONAL_AREA_ID"] = "Please select FUNCTIONAL AREA";
      isValid = false;
    }
    if (!model["ROLE_ID"]) {
      error["ROLE_ID"] = "Please select Role";
      isValid = false;
    }
    // if (!model["REFERENCE_CODE"]) {
    //   error["REFERENCE_CODE"] = "Please select Reference Code";
    //   isValid = false;
    // }
    // if (
    //   model["EDUCATION_QUALIFICATION_ID"] &&
    //   model["EDUCATION_QUALIFICATION_ID"].length == 0
    // ) {
    //   error["EDUCATION_QUALIFICATION_ID"] =
    //     "Please select Education Qualification";
    //   isValid = false;
    // }
    if (edu_qualification && edu_qualification.length == 0) {
      error["QUALIFICATION_ID"] = "Please select Qualification";
      isValid = false;
    }

    //walk in details
    if (model["IS_INCLUDE_WALK_IN"] == "Y") {
      if (!model["WALK_IN_END_TIME"]) {
        error["WALK_IN_END_TIME"] = "Please select Walk In End Time";
        isValid = false;
      }
      if (!model["WALK_IN_START_DATE"]) {
        error["WALK_IN_START_DATE"] = "Please select Walk In Start Date";
        isValid = false;
      }
      if (!model["WALK_IN_START_TIME"]) {
        error["WALK_IN_START_TIME"] = "Please select Walk In Start Time";
        isValid = false;
      }
      if (!model["VENUE"]) {
        error["VENUE"] = "Please select Walk In Venue";
        isValid = false;
      }
      if (!model["DURATION"]) {
        error["DURATION"] = "Please select Walk In Duration";
        isValid = false;
      }
      if (!model["MOBILE_NUMBER"]) {
        error["MOBILE_NUMBER"] = "Please enter Mobile Number";
        isValid = false;
      }
      if (model["MOBILE_NUMBER"] !== "") {
        const regexExp = /^[6789][0-9]{9}/
        if (regexExp.test(model.MOBILE_NUMBER)) { } else {
          error["MOBILE_NUMBER"] = "Please enter valid Mobile Number";
          isValid = false;
        }
      }
      if (!model["CONTACT_PERSON"]) {
        error["CONTACT_PERSON"] = "Please enter Contact Person";
        isValid = false;
      }
      if (!model["GOOGLE_MAP_URL"]) {
        error["GOOGLE_MAP_URL"] = "Please enter Google Map URL";
        isValid = false;
      }
    }

    // company details
    if (!model["EMPLOYER_ID"]) {
      error["EMPLOYER_ID"] = "Please select Company";
      isValid = false;
    }
    if (!model["CLIENT_DETAILS"]) {
      error["CLIENT_DETAILS"] = "Please enter Client Details";
      isValid = false;
    }
    if (!model["CLIENT_NAME"]) {
      error["CLIENT_NAME"] = "Please enter Client Name";
      isValid = false;
    }
    if (!model["ADDRESS"]) {
      error["ADDRESS"] = "Please enter Address";
      isValid = false;
    }

    // Question
    if (model["QUESTIONS"] && model["QUESTIONS"].length == 0) {
      error["QUESTIONS"] = "Please select Question";
      isValid = false;
    }

    if (!model["LISTNING_TYPE"]) {
      error["LISTING_TYPE"] = "Please select Question";
      isValid = false;
    }
    setError(error);
    return isValid;
  };

  const handleSubmit = () => {
    debugger
    let pn = params.pageno
    // console.log('test', pn);
    if (id !== undefined) {
      let model = {
        JOB_ID: JOB_ID,
        JOB_TITLE: designation,
        NO_OF_VACANCIES: Vacancies,
        EMPLOYMENT_TYPE_ID: employmentType,
        IS_WORK_FROM_HOME: isWFHOrPermanantRem ? "Y" : "N",
        IS_WORK_FROM_HOME_DURING_COVID: isWFHOrPermanantRemCheck ? "Y" : "N",
        TYPE_OF_WORK_FROM_HOME: "PRJ",
        WORK_EXP_MIN: workExpMin.toString(),
        WORK_EXP_MAX: workExpMax.toString(),
        KEYWORDS: selectedSkillsKeyword,
        JOB_DETAILS: jobDisc,
        CTC_CURRENCY: 1,
        CTC_MIN: annualCtcMin,
        CTC_MAX: annualCtcMax,
        IS_HIDE_SALARY_FROM_CANDIDATE: isHideSalaryFromCandidate ? "Y" : "N",
        OTHER_SALARY_DETAILS: otherSalaryDet,
        INDUSTRY_ID: industry,
        FUNCTIONAL_AREA_ID: functional,
        ROLE_ID: role,
        REFERENCE_CODE: refCode,
        IS_INCLUDE_WALK_IN: isWalkIn ? "Y" : "N",
        WALK_IN_START_DATE: startDate,
        DURATION: duration,
        WALK_IN_START_TIME: startTiming,
        WALK_IN_END_TIME: endTiming,
        CONTACT_PERSON: contactPerson,
        MOBILE_NUMBER: mobile,
        VENUE: Venue,
        GOOGLE_MAP_URL: googleMapUrl,
        EMPLOYER_ID: company,
        CLIENT_NAME: clientName,
        CLIENT_DETAILS: clientDetails,
        ADDRESS: address,
        SORT_NUMBER: 1,
        JOB_LOCATIONS: selectedLocality.map((data, index) => {
          return data.LOCALITY_ID;
        }),
        COURSE_SPECIALIZATION: selectedCourseSpeczList && selectedCourseSpeczList.map((data, index) => {
          return data.SPECIALIZATION_ID;
        }),
        QUESTIONS: selectedQuestion,
        CITY_ID: location.map((data, index) => {
          return data.CITY_ID;
        }),
        EDUCATION_QUALIFICATION_ID: selectedEduQualificationList && selectedEduQualificationList.map(
          (data, index) => {
            return data.EDUCATION_QUALIFICATION_ID;
          }
        ),
        QUALIFICATION_ID: edu_qualification && edu_qualification.map(
          (data, index) => {
            return data.QUALIFICATION_ID;
          }
        ),
        LISTNING_TYPE: listingType,
        type: "UPDATE",
      };
      model.pageno= pn
        
      let status = validationHandler(model);
      if (status) {
        props.onSubmit(model);
      }
    } else {
      let model = {
        JOB_TITLE: designation,
        NO_OF_VACANCIES: Vacancies,
        EMPLOYMENT_TYPE_ID: employmentType,
        IS_WORK_FROM_HOME: isWFHOrPermanantRem ? "Y" : "N",
        IS_WORK_FROM_HOME_DURING_COVID: isWFHOrPermanantRemCheck ? "Y" : "N",
        TYPE_OF_WORK_FROM_HOME: "PRJ",
        WORK_EXP_MIN: workExpMin,
        WORK_EXP_MAX: workExpMax,
        KEYWORDS: selectedSkillsKeyword,
        JOB_DETAILS: jobDisc,
        CTC_CURRENCY: 1,
        CTC_MIN: annualCtcMin,
        CTC_MAX: annualCtcMax,
        IS_HIDE_SALARY_FROM_CANDIDATE: isHideSalaryFromCandidate ? "Y" : "N",
        OTHER_SALARY_DETAILS: otherSalaryDet,
        INDUSTRY_ID: industry,
        FUNCTIONAL_AREA_ID: functional,
        ROLE_ID: role,
        REFERENCE_CODE: refCode,
        IS_INCLUDE_WALK_IN: isWalkIn ? "Y" : "N",
        WALK_IN_START_DATE: startDate,
        DURATION: duration,
        WALK_IN_START_TIME: startTiming,
        WALK_IN_END_TIME: endTiming,
        CONTACT_PERSON: contactPerson,
        MOBILE_NUMBER: mobile,
        VENUE: Venue,
        GOOGLE_MAP_URL: googleMapUrl,
        EMPLOYER_ID: company,
        CLIENT_NAME: clientName,
        CLIENT_DETAILS: clientDetails,
        ADDRESS: address,
        SORT_NUMBER: 1,
        JOB_LOCATIONS: selectedLocality.map((data, index) => {
          return data.LOCALITY_ID;
        }),
        COURSE_SPECIALIZATION: selectedCourseSpeczList && selectedCourseSpeczList.map((data, index) => {
          return data.SPECIALIZATION_ID;
        }),
        QUESTIONS: selectedQuestion,
        CITY_ID: location.map((data, index) => {
          return data.CITY_ID;
        }),
        EDUCATION_QUALIFICATION_ID: selectedEduQualificationList && selectedEduQualificationList.map(
          (data, index) => {
            return data.EDUCATION_QUALIFICATION_ID;
          }
        ),
        QUALIFICATION_ID: edu_qualification && edu_qualification.map(
          (data, index) => {
            return data.QUALIFICATION_ID;
          }
        ),
        LISTNING_TYPE: listingType,
        type: "ADD",
      };
      let status = validationHandler(model);
      if (status) {
        props.onSubmit(model);
      }
    }
  };

  const deleteLocation = (JOB_ID, LOCATION_ID, TYPE, INDEX, DATA) => {
    if (Object.keys(DATA).length < 4) {
      if (TYPE == "CITY") {
        removeJobCity({
          JOB_ID: JOB_ID,
          CITY_ID: LOCATION_ID,
        })
          .then((res) => {
            if (res.status) {
              alert(res.messageCode);
              getJobDetailFromId(id);
            }
          })
          .catch((err) => {
            alert(err);
          });
      } else {
        removeJobLocality({
          JOB_ID: JOB_ID,
          LOCALITY_ID: LOCATION_ID,
        })
          .then((res) => {
            if (res.status) {
              alert(res.messageCode);
              getJobDetailFromId(id);
            }
          })
          .catch((err) => {
            alert(err);
          });
      }
    } else {
      if (TYPE == "CITY") {
        let loc = [...location];
        loc.splice(INDEX, 1);
        let r = selectedLocality.filter((d, i) => {
          return d.CITY_ID !== LOCATION_ID ? d : "";
        });
        setSelectedLocality(r);
        setLocation(loc);
      } else if (TYPE == "LOCALITY") {
        let loc = [...selectedLocality];
        loc.splice(INDEX, 1);
        setSelectedLocality(loc);
      }
      // else {
      //   let loc = [...selectedStateList];
      //   loc.splice(INDEX, 1);
      //   let r = location.filter((d, i) => {
      //     return d.STATE_ID !== LOCATION_ID ? d : "";
      //   });
      //   let s = selectedLocality.filter((d, i) => {
      //     return d.STATE_ID !== LOCATION_ID ? d : "";
      //   });
      //   setSelectedLocality(s);
      //   setLocation(r);
      //   setSelectedStateList(loc);
      // }
    }
  };

  const deleteQualification = (JOB_ID, EDUCATION, TYPE, INDEX, DATA) => {
    if (Object.keys(DATA).length < 4) {
      if (TYPE == "EDUCATION_QUALIFICATION") {
        removeJobEducationQualification({
          JOB_ID: JOB_ID,
          EDUCATION_QUALIFICATION_ID: EDUCATION,
        })
          .then((res) => {
            if (res.status) {
              alert(res.messageCode);
              getJobDetailFromId(id);
            }
          })
          .catch((err) => {
            alert(err);
          });
      } else {
        removeJobCourseSpecialization({
          JOB_ID: JOB_ID,
          SPECIALIZATION_ID: EDUCATION,
        })
          .then((res) => {
            if (res.status) {
              alert(res.messageCode);
              getJobDetailFromId(id);
            }
          })
          .catch((err) => {
            alert(err);
          });
      }
    } else {
      if (TYPE == "EDUCATION_QUALIFICATION") {
        let edu = [...selectedEduQualificationList];
        edu.splice(INDEX, 1);
        let r = selectedCourseSpeczList.filter((d, i) => {
          return d.EDUCATION_QUALIFICATION_ID !== EDUCATION ? d : "";
        });
        setSelectedCourseSpezList(r);
        setSelectedEduQualificationList(edu);
      } else {
        let edu = [...selectedCourseSpeczList];
        edu.splice(INDEX, 1);
        setSelectedCourseSpezList(edu);
      }
    }
  };

  const handleCancel = (e) => {
     
    e.preventDefault();
   
     
    if (id) {
      window.location.href = constant.component.listJobs.url
    } else {
      setError({})
      setSelectedQuestion([]);
      setAddress('');
      setClientDetails('');
      setClientName('');
      setContactPerson('');
      setCurrency('');
      setAnnualCtcMax('');
      setAnnualCtcMin('');
      setDuration('');
      setEdu_Qualification('');
      setSelectedEduQualificationList([])
      setSelectedCourseSpezList([]);
      setCompany('');
      setEmploymentType([]);
      setFunctional('');
      setGoogleMapUrl('');
      setIndustry('');
      setIsHideSalaryFromCandidate(false)
      setIsWalkIn(false);
      setIsWFHOrPermanantRemCheck(false);
      setIsWFHOrPermanantRem(false);
      setJobDisc('');
      setJobId('');
      setSelectedLocality([]);
      setLocation([]);
      setDesignation('');
      setSelectedSkillsKeyword([]);
      setMobile('');
      setVacancies('');
      setOtherSalaryDet('');
      setSelectedQualification('');
      setRefCode('');
      setRole('');
      setStatus('');
      setVenue('');
      setEndTiming('');
      setStartDate('');
      setStartDate('');
      setWorkExpMax('');
      setWorkExpMin('');
      getRoleForJob('');
      setListingType('');
      setSelectedQuestion([]);
      setQuestionList([]);
      getQuestionLists();
      set_max_sal_list([])
    }
  }


  const handleChange = (content) => {
    setJobDisc(content)
  }
  return (
    <React.Fragment>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0" style={{ fontSize: "18px" }}>
                    {id ? "Update Job" : "Add Job"}
                  </h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Jobs</a>
                      </li>
                      <li className="breadcrumb-item active">
                        {id ? "Update Job" : "Add Job"}
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <h4
                      className="card-title mb-4"
                      style={{ color: "#f46d6d" }}
                    >
                      JOB INFORMATION
                    </h4>
                    {
                      props.showMsg && (props.response.status ?
                        <SuccessMessage message={props.response.messageCode} />
                        :
                        <ErrorMessage message={props.response.error} />)
                    }

                    <div id="basic-example">
                      <section>
                        <form method="POST" autocomplete="off">
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label for="basicpill-firstname-input">
                                  Job Title / Designation
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={designation}
                                  onChange={(e) => {
                                    setDesignation(e.target.value);
                                  }}
                                  id="basicpill-firstname-input"
                                  placeholder="Enter Job Title / Designation"
                                />
                                {error && !designation && (
                                  <span className="text-danger ml-1">
                                    {error.JOB_TITLE}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="mb-3">
                                <label for="basicpill-lastname-input">
                                  Number of Vacancies
                                </label>
                                <input
                                  type="text"
                                  value={Vacancies}
                                  onChange={(e) => {
                                    setVacancies(e.target.value);
                                  }}
                                  className="form-control"
                                  id="basicpill-lastname-input"
                                  placeholder="Enter Number of Vacancies"
                                />
                                {error && !Vacancies && (
                                  <span className="text-danger ml-1">
                                    {error.NO_OF_VACANCIES}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="mb-3">
                                <label for="basicpill-lastname-input">
                                  Employment Type
                                </label>
                                <select
                                  className="form-select"
                                  value={employmentType}
                                  onChange={(e) => {
                                    setEmploymentType(e.target.value);
                                  }}
                                >
                                  <option>Select Employment Type</option>
                                  {employmentTypeList.map((data, index) => {
                                    return (
                                      <option
                                        value={data.EMPLOYMENT_TYPE_ID}
                                        key={index}
                                      >
                                        {data.EMPLOYMENT_TYPE}
                                      </option>
                                    );
                                  })}
                                </select>
                                {error && !employmentType && (
                                  <span className="text-danger ml-1">
                                    {error.EMPLOYMENT_TYPE_ID}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <div className="mb-12">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="formCheck2"
                                    value={isWFHOrPermanantRemCheck}
                                    checked={isWFHOrPermanantRemCheck}
                                    onChange={(e) => {
                                      setIsWFHOrPermanantRemCheck(
                                        e.target.value
                                      );
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    for="formCheck2"
                                  >
                                    &nbsp;Work from home /
                                    Permanent remote
                                  </label>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-3">
                              <div className="mb-3">
                                <div className="mb-12">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="covid"
                                    id="formCheck3"
                                    value={isWFHOrPermanantRem}
                                    checked={isWFHOrPermanantRem}
                                    onChange={(e) => {
                                      setIsWFHOrPermanantRem(e.target.value);
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    for="formCheck3"
                                  >
                                    &nbsp;Work from home
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="mb-3">
                                <div className="mb-12">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="covid"
                                    id="formCheck4"
                                    value={isWFHOrPermanantRem}
                                    onChange={(e) => {
                                      setIsWFHOrPermanantRem(e.target.value);
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    for="formCheck4"
                                  >
                                    &nbsp;Permanent remote job
                                  </label>
                                </div>
                                {
                                  error && !isWFHOrPermanantRem && (
                                    <span className="text-danger ml-1">
                                      {error.isWFHOrPermanantRem}
                                    </span>
                                  )
                                }
                              </div>

                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-3">
                              <div className="mb-3">
                                <label for="basicpill-phoneno-input">
                                  Work Experience
                                </label>
                                <select
                                  className="form-select"
                                  value={workExpMin}
                                  onChange={(e) => {
                                    setWorkExpMin(e.target.value);
                                  }}
                                >
                                  <option>Select Min</option>
                                  {
                                    experienceList.map((data, index) => {
                                      return (
                                        <option value={data}>{data}</option>
                                      )
                                    })
                                  }
                                </select>
                                {error && !workExpMin && (
                                  <span className="text-danger ml-1">
                                    {error.WORK_EXP_MIN}
                                  </span>
                                )}
                              </div>
                            </div>

                            <div className="col-lg-3">
                              <div className="mb-3">
                                <label for="basicpill-firstname-input">
                                  &nbsp;
                                </label>
                                <select
                                  className="form-select"
                                  value={workExpMax}
                                  onChange={(e) => {
                                    setWorkExpMax(e.target.value);
                                  }}
                                >
                                  <option>Select Max</option>
                                  {
                                    experienceList.map((data, index) => {
                                      return data > workExpMin ? (
                                        <option value={data}>{data}</option>
                                      ) : ''
                                    })
                                  }
                                </select>
                                {error && !workExpMax && (
                                  <span className="text-danger ml-1">
                                    {error.WORK_EXP_MAX}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="mb-3">
                                <label for="basicpill-firstname-input">
                                  Keywords / Skills / Tags
                                </label>
                                <Typeahead
                                  id="keywords"
                                  onChange={(e) => {
                                    if (e && e.length > 0) {

                                      let skill = [
                                        ...selectedSkillsKeyword,
                                        e[0].SKILL,
                                      ];
                                      let r = skill.filter(
                                        (v, i, a) =>
                                          a.findIndex((v2) => v2 === v) == i
                                      );
                                      setSelectedSkillsKeyword(r);
                                      // setSelectedSkillsKeyword([
                                      //   ...selectedSkillsKeyword,
                                      //   e[0].SKILL,
                                      // ]);
                                    }
                                  }}
                                  options={skillsKeywordLists}
                                  placeholder="Choose a Skills..."
                                //  selected={location[0]}
                                />
                                {/* <input
                                  type="text"
                                  className="form-control"
                                  id="basicpill-lastname-input"
                                  value={skillsKeyword}
                                  onChange={(e) => {
                                    setSkillsKeyword(e.target.value);
                                  }}
                                  placeholder="Enter Keywords Comma Seprated"
                                /> */}
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="mb-3">
                                <label for="basicpill-firstname-input">
                                  Selected Skills
                                </label>
                                <ul>
                                  {selectedSkillsKeyword &&
                                    selectedSkillsKeyword.length > 0 &&
                                    selectedSkillsKeyword.map((data, index) => {
                                      return (
                                        <li>
                                          <div className="row">
                                            <div className="col-lg-9">
                                              <b>{data}</b>
                                            </div>
                                            <div className="col-lg-3">
                                              <i
                                                className="bx bx-trash bx-xs"
                                                style={{ color: "red" }}
                                                onClick={(e) => {
                                                  let key = [
                                                    ...selectedSkillsKeyword,
                                                  ];
                                                  key.splice(index, 1);
                                                  setSelectedSkillsKeyword(key);
                                                }}
                                              ></i>
                                            </div>
                                          </div>
                                        </li>
                                      );
                                    })}
                                </ul>


                                {error && selectedSkillsKeyword && selectedSkillsKeyword.length == 0 && (
                                  <span className="text-danger ml-1">
                                    {error.KEYWORDS}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label for="basicpill-phoneno-input">
                                  Job Description
                                </label>
                                <SunEditor setDefaultStyle="box-sizing: border-box;font-family: 'Segoe UI', 'Roboto', 'Oxygen',
                                  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue','Sans-serif'; font-size: 18; color: inherit;"
                                  getSunEditorInstance={getSunEditorInstance} height="160" onChange={handleChange} setContents={jobDisc} defaultValue="<strong>Roles and Responsibilities:</strong><br /> <br /><strong>Desired Candidate Profile:</strong><br /><br /> <strong>Perks and Benefits:</strong>" />
                                {/* <textarea
                                  id="editor_classNameic"
                                  className="form-control"
                                  value={jobDisc} 
                                  onChange={(e) => {
                                    setJobDisc(e.target.value);
                                  }}
                                  rows="6"
                                  placeholder="Enter Description"
                                >
                                
                                </textarea> */}
                                {
                                  error && !jobDisc && (
                                    <span className="text-danger ml-1">
                                      {error.JOB_DETAILS}
                                    </span>
                                  )
                                }
                              </div >
                            </div >

                            <div className="col-lg-2">
                              <div className="mb-3">
                                <label for="basicpill-phoneno-input">
                                  Annual CTC
                                </label>
                                <select
                                  className="form-select"
                                  value={currency}
                                  onChange={(e) => {

                                     setCurrency(e.target.value);

                                   }}
                                >
                                  <option value={"1"}>Rs</option>
                                  <option value={"2"}>USD</option>
                                  <option value={"3"}>GBP</option>
                                </select>
                                {error && !currency && (
                                  <span className="text-danger ml-1">
                                    {error.CTC_CURRENCY}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="mb-6">
                                    <label for="basicpill-phoneno-input">
                                      &nbsp;
                                    </label>
                                    <select
                                      className="form-select"
                                      value={annualCtcMin}
                                      onChange={(e) => { 
                                        debugger
                                         let value = e.target.value
                                        let f_max_ctc = salaryList.filter(ele=> ele.SALARY>Number(value) )
                                          // console.log("f_max_ctc", f_max_ctc)
                                          setAnnualCtcMin(value);
                                          set_max_sal_list(f_max_ctc)   
                                      
                                       }}
                                    >
                                      <option>Min Salary</option>
                                      {
                                        salaryList.map((data, index) => {
                                          return <option value={data.SALARY}>{data.SALARY}</option>
                                        })
                                      }
                                    </select>
                                    {error && !annualCtcMin && (
                                      <span className="text-danger ml-1">
                                        {error.CTC_MIN}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-6">
                                    <label for="basicpill-firstname-input">
                                      &nbsp;
                                    </label>
                                    <select
                                      className="form-select"
                                      value={annualCtcMax}
                                      onChange={(e) => {
                                        setAnnualCtcMax(e.target.value);
                                      }}
                                    >
                                      <option disabled hidden selected >Max Salary</option>
                                      {
                                        max_sal_list && max_sal_list.map((data, index) => {

                                          return  <option key={index} value={data.SALARY}>{data.SALARY}</option>
                                        })
                                      }
                                    </select>
                                    {error && !annualCtcMax && (
                                      <span className="text-danger ml-1">
                                        {error.CTC_MAX}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="mb-12">
                                  <label
                                    className="form-check-label"
                                    for="formCheck1"
                                    style={{
                                      lineHeight: "10px",
                                      fontWeight: "normal",
                                      color: "#f46d6d",
                                    }}
                                  >
                                    Specify Correct Salary to get Best Responses
                                  </label>
                                </div>
                              </div>
                              <div className="row">
                                <div className="mb-12">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="formCheck1"
                                    checked={isHideSalaryFromCandidate}
                                    value={isHideSalaryFromCandidate}
                                    onChange={(e) => {
                                      setIsHideSalaryFromCandidate(
                                        e.target.checked
                                      );
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    for="formCheck1"
                                  >
                                    &nbsp;Hide Salary from Job Seekers
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div >

                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label for="basicpill-firstname-input">
                                  Other Salary Details (If any)
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={otherSalaryDet}
                                  onChange={(e) => {
                                    setOtherSalaryDet(e.target.value);
                                  }}
                                  id="basicpill-phoneno-input"
                                  placeholder="Enter Other Salary Details"
                                />
                                {error && !otherSalaryDet && (
                                  <span className="text-danger ml-1">
                                    {error.OTHER_SALARY_DETAILS}
                                  </span>
                                )}
                              </div>
                            </div>
                            {/* <div className="col-lg-6">
                              <div className="mb-3">
                                <label for="basicpill-firstname-input">
                                  Job Location(s)
                                </label>
                                <Typeahead
                                  id="basic-example"
                                  onChange={(e) => {
                                    if (e && e.length > 0) {
                                      setLocation([...location, e[0]]);
                                    }
                                  }}
                                  options={cityList}
                                  placeholder="Choose a City..."
                                  //  selected={location[0]}
                                />
                              
                                 {
                        error && <span className='text-danger ml-1'>{error.CITY_ID}</span>
                          }
                              </div>
                            </div> */}
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label for="basicpill-firstname-input">
                                  Listing Type
                                </label>
                                <select
                                  className="form-control"
                                  value={listingType}
                                  onChange={(e) => {
                                    setListingType(e.target.value);
                                  }}
                                  id="basicpill-phoneno-input"
                                  placeholder="Select Listing Type"
                                >
                                  <option>Please select Listing Type</option>
                                  <option value="1">Regular</option>
                                  <option value="2">Featured</option>
                                  <option value="3">Premium</option>
                                </select>
                                {error && !listingType && (
                                  <span className="text-danger ml-1">
                                    {error.LISTING_TYPE}
                                  </span>
                                )}
                              </div>
                            </div>
                            <br />
                          </div>
                          {/* <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label for="basicpill-firstname-input">
                                  Select Job State(s)
                                </label>
                                <Typeahead
                                  id="state"
                                  onChange={(e) => {
                                    if (e && e.length > 0) {
                                      setSelectedStateList([
                                        ...selectedStateList,
                                        e[0],
                                      ]);
                                    }
                                  }}
                                  options={stateList}
                                  placeholder="Choose a State..."
                                  //  selected={location[0]}
                                />

                                {error && (
                                  <span className="text-danger ml-1">
                                    {error.STATE_ID}
                                  </span>
                                )}
                              </div>
                            </div>
                            {selectedStateList.length > 0 && (
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label for="basicpill-firstname-input">
                                    Selected State
                                  </label>
                                  <ul>
                                    {stateList.map((data, index) => {
                                      return selectedStateList.map((d, i) => {
                                        return data.STATE_ID == d.STATE_ID ? (
                                          <>
                                            <li>
                                              <div className="row">
                                                <div className="col-lg-4">
                                                  <b>{data.STATE}</b>
                                                </div>
                                                <div className="col-lg-4">
                                                  <button
                                                    type="button"
                                                    onClick={(e) => {
                                                      deleteLocation(
                                                        JOB_ID,
                                                        data.STATE_ID,
                                                        "STATE",
                                                        i,
                                                        d
                                                      );
                                                    }}
                                                    // data-bs-toggle="modal"
                                                    // data-bs-target="#staticBackdrop4"
                                                    className="btn-danger"
                                                    style={{
                                                      outline: "none",
                                                      border: "none",
                                                    }}
                                                  >
                                                    Delete
                                                  </button>
                                                </div>
                                                <div className="col-lg-4">
                                                  <button
                                                    type="button"
                                                    onClick={(e) => {
                                                      setStateDetails(data);
                                                      getCityListForJob(
                                                        d.STATE_ID
                                                      );
                                                    }}
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#staticBackdrop7"
                                                    className="btn-primary"
                                                    style={{
                                                      outline: "none",
                                                      border: "none",
                                                    }}
                                                  >
                                                    Add City
                                                  </button>
                                                </div>
                                              </div>
                                            </li>
                                            <br />
                                          </>
                                        ) : (
                                          ""
                                        );
                                      });
                                    })}
                                  </ul>
                                </div>
                              </div>
                            )}
                          </div> */}

                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label for="basicpill-lastname-input">
                                  City
                                </label>
                                {
                                  <Typeahead
                                    id="city"
                                    onChange={(e) => {
                                      if (e && e.length > 0) {
                                        let loc = [...location, e[0]];
                                        let r = loc.filter(
                                          (v, i, a) =>
                                            a.findIndex(
                                              (v2) => v2.CITY_ID === v.CITY_ID
                                            ) === i
                                        );
                                        setLocation(r);
                                      }
                                    }}
                                    options={cityList}
                                    placeholder="Choose a City..."
                                  //  selected={location[0]}
                                  />
                                }
                                {error && location && location.length == 0 && (
                                  <span className="text-danger ml-1">
                                    {error.CITY_ID}
                                  </span>
                                )}
                              </div>

                            </div>

                            {location.length > 0 && (
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label for="basicpill-firstname-input">
                                    Selected Cities
                                  </label>
                                  <ul>
                                    {location.map((d, i) => {
                                      return (
                                        <>
                                          <li>
                                            <div className="row">
                                              <div className="col-lg-10">
                                                <b>{d.CITY}</b>
                                              </div>
                                              <div className="col-lg-1">
                                                <i
                                                  className="bx bx-trash bx-xs"
                                                  style={{ color: "red" }}
                                                  onClick={(e) => {
                                                    deleteLocation(
                                                      JOB_ID,
                                                      d.CITY_ID,
                                                      "CITY",
                                                      i,
                                                      d
                                                    );
                                                  }}
                                                  title="Delete City"
                                                ></i>
                                              </div>
                                              <div className="col-lg-1">
                                                <i
                                                  className="bx bx-plus bx-xs"
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#staticBackdrop4"
                                                  onClick={(e) => {
                                                    setCityDetails(d);
                                                    getLocalityListForJob(
                                                      d.CITY_ID
                                                    );
                                                  }}
                                                  title="Add Locality"
                                                ></i>

                                                {/* <button
                                                    type="button"
                                                    
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#staticBackdrop4"
                                                    className="btn-danger"
                                                    style={{
                                                      outline: "none",
                                                      border: "none",
                                                    }}
                                                  >
                                                    Delete
                                                  </button> */}
                                              </div>
                                              {/* <div className="col-lg-4"> */}
                                              {/* <button
                                                    type="button"
                                                    onClick={(e) => {
                                                      setCityDetails(d);
                                                      getLocalityListForJob(
                                                        d.CITY_ID
                                                      );
                                                    }}
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#staticBackdrop4"
                                                    className="btn-primary"
                                                    style={{
                                                      outline: "none",
                                                      border: "none",
                                                    }}
                                                  >
                                                    Add Locality
                                                  </button> */}
                                              {/* </div> */}
                                            </div>
                                          </li>
                                          <br />
                                        </>
                                      );
                                    })}
                                  </ul>

                                </div>
                              </div>
                            )}
                            {selectedLocality.length > 0 && (
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label for="basicpill-firstname-input">
                                    Selected Locality
                                  </label>
                                  <ul>
                                    {selectedLocality.map((data, index) => {
                                      return (
                                        <>
                                          <li>
                                            <div className="row">
                                              <div className="col-lg-11">
                                                <b>{data.LOCALITY}</b>
                                              </div>
                                              <div className="col-lg-1">
                                                <i
                                                  className="bx bx-trash bx-xs"
                                                  style={{ color: "red" }}
                                                  onClick={(e) => {
                                                    deleteLocation(
                                                      JOB_ID,
                                                      data.LOCALITY_ID,
                                                      "LOCALITY",
                                                      index,
                                                      data
                                                    );
                                                  }}
                                                ></i>
                                                {/* <button
                                                  type="button"
                                                  onClick={(e) => {
                                                    deleteLocation(
                                                      JOB_ID,
                                                      data.LOCALITY_ID,
                                                      "LOCALITY",
                                                      index,
                                                      data
                                                    );
                                                  }}
                                                   data-bs-toggle="modal"
                                                   data-bs-target="#staticBackdrop4"
                                                  className="btn-danger"
                                                  style={{
                                                    outline: "none",
                                                    border: "none",
                                                  }}
                                                >
                                                  Delete
                                                </button> */}
                                              </div>
                                            </div>
                                          </li>
                                          <br />
                                        </>
                                      );
                                    })}
                                  </ul>
                                </div>
                              </div>
                            )}
                            <br />
                          </div>

                          <div className="row">
                            <div className="col-lg-3">
                              <div className="mb-3">
                                <label for="basicpill-firstname-input">
                                  Industry
                                </label>
                                <select
                                  className="form-select"
                                  value={industry}
                                  onChange={(e) => {
                                    setIndustry(e.target.value);
                                  }}
                                >
                                  <option>Select Industry Type</option>
                                  {industryList.map((data, index) => {
                                    return (
                                      <option
                                        value={data.INDUSTRY_ID}
                                        key={index}
                                      >
                                        {data.INDUSTRY}
                                      </option>
                                    );
                                  })}
                                </select>
                                {error && !industry && (
                                  <span className="text-danger ml-1">
                                    {error.INDUSTRY_ID}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="mb-3">
                                <label for="basicpill-firstname-input">
                                  Functional Area
                                </label>
                                <select
                                  className="form-select"
                                  value={functional}
                                  onChange={(e) => {
                                    setFunctional(e.target.value);
                                    getRoleForJob(e.target.value);
                                  }}
                                >
                                  <option>Select Functional Area</option>
                                  {functionalList.map((data, index) => {
                                    return (
                                      <option
                                        value={data.FUNCTIONAL_AREA_ID}
                                        key={index}
                                      >
                                        {data.FUNCTIONAL_AREA}
                                      </option>
                                    );
                                  })}
                                </select>
                                {error && !functional && (
                                  <span className="text-danger ml-1">
                                    {error.FUNCTIONAL_AREA_ID}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="mb-3">
                                <label for="basicpill-firstname-input">
                                  Role
                                </label>
                                <select
                                  className="form-select"
                                  value={role}
                                  onChange={(e) => {
                                    setRole(e.target.value);
                                  }}
                                >
                                  <option>Select Role</option>
                                  {rolelList.map((data, index) => {
                                    return (
                                      <option value={data.ROLE_ID} key={index}>
                                        {data.ROLE_NAME}
                                      </option>
                                    );
                                  })}
                                </select>
                                {error && !role && (
                                  <span className="text-danger ml-1">
                                    {error.ROLE_ID}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="mb-3">
                                <label for="basicpill-firstname-input">
                                  Reference Code
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={refCode}
                                  onChange={(e) => {
                                    setRefCode(e.target.value);
                                  }}
                                  id="basicpill-phoneno-input"
                                  placeholder="Enter Reference Code"
                                />
                                {error && !refCode && (
                                  <span className="text-danger ml-1">
                                    {error.REFERENCE_CODE}
                                  </span>
                                )}
                                <label
                                  className="form-check-label"
                                  for="formCheck1"
                                  style={{
                                    lineHeight: "10px",
                                    fontWeight: "normal",
                                    color: "#f46d6d",
                                  }}
                                >

                                  <br />
                                  Code to uniquely identify this Job.
                                </label>

                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label for="basicpill-firstname-input">
                                  Qualification Type
                                </label>
                                <Typeahead
                                  id="edu_qualification"
                                  onChange={(e) => {
                                    if (e && e.length > 0) {
                                      let edu = [...edu_qualification, e[0]];
                                      let r = edu.filter(
                                        (v, i, a) =>
                                          a.findIndex(
                                            (v2) =>
                                              v2.QUALIFICATION_ID ===
                                              v.QUALIFICATION_ID
                                          ) === i
                                      );
                                      setEdu_Qualification(r);
                                    }
                                  }}
                                  options={qualificationList}
                                  placeholder="Choose a Qualification Type..."
                                //  selected={location[0]}
                                />
                                {error && edu_qualification && edu_qualification.length == 0 && (
                                  <span className="text-danger ml-1">
                                    {error.EDUCATION_QUALIFICATION_ID}
                                  </span>
                                )}
                                {/* <select
                                  className="select2 form-control select2-multiple"
                                  multiple="multiple"
                                  data-placeholder="Choose ..."
                                  value={edu_qualification}
                                  onChange={(e) => {
                                    setEdu_Qualification([
                                      ...edu_qualification,
                                      e.target.value,
                                    ]);
                                  }}
                                >
                                  {qualificationList.map((data, index) => {
                                    return (
                                      <option
                                        value={data.QUALIFICATION_ID}
                                        key={index}
                                      >
                                        {data.QUALIFICATION_NAME}
                                      </option>
                                    );
                                  })}
                                </select> */}
                              </div>
                            </div>
                            {edu_qualification.length > 0 && (
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label for="basicpill-firstname-input">
                                    Selected Qualifications
                                  </label>
                                  <ul>
                                    {qualificationList.map((data, index) => {
                                      return edu_qualification.map((d, i) => {
                                        return data.QUALIFICATION_ID ==
                                          d.QUALIFICATION_ID ? (
                                          <>
                                            <li>
                                              <div className="row">
                                                <div className="col-lg-11">
                                                  <b>
                                                    {data.QUALIFICATION_NAME}
                                                  </b>
                                                </div>
                                                <div className="col-lg-1">
                                                  <i
                                                    className="bx bx-plus bx-xs"
                                                    onClick={(e) => {
                                                      setQualificationDetails(
                                                        data
                                                      );
                                                      getEducationQualificationTypeListForJob(
                                                        data.QUALIFICATION_ID
                                                      );
                                                    }}
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#staticBackdrop5"
                                                    title="Add Education Qualification"
                                                  ></i>
                                                </div>
                                              </div>
                                            </li>
                                            <br />
                                          </>
                                        ) : (
                                          ""
                                        );
                                      });
                                    })}
                                  </ul>
                                </div>
                              </div>
                            )}
                            <div className="row">
                              {selectedEduQualificationList &&
                                selectedEduQualificationList.length > 0 && (
                                  <div className="col-lg-6">
                                    <div className="mb-3">
                                      <label for="basicpill-firstname-input">
                                        Selected Education Qualification
                                      </label>
                                      <ul>
                                        {selectedEduQualificationList.map(
                                          (data, index) => {
                                            return (
                                              <>
                                                <li>
                                                  <div className="row">
                                                    <div className="col-lg-10">
                                                      <b>
                                                        {data.COURSE_STREAM}
                                                      </b>
                                                    </div>
                                                    <div className="col-lg-1">
                                                      <i
                                                        className="bx bx-trash bx-xs"
                                                        onClick={(e) => {
                                                          deleteQualification(
                                                            JOB_ID,
                                                            data.EDUCATION_QUALIFICATION_ID,
                                                            "EDUCATION_QUALIFICATION",
                                                            index,
                                                            data
                                                          );
                                                        }}
                                                        style={{ color: "red" }}
                                                        title="Delete Education Qualification"
                                                      ></i>
                                                    </div>
                                                    <div className="col-lg-1">
                                                      <i
                                                        className="bx bx-plus bx-xs"
                                                        onClick={(e) => {
                                                          setCourseSpeczDetails(
                                                            data
                                                          );
                                                          getCourseSpeczTypeListForJob(
                                                            data.EDUCATION_QUALIFICATION_ID
                                                          );
                                                        }}
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#staticBackdrop6"
                                                        title="Add Course
                                                    Specialization"
                                                      ></i>
                                                      {/* <button
                                                        type="button"
                                                        onClick={(e) => {
                                                          setCourseSpeczDetails(
                                                            data
                                                          );
                                                          getCourseSpeczTypeListForJob(
                                                            data.EDUCATION_QUALIFICATION_ID
                                                          );
                                                        }}
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#staticBackdrop6"
                                                        className="btn-primary"
                                                        style={{
                                                          outline: "none",
                                                          border: "none",
                                                        }}
                                                      >
                                                        Add Course
                                                        Specialization
                                                      </button> */}
                                                    </div>
                                                  </div>
                                                </li>
                                                <br />
                                              </>
                                            );
                                          }
                                        )}
                                      </ul>
                                    </div>
                                  </div>
                                )}
                              {selectedCourseSpeczList &&
                                selectedCourseSpeczList.length > 0 && (
                                  <div className="col-lg-6">
                                    <label>Selected Course</label>
                                    <ul>
                                      {selectedCourseSpeczList.map(
                                        (data, index) => {
                                          return (
                                            <>
                                              <li>
                                                <div className="row">
                                                  <div className="col-lg-11">
                                                    <b>{data.SPECIALIZATION}</b>
                                                  </div>
                                                  <div className="col-lg-1">
                                                    <i
                                                      className="bx bx-trash bx-xs"
                                                      onClick={(e) => {
                                                        deleteQualification(
                                                          JOB_ID,
                                                          data.SPECIALIZATION_ID,
                                                          "SPECIALIZATION",
                                                          index,
                                                          data
                                                        );
                                                      }}
                                                      style={{ color: "red" }}
                                                      title="Delete Course Specification"
                                                    ></i>
                                                    {/* <button
                                                      type="button"
                                                      onClick={(e) => {
                                                        deleteQualification(
                                                          JOB_ID,
                                                          data.SPECIALIZATION_ID,
                                                          "SPECIALIZATION",
                                                          index,
                                                          data
                                                        );
                                                      }}
                                                      data-bs-toggle="modal"
                                                      data-bs-target="#staticBackdrop4"
                                                      className="btn-danger"
                                                      style={{
                                                        outline: "none",
                                                        border: "none",
                                                      }}
                                                    >
                                                      Delete
                                                    </button> */}
                                                  </div>
                                                </div>
                                              </li>
                                              <br />
                                            </>
                                          );
                                        }
                                      )}
                                    </ul>
                                  </div>
                                )}
                            </div>
                          </div>

                          <h4
                            className="card-title mb-4"
                            style={{ marginTop: "14px", color: "#f46d6d" }}
                          >
                            WALK-IN DETAILS{" "}
                          </h4>

                          <div className="row">
                            <div className="col-lg-3">
                              <div className="mb-3">
                                <label for="basicpill-firstname-input">
                                  Include walk-in details
                                </label>
                                <select
                                  className="form-select"
                                  value={isWalkIn}
                                  onChange={(e) => {
                                    setIsWalkIn(!isWalkIn);
                                  }}
                                >
                                  <option value={false}>No</option>
                                  <option value={true}>Yes</option>
                                </select>
                              </div>
                            </div>
                            {isWalkIn && (
                              <>
                                <div className="col-lg-3">
                                  <div className="mb-3">
                                    <label for="userDate">
                                      Walk-in Start Date
                                    </label>
                                    <div className="docs-datepicker">
                                      <div className="input-group">
                                        <input
                                          type="date"
                                          id="userDate"
                                          className="form-control docs-date"
                                          value={startDate}
                                          onChange={(e) => {
                                            // greaterDate()
                                            setStartDate(e.target.value);
                                          }}
                                          name="date"
                                          min={new Date().toISOString().split("T")[0]}
                                          placeholder="Pick a date"
                                          autocomplete="off"
                                        />
                                        {/* <button
                                          type="button"
                                          className="btn btn-secondary docs-datepicker-trigger"
                                          disabled=""
                                        >
                                          <i
                                            className="mdi mdi-calendar"
                                            aria-hidden="true"
                                          ></i>
                                        </button> */}
                                      </div>
                                      {error && !startDate && (
                                        <span className="text-danger ml-1">
                                          {error.WALK_IN_START_DATE}
                                        </span>
                                      )}
                                      <div className="docs-datepicker-container"></div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-2">
                                  <div className="mb-3">
                                    <label for="basicpill-firstname-input">
                                      Duration (Days)
                                    </label>
                                    <select
                                      className="form-select"
                                      value={duration}
                                      onChange={(e) => {
                                        setDuration(e.target.value);
                                      }}
                                    >
                                      <option>Select</option>
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                      <option value="4">4</option>
                                      <option value="5">5</option>
                                      <option value="6">6</option>
                                      <option value="7">7</option>
                                      <option value="8">8</option>

                                    </select>
                                    {error && !duration && (
                                      <span className="text-danger ml-1">
                                        {error.DURATION}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="col-lg-2">
                                  <div className="mb-3">
                                    <label for="basicpill-firstname-input">
                                      Walk-in Timing
                                    </label>
                                    <div
                                      className="input-group"
                                      id="timepicker-input-group1"
                                    >
                                      <input
                                        id="timepicker"
                                        type="time"
                                        value={startTiming}
                                        onChange={(e) => {
                                          setStartTiming(e.target.value);
                                        }}
                                        className="form-control"
                                        data-provide="timepicker"
                                      />

                                      {/* <span className="input-group-text">
                                        <i className="mdi mdi-clock-outline"></i>
                                      </span> */}
                                    </div>
                                    {error && !startTiming && (
                                      <span className="text-danger ml-1">
                                        {error.WALK_IN_START_TIME}
                                      </span>
                                    )}
                                  </div>
                                </div>

                                <div className="col-lg-2">
                                  <div className="mb-3">
                                    <label for="basicpill-firstname-input">
                                      &nbsp;
                                    </label>
                                    <div
                                      className="input-group"
                                      id="timepicker-input-group2"
                                    >
                                      <input
                                        id="timepicker2"
                                        type="time"
                                        className="form-control"
                                        value={endTiming}
                                        onChange={(e) => {
                                          setEndTiming(e.target.value);
                                        }}
                                        data-provide="timepicker"
                                        min={startTiming}
                                      />

                                      {/* <span className="input-group-text">
                                        <i className="mdi mdi-clock-outline"></i>
                                      </span> */}
                                    </div>
                                    {error && !endTiming && (
                                      <span className="text-danger ml-1">
                                        {error.WALK_IN_END_TIME}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                          {
                            isWalkIn && (
                              <div className="row">
                                <div className="col-lg-3">
                                  <div className="mb-3">
                                    <label for="basicpill-firstname-input">
                                      Contact Person
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={contactPerson}
                                      onChange={(e) => {
                                        setContactPerson(e.target.value)
                                      }}
                                      id="basicpill-firstname-input"
                                      placeholder="Enter Contact Person"
                                    />
                                    {error && !contactPerson && (
                                      <span className="text-danger ml-1">
                                        {error.CONTACT_PERSON}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="col-lg-3">
                                  <div className="mb-3">
                                    <label for="basicpill-firstname-input">
                                      Mobile Number
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={mobile}
                                      pattern="^[6789][0-9]{9}"
                                      maxLength={10}
                                      onChange={(e) => {
                                        setMobile(e.target.value)
                                        const regexExp = /^[6789][0-9]{9}/
                                        if (regexExp.test(e.target.value)) {
                                          setIsMobNumValid(true)
                                        } else {
                                          setIsMobNumValid(false)
                                        }

                                      }}
                                      id="basicpill-firstname-input"
                                      placeholder="Enter Mobile Number"
                                    />
                                    {error && !isMobNumValid && (
                                      <span className="text-danger ml-1">
                                        {error.MOBILE_NUMBER}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="col-lg-3">
                                  <div className="mb-3">
                                    <label for="basicpill-firstname-input">
                                      Venue
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={Venue}
                                      onChange={(e) => {
                                        setVenue(e.target.value);
                                      }}
                                      id="basicpill-firstname-input"
                                      placeholder="Enter Venue"
                                    />
                                    {error && !Venue && (
                                      <span className="text-danger ml-1">
                                        {error.VENUE}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="col-lg-3">
                                  <div className="mb-3">
                                    <label for="basicpill-firstname-input">
                                      Google Map URL
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={googleMapUrl}
                                      onChange={(e) => {
                                        setGoogleMapUrl(e.target.value);
                                        let reg = /^https?\:\/\/((www|maps)\.)?google\.[a-z]+\/maps\/?\?([^&]+&)*(s?ll=-?[0-9]{1,2}\.[0-9]+,-?[0-9]{1,2}\.[0-9]+|q=[^&+])+($|&)/;
                                        if (reg.test(e.target.value)) { setIsValidGoogleMapUrl(true) } else { setIsValidGoogleMapUrl(false) }
                                      }}
                                      id="basicpill-firstname-input"
                                      placeholder="Enter Google Map URL"
                                    />
                                    {error && !isValidGoogleMapUrl && (
                                      <span className="text-danger ml-1">
                                        {error.GOOGLE_MAP_URL}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )
                          }

                          <h4
                            className="card-title mb-4"
                            style={{ marginTop: "14px", color: "#f46d6d" }}
                          >
                            COMPANY & CLIENT DETAILS
                          </h4>

                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label for="basicpill-firstname-input">
                                  Company
                                </label>
                                <Typeahead
                                  id="company"
                                  value={company}
                                  onChange={(e) => {
                                    if (e && e.length > 0) {
                                      setCompany(e[0].EMPLOYER_ID);
                                    }
                                  }}
                                  options={companyList}
                                  placeholder="Choose a Company Or Employer..."
                                //  selected={company}
                                />
                                {error && !company && (
                                  <span className="text-danger ml-1">
                                    {error.EMPLOYER_ID}
                                  </span>
                                )}
                                {/* <input
                                  type="text"
                                  value={company}
                                  onChange={(e) => {
                                    setCompany(e.target.value);
                                  }}
                                  className="form-control"
                                  id="basicpill-firstname-input"
                                  readonly
                                  placeholder="MTC"
                                /> */}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label for="basicpill-firstname-input">
                                  Client Name
                                </label>
                                <input
                                  type="text"
                                  value={clientName}
                                  onChange={(e) => {
                                    setClientName(e.target.value);
                                  }}
                                  className="form-control"
                                  id="basicpill-firstname-input"
                                  placeholder="Enter Client Name"
                                />
                                {error && !clientName && (
                                  <span className="text-danger ml-1">
                                    {error.CLIENT_NAME}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label for="basicpill-phoneno-input">
                                  Client Details
                                </label>
                                <textarea
                                  id="client_details"
                                  value={clientDetails}
                                  onChange={(e) => {
                                    setClientDetails(e.target.value);
                                  }}
                                  className="form-control"
                                  rows="3"
                                  placeholder="Enter Details"
                                ></textarea>
                                {error && !clientDetails && (
                                  <span className="text-danger ml-1">
                                    {error.CLIENT_DETAILS}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label for="basicpill-phoneno-input">
                                  Address
                                </label>
                                <textarea
                                  id="client_details"
                                  value={address}
                                  onChange={(e) => {
                                    setAddress(e.target.value);
                                  }}
                                  className="form-control"
                                  rows="3"
                                  placeholder="Enter Client Address"
                                ></textarea>
                                {error && !address && (
                                  <span className="text-danger ml-1">
                                    {error.ADDRESS}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>

                          <h4
                            className="card-title mb-4"
                            style={{ marginTop: "14px", color: "#f46d6d" }}
                          >
                            JOB QUESTIONS
                          </h4>


                          {
                            id && prevSelectedQuestion && prevSelectedQuestion.length > 0 && <div className="row">
                              <p className="col s12 helpText">
                                Selected Questions {" "}
                              </p>
                              {prevSelectedQuestion.map((d, i) => {
                                return (
                                  <div className="col-lg-6">
                                    <div className="mb-3">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        // id="formCheck1"
                                        checked={true}

                                      />
                                      <label
                                        className="form-check-label"
                                        for="formCheck1"
                                        data-bs-toggle="modal"
                                        data-bs-target="#staticBackdrop3"
                                      // onClick={(e) => {
                                      //   e.preventDefault();
                                      //   setQuestDetails(d);
                                      // }}
                                      >
                                        &nbsp;{d.QUESTION}
                                      </label>
                                    </div>
                                  </div>
                                )

                              })
                              }
                            </div>
                          }

                          <p className="col s12 helpText">
                            Select the Questions you want to associate with job{" "}
                          </p>
                          <div className="row">
                            {questionList.map((data, index) => {
                              return (
                                <div className="col-lg-6">
                                  <div className="mb-3">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      // id="formCheck1"
                                      value={data.checked}
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          let isAvailable =
                                            selectedQuestion.includes(
                                              data.QUESTION_ID
                                            );
                                          if (isAvailable) {
                                          } else {
                                            setSelectedQuestion([
                                              ...selectedQuestion,
                                              data.QUESTION_ID
                                            ]);
                                          }
                                        }
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      for="formCheck1"
                                      data-bs-toggle="modal"
                                      data-bs-target="#staticBackdrop3"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setQuestDetails(data);
                                      }}
                                    >
                                      &nbsp;{data.QUESTION}
                                    </label>
                                  </div>
                                </div>
                              );
                            })}
                            {error && selectedQuestion && selectedQuestion.length == 0 && (
                              <span className="text-danger ml-1 mb-1">
                                {error.QUESTIONS}
                              </span>
                            )}
                          </div>

                          <div
                            className="modal fade"
                            id="staticBackdrop5"
                            data-bs-backdrop="static"
                            data-bs-keyboard="false"
                            tabindex="-1"
                            role="dialog"
                            aria-labelledby="staticBackdropLabel"
                            aria-hidden="true"
                          >
                            <div
                              className="modal-dialog modal-dialog-centered modal-lg"
                              role="document"
                            >
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h5
                                    className="modal-title"
                                    id="staticBackdropLabel"
                                  >
                                    Select Education Qualification List
                                  </h5>
                                  <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>

                                <div className="modal-body">
                                  <p>
                                    <div className="col-lg-12">
                                      <div className="mb-3">
                                        <label for="basicpill-lastname-input">
                                          Education Qualification
                                        </label>
                                        {edu_qualification && (
                                          <Typeahead
                                            id="qualification"
                                            onChange={(e) => {
                                              if (e && e.length > 0) {
                                                let ed = [
                                                  ...selectedEduQualificationList,
                                                  e[0],
                                                ];
                                                let r = ed.filter(
                                                  (v, i, a) =>
                                                    a.findIndex(
                                                      (v2) =>
                                                        v2.EDUCATION_QUALIFICATION_ID ===
                                                        v.EDUCATION_QUALIFICATION_ID
                                                    ) == i
                                                );
                                                setSelectedEduQualificationList(
                                                  r
                                                );
                                                // if(ed.includes(e[0])){}else{
                                                //   setSelectedEduQualificationList(
                                                //     [
                                                //       ...selectedEduQualificationList,
                                                //       e[0],
                                                //     ]
                                                //   );
                                                // }
                                              }
                                            }}
                                            options={eduQualificationList}
                                            placeholder="Choose a Education Qualification Type..."
                                          //  selected={location[0]}
                                          />

                                          // <select
                                          //   className="form-select"
                                          //   onChange={(e) => {
                                          //     let local =
                                          //       eduQualificationList.filter(
                                          //         (data, index) => {
                                          //           return data.EDUCATION_QUALIFICATION_ID ==
                                          //             e.target.value
                                          //             ? data
                                          //             : "";
                                          //         }
                                          //       );
                                          //     setSelectedEduQualificationList([
                                          //       ...selectedEduQualificationList,
                                          //       local[0],
                                          //     ]);
                                          //   }}
                                          //   multiple
                                          // >
                                          //   <option>
                                          //     Select Locality of{" "}
                                          //     {
                                          //       qualificationDetails.QUALIFICATION_NAME
                                          //     }
                                          //   </option>
                                          //   {eduQualificationList.map(
                                          //     (data, index) => {
                                          //       return (
                                          //         <option
                                          //           value={
                                          //             data.EDUCATION_QUALIFICATION_ID
                                          //           }
                                          //           key={index}
                                          //         >
                                          //           {data.COURSE_STREAM}
                                          //         </option>
                                          //       );
                                          //     }
                                          //   )}
                                          // </select>
                                        )}
                                      </div>
                                    </div>
                                  </p>
                                </div>
                                <div className="modal-footer">
                                  <button
                                    type="button"
                                    className="btn btn-light"
                                    data-bs-dismiss="modal"
                                  >
                                    Close
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="modal fade"
                            id="staticBackdrop6"
                            data-bs-backdrop="static"
                            data-bs-keyboard="false"
                            tabindex="-1"
                            role="dialog"
                            aria-labelledby="staticBackdropLabel"
                            aria-hidden="true"
                          >
                            <div
                              className="modal-dialog modal-dialog-centered modal-lg"
                              role="document"
                            >
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h5
                                    className="modal-title"
                                    id="staticBackdropLabel"
                                  >
                                    Select Course
                                  </h5>
                                  <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>

                                <div className="modal-body">
                                  <p>
                                    <div className="col-lg-12">
                                      <div className="mb-3">
                                        <label for="basicpill-lastname-input">
                                          Course Specialization
                                        </label>
                                        {courseSpeczDetails && (
                                          <Typeahead
                                            id="course_specz"
                                            onChange={(e) => {
                                              if (e && e.length > 0) {
                                                let eds = [
                                                  ...selectedCourseSpeczList,
                                                  e[0],
                                                ];
                                                let r = eds.filter(
                                                  (v, i, a) =>
                                                    a.findIndex(
                                                      (v2) =>
                                                        v2.SPECIALIZATION_ID ===
                                                        v.SPECIALIZATION_ID
                                                    ) == i
                                                );
                                                setSelectedCourseSpezList(r);
                                                // if(eds.includes(e[0])){}else{
                                                //   setSelectedCourseSpezList([
                                                //     ...selectedCourseSpeczList,
                                                //     e[0],
                                                //   ]);
                                                // }
                                              }
                                            }}
                                            options={courseSpeczList}
                                            placeholder="Choose a Course Specialization..."
                                          //  selected={location[0]}
                                          />

                                          // <select
                                          //   className="form-select"
                                          //   onChange={(e) => {
                                          //     let local =
                                          //       courseSpeczList.filter(
                                          //         (data, index) => {
                                          //           return data.SPECIALIZATION_ID ==
                                          //             e.target.value
                                          //             ? data
                                          //             : "";
                                          //         }
                                          //       );
                                          //     setSelectedCourseSpezList([
                                          //       ...selectedCourseSpeczList,
                                          //       local[0],
                                          //     ]);
                                          //   }}
                                          //   multiple
                                          // >
                                          //   <option>
                                          //     Select Specialization{" "}
                                          //     {courseSpeczDetails.COURSE_STREAM}
                                          //   </option>
                                          //   {courseSpeczList.map(
                                          //     (data, index) => {
                                          //       return (
                                          //         <option
                                          //           value={
                                          //             data.SPECIALIZATION_ID
                                          //           }
                                          //           key={index}
                                          //         >
                                          //           {data.SPECIALIZATION}
                                          //         </option>
                                          //       );
                                          //     }
                                          //   )}
                                          // </select>
                                        )}
                                      </div>
                                    </div>
                                  </p>
                                </div>
                                <div className="modal-footer">
                                  <button
                                    type="button"
                                    className="btn btn-light"
                                    data-bs-dismiss="modal"
                                  >
                                    Close
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="modal fade"
                            id="staticBackdrop7"
                            data-bs-backdrop="static"
                            data-bs-keyboard="false"
                            tabindex="-1"
                            role="dialog"
                            aria-labelledby="staticBackdropLabel"
                            aria-hidden="true"
                          >
                            <div
                              className="modal-dialog modal-dialog-centered modal-lg"
                              role="document"
                            >
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h5
                                    className="modal-title"
                                    id="staticBackdropLabel"
                                  >
                                    Select City
                                  </h5>
                                  <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>

                                <div className="modal-body">
                                  <p>
                                    <div className="col-lg-12">
                                      <div className="mb-3">
                                        <label for="basicpill-lastname-input">
                                          City
                                        </label>
                                        {
                                          <Typeahead
                                            id="city"
                                            onChange={(e) => {
                                              if (e && e.length > 0) {
                                                setLocation([
                                                  ...location,
                                                  e[0],
                                                ]);
                                              }
                                            }}
                                            options={cityList}
                                            placeholder="Choose a City..."
                                          //  selected={location[0]}
                                          />
                                        }
                                      </div>
                                    </div>
                                  </p>
                                </div>
                                <div className="modal-footer">
                                  <button
                                    type="button"
                                    className="btn btn-light"
                                    data-bs-dismiss="modal"
                                  >
                                    Close
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="modal fade"
                            id="staticBackdrop4"
                            data-bs-backdrop="static"
                            data-bs-keyboard="false"
                            tabindex="-1"
                            role="dialog"
                            aria-labelledby="staticBackdropLabel"
                            aria-hidden="true"
                          >
                            <div
                              className="modal-dialog modal-dialog-centered modal-lg"
                              role="document"
                            >
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h5
                                    className="modal-title"
                                    id="staticBackdropLabel"
                                  >
                                    Select Locality
                                  </h5>
                                  <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>

                                <div className="modal-body">
                                  <p>
                                    <div className="col-lg-12">
                                      <div className="mb-3">
                                        <label for="basicpill-lastname-input">
                                          Locality
                                        </label>
                                        {cityDetails && (
                                          <Typeahead
                                            id="locality"
                                            onChange={(e) => {
                                              if (e && e.length > 0) {
                                                let loc = [
                                                  ...selectedLocality,
                                                  e[0],
                                                ];
                                                let r = loc.filter(
                                                  (v, i, a) =>
                                                    a.findIndex(
                                                      (v2) =>
                                                        v2.LOCALITY_ID ===
                                                        v.LOCALITY_ID
                                                    ) === i
                                                );
                                                setSelectedLocality(r);
                                                // if(loc.includes(e[0])){}else{
                                                //   setSelectedLocality([
                                                //     ...selectedLocality,
                                                //     e[0]
                                                //   ]);
                                                // }
                                              }
                                            }}
                                            options={localityLists}
                                            placeholder="Choose a Locality..."
                                          //  selected={location[0]}
                                          />
                                          // <select
                                          //   className="form-select"
                                          //   onChange={(e) => {
                                          //     let local = localityLists.filter(
                                          //       (data, index) => {
                                          //         return data.LOCALITY_ID ==
                                          //           e.target.value
                                          //           ? data
                                          //           : "";
                                          //       }
                                          //     );
                                          //     setSelectedLocality([
                                          //       ...selectedLocality,
                                          //       local[0],
                                          //     ]);
                                          //   }}
                                          //   multiple
                                          // >
                                          //   <option>
                                          //     Select Locality of{" "}
                                          //     {cityDetails.CITY}
                                          //   </option>
                                          //   {localityLists.map(
                                          //     (data, index) => {
                                          //       return (
                                          //         <option
                                          //           value={data.LOCALITY_ID}
                                          //           key={index}
                                          //         >
                                          //           {data.LOCALITY}
                                          //         </option>
                                          //       );
                                          //     }
                                          //   )}
                                          // </select>
                                        )}
                                      </div>
                                    </div>
                                  </p>
                                </div>
                                <div className="modal-footer">
                                  <button
                                    type="button"
                                    className="btn btn-light"
                                    data-bs-dismiss="modal"
                                  >
                                    Close
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="modal fade"
                            id="staticBackdrop3"
                            data-bs-backdrop="static"
                            data-bs-keyboard="false"
                            tabindex="-1"
                            role="dialog"
                            aria-labelledby="staticBackdropLabel"
                            aria-hidden="true"
                          >
                            <div
                              className="modal-dialog modal-dialog-centered modal-lg"
                              role="document"
                            >
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h5
                                    className="modal-title"
                                    id="staticBackdropLabel"
                                  >
                                    Question Details
                                  </h5>
                                  <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>

                                <div className="modal-body">
                                  <p>
                                    <table className="table table-striped mb-0">
                                      <tbody>
                                        {questDetails && (
                                          <>
                                            <tr>
                                              <td>{questDetails.QUESTION}</td>
                                            </tr>
                                            <tr>
                                              <td>
                                                &bull;{" "}
                                                {questDetails.ANSWER.OPTION1}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                &bull;{" "}
                                                {questDetails.ANSWER.OPTION2}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                &bull;{" "}
                                                {questDetails.ANSWER.OPTION3}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                &bull;{" "}
                                                {questDetails.ANSWER.OPTION4}
                                              </td>
                                            </tr>
                                          </>
                                        )}
                                      </tbody>
                                    </table>
                                  </p>
                                </div>
                                <div className="modal-footer">
                                  <button
                                    type="button"
                                    className="btn btn-light"
                                    data-bs-dismiss="modal"
                                  >
                                    Close
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <button
                                  type="button"
                                  className="btn btn-info waves-effect btn-label waves-light"
                                  onClick={handleSubmit}
                                >
                                  <i className="bx bx-check-double label-icon"></i>{" "}
                                  {id ? "Save Changes" : "Create Job"}
                                </button>
                                &nbsp;&nbsp;
                                <button
                                  type="button"
                                  className="btn btn-danger waves-effect btn-label waves-light"
                                  onClick={handleCancel}
                                >
                                  <i className="bx bx-block label-icon "></i>{" "}
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default AddJobs;
