import React from 'react'
import Pagination from 'react-js-pagination';
import moment from 'moment/moment';
import { useState } from 'react';
import { useEffect } from 'react';
import constant from '../../../constant';
import queryString from 'query-string'
import { useLocation, useNavigate } from 'react-router-dom';


const States = (props) => {


    const [currentPage, setCurrentPage] = useState(1);
    const loaction = useLocation()
    const Navigate = useNavigate()

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        Navigate(loaction.pathname + '?p=' + pageNumber)
        props.setPage(pageNumber)
    };

    useEffect(() => {
        document.title = constant.title.States
    })


    const searchList = () => {
        setCurrentPage(1)
        props.getStateList(1)
    }

    const qParams = queryString.parse(loaction.search)

    return (
        <React.Fragment>
            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0" style={{ fontSize: '18px' }}>State List</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="javascript: void(0);">Utilities</a></li>
                                            <li className="breadcrumb-item active">State List</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <form className="row gy-12 gx-12 align-items-center">
                                            <div className="col-sm-2">
                                                <label className="visually-hidden" for="autoSizingInput">
                                                    Name
                                                </label>
                                                Search State
                                            </div>
                                            <div className="col-sm-6">
                                                <label
                                                    className="visually-hidden"
                                                    for="autoSizingInputGroup"
                                                >
                                                    Username
                                                </label>

                                                <div className="input-group">
                                                    <input type="text"
                                                        onKeyDown={
                                                            (e) => {
                                                                if (e.key == 'Enter') {
                                                                    e.preventDefault()
                                                                    searchList()
                                                                }
                                                            }
                                                        } onChange={(e) => {
                                                            props.setKeyword(e.target.value)
                                                        }} className="form-control" id="autoSizingInputGroup" placeholder="Enter State, Keywords etc" />
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <button
                                                    type="button"
                                                    className="btn btn-success waves-effect btn-label waves-light"
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        searchList()
                                                    }}
                                                >
                                                    <i className="bx bx-check-double label-icon"></i> Search
                                                </button>
                                                &nbsp;

                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table align-middle table-nowrap table-hover">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope='col'>S.No</th>
                                                        <th scope="col">State ID</th>
                                                        <th scope="col">State</th>
                                                        <th scope="col">Country</th>
                                                        <th scope="col">Added On</th>

                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {props.List && props.List.length > 0
                                                        && props.List.map((ele, index, list) => {

                                                            return (
                                                                <tr>

                                                                    <td>{20 * currentPage + (index + 1) - 20}</td>


                                                                    <td>{ele.STATE_ID}</td>

                                                                    <td>
                                                                        <p className="text-muted mb-0">{ele.STATE}</p>
                                                                    </td>
                                                                    <td>{ele.COUNTRY}</td>

                                                                    <td>
                                                                        {moment(ele.CREATED_ON).format(
                                                                            "DD.MM.YYYY on HH:MM A"
                                                                        )}
                                                                    </td>


                                                                </tr>

                                                            )
                                                        })
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="pagination pagination-rounded justify-content-center mt-4">
                                                    <Pagination
                                                        activePage={qParams?.p ? + qParams.p :
                                                            currentPage}
                                                        itemsCountPerPage={20}
                                                        totalItemsCount={props.count}
                                                        pageRangeDisplayed={5}
                                                        onChange={handlePageChange}
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default States
