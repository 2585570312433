import React, { useEffect, useState } from "react";
import constant from "../../constant";
import profileImage from "../../assets/images/profile-img.png";
import adminIcon from "../../assets/images/admin-icon.png";
import jobzshala from "../../assets/images/jobzshala.png";
import { Link } from "react-router-dom";
import { getStorage, onChange, validateForm } from "../../utils";
import { login } from "../../actions/AuthActions";
import Swal from "sweetalert2";
import moment from "moment";


const Login = (props) => {

  const [remember, setRemember] = useState(true)
  const [show, setShow] = useState()
  const [email, setEmail] = useState({
    email: { name: "email", value: "", error: "", isRequired: true },
  });

  const [password, setPassword] = useState({
    password: { name: "password", value: "", error: "", isRequired: true },
  });

  const [mobile, setMobile] = useState({
    mobile: { name: "mobile", value: "", error: "", isRequired: false },
  });

  const [error, setError] = useState(false)
  const [passwordShow, setPasswordShow] = useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target
    if (name == "email") {
      setEmail((prevState) => ({
        ...prevState,
        email: {
          ...prevState.email,
          value: value,
        },
      }));
    }
    if (name == "password") {
      setPassword((prevState) => ({
        ...prevState,
        password: {
          ...prevState.password,
          value: value,
        },
      }));
    }
    if (name == "mobile") {
      setMobile((prevState) => ({
        ...prevState,
        mobile: {
          ...prevState.mobile,
          value: value,
        },
      }));
    }
  };

  const togglePasswordVisiblity = (e) => {
    setPasswordShow(!passwordShow);
  }



  const onSubmit = (e) => {
    e.preventDefault();
    if (email.email.value.length === 0 || password.password.value.length === 0) {
      setError(true)
      return false
    }

    const model = {
      EmailId: email.email.value,
      Password: password.password.value,
      REMEMBER_ME: remember
    };
    login(model).then((res) => {
      if (res.status) {
        Swal.fire({
          icon: "success",
          text: "You have Successfully Logged in",
          timer: 2000,
          showCancelButton: false,
          showConfirmButton: false,
        });
        window.location.href = constant.component.dashboard.url;
      } else {
        Swal.fire({
          icon: "error",
          // title: 'Oops...',
          text: res.error
            ? res.error
            : "Something went wrong!! Please contact system administrator.",
          timer: 2000,
          showCancelButton: false,
          showConfirmButton: false,
        });
      }
    });


  };
  useEffect(() => {
    document.title = constant.title.Login;
  }, []);

  let auth = getStorage(constant.keys.token)
  if (auth) {
    window.location.href = constant.component.dashboard.url;
  }
  return (
    <div className="auth-page-wrapper pt-5">
      {/* auth page bg */}
      <div className="auth-one-bg-position auth-one-bg" id="auth-particles">
        <div className="bg-overlay" />
        <div className="shape">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 1440 120"
          >
            <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z" />
          </svg>
        </div>
        <canvas
          className="particles-js-canvas-el"
          width={1898}
          height={475}
          style={{ width: "100%", height: "100%" }}
        />
      </div>
      {/* auth page content */}
      <div className="auth-page-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center mt-sm-5 text-white-50">
                <div>
                  <a href="/" className="d-inline-block auth-logo">
                    <img src={jobzshala} alt="" height={60} />
                  </a>
                </div>
                <p className=" fs-15 fw-medium">
                  Log in to access the JobzShala Administration Control Panel.
                </p>
              </div>
            </div>
          </div>
          {/* end row */}
          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5">
              <div className="card mt-4">
                <div className="card-body p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-hd-log">Welcome to Jobz<span>Shala!</span></h5>
                    <p className="text-muted">Log in to access the JobzShala Administration Control Panel.</p>
                  </div>
                  <div className="p-2 mt-4">
                    <form onSubmit={(e) => onSubmit(e)}>
                      <div className="mb-3">
                        <label htmlFor="username" className="form-label">
                          Email
                        </label>
                        <input
                          type="email"
                          value={email.value}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          name="email"

                          className={error && email.email.value.length == 0 ? 'form-control is-invalid ' : 'form-control'}
                          id="username"
                          placeholder="Enter Email"
                        />
                        {
                          error && email.email.value.length == 0 ? <span className='text-danger ml-1'>Please Enter Email</span>
                            : ""}
                      </div>
                      <div className="mb-3">

                        <label className="form-label" htmlFor="password-input">
                          Password
                        </label>
                        <div className="position-relative auth-pass-inputgroup mb-3">
                          <input
                            value={password.value}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            name="password"
                            type={(passwordShow) ? "text" : "password"}

                            className={error && password.password.value.length == 0 ? 'form-control is-invalid ' : 'form-control'}
                            placeholder="Enter password"
                            aria-label="Password"
                            aria-describedby="password-addon"
                          />
                          <button
                            className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                            type="button"
                            id="password-addon"
                          >
                            <i className="ri-eye-fill align-middle" />
                          </button>
                        </div>

                      </div>
                      {/* <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={remember}
                          onClick={() => { setRemember(!remember) }}
                          id="remember-check"
                        />
                        <label className="form-check-label" for="remember-check">
                          Remember me
                        </label>
                      </div> */}
                      <div className="mt-4">
                        <button className="btn btn-success btn-successlogin w-100" type="submit">
                          Login
                        </button>
                      </div>
                      <div className="float-end-center">
                        <Link
                          to={constant.component.forgotPassword.url}
                          className="text-muted"
                        >
                          Forgotten Your Password?
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
                {/* end card body */}
              </div>
              {/* end card */}
              {/* <div className="mt-4 text-center">
            <p className="mb-0">
              Don't have an account ?{" "}
              <a
                href="auth-signup-basic.html"
                className="fw-semibold text-primary text-decoration-underline"
              >
                {" "}
                Signup{" "}
              </a>{" "}
            </p>
          </div> */}
            </div>
          </div>
          {/* end row */}
        </div>
        {/* end container */}
      </div>
      {/* end auth page content */}
      {/* footer */}
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center">
                <p className="mb-0 text-muted">
                © {moment().format('YYYY')} JobzShala.com <i className="mdi mdi-heart text-danger" />{" "}
                    Powered by Akshaki India (P) Limited.
                   </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* end Footer */}
    </div>

    // <div className="account-pages pt-sm-5">
    //   <div className="container">
    //     <div className="row justify-content-center">
    //       <div className="col-md-8 col-lg-6 col-xl-5">
    //         <div className="card overflow-hidden">
    //           <div className="bg-primary bg-soft">
    //             <div className="row">
    //               <div className="col-7">
    //                 <div className="text-primary p-4">
    //                   <Link
    //                     to=""
    //                     style={{
    //                       color: "rgb(0 139 210)",
    //                       fontFamily: "nunito",
    //                       fontSize: "22px",
    //                       fontWeight: "700",
    //                     }}
    //                   >
    //                     JobzShala<span style={{ color: "rgb(255 205 6)" }}>.COM</span>
    //                   </Link>
    //                   <p style={{color: "#000000"}}>
    //                     Sign in to continue to JobzShala Administration Control
    //                     Panel.
    //                   </p>
    //                 </div>
    //               </div>
    //               <div className="col-5 align-self-end">
    //                 <img src={profileImage} alt="" className="img-fluid" />
    //               </div>
    //             </div>
    //           </div>
    //           <div className="card-body pt-0">
    //             <div className="auth-logo">
    //               <Link
    //                 to={constant.component.login.url}
    //                 className="auth-logo-light"
    //               >
    //                 <div className="avatar-md profile-user-wid mb-4">
    //                   <span className="avatar-title rounded-circle bg-light">
    //                     <img
    //                       src={adminIcon}
    //                       alt=""
    //                       className="rounded-circle"
    //                       height="70"
    //                     />
    //                   </span>
    //                 </div>
    //               </Link>

    //               <Link
    //                 to={constant.component.login.url}
    //                 className="auth-logo-dark"
    //               >
    //                 <div className="avatar-md profile-user-wid mb-4">
    //                   <span className="avatar-title rounded-circle bg-light">
    //                     <img
    //                       src={adminIcon}
    //                       alt=""
    //                       className="rounded-circle"
    //                       height="70"
    //                     />
    //                   </span>
    //                 </div>
    //               </Link>
    //             </div>
    //             <div className="p-2">
    //               <form
    //                 onSubmit={(e) => onSubmit(e)}
    //               >
    //                 <div className="mb-3">
    //                   <label className="form-label">Email</label>
    //                   <input
    //                     type="email"
    //                     value={email.value}
    //                     onChange={(e) => {
    //                       handleChange(e);
    //                     }}
    //                     name="email"

    //                     className={error && email.email.value.length == 0 ? 'form-control is-invalid ' : 'form-control'}
    //                     id="username"
    //                     placeholder="Enter Email"
    //                   />
    //                   {
    //                     error && email.email.value.length == 0 ? <span className='text-danger ml-1'>Please Enter Email</span>
    //                       : ""}
    //                 </div>

    //                 <div className="mb-3">
    //                   <label className="form-label">Password</label>
    //                   <div className="input-group auth-pass-inputgroup">
    //                     <input
    //                       value={password.value}
    //                       onChange={(e) => {
    //                         handleChange(e);
    //                       }}
    //                       name="password"
    //                       type={(passwordShow) ? "text" : "password"}

    //                       className={error && password.password.value.length == 0 ? 'form-control is-invalid ' : 'form-control'}
    //                       placeholder="Enter password"
    //                       aria-label="Password"
    //                       aria-describedby="password-addon"
    //                     />

    //                     <button
    //                       className="btn btn-light "
    //                       type="button"
    //                       id="password-addon"
    //                     >
    //                       <i className={(passwordShow) ? "fa fa-eye password-icon eyeicon" : "fa fa-eye-slash eyeicon"} onClick={(e) => togglePasswordVisiblity(e)}></i>
    //                     </button>
    //                   </div>
    //                   {
    //                     error && password.password.value.length <= 0 ? <span className='text-danger ml-1'>Please Enter password</span>
    //                       : ""}
    //                 </div>

    //                 <div className="form-check">
    //                   <input
    //                     className="form-check-input"
    //                     type="checkbox"
    //                     checked={remember}
    //                     onClick={() => { setRemember(!remember) }}
    //                     id="remember-check"
    //                   />
    //                   <label className="form-check-label" for="remember-check">
    //                     Remember me
    //                   </label>
    //                 </div>

    //                 <div className="mt-3 d-grid">
    //                   <button
    //                     className="btn btn-primary login-btn waves-effect waves-light"
    //                     type="submit"
    //                   >
    //                     Log In
    //                   </button>
    //                 </div>

    //                 <div className="mt-4 text-center">
    //                   <Link
    //                     to={constant.component.forgotPassword.url}
    //                     className="text-muted"
    //                   >
    //                     <i className="mdi mdi-lock me-1"></i> Forgot your
    //                     password?
    //                   </Link>
    //                 </div>
    //               </form>
    //             </div>
    //           </div>
    //         </div>
    //         <div className="text-center">
    //           <div>
    //             <p>
    //               <a href="https://www.jobzshala.com" target="_blank">
    //                 <img
    //                   src={jobzshala}
    //                   alt="jobzshala"
    //                   style={{ width: "112px", marginBottom: "12px" }}
    //                 />
    //               </a>
    //               <br /> Powered by 
    //                 Akshaki India (P) Limited.
    //             </p>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default Login;
