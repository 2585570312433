import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { employerDetailsByID, updateEmployer } from '../../actions/employer'
import Addemployers from '../../component/Employers/AddEmployers'
import constant from '../../constant'

export default function AddEmployers(props) {
    const Navigate = useNavigate();
    const [response, setResponse] = useState({})
    const [showMsg, setShowMsg] = useState(false)
    const [data, setData] = useState(null)
    const params = useParams()

    useEffect(() => {
          document.title = constant.title.EditEmployers

         employerDetailsByID(params.id).then(res => {
            if (res.status) {
                setData(res.result)
            }
            else {
                setResponse(res)
                setShowMsg(true)
                setTimeout(() => {
                    setShowMsg(false)
                }, 7000)
            }
        }).catch(err => {
            alert(err)
        })
    }, [])

    const onSubmit = (model) => {
        updateEmployer(model).then((res) => {
            if (res.status) {
                Navigate(constant.component.employerslist.url+"?p="+model.pageNo)
            }
            else {
                window.scrollTo(0, 0)
                setResponse(res)
                setShowMsg(true)
                setTimeout(() => {
                    setShowMsg(false)
                }, 7000)
            }

        }).catch((err) => {
            alert(err)
        })
    }
    return (
        <React.Fragment>
            {data && <Addemployers detail={data} onSubmit={onSubmit} response={response} showMsg={showMsg} />}
        </React.Fragment>
    )
}
