import React, { useState } from "react";
import constant from "../../constant";
import moment from "moment";
import queryString from 'query-string'
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";

import { useLocation, useNavigate } from "react-router-dom";

const BannerList = (props) => {

    const [currentPage, setCurrentPage] = useState(1);
    const location = useLocation()
    const Navigate = useNavigate()

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        Navigate(location.pathname + '?p=' + pageNumber)
        props.setPage(pageNumber)
    };

    const updateStatus = (BANNER_ID, STATUS) => {
        const model = {
            BANNER_ID: BANNER_ID,
            STATUS: STATUS === 'U' ? 'A' : 'U'
        }
        props.setPage(currentPage)
        props.updateStatus(model)
    }
    const qParams = queryString.parse(location.search)
        const { cpage } = props
    
        return (
            < React.Fragment >
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0 " style={{ fontSize: "18px" }}>
                                            Banner List
                                        </h4>

                                        {/* <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">
                                                    <a href="javascript: void(0);">Utilities</a>
                                                </li>
                                                <li className="breadcrumb-item active">Skills List</li>
                                            </ol>
                                        </div> */}
                                    </div>
                                </div>
                            </div>

                            

                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table className="table align-middle table-nowrap table-hover">
                                                    {/* {
                          props.showLoader &&
                          <Loader />
                        } */}
                                                    {!props.showLoader && <thead className="table-light">
                                                        <tr>
                                                            <th scope="col">S.No.</th>
                                                            <th scope="col">Banner Details</th>
                                                            <th scope="col">Add. Info</th>
                                                            <th scope="col">Company Details</th>
                                                            <th scope="col">Status</th>
                                                            <th scope="col" style={{ width: "100px" }}>
                                                                Action
                                                            </th>
                                                        </tr>
                                                    </thead>}
                                                    <tbody>

                                                        {props.List && props.List.length > 0
                                                            && props.List.map((ele, index, list) => {
                                                                return (
                                                                    <tr>
                                                                        <td>{currentPage == 1 ? `${index + 1}` : `${index == 9 ? currentPage : currentPage - 1}` + `${index == 9 ? 0 : index + 1}`}</td>
                                                                        <td>
                                                                    <p className="mb-2" style={{color:"red",fontSize:"15px"}}> <b>{ele.TITLE}</b></p>
                                                                    <p> <b>Page Location: </b>{ele.PAGE_LOCATION}</p> 
                                                                    <p> <b>Position: </b>{ele.POSITION}</p> 
                                                                    <p><b>Target Clicks: </b>{ele.TARGET_CLICK}</p>   
                                                                    <p><b>Total Clicks: </b>{ele.CLICK_COUNT.TOTAL_COUNT}</p>                                               
                                                                    <p> <b>Sort Number: </b>{ele.SORT_NUMBER}</p> 
                                                                                                                
                                                  
                                                                </td>
                                                                        <td>{ele.AD_TYPE == "I"?<a href="javascript: void(0);" title="View Thread" style={{ cursor: "pointer" }} data-bs-toggle="modal" data-bs-target={"#MODELIMAGE" + ele.BANNER_ID} ><img style={{width:"154px"}} src={`${"https://jobzshala.s3.ap-south-1.amazonaws.com/component/page_header/images/"}${ele.BANNER_FILE}`}></img></a>: ele.AD_TYPE=="T" ? <p style={{width:"300px",whiteSpace:"initial"}}>{ele.AD_TEXT}</p> :""}
                                                                        
                                                                        <div className="modal fade" id={"MODELIMAGE" + ele.BANNER_ID} tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                                                                                    <div className="modal-content">
                                                                                        <div className="modal-header">
                                                                                            <h5 className="modal-title" id="staticBackdropLabel">Banner Image</h5>

                                                                                        </div>

                                                                                        <div className="modal-body">
                                                                                            <p>
                                                                                                <table className="table table-striped mb-0">
                                                                                                    <tbody >
                                                                                                        <tr>
                                                                                                            
                                                                                                            <td ><img style={{height:"100%",width:"100%"}} src={`${"https://jobzshala.s3.ap-south-1.amazonaws.com/component/page_header/images/"}${ele.BANNER_FILE}`}></img></td>
                                                                                                        </tr>
                                                                                                    </tbody>
                                                                                                </table>
                                                                                            </p>
                                                                                        </div>

                                                                                        <div className="modal-footer">
                                                                                            <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        
                                                                        </td>



                                                                        <td>
                                                                        <p> <b>Company Name:</b>  {ele.COMPANY_NAME}</p> 
                                                                        <p> <b>Contact Person: </b>{ele.CONTACT_PERSON}</p> 
                                                                        <p> <b>Email: </b>{ele.COMPANY_EMAIL}</p> 
                                                                        <p> <b>Phone: </b>{ele.COMPANY_PHONE}</p> 
                                                                        <p> <b>Industry: </b>{ele.INDUSTRY}</p> 
                                                                        <p> <b>Address: </b>{ele.ADDRESS}</p> 
                                                                        </td>

                                                                        <td>{
                                                                            ele.STATUS == 'U' ?
                                                                                <button type="button" className="btn btn-success btn-sm waves-effect waves-light">ACTIVE</button> : <button type="button" className="btn btn-danger btn-sm waves-effect waves-light">ARCHIVE</button>
                                                                        }
                                                                        </td>
                                                                        <td>
                                                                            <ul className="list-inline contact-links mb-0" style={{ fontSize: "18px" }}>
                                                                                 
                                                                            <li className="list-inline-item px-2">
                                                                            <a href="javascript: void(0);" title="View Thread" style={{ cursor: "pointer" }} data-bs-toggle="modal" data-bs-target={"#MODELID" + ele.BANNER_ID} ><i className="bx bxs-zoom-in"></i></a>
                                                                            <div className="modal fade" id={"MODELID" + ele.BANNER_ID} tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                                                                                    <div className="modal-content">
                                                                                        <div className="modal-header">
                                                                                            <h5 className="modal-title" id="staticBackdropLabel">Banner Information</h5>

                                                                                        </div>

                                                                                        <div className="modal-body">
                                                                                            <p>
                                                                                                <table className="table table-striped mb-0">
                                                                                                    <tbody >
                                                                                                        <tr>
                                                                                                            <td><h5>  Company Name :  </h5></td>
                                                                                                            <td style={{ whiteSpace: "normal", display: "flex", textAlign: "left", fontSize: "14px" }}><p>{ele.COMPANY_NAME} </p></td>
                                                                                                        </tr>

                                                                                                        <tr>
                                                                                                            <td><h5>  Industry :  </h5></td>
                                                                                                            <td style={{ whiteSpace: "normal", display: "flex", textAlign: "left", fontSize: "14px" }}><p> {ele.INDUSTRY} </p></td>
                                                                                                        </tr>



                                                                                                        <tr>
                                                                                                            <td><h5>  Name:  </h5></td>
                                                                                                            <td style={{ whiteSpace: "normal", display: "flex", textAlign: "left", fontSize: "14px" }}><p>  {ele.CONTACT_PERSON} </p></td>
                                                                                                        </tr>

                                                                                                        <tr>
                                                                                                            <td><h5>  Mail Id:  </h5></td>
                                                                                                            <td style={{ whiteSpace: "normal", display: "flex", textAlign: "left", fontSize: "14px" }}><p> {ele.COMPANY_EMAIL}</p></td>
                                                                                                        </tr>

                                                                                                        <tr>
                                                                                                            <td><h5>  Phone Number:  </h5></td>
                                                                                                            <td style={{ whiteSpace: "normal", display: "flex", textAlign: "left", fontSize: "14px" }}><p>  {ele.COMPANY_PHONE}</p></td>
                                                                                                        </tr>

                                                                                                        <tr>
                                                                                                            <td><h5>  Target Click :  </h5></td>
                                                                                                            <td style={{ whiteSpace: "normal", display: "flex", textAlign: "left", fontSize: "14px" }}><p>{ele.TARGET_CLICK} </p></td>
                                                                                                        </tr>

                                                                                                        <tr>
                                                                                                            <td><h5>  Target Lead:  </h5></td>
                                                                                                            <td style={{ whiteSpace: "normal", display: "flex", textAlign: "left", fontSize: "14px" }}><p>{ele.TARGET_LEAD} </p></td>
                                                                                                        </tr>

                                                                                                        <tr>
                                                                                                            <td><h5>  Validity:  </h5></td>
                                                                                                            <td style={{ whiteSpace: "normal", display: "flex", textAlign: "left", fontSize: "14px" }}><p>{ele.VALIDITY_TYPE == "L"
                                                                                                            ? "Lifetime"
                                                                                                            : moment(
                                                                                                                ele.START_DATE
                                                                                                              ).format("DD.MM.YYYY") +
                                                                                                              " - " +
                                                                                                              moment(ele.END_DATE).format(
                                                                                                                "DD.MM.YYYY"
                                                                                                              )}  </p></td>
                                                                                                        </tr>
                                                                                                    </tbody>
                                                                                                </table>
                                                                                            </p>
                                                                                        </div>

                                                                                        <div className="modal-footer">
                                                                                            <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </li>

                                                                                <li className="list-inline-item px-2">
                                                                                    <Link to={constant.component.editbanner.url.replace(':id', ele.BANNER_ID).replace(':pageno', cpage)
                                                                                    } title="Edit Banner"><i className="bx bx-edit"></i></Link>
                                                                                </li>
                                                                                <li className="list-inline-item px-2">
                                                                                    <a href="javascript: void(0)"
                                                                                        onClick={() => updateStatus(ele.BANNER_ID, ele.STATUS)}
                                                                                        style={{ color: 'red' }} title={ele.STATUS == 'U' ? "Archive Record" : "Unarchive Record"}>
                                                                                        {
                                                                                            ele.STATUS == 'U' ? <i className="bx bx-trash"></i> : <i class="bx bx-log-in"></i>
                                                                                        }
                                                                                    </a>
                                                                                </li>
                                                                            </ul>
                                                                            <p className="mt-5"><Link to={constant.component.banneranalytics.url.replace(':id', ele.BANNER_ID)}><button className="btn btn-info">View analytics</button>   </Link>  </p>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                        {<>{props.List && props.List.length == 0 && props.showLoader == false && <td colSpan={10} className="text-center text-danger pt-3">No Record Found</td>}</>}
                                                    </tbody>
                                                </table>
                                            </div>
                                             
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment >

        )
    }

export default BannerList
