import React, { useEffect } from 'react'
import { addDesignation } from '../../../actions/utilitiesAction'
import Add from '../../../component/Utilities/Designation/AddDesignation'
import constant from '../../../constant'
const AddDesignation = () => {
    useEffect(() => {
        document.title = constant.title.AddDesignation
    }, [])
    const [response, setResponse] = React.useState({})
    const [showMsg, setShowMsg] = React.useState(false)

    const onSubmit = (model) => {
        addDesignation(model).then((res) => {
            setResponse(res)
            setShowMsg(true)
            setTimeout(() => {
                setShowMsg(false)
            }, 7000)
            // if (res.status)
            //     alert(res.messageCode)
        }).catch((err) => {
            alert(err)
        })
    }

    return (
        <React.Fragment>
            <Add onSubmit={onSubmit} response={response} showMsg={showMsg} />
        </React.Fragment>
    )
}

export default AddDesignation