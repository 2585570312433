import React from 'react'
import AddCategory from "../../../component/Utilities/Article/AddArticleCategory"
import { useState } from 'react'
import { addArticleCategory } from '../../../actions/utilitiesAction'

export default function AddArticleCategory() {

    const [response, setResponse] = useState({})
    const [showMsg, setShowMsg] = useState(false)

    const onSubmit = (model) => {
        addArticleCategory(model).then((res) => {
            setResponse(res)
            setShowMsg(true)
            setTimeout(() => {
                setShowMsg(false)
            }, 7000)
        }).catch((err) => {
            alert(err)
        })
    }


    return (
        <React.Fragment>
            <AddCategory onSubmit={onSubmit} response={response} showMsg={showMsg} />
        </React.Fragment>
    )
}
