import React, {useState} from 'react'
import Pagination from 'react-js-pagination'
import Loader from '../common/Loader'
import Swal from 'sweetalert2'
import ReactReadMoreReadLess from "react-read-more-read-less";
import Parser from 'html-react-parser'
import { nl2br } from 'react-js-nl2br';

export default function UnderMaintainanceEnquiryList(props){

const [currentPage, setCurrentPage] = useState(1)

const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
    props.setPage(pageNumber)
}

const deleteMaintainanceEnquiry = (ENQUIRY_ID) => {
    Swal.fire({
        title: 'Are you sure?',
        text: "You want to delete this comment? ",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
        if (result.isConfirmed) {
            props.deleteMaintainanceEnquiryLists(ENQUIRY_ID)
        }
    })

}

  return (
    <React.Fragment>
     <div className="main-content">

<div className="page-content">
    <div className="container-fluid">
        <div className="row">
            <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0" style={{ fontSize: "18px" }}>Under Maintainance Enquiries List</h4>

                    <div className="page-title-right">
                        <ol className="breadcrumb m-0">
                            <li className="breadcrumb-item"><a href="#">Operations</a></li>
                            <li className="breadcrumb-item active">Under Maintainance Enquiries List</li>
                        </ol>
                    </div>

                </div>
            </div>
        </div>

        <div className="row">
            <div className="col-lg-12">
                <div className="card">
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table align-middle table-nowrap table-hover">
                                {
                                    props.showLoader &&
                                    <Loader />
                                }
                                {!props.showLoader && <thead className="table-light">
                                    <tr >
                                        <th scope="col">S.No.</th>
                                        <th scope="col"style={{ textAlign: "center" }} >Name</th>
                                        <th scope="col"style={{ textAlign: "center" }}>Email</th>
                                        <th scope="col"style={{ textAlign: "center" }}>Phone</th>
                                        <th scope="col" style={{ textAlign: "center" }}>Description</th>
                                        <th scope="col" style={{ textAlign: "center" }}>Action</th>
                                        
                                    </tr>
                                </thead>}
                                <tbody>
                                    {props.list && props.list.length > 0 &&
                                        props.list.map((item, index, list) => {
                                                                                      
                                            return (
                                                <tr style={{ textAlign: "center" }}>
                                                    <td>
                                                        <div className="avatar-xs">
                                                            <span className="avatar-title rounded-circle">
                                                                {item.NAME.charAt(0).toUpperCase()}
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <h5 className="mb-1" style={{ fontSize: "14px", textTransform: "capitalize" }}><a href="javascript:void(0);" className="text-dark">{item.NAME}</a></h5>

                                                    </td>
                                                    <td>{item.EMAIL}</td>
                                                    <td>
                                                        {item.MOBILE}
                                                    </td>
                                                    <td style={{ maxWidth: '200px' }}>
                                                                       
                                                                        <ReactReadMoreReadLess
                                                                                charLimit={25}
                                                                                readMoreText={<a href="javascript: void(0);" className="text-primary">  </a>}
                                                                                readLessText={<a href="javascript: void(0);" className="text-primary"> </a>}
                                                                                >
                                                                                {item.MESSAGE}
                                                                            </ReactReadMoreReadLess>
                                                                        <a title="Click to View Download Details" data-bs-toggle="modal" style={{ cursor: "pointer", color: "blue" }} data-bs-target={"#MODELID" + item.ENQUIRY_ID} >See Details</a>

                                                                        <div className="modal fade" id={"MODELID" + item.ENQUIRY_ID}  tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                                                                                <div className="modal-content">
                                                                                    <div className="modal-header">
                                                                                        <h5 className="modal-title" id="staticBackdropLabel">Under Maintainance Enquiries</h5>
                                                                                       
                                                                                    </div>
                                                                                    <div className="modal-body">
                                                                                        <p>
                                                                                            <table className="table table-striped mb-0">
                                                                                                <tbody>
                                                                                                    <tr>
                                                                                                        <td> User Information : </td>
                                                                                                        <td style={{textAlign: "left"}}>
                                                                                                        <p> <i className="bx bx-user font-size-16 align-middle me-1"> <span style={{ color: "#0080ff " }}>  {item.NAME}</span></i> </p>
                                                                                                        <p> <i className="bx bx-envelope font-size-16 align-middle me-1"> <span style={{ color: "#0080ff " }}>   {item.EMAIL}</span></i> </p>
                                                                                                        <p> <i className="bx bxs-contact font-size-16 align-middle me-1"> <span style={{ color: "#0080ff " }}>   {item.MOBILE}</span></i> </p>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>Description :  </td>
                                                                                                        <td style={{whiteSpace:"normal", display: "flex", textAlign:"left"}}> {nl2br(item.MESSAGE)} </td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </p>
                                                                                    </div>
                                                                                   
                                                                                    <div className="modal-footer">
                                                                                        <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                    <td>
                                                        <ul className="list-inline contact-links mb-0" style={{ fontSize: "20px" }}>
                                                            <li className="list-inline-item px-2">
                                                                <a href="javascript:void(0);"
                                                                 onClick={() => deleteMaintainanceEnquiry(item.ENQUIRY_ID)}
                                                                  style={{ color: "red" }} title="Delete User"><i className="bx bx-trash"></i></a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    {
                                        <>
                                            {
                                                props.list && props.list.length == 0 && props.showLoader == false &&
                                                <td colSpan={10} className="text-center text-danger pt-3">No Maintainance Enquiries Submitteed !</td>
                                            }
                                        </>
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <ul className="pagination pagination-rounded justify-content-center mt-4">
                                    <Pagination
                                        activePage={currentPage}
                                        itemsCountPerPage={10}
                                        totalItemsCount={props.count}
                                        pageRangeDisplayed={5}
                                        onChange={handlePageChange}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                    />
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
    </React.Fragment>
  )
}

