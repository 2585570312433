import * as React from 'react';
import { Spinner } from 'reactstrap';
// import Spinner from '../../assets/images/spinner.png';

const Loader = () => {
    return (
        // <div className='loader-container'>
        //     <img alt="Loading..." className='loader' src={Spinner} />
        // </div>
        // <div className='d-flex justify-content-center allign-item-center'>
        //     <Spinner animation="border" role="status">
        //         <span className="visually-hidden">Loading...</span>
        //     </Spinner>
        // </div>

        <React.Fragment>
            <div style={{
                width: "80vw", height: "70vh", display: "flex", alignItems: "center",
                justifyContent: "center"
            }}>
                <Spinner animation="border" variant="info"
                    style={{ width: '3rem', height: '3rem' }} />

            </div>
        </React.Fragment>

    );
}

export default Loader;