import React, { Children, useRef } from "react";
import { addFaq, updateFaq } from "../../actions/utilitiesAction";
import constant from "../../constant";
import QualificationLists from "../../component/jobs/QualificationList";
import { archiveEducationQualification, createEducationQualification, createIndustry, createRole, updateEducationQualification, updateIndustry, updateRole } from "../../actions/JobsAction";
import QuestionLists from "../../component/jobs/QuestionList";
import RoleLists from "../../component/jobs/RoleList";
import AddRoles from "../../component/jobs/AddRole";
import AddFunctionalAreas from "../../component/jobs/AddFunctionalArea";
import AddIndustrys from "../../component/jobs/AddIndustry";
import { useNavigate } from "react-router-dom";

const EditJobIndustry = () => {
    const [response, setResponse] = React.useState({})
    const [showMsg, setShowMsg] = React.useState(false)

    const navigation = useNavigate();

    const onSubmit = (model) => {
        if (model.type === 'ADD') {
            // createIndustry(model).then((res) => {
            //     setResponse(res)
            //     setShowMsg(true)
            //     setTimeout(() => {
            //         setShowMsg(false)
            //     }, 7000)
            // }).catch((err) => {
            //     alert(err)
            // })
        } else {
            updateIndustry(model).then((res) => {
                if (res.status) {
                    navigation(constant.component.listIndustry.url + '?p=' + model.pageno)
                }
                else {
                    setResponse(res)
                    setShowMsg(true)
                    setTimeout(() => {
                        setShowMsg(false)
                    }, 7000)
                }
            }).catch((err) => {
                alert(err)
            })
        }

    }


    return (
        <React.Fragment>
            <AddIndustrys onSubmit={(data) => onSubmit(data)} response={response} showMsg={showMsg} />
        </React.Fragment>
    )
}

export default EditJobIndustry;