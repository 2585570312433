import React from "react";
import { addFaq, updateFaq } from "../../actions/utilitiesAction";
import constant from "../../constant";
import AddQualifications from "../../component/jobs/AddQualification";
import { createEducationQualification, updateEducationQualification } from "../../actions/JobsAction";
import { useNavigate } from "react-router-dom";

const EditQualification = () => {

    const navigation = useNavigate();

    const [response, setResponse] = React.useState({})
    const [showMsg, setShowMsg] = React.useState(false)


    const onSubmit = (model) => {
        if (model.type === 'ADD') {
            // createEducationQualification(model).then((res) => {
            //     setResponse(res)
            //     setShowMsg(true)
            //     setTimeout(() => {
            //         setShowMsg(false)
            //     }, 7000)
            // }).catch((err) => {
            //     alert(err)
            // })
        } else {
            updateEducationQualification(model).then((res) => {
                if (res.status) {
                    navigation(constant.component.listQualification.url + '?p=' + model.pageno)
                }
                else {
                    setResponse(res)
                    setShowMsg(true)
                    setTimeout(() => {
                        setShowMsg(false)
                    }, 7000)
                }
            }).catch((err) => {
                alert(err)
            })
        }

    }
    return (
        <React.Fragment>
            <AddQualifications onSubmit={(data) => onSubmit(data)} response={response} showMsg={showMsg} />
        </React.Fragment>
    )
}

export default EditQualification;