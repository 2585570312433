import React, { Component } from 'react'
import { onChange, setOptions, validateForm } from '../../../utils';
import { cityList, stateList } from '../../../actions/utilitiesAction';
import Swal from 'sweetalert2';
import { uploadUniversityLogo } from '../../../actions/campus';
import NumberFormat from 'react-number-format';

export default class AddUniversity extends Component {
  constructor(props) {
    super(props);
    const { universityeData, state, city } = props?.data;
    const { id } = props
    debugger
    this.state = {
      UNIVERSITY_NAME: { name: 'UNIVERSITY_NAME', value: universityeData ? universityeData.UNIVERSITY_NAME : '', error: '', isRequired: true },
      EMAIL: { name: 'EMAIL', value: universityeData ? universityeData.EMAIL : '', error: '', isRequired: true },
      PHONE: { name: 'PHONE', value: universityeData ? universityeData.PHONE : '', error: '', isRequired: true },
      PIN_CODE: { name: 'PIN_CODE', value: universityeData ? universityeData.PIN_CODE : '', error: '', isRequired: true },
      ADDRESS: { name: 'ADDRESS', value: universityeData ? universityeData.ADDRESS : '', error: '', isRequired: true },
      CITY: { name: 'CITY', value: universityeData ? universityeData.CITY : '', options: city ? city : [], error: '', isRequired: true },
      STATE: { name: 'STATE', value: universityeData ? universityeData.STATE : '', options: [], error: '', isRequired: true },
      SPOC_NAME: { name: 'SPOC_NAME', value: universityeData ? universityeData.SPOC_NAME : '', error: '', isRequired: true },
      SPOC_EMAIL: { name: 'SPOC_EMAIL', value: universityeData ? universityeData.SPOC_EMAIL : '', error: '', isRequired: true },
      SPOC_PHONE: { name: 'SPOC_PHONE', value: universityeData ? universityeData.SPOC_PHONE : '', error: '', isRequired: true },
      LOGO: { name: 'LOGO', value: universityeData ? universityeData.LOGO : '', error: '', isRequired: true },
      UNIVERSITY_TYPE: { name: 'UNIVERSITY_TYPE', value: universityeData ? universityeData.UNIVERSITY_TYPE : 'Private', error: '', isRequired: true },
      DESCRIPTION: { name: 'DESCRIPTION', value: universityeData ? universityeData.DESCRIPTION : '', error: '', isRequired: false },
      id
    }
  }


  onFileChange = (e) => {
    let files = e.target.files
    if (files && files.length) {
      if (files[0].size <= 5000000 && (files[0].type.includes("image"))) {
        const formData = new FormData();
        formData.append('file', files[0])
        uploadUniversityLogo(formData).then(res => {
          if (res.status) {
            onChange(this, this.state.LOGO.name, res.result.fileName)
          }
        })
      }
      else {
        onChange(this, this.state.LOGO.name, null)
        Swal({
          icon: "error",
          text: "File size must be less than 5 MB. File must be Image.",
          timer: 2000,
          showCancelButton: false,
          showConfirmButton: false,
        });
      }

    }
  }
  onChange(e) {
    const { name, value } = e.target;
    if (name === 'STATE') {
      cityList(value).then(res => {
        setOptions(this, 'CITY', res.result.list)
      }).catch(err => {
        alert(err)
      })
    }
    onChange(this, name, value)
  }
  componentDidMount() {
    stateList('102').then(res => {
      setOptions(this, 'STATE', res.result.list)
    }).catch(err => {
      alert(err)
    })
  }


  onSubmit(e) {
    e.preventDefault();
    if (validateForm(this)) {
      const { UNIVERSITY_NAME, EMAIL, PHONE, ADDRESS, CITY, STATE, SPOC_NAME, SPOC_EMAIL, SPOC_PHONE, LOGO, UNIVERSITY_TYPE, PIN_CODE, DESCRIPTION } = this.state;

      debugger
      const model = {
        UNIVERSITY_NAME: UNIVERSITY_NAME.value,
        EMAIL: EMAIL.value,
        PHONE: PHONE.value,
        ADDRESS: ADDRESS.value,
        CITY: CITY.value,
        STATE: STATE.value,
        SPOC_NAME: SPOC_NAME.value,
        SPOC_EMAIL: SPOC_EMAIL.value,
        SPOC_PHONE: SPOC_PHONE.value,
        LOGO: LOGO.value,
        UNIVERSITY_TYPE: UNIVERSITY_TYPE.value,
        PIN_CODE: PIN_CODE.value,
        DESCRIPTION: DESCRIPTION.value
      }
      this.props.onSubmit(model)
    }
  }


  render() {
    const { id, UNIVERSITY_NAME, EMAIL, PHONE, ADDRESS, CITY, STATE, SPOC_NAME, SPOC_EMAIL, SPOC_PHONE, LOGO, UNIVERSITY_TYPE, PIN_CODE, DESCRIPTION } = this.state;
    return (
      <React.Fragment>
        <div className='main-content'>
          <div className='page-content'>
            <div className='container-fluid'>
              <div class="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0">{id ? 'Edit' : 'Add'} University</h4>

                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item"><a href="javascript: void(0);">Campus</a></li>
                        <li className="breadcrumb-item active">{id ? 'Edit' : 'Add'} University</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <div id="basic-example">
                        <section>
                          <form onSubmit={(e) => { this.onSubmit(e) }} autocomplete="on">
                            <h4 className="card-title mb-4" style={{ marginTop: "16px", color: "#f46d6d" }}>UNIVERSITY INFORMATION </h4>
                            <div className="row">
                              <div className="col-lg-3">
                                <div className="mb-3">
                                  <label for="basicpill-firstname-input">University Name <span className='text-danger'>*</span></label>
                                  <input
                                    type="text"
                                    className={!UNIVERSITY_NAME.error.length ? "form-control" : "form-control is-invalid"}
                                    name={UNIVERSITY_NAME.name}
                                    value={UNIVERSITY_NAME.value}
                                    onChange={(e) => { this.onChange(e) }}
                                    placeholder="Enter University Name"
                                  />

                                </div>
                              </div>
                              <div className="col-lg-3">
                                <div className="mb-3">
                                  <label for="basicpill-firstname-input">Email <span className='text-danger'>*</span></label>
                                  <input
                                    type="text"
                                    className={!EMAIL.error.length ? "form-control" : "form-control is-invalid"}
                                    name={EMAIL.name}
                                    value={EMAIL.value}
                                    onChange={(e) => { this.onChange(e) }}
                                    placeholder="Enter Email"
                                  />

                                </div>
                              </div>
                              <div className="col-lg-3">
                                <div className="mb-3">
                                  <label for="basicpill-firstname-input">Phone No<span className='text-danger'>*</span></label>
                                  <input
                                    type="text"
                                    className={!PHONE.error.length ? "form-control" : "form-control is-invalid"}
                                    name={PHONE.name}
                                    value={PHONE.value}
                                    onChange={(e) => { this.onChange(e) }}

                                    placeholder="Enter Phone No"
                                  />

                                </div>
                              </div>
                              <div className="col-lg-3">
                                <div className="mb-3">
                                  <label for="basicpill-address-input">SPOC Name<span className='text-danger'>*</span></label>
                                  <input
                                    type="text"
                                    className={!SPOC_NAME.error.length ? "form-control" : "form-control is-invalid"}
                                    name={SPOC_NAME.name}
                                    value={SPOC_NAME.value}
                                    onChange={(e) => { this.onChange(e) }}

                                    placeholder="Enter SPOC Name"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3">
                                <div className="mb-3">
                                  <label for="basicpill-address-input">SPOC Email<span className='text-danger'>*</span></label>
                                  <input
                                    type="text"
                                    className={!SPOC_EMAIL.error.length ? "form-control" : "form-control is-invalid"}
                                    name={SPOC_EMAIL.name}
                                    value={SPOC_EMAIL.value}
                                    onChange={(e) => { this.onChange(e) }}

                                    placeholder="Enter SPOC Email"
                                  />
                                </div>
                              </div>

                              <div className="col-lg-3">
                                <div className="mb-3">
                                  <label for="basicpill-address-input">SPOC Phone<span className='text-danger'>*</span></label>
                                  <input
                                    type="text"
                                    className={!SPOC_PHONE.error.length ? "form-control" : "form-control is-invalid"}
                                    name={SPOC_PHONE.name}
                                    value={SPOC_PHONE.value}
                                    onChange={(e) => { this.onChange(e) }}
                                    placeholder="Enter SPOC Phone"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3">
                                <div className="mb-3">
                                  <label for="basicpill-address-input">University Type<span className='text-danger'>*</span></label>
                                  <div className="row">
                                    <div className='col-1'>
                                      <input
                                        type="radio"
                                        className={"form-check"}
                                        checked={UNIVERSITY_TYPE.value === 'Private'}
                                        name={UNIVERSITY_TYPE.name}
                                        value={'Private'}
                                        onChange={(e) => { this.onChange(e) }}

                                      />
                                      <input
                                        type="radio"
                                        className={"form-check"}
                                        checked={UNIVERSITY_TYPE.value === 'Government'}
                                        name={UNIVERSITY_TYPE.name}
                                        value={'Government'}
                                        onChange={(e) => { this.onChange(e) }}

                                      />
                                    </div>
                                    <div className='col-11'>
                                      <span>Private</span>
                                      <br />
                                      <span>Government</span>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-3">
                                <div className="mb-3">
                                  <label for="basicpill-address-input">Address<span className='text-danger'>*</span></label>
                                  <input
                                    type="text"
                                    className={!ADDRESS.error.length ? "form-control" : "form-control is-invalid"}
                                    name={ADDRESS.name}
                                    value={ADDRESS.value}
                                    onChange={(e) => { this.onChange(e) }}

                                    placeholder="Enter Address"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3">
                                <div className="mb-3">
                                  <label for="basicpill-address-input">State<span className='text-danger'>*</span></label>
                                  <select
                                    type="text"
                                    className={!STATE.error.length ? "form-select" : "form-select is-invalid"}
                                    name={STATE.name}
                                    value={STATE.value}
                                    onChange={(e) => { this.onChange(e) }}
                                  >
                                    <option value=''>Select State</option>
                                    {STATE.options.map((item) => {
                                      return (<option value={item.STATE_ID}>{item.STATE}</option>)
                                    })}
                                  </select>
                                </div>
                              </div>
                              <div className="col-lg-3">
                                <div className="mb-3">
                                  <label for="basicpill-address-input">City<span className='text-danger'>*</span></label>
                                  <select
                                    type="text"
                                    className={!CITY.error.length ? "form-select" : "form-select is-invalid"}
                                    name={CITY.name}
                                    value={CITY.value}
                                    onChange={(e) => { this.onChange(e) }}
                                  >
                                    <option value=''>Select City</option>
                                    {CITY.options.map((item) => {
                                      return (<option value={item.CITY_ID}>{item.CITY}</option>)
                                    })}
                                  </select>
                                </div>
                              </div>
                              <div className="col-lg-3">
                                <div className="mb-3">
                                  <label for="basicpill-address-input">Pin Code<span className='text-danger'>*</span></label>
                                  <NumberFormat
                                    className={!PIN_CODE.error.length ? "form-control" : "form-control is-invalid"}
                                    name={PIN_CODE.name}
                                    value={PIN_CODE.value}
                                    onChange={(e) => { this.onChange(e) }}
                                    placeholder="Enter Pin Code"
                                  />
                                </div>
                              </div>

                              <div className="col-lg-3">
                                <div className="mb-3">
                                  <label for="basicpill-address-input">Logo<span className='text-danger'>*</span></label>
                                  <input
                                    type="file"
                                    className={!LOGO.error.length ? "form-control" : "form-control is-invalid"}
                                    name={LOGO.name}
                                    onChange={(e) => { this.onFileChange(e) }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-lg-12">
                                <div className="mb-3">
                                  <label for="basicpill-address-input">Description</label>
                                  <textarea
                                    type="text"
                                    row={3}
                                    className={!DESCRIPTION.error.length ? "form-control" : "form-control is-invalid"}
                                    name={DESCRIPTION.name}
                                    value={DESCRIPTION.value}
                                    onChange={(e) => { this.onChange(e) }}
                                    placeholder="Enter Description"
                                  ></textarea>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <button type="submit" className="btn btn-info waves-effect btn-label waves-light"><i className="bx bx-check-double label-icon"></i> {id ? 'Edit' : 'Add'} University</button>
                                  &nbsp;&nbsp;
                                  <button type="reset" className="btn btn-danger waves-effect btn-label waves-light"><i className="bx bx-block label-icon "></i> Cancel</button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </section>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </React.Fragment >
    )
  }
}
