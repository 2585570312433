import React, { useEffect } from "react";
import NumberFormat from "react-number-format";
import { useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { FAQCategorybyId, JobsFAQCategorybyId } from "../../../actions/utilitiesAction";
import SuccessMessage from "../../common/SuccessMessage";
import ErrorMessage from "../../common/ErrorMessage";
import constant from "../../../constant";


export default function AddCategory(props) {
  const { id } = useParams()
  const [CATEGORY_ID, setCatId] = useState(null)
  const navigate = useNavigate()
  const [category, setCategory] = useState({
    CATEGORY: "",
    SORT_NUMBER: "",
    CATEGORY_DETAILS: "",
  });

  useEffect(() => {
    document.title = constant.title.FaqAddCategory
  })
  const handleReset = (e) => {
    e.preventDefault();
    id && navigate(constant.component.jobsfaqlist.url)
    setError(false)
    setCategory({
      CATEGORY: "",
      CATEGORY_DETAILS: "",
      SORT_NUMBER: '',
    });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCategory({
      ...category,
      [name]: value,
    });
  };

  const [error, setError] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    if (category.CATEGORY.trim().length == 0 || category.SORT_NUMBER.toString().trim().length == 0) {
      setError(true);
      return false
    } else {
      if (id) {
        const model = {
          CATEGORY_ID: CATEGORY_ID,
          CATEGORY: category.CATEGORY,
          SORT_NUMBER: category.SORT_NUMBER,
          CATEGORY_DETAILS: category.CATEGORY_DETAILS,
          type: 'UPDATE'
        };
        props.onSubmit(model);
      } else {
        const model = {
          CATEGORY: category.CATEGORY,
          SORT_NUMBER: category.SORT_NUMBER,
          CATEGORY_DETAILS: category.CATEGORY_DETAILS,
          type: 'ADD'
        };
        props.onSubmit(model);
      }

    }
  };
  useEffect(() => {
    (id ? document.title = constant.title.UpdateFaqCategory : document.title = constant.title.FaqAddCategory)
  }, [])
  useEffect(() => {
    id && getCategoryDetails(id)

  }, [])

  const getCategoryDetails = (id) => {
    debugger
    JobsFAQCategorybyId({ CATEGORY_ID: id }).then((res) => {
      if (res.status) {
        setCategory({
          CATEGORY: res.result.CATEGORY,
          CATEGORY_DETAILS: res.result.CATEGORY_DETAILS,
          SORT_NUMBER: res.result.SORT_NUMBER,
        });
        setCatId(res.result.CATEGORY_ID)
      }

    }).catch((err) => {
      alert(err)
    })
  }

  return (
    <React.Fragment>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">{id ? "Update Job's FAQ Category" : "Add Job's FAQ Category"}</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);"> Jobs FAQs </a>
                      </li>
                      <li className="breadcrumb-item active">
                        {id ? "Update Jobs FAQ Category" : "Add Jobs FAQ Category"}
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* end page title */}
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title mb-4">Category Information</h4>
                    {
                      props.showMsg && (props.response.status ?
                        <SuccessMessage message={props.response.messageCode} />
                        :
                        <ErrorMessage message={props.response.error} />)
                    }
                    <div id="basic-example">
                      <section>
                        <form method="POST">
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label htmlFor="basicpill-firstname-input">
                                  Category <span style={{ color: "#FF0000" }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className={error && category.CATEGORY.trim().length <= 0 ? 'form-control is-invalid ' : 'form-control'}
                                  id="basicpill-firstname-input"
                                  placeholder="Enter Category"
                                  name="CATEGORY"
                                  value={category.CATEGORY}
                                  onChange={handleChange}
                                />
                                {
                                  error && category.CATEGORY.trim().length <= 0 ? <span className='text-danger ml-1'>This Field is Required</span>
                                    : ""}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label htmlFor="basicpill-address-input">Sort Number <span style={{ color: "#FF0000" }}>*</span></label>
                                <NumberFormat
                                  type="text"
                                  allowLeadingZeros={false}
                                  allowNegative={false}
                                  thousandSeparator={false}
                                  className={error && category.SORT_NUMBER.toString().trim().length <= 0 ? 'form-control is-invalid ' : 'form-control'}
                                  id="basicpill-firstname-input"
                                  placeholder="Enter Sort Number"
                                  name="SORT_NUMBER"
                                  value={category.SORT_NUMBER}
                                  onChange={handleChange}
                                />
                                {
                                  error && category.SORT_NUMBER.toString().trim().length <= 0 ? <span className='text-danger ml-1'>This Field is Required</span>
                                    : ""}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="mb-3">
                                <label htmlFor="basicpill-address-input">
                                  Page Content
                                </label>
                                <textarea
                                  id="basicpill-address-input"
                                  className="form-control"
                                  rows={2}
                                  placeholder="Enter Category Details"
                                  defaultValue={""}
                                  name="CATEGORY_DETAILS"
                                  value={category.CATEGORY_DETAILS}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <button
                                  type="button"
                                  onClick={onSubmit}
                                  className="btn btn-info waves-effect btn-label waves-light"
                                >
                                  <i className="bx bx-check-double label-icon" />{" "}
                                  {id ? 'Save Changes' : 'Create Category'}
                                </button>
                                &nbsp;&nbsp;
                                <button
                                  type="button"
                                  onClick={handleReset}
                                  className="btn btn-danger waves-effect btn-label waves-light"
                                >
                                  <i className="bx bx-block label-icon " />{" "}
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
