import React, { Children, useRef } from "react";
import { addFaq, updateFaq } from "../../actions/utilitiesAction";
import constant from "../../constant";
import QualificationLists from "../../component/jobs/QualificationList";
import { archiveEducationQualification, createEducationQualification, createRole, updateEducationQualification, updateRole } from "../../actions/JobsAction";
import QuestionLists from "../../component/jobs/QuestionList";
import RoleLists from "../../component/jobs/RoleList";
import AddRoles from "../../component/jobs/AddRole";
import { NavigationType, useNavigate } from "react-router-dom";

const EditRole = () => {

    const navigation = useNavigate();

    const [response, setResponse] = React.useState({})
    const [showMsg, setShowMsg] = React.useState(false)


    const onSubmit = (model) => {
        if (model.type === 'ADD') {
            // createRole(model).then((res) => {
            //     setResponse(res)
            //     setShowMsg(true)
            //     setTimeout(() => {
            //         setShowMsg(false)
            //     }, 7000)
            // }).catch((err) => {
            //     alert(err)
            // })
        } else {
            updateRole(model).then((res) => {
                if (res.status) {
                    navigation(constant.component.listRole.url + "?p=" + model.pageno)
                }
                else {
                    setResponse(res)
                    setShowMsg(true)
                    setTimeout(() => {
                        setShowMsg(false)
                    }, 7000)
                }
            }).catch((err) => {
                alert(err)
            })
        }

    }


    return (
        <React.Fragment>
            <AddRoles
                onSubmit={(data) => onSubmit(data)} response={response} showMsg={showMsg}

            />
        </React.Fragment>
    )
}

export default EditRole;