import React from 'react'
import Swal from 'sweetalert2'
import constant from '../../../constant'
import { useNavigate } from 'react-router-dom'
import Add from '../../../component/campus/university/addAnnouncement'
import { addNewAnnouncement } from '../../../actions/campus'
const AddAnnouncement = (props) => {
    const Navigate = useNavigate()
    const onSubmit = (model) => {
        addNewAnnouncement(model).then(res => {
            if (res.status) {
                Swal.fire({
                    icon: 'success',
                    text: 'Announcement Successfully Added',
                    timer: 1500,
                    showCancelButton: false,
                    showConfirmButton: false
                })

                Navigate(constant.component.listCampusAnnouncement.url)
            }
            else {
                console.error(res.error)
            }
        })
    }
    return (
        <React.Fragment>
            <Add
                onSubmit={(model) => onSubmit(model)}
            />
        </React.Fragment>
    )
}

export default AddAnnouncement