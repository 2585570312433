import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { activeSkillList, PracticeQuestionbyId, salaryTypeById, updatePracticeQuestion, updateSalaryType } from '../../../actions/utilitiesAction'
import Add from '../../../component/Utilities/PracticeQuestion/AddPracticeQuestion'
import constant from '../../../constant'
const AddSalary = () => {

    const [response, setResponse] = React.useState({})
    const [showMsg, setShowMsg] = React.useState(false)
    const [list, setlist] = useState([])

    const navigate = useNavigate()
    const params = useParams()
    const [data, setData] = useState(null)
    useEffect(() => {
        PracticeQuestionbyId(params.id).then((res) => {
            if (res.status) {
                setData(res.result)
            }
            else {
                setResponse(res)
                setShowMsg(true)
                setTimeout(() => {
                    setShowMsg(false)
                }, 7000)
            }
        }).catch(err => {
            alert(err)
        })
        activeSkillList().then((res) => {
            if (res.status) {
                setlist(res.result.list)
            }
            else {
                alert(res.error)
            }
        }).catch((err) => {
            alert(err)
        })
    }, [])

    const onSubmit = (model) => {
        updatePracticeQuestion(model).then((res) => {
            if (res.status) {
                navigate(constant.component.practiceQuestionList.url + "?p=" + model.pageno)
            } else {
                setResponse(res)
                setShowMsg(true)
                setTimeout(() => {
                    setShowMsg(false)
                }, 7000)
            }
        }).catch((err) => {
            alert(err)
        })
    }

    return (
        <React.Fragment>
            {data && <Add list={list} detail={data}
                onSubmit={onSubmit} response={response} showMsg={showMsg} />}
        </React.Fragment>
    )
}
export default AddSalary;