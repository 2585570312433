import React from 'react'
import { unlockScreen } from '../../actions/AuthActions';
import LockScreens from '../../component/Menu/Dashboard/LockScreen'
import Swal from "sweetalert2";
export default function LockScreen() {
    const onSubmit = (model) => {
        unlockScreen(model).then((res) => {
            if (res.status) {
                Swal.fire({
                    icon: "success",
                    text: res.messageCode,
                    timer: 2000,
                    showCancelButton: false,
                    showConfirmButton: false,
                });
                window.location.reload();
            }
            else {

                Swal.fire({
                    icon: "error",
                    // title: 'Oops...',
                    text: res.error
                        ? res.error
                        : "Something went wrong!! Please contact system administrator.",
                    timer: 2000,
                    showCancelButton: false,
                    showConfirmButton: false,
                });
            }
        }).catch((err) => {
            alert(err)
        })
    }

    return (
        <React.Fragment>
            <LockScreens onSubmit={onSubmit} />
        </React.Fragment>
    )
}
