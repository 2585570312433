import React from 'react'
import { useState } from 'react';
import moment from 'moment/moment';

export default function Country(props) {

  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    props.setPage(pageNumber)
  };

  return (
    <React.Fragment>
      <div className="main-content">

        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0" style={{ fontSize: '18px' }}>Country List</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item"><a href="javascript: void(0);">Utilities</a></li>
                      <li className="breadcrumb-item active">Country List</li>
                    </ol>
                  </div>

                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table align-middle table-nowrap table-hover">
                        <thead className="table-light">
                          <tr>
                            <th scope='col'>S.No</th>
                            <th scope="col">Country ID</th>
                            <th scope="col">Country</th>
                            <th scope="col">Added On</th>
                            {/* <th scope="col" style={{ width: '100px' }}>Action</th> */}
                          </tr>
                        </thead>
                        <tbody>

                          {props.List && props.List.length > 0
                            && props.List.map((ele, index, list) => {

                              return (
                                <tr>

                                  <td>{currentPage == 1 ? `${index + 1}` : `${index == 9 ? currentPage : currentPage - 1}` + `${index == 9 ? 0 : index + 1}`}</td>


                                  <td>{ele.COUNTRY_ID}</td>

                                  <td>
                                    <p className="text-muted mb-0">{ele.COUNTRY}</p>
                                  </td>

                                  <td>
                                    {moment(ele.CREATED_ON).format(
                                      "DD.MM.YYYY on HH:MM A"
                                    )}
                                  </td>


                                </tr>

                              )
                            })
                          }

                        </tbody>
                      </table>
                    </div>
                    {/* <div className="row">
                      <div className="col-lg-12">
                        <div className="pagination pagination-rounded justify-content-center mt-4">
                          <Pagination
                            activePage={currentPage}
                            itemsCountPerPage={10}
                            totalItemsCount={props.count}
                            pageRangeDisplayed={5}
                            onChange={handlePageChange}
                            itemClass="page-item"
                            linkClass="page-link"
                          />
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
