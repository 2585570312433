import * as axios from "axios";
import { getAuthHeader } from "./../utils";

export const addForumCategory = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/add-forum-category`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const discussionForumCategoryList = (page) => {
    // const url = `${process.env.REACT_APP_BASE_URL}/Forum-Category-List`;
    const url = `${process.env.REACT_APP_BASE_URL}/Forum-Category-List?page=${page}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const ForumCategoryList = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/forum-category-foredit`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const archiveForumCategory = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/archive-discussion-forum`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const ForumCategoryById = (id) => {
    
    const url = `${process.env.REACT_APP_BASE_URL}/forum-category-By-Id/?DISCUSSION_CATEGORY_ID=${id}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const updateDiscussionForum = (body) => {
    const url = `${process.env.REACT_APP_BASE_URL}/discussion-forum-update`;
    return axios.put(url, body, getAuthHeader()).then((res) => {
        return res.data;
    })
};