import React, { useState, useEffect } from 'react'
import Pagination from 'react-js-pagination'
import Loader from '../../common/Loader'
import ReactReadMoreReadLess from 'react-read-more-read-less'
import { nl2br } from 'react-js-nl2br'
import { getDateParts } from '../../../utils'
import Swal from 'sweetalert2'
import StatusModule from './StatusModule'
import { Discussionthreadvalue } from '../../../actions/utilitiesAction'

const Discussionforumactivethread = (props) => {

    const [currentPage, setCurrentPage] = useState(1);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        props.setPage(pageNumber)
    }

    const updateStatus = (DISCUSSION_THREAD_ID, STATUS) => {
        const model = {
            DISCUSSION_THREAD_ID: DISCUSSION_THREAD_ID,
            STATUS: STATUS === 'A' ? 'I' : 'A'
        }
        props.setPage(currentPage)
        props.updateStatus(model)
    }

    const deleteDiscussionthread = (DISCUSSION_THREAD_ID) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to delete this comment? ",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                props.deleteDiscussionthreadLists(DISCUSSION_THREAD_ID)
            }
        })

    }

    function updateDiscussionForm(model) {

        if (model) {
            Discussionthreadvalue(model).then((res) => {
                
                if (res.status) {
                   
                } else {
                    alert(res.error)
                }
            }).catch((err) => {
                alert(err)
            })
        }
    }

    
    

    return (
        <React.Fragment>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0" style={{ fontSize: "18px" }}>DISCUSSION THREADS</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="#">Utilities</a></li>
                                            <li className="breadcrumb-item active">Discussion Threads</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>



                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table align-middle table-nowrap table-hover" >
                                                {
                                                    props.showLoader &&
                                                    <Loader />
                                                }
                                                {!props.showLoader &&
                                                    <thead className="table-light">
                                                        <tr style={{ fontSize: "16px" }}>
                                                            <th scope="col">S.No.</th>
                                                            <th scope="col">Topic Information</th>
                                                            <th scope="col">Thread Stats</th>
                                                            <th scope="col">Action</th>


                                                        </tr>
                                                    </thead>}
                                                <tbody>

                                                    {props.list && props.list.length > 0 &&
                                                        props.list.map((item , index, list) => {
                                                            return (

                                                                <tr style={{ whiteSpace: "inherit" }}>

                                                                <td>{10 * currentPage + (index + 1) - 10}</td>
                                                                    {/* <td style={{ color: "#ff8000", fontSize: "14px" }}>
                                                                        <div className="avatar-xs">
                                                                            <span className="avatar-title rounded-circle">
                                                                                {item?.CANDIDATE_NAME?.charAt(0)?.toUpperCase()}
                                                                            </span>
                                                                        </div>
                                                                    </td> */}

                                                                    <td style={{ fontFamily: " sans-serif", fontSize: "4px" }}>
                                                                        <h5 className=" mb-1" style={{ fontSize: "15px" }}> <span className="text-dark">
                                                                            <ReactReadMoreReadLess
                                                                                charLimit={40}
                                                                                readMoreText={<a href="javascript: void(0);" className="text-primary">  </a>}
                                                                                readLessText={<a href="javascript: void(0);" className="text-primary"> </a>}
                                                                            >
                                                                                {item.THREAD_TITLE.charAt(0).toUpperCase() + item.THREAD_TITLE.slice(1).toLowerCase()}
                                                                            </ReactReadMoreReadLess>
                                                                        </span> </h5>

                                                                        <p className="text-muted mb-0"><i className="bx bx-user font-size-14 align-middle me-1"> <span style={{ color: "#0080ff ", fontFamily: " sans-serif", fontSize: "14px" }}> {item.CANDIDATE_NAME==null?"Not Available":item?.CANDIDATE_NAME?.charAt(0)?.toUpperCase() + item?.CANDIDATE_NAME?.slice(1)?.toLowerCase()}</span></i></p><br />
                                                                        <p className="text-muted mb-0"><i className="bx bxs-purchase-tag font-size-14 align-middle me-1"> <span style={{ color: "#9f40ff ", fontFamily: " sans-serif", fontSize: "14px" }}>{item.DISCUSSION_CATEGORY_TITLE}</span></i></p><br />
                                                                        <p className="text-muted mb-0" style={{ fontSize: "14px" }}><span style={{ color: "#f46a6a" }}>Started about
                                                                            <span style={{ color: "#333" }}> {getDateParts(item.CREATED_ON).relativeTime.split(' ')[0] == 'a' ? '1 ' + getDateParts(item.CREATED_ON).relativeTime.split(' ').splice(1).join(' ') : getDateParts(item.CREATED_ON).relativeTime} </span> by
                                                                            <span style={{ color: "#00aeae" }}></span> {item.CANDIDATE_NAME}</span>  </p>
                                                                    </td>
                                                                    <td >
                                                                        <p className="text-muted mb-0"><span style={{ color: "#333", fontSize: "14px" }}> Views: </span> <span style={{ color: "#008040 ", fontSize: "14px" }}>{item.VIEW_COUNT} </span></p>
                                                                        <p className="text-muted mb-0"><span style={{ color: "#333", fontSize: "14px" }}> Likes : </span> <span style={{ color: "#0080ff ", fontSize: "14px" }}>{item.likeCount.total_likes} </span></p>
                                                                        <p className="text-muted mb-0"><span style={{ color: "#333", fontSize: "14px" }}> Last Reply : </span> <span style={{ color: "#9f40ff ", fontSize: "14px" }}>{(item.Countcomment.total_comment) ? getDateParts(item.lastcomment).relativeTime : 'No reply'} </span></p>
                                                                        <p className="text-muted mb-0"><span style={{ color: "#333", fontSize: "14px" }}> Total Reply :</span> <span style={{ color: "#f46a6a", fontSize: "14px" }}>{item.Countcomment.total_comment}</span> </p><br />
                                                                        <p className="text-muted mb-0"> {item.STATUS == 'A' ?
                                                                            <span className="badge rounded-pill bg-success" style={{ fontSize: "14px" }}>ACTIVE</span> :
                                                                            item.STATUS == 'I' ?
                                                                                <span className="badge rounded-pill bg-warning" style={{ fontSize: "14px" }}>INACTIVE</span> :

                                                                                ''
                                                                        } </p>

                                                                    </td>

                                                                    <td>
                                                                        <ul className="list-inline font-size-20 contact-links mb-0">
                                                                            <li className="list-inline-item px-2">
                                                                                <a href="javascript: void(0);" title="View Thread" style={{ cursor: "pointer" }} data-bs-toggle="modal" data-bs-target={"#MODELID" + item.DISCUSSION_THREAD_ID} ><i className="bx bxs-zoom-in"></i></a>
                                                                                <div className="modal fade" id={"MODELID" + item.DISCUSSION_THREAD_ID}  tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                                                                                        <div className="modal-content">
                                                                                            <div className="modal-header">
                                                                                                <h5 className="modal-title" id="staticBackdropLabel">Discussion Threads</h5>

                                                                                            </div>

                                                                                            <div className="modal-body">
                                                                                                <p>
                                                                                                    <table className="table table-striped mb-0">
                                                                                                        <tbody >
                                                                                                            <tr>
                                                                                                                <td><h5>  Thread Title :  </h5></td>
                                                                                                                <td style={{ whiteSpace: "normal", display: "flex", textAlign: "left", fontSize: "14px" }}><p>   {nl2br(item.THREAD_TITLE)} </p></td>
                                                                                                            </tr>

                                                                                                            <tr>
                                                                                                                <td><h5>  Thread Deatails :  </h5></td>
                                                                                                                <td style={{ whiteSpace: "normal", display: "flex", textAlign: "left", fontSize: "14px" }}><p>   {nl2br(item.THREAD_DETAILS)} </p></td>
                                                                                                            </tr>



                                                                                                            <tr>
                                                                                                                <td><h5>  Discussion Category Title:  </h5></td>
                                                                                                                <td style={{ whiteSpace: "normal", display: "flex", textAlign: "left", fontSize: "14px" }}><p>   {nl2br(item.DISCUSSION_CATEGORY_TITLE)} </p></td>
                                                                                                            </tr>
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </p>
                                                                                            </div>

                                                                                            <div className="modal-footer">
                                                                                                <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </li>

                                                                            <li className="list-inline-item px-2">
                                                                             {
                                                                             console.log("props.category",props.category)
                                                                             }
                                                                                <StatusModule 
                                                                                categorylist={props.category} 
                                                                                 listItem={item}
                                                                                   onUpdate={updateDiscussionForm} 
                                                                                   />
                                                                            </li>

                                                                            <li className="list-inline-item px-2">
                                                                                <a href="javascript: void(0);"
                                                                                    onClick={() => deleteDiscussionthread(item.DISCUSSION_THREAD_ID)} style={{ color: "red" }} title="Delete Thread"><i className="bx bx-trash"></i></a>
                                                                            </li>

                                                                        </ul><br />

                                                                        <select style={{ padding: "3px" }} name="STATUS" onChange={() => updateStatus(item.DISCUSSION_THREAD_ID, item.STATUS)}
                                                                            value={item.STATUS} >

                                                                            <option value="A" selected={item.STATUS == 'A' ? true : false}>ACTIVE</option>
                                                                            <option value="I" selected={item.STATUS == 'I' ? true : false}>INACTIVE</option>

                                                                        </select>

                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        <>
                                                            {
                                                                props.list && props.list.length == 0 && props.showLoader == false &&
                                                                <td colSpan={10} className="text-center text-danger pt-3">No Record Found</td>
                                                            }
                                                        </>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="pagination pagination-rounded justify-content-center mt-4">

                                                    <Pagination
                                                        activePage={currentPage}
                                                        itemsCountPerPage={10}
                                                        totalItemsCount={props.count}
                                                        pageRangeDisplayed={5}
                                                        onChange={handlePageChange}
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                    />

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Discussionforumactivethread
