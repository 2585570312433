import React from 'react'
import { useEffect, useState } from "react";
import constant from '../../../constant';
import { useParams } from 'react-router-dom';
import { ToSeoUrl } from '../../../utils';
import NumberFormat from 'react-number-format';
import { uploadArticlePicture } from '../../../actions/utilitiesAction';
import SuccessMessage from '../../common/SuccessMessage';
import ErrorMessage from '../../common/ErrorMessage';
function AddArticle(props) {

    const { id } = useParams()
    const { detail } = props
    console.log("detail", props);

    const [error, setError] = useState(false)

    const [data, setData] = useState({
        ARTICLE_TITLE: "",
        ARTICLE_DETAILS: "",
        PICTURE: "",
        AUTHOR: "",
        TAG: "",
        URL: "",
        CATEGORY_ID: "",
        SORT_NUMBER: ""
    })

    const onSubmit = (e) => {
        
        e.preventDefault();

        if (data.ARTICLE_TITLE.trim().length == 0 ||
            data.ARTICLE_DETAILS.trim().length == 0 ||
            data.CATEGORY_ID.toString().trim().length == 0 ||
            data.AUTHOR.trim().length == 0 ||
            data.SORT_NUMBER.toString().trim().length == 0 ||
            data.PICTURE.trim().length == 0 ||
            data.TAG.trim().length == 0 ||
            data.URL.length == 0) {
            setError(true)
            return false
        }
        else {
            const model = {
                ARTICLE: data.ARTICLE_TITLE,
                ARTICLE_DETAILS: data.ARTICLE_DETAILS,
                PICTURE: data.PICTURE,
                TAG: data.TAG,
                AUTHOR: data.AUTHOR,
                URL: data.URL,
                CATEGORY_ID: data.CATEGORY_ID,
                SORT_NUMBER: data.SORT_NUMBER
            };
            if (detail) {
                model.ARTICLE_ID = detail.ARTICLE_ID
            }
            console.log(props)
            props.onSubmit(model)
        }


    }


    useEffect(() => {
        (id ? document.title = constant.title.EditArticle : document.title = constant.title.AddArticle)
        if (detail) {
            setData({
                ARTICLE_TITLE: detail.ARTICLE,
                ARTICLE_DETAILS: detail.ARTICLE_DETAILS,
                PICTURE: detail.PICTURE,
                AUTHOR: detail.AUTHOR,
                TAG: detail.TAG,
                URL: detail.URL,
                CATEGORY_ID: detail.CATEGORY_ID,
                SORT_NUMBER: detail.SORT_NUMBER,
                CATEGORY: detail.CATEGORY_ID
            })
        }

    }, [])




    const handleChange = (e) => {
        
        const { name, value } = e.target
        if (name === 'ARTICLE_TITLE') {
            let url = ToSeoUrl(value);

            setData({
                ...data,
                [name]: value,
                'URL': url
            })
        }
        else {
            setData({
                ...data,
                [name]: value
            })
        }
    }

    const onArticlePictureUpload = (e) => {
        e.preventDefault();
        let model = new FormData();
        model.append('file', e.target.files[0]);
        uploadArticlePicture(model).then((res) => {
            if (res.status) {
                setData((prevState) => ({
                    ...prevState,
                    PICTURE: res.result.fileName
                }))
            } else {
                alert(res.error);
            }
        })
    }
    // useEffect(() => {
    //     (id ? document.title = constant.title.EditArticle : document.title = constant.title.AddArticle)
    // }, [])


    return (
        <React.Fragment>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0" style={{ fontSize: "18px" }}>{id ? "Update Article" : "Add Article"}</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="javascript: void(0);">Utilities</a></li>
                                            <li className="breadcrumb-item"><a href="javascript: void(0);">Article</a></li>
                                            <li className="breadcrumb-item active">{id ? "Update Article" : "Add Article"}</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">Create New Article</h4>
                                        {
                                            props.showMsg && (props.response.status ?
                                                <SuccessMessage message={props.response.messageCode} />
                                                :
                                                <ErrorMessage message={props.response.error} />)
                                        }
                                        <div className="row mb-4">
                                            <label for="projectname" className="col-form-label col-lg-2">Title <span className='text-danger'>*</span></label>
                                            <div className="col-lg-10">
                                                <input id="projectname"
                                                    name="ARTICLE_TITLE"
                                                    value={data.ARTICLE_TITLE}
                                                    onChange={handleChange}
                                                    type="text"
                                                    className={error && data.ARTICLE_TITLE.trim().length <= 0 ? 'form-control is-invalid ' : 'form-control'}
                                                    placeholder="Enter Article Title..." />
                                                {
                                                    error && data.ARTICLE_TITLE.trim().length <= 0 ? <span className='text-danger ml-1'>This Field is Required</span>
                                                        : ""}
                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <label for="projectname" className="col-form-label col-lg-2">Author <span className='text-danger'>*</span></label>
                                            <div className="col-lg-10">
                                                <input id="projectname"
                                                    name="AUTHOR"
                                                    value={data.AUTHOR}
                                                    onChange={handleChange}
                                                    type="text"
                                                    className={error && data.AUTHOR.trim().length === 0 ? 'form-control is-invalid ' : 'form-control'}
                                                    placeholder="Author..." />
                                                {
                                                    error && data.AUTHOR.trim().length === 0 && <span className='text-danger ml-1'>This Field is Required</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <label for="projectname" className="col-form-label col-lg-2">Slug</label>
                                            <div className="col-lg-10">
                                                <input id="projectname"
                                                    name="URL"
                                                    value={data.URL}
                                                    onChange={handleChange}
                                                    type="text"
                                                    readOnly={true}
                                                    className={'form-control'}
                                                    placeholder="Slug..." />

                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <label for="projectdesc" className="col-form-label col-lg-2">Description <span className='text-danger'>*</span></label>
                                            <div className="col-lg-10">
                                                <textarea
                                                    className={error && data.ARTICLE_DETAILS.trim().length <= 0 ? 'form-control is-invalid ' : 'form-control'}
                                                    id="projectdesc"
                                                    rows="8"
                                                    name='ARTICLE_DETAILS'
                                                    onChange={handleChange}
                                                    value={data.ARTICLE_DETAILS}
                                                    placeholder="Enter Article Description..."
                                                >
                                                </textarea>
                                                {
                                                    error && data.ARTICLE_DETAILS.length === 0 && <span className='text-danger ml-1'>This Field is Required</span>
                                                }
                                            </div>
                                        </div>

                                        <div className="row mb-4">
                                            <label className="col-form-label col-lg-2">Category <span className='text-danger'>*</span></label>
                                            <div className="col-lg-10">
                                                <div className="input-daterange input-group" id="project-date-inputgroup" data-provide="datepicker" data-date-format="dd M, yyyy" data-date-container='#project-date-inputgroup' data-date-autoclose="true">
                                                    <select
                                                        className={error && data.CATEGORY_ID.toString().trim().length === 0 ? 'form-control is-invalid ' : 'form-control'}
                                                        name='CATEGORY_ID'
                                                        onChange={handleChange}
                                                        value={data.CATEGORY_ID}
                                                    >
                                                        <option value="">Select a Category</option>
                                                        {
                                                            props.list && props.list.length > 0 &&
                                                            props.list.map((item) => {
                                                                return (
                                                                    <option value={item.CATEGORY_ID}>{item.CATEGORY}</option>
                                                                )
                                                            })

                                                        }
                                                    </select>

                                                </div>
                                                {
                                                    error && data.CATEGORY_ID.toString().trim().length === 0 && <span className='text-danger ml-1'>This Field is Required</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <label for="projectname" className="col-form-label col-lg-2">Sort Number <span className='text-danger'>*</span></label>
                                            <div className="col-lg-10">
                                                <NumberFormat id="projectname"
                                                    name="SORT_NUMBER"
                                                    value={data.SORT_NUMBER}
                                                    onChange={handleChange}
                                                    allowLeadingZeros={false}
                                                    allowNegative={false}
                                                    thousandSeparator={false}
                                                    type="text"
                                                    className={error && data.SORT_NUMBER.toString().trim().length <= 0 ? 'form-control is-invalid ' : 'form-control'}
                                                    placeholder="Sort Number..." />
                                                {
                                                    error && data.SORT_NUMBER.toString().trim().length <= 0 && <span className='text-danger ml-1'>This Field is Required</span>
                                                }
                                            </div>
                                        </div>

                                        <div className="row mb-4">
                                            <label for="projectbudget" className="col-form-label col-lg-2">Tags <span className='text-danger'>*</span></label>
                                            <div className="col-lg-10">
                                                <input id="projectbudget"
                                                    type="text"
                                                    name='TAG'
                                                    onChange={handleChange}
                                                    value={data.TAG}
                                                    placeholder="Enter Article Tag. Seprated by comma ',' in case of more than one tag..."
                                                    className={error && data.TAG.trim().length === 0 ? 'form-control is-invalid ' : 'form-control'} />
                                                {
                                                    error && data.TAG.trim().length === 0 && <span className='text-danger ml-1'>This Field is Required</span>
                                                }
                                            </div>
                                        </div>

                                        <div className="row mb-4">
                                            <label className="col-form-label col-lg-2">Picture <span className='text-danger'>*</span></label>
                                            <div className="col-lg-10">
                                                <div className="input-group">
                                                    <input
                                                        className={error && data.PICTURE.trim().length === 0 ? 'form-control is-invalid ' : 'form-control'}
                                                        id="inputGroupFile02"
                                                        name='PICTURE'
                                                        type="file"
                                                        onChange={(e) => onArticlePictureUpload(e)}
                                                    />

                                                    <label className="input-group-text" for="inputGroupFile02">Upload</label>

                                                </div>
                                                {
                                                    error && data.PICTURE.trim().length === 0 && <span className='text-danger ml-1'>This Field is Required</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="row justify-content-end">
                                            <div className="col-lg-10">
                                                <button type="submit" onClick={onSubmit} className="btn btn-primary">{detail ? "Save Changes" : "Create Article"}</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AddArticle