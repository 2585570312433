import React, { useEffect, useState } from 'react'
import { activeRoleList, createAdminUser } from '../../actions/AdminUserAction'
import Add from '../../component/Administration/adminUser/AddAdminUser'
import constant from '../../constant'

const AddAdminUser = () => {

    const [response, setResponse] = React.useState({})
    const [showMsg, setShowMsg] = React.useState(false)
    const [roleList, setRoleList] = useState([])

    useEffect(() => {
        document.title = constant.title.AddAdminUser
        activeRoleList().then(res => {
            if (res.status) {
                setRoleList(res.result)
            }
            else {
                alert(res.error)
            }
        }).catch((err) => {
            alert(err)
        })
    }, [])

    const onSubmit = (model) => {
        createAdminUser(model).then(res => {
            if (res.status) {
                setResponse(res)
                setShowMsg(true)
                setTimeout(() => {
                    setShowMsg(false)
                }, 7000)
            }
            else {
                setResponse(res)
                setShowMsg(true)
                setTimeout(() => {
                    setShowMsg(false)
                }, 7000)
            }

        }).catch((err) => {
            alert(err)
        })
    }
    return (
        <React.Fragment>
            <Add
                roleList={roleList}
                onSubmit={onSubmit}
                response={response}
                showMsg={showMsg}
            />
        </React.Fragment>
    )
}
export default AddAdminUser;