import React from 'react'
import { useState } from 'react';
import Pagination from 'react-js-pagination';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string'

const SubscriptionPackage = (props) => {

    const [currentPage, setCurrentPage] = useState(1);
    const location = useLocation()
    const navigate = useNavigate()

    const handlePageChange = (pageNumber) => {

        setCurrentPage(pageNumber);
        navigate(location.pathname + "?p=" + pageNumber)
        props.setPage(pageNumber)
    };

    props.setPage(currentPage)

    const qParams = queryString.parse(location.search)



    return (
        < React.Fragment >


            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">

                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 font-size-18"> EMPLOYER SUBSCRIPTION </h4>
                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item">
                                                <a href="">Subscription List</a>
                                            </li>
                                            <li className="breadcrumb-item active"> Employers Subscription List</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">Subscription List</h4>
                                        <div className="table-responsive">
                                            <table className="table align-middle  table-hover ">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col">ID</th>
                                                        <th scope="col">Employer Information</th>
                                                        <th scope="col">Subscription Details</th>
                                                        <th scope="col">Transaction Details</th>

                                                        <th scope="col">
                                                            Action
                                                        </th>
                                                    </tr>
                                                </thead>



                                                <tbody>
                                                    {props.List && props.List.length > 0
                                                        ? props.List.map((ele, index, list) => {

                                                            return (
                                                                <tr style={{ whiteSpace: "inherit" }} >

                                                                    <td style={{ color: "#ff8000", fontSize: "14px", fontFamily: "nunito" }}>
                                                                        {10 * currentPage + (index + 1) - 10}
                                                                    </td>

                                                                    <td  >
                                                                        <h5 className=" mb-1" style={{ fontSize: "14px", width: "200px" }}><a className="text-dark">{ele.COMPANY_NAME}</a></h5>
                                                                        <p className="text-muted mb-0"> <span style={{ color: "#00add2 " }}>Industry</span> : {ele.industry
                                                                        }
                                                                            {/* <span className="badge border border-light rounded-circle bg-danger p-1"  ><span className="visually-hidden" >Consultant &amp; Recruiter</span></span> */}
                                                                        </p>
                                                                        <p className="text-muted mb-0">  <i className="bx bx-user font-size-16 align-middle me-1"> <span style={{ color: "#0080ff ", fontFamily: " sans-serif", fontSize: "15px" }}>{ele.CONTACT_PERSON}</span></i></p>
                                                                        <p className="text-muted mb-0"><i class=" fa fa-envelope " > </i> {ele.EMAIL}</p>
                                                                        <p className="text-muted mb-0"> <i class="fa fa-phone-alt"></i> 7082804082</p>
                                                                        <p className="text-muted mb-0"><span style={{ color: "#008040" }}>Purchase On</span> | {ele.START_DATE}</p>
                                                                    </td>
                                                                    <td >

                                                                        <h5 className=" mb-1" style={{ fontSize: "14px", width: "200px" }}><a href="" className="text-dark">{ele.PACKAGE_TITE} </a></h5>
                                                                        <p className="text-muted mb-0">Quantity : {ele.QTY}
                                                                        </p>
                                                                        <p className="text-muted mb-0">Used : 1</p>
                                                                        <p className="text-muted mb-0">Available Credit : 1</p>
                                                                        <p className="text-muted mb-0">View job Posting : {ele.jobpost}</p>
                                                                    </td>
                                                                    <td >
                                                                        Transaction ID : <span style={{ color: " #008040" }}>{ele.SUBSCRIPTION_TXN_ID} </span><br />
                                                                        Amount : <span style={{ color: " #008040" }}>{ele.AMOUNT} </span><br />
                                                                        payment Status : <span style={{ color: "#008040" }}>
                                                                            {ele.PAYMENT_STATUS == 'S' ?
                                                                                <span >Successful</span> :
                                                                                ele.PAYMENT_STATUS == 'F' ?
                                                                                    <span >Failure</span> :
                                                                                    ele.PAYMENT_STATUS == "P" ? <span> Processing  </span> : ''
                                                                            }
                                                                        </span><br />
                                                                        payment Mode : <span style={{ color: " #008040" }}>UPI </span><br />
                                                                        Validity : <span style={{ color: " #f46a6a" }}>
                                                                            (End Date) {ele.END_DATE}</span><br />

                                                                    </td>



                                                                    <td>
                                                                        <ul className="list-inline font-size-20 contact-links mb-0">
                                                                            <li className="list-inline-item px-2">
                                                                                <a href="javascript: void(0);" title="View Thread" style={{ cursor: "pointer" }} data-bs-toggle="modal" data-bs-target={"#MODELID" + ele.PAYMENT_ID} ><i className="bx bxs-zoom-in"></i></a>
                                                                                <div className="modal fade" id={"MODELID" + ele.PAYMENT_ID}  tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                                                                                        <div className="modal-content">
                                                                                            <div className="modal-header">
                                                                                                <h5 className="modal-title" id="staticBackdropLabel">Subscription package Information</h5>

                                                                                            </div>

                                                                                            <div className="modal-body">
                                                                                                <p>
                                                                                                    <table className="table table-striped mb-0">
                                                                                                        <tbody >
                                                                                                            <tr>
                                                                                                                <td><h5>  Company Name :  </h5></td>
                                                                                                                <td style={{ whiteSpace: "normal", display: "flex", textAlign: "left", fontSize: "14px" }}><p>{ele.COMPANY_NAME} </p></td>
                                                                                                            </tr>

                                                                                                            <tr>
                                                                                                                <td><h5>  Industry :  </h5></td>
                                                                                                                <td style={{ whiteSpace: "normal", display: "flex", textAlign: "left", fontSize: "14px" }}><p> Industry  </p></td>
                                                                                                            </tr>



                                                                                                            <tr>
                                                                                                                <td><h5>  Name:  </h5></td>
                                                                                                                <td style={{ whiteSpace: "normal", display: "flex", textAlign: "left", fontSize: "14px" }}><p>  {ele.CONTACT_PERSON} </p></td>
                                                                                                            </tr>

                                                                                                            <tr>
                                                                                                                <td><h5>  Mail Id:  </h5></td>
                                                                                                                <td style={{ whiteSpace: "normal", display: "flex", textAlign: "left", fontSize: "14px" }}><p> {ele.EMAIL}</p></td>
                                                                                                            </tr>

                                                                                                            <tr>
                                                                                                                <td><h5>  Phone Number:  </h5></td>
                                                                                                                <td style={{ whiteSpace: "normal", display: "flex", textAlign: "left", fontSize: "14px" }}><p>  Phone Number </p></td>
                                                                                                            </tr>

                                                                                                            <tr>
                                                                                                                <td><h5>  purchase :  </h5></td>
                                                                                                                <td style={{ whiteSpace: "normal", display: "flex", textAlign: "left", fontSize: "14px" }}><p>{ele.START_DATE} </p></td>
                                                                                                            </tr>

                                                                                                            <tr>
                                                                                                                <td><h5>  job Type:  </h5></td>
                                                                                                                <td style={{ whiteSpace: "normal", display: "flex", textAlign: "left", fontSize: "14px" }}><p>Job type  </p></td>
                                                                                                            </tr>


                                                                                                            <tr>
                                                                                                                <td><h5>  Quantity:  </h5></td>
                                                                                                                <td style={{ whiteSpace: "normal", display: "flex", textAlign: "left", fontSize: "14px" }}><p>{ele.QTY}</p></td>
                                                                                                            </tr>


                                                                                                            <tr>
                                                                                                                <td><h5>  Package Used:  </h5></td>
                                                                                                                <td style={{ whiteSpace: "normal", display: "flex", textAlign: "left", fontSize: "14px" }}><p>Package Used </p></td>
                                                                                                            </tr>


                                                                                                            <tr>
                                                                                                                <td><h5>  Available Credit:  </h5></td>
                                                                                                                <td style={{ whiteSpace: "normal", display: "flex", textAlign: "left", fontSize: "14px" }}>Available Credit<p>  </p></td>
                                                                                                            </tr>


                                                                                                            <tr>
                                                                                                                <td><h5>  View job Posting:  </h5></td>
                                                                                                                <td style={{ whiteSpace: "normal", display: "flex", textAlign: "left", fontSize: "14px" }}><p> View job Posting  </p></td>
                                                                                                            </tr>

                                                                                                            <tr>
                                                                                                                <td><h5>  Transaction ID:  </h5></td>
                                                                                                                <td style={{ whiteSpace: "normal", display: "flex", textAlign: "left", fontSize: "14px" }}><p>{ele.SUBSCRIPTION_TXN_ID}  </p></td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td><h5>  Amount:  </h5></td>
                                                                                                                <td style={{ whiteSpace: "normal", display: "flex", textAlign: "left", fontSize: "14px" }}><p>{ele.AMOUNT}  </p></td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td><h5>  Payment Statement:  </h5></td>
                                                                                                                <td style={{ whiteSpace: "normal", display: "flex", textAlign: "left", fontSize: "14px" }}><p>
                                                                                                                    {ele.PAYMENT_STATUS == 'S' ?
                                                                                                                        <span >Successful</span> :
                                                                                                                        ele.PAYMENT_STATUS == 'F' ?
                                                                                                                            <span >Failure</span> :
                                                                                                                            ele.PAYMENT_STATUS == "P" ? <span> Processing  </span> : ''
                                                                                                                    }
                                                                                                                </p></td>
                                                                                                            </tr>

                                                                                                            <tr>
                                                                                                                <td><h5>  Payment Mode:  </h5></td>
                                                                                                                <td style={{ whiteSpace: "normal", display: "flex", textAlign: "left", fontSize: "14px" }}><p>Payment Mode  </p></td>
                                                                                                            </tr>

                                                                                                            <tr>
                                                                                                                <td><h5>  Validity:  </h5></td>
                                                                                                                <td style={{ whiteSpace: "normal", display: "flex", textAlign: "left", fontSize: "14px" }}><p>{ele.END_DATE}  </p></td>
                                                                                                            </tr>
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </p>
                                                                                            </div>

                                                                                            <div className="modal-footer">
                                                                                                <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        </ul><br />
                                                                    </td>
                                                                </tr>


                                                            )
                                                        }) : ""}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="pagination pagination-rounded justify-content-center mt-4">

                                            <Pagination
                                                activePage={qParams?.p ? + qParams?.p : currentPage}
                                                itemsCountPerPage={10}
                                                totalItemsCount={props.count.total}
                                                pageRangeDisplayed={10}
                                                onChange={handlePageChange}
                                                itemClass="page-item"
                                                linkClass="page-link"
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}

export default SubscriptionPackage
