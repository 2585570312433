import React, { useState, useEffect } from 'react'
import NumberFormat from 'react-number-format'
import constant from '../../../constant'
import SuccessMessage from '../../common/SuccessMessage';
import ErrorMessage from '../../common/ErrorMessage';
import { useNavigate, useParams } from 'react-router-dom';

const AddSalary = (props) => {
    const { id } = useParams()
    const { detail } = props
    const [salary, setSalary] = useState({
        SALARY: "",
        SORT_NUMBER: "",
        SALARY_DETAILS: ""
    })
    const Navigate = useNavigate()
    const [error, setError] = useState(false)
    const handleChange = e => {
        const { name, value } = e.target

        setSalary({
            ...salary,
            [name]: value
        })
        console.log(salary);
    }
    const handleReset = (e) => {
        setError(false)
        e.preventDefault();
        id && Navigate(constant.component.salarylist.url)
        setSalary(prevState => ({
            ...prevState,
            SALARY: "",
            SORT_NUMBER: "",
            SALARY_DETAILS: ""
        }))
    }

    const onSubmit = (e) => {
        e.preventDefault()
        if (salary.SALARY.trim().length == 0 || salary.SORT_NUMBER.toString().trim().length == 0) {
            setError(true);
        }
        else {
            const model = {
                SALARY: salary.SALARY,
                SORT_NUMBER: salary.SORT_NUMBER,
                SALARY_DETAILS: salary.SALARY_DETAILS
            };
            if (detail) {
                model.SALARY_ID = detail.SALARY_ID
            }
            props.onSubmit(model)
        }

    }
    useEffect(() => {
        (id ? document.title = constant.title.EditSalary : document.title = constant.title.AddSalary)
    }, [])
    useEffect(() => {
        if (detail) {
            setSalary({
                SALARY: detail.SALARY,
                SORT_NUMBER: detail.SORT_NUMBER,
                SALARY_DETAILS: detail.SALARY_DETAILS
            })
        }
    }, [])
    return (
        <React.Fragment>
            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">


                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0" style={{ fontSize: '18px' }}>{id ? "Update Salary" : "Add Salary"}</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="javascript: void(0);">Utilities</a></li>
                                            <li className="breadcrumb-item active">{id ? "Update Salary" : "Add Salary"}</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">Salary Information</h4>
                                        {
                                            props.showMsg && (props.response.status ?
                                                <SuccessMessage message={props.response.messageCode} />
                                                :
                                                <ErrorMessage message={props.response.error} />)
                                        }
                                        <div id="basic-example">
                                            <section>
                                                <form    >
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label for="basicpill-firstname-input">Salary <span style={{ color: "#FF0000" }}>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    value={salary.SALARY}
                                                                    className={error && salary.SALARY.trim().length <= 0 ? 'form-control is-invalid ' : 'form-control'}
                                                                    id="basicpill-firstname-input"
                                                                    name="SALARY"
                                                                    onChange={handleChange}
                                                                    placeholder="Enter Salary" />
                                                                {error && salary.SALARY.trim().length <= 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label for="basicpill-address-input">Sort Number <span style={{ color: "#FF0000" }}>*</span></label>
                                                                <NumberFormat
                                                                    name="SORT_NUMBER"
                                                                    onChange={handleChange}
                                                                    value={salary.SORT_NUMBER}
                                                                    allowLeadingZeros={false}
                                                                    allowNegative={false}
                                                                    thousandSeparator={false}
                                                                    className={error && salary.SORT_NUMBER.toString().trim().length <= 0 ? 'form-control is-invalid ' : 'form-control'}
                                                                    id="basicpill-firstname-input" placeholder="Enter Sort Number"
                                                                    maxLength={10} />
                                                                {error && salary.SORT_NUMBER.toString().trim().length <= 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">

                                                        <div className="col-lg-12">
                                                            <div className="mb-3">
                                                                <label for="basicpill-address-input">Salary Details</label>
                                                                <textarea id="basicpill-address-input" className="form-control"
                                                                    name="SALARY_DETAILS"
                                                                    onChange={handleChange}
                                                                    value={salary.SALARY_DETAILS}
                                                                    rows="2" placeholder="Enter Salary Details"></textarea>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <button type="button" className="btn btn-info waves-effect btn-label waves-light"
                                                                    onClick={(e) => onSubmit(e)}
                                                                ><i className="bx bx-check-double label-icon"></i>{detail ? "Save Changes" : "Create Salary"}</button>&nbsp;&nbsp;
                                                                <button type="button"
                                                                    className="btn btn-danger waves-effect btn-label waves-light"

                                                                    onClick={(e) => handleReset(e)}
                                                                ><i className="bx bx-block label-icon "></i> Cancel</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </section>
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default AddSalary;