import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import constant from "../../../constant";
import SuccessMessage from "../../common/SuccessMessage";
import ErrorMessage from "../../common/ErrorMessage";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-dropdown-select";
import { getGovtState } from "../../../actions/utilitiesAction";
import { govtDepartmentList } from "../../../actions/JobsAction";
const AddGovtDepartment = (props) => {
  const { id } = useParams();
  const details = props.detail;

  const [error, setError] = useState(false);
  const [state, setState] = useState();
  const Navigate = useNavigate();
  const params = useParams();
  const [data, setData] = useState({
    GOVT_DEPARTMENT: "",
    CATEGORY:"",
    STATE: "",
    DEPARTMENT_WEBSITE: "",
  });

  const handleReset = (e) => {
    e.preventDefault();
    id && Navigate(constant.component.govtdepartmentlist.url);
    setError(false);
    setData((PrevState) => ({
      ...PrevState,
      GOVT_DEPARTMENT: "",
      CATEGORY: "",
      STATE: "",
      DEPARTMENT_WEBSITE: "",
    }));
  };

  const onSubmit = (e) => {
    let pn = params.pageno;
    e.preventDefault();
    if (
      data.CATEGORY.toString().trim().length == 0 ||
      
      data.GOVT_DEPARTMENT.trim().length == 0 ||
      data.DEPARTMENT_WEBSITE.trim().length == 0
    ) {
      setError(true);
      return false;
    } else if (
        data.CATEGORY !== "CENTRAL" &&
        (data.STATE.toString().trim().length == 0 )
      ) {
        setError(true);
        return false;
      }
    setData((PrevState) => ({
      ...PrevState,
      GOVT_DEPARTMENT: "",
      CATEGORY: "",
      STATE: "",
      DEPARTMENT_WEBSITE: "",
    }));
    const model = {
      DEPARTMENT: data.GOVT_DEPARTMENT,
      CATEGORY: data.CATEGORY,
      STATE_ID: data.STATE,
      DEPARTMENT_WEBSITE: data.DEPARTMENT_WEBSITE,
    };
    if (details) {
      model.GOVT_DEPT_ID = details.GOVT_DEPT_ID;
    }
    props.onSubmit(model);
    model.pageno = pn;
    setError(false);
    console.log(model);
  };

  useEffect(() => {
    id
      ? (document.title = constant.title.EditGovtDepartment)
      : (document.title = constant.title.AddGovtDepartment);
    if (details) {
      setData({
        GOVT_DEPARTMENT: details.GOVT_DEPT,
      });
    }
    getGovtState()
      .then((res) => {
        // console.log(res.result,"STATE");
        if (res.status) {
          setState(res.result.list);
        }
      })
      .catch((err) => {
        alert(err);
      });
      
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  return (
    <React.Fragment>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 " style={{ fontSize: "18px" }}>
                    {id ? "Update Govt Department" : "Add Govt Department"}
                  </h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Utilities</a>
                      </li>
                      <li className="breadcrumb-item active">
                        {id ? "Update Govt Department" : "Add Govt Department"}
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title mb-4">
                      Government Department Information
                    </h4>
                    {props.showMsg &&
                      (props.response.status ? (
                        <SuccessMessage message={props.response.messageCode} />
                      ) : (
                        <ErrorMessage message={props.response.error} />
                      ))}
                    <div id="basic-example">
                      <section>
                        <form method="POST">
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label htmlFor="basicpill-firstname-input">
                                  Government Department{" "}
                                  <span style={{ color: "#FF0000" }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className={
                                    error &&
                                    data.GOVT_DEPARTMENT.trim().length <= 0
                                      ? "form-control is-invalid "
                                      : "form-control"
                                  }
                                  id="basicpill-firstname-input"
                                  placeholder="Enter Government Department"
                                  name="GOVT_DEPARTMENT"
                                  value={data.GOVT_DEPARTMENT}
                                  onChange={handleChange}
                                />
                                {error &&
                                data.GOVT_DEPARTMENT.trim().length <= 0 ? (
                                  <span className="text-danger ml-1">
                                    This Field is Required
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            {/* <div className="col-lg-6">
                              <div className="mb-3">
                                <label htmlFor="basicpill-address-input">
                                  Country{" "}
                                  <span style={{ color: "#FF0000" }}>*</span>
                                </label>
                                <select
                                  className={
                                    error && data.COUNTRY_ID.trim().length <= 0
                                      ? "form-control is-invalid "
                                      : "form-control"
                                  }
                                  value={data.COUNTRY_ID}
                                  name="COUNTRY_ID"
                                  onChange={handleChange}
                                >
                                  <option selected value="102">
                                    India
                                  </option>
                                </select>
                                {error && data.COUNTRY_ID.trim().length <= 0 ? (
                                  <span className="text-danger ml-1">
                                    This Field is Required
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div> */}
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label htmlFor="basicpill-address-input">
                                  Department Category{" "}
                                  <span style={{ color: "#FF0000" }}>*</span>
                                </label>
                                <select
                                  className={
                                    error && data.CATEGORY.trim().length <= 0
                                      ? "form-control is-invalid "
                                      : "form-control"
                                  }
                                  value={data.CATEGORY}
                                  name="CATEGORY"
                                  onChange={handleChange}
                                >
                                  <option value="">Select Category</option>
                                   <option value="CENTRAL">Central Government</option>
                                  <option value="STATE">State Government</option>
                                </select>
                                {error &&
                                data.CATEGORY.toString().trim().length <=
                                  0 ? (
                                  <span className="text-danger ml-1">
                                    This Field is Required
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                          {data.CATEGORY !== "CENTRAL" && (
                            <>
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label htmlFor="basicpill-address-input">
                                  State{" "}
                                  <span style={{ color: "#FF0000" }}>*</span>
                                </label>

                                <select
                                  className={
                                    error && data.STATE.trim().length <= 0
                                      ? "form-control is-invalid "
                                      : "form-control"
                                  }
                                  value={data.STATE}
                                  name="STATE"
                                  onChange={handleChange}
                                >
                                  <option value={""}>Select State</option>
                                  {state &&
                                    state.map((item) => {
                                      return (
                                        <option value={item.STATE_ID}>
                                          {item.STATE}
                                        </option>
                                      );
                                    })}
                                </select>
                                {error && data.STATE.trim().length <= 0 ? (
                                  <span className="text-danger ml-1">
                                    This Field is Required
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            </>
                            )}
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label htmlFor="basicpill-firstname-input">
                                  Department Website
                                  <span style={{ color: "#FF0000" }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className={
                                    error &&
                                    data.DEPARTMENT_WEBSITE.trim().length <= 0
                                      ? "form-control is-invalid "
                                      : "form-control"
                                  }
                                  id="basicpill-firstname-input"
                                  placeholder="Enter Department Website"
                                  name="DEPARTMENT_WEBSITE"
                                  value={data.DEPARTMENT_WEBSITE}
                                  onChange={handleChange}
                                />
                                {error &&
                                data.DEPARTMENT_WEBSITE.trim().length <= 0 ? (
                                  <span className="text-danger ml-1">
                                    This Field is Required
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <button
                                  type="button"
                                  onClick={onSubmit}
                                  className="btn btn-info waves-effect btn-label waves-light"
                                >
                                  <i className="bx bx-check-double label-icon" />
                                  {details
                                    ? "Save Changes"
                                    : "Create Government Department"}
                                </button>
                                &nbsp;&nbsp;
                                <button
                                  type="button"
                                  onClick={handleReset}
                                  className="btn btn-danger waves-effect btn-label waves-light"
                                >
                                  <i className="bx bx-block label-icon " />{" "}
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default AddGovtDepartment;
