import React from 'react'
import Add from '../../component/SubscriptionManagement/AddSubscriptionPackage'
import { addsubscription } from '../../actions/Subscription'
import { useState } from 'react'
import { useEffect } from 'react'
import constant from '../../constant'

const AddSubscriptionPackage = () => {

    const [response, setResponse] = React.useState({})
    const [showMsg, setShowMsg] = React.useState(false)
    const [subscription, SetSubscription] = useState([])


    const onSubmit = (model) => {

        addsubscription(model).then((res) => {

            setResponse(res)
            setShowMsg(true)
            setTimeout(() => {
                setShowMsg(false)
            }, 7000)
        }).catch((err) => {
            alert(err)
        })
    }

    useEffect(() => {
        document.title = constant.title.Addsubscription
    }, [])


    return (
        <React.Fragment>
            <Add
                onSubmit={onSubmit}
                subscription={subscription}
                response={response}
                showMsg={showMsg}
            />
        </React.Fragment>
    )
}

export default AddSubscriptionPackage
