import React from 'react'
import List from '../../../component/Utilities/InterviewQuestion/InterviewQuestionList'
import constant from '../../../constant'
import { useEffect, useState } from 'react'
import queryString from 'query-string'
import { useLocation } from "react-router-dom";
import { getInterviewQuestion, archiveInterviewQuestion, AddStatusUpdate } from '../../../actions/utilitiesAction'

export default function InterviewQuestionsList() {


    const [list, setList] = useState(null)
    const [count, setCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [showLoader, setShowLoader] = useState(true)
    const location = useLocation()


    useEffect(() => {
        // setShowLoader(true)
        const qParams = queryString.parse(location.search)
        getInterviewQuestions(qParams?.p ? qParams?.p : qParams?.pageno ? qParams.pageno : currentPage)
        document.title = constant.title.ListInterviewQuestion
    }, [currentPage])

    const getInterviewQuestions = (page) => {
        setShowLoader(true)
        getInterviewQuestion(page).then((res) => {
            if (res.status) {
                setShowLoader(false)
                setList(res.result.list)
                setCount(res.result.count)
            }
            else {
                alert(res.error)
            }
        }).catch((err) => {
            alert(err)
        })
    }

    const updateStatus = (model) => {
        const Details = JSON.parse(localStorage.getItem("ud.jobzshala.com"))
        model.MODIFIED_BY = Details.ADMIN_ID
        archiveInterviewQuestion(model).then((res) => {
            if (res.status) {
                setShowLoader(true)
                getInterviewQuestions(currentPage)
            }
            else {
                alert(res.error)
            }
        }).catch((err) => {
            alert(err)
        })
    }
    // const onInputClick = (data, status) => {
    //     const Details = JSON.parse(localStorage.getItem("ud.jobzshala.com"))
    //     const model = {
    //         INTERVIEW_QUESTIONS_ID: data,
    //         IQ_USER_TYPE: status,
    //         ADMIN_ID: Details.ADMIN_ID
    //     }
    //     AddStatusUpdate(model).then((res) => {
    //         getInterviewQuestions(currentPage)
    //     }).catch((err) => alert(err))
    // }


    return (
        <React.Fragment>
            <List
                list={list}
                getInterviewQuestions={(page => getInterviewQuestions(page))}
                count={count}
                setPage={setCurrentPage}
                showLoader={showLoader}
                updateStatus={updateStatus}
                cpage={currentPage}
            // onInputClick={onInputClick}
            />
        </React.Fragment>
    )
}
