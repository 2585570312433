import React,{useState, useEffect} from 'react'
import { useParams } from 'react-router-dom';
import EmpPremiumJobs from '../../component/Employers/EmpPremiumJobs'
import { emppremiumJobList } from '../../actions/JobsAction';
import constant from '../../constant';

export default function EmpPremiumJob() {
    const [count, setCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [list, setList] = useState([]);
    const [showLoader, setShowLoader] = useState(false)
    const params = useParams()

    useEffect(() => {
        premiumjobs(currentPage)
        document.title = constant.title.employerpremiumjobs

    }, [currentPage])

    const premiumjobs = (page) => {
    
        setShowLoader(true)
        emppremiumJobList({EMPLOYER_ID: parseInt( params.id) ,page:page})
            .then((res) => {
                if (res.status) {
                    setList(res.result.premiumJobList);
                    // setCount(res.result.count.total);
                    setShowLoader(false)
                }
            })
            .catch((err) => {
                alert(err);
            });
    };


  return (

    <React.Fragment>

    <EmpPremiumJobs
        list={list}
                premiumjob={(page) => premiumjobs(page)}
                count={count}
                setPage={(page) => setCurrentPage(page)}
                setShowLoader={setShowLoader}
                showLoader={showLoader}
    />
      
    </React.Fragment>

  )
}

