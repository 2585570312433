import React from 'react'
import { useEffect, useState } from "react";
import constant from '../../../constant';
import NumberFormat from 'react-number-format';
import SuccessMessage from '../../common/SuccessMessage';
import ErrorMessage from '../../common/ErrorMessage';
import { useNavigate, useParams } from 'react-router-dom';

function AddArticleCategory(props) {

  const { id } = useParams()

  useEffect(() => {
    (id ? document.title = constant.title.UpdateArticleCategory : document.title = constant.title.AddArticleCategory)
  }, [])

  const { detail } = props

  const Navigate = useNavigate()

  const [error, setError] = useState(false)

  const [data, setData] = useState({
    CATEGORY: "",
    SORT_NUMBER: "",
    CATEGORY_DETAILS: "",
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setData({
      ...data,
      [name]: value
    })

  }

  const onSubmit = (e) => {
    e.preventDefault();
    if (data.CATEGORY.trim().length == 0 || data.SORT_NUMBER.toString().trim().length == 0) {
      setError(true)
    }
    else {
      const model = {
        CATEGORY: data.CATEGORY,
        SORT_NUMBER: data.SORT_NUMBER,
        CATEGORY_DETAILS: data.CATEGORY_DETAILS,
      };
      if (detail) {
        model.CATEGORY_ID = detail.CATEGORY_ID
      }
      props.onSubmit(model)
    }

  }

  const handleReset = (e) => {
    e.preventDefault();
    setError(false)
    id && Navigate(constant.component.articleCategoryList.url)
    setData(PrevState => ({
      ...PrevState,
      CATEGORY: "",
      SORT_NUMBER: "",
      CATEGORY_DETAILS: "",
    }))

  }

  useEffect(() => {
    if (detail) {
      setData({
        CATEGORY: detail.CATEGORY,
        SORT_NUMBER: detail.SORT_NUMBER,
        CATEGORY_DETAILS: detail.CATEGORY_DETAILS,
      })
    }

  }, [])


  return (
    <React.Fragment>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0" style={{ fontSize: "18px" }}>{id ? "Update Article Category" : "Add Article Category"}</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item"><a href="javascript: void(0);">Utilities</a></li>
                      <li className="breadcrumb-item"><a href="javascript: void(0);">Article</a></li>
                      <li className="breadcrumb-item active">{id ? "Update Article Category" : "Add Article Category"}</li>
                    </ol>
                  </div>

                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title mb-4">Category Information</h4>
                    {
                      props.showMsg && (props.response.status ?
                        <SuccessMessage message={props.response.messageCode} />
                        :
                        <ErrorMessage message={props.response.error} />)
                    }
                    <div id="basic-example">
                      <section>
                        <form method="POST">
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label htmlFor="basicpill-firstname-input">Category <span className='text-danger'>*</span></label>
                                <input type="text"
                                  className={error && data.CATEGORY.trim().length <= 0 ? 'form-control is-invalid ' : 'form-control'}
                                  id="basicpill-firstname-input"
                                  placeholder="Enter Category"
                                  name='CATEGORY'
                                  onChange={handleChange}
                                  value={data.CATEGORY}
                                />
                                {error && data.CATEGORY.trim().length <= 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}
                              </div>
                            </div>

                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label htmlFor="basicpill-address-input">Sort Number <span className='text-danger'>*</span></label>
                                <NumberFormat
                                  type="text"
                                  allowLeadingZeros={false}
                                  allowNegative={false}
                                  thousandSeparator={false}
                                  className={error && data.SORT_NUMBER.toString().trim().length <= 0 ? 'form-control is-invalid ' : 'form-control'}
                                  id="basicpill-firstname-input"
                                  placeholder="Enter Sort Number"
                                  name='SORT_NUMBER'
                                  onChange={handleChange}
                                  value={data.SORT_NUMBER}
                                />
                                {error && data.SORT_NUMBER.toString().length <= 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}
                              </div>
                            </div>
                          </div>
                          <div className="row">

                            <div className="col-lg-12">
                              <div className="mb-3">
                                <label htmlFor="basicpill-address-input">Category Details</label>
                                <textarea id="basicpill-address-input"
                                  className="form-control"
                                  rows={2} placeholder="Enter Category Details"
                                  name='CATEGORY_DETAILS'
                                  value={data.CATEGORY_DETAILS}
                                  onChange={handleChange}
                                  defaultValue={""} />
                              </div>
                            </div>

                          </div>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <button type="button" onClick={onSubmit} className="btn btn-info waves-effect btn-label waves-light"><i className="bx bx-check-double label-icon"></i> {detail ? "Save Changes" : "Create Article Category"}</button>
                                &nbsp;&nbsp;
                                <button type="button" onClick={handleReset} className="btn btn-danger waves-effect btn-label waves-light"><i className="bx bx-block label-icon "></i> Cancel</button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </section>
                    </div>

                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AddArticleCategory