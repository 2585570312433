import React, { useEffect, useState } from "react";
import { archivFaq, archivJobsFaq, countryList, getFAQCategoryList, getJobsFAQCategoryList, listFaq, listJobsFaq } from "../../../actions/utilitiesAction";
import List from "../../../component/Utilities/JobsFAQs/FAQList";
import constant from "../../../constant";

const FAQList = () => {
    const [faqList, setFaqList] = useState();
    const [list, setlist] = useState();
    const [showLoader, setShowLoader] = useState(false)

    useEffect(() => {
        document.title = constant.title.FaqList
        getJobsFAQCategoryList().then(res => {
            setShowLoader(true)
            setlist(res.result)
            setShowLoader(false)
        }).catch(err => {
            alert(err)
        })
        getFaqList()
    }, [])

    const getFaqList = () => {
        listJobsFaq().then((res) => {
            setShowLoader(true)
            if (res.status) {
                setShowLoader(false)
                setFaqList(res.result.list)
            }
        }).catch((err) => {
            alert(err)
        })
    }


    const updateStatus = (model) => {
        archivJobsFaq(model).then((res) => {
            if (res.status) {
                getFaqList()
            }
        })
            .catch((err) => {
                alert(err);
            });
    }
    return (
        <React.Fragment>
            {list && faqList && <List
                faqList={faqList}
                updateStatus={updateStatus}
                showLoader={showLoader}
                list={list}
            />}
        </React.Fragment>
    )
}

export default FAQList;