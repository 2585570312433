import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { getCandidateandjobemployerCount } from "../../../actions/ProfileAction";
import { ShimmerText } from "react-shimmer-effects";

export default function ApexChart() {
    const [CandidateEmpJobCount,setCandidateEmpJobCount]=useState()
    const [shimmer,setShiimmer]=useState(false)
     const candidatelistjob=CandidateEmpJobCount&&CandidateEmpJobCount.map((item)=>{
      return item.job.total
     })
     const candidatelistEmp=CandidateEmpJobCount&&CandidateEmpJobCount.map((item)=>{
      return item.employer.total
     })
     const candidatelistCandidate=CandidateEmpJobCount&&CandidateEmpJobCount.map((item)=>{
      return item.candidate.total
     })
     const candidatelistCandidateMonth=CandidateEmpJobCount&&CandidateEmpJobCount.map((item)=>{
      return item.month
     })

    
 
    useEffect(()=>{
      chartlist()
      
    },[])
    const chartlist=()=>{
      setShiimmer(true)
      getCandidateandjobemployerCount().then((res) => {
        if (res.status) {
          setShiimmer(false)
          setCandidateEmpJobCount(res.result.reverse());
        }
    }).catch((err) => {
        alert(err)
    })
    }

      var options = {
        series: [{
          name: 'Candidates',
          data: candidatelistCandidate
        }, {
          name: 'Jobs',
          data:candidatelistjob
        }, {
          name: 'Employers',
          data: candidatelistEmp
        }],
        chart: {
        type: 'bar',
        title: 'JobzShala STATISTICS',
        subtitle: 'Candidates, Jobs & Employers: 2023',
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: candidatelistCandidateMonth,
      },
      yaxis: {
        labels: {
          formatter: (value) => {
            return value.toFixed(1)
          },
        }
      },
  
     
      fill: {
        opacity: 1
      },
     
      };
    
      
  return (
    <React.Fragment>
       {shimmer?<ShimmerText line={5} gap={30} />:
       <div>
        <div>
                      <h4 className="card-title ">
                      JOBZSHALA STATISTICS - 2023
                      </h4>
                      <p>Candidates, Jobs & Employers: 2023</p>

                      <div className="ms-auto">

                                                {/* <li className="nav-item">
                            <a className="nav-link" href="#">
                              Month
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link active" href="#">
                              Year
                            </a>
                          </li>
                       

                    {/* <div id="stacked-column-chart" className="apex-charts" dir="ltr"></div> */}
                      </div>
                    </div> 
    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "80%",
                      }}
                    >
                      <div style={{ height: "100%", width: "100%" }}>
                     

                      <Chart
                          options={options}
                          series={options.series}
                          type="bar"
                          width={"100%"}
                        // height="80%"
                        />
                      </div>
                    </div></div>}
                    </React.Fragment>
  )
}
