import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import SuccessMessage from '../../common/SuccessMessage'
import ErrorMessage from '../../common/ErrorMessage'
import NumberFormat from 'react-number-format'
import Multiselect from 'multiselect-react-dropdown'
import constant from '../../../constant'


export default function AddInterviewQuestion(props) {

    const { id } = useParams()
    const { detail } = props
    const navigate = useNavigate()
    const [error, setError] = useState(false)
    const skillSelect = props.skills
    const roleSelect = props.roleList
    const companySelect = props.company
    const [data, setData] = useState({
        SKILL_ID: [],
        ROLES: [],
        COMPANIES: [],
        QUESTION_LEVEL: "",
        QUESTION_TITLE: "",
        ANSWER: "",
        SORT_NUMBER: "",
    })

    const handleReset = (e) => {
        e.preventDefault();
        id && navigate(constant.component.interviewQuestionList.url)
        window.location.reload()
        setError(false)
        setData(PrevState => ({
            ...PrevState,
            SKILL_ID: [],
            ROLES: [],
            COMPANIES: [],
            QUESTION_LEVEL: "",
            QUESTION_TITLE: "",
            ANSWER: "",
            SORT_NUMBER: "",
        }))
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setData({
            ...data,
            [name]: value
        })
    }

    const onSubmit = (e) => {
        e.preventDefault();

        if (data.SKILL_ID.length == 0 ||
            data.ROLES.length == 0 ||
            data.QUESTION_LEVEL.trim().length == 0 ||
            data.QUESTION_TITLE.trim().length == 0 ||
            data.SORT_NUMBER.toString().trim().length == 0) {
            setError(true)
            return false
        }

        const model = {
            SKILL_ID: data.SKILL_ID,
            ROLES: data.ROLES,
            COMPANIES: data.COMPANIES,
            QUESTION_LEVEL: data.QUESTION_LEVEL,
            QUESTION_TITLE: data.QUESTION_TITLE,
            ANSWER: data.ANSWER,
            SORT_NUMBER: data.SORT_NUMBER,
        }
        if (detail) {
            model.INTERVIEW_QUESTIONS_ID = detail.INTERVIEW_QUESTIONS_ID
            model.MODIFIED_BY = detail.MODIFIED_BY
        }
        props.onSubmit(model)
    }

    useEffect(() => {
        (id ? document.title = constant.title.UpdateInterviewQuestion : document.title = constant.title.AddInterviewQuestion)
        if (detail) {
            setData({
                SKILL_ID: detail && detail.SKILL && detail.SKILL.length > 0 && detail.SKILL.map((d, i) => {
                    return d.SKILL_ID
                }),
                ROLES: detail && detail.ROLE && detail.ROLE.length > 0 && detail.ROLE.map((d, i) => {
                    return d.ROLE_ID
                }),
                COMPANIES: detail && detail.COMPANY && detail.COMPANY.length > 0 && detail.COMPANY.map((d, i) => {
                    return d.COMPANY_ID
                }),
                QUESTION_LEVEL: detail.QUESTION_LEVEL,
                QUESTION_TITLE: detail.QUESTION_TITLE,
                ANSWER: detail.ANSWER,
                SORT_NUMBER: detail.SORT_NUMBER,
            })
        }
    }, [])



    return (
        <React.Fragment>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0" style={{ fontSize: "18px" }}>{id ? "Update Share Interview" : "Add Interview Questions"}</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="javascript: void(0);">Utilities</a></li>
                                            <li className="breadcrumb-item active">{id ? "Update Share Interview" : "Add Interview Questions"}</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">Interview Questions Information</h4>
                                        {
                                            props.showMsg && (props.response.status ?
                                                <SuccessMessage message={props.response.messageCode} />
                                                :
                                                <ErrorMessage message={props.response.error} />)
                                        }
                                        <div id="basic-example">
                                            <section>
                                                <form method="POST">
                                                    <div className="row">
                                                        <div className="col-lg-3">
                                                            <div className="mb-3">
                                                                <label for="basicpill-firstname-input">Skill <span className="text-danger">*</span></label>

                                                                <Multiselect
                                                                    options={skillSelect}
                                                                    onSelect={(selectedValue) => {
                                                                        setData({ ...data, SKILL_ID: [...data.SKILL_ID, selectedValue[selectedValue.length - 1].SKILL_ID] })
                                                                    }}
                                                                    displayValue="SKILL"
                                                                    name="SKILL_ID"
                                                                    placeholder='Select skill'
                                                                    selectedValues={detail ? detail.SKILL : []}
                                                                    onRemove={(selectedValue) => {
                                                                        setData({
                                                                            ...data, SKILL_ID: selectedValue.map((d, i) => {
                                                                                return d.SKILL_ID
                                                                            })
                                                                        })
                                                                    }}
                                                                />
                                                                {error && data.SKILL_ID.length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-3">
                                                            <div className="mb-3">
                                                                <label for="basicpill-firstname-input">Roles <span className="text-danger">*</span></label>
                                                                <Multiselect
                                                                    options={roleSelect}
                                                                    onSelect={(selectedValue) => {
                                                                        console.log("selectedValue", selectedValue)
                                                                        console.log("data", data)
                                                                        setData({ ...data, ROLES: [...data.ROLES, selectedValue[selectedValue.length - 1].ROLE_ID] })
                                                                    }}
                                                                    displayValue="ROLE_NAME"
                                                                    name="ROLES"
                                                                    selectedValues={detail ? detail.ROLE : []}
                                                                    placeholder='Select Role'
                                                                    onRemove={(selectedValues) => {
                                                                        setData({
                                                                            ...data, ROLES: selectedValues.map((d, i) => {
                                                                                return d.ROLE_ID
                                                                            })
                                                                        })
                                                                    }}
                                                                />
                                                                {error && data.ROLES.length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-3">
                                                            <div className="mb-3">
                                                                <label for="basicpill-firstname-input">Companies</label>
                                                                <Multiselect
                                                                    options={companySelect}
                                                                    onSelect={(selectedValue) => {
                                                                        setData({ ...data, COMPANIES: [...data.COMPANIES, selectedValue[selectedValue.length - 1].EMPLOYER_ID] })
                                                                    }}
                                                                    displayValue="COMPANY_NAME"
                                                                    name="COMPANIES"
                                                                    // value={data.COMPANIES}
                                                                    // onChange={handleChange}
                                                                    selectedValues={detail ? detail.COMPANY : []}
                                                                    placeholder='Select Company'
                                                                    onRemove={(selectedValues) => {
                                                                        setData({
                                                                            ...data, COMPANIES: selectedValues.map((d, i) => {
                                                                                return d.COMPANY_ID
                                                                            })
                                                                        })
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-3">
                                                            <div className="mb-3">
                                                                <label for="basicpill-firstname-input">Level <span className="text-danger">*</span></label>
                                                                <select
                                                                    className='form-select'
                                                                    name='QUESTION_LEVEL'
                                                                    value={data.QUESTION_LEVEL}
                                                                    onChange={handleChange}
                                                                >

                                                                    <option value=''>Select Level</option>
                                                                    <option value="B">Beginner</option>
                                                                    <option value="I">Intermediate</option>
                                                                    <option value="A">Advance</option>
                                                                </select>
                                                                {error && data.QUESTION_LEVEL.trim().length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="mb-3">
                                                                <label for="basicpill-address-input">Question <span className="text-danger">*</span></label>
                                                                <input
                                                                    type="text"
                                                                    className='form-control'
                                                                    // className={error && data.QUESTION_TITLE.trim().length == 0 ? 'form-control is-invalid' : 'form-control'}
                                                                    id="basicpill-firstname-input"
                                                                    placeholder="Enter Interview Question"
                                                                    name='QUESTION_TITLE'
                                                                    value={data.QUESTION_TITLE}
                                                                    onChange={handleChange}
                                                                />
                                                                {error && data.QUESTION_TITLE.trim().length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        id ? "" : <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="mb-3">
                                                                    <label for="basicpill-address-input">Answer</label>
                                                                    <textarea
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="basicpill-firstname-input"
                                                                        placeholder="Enter Question Answer"
                                                                        name='ANSWER'
                                                                        value={data.ANSWER}
                                                                        onChange={handleChange}
                                                                    ></textarea>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className="row">
                                                        <div className="col-lg-3">
                                                            <div className="mb-3">
                                                                <label for="basicpill-address-input">Sort Number <span className="text-danger">*</span></label>
                                                                <NumberFormat
                                                                    type="text"
                                                                    className="form-control"
                                                                    // className={error && data.SORT_NUMBER.toString().trim().length == 0 ? 'form-control is-invalid' : 'form-control'}
                                                                    id="basicpill-firstname-input"
                                                                    placeholder="Enter Sort Number"
                                                                    name='SORT_NUMBER'
                                                                    maxLength={10}
                                                                    value={data.SORT_NUMBER}
                                                                    onChange={handleChange}
                                                                />
                                                                {error && data.SORT_NUMBER.toString().trim().length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <button type="button" onClick={onSubmit} className="btn btn-info waves-effect btn-label waves-light"><i className="bx bx-check-double label-icon"></i>{id ? "Save Changes" : "Create Interview Questions"}</button>
                                                                &nbsp;&nbsp;
                                                                <button type="button" onClick={handleReset} className="btn btn-danger waves-effect btn-label waves-light"><i className="bx bx-block label-icon "></i> Cancel</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </section>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>


                        {/* <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">Interview Questions Information</h4>
                                        {
                                            props.showMsg && (props.response.status ?
                                                <SuccessMessage message={props.response.messageCode} />
                                                :
                                                <ErrorMessage message={props.response.error} />)
                                        }
                                        <div id="basic-example">
                                            <section>
                                                <form method="POST">
                                                    <div className="row">
                                                        <div className="col-lg-3">
                                                            <div className="mb-3">
                                                                <label for="basicpill-firstname-input">Skill <span className="text-danger">*</span></label>

                                                                <Multiselect
                                                                    options={skillSelect}
                                                                    onSelect={(selectedValue) => {
                                                                        setData({ ...data, SKILL_ID: [...data.SKILL_ID, selectedValue[selectedValue.length - 1].SKILL_ID] })
                                                                    }}
                                                                    displayValue="SKILL"
                                                                    name="SKILL_ID"
                                                                    placeholder='Select skill'
                                                                    selectedValues={detail ? detail.SKILL : []}
                                                                    onRemove={(selectedValue) => {
                                                                        setData({
                                                                            ...data, SKILL_ID: selectedValue.map((d, i) => {
                                                                                return d.SKILL_ID
                                                                            })
                                                                        })
                                                                    }}
                                                                />
                                                                {error && data.SKILL_ID.length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-3">
                                                            <div className="mb-3">
                                                                <label for="basicpill-firstname-input">Roles <span className="text-danger">*</span></label>
                                                                <Multiselect
                                                                    options={roleSelect}
                                                                    onSelect={(selectedValue) => {
                                                                        console.log("selectedValue", selectedValue)
                                                                        console.log("data", data)
                                                                        setData({ ...data, ROLES: [...data.ROLES, selectedValue[selectedValue.length - 1].ROLE_ID] })
                                                                    }}
                                                                    displayValue="ROLE_NAME"
                                                                    name="ROLES"
                                                                    selectedValues={detail ? detail.ROLE : []}
                                                                    placeholder='Select Role'
                                                                    onRemove={(selectedValues) => {
                                                                        setData({
                                                                            ...data, ROLES: selectedValues.map((d, i) => {
                                                                                return d.ROLE_ID
                                                                            })
                                                                        })
                                                                    }}
                                                                />
                                                                {error && data.ROLES.length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-3">
                                                            <div className="mb-3">
                                                                <label for="basicpill-firstname-input">Companies</label>
                                                                <Multiselect
                                                                    options={companySelect}
                                                                    onSelect={(selectedValue) => {
                                                                        setData({ ...data, COMPANIES: [...data.COMPANIES, selectedValue[selectedValue.length - 1].EMPLOYER_ID] })
                                                                    }}
                                                                    displayValue="COMPANY_NAME"
                                                                    name="COMPANIES"
                                                                    // value={data.COMPANIES}
                                                                    // onChange={handleChange}
                                                                    selectedValues={detail ? detail.COMPANY : []}
                                                                    placeholder='Select Company'
                                                                    onRemove={(selectedValues) => {
                                                                        setData({
                                                                            ...data, COMPANIES: selectedValues.map((d, i) => {
                                                                                return d.COMPANY_ID
                                                                            })
                                                                        })
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-3">
                                                            <div className="mb-3">
                                                                <label for="basicpill-firstname-input">Level <span className="text-danger">*</span></label>
                                                                <select
                                                                    className='form-select'
                                                                    name='QUESTION_LEVEL'
                                                                    value={data.QUESTION_LEVEL}
                                                                    onChange={handleChange}
                                                                >

                                                                    <option value=''>Select Level</option>
                                                                    <option value="B">Beginner</option>
                                                                    <option value="I">Intermediate</option>
                                                                    <option value="A">Advance</option>
                                                                </select>
                                                                {error && data.QUESTION_LEVEL.trim().length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="mb-3">
                                                                <label for="basicpill-address-input">Question <span className="text-danger">*</span></label>
                                                                <input
                                                                    type="text"
                                                                    className='form-control'
                                                                    // className={error && data.QUESTION_TITLE.trim().length == 0 ? 'form-control is-invalid' : 'form-control'}
                                                                    id="basicpill-firstname-input"
                                                                    placeholder="Enter Interview Question"
                                                                    name='QUESTION_TITLE'
                                                                    value={data.QUESTION_TITLE}
                                                                    onChange={handleChange}
                                                                />
                                                                {error && data.QUESTION_TITLE.trim().length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        id ? "" : <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="mb-3">
                                                                    <label for="basicpill-address-input">Answer</label>
                                                                    <textarea
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="basicpill-firstname-input"
                                                                        placeholder="Enter Question Answer"
                                                                        name='ANSWER'
                                                                        value={data.ANSWER}
                                                                        onChange={handleChange}
                                                                    ></textarea>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className="row">
                                                        <div className="col-lg-3">
                                                            <div className="mb-3">
                                                                <label for="basicpill-address-input">Sort Number <span className="text-danger">*</span></label>
                                                                <NumberFormat
                                                                    type="text"
                                                                    className="form-control"
                                                                    // className={error && data.SORT_NUMBER.toString().trim().length == 0 ? 'form-control is-invalid' : 'form-control'}
                                                                    id="basicpill-firstname-input"
                                                                    placeholder="Enter Sort Number"
                                                                    name='SORT_NUMBER'
                                                                    maxLength={10}
                                                                    value={data.SORT_NUMBER}
                                                                    onChange={handleChange}
                                                                />
                                                                {error && data.SORT_NUMBER.toString().trim().length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <button type="button" onClick={onSubmit} className="btn btn-info waves-effect btn-label waves-light"><i className="bx bx-check-double label-icon"></i>{id ? "Save Changes" : "Create Interview Questions"}</button>
                                                                &nbsp;&nbsp;
                                                                <button type="button" onClick={handleReset} className="btn btn-danger waves-effect btn-label waves-light"><i className="bx bx-block label-icon "></i> Cancel</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </section>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div> */}

                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
