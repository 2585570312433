import React, { useState, useEffect } from 'react'
import List from '../../component/Employers/ArchivedList'
import { archiveEmployerList, updateEmployerStatus } from '../../actions/employer'
import constant from '../../constant'

export default function ArchiveList() {

    const [list, setList] = useState([])
    const [count, setCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [showLoader, setShowLoader] = useState(true)

    useEffect(() => {
        document.title = constant.title.FeaturedEmployerList
        archiveEmployerLists(currentPage, "")
    }, [currentPage])


    const archiveEmployerLists = (page, KEYWORD) => {
        archiveEmployerList({ page: page, KEYWORD: KEYWORD }).then(res => {
            setShowLoader(false)
            if (res.status) {
                setList(res.result.list)
                setCount(res.result.count.total)
            }
            else {
                alert(res.error)
            }
        }).catch(err => {
            alert(err)
        })
    }

    const updateStatus = (model) => {
        updateEmployerStatus(model).then((res) => {
            if (res.status) {
                archiveEmployerLists(currentPage, "")
            }
        })
    }


    return (
        <React.Fragment>
            <List
                list={list}
                count={count}
                archiveEmployerLists={(page) => { archiveEmployerLists(page) }}
                updateStatus={updateStatus}
                setPage={setCurrentPage}
                setShowLoader={setShowLoader}
                showLoader={showLoader}
            />
        </React.Fragment>
    )
}
