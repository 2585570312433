import React, { useState, useCallback, useEffect } from 'react'
import Pagination from "react-js-pagination";
import moment from 'moment'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import constant from '../../constant';
import Loader from '../common/Loader';
import queryString from 'query-string'
import { capitalize, clearForm, getStorage } from '../../utils';
export default function EmployerList(props) {

    const [employerFullDetails, setEmployerFullDetails] = useState({})
    const [currentPage, setCurrentPage] = useState(1);
    const [note, setNote] = useState('')
    const [error, setError] = useState(false);
    const [state, setstate] = useState({
        emp_dt: getStorage(constant.keys.userDetails)
    })
    const { list, notes } = props
    const location = useLocation();
    const navigate = useNavigate()
    const { cp } = props


    const handlePageChange = (pageNumber) => {
        window.scroll(0, 0)
        setCurrentPage(pageNumber);
        navigate(location.pathname + '?p=' + pageNumber)
        props.setPage(pageNumber)
    };

    const updateStatus = (EMPLOYER_ID, STATUS) => {
        const model = {
            EMPLOYER_ID: EMPLOYER_ID,
            STATUS: STATUS === 'A' ? 'I' : 'A'
        }
        props.updateStatus(model);
    }

    const searchList = (activePage) => {
        setCurrentPage(activePage)
        props.getEmployerList(1)
    }
    const qParams = queryString.parse(location.search)

    const Addmessage = (e) => {
        const { name, value } = e.target
        setNote(value)
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if (note.trim().length === 0) {
            setError(false)
            return true
        }
        setNote('')

        props.createNote(note, employerFullDetails.EMPLOYER_ID)
    }

    const deleteEmpnotes = (NOTES_ID) => {
        props.delete(NOTES_ID, employerFullDetails.EMPLOYER_ID)
    }



    return (
        <React.Fragment>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0" style={{ fontSize: "18px" }}>Employers List</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="">Employers</a></li>
                                            <li className="breadcrumb-item active">Employers List</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <form className="row gy-12 gx-12 align-items-center">
                                            <div className="col-sm-2">
                                                <label
                                                    className="visually-hidden"
                                                    for="autoSizingInput"
                                                >
                                                    Name
                                                </label>
                                                Search Employer
                                            </div>
                                            <div className="col-sm-6">
                                                <label
                                                    className="visually-hidden"
                                                    for="autoSizingInputGroup"
                                                >
                                                    Username
                                                </label>
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="autoSizingInputGroup"
                                                        placeholder="Enter Company Name, Emil ID, Location, Contact person etc."
                                                        value={props.value}
                                                        onChange={(e) => {
                                                            props.keyword(e.target.value)
                                                        }}
                                                        onKeyDown={
                                                            (e) => {
                                                                if (e.key == 'Enter') {
                                                                    e.preventDefault()
                                                                    searchList()
                                                                }
                                                            }
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <button
                                                    type="button"
                                                    className="btn btn-success waves-effect btn-label waves-light"
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        searchList()
                                                    }}
                                                >
                                                    <i className="bx bx-check-double label-icon"></i>{" "}
                                                    Search
                                                </button>

                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">Latest Employers</h4>
                                        <div className="table-responsive">
                                            <table className="table align-middle table-nowrap table-hover">
                                                {!props.showLoader && <thead className="table-light">
                                                    <tr>
                                                        <th scope="col" style={{ width: "70px" }}>ID</th>
                                                        <th scope="col">Employer Information</th>
                                                        <th scope="col">JobzShala Stats</th>
                                                        <th scope="col">Profile Status</th>
                                                        <th scope="col">Notes </th>
                                                        <th scope="col" style={{ textAlign: "center" }}>Action</th>
                                                    </tr>
                                                </thead>}

                                                <tbody>
                                                    {props.showLoader ? <Loader /> : list && list.length > 0 && list.map((item, index) => {
                                                        return (
                                                            <tr>
                                                                <td style={{ color: "#ff8000", fontSize: "15px", fontFamily: "nunito" }}>
                                                                    {item.CUSTOM_EMPLOYER_ID}
                                                                </td>
                                                                <td>
                                                                    <h5 className="mb-1" data-bs-toggle="modal" data-bs-target={"#staticBackdrop11" + item.EMPLOYER_ID} style={{ fontSize: "14px" }} title={item.COMPANY_NAME}>
                                                                        <a onClick={(e) => { setEmployerFullDetails(item) }} href="javascript:void(0)" className="text-dark">{item.COMPANY_NAME.length > 25
                                                                            ? item.COMPANY_NAME.slice(0, 30) + "..." : item.COMPANY_NAME}</a></h5>


                                                                    <p className="text-muted mb-0" style={{ color: "#00add2" }} title={item.INDUSTRY}>{item?.INDUSTRY?.length > 25
                                                                        ? item.INDUSTRY.slice(0, 30) + "..." : item.INDUSTRY} <span className={item.STATUS === 'A' ? "badge border border-light rounded-circle bg-success p-1" : "badge border border-light rounded-circle bg-danger p-1"}>{item.STATUS === 'A' ? " " : " "}</span></p>
                                                                    <p className="text-muted mb-0" style={{ color: "#0080ff" }}><i className="bx bx-user align-middle me-1" style={{ fontSize: "16px", color: "#1894ed" }}></i> {capitalize(item.CONTACT_PERSON.trim())}</p>
                                                                    <p className="text-muted mb-0"><i className="bx bxs-envelope" style={{ color: "#fae60f" }} />&nbsp;{item.EMAIL}</p>
                                                                    <p className="text-muted mb-0"><i className="bx bxs-phone" style={{ color: "#299c0c" }} />&nbsp;{item.MOBILE}</p>
                                                                    <p className="text-muted mb-0"><span style={{ color: "#f46a6a" }}>Member Since</span> | {moment(item.CREATED_ON).format('DD-MM-YYYY')} </p>
                                                                </td>
                                                                <td>
                                                                    <h5 className="mb-1" style={{ fontSize: "14px" }}>{item.jobpost > 0 ? <td><a href="javascript:void(0);" ><Link to={constant.component.employerJobsPosted.url.replace(':id', item.EMPLOYER_ID)} className="text-dark">Jobs Posted ({item.jobpost})</Link></a></td>
                                                                        : <td><a data-bs-toggle="modal" data-bs-target="#staticBackdrop3" > Jobs Posted ({item.jobpost})</a></td>}</h5>
                                                                    <p className="text-muted mb-0">
                                                                        {item.featuredjob > 0 ? <td><a href="javascript:void(0);" className="text-dark" ><Link to={constant.component.employerfeaturedjobs.url.replace(':id', item.EMPLOYER_ID)}><span style={{ color: "#dd006f" }}>Featured Jobs ({item.featuredjob}) </span></Link></a></td>
                                                                            : <td><a data-bs-toggle="modal" data-bs-target="#staticBackdrop3" style={{ color: "#dd006f" }} > Featured Jobs ({item.featuredjob})</a></td>
                                                                        }</p>
                                                                    <p className="text-muted mb-0">
                                                                        {item.premiumjob > 0 ? <td><a> <Link to={constant.component.employerpremiumjobs.url.replace(':id', item.EMPLOYER_ID)}><span style={{ color: "#8000ff" }}>Premium Jobs ({item.premiumjob})</span></Link></a></td>
                                                                            : <td><a data-bs-toggle="modal" data-bs-target="#staticBackdrop3" style={{ color: "#8000ff" }} > Premium Jobs ({item.premiumjob})</a></td>
                                                                        }</p> <br />

                                                                </td>
                                                                <td>
                                                                    {/* Completeness <span className="badge rounded-pill bg-success">90%</span><br /> */}

                                                                    <span style={{ color: "008040" }}>Candidate Message : <span style={{ color: "#f46a6a" }}> ({item.messagecount}) </span> </span>
                                                                    <br />

                                                                    <span style={{ color: "#ad520c" }}>Subscription : <span className="text-dark"       >
                                                                        {item.LISTING_TYPE == 'P' ? <span style={{ fontSize: "13px", color: "#940c35" }}>Premium</span> :
                                                                            item.LISTING_TYPE == 'R' ? <span style={{ fontSize: "13px", color: "#0c5b94" }}>Regular</span> :
                                                                                item.LISTING_TYPE == 'F' ? <span style={{ fontSize: "13px", color: "#d6460d" }}>Featured</span> : ''} </span> </span>
                                                                    <br />
                                                                    <a href="javascript:void(0);" className="text-dark">
                                                                        <Link to={constant.component.EmployerSubscription.url.replace(':id', item.EMPLOYER_ID)}><span style={{ color: "#444444" }}>Transaction History  </span></Link></a>
                                                                    <br />
                                                                    <a href="javascript:void(0);" className="text-dark">
                                                                        <Link to={constant.component.EmployersReview.url.replace(':id', item.EMPLOYER_ID)}><span style={{ color: "#c80064" }}>Reviews : {" "} ({item.reviewcount})</span></Link></a>
                                                                    <br />

                                                                </td>
                                                                <td>


                                                                    <button
                                                                        type="button"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target={"#staticBackdropnote" + item.EMPLOYER_ID}
                                                                        className="btn btn-info waves-effect  waves-light"

                                                                        onClick={(e) => {
                                                                            e.preventDefault()
                                                                            setEmployerFullDetails(item)
                                                                            props.EmployerNotesList(item.EMPLOYER_ID)

                                                                        }}

                                                                    > Notes </button>

                                                                    <div className="modal fade" id={"staticBackdropnote" + item.EMPLOYER_ID} tabindex={-1} role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                                                                            <div className="modal-content">
                                                                                <div className="modal-header">
                                                                                    <h5 className="modal-title" id="staticBackdropLabel">Employer: <span style={{ color: 'red' }}>{employerFullDetails.COMPANY_NAME}</span> </h5>
                                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                </div>
                                                                                <div className='row'>
                                                                                    <div className='col-12'>
                                                                                        <div className="form-group px-3 py-3 mb-3">
                                                                                            <textarea
                                                                                                // style={{ height: "auto", width: "98%", borderColor: "#ddd", borderRadius: "4px", margin: "7px", padding: "8px 12px", letterSpacing: "normal", wordSpacing: "normal", textAlign: "start", whiteSpace: "pre-wrap", overflowWrap: "break-word" }}
                                                                                                value={note}
                                                                                                className={error && note.trim().length <= 0 ? "form-control is-invalid" : "form-control"}
                                                                                                name="NOTES"
                                                                                                onChange={
                                                                                                    Addmessage
                                                                                                }
                                                                                            ></textarea>
                                                                                            {error && note.trim().length <= 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ' '}

                                                                                            <input type="hidden" name="EMPLOYER_ID" value={item.EMPLOYER_ID} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {/* {
                                                                                    <div className='row'>
                                                                                        <div className='col-12'>
                                                                                            <div className='form-group px-3 mb-3'>
                                                                                                <label>Date Contacted</label>

                                                                                                <input className='form-control'
                                                                                                    type='datetime-local' />
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                                <div className='px-3'>
                                                                                    <div className='form-check'>
                                                                                        <input className='form-check-input'
                                                                                            name="flexRadioDefault"
                                                                                            type='radio'
                                                                                            id='contacted_indicator_yes'
                                                                                            onChange={() => { }}
                                                                                            value={'yes'}
                                                                                        />
                                                                                        <label className='px-2' for="contacted_indicator_yes">I got in touch with this employer</label>
                                                                                    </div>
                                                                                    <div className='form-check'>
                                                                                        <input className='form-check-input'
                                                                                            name="flexRadioDefault"
                                                                                            type='radio'
                                                                                            id='contacted_indicator_no'
                                                                                            value={'no'}
                                                                                            onChange={() => { }}
                                                                                        />
                                                                                        <label className='px-2' for="contacted_indicator_no">I have not contacted this employer</label>
                                                                                    </div>
                                                                                </div> */}
                                                                                <div className="form-group" style={{ padding: "8px 20px" }}>

                                                                                    <div style={{ padding: "4px 15px" }} >
                                                                                        <button className="btn btn-primary pull-right"
                                                                                            onClick={onSubmit}
                                                                                            style={{ color: "white", borderRadius: "4px", float: "right", marginBottom: "10px" }}>
                                                                                            Add Notes
                                                                                        </button>
                                                                                    </div>

                                                                                </div>

                                                                                <div className="modal-content" style={{ padding: "15px ", width: "96%", marginLeft: "15px" }}>

                                                                                    {notes && notes.map((ele, index) => {
                                                                                        return (
                                                                                            <ul className='em-note-box' style={{ borderBotton: '1px solid #eeeeee' }}>
                                                                                                <li className='employe-note-chat'>
                                                                                                    <div className="d-flex justify-content-between">
                                                                                                        <li>
                                                                                                            <div className='d-flex'>
                                                                                                                <div className='px-2'>
                                                                                                                    {ele.CREATED_BY == state.emp_dt.ADMIN_ID ? <img src={`${process.env.REACT_APP_BASE_URL}/admin/profile/pic/${state.emp_dt.PROFILE_PICTURE}`} alt="" style={{ width: '25px', height: "25px", objectFit: "cover", borderRadius: "50%" }} /> :
                                                                                                                        <img src={ele.PROFILE_PICTURE != null ? ele.PROFILE_PICTURE : "assets/images/users/avatar-2.webp"} alt="" style={{ width: '25px', height: "25px", objectFit: "cover", borderRadius: "50%" }} />}
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    <h6 style={{ color: "#168ede", marginBottom: '0px' }}>
                                                                                                                        {ele.CREATED_BY == state.emp_dt.ADMIN_ID ? state.emp_dt.FIRST_NAME + " " + state.emp_dt.LAST_NAME : ele.FIRST_NAME + " " + ele.LAST_NAME}
                                                                                                                    </h6>
                                                                                                                    <span>Note added : {""}{moment(ele.CREATED_ON).format('DD-MM-YYYY HH:mm A')}</span>
                                                                                                                    <div className='py-3'>
                                                                                                                        <span>{ele.NOTES}</span>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </div>

                                                                                                        </li>

                                                                                                        <span>
                                                                                                            {ele.CREATED_BY == state.emp_dt.ADMIN_ID ?
                                                                                                                <i onClick={() => deleteEmpnotes(ele.NOTES_ID)} className='fa fa-times text-danger' style={{ cursor: 'pointer' }}></i> : ""}
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </li>
                                                                                            </ul>
                                                                                        )
                                                                                    })

                                                                                    }

                                                                                </div>


                                                                                <div className="modal-footer">
                                                                                    <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </td>

                                                                <td>
                                                                    <ul className="list-inline contact-links mb-0" style={{ fontSize: "20px" }}>
                                                                        <li className="list-inline-item px-2">
                                                                            <a onClick={(e) => { setEmployerFullDetails(item) }} href="javascript: void(0);" title="View Client" data-bs-toggle="modal" data-bs-target={"#staticBackdrop11" + item.EMPLOYER_ID}><i className="bx bxs-zoom-in"></i></a>
                                                                            <div className="modal fade" id={"staticBackdrop11" + item.EMPLOYER_ID} tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                                                                                    <div className="modal-content">
                                                                                        <div className="modal-header">
                                                                                            <h5 className="modal-title" id="staticBackdropLabel">Employer Details -  <span style={{ color: 'red' }}> {employerFullDetails.COMPANY_NAME}</span></h5>
                                                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                        </div>
                                                                                        <div className="modal-body">
                                                                                            <p>{employerFullDetails &&
                                                                                                <table className="table table-striped mb-0">
                                                                                                    <tbody style={{ fontSize: "14px", whiteSpace: "normal" }}>
                                                                                                        <tr>
                                                                                                            <td style={{ width: "32%" }}>ID</td>
                                                                                                            <td>{employerFullDetails.CUSTOM_EMPLOYER_ID}</td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td>Company</td>
                                                                                                            <td>{employerFullDetails.COMPANY_NAME}</td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td>Industry</td>
                                                                                                            <td>{employerFullDetails.INDUSTRY}</td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td>Contact Person</td>
                                                                                                            <td>{employerFullDetails.CONTACT_PERSON}</td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td>Email</td>
                                                                                                            <td>{employerFullDetails.EMAIL}</td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td>Phone No.</td>
                                                                                                            <td>{employerFullDetails.MOBILE}</td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td>Member Since</td>
                                                                                                            <td>{moment(employerFullDetails.CREATED_ON).format('DD-MM-YYYY')}</td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td>Year Established</td>
                                                                                                            <td>{employerFullDetails.FOUNDED ? employerFullDetails.FOUNDED : '-'}</td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td>Address</td>
                                                                                                            <div>{employerFullDetails.LOCATION ? employerFullDetails.LOCATION : 'Not Available'} </div>
                                                                                                        </tr>

                                                                                                        <tr>
                                                                                                            <td>Logo</td>
                                                                                                            <td><img src={`${process.env.REACT_APP_BASE_URL}/company/logo/${employerFullDetails.COMPANY_LOGO}`} style={{ width: "100px" }} /></td>
                                                                                                        </tr>

                                                                                                        <tr>
                                                                                                            <td>Profile Status</td>
                                                                                                            <td><span className={employerFullDetails.STATUS === 'A' ? "badge rounded-pill bg-success" : "badge rounded-pill bg-danger"}>{employerFullDetails.STATUS === 'A' ? 'ACTIVE' : 'INACTIVE'}</span></td>
                                                                                                        </tr>
                                                                                                    </tbody>
                                                                                                </table>}
                                                                                            </p>
                                                                                        </div>
                                                                                        <div className="modal-footer">
                                                                                            <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </li>
                                                                        <li className="list-inline-item px-2">
                                                                            <Link to={constant.component.editemployers.url.replace(':id', item.EMPLOYER_ID).replace(':pageNo', cp)} title="Edit Employer"><i className="bx bx-edit"></i></Link>
                                                                        </li>
                                                                        <li className="list-inline-item px-2">
                                                                            <a href="javascript: void(0)" onClick={() => updateStatus(item.EMPLOYER_ID, item.STATUS)} style={{ color: 'red' }} title={item.STATUS == 'A' ? "Block Employer" : "Unblock Employer"}>
                                                                                {
                                                                                    item.STATUS == 'A' ? <i className="bx bx-trash"></i> : <i class="bx bx-log-in"></i>
                                                                                }
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </td>

                                                            </tr>

                                                        )
                                                    })

                                                    }

                                                    {!props.showLoader && list.length == 0 && < td colSpan={10} className="text-center text-danger">No Employer Record Found</td>}

                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="pagination pagination-rounded justify-content-center mt-4">
                                                    <Pagination
                                                        activePage={qParams?.p ? +qParams?.p :
                                                            qParams?.pageNo ? Number(qParams.pageNo) : currentPage}
                                                        itemsCountPerPage={10}
                                                        totalItemsCount={props.count}
                                                        pageRangeDisplayed={5}
                                                        onChange={handlePageChange}
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>

    )
}
