import React, { useEffect, useState } from 'react'
import { archiveSkill, getSkillList } from '../../../actions/utilitiesAction'
import AddSkillList from '../../../component/Utilities/Skills/SkillList'
import constant from '../../../constant'
import queryString from 'query-string'
import { useLocation } from "react-router-dom";

const SkillList = () => {

    const [List, setList] = useState(null)
    const [count, setCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [keyword, setKeyword] = useState('')
    const [showLoader, setShowLoader] = useState(false)
    const location = useLocation()


    useEffect(() => {
        const qParams = queryString.parse(location.search)
        getSkillsLists(qParams?.p ? qParams?.p : qParams?.pageno ? qParams.pageno : currentPage);
        document.title = constant.title.SkillList
    }, [currentPage])

    const getSkillsLists = (page) => {
        setShowLoader(true)
        getSkillList({ KEYWORD: keyword, page: page }).then((res) => {
            if (res.status) {
                setShowLoader(false)
                setList(res.result.res)
                setCount(res.result.count.total)
            }
        }).catch((err) => {
            alert(err)
        })
    }

    const updateStatus = (model) => {
        archiveSkill(model).then((res) => {
            if (res.status) {
                getSkillsLists(currentPage)
            }
            else {
                alert(res.error)
            }
        }).catch((err) => {
            alert(err)
        })
    }
    return (
        <React.Fragment>
            <AddSkillList
                List={List}
                setList={setList}
                updateStatus={updateStatus}
                getSkillsLists={(page) => { getSkillsLists(page) }}
                count={count}
                setPage={setCurrentPage}
                setKeyword={setKeyword}
                showLoader={showLoader}
                cpage={currentPage}
            />
        </React.Fragment>
    )
}
export default SkillList