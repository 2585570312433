import React, { useState, useEffect } from 'react'
import constant from '../../../constant'
import Discussion from '../../../component/Utilities/DiscussionForm/Discussionforumactivethread'
import { updateDiscussionforumactivethread, archiveDiscussionforumactivethread, deleteDiscussionthread, Discussionthreadvalue } from '../../../actions/utilitiesAction'
import Swal from 'sweetalert2'
import { ForumCategoryList } from '../../../actions/AddForumAction'
const Discussionforumactivethread = () => {

    const [response, setResponse] = React.useState({})
    const [showMsg, setShowMsg] = React.useState(false)
    const [data, setData] = useState(null)
    const [List, setList] = useState([])
    const [count, setCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [category, setCategory] = useState([])
    const [showLoader, setShowLoader] = useState(true)


    useEffect(() => {

        Discussionforumactivethreadlist(currentPage)
        document.title = constant.title.Discussionforumactivethread

    }, [currentPage])

    const Discussionforumactivethreadlist = (page) => {

        updateDiscussionforumactivethread({ page: page }).then((res) => {

            if (res.status) {
                setList(res.result.list)
                setCount(res.result.count.Total)
                setData(res.result)
                setShowLoader(false)
            }
            else {
                alert(res.error)
            }
        }).catch((err) => {
            alert(err)
        })

        ForumCategoryList(page).then((res) => {
            if (res.status) {
                setCategory(res.result.list)
            }
        }).catch(err => {
            alert(err)
        })
    }

    const updateStatus = (model) => {
        archiveDiscussionforumactivethread(model).then((res) => {
            if (res.status) {

                Discussionforumactivethreadlist(currentPage)
            }
            else {
                alert(res.error)
            }
        }).catch((err) => {
            alert(err)
        })
    }

    const onSubmit = (model) => {
        Discussionthreadvalue(model).then((res) => {
            if (res.status) {

            } else {
                setResponse(res)
                setShowMsg(true)
                setTimeout(() => {
                    setShowMsg(false)
                }, 1000)
            }
        }).catch((err) => {
            alert(err)
        })
    }

    const deleteDiscussionthreadLists = (DISCUSSION_THREAD_ID) => {
        deleteDiscussionthread({ DISCUSSION_THREAD_ID: DISCUSSION_THREAD_ID }).then((res) => {
            if (res.status) {
                Swal.fire(
                    'Deleted!',
                    'Your enquiry has been deleted.',
                    'success'
                )
                Discussionforumactivethreadlist(currentPage)

            }
            else {
                Swal.fire(
                    (res.result.error)
                )
            }
        })
    }

    return (
        <React.Fragment>{data &&

            <Discussion
                list={List}
                category={category}
                setList={setList}
                Discussionforumactivethreadlist={(page => Discussionforumactivethreadlist(page))}
                count={count}
                updateStatus={updateStatus}
                setPage={setCurrentPage}
                onSubmit={onSubmit}
                response={response}
                showMsg={showMsg}
                showLoader={showLoader}
                deleteDiscussionthreadLists={deleteDiscussionthreadLists}
            />}
        </React.Fragment>
    )
}

export default Discussionforumactivethread
