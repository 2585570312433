import React, { useState } from 'react'
import constant from '../../../constant';
import { Link } from 'react-router-dom'
import { capitalize } from '../../../utils'
import moment from 'moment';
import Swal from 'sweetalert2';
import Shimmer from '../../common/Shimmer';
export default function LatestCandidates(props) {


    const { candidatelist } = props;
    const [candidateFullDetails, setCandidateFullDetails] = useState({})

    const archievecandidate = (CANDIDATE_ID, STATUS) => {

        const model = {
            CANDIDATE_ID: CANDIDATE_ID,
            STATUS: STATUS === 'U' ? 'A' : 'U'
        }
        props.archievecandidate(model);
    }


    const GetResume = () => {
        Swal.fire({
            icon: "error",
            text: "Resume Not Available",
            timer: 2000,
            showCancelButton: false,
            showConfirmButton: false,
        });
    }



    return (
        <React.Fragment>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card" >
                        <div className="card-body">
                            <h4 className="card-title mb-4">Latest Candidates</h4>
                            <div className="table-responsive">
                                <table className="table align-middle table-nowrap table-hover">
                                    <thead className="table-light">
                                        <tr>
                                            <th scope="col" style={{ width: "70px" }}>ID</th>
                                            <th scope="col">Candidate Information</th>
                                            <th scope="col">JobzShala Stats</th>
                                            <th scope="col">Profile Status</th>
                                            <th scope="col">Other Information </th>
                                            {/* <th scope="col">Action</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>


                                        {props.candidatelist && props.candidatelist.length > 0 ? props.candidatelist.map((ele, index) => {
                                            if (index <= 2) {
                                                return (
                                                    <tr key={index}>
                                                        <td
                                                            style={{
                                                                color: "#ff8000",
                                                                fontFamily: "nunito",
                                                            }}
                                                        >
                                                            {ele.CANDIDATE_ID}
                                                        </td>
                                                        <td>
                                                            <h5 className="font-size-14 mb-1">
                                                                <a href="" className="text-dark"><Link to={constant.component.CandidatesProfile.url.replace(':id', ele.CANDIDATE_ID)}>
                                                                    <span style={{ color: "#000" }}>  {capitalize(ele.CANDIDATE_NAME)}</span></Link>
                                                                </a>
                                                            </h5>
                                                            <p className="text-muted mb-0" style={{ color: "#00add2 !important" }}>
                                                                <span className={ele.WORK_STATUS === 'F'}>{ele.WORK_STATUS === 'F' ? 'Fresher' : 'Experience'}</span>&nbsp;
                                                                {/* {ele.WORK_STATUS==='F'?'Fresher':} */}
                                                                <span className="badge border border-light rounded-circle bg-success p-1">
                                                                    <span className="visually-hidden">
                                                                        Online
                                                                    </span>
                                                                </span>
                                                            </p>
                                                            <p className="text-muted mb-0">
                                                                {ele.EMAIL_ID}
                                                            </p>
                                                            <p className="text-muted mb-0">
                                                                {ele.PHONENO}
                                                            </p>
                                                            {/* <p> Completeness <span className="badge rounded-pill bg-success">90%</span></p> */}
                                                        </td>
                                                        <td>
                                                            <h5 className="font-size-14 mb-1">
                                                                <a
                                                                    // href="?status=all"
                                                                    target="_blank"
                                                                    className="text-dark"
                                                                ><Link to={constant.component.JobApplied.url.replace(':id', ele.CANDIDATE_ID)}>
                                                                        <span style={{ color: "#000" }}>
                                                                            Jobs Applied&nbsp;({ele.appliedJobsCount ? ele.appliedJobsCount : '0'})
                                                                        </span>
                                                                    </Link>
                                                                </a>
                                                            </h5>
                                                            <p className="text-muted mb-0">
                                                                <a
                                                                    // href="javascript:void(0);"
                                                                    className="text-dark"
                                                                ><Link to={constant.component.JobApplied.url.replace(':id', ele.CANDIDATE_ID)}>
                                                                        <span style={{ color: "#c80064" }}>
                                                                            Shortlisted&nbsp;({ele.shortlistedJobsCount ? ele.shortlistedJobsCount : '0'})
                                                                        </span></Link>
                                                                </a>
                                                            </p>{" "}
                                                            <a
                                                                // href="?status=rejected"
                                                                className="text-dark"
                                                            ><Link to={constant.component.JobApplied.url.replace(':id', ele.CANDIDATE_ID)}>
                                                                    <span style={{ color: "#ff0000" }}>
                                                                        Rejected&nbsp;({ele.rejectJobsCount ? ele.rejectJobsCount : '0'})
                                                                    </span>
                                                                </Link>
                                                            </a>{" "}
                                                            <br />

                                                            <Link to={constant.component.JobApplied.url.replace(':id', ele.CANDIDATE_ID)}>
                                                                <span style={{ color: "#ff0000" }}>
                                                                    Saved Jobs&nbsp;({ele.savedJobsCount ? ele.savedJobsCount : '0'})
                                                                </span>
                                                            </Link>
                                                            <br />{" "}
                                                            <p></p>
                                                        </td>
                                                        <td style={{ verticalAlign: "top" }} >
                                                            {" "}
                                                            {ele.RESUME_FILE == null ?
                                                                <a className="text-dark">
                                                                    <span style={{ color: "#808040" }}>
                                                                        Download Resume-NA
                                                                    </span>
                                                                </a> : <a download target='_blank' href={`${process.env.REACT_APP_BASE_URL}/candidate-doc-file/${ele.CANDIDATE_ID}/${ele.RESUME_FILE}`}
                                                                    className="text-dark"
                                                                >
                                                                    <span style={{ color: "#808040" }}>
                                                                        Download Resume{" "}
                                                                        <i className="bx bxs-cloud-download"></i>{" "}
                                                                    </span>
                                                                </a>}
                                                            {" "}
                                                            <br />
                                                            {/* <table cellspacing="2px" cellPadding="2px">
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{ paddingRight: "42px" }}>
                                                                            •{" "}
                                                                            <a
                                                                                //href="javascript:void(0);"
                                                                                className="text-dark"
                                                                            >
                                                                                <Link to={constant.component.CandidatesProfile.url.replace(':id', ele.CANDIDATE_ID)}>
                                                                                    <span style={{ color: "#004e9b" }}>
                                                                                        Education
                                                                                    </span></Link>
                                                                            </a>{" "}
                                                                            {ele.educationcount === 0 ? "" : <i className="bx bxs-badge-check"></i>}
                                                                        </td>
                                                                        <td>&nbsp;</td>
                                                                        <td>
                                                                            •{" "}
                                                                            <a className="text-dark">
                                                                                <Link to={constant.component.CandidatesProfile.url.replace(':id', ele.CANDIDATE_ID)}>
                                                                                    <span style={{ color: "#004e9b" }}>
                                                                                        Headline
                                                                                    </span>
                                                                                </Link>
                                                                            </a> {" "}
                                                                            {ele.RESUME_HEADLINE == null ? '' : <i className="bx bxs-badge-check"></i>}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            •{" "}
                                                                            <a
                                                                                //href="javascript:void(0);"
                                                                                className="text-dark"
                                                                            ><Link to={constant.component.CandidatesProfile.url.replace(':id', ele.CANDIDATE_ID)}>
                                                                                    <span onClick={(ele.CANDIDATE_ID)} style={{ color: "#004e9b" }}>
                                                                                        Career Profile
                                                                                    </span></Link>
                                                                            </a>{" "}
                                                                            {ele.EXPECTED_SALARY_LACS === null ? '' : <i className="bx bxs-badge-check"></i>}
                                                                        </td>
                                                                        <td>&nbsp;</td>
                                                                        <td>
                                                                            •{" "}
                                                                            <a
                                                                                // href="javascript:void(0);"
                                                                                className="text-dark"
                                                                            ><Link to={constant.component.CandidatesProfile.url.replace(':id', ele.CANDIDATE_ID)}>
                                                                                    <span style={{ color: "#004e9b" }}>
                                                                                        IT Skills
                                                                                    </span></Link>
                                                                            </a>{" "}
                                                                            {ele.itskills === 0 ? "" : <i className="bx bxs-badge-check"></i>}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            •{" "}
                                                                            <a
                                                                                // href="javascript:void(0);"
                                                                                className="text-dark"
                                                                            ><Link to={constant.component.CandidatesProfile.url.replace(':id', ele.CANDIDATE_ID)}>
                                                                                    <span style={{ color: "#004e9b" }}>
                                                                                        Employment
                                                                                    </span></Link>
                                                                            </a>{" "}
                                                                            {ele.CompanyDeatails.length == 0 ? "" : <i className="bx bxs-badge-check"></i>}
                                                                        </td>
                                                                        <td>&nbsp;</td>
                                                                        <td>
                                                                            •{" "}
                                                                            <a
                                                                                //href="javascript:void(0);"
                                                                                className="text-dark"
                                                                            ><Link to={constant.component.CandidatesProfile.url.replace(':id', ele.CANDIDATE_ID)}>
                                                                                    <span style={{ color: "#004e9b" }}>
                                                                                        Key Skills
                                                                                    </span></Link>
                                                                            </a>{" "}
                                                                            {ele.skills.length == 0 ? "" : <i className="bx bxs-badge-check"></i>}
                                                                        </td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            •{" "}
                                                                            <a
                                                                                // href="javascript:void(0);"
                                                                                className="text-dark"
                                                                            ><Link to={constant.component.CandidatesProfile.url.replace(':id', ele.CANDIDATE_ID)}>
                                                                                    <span style={{ color: "#004e9b" }}>
                                                                                        Profile Summary
                                                                                    </span></Link>
                                                                            </a>{" "}
                                                                            {ele.PROFILE_SUMMARY === null ? '' : <i className="bx bxs-badge-check"></i>}
                                                                        </td>
                                                                        <td>&nbsp;</td>
                                                                        <td>
                                                                            •{" "}
                                                                            <a
                                                                                //href="javascript:void(0);"
                                                                                className="text-dark"
                                                                            ><Link to={constant.component.CandidatesProfile.url.replace(':id', ele.CANDIDATE_ID)}>
                                                                                    <span style={{ color: "#004e9b" }}>
                                                                                        Projects
                                                                                    </span></Link>
                                                                            </a>{" "}
                                                                            {ele.projectcount === 0 ? "" : <i className="bx bxs-badge-check"></i>}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            •{" "}
                                                                            <a
                                                                                //href="javascript:void(0);"
                                                                                className="text-dark"
                                                                            ><Link to={constant.component.CandidatesProfile.url.replace(':id', ele.CANDIDATE_ID)}>
                                                                                    <span style={{ color: "#004e9b" }}>
                                                                                        Personal Details
                                                                                    </span></Link>
                                                                            </a>{" "}
                                                                            {ele.DOB === null ? "" : <i className="bx bxs-badge-check"></i>}
                                                                        </td>
                                                                        <td>&nbsp;</td>
                                                                        <td>
                                                                            •{" "}
                                                                            <a
                                                                                //href="javascript:void(0);"
                                                                                className="text-dark"
                                                                            ><Link to={constant.component.CandidatesProfile.url.replace(':id', ele.CANDIDATE_ID)}>
                                                                                    <span style={{ color: "#004e9b" }}>
                                                                                        Accomplishments
                                                                                    </span></Link>
                                                                            </a>{" "}
                                                                            {ele.certifications.length == 0 ? "" : <i className="bx bxs-badge-check"></i>}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table> */}
                                                        </td>


                                                        <td>

                                                            <a
                                                                type="button"
                                                                // data-bs-toggle="modal"
                                                                //data-bs-target="#staticBackdrop4"
                                                                // data-bs-target="#staticBackdrop4"
                                                                className="btn btn-info waves-effect btn-label waves-light"
                                                                // onClick={() => (candidateFullDetails.CANDIDATE_ID)}>
                                                                // onClick={(e) => { setCandidateFullDetails(ele) }}
                                                                href={constant.component.CandidatesProfile.url.replace(':id', ele.CANDIDATE_ID)}

                                                            >
                                                                <i className="bx bx-street-view label-icon "></i> View Details
                                                            </a>
                                                        </td>
                                                    </tr>
                                                )
                                            }

                                        }) : (props.candidatelist && props.candidatelist.length == 0 ? < td colSpan={10} className="text-center text-danger pt-3">No Candidate Record Found</td> : <Shimmer />)
                                        }

                                        {/* <tr>
                                            <td style={{ color: "#ff8000", fontSize: "15px", fontFamily: "nunito" }}>
                                                RZG000091
                                            </td>
                                            <td>
                                                <h5 className=" mb-1"><a href="" className="text-dark">Satya Prakash</a></h5>
                                                <p className="text-muted mb-0" style={{ color: "#00add2 !important" }}>Fresher <span className="badge border border-light rounded-circle bg-success p-1"><span className="visually-hidden">Online</span></span></p>
                                                <p className="text-muted mb-0">satyaprakash@gmail.com</p>
                                                <p className="text-muted mb-0">99902434443</p>
                                                <p> Completeness <span className="badge rounded-pill bg-success">90%</span></p>
                                                <p>

                                                    <a href="javascript:void(0);" className="text-dark"><span style={{ color: "#ff00ff" }}>Reviews(3) </span></a>
                                                </p>
                                            </td>
                                            <td>
                                                <h5 className=" mb-1"><a href="javascript:void(0);" className="text-dark">Jobs Applied (12)</a></h5>
                                                <p className="text-muted mb-0"><a href="javascript:void(0);" className="text-dark"><span style={{ color: "#c80064" }}>Shortlisted (2) </span></a> </p>
                                                <a href="javascript:void(0);" className="text-dark"><span style={{ color: "#ff0000" }}>Rejected (5)</span></a> <br />
                                                <a href="javascript:void(0);" className="text-dark"><span style={{ color: "#008040" }}>Subscription (2) </span></a>
                                                <br />
                                                <a href="javascript:void(0);" className="text-dark"><span style={{ color: "#009999" }}>Saved Jobs (23)</span></a>
                                                <a href="javascript:void(0);" className="text-dark"> <br /><span style={{ color: "#c80064" }}>Reviews(3) </span></a> <br />
                                            </td>
                                            <td style={{ verticalAlign: "top" }}> <a href="javascript:void(0);" className="text-dark"><span style={{ color: "#808040" }}>Download Resume <i className="bx bxs-cloud-download"></i> </span></a> <br />
                                                <table cellspacing="2px" cellpadding="2px">
                                                    <tbody><tr>
                                                        <td style={{ paddingRight: "42px" }}>• <a href="javascript:void(0);" className="text-dark"><span style={{ color: "#004e9b" }}>Education</span></a> <i className="bx bxs-badge-check"></i></td>
                                                        <td>&nbsp;</td>
                                                        <td>• <a href="javascript:void(0);" className="text-dark"><span style={{ color: "#004e9b" }}>Headline</span></a></td>
                                                    </tr>
                                                        <tr>
                                                            <td>• <a href="javascript:void(0);" className="text-dark"><span style={{ color: "#004e9b" }}>Career Profile</span></a></td>
                                                            <td>&nbsp;</td>
                                                            <td>• <a href="javascript:void(0);" className="text-dark"><span style={{ color: "#004e9b" }}>IT Skills</span></a></td>
                                                        </tr>
                                                        <tr>
                                                            <td>• <a href="javascript:void(0);" className="text-dark"><span style={{ color: "#004e9b" }}>Employment</span></a> <i className="bx bxs-badge-check"></i></td>
                                                            <td>&nbsp;</td>
                                                            <td>• <a href="javascript:void(0);" className="text-dark"><span style={{ color: "#004e9b" }}>Key Skills</span></a></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>• <a href="javascript:void(0);" className="text-dark"><span style={{ color: "#004e9b" }}>Profile Summary</span></a></td>
                                                            <td>&nbsp;</td>
                                                            <td>• <a href="javascript:void(0);" className="text-dark"><span style={{ color: "#004e9b" }}>Projects</span></a></td>
                                                        </tr>
                                                        <tr>
                                                            <td>• <a href="javascript:void(0);" className="text-dark"><span style={{ color: "#004e9b" }}>Personal Details</span></a></td>
                                                            <td>&nbsp;</td>
                                                            <td>• <a href="javascript:void(0);" className="text-dark"><span style={{ color: "#004e9b" }}>Accomplishments</span></a></td>
                                                        </tr>

                                                    </tbody></table>

                                            </td>
                                            <td>


                                                <button type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop3" className="btn btn-info waves-effect btn-label waves-light"><i className="bx bx-street-view label-icon "></i> View Details</button>

                                                <div className="modal fade" id="staticBackdrop3" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title" id="staticBackdropLabel">Satya Prakash - Information</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>

                                                            <div className="modal-body">
                                                                <p>
                                                                </p><table className="table table-striped mb-0">
                                                                    <tbody>

                                                                        <tr>
                                                                            <td>Skills</td>
                                                                            <td>PHP, Lamp, Java</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Certifications</td>
                                                                            <td>GCP, CSM</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Current Company</td>
                                                                            <td>Mount Talent</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                                <p></p>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>                       </td>

                                            <td>
                                                <ul className="list-inline  contact-links mb-0 font-size-20">
                                                    <li className="list-inline-item px-2">
                                                        <a href="javascript: void(0);" title="View Client" data-bs-toggle="modal" data-bs-target="#staticBackdrop"><i className="bx bxs-zoom-in"></i></a>
                                                    </li>
                                                    <li className="list-inline-item px-2">
                                                        <a href="javascript: void(0);" title="Edit User"><i className="bx bx-edit"></i></a>
                                                    </li>
                                                    <li className="list-inline-item px-2">
                                                        <a href="javascript: void(0);" style={{ color: "red" }} title="Delete User"><i className="bx bx-trash"></i></a>
                                                    </li>
                                                </ul>

                                                <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title" id="staticBackdropLabel">Candidate Details</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>
                                                            <div className="modal-body">
                                                                <p>
                                                                </p><table className="table table-striped mb-0">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td style={{ width: "32%" }}>ID</td>
                                                                            <td>RZG000091</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Name</td>
                                                                            <td>Satya Prakash</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Skills</td>
                                                                            <td>PHP, Lamp, Java, GCP</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Certification</td>
                                                                            <td>GCP, CSM</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Email</td>
                                                                            <td>satyaprakash@gmail.co</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Phone No.</td>
                                                                            <td>99902434443</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Current Organization</td>
                                                                            <td>Mount Talent</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Notice Period</td>
                                                                            <td>Immediate Joiners</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Address</td>
                                                                            <td>H - 12, GNA2, Noida Extension</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Featured Profile Subscription</td>
                                                                            <td>No</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td>Profile Status</td>
                                                                            <td><span className="badge rounded-pill bg-success">LIVE</span></td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                                <p></p>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr> */}
                                    </tbody>
                                </table>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <Link to={constant.component.totalcandidates.url} className="btn btn-primary waves-effect btn-label waves-light"><i className="bx bxs-right-arrow-square label-icon"></i> View All Candidates</Link>
                                    {/* <button type="button" className="btn btn-primary waves-effect btn-label waves-light"><i className="bx bxs-right-arrow-square label-icon"></i> View All Candidates</button> */}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div >
        </React.Fragment >
    )
}
