import React, { useEffect } from 'react'
import AddAddressProoftype from '../../component/Employers/AddAddressProofType'
import constant from '../../constant';
import { createAddressproofType } from '../../actions/employer'
import { useState } from 'react';

export default function AddAddressProofType() {

    const [response, setResponse] = useState({})
    const [showMsg, setShowMsg] = useState(false)


    useEffect(() => {
        document.title = constant.title.AddAddressProofType;
    }, [])


    const onSubmit = (model) => {
        createAddressproofType(model).then(res => {
            setResponse(res)
            setShowMsg(true)
            setTimeout(() => {
                setShowMsg(false)
            }, 7000)
        })
            .catch(err => {
                alert(err)
            })
    }

    return (
        <React.Fragment>
            <AddAddressProoftype onSubmit={onSubmit} response={response} showMsg={showMsg} />
        </React.Fragment>
    )
}
