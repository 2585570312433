import React, { useState } from 'react'
import Pagination from 'react-js-pagination';



export default function EmployerSubscription(props) {

  const { Subscription } = props
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (pageNumber) => {

    setCurrentPage(pageNumber);
    props.setPage(pageNumber)
  };

  props.setPage(currentPage)





  return (
    <React.Fragment>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Employers Subscription</h4>
                  <div>
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">Employers</li>
                      <li className="breadcrumb-item active">Subscription</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table align-middle table-nowrap table-hover">
                        <thead className="table-light">
                          <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Candidate Information</th>
                            <th scope="col">Subscription Details</th>
                            <th scope="col">Transaction History</th>
                          </tr>
                        </thead>
                        <tbody>


                          {Subscription && Subscription.length > 0 && Subscription.map((item, index) => {
                            return (

                              <tr>
                                <td style={{ color: "#ff8000", fontSize: "14px", fontFamily: "nunito" }}>
                                  {10 * currentPage + (index + 1) - 10}
                                </td>
                                <td  >
                                  <h5 className=" mb-1" style={{ fontSize: "14px", width: "200px" }}><a className="text-dark">{item.COMPANY_NAME}</a></h5>
                                  <p className="text-muted mb-0"> <span style={{ color: "#00add2 " }}>Industry</span> : {item.industry}</p>
                                  <p className="text-muted mb-0">  <i className="bx bx-user font-size-16 align-middle me-1"> <span style={{ color: "#0080ff ", fontFamily: " sans-serif", fontSize: "14px" }}>{item.CONTACT_PERSON}</span></i></p>
                                  <p className="text-muted mb-0">{item.EMAIL}</p>
                                  <p className="text-muted mb-0"><span style={{ color: "#f46a6a" }}>Purchase On</span> | {item.START_DATE}</p>
                                </td>

                                <td >

                                  <h5 className=" mb-1" style={{ fontSize: "14px", width: "200px" }}><a href="" className="text-dark">{item.PACKAGE_TITE} </a></h5>
                                  <p className="text-muted mb-0">Quantity : {item.QTY}
                                  </p>
                                  <p className="text-muted mb-0">Used : 1</p>
                                  <p className="text-muted mb-0">Available Credit : 1</p>
                                  <p className="text-muted mb-0">View job Posting : {item.jobpost}</p>
                                </td>
                                <td >
                                  Transaction ID : <span style={{ color: " #008040" }}>{item.SUBSCRIPTION_TXN_ID ? item.SUBSCRIPTION_TXN_ID : 'null'} </span><br />
                                  Amount : <span style={{ color: " #008040" }}>{item.AMOUNT ? item.AMOUNT : 'null'} </span><br />
                                  payment Status : <span style={{ color: "#008040" }}>{item.PAYMENT_STATUS == 'S' ?
                                    <span >Successful</span> :
                                    item.PAYMENT_STATUS == 'F' ?
                                      <span >Failure</span> :
                                      item.PAYMENT_STATUS == "P" ? <span> Processing  </span> : ''
                                  }
                                  </span><br />
                                  payment Mode : <span style={{ color: " #008040" }}>UPI </span><br />
                                  Validity : <span style={{ color: " #f46a6a" }}>(End Date) {item.END_DATE}</span><br />
                                </td>
                              </tr>
                            )
                          })
                          }
                          {
                            <>
                              {
                                Subscription.length == 0 &&
                                <td colSpan={10} className="text-center text-danger pt-3">No Record Found</td>
                              }
                            </>
                          }



                        </tbody>
                      </table>

                    </div>

                    <div className="pagination pagination-rounded justify-content-center mt-4">

                      <Pagination
                        activePage={currentPage}
                        itemsCountPerPage={10}
                        totalItemsCount={props.count}
                        pageRangeDisplayed={10}
                        onChange={handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>




          </div>

        </div>
      </div>
    </React.Fragment>
  )
}

