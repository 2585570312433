import React from 'react'
import { useState, useEffect } from 'react';
import Add from '../../../component/Utilities/Article/AddArticleCategory';
import { updateArticleCategory, ArticleCategorybyId } from '../../../actions/utilitiesAction';
import constant from '../../../constant';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
export default function EditArticleCategory(props) {

    const [response, setResponse] = React.useState({})
    const [showMsg, setShowMsg] = React.useState(false)
    const navigate = useNavigate()
    const params = useParams()
    const [data, setData] = useState(null)

    useEffect(() => {
        ArticleCategorybyId(params.id).then((res) => {
            if (res.status) {
                setData(res.result)
            }
            else {
                setResponse(res)
                setShowMsg(true)
                setTimeout(() => {
                    setShowMsg(false)
                }, 7000)
            }
        }).catch(err => {
            alert(err)
        })
    }, [])

    const onSubmit = (model) => {

        updateArticleCategory(model).then((res) => {
            if (res.status) {
                navigate(constant.component.articleCategoryList.url)
            } else {
                setResponse(res)
                setShowMsg(true)
                setTimeout(() => {
                    setShowMsg(false)
                }, 7000)
            }
        }).catch((err) => {
            alert(err)
        })
    }


    return (
        <React.Fragment>
            {
                data && <Add onSubmit={onSubmit} detail={data} response={response} showMsg={showMsg} />
            }
        </React.Fragment>
    )
}
