import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { addFAQCategory, updateFAQCategory } from "../../../actions/utilitiesAction";
import AddFAQCategory from '../../../component/Utilities/FAQs/AddCategory';
import constant from "../../../constant";

const AddCategory = () => {

    const [response, setResponse] = React.useState({})
    const [showMsg, setShowMsg] = React.useState(false)
    const navigate = useNavigate()

    const onSubmit = (model) => {
        if (model.type === 'ADD') {
            addFAQCategory(model).then((res) => {
                setResponse(res)
                setShowMsg(true)
                setTimeout(() => {
                    setShowMsg(false)
                }, 7000)
                window.location.href = constant.component.faqlistcategory.url;
                // if (res.status) {
                //     alert(res.messageCode)
                //     window.location.href = constant.component.faqlistcategory.url;
                // }
            }).catch((err) => {
                alert(err)
            })
        } else {
            updateFAQCategory(model).then((res) => {
                if (res.status) {
                    navigate(constant.component.faqlistcategory.url)
                } else {
                    setResponse(res)
                    setShowMsg(true)
                    setTimeout(() => {
                        setShowMsg(false)
                    }, 7000)
                }
            }).catch((err) => {
                alert(err)
            })
        }

    }


    return (
        <React.Fragment>
            <AddFAQCategory onSubmit={(data) => onSubmit(data)} response={response} showMsg={showMsg} />
        </React.Fragment>
    )
}

export default AddCategory