import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import constant from "../../../constant";
import SuccessMessage from "../../common/SuccessMessage";
import ErrorMessage from "../../common/ErrorMessage";
import { useNavigate, useParams } from "react-router-dom";
import {
  getGovtState,
  getStateList,
  getStateLists,
} from "../../../actions/utilitiesAction";
import { uploadGovtLogo } from "../../../actions/ProfileAction";
import {
  employmentTypeListsForJobs,
  govtDepartmentList,
  qualificationGovtList,
} from "../../../actions/JobsAction";

const AddGovtJob = (props) => {
  const { id } = useParams();
  const details = props.detail;
  const [state, setState] = useState([]);
  const [qualification, setQualification] = useState([]);
  const [jobType, setJobType] = useState("");
  const [govtDepartment, setGovtDepartment] = useState("");
  const [error, setError] = useState(false);
  const Navigate = useNavigate();

  const params = useParams();

  const [data, setData] = useState({
    JOB_TITLE: "",
    CATEGORY: "",
    STATE: "",
    LOCATION: "",
    LAST_DATE: "",
    DEPARTMENT_ID: "",
    SALARY: "",
    JOB_TYPE: "",
    QUALIFICATION: "",
    DESCRIPTION: "",
    LOGO: "",
    KEYWORDS: "",
    APPLY_JOB_URL: "",
    REFERENCE_BY: "",
  });

  const handleReset = (e) => {
    e.preventDefault();
    id && Navigate(constant.component.addgovtjobs.url);
    setError(false);
    setData((PrevState) => ({
      ...PrevState,
      JOB_TITLE: "",
      CATEGORY: "",
      STATE: "",
      LOCATION: "",
      LAST_DATE: "",
      DEPARTMENT_ID: "",
      SALARY: "",
      JOB_TYPE: "",
      QUALIFICATION: "",
      DESCRIPTION: "",
      LOGO: "",
      KEYWORDS: "",
      APPLY_JOB_URL: "",
      REFERENCE_BY: "",
    }));
  };
  const onSubmit = (e) => {
    e.preventDefault();

    if (
      data.JOB_TITLE.toString().trim().length == 0 ||
      data.CATEGORY.toString().trim().length == 0 ||
      //data.LAST_DATE.trim().length == 0 ||
      data.DEPARTMENT_ID.toString().trim().length == 0 ||
      // data.SALARY.trim().length == 0 ||
      data.JOB_TYPE.toString().trim().length == 0 ||
      data.QUALIFICATION.trim().length == 0 ||
      data.DESCRIPTION.toString().trim().length == 0 ||
      data.LOGO.trim().length == 0 ||
      data.KEYWORDS.toString().trim().length == 0 ||
      data.APPLY_JOB_URL.toString().trim().length == 0 ||
      data.REFERENCE_BY.toString().trim().length == 0
    ) {
      setError(true);
      return false;
    } else if (
      data.CATEGORY !== "CENTRAL" &&
      (data.STATE.toString().trim().length == 0 ||
        data.LOCATION.toString().trim().length == 0)
    ) {
      setError(true);
      return false;
    }
    setData((PrevState) => ({
      ...PrevState,
      JOB_TITLE: "",
      CATEGORY: "",
      STATE: "",
      LOCATION: "",
      LAST_DATE: "",
      DEPARTMENT_ID: "",
      SALARY: "",
      JOB_TYPE: "",
      QUALIFICATION: "",
      DESCRIPTION: "",
      LOGO: "",
      KEYWORDS: "",
      APPLY_JOB_URL: "",
      REFERENCE_BY: "",
    }));
    const model = {
      JOB_TITLE: data.JOB_TITLE,
      CATEGORY: data.CATEGORY,
      STATE: data.STATE,
      LOCATION: data.LOCATION,
      LAST_DATE: data.LAST_DATE,
      DEPARTMENT_ID: data.DEPARTMENT_ID,
      SALARY: data.SALARY,
      JOB_TYPE: data.JOB_TYPE,
      QUALIFICATION: data.QUALIFICATION,
      DESCRIPTION: data.DESCRIPTION,
      LOGO: data.LOGO,
      KEYWORDS: data.KEYWORDS,
      APPLY_JOB_URL: data.APPLY_JOB_URL,
      REFERENCE_BY: data.REFERENCE_BY,
    };
    props.onSubmit(model);
    
    setError(false);
  };

  //   useEffect(() => {
  //     id
  //       ? (document.title = constant.title.EditGovtJobs)
  //       : (document.title = constant.title.addGovtJobs);
  //   }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
    // DepartmentList(value)
  };
  const handlecat = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
   DepartmentList(value,'null');
   getStateList()
   
  };
  const handleState = (e) => {
    debugger
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
   DepartmentList(data.CATEGORY,value);
  };

  const GovtLogo = (e) => {
    e.preventDefault();
    let model = new FormData();
    model.append("file", e.target.files[0]);

    uploadGovtLogo(model)
      .then((res) => {
        if (res.status) {
          setData({
            ...data,
            [e.target.name]: res.result.fileName,
          });
        } else {
          alert(res.error);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };
 
  useEffect(() => {
    qualificationGovtList()
      .then((res) => {
        console.log(res, "result");
        if (res.status) {
          setQualification(res.result.list);
        }
      })
      .catch((err) => {
        alert(err);
      });
  }, []);
  useEffect(() => {
    employmentTypeListsForJobs()
      .then((res) => {
        if (res.status) {
          setJobType(res.result);
        }
      })
      .catch((err) => {
        alert(err);
      });
  }, []);
  const getStateList=()=>{
    getGovtState()
    .then((res) => {
      if (res.status) {
        setState(res.result.list);
      }
    })
    .catch((err) => {
      alert(err);
    });
  }
  useEffect(() => {
    DepartmentList();
    getStateList()
  }, []);
  const DepartmentList = (category,state) => {
    govtDepartmentList({CATEGORY:category,STATE_ID:state})
      .then((res) => {
        if (res.status) {
          setGovtDepartment(res.result.res);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };
  return (
    <React.Fragment>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 " style={{ fontSize: "18px" }}>
                    {id ? "Update Govt Jobs" : "Add Govt Jobs"}
                  </h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Utilities</a>
                      </li>
                      <li className="breadcrumb-item active">
                        {id ? "Update Govt Jobs" : "Add Govt Jobs"}
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    {/* <h4 className="card-title mb-4">Government Jobs Information</h4> */}
                    {props.showMsg &&
                      (props.response.status ? (
                        <SuccessMessage message={props.response.messageCode} />
                      ) : (
                        <ErrorMessage message={props.response.error} />
                      ))}
                    <div id="basic-example">
                      <section>
                        <form method="POST">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="mb-3">
                                <label htmlFor="basicpill-firstname-input">
                                  Job Title{" "}
                                  <span style={{ color: "#FF0000" }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className={
                                    error && data.JOB_TITLE.trim().length <= 0
                                      ? "form-control is-invalid "
                                      : "form-control"
                                  }
                                  id="basicpill-firstname-input"
                                  placeholder="Enter Job Title"
                                  name="JOB_TITLE"
                                  value={data.JOB_TITLE}
                                  onChange={handleChange}
                                />
                                {error && data.JOB_TITLE.trim().length <= 0 ? (
                                  <span className="text-danger ml-1">
                                    This Field is Required
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label htmlFor="basicpill-address-input">
                                  Job Category{" "}
                                  <span style={{ color: "#FF0000" }}>*</span>
                                </label>
                                <select
                                  className={
                                    error && data.CATEGORY.trim().length <= 0
                                      ? "form-control is-invalid "
                                      : "form-control"
                                  }
                                  value={data.CATEGORY}
                                  name="CATEGORY"
                                  onChange={handlecat}
                                >
                                  <option value="">Select Category</option>
                                  <option value="CENTRAL">
                                    Central Government
                                  </option>
                                  <option value="STATE">
                                    State Government
                                  </option>
                                </select>
                                {error &&
                                data.CATEGORY.toString().trim().length <= 0 ? (
                                  <span className="text-danger ml-1">
                                    This Field is Required
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label htmlFor="basicpill-address-input">
                                  Last Date For Apply Online{" "}
                                  {/* <span style={{ color: "#FF0000" }}>*</span> */}
                                </label>
                                <input
                                  type="date"
                                  className="form-control"
                                  id="basicpill-firstname-input"
                                  placeholder="Enter Last Date For Apply Online"
                                  name="LAST_DATE"
                                  value={data.LAST_DATE}
                                  onChange={handleChange}
                                />
                                {/* {error &&
                                data.SALARY.toString().trim().length <= 0 ? (
                                  <span className="text-danger ml-1">
                                    This Field is Required
                                  </span>
                                ) : (
                                  ""
                                )} */}
                              </div>
                            </div>
                          </div>
                          {data.CATEGORY !== "CENTRAL" && (
                            <>
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="mb-3">
                                    <label htmlFor="basicpill-firstname-input">
                                      State{" "}
                                      <span style={{ color: "#FF0000" }}>
                                        *
                                      </span>
                                    </label>
                                    <select
                                      className={
                                        error && data.STATE.trim().length <= 0
                                          ? "form-control is-invalid "
                                          : "form-control"
                                      }
                                      name="STATE"
                                      value={data.STATE}
                                      onChange={handleState}
                                    >
                                      <option value="">Select State</option>
                                      {state &&
                                        state.length > 0 &&
                                        state.map((item, index) => {
                                          return (
                                            <option
                                              key={index}
                                              value={item.STATE_ID}
                                            >
                                              {" "}
                                              {item.STATE}
                                            </option>
                                          );
                                        })}
                                    </select>
                                    {error && data.STATE.trim().length <= 0 ? (
                                      <span className="text-danger ml-1">
                                        This Field is Required
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-3">
                                    <label htmlFor="basicpill-firstname-input">
                                      Location{" "}
                                      <span style={{ color: "#FF0000" }}>
                                        *
                                      </span>
                                    </label>
                                    <input
                                      type="text"
                                      className={
                                        error &&
                                        data.LOCATION.trim().length <= 0
                                          ? "form-control is-invalid "
                                          : "form-control"
                                      }
                                      id="basicpill-firstname-input"
                                      placeholder="Enter Location"
                                      name="LOCATION"
                                      value={data.LOCATION}
                                      onChange={handleChange}
                                    />
                                    {error &&
                                    data.LOCATION.trim().length <= 0 ? (
                                      <span className="text-danger ml-1">
                                        This Field is Required
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                            </>
                          )}

                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label htmlFor="basicpill-address-input">
                                  Department{" "}
                                  <span style={{ color: "#FF0000" }}>*</span>
                                </label>
                                <select
                                  className={
                                    error &&
                                    data.DEPARTMENT_ID.trim().length <= 0
                                      ? "form-control is-invalid "
                                      : "form-control"
                                  }
                                  value={data.DEPARTMENT_ID}
                                  name="DEPARTMENT_ID"
                                  onChange={handleChange}
                                >
                                  <option value="">Select Department</option>
                                  {govtDepartment &&
                                    govtDepartment.length > 0 &&
                                    govtDepartment.map((item, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={item.DEPARTMENT_ID}
                                        >
                                          {item.DEPARTMENT}
                                        </option>
                                      );
                                    })}
                                  <option value="00">Others</option>
                                </select>
                                {error &&
                                data.DEPARTMENT_ID.toString().trim().length <=
                                  0 ? (
                                  <span className="text-danger ml-1">
                                    This Field is Required
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label htmlFor="basicpill-address-input">
                                  Salary{" "}
                                  {/* <span style={{ color: "#FF0000" }}>*</span> */}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="basicpill-firstname-input"
                                  placeholder="Enter Salary"
                                  name="SALARY"
                                  value={data.SALARY}
                                  onChange={handleChange}
                                />
                                {/* {error &&
                                data.SALARY.toString().trim().length <= 0 ? (
                                  <span className="text-danger ml-1">
                                    This Field is Required
                                  </span>
                                ) : (
                                  ""
                                )} */}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label htmlFor="basicpill-firstname-input">
                                  Job Type{" "}
                                  <span style={{ color: "#FF0000" }}>*</span>
                                </label>
                                <select
                                  className={
                                    error && data.JOB_TYPE.trim().length <= 0
                                      ? "form-control is-invalid "
                                      : "form-control"
                                  }
                                  value={data.JOB_TYPE}
                                  name="JOB_TYPE"
                                  onChange={handleChange}
                                >
                                  <option value="">Select Job Type</option>
                                  {jobType &&
                                    jobType.length > 0 &&
                                    jobType.map((item, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={item.EMPLOYMENT_TYPE_ID}
                                        >
                                          {item.EMPLOYMENT_TYPE}
                                        </option>
                                      );
                                    })}
                                </select>
                                {error && data.JOB_TYPE.trim().length <= 0 ? (
                                  <span className="text-danger ml-1">
                                    This Field is Required
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label htmlFor="basicpill-firstname-input">
                                  Qualification{" "}
                                  <span style={{ color: "#FF0000" }}>*</span>
                                </label>
                                <select
                                  className={
                                    error &&
                                    data.QUALIFICATION.trim().length <= 0
                                      ? "form-control is-invalid "
                                      : "form-control"
                                  }
                                  name="QUALIFICATION"
                                  value={data.QUALIFICATION}
                                  onChange={handleChange}
                                >
                                  <option value="">Select Qualification</option>
                                  {qualification &&
                                    qualification.length > 0 &&
                                    qualification.map((item, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={
                                            item.EDUCATION_QUALIFICATION_ID
                                          }
                                        >
                                          {item.COURSE_STREAM}
                                        </option>
                                      );
                                    })}
                                </select>
                                {error &&
                                data.QUALIFICATION.trim().length <= 0 ? (
                                  <span className="text-danger ml-1">
                                    This Field is Required
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="mb-3">
                                <label htmlFor="basicpill-address-input">
                                  Job Description{" "}
                                  <span style={{ color: "#FF0000" }}>*</span>
                                </label>
                                <textarea
                                  id="basicpill-address-input"
                                  className={
                                    error && data.DESCRIPTION.trim().length <= 0
                                      ? "form-control is-invalid "
                                      : "form-control"
                                  }
                                  rows={4}
                                  placeholder="Enter Government Department Details"
                                  defaultValue={""}
                                  name="DESCRIPTION"
                                  value={data.DESCRIPTION}
                                  onChange={handleChange}
                                />
                                {error &&
                                data.DESCRIPTION.toString().trim().length <=
                                  0 ? (
                                  <span className="text-danger ml-1">
                                    This Field is Required
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label htmlFor="basicpill-address-input">
                                  Department Logo{" "}
                                  <span style={{ color: "#FF0000" }}>*</span>
                                </label>
                                <input
                                  className={
                                    error && data.LOGO.trim().length <= 0
                                      ? "form-control is-invalid "
                                      : "form-control"
                                  }
                                  type="file"
                                  name="LOGO"
                                  onChange={GovtLogo}
                                />

                                {error &&
                                data.LOGO.toString().trim().length <= 0 ? (
                                  <span className="text-danger ml-1">
                                    This Field is Required
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label htmlFor="basicpill-address-input">
                                  Keywords{" "}
                                  <span style={{ color: "#FF0000" }}>*</span>
                                </label>
                                <input
                                  className={
                                    error && data.KEYWORDS.trim().length <= 0
                                      ? "form-control is-invalid "
                                      : "form-control"
                                  }
                                  type="text"
                                  name="KEYWORDS"
                                  value={data.KEYWORDS}
                                  onChange={handleChange}
                                />
                                {error &&
                                data.KEYWORDS.toString().trim().length <= 0 ? (
                                  <span className="text-danger ml-1">
                                    This Field is Required
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label htmlFor="basicpill-address-input">
                                  Apply Job URL{" "}
                                  <span style={{ color: "#FF0000" }}>*</span>
                                </label>
                                <input
                                  className={
                                    error &&
                                    data.APPLY_JOB_URL.trim().length <= 0
                                      ? "form-control is-invalid "
                                      : "form-control"
                                  }
                                  type="text"
                                  name="APPLY_JOB_URL"
                                  value={data.APPLY_JOB_URL}
                                  onChange={handleChange}
                                />
                                {error &&
                                data.APPLY_JOB_URL.toString().trim().length <=
                                  0 ? (
                                  <span className="text-danger ml-1">
                                    This Field is Required
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label htmlFor="basicpill-address-input">
                                  Reference URL{" "}
                                  <span style={{ color: "#FF0000" }}>*</span>
                                </label>
                                <input
                                  className={
                                    error &&
                                    data.REFERENCE_BY.trim().length <= 0
                                      ? "form-control is-invalid "
                                      : "form-control"
                                  }
                                  type="text"
                                  name="REFERENCE_BY"
                                  value={data.REFERENCE_BY}
                                  onChange={handleChange}
                                />
                                {error &&
                                data.REFERENCE_BY.toString().trim().length <=
                                  0 ? (
                                  <span className="text-danger ml-1">
                                    This Field is Required
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <button
                                  type="button"
                                  onClick={onSubmit}
                                  className="btn btn-info waves-effect btn-label waves-light"
                                >
                                  <i className="bx bx-check-double label-icon" />
                                  {details
                                    ? "Save Changes"
                                    : "Create Government Job"}
                                </button>
                                &nbsp;&nbsp;
                                <button
                                  type="button"
                                  onClick={handleReset}
                                  className="btn btn-danger waves-effect btn-label waves-light"
                                >
                                  <i className="bx bx-block label-icon " />{" "}
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default AddGovtJob;
