import React from 'react'
import { useEffect, useState } from 'react'
import List from '../../component/Enquiry/ReportIssueList'
import constant from '../../constant'
import { ReportIssueList, UpdateIssue } from '../../actions/utilitiesAction'
import Swal from 'sweetalert2'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

export default function ReportIssue() {

    const [list, setList] = useState([])
    const [count, setCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [showLoader, setShowLoader] = useState(false)
    const [statusChanged, setStatusChanged] = useState(false)
    const location = useLocation()

    useEffect(() => {
        const qParams = queryString.parse(location.search)
        ReportIssueList(qParams?.p ? qParams?.p : currentPage).then(res => {
            if (res.status) {
                setList(res.result.list)
                setCount(res.result.count)
            }
            else {
                alert(res.error)
            }

        }).catch(err => {
            alert(err)
        })

    }, [currentPage, statusChanged])

    const updateStatus = (model) => {

        UpdateIssue(model).then((res) => {
            if (res.status) {
                setStatusChanged(!statusChanged)

            } else {
                alert(res.error)
            }
        }).catch((err) => {
            alert(err)
        })
    }

    return (
        <React.Fragment>
            <List
                list={list}
                count={count}
                currentPage={currentPage}
                setPage={setCurrentPage}
                updateStatus={updateStatus}
                showLoader={showLoader}


            />
        </React.Fragment>
    )
}
