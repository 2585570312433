import React, { useEffect, useState } from 'react'
import { getJobTypeList, archiveJobType } from '../../../actions/utilitiesAction'
import List from '../../../component/Utilities/JobType/JobTypeList'
import constant from '../../../constant'
import queryString from 'query-string'
import { useLocation } from "react-router-dom";

const JobTypeList = () => {
    const [list, setList] = useState([])
    const [count, setCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [keyword, setKeyword] = useState('')
    const [showLoader, setShowLoader] = useState(false)
    const location = useLocation()


    useEffect(() => {
        const qParams = queryString.parse(location.search)
        getJobTypeLists(qParams?.p ? qParams?.p : qParams?.pageno ? qParams.pageno : currentPage)
        document.title = constant.title.JobTypeList
    }, [currentPage])

    const getJobTypeLists = (page) => {
        setShowLoader(true)
        getJobTypeList({ pageNumber: page, KEYWORD: keyword }).then((res) => {
            setShowLoader(false)
            setList(res.result.list)
            setCount(res.result.count)
        }).catch((err) => {
            alert(err)
        })
    }

    const updateStatus = (model) => {
        archiveJobType(model).then((res) => {
            if (res.status) {
                getJobTypeLists(currentPage)
            } else {
                alert(res.error)
            }
        }).catch((err) => {
            alert(err)
        })
    }


    return (
        <React.Fragment>
            <List
                list={list}
                updateStatus={updateStatus}
                getJobTypeLists={(page => getJobTypeLists(page))}
                count={count}
                setPage={setCurrentPage}
                keyword={setKeyword}
                showLoader={showLoader}
                cpage={currentPage}
            />
        </React.Fragment>
    )
}
export default JobTypeList;