import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import constant from '../../constant'
import Pagination from "react-js-pagination";
import { Spinner } from 'react-bootstrap';
import Loader from '../common/Loader';

export default function CompanyTypeList(props) {

    const { setShowLoader, showLoader } = props
    const [currentPage, setCurrentPage] = useState(1);
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        // props.getCompanyTypeList(pageNumber)
        props.setPage(pageNumber)
    };
    const updateStatus = (COMPANY_TYPE_ID, STATUS) => {
        const model = {
            COMPANY_TYPE_ID: COMPANY_TYPE_ID,
            STATUS: STATUS === 'U' ? 'A' : 'U'
        }
        props.updateStatus(model)
        props.setPage(currentPage)
    }

    const { list } = props

    return (
        <React.Fragment>

            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0" style={{ fontSize: "18px" }}>Company Type List</h4>
                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="javascript: void(0);">Employers</a></li>
                                            <li className="breadcrumb-item active">Company Type List</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <form className="row gy-12 gx-12 align-items-center">
                                            <div className="col-sm-2">
                                                <label className="visually-hidden" for="autoSizingInput">Name</label>
                                                Search Companny Type
                                            </div>
                                            <div className="col-sm-6">
                                                <label className="visually-hidden" for="autoSizingInputGroup">Username</label>
                                                <div className="input-group">
                                                    <input type="text" className="form-control" id="autoSizingInputGroup" placeholder="Enter Employer ID, Name, Email ID, Phone etc" />
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <button type="button" className="btn btn-success waves-effect btn-label waves-light"><i className="bx bx-check-double label-icon"></i> Search</button>&nbsp; <button type="button" className="btn btn-outline-danger">Reset</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table align-middle table-nowrap table-hover">
                                                {!showLoader && <thead className="table-light">
                                                    <tr>
                                                        <th scope="col">S.No.</th>
                                                        <th scope="col">Company Type</th>
                                                        <th scope="col">Linked to Jobs</th>
                                                        <th scope="col">Added On</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col" style={{ width: "100px" }}>Action</th>
                                                    </tr>
                                                </thead>}
                                                {showLoader && <Loader />}
                                                <tbody>
                                                    {/* <td colSpan={10} className="text-center">{showLoader && <Loader />}</td> */}
                                                    {list && list.length > 0 &&
                                                        list.map((ele, index) => {
                                                            return (
                                                                <tr>
                                                                    <td>{currentPage == 1 ? `${index + 1}` : `${index == 9 ? currentPage : currentPage - 1}` + `${index == 9 ? 0 : index + 1}`}</td>
                                                                    {/* <td>{ele.COMPANY_TYPE_ID}</td> */}
                                                                    <td>
                                                                        <p className="text-muted mb-0">{ele.COMPANY_TYPE}</p>
                                                                    </td>
                                                                    <td>0</td>
                                                                    <td>{moment(ele.CREATED_ON).format('DD.MM.YYYY on HH:MMa')}
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            ele.STATUS == 'U' ?
                                                                                <button type="button" className="btn btn-success btn-sm waves-effect waves-light">ACTIVE</button> : <button type="button" className="btn btn-danger btn-sm waves-effect waves-light">ARCHIVE</button>
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        <ul className="list-inline contact-links mb-0" style={{ fontSize: "18px" }}>
                                                                            <li className="list-inline-item px-2">
                                                                                <Link to={constant.component.editcompanytype.url.replace(':id', ele.COMPANY_TYPE_ID)} title="Edit Company Type"><i className="bx bx-edit"></i></Link>
                                                                            </li>
                                                                            <li className="list-inline-item px-2">
                                                                                <a href="javascript: void(0)" onClick={() => updateStatus(ele.COMPANY_TYPE_ID, ele.STATUS)} style={{ color: 'red' }} title={ele.STATUS == 'U' ? "Archive Record" : "Unarchive Record"}>
                                                                                    {
                                                                                        ele.STATUS == 'U' ? <i className="bx bx-trash"></i> : <i class="bx bx-log-in"></i>
                                                                                    }


                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }) 
                                                        // :
                                                        // < td colSpan={10} className="text-center text-danger">{!showLoader && list.length == 0 && 'No Company Type List Record Found'}</td>

                                                    }
                                                    {/* {<td colSpan={10} className="text-center text-danger">{!showLoader && list.length == 0 && 'No Company Type List Record Found'}</td>} */}


                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="pagination pagination-rounded justify-content-center mt-4">
                                                    
                                                     <Pagination
                                                        activePage={currentPage > 0 ? currentPage : 1 }
                                                        itemsCountPerPage={10}
                                                        totalItemsCount={props.count}
                                                        pageRangeDisplayed={5}
                                                        onChange={handlePageChange}
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                    />

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}
