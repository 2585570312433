import React from 'react'
import { Link, useParams } from 'react-router-dom'
import constant from '../../../constant'
import { capitalize } from '../../../utils'
import Pic from "../../../assets/images/profilePic/secondary.jfif"

const CollegeDetail = ({ details }) => {
    const params = useParams()
    console.log(params)
    return (
        <div className="main-content">
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">College Profile</h4>
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item">
                                            <a href="javascript:void(0);">
                                                <Link to={constant.component.collegeList.url}>
                                                    College</Link>
                                            </a>
                                        </li>
                                        <li className="breadcrumb-item active">Profile Information</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row" id="summary">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-7">
                                            <div className="d-flex">
                                                <div className="flex-shrink-0 me-3">
                                                    {details != null && details.LOGO ?
                                                        <img src={`${process.env.REACT_APP_BASE_URL}/get/college-logo/${details.LOGO}`} className="avatar-md rounded-circle img-thumbnail" /> : <img src={Pic} className="avatar-md rounded-circle img-thumbnail" />}

                                                </div>
                                                <div className="flex-grow-1 align-self-center">
                                                    <div className="text-muted">
                                                        <h3 className="mb-1">{capitalize(details.COLLEGE_NAME ? details.COLLEGE_NAME : 'NA')}</h3>
                                                        <p className="mb-0">
                                                            <h6>
                                                                {details.COLLEGE_CODE ? details.COLLEGE_CODE : 'NA'}
                                                            </h6>
                                                        </p>
                                                        <table cellSpacing="2px" cellPadding="2px">
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={10} style={{ height: 10 }} />
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <i className="bx bx bxs-map" style={{ color: "#f03818" }} />&nbsp;
                                                                        {details.STATE ? details.STATE : 'NA'}
                                                                    </td>
                                                                    <td>&nbsp;</td>

                                                                </tr>
                                                                <tr>

                                                                    <td >
                                                                        <i className="bx bxs-envelope" style={{ color: "#fae60f" }} />&nbsp;{details.EMAIL ? details.EMAIL : 'NA'}{" "}
                                                                    </td>
                                                                    <td>
                                                                        <i className="bx bxs-phone" style={{ color: "#299c0c" }} />&nbsp;{details.PHONE ? details.PHONE : 'NA'}{" "}
                                                                    </td>
                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div >
                                                    <button type="button" class="btn btn-info waves-effect btn-label waves-light" onClick={() => { window.location.href = constant.component.editCollegeDetail.url.replace(":id", params.id) }}><i class="bx bx-edit label-icon "></i> Edit Details </button>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-lg-4 align-self-center">
                                            <div className="text-lg-center mt-4 mt-lg-0">

                                                <div className="row">
                                                    <div className="col-12">
                                                        <div>
                                                            <p
                                                                className="text-muted text-left mb-2"
                                                                style={{
                                                                    textAlign: "left",
                                                                    marginTop: 17,
                                                                    color: "#556ee6 !important"
                                                                }}
                                                            >
                                                            </p><b></b>
                                                            {Profile.ProfileWeigh > 60 ? <p className='pt-2 pb-0'>Profile Strength ({Profile.ProfileWeigh}% Completed)</p> : <p className='pt-2 pb-0'>Profile Strength <span style={{ fontWeight: "500" }}>  (Critical Fields Missing)  </span> <div style={{ display: "contents" }}>{Profile.ProfileWeigh}% </div>  </p>}
                                                            <div class="progress profile">
                                                                <progress max="100" value={Profile.ProfileWeigh} style={{ width: '100%' }} ><span>{Profile.ProfileWeigh}</span></progress>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row" id="personaldetails">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">Other Details </h4>


                                    <p />
                                    <div className="row">
                                        <div className="col-3">
                                            <p
                                                className="text-muted"
                                                style={{ marginBottom: "5px !important" }}
                                            >
                                                SPOC Name
                                            </p>
                                            <p className="">
                                                {details.SPOC_NAME ? details.SPOC_NAME : 'NA'}
                                            </p>
                                        </div>
                                        <div className="col-3">
                                            <p
                                                className="text-muted"
                                                style={{ marginBottom: "5px !important" }}
                                            >
                                                SPOC Email

                                            </p>
                                            <p className="">

                                                {details.SPOC_EMAIL ? details.SPOC_EMAIL : 'NA'}

                                            </p>
                                        </div>
                                        <div className="col-3">
                                            <p
                                                className="text-muted"
                                                style={{ marginBottom: "5px !important" }}
                                            >
                                                SPOC Phone

                                            </p>
                                            <p className="">

                                                {details.SPOC_PHONE ? details.SPOC_PHONE : 'NA'}

                                            </p>
                                        </div>
                                        <div className="col-3">
                                            <p
                                                className="text-muted"
                                                style={{ marginBottom: "5px !important" }}
                                            >
                                                Address
                                            </p>
                                            <p className="">{details.ADDRESS}, {details.STATE},{details.CITY},{details.PIN_CODE}</p>
                                        </div>
                                    </div>
                                    <p />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>{" "}
            </div>


        </div>
    )
}

export default CollegeDetail