import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getFunctionalAreaListsForJobs, getRoleByID } from "../../actions/JobsAction";
import SuccessMessage from "../common/SuccessMessage";
import ErrorMessage from "../common/ErrorMessage";
import constant from "../../constant";

function AddRoles(props) {
  const { id } = useParams();
  const [ROLE_NAME, setROLE_NAME] = useState('');
  const [ROLE_DETAILS, setROLE_DETAILS] = useState("");
  // const [FUNCTIONAL_AREA_ID, setFUNCTIONAL_AREA_ID] = useState("1");
  const [ROLE_ID, setROLE_ID] = useState("");
  const [error, setError] = useState(false)
  const [functionList, setFunctionalList] = useState([])
  const [FUNCTIONAL_AREA_ID, setFUNCTIONAL_AREA_ID] = useState('')
  const params = useParams()

  const Navigate = useNavigate()

  const onSubmit = (e) => {
    let pn = params.pageno
    e.preventDefault();
    if (ROLE_NAME.trim().length === 0 || FUNCTIONAL_AREA_ID.trim().length === 0) {
      setError(true)
      return false
    }
    if (id) {
      const model = {
        ROLE_ID: ROLE_ID,
        ROLE_NAME: ROLE_NAME,
        ROLE_DETAILS: ROLE_DETAILS,
        FUNCTIONAL_AREA_ID: FUNCTIONAL_AREA_ID,
        type: "UPDATE",
      };
      props.onSubmit(model);
      model.pageno = pn
    } else {
      const model = {
        ROLE_NAME: ROLE_NAME,
        ROLE_DETAILS: ROLE_DETAILS,
        FUNCTIONAL_AREA_ID: FUNCTIONAL_AREA_ID,
        type: "ADD",
      };
      props.onSubmit(model);
      model.pageno = pn
    }
  };

  useEffect(() => {
    getFunctionalListForRole()
    id && getJobRoleDetails(id);

    (id ? document.title = constant.title.UpdateRole : document.title = constant.title.AddRole)
    //   getJobQuestionType();
  }, []);

  // const getJobQuestionType = () => {
  //   listJobQuestionType()
  //     .then((res) => {
  //       if (res.status) {
  //         setQUESTIONTYPE(res.result);
  //       }
  //     })
  //     .catch((err) => {
  //       alert(err);
  //     });
  // };

  const getFunctionalListForRole = () => {
    getFunctionalAreaListsForJobs()
      .then((res) => {
        if (res.status) {
          setFunctionalList(res.result);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const getJobRoleDetails = (id) => {
    getRoleByID({ ROLE_ID: id })
      .then((res) => {
        if (res.status) {
          setROLE_ID(res.result[0].ROLE_ID)
          setROLE_NAME(res.result[0].ROLE_NAME)
          setROLE_DETAILS(res.result[0].ROLE_DETAILS)
          setFUNCTIONAL_AREA_ID(res.result[0].FUNCTIONAL_AREA_ID.toString())
        }
      })
      .catch((err) => {
        alert(err);
      });
  };
  const handleReset = () => {
    id && Navigate(constant.component.listRole.url)
    setError(false)
    setROLE_NAME('')
    setROLE_DETAILS("")
    setFUNCTIONAL_AREA_ID("")
  }
  return (
    <React.Fragment>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0" style={{ fontSize: "18px" }}>
                    {id ? " Update Roles " : "  Add Roles "}
                  </h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Jobs</a>
                      </li>
                      <li className="breadcrumb-item active">{id ? " Update Roles " : "  Add Roles "}</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title mb-4">Role Information</h4>
                    {
                      props.showMsg && (props.response.status ?
                        <SuccessMessage message={props.response.messageCode} />
                        :
                        <ErrorMessage message={props.response.error} />)
                    }
                    <div id="basic-example">
                      <section>
                        <form method="POST">
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label for="basicpill-firstname-input">
                                  Role <span className="text-danger"> *</span>
                                </label>
                                <input
                                  type="text"
                                  className={error && ROLE_NAME.trim().length <= 0 ? 'form-control is-invalid ' : 'form-control'}
                                  id="basicpill-firstname-input"
                                  placeholder="Enter Role"
                                  value={ROLE_NAME}
                                  onChange={(e) => { setROLE_NAME(e.target.value) }}
                                />
                                {
                                  error && ROLE_NAME.trim().length <= 0 ? <span className='text-danger ml-1'>This Field is Required</span>
                                    : ""}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label for="basicpill-firstname-input">
                                  Functional Area
                                </label>
                                <select
                                  className={error && FUNCTIONAL_AREA_ID.trim().length <= 0 ? 'form-select is-invalid ' : 'form-select'}

                                  value={FUNCTIONAL_AREA_ID}
                                  onChange={(e) => {
                                    setFUNCTIONAL_AREA_ID(e.target.value);
                                  }}
                                >
                                  <option>Select Functional Area</option>
                                  {functionList.map((data, index) => {
                                    return (
                                      <option
                                        value={data.FUNCTIONAL_AREA_ID}
                                        key={index}
                                      >
                                        {data.FUNCTIONAL_AREA}
                                      </option>
                                    );
                                  })}
                                </select>
                                {error && (
                                  <span className="text-danger ml-1">
                                    {error.FUNCTIONAL_AREA_ID}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label for="basicpill-address-input">
                                  Role Details
                                </label>
                                <textarea
                                  id="basicpill-address-input"
                                  className="form-control"
                                  rows="2"
                                  placeholder="Enter Role Details"
                                  value={ROLE_DETAILS}
                                  onChange={(e) => { setROLE_DETAILS(e.target.value) }}
                                ></textarea>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <button
                                  type="button"
                                  className="btn btn-info waves-effect btn-label waves-light"
                                  onClick={onSubmit}
                                >
                                  <i className="bx bx-check-double label-icon"></i>{" "}
                                  {id ? 'Save Changes' : 'Create Role'}
                                </button>
                                &nbsp;&nbsp;
                                <button
                                  type="button"
                                  onClick={handleReset}
                                  className="btn btn-danger waves-effect btn-label waves-light"
                                >
                                  <i className="bx bx-block label-icon "></i>{" "}
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AddRoles;
