import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Add from '../../component/Administration/adminUser/AddAdminRole'
import { adminRoleById, updateRole } from '../../actions/AdminUserAction'

import constant from '../../constant'

const AddAdminrole = (props) => {

    const [response, setResponse] = React.useState({})
    const [showMsg, setShowMsg] = React.useState(false)

    const navigate = useNavigate()

    const params = useParams()
    const [data, setData] = useState(null)

    useEffect(() => {
        adminRoleById(params.id).then((res) => {
            if (res.status) {
                setData(res.result)
            }
            else {
                setResponse(res)
                setShowMsg(true)
                setTimeout(() => {
                    setShowMsg(false)
                }, 7000)
            }
        }).catch(err => {
            alert(err)
        })
    }, [])

    const onSubmit = (model) => {
        updateRole(model).then((res) => {
            if (res.status) {
                navigate(constant.component.adminrolelist.url)
            } else {
                setResponse(res)
                setShowMsg(true)
                setTimeout(() => {
                    setShowMsg(false)
                }, 7000)
            }
        }).catch((err) => {
            alert(err)
        })
    }

    return (
        <React.Fragment>
            {data && <Add onSubmit={onSubmit} detail={data} response={response} showMsg={showMsg} />}
        </React.Fragment>
    )
}

export default AddAdminrole