import React, { useEffect, useState } from 'react'
import { activeCompanyType, createEmployerIndustry } from '../../actions/employer'
import Addindustry from '../../component/Employers/AddIndustry'
import constant from '../../constant'
export default function AddIndustry() {

    const [response, setResponse] = React.useState({})
    const [showMsg, setShowMsg] = React.useState(false)

    useEffect(() => {
        document.title = constant.title.AddIndustry
    }, [])

    const onSubmit = (model) => {
        createEmployerIndustry(model).then((res) => {
            setResponse(res)
            setShowMsg(true)
            setTimeout(() => {
                setShowMsg(false)
            }, 7000)
        }).catch((err) => [
            alert(err)
        ])
    }

    return (
        <React.Fragment>
            {
                <Addindustry onSubmit={onSubmit} response={response} showMsg={showMsg} />
            }
        </React.Fragment>
    )
}
