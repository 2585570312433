import React from 'react'
import Modal from 'react-bootstrap/Modal';
import { Discussionthreadvalue } from '../../../actions/utilitiesAction';
import Swal from 'sweetalert2';


function StatusModule(props) {

    const { listItem, categorylist } = props
    const [id, setId] = React.useState("")
    const [modalShow, setModalShow] = React.useState(false);

    function submit(model) {
        const data = {
            THREAD_TITLE: model.title,
            DISCUSSION_CATEGORY: parseInt(model.category),
            THREAD_DETAILS: model.detail,
            DISCUSSION_THREAD_ID: id
        }



        // setTimeout(() => {
        //     window.location.reload();
        // }, 1000);

        Discussionthreadvalue(data).then((res) => {
            if (res.status) {

                Swal.fire({
                    icon: "success",
                    text: res.messageCode,
                    timer: 1000,
                    showCancelButton: false,
                    showConfirmButton: false,
                })
                window.location.reload();
            } else {
                alert(res.error)
            }
        }).catch((err) => {
            alert(err)
        })
    }


    function editModal(item) {
        setModalShow(true)
        setId(item.DISCUSSION_THREAD_ID)
    }

    return (
        <React.Fragment>

            <i onClick={() => editModal(listItem)} className="bx bx-edit" style={{ cursor: "pointer" }} title="Edit Thread">
            </i>

            <MyVerticallyCenteredModal
                onUpdate={submit}
                categorylist={categorylist}
                listItem={listItem}
                show={modalShow}
                onHide={() => setModalShow(false)}
            />

        </React.Fragment>
    )
}

export default StatusModule




function MyVerticallyCenteredModal(props) {

    const { listItem, categorylist } = props
    const [data, setData] = React.useState({
        title: listItem.THREAD_TITLE,
        category: listItem.DISCUSSION_CATEGORY,
        detail: listItem.THREAD_DETAILS,

    })

    function onUpdate(e) {

        props.onUpdate(data)


    }

    function onChangeHandeler(e) {

        let name = e.target.name
        let value = e.target.value

        setData({ ...data, [name]: value })

    }

    return (
        <Modal
            {...props}
            size="mg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
              

                <div className="modal-body">
                    <div className="modal-header" >
                        <h5 className="modal-title">Update Thread</h5>

                    </div>


                    <p>
                        <table className="table table-striped mb-0">
                            <tbody>
                                <tr>
                                    <td ><input type="text" placeholder=' Thread Title :' className='mb-1 form-control' style={{ padding: '0px 5px', height: "35px" }}
                                        onChange={onChangeHandeler}
                                        name="title"
                                        value={data.title}
                                        
                                        >

                                    </input>
                                    </td>
                                </tr>
                                <tr>
                                    <td ><select onChange={onChangeHandeler} name='category' value={data.category} className='mb-1 form-control' style={{ padding: '0px 5px', height: "35px" }}>

                                        {
                                            categorylist && categorylist.length > 0 && categorylist.map((item, index) => {
                                                <option value="">Select a Category</option>
                                                return (

                                                    <option key={index} value={item.DISCUSSION_CATEGORY_ID}>{item.DISCUSSION_CATEGORY_TITLE}</option>
                                                )
                                            })

                                        }
                                    </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td ><textarea type="text"
                                        placeholder=' Thread Detail:'
                                        className='mb-1 form-control'
                                        style={{ padding: '4px 10px', height: '9em' }}
                                        onChange={onChangeHandeler}
                                        name="detail"
                                        value={data.detail}
                                    >
                                    </textarea></td>
                                </tr>
                            </tbody>
                        </table>
                    </p>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-danger" onClick={onUpdate} > Submit</button>
                </div>
            </Modal.Body>

        </Modal>
    );
}

