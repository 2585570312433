import React from 'react';
import { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import constant from '../../../constant';
import SuccessMessage from '../../common/SuccessMessage';
import ErrorMessage from '../../common/ErrorMessage';
import { useNavigate, useParams } from 'react-router-dom';


const AddJobCategory = (props) => {
    const { id } = useParams()
    const details = props.detail;
    const Navigate = useNavigate()
    const [error, setError] = useState(false)
    const params = useParams()

    // useEffect(() => {
    //     document.title = constant.title.AddJobCategory
    // }, [])

    const [data, setData] = useState({
        JOB_CATEGORY: "",
        SORT_NUMBER: "",
        JOB_CATEGORY_DETAILS: "",
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setData({
            ...data,
            [name]: value
        })
    }

    const onSubmit = (e) => {
        let pn = params.pageno
        e.preventDefault();
        if (data.JOB_CATEGORY.trim().length == 0 || data.SORT_NUMBER.toString().trim().length == 0) {
            setError(true)
        }
        else {
            const model = {
                JOB_CATEGORY: data.JOB_CATEGORY,
                SORT_NUMBER: data.SORT_NUMBER,
                JOB_CATEGORY_DETAILS: data.JOB_CATEGORY_DETAILS,
            };
            if (details) {
                model.JOB_CATEGORY_ID = details.JOB_CATEGORY_ID
            }
            props.onSubmit(model)
            model.pageno = pn
        }
    }

    useEffect(() => {
        (id ? document.title = constant.title.EditJobCategory : document.title = constant.title.AddJobCategory)
    }, [])

    useEffect(() => {
        if (details) {
            setData({
                JOB_CATEGORY: details.JOB_CATEGORY,
                SORT_NUMBER: details.SORT_NUMBER,
                JOB_CATEGORY_DETAILS: details.JOB_CATEGORY_DETAILS
            })
        }
    }, [])

    const handleReset = (e) => {
        setError(false)
        e.preventDefault();
        id && Navigate(constant.component.jobcategorylist.url)
        setData(PrevState => ({
            ...PrevState,
            JOB_CATEGORY: "",
            SORT_NUMBER: "",
            JOB_CATEGORY_DETAILS: "",
        }))
    }


    return (
        <React.Fragment>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 font-size-18" style={{ fontSize: '18px' }}>{id ? "Update Job Category" : "Add Job Category"}</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="javascript: void(0);">Utilities</a></li>
                                            <li className="breadcrumb-item active">{id ? "Update Job Category" : "Add Job Category"}</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">Job Category Information</h4>
                                        {
                                            props.showMsg && (props.response.status ?
                                                <SuccessMessage message={props.response.messageCode} />
                                                :
                                                <ErrorMessage message={props.response.error} />)
                                        }
                                        <div id="basic-example">
                                            <section>
                                                <form method="POST">
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label for="basicpill-firstname-input">Job Category <span style={{ color: "#FF0000" }}>*</span></label>
                                                                <input type="text"
                                                                    className={error && data.JOB_CATEGORY.trim().length <= 0 ? 'form-control is-invalid ' : 'form-control'}
                                                                    id="basicpill-firstname-input"
                                                                    placeholder="Enter Job Category"
                                                                    name="JOB_CATEGORY"
                                                                    value={data.JOB_CATEGORY}
                                                                    onChange={handleChange}
                                                                />
                                                                {
                                                                    error && data.JOB_CATEGORY.toString().trim().length <= 0 ? <span className='text-danger ml-1'>This Field is Required</span>
                                                                        : ""}
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label for="basicpill-address-input">Sort Number <span style={{ color: "#FF0000" }}>*</span></label>
                                                                <NumberFormat
                                                                    name="SORT_NUMBER"
                                                                    value={data.SORT_NUMBER}
                                                                    type="text"
                                                                    className={error && data.SORT_NUMBER.toString().trim().length <= 0 ? 'form-control is-invalid ' : 'form-control'}
                                                                    id="basicpill-firstname-input"
                                                                    placeholder="Enter Sort Number"
                                                                    allowLeadingZeros={false}
                                                                    allowNegative={false}
                                                                    thousandSeparator={false}
                                                                    onChange={handleChange}
                                                                    maxlength={10}

                                                                />

                                                                {
                                                                    error && data.SORT_NUMBER.toString().trim().length <= 0 ? <span className='text-danger ml-1'>This Field is Required</span>
                                                                        : ""}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">

                                                        <div className="col-lg-12">
                                                            <div className="mb-3">
                                                                <label for="basicpill-address-input">Job Category Details</label>
                                                                <textarea id="basicpill-address-input"
                                                                    className="form-control"
                                                                    rows="2"
                                                                    placeholder="Enter Job Category Details"
                                                                    name="JOB_CATEGORY_DETAILS"
                                                                    value={data.JOB_CATEGORY_DETAILS}
                                                                    onChange={handleChange}
                                                                ></textarea>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <button type="button" onClick={onSubmit} className="btn btn-info waves-effect btn-label waves-light"><i className="bx bx-check-double label-icon"></i>{details ? "Save Changes" : "Create Job Category"}</button>
                                                                &nbsp;&nbsp;
                                                                <button type="button" onClick={handleReset} className="btn btn-danger waves-effect btn-label waves-light"><i className="bx bx-block label-icon "></i> Cancel</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>

    )
}

export default AddJobCategory;