import React, { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom';
import constant from './constant';
import CustomRoute from './Router';
import { getsessionStorage, getStorage, removeAllLocalStorge, removeStorage } from './utils';
import moment from 'moment';
import io from 'socket.io-client'
import { lastActivity } from './actions/AuthActions'
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { actionCreators } from './state/index'


const App = () => {
  const session_token = getsessionStorage(constant.keys.token)
  const loggedIn = getStorage(constant.keys.loggedIn)
  const remeber_me = getStorage(constant.keys.remeber_me)
  const ud = getStorage(constant.keys.userDetails)
  const local_token = getStorage(constant.keys.token)
  

  useEffect(() => {
    if (ud) {

      if (!remeber_me && !session_token) {
        removeStorage('rsc_cache')
        removeStorage(constant.keys.token);
        removeStorage(constant.keys.userDetails);
        removeAllLocalStorge()
        window.location.reload();
      }
      else if (loggedIn) {
        const CurrentTime = moment().format('X')
        const expireTime = +loggedIn + 604800000;
        if (CurrentTime > expireTime) {
          removeStorage('rsc_cache')
          removeStorage(constant.keys.token);
          removeStorage(constant.keys.userDetails);
          removeAllLocalStorge()
          window.location.reload();
        }
      }
      if (local_token) {
        lastActivity()
      }

    }


  }, [])

  return (
    <BrowserRouter>
      <CustomRoute />
    </BrowserRouter>
  )
}

export default App;
