import React, { useEffect, useState } from 'react'
import { listCompanyType, archiveCompanyType } from '../../actions/employer';
import CompanyTypelist from '../../component/Employers/CompanyTypeList'
import constant from '../../constant';
import Loader from '../../component/common/Loader';

export default function CompanyTypeList() {
    const [list, setList] = useState([]);
    const [count, setCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)

    useEffect(() => {
        document.title = constant.title.CompanyTypeList
        getCompanyTypeList(currentPage)
    }, [currentPage])

    const getCompanyTypeList = (page) => {
        listCompanyType({ page: page }).then((res) => {
            if (res.status) {
                setCount(res.result.count.total)
                setList(res.result.list)
            }
            else {
                alert(res.error)
            }
        }).catch((err) => {
            alert(err)
        })

    }
    const updateStatus = (model) => {
        archiveCompanyType(model).then((res) => {
            if (res.status) {
                getCompanyTypeList(currentPage)
            }
            else {
                alert(res.error)
            }
        }).catch(err => {
            alert(err)
        })
    }

    return (
        <React.Fragment>
            <CompanyTypelist
                count={count}
                list={list}
                updateStatus={updateStatus}
                getCompanyTypeList={getCompanyTypeList}
                setPage={setCurrentPage}
            />

        </React.Fragment>
    )
}
