import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import constant from "../../../constant";
import SuccessMessage from "../../common/SuccessMessage";
import ErrorMessage from "../../common/ErrorMessage";
import { useNavigate, useParams } from "react-router-dom";
import {
    getGovtState,
    getStateList,
    getStateLists,
} from "../../../actions/utilitiesAction";
import { uploadGovtLogo } from "../../../actions/ProfileAction";
import {
    employmentTypeListsForJobs,
    govtDepartmentList,
    qualificationGovtList,
} from "../../../actions/JobsAction";

const AddGovtResults = (props) => {
    const { id } = useParams();
    const details = props.detail;
    const [state, setState] = useState([]);
    const [qualification, setQualification] = useState([]);
    const [jobType, setJobType] = useState("");
    const [govtDepartment, setGovtDepartment] = useState("");
    const [error, setError] = useState(false);
    const Navigate = useNavigate();

    const params = useParams();

    const [data, setData] = useState({
        RESULT_TITLE: "",
        CATEGORY: "",
        STATE: "",
        DEPARTMENT_ID: "",
        DESCRIPTION: "",
        RESULT_URL: "",
        
    });

    const handleReset = (e) => {
        e.preventDefault();
        id && Navigate(constant.component.addgovtresult.url);
        setError(false);
        setData((PrevState) => ({
            ...PrevState,
            RESULT_TITLE: "",
            CATEGORY: "",
            STATE: "",
            DEPARTMENT_ID: "",
            DESCRIPTION: "",
            RESULT_URL: "",
            
        }));
    };
    const onSubmit = (e) => {
        e.preventDefault();

        if (
            data.RESULT_TITLE.toString().trim().length == 0 ||
            data.CATEGORY.toString().trim().length == 0 ||
            //data.DECLARE_DATE.trim().length == 0 ||
            data.DEPARTMENT_ID.toString().trim().length == 0 ||
            // data.SALARY.trim().length == 0 ||
            
            data.DESCRIPTION.toString().trim().length == 0 ||
          
            data.RESULT_URL.toString().trim().length == 0 
        ) {
            setError(true);
            return false;
        } else if (
            data.CATEGORY !== "CENTRAL" &&
            (data.STATE.toString().trim().length == 0 )
        ) {
            setError(true);
            return false;
        }
        setData((PrevState) => ({
            ...PrevState,
            RESULT_TITLE: "",
            CATEGORY: "",
            STATE: "",
            DEPARTMENT_ID: "",
            DESCRIPTION: "",
            RESULT_URL: "",
            
        }));
        const model = {
            RESULT_TITLE: data.RESULT_TITLE,
            CATEGORY: data.CATEGORY,
            STATE: data.STATE,
            DEPARTMENT_ID: data.DEPARTMENT_ID,
            DESCRIPTION: data.DESCRIPTION,
            RESULT_URL: data.RESULT_URL,
            
        };
        props.onSubmit(model);

        setError(false);
    };

    //   useEffect(() => {
    //     id
    //       ? (document.title = constant.title.EditGovtJobs)
    //       : (document.title = constant.title.addGovtJobs);
    //   }, []);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value,
        });
        // DepartmentList(value)
    };
    const handlecat = (e) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value,
        });
        DepartmentList(value, 'null');
        getStateList()

    };
    const handleState = (e) => {
        debugger
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value,
        });
        DepartmentList(data.CATEGORY, value);
    };


    useEffect(() => {
        qualificationGovtList()
            .then((res) => {
                console.log(res, "result");
                if (res.status) {
                    setQualification(res.result.list);
                }
            })
            .catch((err) => {
                alert(err);
            });
    }, []);
    useEffect(() => {
        employmentTypeListsForJobs()
            .then((res) => {
                if (res.status) {
                    setJobType(res.result);
                }
            })
            .catch((err) => {
                alert(err);
            });
    }, []);
    const getStateList = () => {
        getGovtState()
            .then((res) => {
                if (res.status) {
                    setState(res.result.list);
                }
            })
            .catch((err) => {
                alert(err);
            });
    }
    useEffect(() => {
        DepartmentList();
        getStateList()
    }, []);
    const DepartmentList = (category, state) => {
        govtDepartmentList({ CATEGORY: category, STATE_ID: state })
            .then((res) => {
                if (res.status) {
                    setGovtDepartment(res.result.res);
                }
            })
            .catch((err) => {
                alert(err);
            });
    };
    return (
        <React.Fragment>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 " style={{ fontSize: "18px" }}>
                                        {id ? "Update Govt Result" : "Add Govt Result"}
                                    </h4>
                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item">
                                                <a href="javascript: void(0);">Utilities</a>
                                            </li>
                                            <li className="breadcrumb-item active">
                                                {id ? "Update Govt Result" : "Add Govt Result"}
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        {/* <h4 className="card-title mb-4">Government Jobs Information</h4> */}
                                        {props.showMsg &&
                                            (props.response.status ? (
                                                <SuccessMessage message={props.response.messageCode} />
                                            ) : (
                                                <ErrorMessage message={props.response.error} />
                                            ))}
                                        <div id="basic-example">
                                            <section>
                                                <form method="POST">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="mb-3">
                                                                <label htmlFor="basicpill-firstname-input">
                                                                    Result Title{" "}
                                                                    <span style={{ color: "#FF0000" }}>*</span>
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className={
                                                                        error && data.RESULT_TITLE.trim().length <= 0
                                                                            ? "form-control is-invalid "
                                                                            : "form-control"
                                                                    }
                                                                    id="basicpill-firstname-input"
                                                                    placeholder="Enter Job Title"
                                                                    name="RESULT_TITLE"
                                                                    value={data.RESULT_TITLE}
                                                                    onChange={handleChange}
                                                                />
                                                                {error && data.RESULT_TITLE.trim().length <= 0 ? (
                                                                    <span className="text-danger ml-1">
                                                                        This Field is Required
                                                                    </span>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label htmlFor="basicpill-address-input">
                                                                    Job Category{" "}
                                                                    <span style={{ color: "#FF0000" }}>*</span>
                                                                </label>
                                                                <select
                                                                    className={
                                                                        error && data.CATEGORY.trim().length <= 0
                                                                            ? "form-control is-invalid "
                                                                            : "form-control"
                                                                    }
                                                                    value={data.CATEGORY}
                                                                    name="CATEGORY"
                                                                    onChange={handlecat}
                                                                >
                                                                    <option value="">Select Category</option>
                                                                    <option value="CENTRAL">
                                                                        Central Government
                                                                    </option>
                                                                    <option value="STATE">
                                                                        State Government
                                                                    </option>
                                                                </select>
                                                                {error &&
                                                                    data.CATEGORY.toString().trim().length <= 0 ? (
                                                                    <span className="text-danger ml-1">
                                                                        This Field is Required
                                                                    </span>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>
                                                        </div>


                                                    </div>
                                                    {data.CATEGORY !== "CENTRAL" && (
                                                        <>
                                                            
                                                                <div className="col-lg-6">
                                                                    <div className="mb-3">
                                                                        <label htmlFor="basicpill-firstname-input">
                                                                            State{" "}
                                                                            <span style={{ color: "#FF0000" }}>
                                                                                *
                                                                            </span>
                                                                        </label>
                                                                        <select
                                                                            className={
                                                                                error && data.STATE.trim().length <= 0
                                                                                    ? "form-control is-invalid "
                                                                                    : "form-control"
                                                                            }
                                                                            name="STATE"
                                                                            value={data.STATE}
                                                                            onChange={handleState}
                                                                        >
                                                                            <option value="">Select State</option>
                                                                            {state &&
                                                                                state.length > 0 &&
                                                                                state.map((item, index) => {
                                                                                    return (
                                                                                        <option
                                                                                            key={index}
                                                                                            value={item.STATE_ID}
                                                                                        >
                                                                                            {" "}
                                                                                            {item.STATE}
                                                                                        </option>
                                                                                    );
                                                                                })}
                                                                        </select>
                                                                        {error && data.STATE.trim().length <= 0 ? (
                                                                            <span className="text-danger ml-1">
                                                                                This Field is Required
                                                                            </span>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </div>
                                                                </div>

                                                            
                                                        </>
                                                    )}



                                                    <div className="row">

                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label htmlFor="basicpill-address-input">
                                                                    Department{" "}
                                                                    <span style={{ color: "#FF0000" }}>*</span>
                                                                </label>
                                                                <select
                                                                    className={
                                                                        error &&
                                                                            data.DEPARTMENT_ID.trim().length <= 0
                                                                            ? "form-control is-invalid "
                                                                            : "form-control"
                                                                    }
                                                                    value={data.DEPARTMENT_ID}
                                                                    name="DEPARTMENT_ID"
                                                                    onChange={handleChange}
                                                                >
                                                                    <option value="">Select Department</option>
                                                                    {govtDepartment &&
                                                                        govtDepartment.length > 0 &&
                                                                        govtDepartment.map((item, index) => {
                                                                            return (
                                                                                <option
                                                                                    key={index}
                                                                                    value={item.DEPARTMENT_ID}
                                                                                >
                                                                                    {item.DEPARTMENT}
                                                                                </option>
                                                                            );
                                                                        })}
                                                                    <option value="00">Others</option>
                                                                </select>
                                                                {error &&
                                                                    data.DEPARTMENT_ID.toString().trim().length <=
                                                                    0 ? (
                                                                    <span className="text-danger ml-1">
                                                                        This Field is Required
                                                                    </span>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>





                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="mb-3">
                                                                <label htmlFor="basicpill-address-input">
                                                                    Job Description{" "}
                                                                    <span style={{ color: "#FF0000" }}>*</span>
                                                                </label>
                                                                <textarea
                                                                    id="basicpill-address-input"
                                                                    className={
                                                                        error && data.DESCRIPTION.trim().length <= 0
                                                                            ? "form-control is-invalid "
                                                                            : "form-control"
                                                                    }
                                                                    rows={4}
                                                                    placeholder="Enter Government Department Details"
                                                                    defaultValue={""}
                                                                    name="DESCRIPTION"
                                                                    value={data.DESCRIPTION}
                                                                    onChange={handleChange}
                                                                />
                                                                {error &&
                                                                    data.DESCRIPTION.toString().trim().length <=
                                                                    0 ? (
                                                                    <span className="text-danger ml-1">
                                                                        This Field is Required
                                                                    </span>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label htmlFor="basicpill-address-input">
                                                                    Result URL{" "}
                                                                    <span style={{ color: "#FF0000" }}>*</span>
                                                                </label>
                                                                <input
                                                                    className={
                                                                        error &&
                                                                            data.RESULT_URL.trim().length <= 0
                                                                            ? "form-control is-invalid "
                                                                            : "form-control"
                                                                    }
                                                                    type="text"
                                                                    name="RESULT_URL"
                                                                    value={data.RESULT_URL}
                                                                    onChange={handleChange}
                                                                />
                                                                {error &&
                                                                    data.RESULT_URL.toString().trim().length <=
                                                                    0 ? (
                                                                    <span className="text-danger ml-1">
                                                                        This Field is Required
                                                                    </span>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <button
                                                                    type="button"
                                                                    onClick={onSubmit}
                                                                    className="btn btn-info waves-effect btn-label waves-light"
                                                                >
                                                                    <i className="bx bx-check-double label-icon" />
                                                                    {details
                                                                        ? "Save Changes"
                                                                        : "Create Government Result"}
                                                                </button>
                                                                &nbsp;&nbsp;
                                                                <button
                                                                    type="button"
                                                                    onClick={handleReset}
                                                                    className="btn btn-danger waves-effect btn-label waves-light"
                                                                >
                                                                    <i className="bx bx-block label-icon " />{" "}
                                                                    Cancel
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
export default AddGovtResults;
