import React, { useEffect, useState } from 'react'
import { archiveForumCategory, discussionForumCategoryList } from '../../../actions/AddForumAction'
import constant from '../../../constant'
import ForumCategory from "../../../component/Utilities/DiscussionForm/ForumCategoryList"
const SkillList = () => {

    const [List, setList] = useState(null)
    const [count, setCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [showLoader, setShowLoader] = useState(false)


    useEffect(() => {
        discussionForumList(currentPage)
        document.title = constant.title.DiscussionForumList
    }, [currentPage])


    const discussionForumList = (page) => {
        // setShowLoader(true)
        discussionForumCategoryList(page).then((res) => {
            if (res.status) {
                // setShowLoader(false)
                setList(res.result.list)
                setCount(res.result.res1.total)
            }
        }).catch((err) => {
            alert(err)
        })
    }



    const updateStatus = (model) => {

        archiveForumCategory(model).then((res) => {
            if (res.result) {
                discussionForumList(currentPage)
            } else {
                alert(res.error)
            }
        }).catch((err) => {
            alert(err)
        })
    }


    return (
        <React.Fragment>

            <ForumCategory
                List={List}
                setList={setList}
                updateStatus={updateStatus}
                discussionForumList={(page) => { discussionForumList(page) }}
                count={count}
                setPage={setCurrentPage}
                showLoader={showLoader}
            />

        </React.Fragment>
    )
}
export default SkillList