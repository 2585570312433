import moment from 'moment';
import CONSTANT from './constant';

export const ToSeoUrl = (url) => {

    // make the url lowercase         
    var encodedUrl = url.toString().toLowerCase();

    // replace & with and           
    encodedUrl = encodedUrl.split(/\&+/).join("-and-")

    // remove invalid characters 
    encodedUrl = encodedUrl.split(/[^a-z0-9]/).join("-");

    // remove duplicates 
    encodedUrl = encodedUrl.split(/-+/).join("-");

    // trim leading & trailing characters 
    encodedUrl = encodedUrl.trim('-');

    return encodedUrl.toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, '')
        ;
}

export function emailValidation(value) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
        return true
    }
    else {
        return false
    }
}

export function regexvalidatorBankAccount(value, name) {
    let regex = /^\d{9,18}$/;
    if (!regex.test(value)) {
        return false;
    }
}
export function regexvalidatorBankIFSC(value, name) {
    let regex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
    if (!regex.test(value)) {
        return false;
    }


}

export function capFirstLetterInSentence(sentence) {
    let words = sentence.split(" ").map((word) => {
        return word[0].toUpperCase() + word.slice(1);
    })
    return words.join(" ");
}

export function regexvalidatorPassword(value, name) {
    var regularExpression = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,15}$/;
    if (!regularExpression.test(value)) {
        return false;

    }
}

export const setsessionStorage = (key, jsonObj) => {
    sessionStorage.setItem(key, JSON.stringify(jsonObj));
}

export const getsessionStorage = (keyName) => {
    const item = sessionStorage.getItem(keyName);
    return JSON.parse(item);
}

export const setStorage = (key, jsonObj) => {
    localStorage.setItem(key, JSON.stringify(jsonObj));
}

export const getStorage = (keyName) => {
    const item = localStorage.getItem(keyName);
    return JSON.parse(item);
}
export const getUserId = (keyName) => {
    const item = localStorage.getItem("ud.xenatechhrms.com")
    return JSON.parse(item);
}
export const removeStorage = (keyName) => {
    localStorage.removeItem(keyName);
}

export const removeStorageAsync = (keyName) => {
    return Promise.resolve(localStorage.removeItem(keyName));
}

export const removeAllLocalStorge = () => {
    localStorage.clear();
}

export const getAuthHeader = () => {
    const token = getStorage(CONSTANT.keys.token);
    const header = {
        headers: { Authorization: "Bearer " + token }
    };
    return header;
}

export const getUploadFileHeader = () => {
    const token = getStorage(CONSTANT.keys.token);
    const header = {
        headers: {
            Authorization: "Bearer " + token,
            'content-type': 'multipart/form-data/json'
        }
    };
    return header;
}


export const onChange = (context, name, newValue, callback) => {
    context.setState({ [name]: { ...context.state[name], value: newValue } }, callback && callback);
}

export const onChangePromise = (context, name, newValue) => {
    return new Promise((resolve, reject) => {
        try {
            const updatedState = { [name]: { ...context.state[name], value: newValue } };
            context.setState(updatedState, () => {
                resolve(updatedState)
            });
        } catch (error) {
            reject({ error })
        }
    });
}

export const onArrayChange = (context, name, value, callback) => {
    const updatedValue = context.state[name].value.concat(value);
    context.setState({ [name]: { ...context.state[name], value: updatedValue } }, callback && callback);
}

export const setOptions = (context, name, value, callback) => {
    context.setState({ [name]: { ...context.state[name], options: value } }, callback && callback);
}

export const clearOptions = (context, name, value, callback) => {
    context.setState({ [name]: { ...context.state[name], options: [] } }, callback && callback);
}

export const setError = (context, name, error, callback) => {
    context.setState({ [name]: { ...context.state[name], error } }, callback && callback);
}

export const setRequired = (context, name, newIsRequired, callback) => {
    context.setState({ [name]: { ...context.state[name], isRequired: newIsRequired } }, callback && callback);
}



export const setRequiredPromise = (context, name, newIsRequired) => {
    return new Promise((resolve, reject) => {
        try {
            const updatedState = { [name]: { ...context.state[name], isRequired: newIsRequired } };
            context.setState(updatedState, () => {
                resolve(updatedState)
            });
        } catch (error) {
            reject({ error })
        }
    });
}

 

export const setShow = (context, name, newShow, callback) => {
    context.setState({ [name]: { ...context.state[name], show: newShow } }, callback && callback);
}


export const validateFunctionalForm = (state, setState) => {
    const updatedState = { ...state };
    let status = true;
  
    Object.keys(updatedState).forEach(key => {
      const field = updatedState[key];
  
      if (field.isRequired) {
        const value = field?.value;
  
        if (typeof value === 'string' && value.trim() === '') {
          updatedState[key] = { ...field, error: 'This field is required' };
          status = false;
        } else if (Array.isArray(value) && value.length === 0) {
          updatedState[key] = { ...field, error: 'This field is required' };
          status = false;
        } else {
          updatedState[key] = { ...field, error: '' };
        }
      } else {
        updatedState[key] = { ...field, error: '' };
      }
    });
  
    setState(updatedState);
    return status;
  };

export const validateForm = (context) => {
    const st = JSON.parse(JSON.stringify(context.state));
    let status = true;
    for (let key in st) {
        if (st.hasOwnProperty(key) && st[key]) {
            const name = st[key].name;
            const isRequired = st[key].isRequired;
            const value = st[key].value;
            if (isRequired && (value === null || value === undefined || value.length === 0)) {
                status = false;
                setError(context, name, 'This field is required');
            } else if (isRequired && value.toString().length > 0) {
                setError(context, name, '');
            }
        }
    }
    return status;
}


export const getDateParts = (input) => {
    const mDate = moment(input);
    return {
        day: mDate.format("ddd"),
        date: mDate.format("DD"),
        month: mDate.format("MM"),
        monthName: mDate.format("MMM"),
        year: mDate.format("YYYY"),
        hour: mDate.format("h"),
        minute: mDate.format("mm"),
        seconds: mDate.format("ss"),
        amOrpm: mDate.format("A"),
        relativeTime: mDate.fromNow()
    };
};



export const getDatePartsUnix = (input) => {
    const mDate = moment.unix(input);
    return {
        day: mDate.format("ddd"),
        date: mDate.format("DD"),
        month: mDate.format("MM"),
        monthName: mDate.format("MMM"),
        year: mDate.format("YYYY"),
        hour: mDate.format("h"),
        minute: mDate.format("mm"),
        seconds: mDate.format("ss"),
        amOrpm: mDate.format("A"),
        relativeTime: mDate.fromNow()
    };
};



export const removeCommas = (param) => {
    const formattedName = param.toLowerCase().replace(/,/g, '');
    return formattedName;
}

export const toTitleCase = (str) => {
    return str.replace(
        /\w\S*/g,
        (txt) => {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}

export const isEmpty = (input) => {
    const dataType = typeof input;
    if (input === null || input === undefined) {
        return true;
    } else if (dataType === "string") {
        const parsedValue = input.replace(/ /g, "").replace(/(?:\r\n|\r|\n)/g, '');;
        if (parsedValue.length === 0) {
            return true;
        }
    } else if (dataType === "object") {
        return Object.keys(input).length === 0;
    }
    return false;
}

export const replaceLineBreakWithBR = (input) => {
    return input.replace(/(?:\r\n|\r|\n)/g, '<br/>');
}

export const isValid24HourTime = (timeStr) => (timeStr.search(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/) !== -1)

export const isValidDateFormat = (dateString) => (dateString.search(/^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/) !== -1);

export const isValidDate = (dateString) => {
    var regEx = /^\d{4}-\d{2}-\d{2}$/;
    if (!dateString.match(regEx)) return false;  // Invalid format
    var d = new Date(dateString);
    var dNum = d.getTime();
    if (!dNum && dNum !== 0) return false; // NaN value, Invalid date
    return d.toISOString().slice(0, 10) === dateString;
}
export function isEquivalent(a, b, skip) {
    // Create arrays of property names
    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);

    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length !== bProps.length) {
        return false;
    }

    for (var i = 0; i < aProps.length; i++) {
        var propName = aProps[i];
        if (propName === skip)
            continue;
        // If values of same property are not equal,
        // objects are not equivalent
        if (a[propName] !== b[propName]) {
            return false;
        }
    }

    // If we made it this far, objects
    // are considered equivalent
    return true;
}

export const getBrowserDetail = () => {
    let nVer = navigator.appVersion;
    let nAgt = navigator.userAgent;
    let browserName = navigator.appName;
    let fullVersion = '' + parseFloat(navigator.appVersion);
    let majorVersion = parseInt(navigator.appVersion, 10);
    let nameOffset, verOffset, ix;

    // In Opera, the true version is after "Opera" or after "Version"
    if ((verOffset = nAgt.indexOf("Opera")) !== -1) {
        browserName = "Opera";
        fullVersion = nAgt.substring(verOffset + 6);
        if ((verOffset = nAgt.indexOf("Version")) !== -1)
            fullVersion = nAgt.substring(verOffset + 8);
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((verOffset = nAgt.indexOf("MSIE")) !== -1) {
        browserName = "Microsoft Internet Explorer";
        fullVersion = nAgt.substring(verOffset + 5);
    }
    // In Chrome, the true version is after "Chrome" 
    else if ((verOffset = nAgt.indexOf("Chrome")) !== -1) {
        browserName = "Chrome";
        fullVersion = nAgt.substring(verOffset + 7);
    }
    // In Safari, the true version is after "Safari" or after "Version" 
    else if ((verOffset = nAgt.indexOf("Safari")) !== -1) {
        browserName = "Safari";
        fullVersion = nAgt.substring(verOffset + 7);
        if ((verOffset = nAgt.indexOf("Version")) !== -1)
            fullVersion = nAgt.substring(verOffset + 8);
    }
    // In Firefox, the true version is after "Firefox" 
    else if ((verOffset = nAgt.indexOf("Firefox")) !== -1) {
        browserName = "Firefox";
        fullVersion = nAgt.substring(verOffset + 8);
    }
    // In most other browsers, "name/version" is at the end of userAgent 
    else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) <
        (verOffset = nAgt.lastIndexOf('/'))) {
        browserName = nAgt.substring(nameOffset, verOffset);
        fullVersion = nAgt.substring(verOffset + 1);
        if (browserName.toLowerCase() === browserName.toUpperCase()) {
            browserName = navigator.appName;
        }
    }
    // trim the fullVersion string at semicolon/space if present
    if ((ix = fullVersion.indexOf(";")) !== -1)
        fullVersion = fullVersion.substring(0, ix);
    if ((ix = fullVersion.indexOf(" ")) !== -1)
        fullVersion = fullVersion.substring(0, ix);

    majorVersion = parseInt('' + fullVersion, 10);
    if (isNaN(majorVersion)) {
        fullVersion = '' + parseFloat(navigator.appVersion);
        majorVersion = parseInt(navigator.appVersion, 10);
    }

    return {
        nVer, nAgt, browserName, fullVersion, majorVersion, nameOffset, verOffset, ix
    }
}

export const getOSDetail = () => {
    let OSName = "Unknown OS";
    if (navigator.appVersion.indexOf("Win") !== -1) OSName = "Windows";
    if (navigator.appVersion.indexOf("Mac") !== -1) OSName = "MacOS";
    if (navigator.appVersion.indexOf("X11") !== -1) OSName = "UNIX";
    if (navigator.appVersion.indexOf("Linux") !== -1) OSName = "Linux";

    return OSName;
}

export const getRandomNumber = (start, end) => {
    // return Math.floor(Math.random() * end) + start
    return Math.floor(Math.random() * (end - start) + start);
}

export const getRandomColorCSS = (index) => {
    const colorsCSS = [
        "bg-primary",
        "bg-warning",
        "bg-danger",
        "bg-info",
        "bg-secondary",
        "bg-primary",
        "bg-warning",
        "bg-danger",
        "bg-info",
        "bg-secondary",
        "bg-primary",
        "bg-warning",
        "bg-danger",
        "bg-info",
        "bg-secondary",
        "bg-primary",
        "bg-warning",
        "bg-danger",
        "bg-info",
        "bg-secondary",
        "bg-primary",
        "bg-warning",
        "bg-danger",
        "bg-info",
        "bg-secondary",
        "bg-primary",
        "bg-warning",
        "bg-danger",
        "bg-info",
        "bg-secondary",
    ]
    if (index !== undefined && index !== null) {
        return colorsCSS[index];
    }
    return colorsCSS[Math.floor(Math.random() * colorsCSS.length) + 1];
}

export const capitalize = (input) => {
    var words = input?.trim()?.split(' ');
    var CapitalizedWords = [];
    words?.forEach(element => {
        CapitalizedWords.push(element[0]?.toUpperCase() + element.slice(1, element.length));
    });
    return CapitalizedWords.join(' ');
}