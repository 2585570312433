import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useNavigate, useParams } from "react-router-dom";
import {
  educationQualificationType,
  getEducationQualificationById,
} from "../../actions/JobsAction";
import SuccessMessage from "../common/SuccessMessage";
import ErrorMessage from "../common/ErrorMessage";
import constant from "../../constant";

function AddQualifications(props) {
  const { id } = useParams();
  const [educationQualificationTypeList, setEducationQualificationType] =
    useState([]);

  const [qualificationType, setQualificationType] = useState('')
  const [qualificationDetails, setQualificationDetails] = useState('')
  const [course, setCourse] = useState('')
  const [sortNum, setSortNum] = useState('')
  const [education_qualification_id, seteducation_qualification_id] = useState('')
  const [status, setStatus] = useState('')
  const [error, setError] = useState(false)
  const Navigate = useNavigate()
  const params = useParams()

  const onSubmit = (e) => {
    let pn = params.pageno
    e.preventDefault();
    if (qualificationType.toString().trim().length === 0 || course.toString().trim().length === 0 || sortNum.toString().trim().length === 0) {
      setError(true)
      return false
    }
    if (id) {
      const model = {
        EDUCATION_QUALIFICATION_ID: education_qualification_id,
        QUALIFICATION_ID: qualificationType,
        COURSE_STREAM: course,
        COURSE_STREAM_DETAILS: qualificationDetails,
        SORT_NUMBER: sortNum,
        type: "UPDATE",
      };
      props.onSubmit(model);
      model.pageno = pn
    } else {
      const model = {
        QUALIFICATION_ID: qualificationType,
        COURSE_STREAM: course,
        COURSE_STREAM_DETAILS: qualificationDetails,
        SORT_NUMBER: sortNum,
        type: "ADD"
      };
      props.onSubmit(model);
      model.pageno = pn
    }
  };

  useEffect(() => {
    id && getEducationQualificationDetails(id);
    (id ? document.title = constant.title.UpdateQualification : document.title = constant.title.AddQualification)
    getEducationQualificationType();
  }, []);


  const getEducationQualificationType = () => {
    educationQualificationType()
      .then((res) => {
        if (res.status) {
          setEducationQualificationType(res.result);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const getEducationQualificationDetails = (id) => {
    getEducationQualificationById({ EDUCATION_QUALIFICATION_ID: id })
      .then((res) => {
        if (res.status) {
          setQualificationType(res.result.QUALIFICATION_ID)
          seteducation_qualification_id(res.result.EDUCATION_QUALIFICATION_ID)
          setQualificationDetails(res.result.COURSE_STREAM_DETAILS)
          setCourse(res.result.COURSE_STREAM)
          setSortNum(res.result.SORT_NUMBER)
          setStatus(res.result.STATUS)
        }
      })
      .catch((err) => {
        alert(err);
      });
  };
  const handleReset = () => {
    id && Navigate(constant.component.listQualification.url)
    setError(false)
    setQualificationType('')
    setQualificationDetails('')
    setCourse("")
    setSortNum("")
  }
  return (
    <React.Fragment>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0" style={{ fontSize: "18px" }}>
                    {id ? " Update   Educational Qualification " : "  Add   Educational Qualification "}
                  </h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Jobs</a>
                      </li>
                      <li className="breadcrumb-item active">
                        {id ? " Update   Educational Qualification " : "  Add   Educational Qualification "}
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title mb-4">
                      Educational Qualification Information
                    </h4>
                    {
                      props.showMsg && (props.response.status ?
                        <SuccessMessage message={props.response.messageCode} />
                        :
                        <ErrorMessage message={props.response.error} />)
                    }
                    <div id="basic-example">
                      <section>
                        <form method="POST">
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label for="basicpill-firstname-input">
                                  Educational Qualification <span className="text-danger"> *</span>
                                </label>
                                <select
                                  className={error && qualificationType.toString().trim().length <= 0 ? 'form-control is-invalid ' : 'form-control'}
                                  value={qualificationType}
                                  onChange={(e) => {
                                    setQualificationType(e.target.value);
                                  }}
                                >
                                  <option>
                                    Select Educational Qualification
                                  </option>
                                  {educationQualificationTypeList.map(
                                    (data, index) => {
                                      return (
                                        <option value={data.QUALIFICATION_ID}>
                                          {data.QUALIFICATION_NAME}
                                        </option>
                                      );
                                    }
                                  )}
                                </select>
                                {
                                  error && qualificationType.toString().trim().length <= 0 ? <span className='text-danger ml-1'>This Field is Required</span>
                                    : ""}
                              </div>
                            </div>

                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label for="basicpill-firstname-input">
                                  Course / Stream <span className="text-danger"> *</span>
                                </label>
                                <input
                                  type="text"
                                  className={error && course.toString().trim().length <= 0 ? 'form-control is-invalid ' : 'form-control'}
                                  id="basicpill-firstname-input"
                                  placeholder="Enter Educational Qualification Course / Stream"
                                  value={course}
                                  onChange={(e) => {
                                    setCourse(e.target.value);
                                  }}
                                />
                                {
                                  error && course.toString().trim().length <= 0 ? <span className='text-danger ml-1'>This Field is Required</span>
                                    : ""}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label for="basicpill-address-input">
                                  Sort Number <span className="text-danger"> *</span>
                                </label>
                                <NumberFormat
                                  allowLeadingZeros={false}
                                  allowNegative={false}
                                  thousandSeparator={false}
                                  maxlength={10}
                                  className={error && sortNum.toString().trim().length <= 0 ? 'form-control is-invalid ' : 'form-control'}
                                  id="basicpill-firstname-input"
                                  placeholder="Enter Sort Number"
                                  value={sortNum}
                                  onChange={(e) => {
                                    setSortNum(e.target.value);
                                  }}
                                />
                                {
                                  error && sortNum.toString().trim().length <= 0 ? <span className='text-danger ml-1'>This Field is Required</span>
                                    : ""}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label for="basicpill-address-input">
                                  Educational Qualification Details
                                </label>
                                <textarea
                                  id="basicpill-address-input"
                                  className="form-control"
                                  rows="2"
                                  placeholder="Enter Functional Area Details"
                                  value={qualificationDetails}
                                  onChange={(e) => {
                                    setQualificationDetails(e.target.value);
                                  }}
                                ></textarea>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <button
                                  type="button"
                                  className="btn btn-info waves-effect btn-label waves-light"
                                  onClick={onSubmit}
                                >
                                  <i className="bx bx-check-double label-icon"></i>{" "}
                                  {id ? 'Save Changes' : 'Create Educational Qualification'}
                                </button>
                                &nbsp;&nbsp;
                                <button
                                  type="button"
                                  onClick={handleReset}
                                  className="btn btn-danger waves-effect btn-label waves-light"
                                >
                                  <i className="bx bx-block label-icon "></i>{" "}
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AddQualifications;
