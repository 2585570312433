import React, { useState } from 'react'
import { forgotPassword } from '../../actions/AuthActions'
import jobzshala from "../../assets/images/jobzshala.png";
import constant from '../../constant'
import Loader from '../../component/common/Loader';
import { Link } from 'react-router-dom';
import moment from 'moment';

const ForgotPassword = () => {

  const [data, setData] = useState({
    EMAIL_ID: ''
  })
  const [showLoader, setShowLoader] = useState(false)

  const [error, SetError] = useState(false)
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (data.EMAIL_ID.length === 0) {
      SetError(true)
    }
    else {
      SetError(true)

    }

    setData(prevState => ({
      ...prevState,
      [name]: value
    }))
  }
  const onSubmit = (e) => {
    e.preventDefault();
    if (data.EMAIL_ID.length === 0) {
      SetError(true)
      return false
    }
    else {
      setShowLoader(true)
      forgotPassword({ EMAIL_ID: data.EMAIL_ID }).then(res => {
        setShowLoader(true)
        if (res.status) {
          alert(res.messageCode)
        } else {
          alert(res.error)
        }
      }).catch(err => {
        alert(err)
      })

    }
  }

  return (
    <React.Fragment>
      <div className="auth-page-wrapper pt-5">
        {/* auth page bg */}
        <div className="auth-one-bg-position auth-one-bg" id="auth-particles">
          <div className="bg-overlay" />
          <div className="shape">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 1440 120"
            >
              <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z" />
            </svg>
          </div>
          <canvas
            className="particles-js-canvas-el"
            width={1898}
            height={475}
            style={{ width: "100%", height: "100%" }}
          />
        </div>
        {/* auth page content */}
        <div className="auth-page-content">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="text-center mt-sm-5 text-white-50">
                  <div>
                    <Link to={constant.component.login.url} className="d-inline-block auth-logo">
                      <img src={jobzshala} alt="" height={60} />
                    </Link>
                  </div>
                  <p className=" fs-15 fw-medium">
                    Sign in to continue to JobzShala Administration Control Panel.
                  </p>
                </div>
              </div>
            </div>
            {/* end row */}
            <div className="row justify-content-center">
              <div className="col-md-8 col-lg-6 col-xl-5">
                <div className="card mt-4">
                  <div className="card-body p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-hd-log">Forgot Password</h5>
                      <p className="text-muted">Forgot Password with <span className='rest-fg-text'>JobzShala</span>.</p>
                    </div>
                    <div className="p-2 mt-4">
                      <div class="alert alert-success text-center mb-4" role="alert">Enter your Email and instructions will be sent to you!</div>
                      <form onSubmit={onSubmit}>
                        <div className="mb-3">
                          <label htmlFor="username" className="form-label">
                            Email
                          </label>
                          <input
                            type="email"
                            className={error ? "form-control is-invalid" : "form-control"}
                            id="useremail"
                            placeholder="Enter email"
                            name='EMAIL_ID'
                            value={data.EMAIL_ID}
                            onChange={handleChange}
                          />

                          {error && data.EMAIL_ID.length <= 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}
                        </div>

                        {/* <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue=""
                      id="auth-remember-check"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="auth-remember-check"
                    >
                      Remember me
                    </label>
                  </div> */}
                        <div className="mt-4">
                          <button className="btn btn-success btn-successlogin w-100" type="submit">
                            Forgot
                          </button>
                        </div>
                        {/* <div className="float-end-center">
                      <a
                        href="auth-pass-reset-basic.html"
                        className="text-muted"
                      >
                        Forgot password?
                      </a>
                    </div> */}
                      </form>
                    </div>
                  </div>
                  {/* end card body */}
                </div>
                {/* end card */}
                <div className="mt-4 text-center">
                  <p className="mb-0">
                    Remember It  ?{" "}
                    <Link
                      to={constant.component.login.url}
                      className="fw-semibold text-primary text-decoration-underline"
                    >
                      {" "}
                      Sign In here{" "}
                    </Link>{" "}
                  </p>
                </div>
              </div>
            </div>
            {/* end row */}
          </div>
          {/* end container */}
        </div>
        {/* end auth page content */}
        {/* footer */}
        <footer className="footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="text-center">
                  <p className="mb-0 text-muted">
                    © {moment().format('YYYY')} JobzShala.com <i className="mdi mdi-heart text-danger" />{" "}
                    Powered by Akshaki India (P) Limited.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
        {/* end Footer */}
      </div>

    </React.Fragment>
  )
}

export default ForgotPassword