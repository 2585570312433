import React, { Component, useState } from "react";
import { Collapse, Button, CardBody, Card } from "reactstrap";
import swal from "sweetalert";
import {
  addNote,
  listNote,
  deleteNote,
  editNote,
} from "../../actions/components";
import ModalWindow from "./ModalWindow";
import { getDateParts } from "../../utils";

export default class Stickynote extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = { collapse: false };
    this.state = {
      CONTENT: "",
      COLOR_CODE: "",
      notes: [],
      showChat: true,
      ShowModal: false,
      listNotes: "",
      count: "",
      ID: "",
      editData: undefined,
    };
    // this.onChange = this.onChange.bind(this);
    // this.onSave = this.onSave.bind(this);
  }

  componentDidMount() {
    this.getlistNote();
  }

  getlistNote = () => {
    listNote().then((res) => {
      if (res.status) {
        this.setState({
          listNotes: res.result,
          count: res.result.count,
          // NoteID: res.result.list.map((i) => {
          //   return i.ID;
          // }),
        });
      }
    });
  };

  onDelete = (NoteID) => {
    // const{NoteID}=this.state;
    // e.preventDefault()
    let model = {
      ID: NoteID,
    };
    deleteNote(model).then((res) => {
      if (res.status) {
        swal({
          icon: "success",
          text: "Your Note is successfully delete.",
          timer: 2000,
          showCancelButton: false,
          showConfirmButton: false,
        });
        this.getlistNote();
      } else {
        swal({
          icon: "error",
          text: res.error,
          timer: 2000,
          showCancelButton: false,
          showConfirmButton: false,
        });
      }
    });
  };

  onSubmit = (e) => {
    const { CONTENT, COLOR_CODE, CREATED_BY, MODIFIED_BY, editNotes } =
      this.state;
    e.preventDefault();
    let model = {
      CONTENT: CONTENT,
      COLOR_CODE: COLOR_CODE,
    };
    if (editNotes) {
      model.ID = editNotes;
      editNote(model).then((res) => {
        if (res.status) {
          swal({
            icon: "success",
            text: "Your Note is successfully added.",
            timer: 2000,
            showCancelButton: false,
            showConfirmButton: false,
          });
          this.getlistNote();
          this.setState({ ShowModal: false });
        } else {
          swal({
            icon: "error",
            text: res.error,
            timer: 2000,
            showCancelButton: false,
            showConfirmButton: false,
          });
        }
      });
    } else {
      addNote(model).then((res) => {
        if (res.status) {
          swal({
            icon: "success",
            text: "Your Note is successfully added.",
            timer: 2000,
            showCancelButton: false,
            showConfirmButton: false,
          });
          this.getlistNote();
          this.setState({ ShowModal: false });
        } else {
          swal({
            icon: "error",
            text: res.error,
            timer: 2000,
            showCancelButton: false,
            showConfirmButton: false,
          });
        }
      });
    }
  };

  toggle() {
    this.setState({ collapse: !this.state.collapse });
  }

  startChat = () => {
    this.setState({ showChat: true });
  };
  render() {
    const {
      ShowModal,
      CONTENT,
      listNotes,
      count,
      COLOR_CODE,
      deleteNote,
      NoteID,
      editNote,
    } = this.state;
    return (
      <React.Fragment>
        {ShowModal && (
          <ModalWindow
            toggleModal={() => {
              this.setState({ ShowModal: false });
            }}
            color={COLOR_CODE}
            className="Addnotes-model-tittle"
            title="Add New Sticky Note"
            backdrop="static"
          >
            <div className="form-group">
              <h5>Sticky Note:</h5>
              <textarea
                style={{
                  height: "70px",
                  width: "100%",
                  borderColor: "#ddd",
                  borderRadius: "10px",
                  margin: "5px",
                  padding: "10px",
                }}
                value={CONTENT}
                name="CONTENT"
                onChange={(e) => {
                  this.setState({ CONTENT: e.target.value });
                }}
              ></textarea>
            </div>
            <div className="form-group">
              <h5>Colors</h5>
              <div>
                <ul className="icolors">
                  <li
                    id="red"
                    value={COLOR_CODE}
                    name="COLOR_CODE"
                    onClick={() => this.setState({ COLOR_CODE: "#ff0000" })}
                    className={
                      COLOR_CODE == "#ff0000"
                        ? "red active selectColor"
                        : "red selectColor"
                    }
                  ></li>
                  <li
                    id="green"
                    value={COLOR_CODE}
                    name="COLOR_CODE"
                    onClick={() => {
                      this.setState({ COLOR_CODE: "#008000" });
                    }}
                    className={
                      COLOR_CODE == "#008000"
                        ? "green active selectColor"
                        : "green selectColor"
                    }
                  ></li>
                  <li
                    id="blue"
                    value={COLOR_CODE}
                    name="COLOR_CODE"
                    onClick={() => {
                      this.setState({ COLOR_CODE: "#0000ff" });
                    }}
                    className={
                      COLOR_CODE == "#0000ff"
                        ? "blue active selectColor"
                        : "blue selectColor"
                    }
                  ></li>
                  <li
                    id="yellow"
                    value={COLOR_CODE}
                    name="COLOR_CODE"
                    onClick={() => {
                      this.setState({ COLOR_CODE: "#efef0ced" });
                    }}
                    className={
                      COLOR_CODE == "#efef0ced"
                        ? "yellow active selectColor"
                        : "yellow selectColor"
                    }
                  ></li>
                  <li
                    id="purple"
                    value={COLOR_CODE}
                    name="COLOR_CODE"
                    onClick={() => {
                      this.setState({ COLOR_CODE: "#800080" });
                    }}
                    className={
                      COLOR_CODE == "#800080"
                        ? "purple active selectColor"
                        : "purple selectColor"
                    }
                  ></li>
                </ul>
              </div>
              <div
                style={{
                  display: "block",
                  width: "100%",
                  float: "left",
                  marginTop: "10px",
                }}
              >
                If you will not choose any color black will be default.
              </div>
              <div
                style={{
                  display: "block",
                  width: "100%",
                  float: "left",
                  marginTop: "10px",
                }}
              >
                <button
                  type="submit"
                  style={{
                    padding: "10px 15px",
                    backgroundColor: "red",
                    border: "1px solid red",
                    color: "white",
                    borderRadius: "5px",
                    float: "right",
                    fontSize: "15px",
                  }}
                  onClick={(e) => this.onSubmit(e)}
                >
                  Save
                </button>
              </div>
            </div>
          </ModalWindow>
        )}
        <div className="chatboxfooter">
          <div className="btn addnotesbtn">
            Notes
            <div
              className="count-bx"
              style={{
                border: "1px solid grey",
                padding: "0px 2px",
                borderRadius: "5px",
                backgroundColor: "yellow",
                color: "black",
              }}
            >
              {count}
            </div>
            <div
              className="add-note-bx btn-outline-success"
              onClick={() => {
                this.setState({ ShowModal: true });
                this.setState({ COLOR_CODE: "black" });
                this.setState({ CONTENT: "", editNotes: undefined });
              }}
            >
              + Add Notes
            </div>

            <div
              className="sticky-count-bx"
              style={{
                borderRadius: "50px",
                padding: "2px",
                backgroundColor: "white",
              }}
              onClick={this.toggle}
            >
              <i
                className="fa fa-angle-down fa-2x"
                style={{
                  color: "black",
                  fontSize: "17px",
                  marginLeft: "5px",
                  marginTop: "2px",
                  cursor: "pointer",
                  marginRight: "5px",
                  lineHeight: "22px",
                }}
              >
                {" "}
              </i>
            </div>
          </div>

          <Collapse isOpen={this.state.collapse}>
            <div className="sticky-list-box">
              {listNotes.list &&
                listNotes.list.map((item) => {
                  return (
                    <div
                      style={{ backgroundColor: item.COLOR_CODE }}
                      className="well b-none"
                    >
                      <p style={{ color: "white", fontSize: "13px", wordBreak: "break-all" }}>
                        {item.CONTENT}
                      </p>
                      <hr style={{ color: "white" }} />
                      <div className="row font-12">
                        <div className="col-md-12" style={{ color: "white" }}>
                          {"Updated : " +
                            getDateParts(item.CREATED_ON).relativeTime}
                        </div>
                        <div className="col-md-12 mt-2">
                          <a
                            href="javascript:;"
                            className="text-white edit-sik"
                            onClick={() => {
                              this.setState({ ShowModal: true });
                              this.setState({ COLOR_CODE: "black" });
                              this.setState({
                                CONTENT: item.CONTENT,
                                editNotes: item.ID,
                              });
                            }}
                          >
                            <i class="fa fa-pencil-alt"></i> Edit
                          </a>
                          <a
                            href="javascript:;"
                            className="text-white ml-2 close-sik"
                            onClick={() => {
                              this.onDelete(item.ID);
                            }}
                          >
                            <i class="fa fa-times"></i> Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </Collapse>

        </div>
      </React.Fragment>
    );
  }
}
