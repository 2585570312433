import * as axios from "axios";
import { getAuthHeader } from "./../utils";

export const addDesignation = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/create-designation`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};
export const addPracticeQuestion = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/create-practice-question`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};
export const addSkills = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/create-skills`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};
export const addBlogCategory = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/create-blog-category`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};
export const getBlogCategory = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/list-blog-category`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};
export const addBlog = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/add-blog`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const addJobType = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/create-job-type`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const getGovtDeptList = (page) => {
    const url = `${process.env.REACT_APP_BASE_URL}/govt-department-list?page=${page}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};
export const getGovtJobsList = (page) => {
    const url = `${process.env.REACT_APP_BASE_URL}/govt-job-list?page=${page}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};
export const getGovtResultList = (page) => {
    const url = `${process.env.REACT_APP_BASE_URL}/govt-result-list?page=${page}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};
export const getActiveBlogCategory = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/active-blog-category`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};


export const getDesignationList = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/designation-list`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const getJobCategoryList = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/job-category-list`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const getSkillList = (data) => {
    const url = `${process.env.REACT_APP_BASE_URL}/skill-list`;
    return axios.post(url, data, getAuthHeader()).then((res) => {
        return res.data;
    })
};


export const getJobTypeList = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/job-type-list`;

    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};
export const addGovtDepartment = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/create-goverment-department`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};
export const addJobCategory = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/create-job-category`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const addSalary = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/create-salary`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const getSalaryList = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/salary-list`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};


export const archiveSkill = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/archive-skill`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const archiveBlogCategory = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/archive-blog-category`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const archiveDesignation = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/archive-designation`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const archiveSalary = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/archive-salary`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const archiveJobCategory = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/archive-job-category`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};


export const archiveJobType = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/archive-job-type`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const archiveGovtDept = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/archive-govt-dept`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};
export const archiveGovtJobs = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/update-govtjob-status`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};
export const archiveGovtJobsResult = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/update-govtresult-status`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};
export const getActiveFQACategory = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/active-faq-category`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};


export const getActiveJobsFQACategory = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/active-jobs-faq-category`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};


export const addFAQCategory = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/create-faq-category`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};


export const addJobsFAQCategory = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/create-jobs-faq-category`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};
export const uploadBlogPicture = (file) => {
    const url = `${process.env.REACT_APP_BASE_URL}/upload-blog-picture`;
    return axios.post(url, file, getAuthHeader()).then((res) => {
        return res.data
    })
};

export const getfaqCategoryList = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/list-faq-category`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};
export const getjobsfaqCategoryList = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/list-jobs-faq-category`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const blogList = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/blog-list`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};


export const FAQCategorybyId = (data) => {
    debugger
    const url = `${process.env.REACT_APP_BASE_URL}/FAQCategory-By-Id`;
    return axios.post(url, data, getAuthHeader()).then((res) => {
        return res.data;
    })
};


export const JobsFAQCategorybyId = (data) => {
    debugger
    const url = `${process.env.REACT_APP_BASE_URL}/JobsFAQCategory-By-Id`;
    return axios.post(url, data, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const updateFAQCategory = (data) => {
    const url = `${process.env.REACT_APP_BASE_URL}/update-faq-category`;
    return axios.put(url, data, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const updateJobsFAQCategory = (data) => {
    const url = `${process.env.REACT_APP_BASE_URL}/update-jobs-faq-category`;
    return axios.put(url, data, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const addFaq = (data) => {
    const url = `${process.env.REACT_APP_BASE_URL}/create-faq`;
    return axios.post(url, data, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const updateFaq = (data) => {
    const url = `${process.env.REACT_APP_BASE_URL}/update-faq`;
    return axios.put(url, data, getAuthHeader()).then((res) => {
        return res.data;
    })
};


export const addJobsFaq = (data) => {
    const url = `${process.env.REACT_APP_BASE_URL}/create-jobs-faq`;
    return axios.post(url, data, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const updateJobsFaq = (data) => {
    const url = `${process.env.REACT_APP_BASE_URL}/update-jobs-faq`;
    return axios.put(url, data, getAuthHeader()).then((res) => {
        return res.data;
    })
};



export const listFaq = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/faq-list`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};


export const listJobsFaq = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/jobs-faq-list`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const FAQbyId = (data) => {
    const url = `${process.env.REACT_APP_BASE_URL}/FAQ-By-Id`;
    return axios.post(url, data, getAuthHeader()).then((res) => {
        return res.data;
    })
};


export const JobsFAQbyId = (data) => {
    const url = `${process.env.REACT_APP_BASE_URL}/JobsFAQ-By-Id`;
    return axios.post(url, data, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const archivFaq = (data) => {
    const url = `${process.env.REACT_APP_BASE_URL}/archive-faq`;
    return axios.put(url, data, getAuthHeader()).then((res) => {
        return res.data;
    })
};


export const archivJobsFaq = (data) => {
    const url = `${process.env.REACT_APP_BASE_URL}/archive-jobs-faq`;
    return axios.put(url, data, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const archiveFaqCategory = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/archive-faq-category`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};


export const archiveJobsFaqCategory = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/archive-jobs-faq-category`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};


export const countryList = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/country-list`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}
export const getFAQCategoryList = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/faq-category-list`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const getJobsFAQCategoryList = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/jobs-faq-category-list`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const stateList = (COUNTRY_ID) => {
    const url = `${process.env.REACT_APP_BASE_URL}/state-list?COUNTRY_ID=${COUNTRY_ID}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const cityList = (STATE_ID) => {
    const url = `${process.env.REACT_APP_BASE_URL}/city-list?STATE_ID=${STATE_ID}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}



export const updateSkills = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/update-skill`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })

}


export const updateDesignation = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/update-designation`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}


export const designationById = (id) => {
    const url = `${process.env.REACT_APP_BASE_URL}/Designation-By-Id/?DESIGNATION_ID=${id}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const salaryTypeById = (id) => {
    const url = `${process.env.REACT_APP_BASE_URL}/Salary-By-Id/?SALARY_ID=${id}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}
export const PracticeQuestionbyId = (id) => {
    const url = `${process.env.REACT_APP_BASE_URL}/practice-question-By-Id/?PRACTICE_QUESTION__ID=${id}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const updateSalaryType = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/update-salary`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}
export const updatePracticeQuestion = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/update-practice-question`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const jobCategoryById = (id) => {
    const url = `${process.env.REACT_APP_BASE_URL}/JobCategory-By-Id?JOB_CATEGORY_ID=${id}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}
export const updateJobCategory = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/update-job-category`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}
export const jobTypeById = (id) => {
    const url = `${process.env.REACT_APP_BASE_URL}/Jobtype-By-Id?JOB_TYPE_ID=${id}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}
export const updateJobType = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/update-job-type`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}
export const govtDepartmentById = (id) => {
    const url = `${process.env.REACT_APP_BASE_URL}/GovtDept-By-Id?GOVT_DEPT_ID=${id}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}
export const updateGovtDepartment = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/update-govt-dept`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}
export const blogCategorybyId = (id) => {
    const url = `${process.env.REACT_APP_BASE_URL}/BlogCategory-By-Id?CATEGORY_ID=${id}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}
export const updateBlogCategory = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/update-blog-category`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const uploadArticlePicture = (file) => {
    const url = `${process.env.REACT_APP_BASE_URL}/upload-article-pic`;
    return axios.post(url, file, getAuthHeader()).then((res) => {
        return res.data
    })
};

export const addArticleCategory = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/create-article-category`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const getArticleCategory = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/list-article-category?page= ${model.page}&KEYWORD=${model.KEYWORD}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const archiveArticleCategory = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/archive-article-category`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const addArticle = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/add-article`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const getActiveArticleCategory = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/active-article-list`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const articleList = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/article-list`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const updateArticleCategory = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/update-article-category`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const ArticleCategorybyId = (id) => {
    const url = `${process.env.REACT_APP_BASE_URL}/ArticleCategory-By-Id?CATEGORY_ID=${id}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const addTestimonialCategory = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/create-testimonial`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const testimonialList = (data) => {
    const url = `${process.env.REACT_APP_BASE_URL}/testimonial-list`;
    return axios.post(url, data, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const archiveTestimonial = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/archive-testimonial`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const updateTestimonial = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/update-testimonial`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const TestimonialbyId = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/testimonial-By-Id`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const blogById = (id) => {
    const url = `${process.env.REACT_APP_BASE_URL}/Blog-By-Id?BLOG_ID=${id}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const updateBlog = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/update-blog`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const archiveBlog = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/archive-blog`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const getCategory = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/jzsh-blog-category-list`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const articleById = (id) => {
    const url = `${process.env.REACT_APP_BASE_URL}/Article-By-Id?ARTICLE_ID=${id}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const updateArticle = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/update-article`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};


export const archiveArticle = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/archive-article`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const getBlogCommentList = (page) => {
    const url = `${process.env.REACT_APP_BASE_URL}/blog-comment-all?page=${page}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const updateCommentStatus = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/update-Blog-Comment-Status`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};
export const commentByBlogId = (body) => {
    const url = `${process.env.REACT_APP_BASE_URL}/comment-Blog-By-Id?BLOG_ID=${body.BLOG_ID}&page=${body.page}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const getEnquiryList = (data) => {
    const url = `${process.env.REACT_APP_BASE_URL}/jzsh-enquiry-list`;
    return axios.put(url, data, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const MaintainanceEnquiryList = (data) => {
    const url = `${process.env.REACT_APP_BASE_URL}/maintainance-enquiry-list?page=${data.page}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const deleteMaintainanceEnquiry = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/maintainance-enquiry-delete`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const getComplaintList = (data) => {
    const url = `${process.env.REACT_APP_BASE_URL}/complaint-list`;
    return axios.post(url, data, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const deleteComplaint = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/complaint-delete`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};
/***Add by jitender** */
export const skillKeywordSearch = (KEYWORD) => {
    const url = `${process.env.REACT_APP_BASE_URL}/keyword-search?KEYWORD=${KEYWORD}`;
    return axios.get(url).then((res) => {
        return res.data;
    })
};
/***End** */

export const activeSkillList = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/active-skill-list`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const addInterviewQuestion = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/create-interview-question`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const InterviewQuestionFormData = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/interview-question-formdata`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const practiceQuestionList = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/practice-question-list`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const archivePractiseQuestion = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/archive-practice-question`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const getInterviewQuestion = (page) => {
    const url = `${process.env.REACT_APP_BASE_URL}/interview-question-list?page=${page}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const deleteEnquiry = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/delete-enquiry-list-by-id`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const deleteBlogComments = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/delete-comment-blog-by-id`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const archiveInterviewQuestion = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/archive-interview-question-by-id`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const answerlistbyQuestionId = (INTERVIEW_QUESTIONS_ID, page) => {
    const model = { INTERVIEW_QUESTIONS_ID: INTERVIEW_QUESTIONS_ID, page: page }
    const url = `${process.env.REACT_APP_BASE_URL}/answer-list-by-qid`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const archiveInterviewAnswer = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/approve-answer`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const deleteAnswerByQuestionId = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/interview-answer-delete`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const salesEnquiryList = (page) => {
    const url = `${process.env.REACT_APP_BASE_URL}/sales-enquiry-list?page=${page}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const OutplacementenqLists = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/outplacement-list?page=${model.page ? model.page : 1}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const deleteOutplacement = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/delete-outplacement-list-by-id?OUTPLACEMENT_ID=${model.OUTPLACEMENT_ID}`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const deletesalesEnquiryId = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/delete-sales-enquiry`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};
export const updateInterviewQuestion = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/update-interview-question`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const InterviewQuestionById = (id) => {
    const url = `${process.env.REACT_APP_BASE_URL}/interview-question-by-id?INTERVIEW_QUESTIONS_ID=${id}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const TotalInterviewCount = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/total-interview-question`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}
export const ReportIssueList = (page) => {
    const url = `${process.env.REACT_APP_BASE_URL}/reported-issues-list?page=${page}&limit=10`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const ReportIssueReply = (data) => {
    const url = `${process.env.REACT_APP_BASE_URL}/reply-on-report`;
    return axios.post(url, data, getAuthHeader()).then((res) => {
        return res.data;
    })
};



export const UpdateIssue = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/update-issues-status`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const recruiterEnquiryList = (data) => {
    const url = `${process.env.REACT_APP_BASE_URL}/recruiter-enquiry-list`;
    return axios.put(url, data, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const deletePersonalRecruiterEnquiry = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/delete-personal-recruiter-enquiry`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const updateDiscussionforumactivethread = (data) => {
    const url = `${process.env.REACT_APP_BASE_URL}/cp-discussion-thread-List?page=${data.page}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data
    })
}

export const archiveDiscussionforumactivethread = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/active-discussion-thread`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const deleteDiscussionthread = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/delete-thread-by-id`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const Discussionthreadvalue = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/discussion-thread-update`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const onTypeChangeData = (enqType) => {
    const url = `${process.env.REACT_APP_BASE_URL}/enquiry-list-type?enqType=${enqType}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const JobAlertList = (data) => {

    const url = `${process.env.REACT_APP_BASE_URL}/Job-alert-list?page=${data.page}`;
    return axios.put(url, data, getAuthHeader()).then((res) => {
        return res.data;
    })
};
export const deleteAlertListById = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/alert-msg-delete`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};
export const AddStatusUpdate = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/cp-status-update`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};
export const getShareInterviewQuestions = (page) => {
    const url = `${process.env.REACT_APP_BASE_URL}/share-interview-question-list?page=${page}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};
export const shareanswerlistbyQuestionId = (INTERVIEW_QUESTIONS_ID, page) => {
    const model = { INTERVIEW_QUESTIONS_ID: INTERVIEW_QUESTIONS_ID, page: page }
    const url = `${process.env.REACT_APP_BASE_URL}/share-answer-list-by-qid`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}
export const archiveshareInterviewQuestions = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/archive-share-interview-question-by-id`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};
export const archiveshareInterviewQuestions1 = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/archive-share-interview-question-id`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};
export const archiveInterviewQuestions = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/archive-active-interview-question-by-id`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};
export const TotalShareInterviewCount = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/total-share-interview-question`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const appliedJobs = (data) => {

    const url = `${process.env.REACT_APP_BASE_URL}/admin/all-candidate-jobs-applied?pageNumber=${data.page}&KEYWORD=${data.KEYWORD}&STATUS=${data.STATUS}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const getcandidateJobs = (model) => {

    const url = `${process.env.REACT_APP_BASE_URL}/admin/all-candidate-applied-by-jobs?JOB_ID=${model.JOB_ID}&page=${model.page}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const statusList = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/job-status-list`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const EventHiringLists = (data) => {
    const url = `${process.env.REACT_APP_BASE_URL}/admin-future-event-hiring-list?page=${data.page}`;
    return axios.put(url, data, getAuthHeader()).then((res) => {
        return res.data;
    })
};
export const addinterviewQuestionbyAdmin = (data) => {
    const url = `${process.env.REACT_APP_BASE_URL}/interview-question-by-admin`
    return axios.post(url, data, getAuthHeader()).then((res) => {
        return res.data
    })
}
export const addShareInterviewQuestionbyAdmin = (data) => {
    const url = `${process.env.REACT_APP_BASE_URL}/interview-sharequestion-by-admin`
    return axios.post(url, data, getAuthHeader()).then((res) => {
        return res.data
    })
}

export const getCountryList = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/country-list`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};


export const getStateList = (page) => {
    const url = `${process.env.REACT_APP_BASE_URL}/cp-state-list?page=${page}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const getStateLists = (COUNTRY_ID) => {
    const url = `${process.env.REACT_APP_BASE_URL}/state-list?COUNTRY_ID=${COUNTRY_ID}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};
export const getGovtState = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/state-list?COUNTRY_ID=${102}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};
export const getCityList = (page) => {
    const url = `${process.env.REACT_APP_BASE_URL}/cp-city-list?page=${page}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};
export const getCouponList = (page) => {
    const url = `${process.env.REACT_APP_BASE_URL}/coupon-list?page=${page}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};
export const getCouponprefill = (COUPON_ID) => {
    const url = `${process.env.REACT_APP_BASE_URL}/coupon-data?COUPON_ID=${COUPON_ID}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};
export const getGovtprefill = (GOVT_JOB_ID) => {
    const url = `${process.env.REACT_APP_BASE_URL}/govt-job-byid?GOVT_JOB_ID=${GOVT_JOB_ID}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};
export const getGovtResultprefill = (GOVT_RESULT_ID) => {
    const url = `${process.env.REACT_APP_BASE_URL}/govt-result-byId?GOVT_RESULT_ID=${GOVT_RESULT_ID}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};
export const updatePremiumCity = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/mark-premium-city`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const updateCouponsStatus = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/update-coupon-status`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};
export const editDiscountCoupon = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/update-coupon`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};
export const editGovtJob = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/update-goverment-job`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};
export const editGovtResult = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/update-goverment-result`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};
export const addCity = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/create-city`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};
export const addgovtJobs = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/create-goverment-job`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};
export const addgovtresult = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/create-goverment-result`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};
export const addDiscountCoupon = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/create-coupon`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};
export const addLocality = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/create-locality`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};


export const getLoacalityList = (page) => {
    const url = `${process.env.REACT_APP_BASE_URL}/cp-localities-list?page=${page}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const getPremiumCity = (page) => {
    const url = `${process.env.REACT_APP_BASE_URL}/cp-premium-city?page=${page}`
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;

    })
}

export const addBanner = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/create-front-banner`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};
export const addScriptBanner = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/create-adscript-banner`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};
export const uploadBanner = (file) => {
    const url = `${process.env.REACT_APP_BASE_URL}/upload-page-header`;
    return axios.post(url, file, getAuthHeader()).then((res) => {
        return res.data
    })
};

export const getBannerList = (data) => {
    const url = `${process.env.REACT_APP_BASE_URL}/front-banner-list`;
    return axios.put(url, data, getAuthHeader()).then((res) => {
        return res.data;
    })
};
export const getBannerAnalytics = (id) => {
    const url = `${process.env.REACT_APP_BASE_URL}/banner-analytics-list?BANNER_ID=${id}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const bannerById = (id) => {
    const url = `${process.env.REACT_APP_BASE_URL}/front-banner-By-Id/?BANNER_ID=${id}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const updateBanner = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/update-front-banner`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })

}

export const archiveBannerById = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/archive-banner-status-by-id`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const getLoanApplicationList = (page) => {
    const url = `${process.env.REACT_APP_BASE_URL}/loan-applications/${page}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}
export const getAddAffiliate = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/create-affliate`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const AffiliateLists = (page) => {
    const url = `${process.env.REACT_APP_BASE_URL}/affliate-list?page=${page = 1}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data
    })
}

export const AffiliateLastId = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/affliate-last-id`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}


export const affiliateId = (ID) => {
    const url = `${process.env.REACT_APP_BASE_URL}/affliate-by-id?ID=${ID}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const UpdateAffiliate = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/update-affliate`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data

    })

}


export const archiveAffilation = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/update-affliate-status`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};


export const affiliateAnalytics = (ID) => {
    const url = `${process.env.REACT_APP_BASE_URL}/affliate-analytics-list?ID=${ID}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data
    })
}


