import React, { useEffect, useState } from 'react'
import EditComponent from '../../../component/campus/college/EditCollegeDetail'
import { useNavigate, useParams } from 'react-router-dom';
import { collegeProfile, editCollege } from '../../../actions/campus';
import Loader from '../../../component/common/Loader';
import constant from '../../../constant';
import Swal from 'sweetalert2';
const EditCollegeDetail = (props) => {
    const Navigate = useNavigate()

    const params = useParams();
    const [details, setDetails] = useState(null);
    const [showLoader, setShowLoader] = useState(true)
    useEffect(() => {
        collegeProfile(params.id).then((res) => {
            if (res.status) {
                setShowLoader(false)
                setDetails(res.result)

            }
            else {
                alert(res.error)
            }
        })
    }, [])

    const onSubmit = (model) => {
        model.COLLEGE_ID = params.id
        debugger
        editCollege(model).then((res) => {
            if (res.status) {
                Swal.fire({
                    title: 'Success',
                    text: 'College Details Updated',
                    icon: 'success',
                    timer: 3000,  // 3 seconds timer
                    timerProgressBar: true,  // optional: shows a progress bar
                    showConfirmButton: false  // hides the OK button
                }).then((result) => {
                    Navigate(constant.component.collegeDetail.url.replace(":id", params.id))

                });
            }
        })
    }
    return (
        <React.Fragment>
            {showLoader && <Loader />}
            {details && <EditComponent
                details={details}
                onSubmit={onSubmit}
            />}
        </React.Fragment>
    )
}

export default (EditCollegeDetail)