import React, { useEffect, useState } from 'react'
import { archiveAdminRoleList, getAdminRoleList } from '../../actions/AdminUserAction'
import RoleList from '../../component/Administration/adminUser/AdminRoleList'
import constant from '../../constant'

const AdminRoleList = () => {

    const [list, setList] = useState([])
    const [count, setCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [showLoader, setShowLoader] = useState(false)

    useEffect(() => {
        getAdminRoleLists(currentPage)
        document.title = constant.title.AdminRoleList
    }, [currentPage])

    const getAdminRoleLists = ((page) => {
        setShowLoader(true)
        getAdminRoleList({ pageNumber: page }).then((res) => {
            if (res.status) {
                setShowLoader(false)
                setList(res.result.list)
                setCount(res.result.count)
            }
        }).catch((err) => {
            alert(err)
        })
    })

    const updateStatus = (model) => {
        archiveAdminRoleList(model).then((res) => {

            if (res.status) {
                getAdminRoleLists(currentPage)
            }
            else {
                alert(res.error)
            }
        }).catch((err) => {
            alert(err)
        })
    }
    return (
        <React.Fragment>
            <RoleList
                list={list}
                setList={setList}
                updateStatus={updateStatus}
                getAdminRoleLists={(page => getAdminRoleLists(page))}
                count={count}
                setPage={setCurrentPage}
                showLoader={showLoader}
            />
        </React.Fragment>
    )
}

export default AdminRoleList;