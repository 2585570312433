import React, {  useEffect,  useState } from "react";
import { JobPostStatus, listJobPosts } from "../../actions/JobsAction";
import PostJobRequest from "../../component/jobs/PostJobsRequest";


const JobPostList = () => {
    const [postjobList, setpostjobList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1)
    const [count, setCount] = useState(0)
    const [showLoader, setShowLoader] = useState(true)
    const [KEYWORDS, setKeyword] = useState('')

    useEffect(() => {
        getpostjobList(currentPage);
    }, [currentPage]);




    const getpostjobList = (page) => {
        setShowLoader(true)

        listJobPosts({ pageNumber: page, KEYWORDS: KEYWORDS.trim().toLowerCase() , STATUS: 'ALL' })
            .then((res) => {
                console.log("ress///",res);
                if (res.status) {
                    setpostjobList(res.result.list);
                    setCount(res.result.count)
                    setShowLoader(false)

                }
            })
            .catch((err) => {
                alert(err);
            });
    };

    const updateStatus = (model) => {


        JobPostStatus(model)
            .then((res) => {
                if (res.status) {
                    getpostjobList(currentPage);
                }
            })
            .catch((err) => {
                alert(err);
            });
    }

    return (
        <React.Fragment>
            <PostJobRequest
                postjobList={postjobList}
                updateStatus={updateStatus}
                getpostjobList={(page => getpostjobList(page))}
                count={count} 
                setPage={setCurrentPage}
                setKeyword={setKeyword}
                setShowLoader={setShowLoader}
                showLoader={showLoader}

            />

        </React.Fragment>
    )
}

export default JobPostList;