import React from 'react'
import { useState } from "react";
import moment from 'moment';
import constant from '../../../constant';
import Pagination from 'react-js-pagination';
import { Link } from 'react-router-dom';
import Loader from '../../common/Loader';

function ArticleCategoryList(props) {

  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    props.setPage(pageNumber)
  };

  const updateStatus = (CATEGORY_ID, STATUS) => {
    const model = {
      CATEGORY_ID: CATEGORY_ID,
      STATUS: STATUS === 'U' ? 'A' : 'U'
    }
    props.setPage(currentPage)
    props.updateStatus(model)
  }

  const searchList = () => {
    props.getArticleCategorys(currentPage)
  }


  return (
    <React.Fragment>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0" style={{ fontSize: "18px" }}>Article Category List</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item"><a href="#"> Utilities</a></li>
                      <li className="breadcrumb-item"><a href="#">Article</a></li>
                      <li className="breadcrumb-item active">Article Category List</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <form className="row gy-12 gx-12 align-items-center">
                      <div className="col-sm-2">
                        <label className="visually-hidden" for="autoSizingInput">Name</label>
                        Search Article Category
                      </div>
                      <div className="col-sm-6">
                        <label className="visually-hidden" for="autoSizingInputGroup">Username</label>
                        <div className="input-group">
                          <input type="text" onChange={(e) => {
                            props.keyword(e.target.value)
                          }} className="form-control" id="autoSizingInputGroup" placeholder="Enter Article Category" />
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <button type="submit" onClick={(e) => {
                          e.preventDefault()
                          searchList()
                        }} className="btn btn-success waves-effect btn-label waves-light"><i className="bx bx-check-double label-icon"></i> Search</button>&nbsp;
                        {/* <button type="button" className="btn btn-outline-danger">Reset</button> */}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table align-middle table-nowrap table-hover">
                        {
                          props.showLoader &&
                          <Loader />
                        }
                        {!props.showLoader && <thead className="table-light">
                          <tr>
                            <th scope="col">S.No.</th>
                            <th scope="col">Category</th>
                            <th scope="col">Linked to Article</th>
                            <th scope="col">Added On</th>
                            <th scope="col">Status</th>
                            <th scope="col" style={{ width: "100px" }} >Action</th>
                          </tr>
                        </thead>}
                        <tbody>
                          {
                            props.list && props.list.length > 0 &&
                            props.list.map((ele, index, list) => {
                              return (
                                <tr>
                                  <td>{currentPage == 1 ? `${index + 1}` : `${index == 9 ? currentPage : currentPage - 1}` + `${index == 9 ? 0 : index + 1}`}</td>
                                  {/* <td>{ele.CATEGORY_ID}</td> */}
                                  <td>
                                    <p className="text-muted mb-0">{ele.CATEGORY}</p>
                                  </td>
                                  <td>0</td>
                                  <td>
                                    {moment(ele.CREATED_ON).format('DD.MM.YYYY on HH:MM A')}
                                  </td>
                                  <td>
                                    {
                                      ele.STATUS == 'U' ?
                                        <button type="button" className="btn btn-success btn-sm waves-effect waves-light">ACTIVE</button> : <button type="button" className="btn btn-danger btn-sm waves-effect waves-light">ARCHIVE</button>
                                    }
                                  </td>

                                  <td>
                                    <ul className="list-inline contact-links mb-0" style={{ fontSize: "20px" }}>
                                      <li className="list-inline-item px-2">
                                        <Link to={constant.component.editArticleCategory.url.replace(':id', ele.CATEGORY_ID)} title="Edit Article Category"><i className="bx bx-edit"></i></Link>
                                      </li>
                                      <li className="list-inline-item px-2">
                                        <a href="javascript: void(0);" onClick={() => updateStatus(ele.CATEGORY_ID, ele.STATUS)} style={{ color: 'red' }} title={ele.STATUS == 'U' ? "Archive Record" : "Unarchive Record"}>
                                          {
                                            ele.STATUS == 'U' ? <i className="bx bx-trash" ></i> : <i class="bx bx-log-in"></i>
                                          }
                                        </a>
                                      </li>
                                    </ul>
                                  </td>
                                </tr>
                              )
                            })
                          }
                          {
                            <>
                              {
                                props.list && props.list.length == 0 && props.showLoader == false &&
                                <td colSpan={10} className="text-center text-danger pt-3">No Record Found</td>
                              }
                            </>
                          }
                        </tbody>
                      </table>
                    </div>


                    <div className="row">
                      <div className="col-lg-12">
                        <ul className="pagination pagination-rounded justify-content-center mt-4">
                          <Pagination
                            activePage={currentPage}
                            itemsCountPerPage={10}
                            totalItemsCount={props.count}
                            pageRangeDisplayed={10}
                            onChange={handlePageChange}
                            itemClass="page-item"
                            linkClass="page-link"
                          />
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ArticleCategoryList