import React, { useState } from "react";
import constant from "../../../constant";
import moment from "moment";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import Loader from "../../common/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";

const GovtJobList = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  
  const location = useLocation();
  const Navigate = useNavigate();
  const [govtJobFullDetails, setGovtJobFullDetails] = useState({});
  const handlePageChange = (pageNumber) => {
    Navigate(location.pathname + "?p=" + pageNumber);
    setCurrentPage(pageNumber);
    props.getGovtJobsLists(pageNumber);
  };
  const updateStatus = (GOVT_JOB_ID, STATUS) => {
    const model = {
      GOVT_JOB_ID: GOVT_JOB_ID,
      STATUS: STATUS === "A" ? "U" : "A",
    };
    props.updateStatus(model);
    props.setPage(currentPage);
  };

  // const searchList = () => {
  //     props.getGovtJobList(currentPage)
  // }

  const qParams = queryString.parse(location.search);
  const { cpage } = props;

  return (
    <React.Fragment>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 " style={{ fontSize: "18px" }}>
                    Government Jobs List
                  </h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Utilities</a>
                      </li>
                      <li className="breadcrumb-item active">
                        Government Jobs List
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <form className="row gy-12 gx-12 align-items-center">
                                            <div className="col-sm-2">
                                                <label className="visually-hidden" for="autoSizingInput">Name</label>
                                                Search Government Job
                                            </div>
                                            <div className="col-sm-6">
                                                <label className="visually-hidden" for="autoSizingInputGroup">Username</label>
                                                <div className="input-group">
                                                    <input type="text" onChange={(e) => {
                                                        props.keyword(e.target.value)
                                                    }} className="form-control" id="autoSizingInputGroup" placeholder="Enter Government Job" />
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <button type="submit" onClick={(e) => {
                                                    e.preventDefault()
                                                    searchList()
                                                }} className="btn btn-success waves-effect btn-label waves-light"><i className="bx bx-check-double label-icon"></i> Search</button>&nbsp;
                                                <button type="button" className="btn btn-outline-danger">Reset</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div> */}
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table align-middle table-nowrap table-hover">
                        {props.showLoader && <Loader />}
                        {!props.showLoader && (
                          <thead className="table-light">
                            <tr>
                              <th scope="col">S.No.</th>
                              <th scope="col">Government Jobs</th>
                              <th scope="col">Apply Job URL</th>
                              <th scope="col">Added On</th>
                              <th scope="col">Status</th>
                              <th scope="col" style={{ width: "100px" }}>
                                Action
                              </th>
                            </tr>
                          </thead>
                        )}
                        <tbody>
                          {props.list &&
                            props.list.length > 0 &&
                            props.list.map((ele, index, list) => {
                              return (
                                <tr>
                                  <td>
                                    {currentPage == 1
                                      ? `${index + 1}`
                                      : `${
                                          index == 9
                                            ? currentPage
                                            : currentPage - 1
                                        }` + `${index == 9 ? 0 : index + 1}`}
                                  </td>
                                  {/* <td>{ele.GOVT_DEPT_ID}</td> */}
                                  <td>
                                    <h5 className=" mb-0">{ele.JOB_TITLE?.length<=30?ele.JOB_TITLE:(ele.JOB_TITLE?.slice(0,30))+'..'}</h5>
                                    <p>
                                    {ele.CATEGORY !== "CENTRAL"?  ( <>{ele.LOCATION},{ele.STATE} </>):'Central Government Job'  }
                                      <br />
                                      Referred by:<a href={ele.REFERENCE_BY}>
                                        {" "}
                                        {ele.REFERENCE_BY?.length<=30?ele.REFERENCE_BY:(ele.REFERENCE_BY?.slice(0,30))+'..'}
                                      </a>{" "}
                                    
                                    </p>
                                  </td>
                                  <td>
                                    <a target='_blank' href={ele.APPLY_JOB_URL}><button type="button"
                                                    className="btn btn-success">Apply Now</button></a>
                                  </td>
                                  <td>
                                    <a
                                      type="button"
                                      data-bs-toggle="modal"
                                      //data-bs-target="#staticBackdrop4"
                                      data-bs-target="#staticBackdrop4"
                                      className="btn btn-info waves-effect btn-label waves-light"
                                      // onClick={() => (govtJobFullDetails.CANDIDATE_ID)}>
                                      onClick={(e) => {
                                        setGovtJobFullDetails(ele);
                                      }}
                                    >
                                      <i className="bx bx-street-view label-icon "></i>{" "}
                                      View Details
                                    </a>
                                  </td>
                                  <td>
                                    {ele.STATUS == "U" ? (
                                      <button
                                        type="button"
                                        className="btn btn-success btn-sm waves-effect waves-light"
                                      >
                                        ACTIVE
                                      </button>
                                    ) : (
                                      <button
                                        type="button"
                                        className="btn btn-danger btn-sm waves-effect waves-light"
                                      >
                                        ARCHIVE
                                      </button>
                                    )}
                                  </td>
                                  <td>
                                    <ul className="list-inline font-size-20 contact-links mb-0">
                                      <li className="list-inline-item px-2">
                                        <Link
                                          to={constant.component.editgovtjobs.url
                                            .replace(":id", ele.GOVT_JOB_ID)
                                            .replace(":pageno", cpage)}
                                          title="Edit Govt Jobs"
                                        >
                                          <i className="bx bx-edit"></i>
                                        </Link>{" "}
                                      </li>
                                      <li className="list-inline-item px-2">
                                        <a
                                          href="javascript: void(0);"
                                          onClick={() =>
                                            updateStatus(
                                              ele.GOVT_JOB_ID,
                                              ele.STATUS
                                            )
                                          }
                                          style={{ color: "red" }}
                                          title={
                                            ele.STATUS == "U"
                                              ? "Archive Record"
                                              : "Unarchive Record"
                                          }
                                        >
                                          {ele.STATUS == "U" ? (
                                            <i className="bx bx-trash"></i>
                                          ) : (
                                            <i class="bx bx-log-in"></i>
                                          )}
                                        </a>
                                      </li>
                                    </ul>
                                  </td>
                                </tr>
                              );
                            })}
                          {
                            <>
                              {props.list &&
                                props.list.length == 0 &&
                                props.showLoader == false && (
                                  <td
                                    colSpan={10}
                                    className="text-center text-danger pt-3"
                                  >
                                    No Record Found
                                  </td>
                                )}
                            </>
                          }
                          <div
                            className="modal fade"
                            id="staticBackdrop4"
                            tabindex="-1"
                            role="dialog"
                            aria-labelledby="staticBackdropLabel"
                            aria-hidden="true"
                          >
                            <div
                              className="modal-dialog modal-dialog-centered modal-lg"
                              role="document"
                            >
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h5
                                    className="modal-title"
                                    id="staticBackdropLabel"
                                  >
                                    Job Details - {govtJobFullDetails.JOB_TITLE}
                                  </h5>
                                  <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                                <div className="modal-body">
                                  <p>
                                    {govtJobFullDetails && (
                                      <table className="table table-striped mb-0 job-details-table skills-data-table">
                                        <tbody>
                                        
                                        <tr>
                                            <td>Job Category</td>
                                            <td>
                                              {govtJobFullDetails.CATEGORY
                                                ? govtJobFullDetails.CATEGORY
                                                : "Not Available"}
                                            </td>
                                          </tr>
                                          
                                        <tr>
                                            <td>Department</td>
                                            <td>
                                              {govtJobFullDetails.DEPARTMENT
                                                ? govtJobFullDetails.DEPARTMENT
                                                : "Not Available"}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Last Date for Apply </td>
                                            <td>
                                            {moment(govtJobFullDetails.LAST_DATE).format(
                                        "DD.MM.YYYY hh:mma"
                                      )}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Salary</td>
                                            <td>
                                              {govtJobFullDetails.SALARY
                                                ? govtJobFullDetails.SALARY
                                                : "Not Available"}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Job Type</td>
                                            <td>
                                              {govtJobFullDetails.EMPLOYMENT_TYPE ? govtJobFullDetails.EMPLOYMENT_TYPE : "Not Available"}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Qualification</td>
                                            <td>
                                              {govtJobFullDetails.COURSE_STREAM ? govtJobFullDetails.COURSE_STREAM : "Not Available"}
                                            </td>
                                          </tr>
                                          
                                          
                                          <tr>
                                            <td>Department Logo</td>
                                            <td>
                                            <img
                                  style={{
                                    height: "75px",
                                    width: "75px",
                                    marginTop: "10px",
                                  }}
                                  src={`${
                                    govtJobFullDetails.LOGO &&
                                    govtJobFullDetails.LOGO.includes(
                                      "https://jobzshala.s3.ap-south-1.amazonaws.com/job/"
                                    )
                                      ? govtJobFullDetails.LOGO
                                      : "https://jobzshala.s3.ap-south-1.amazonaws.com/job/" +
                                      govtJobFullDetails.LOGO
                                  }`}
                                />
                                            </td>
                                          </tr>
                                          

                                          <tr>
                                            <td>Keywords</td>
                                            <td>
                                              {govtJobFullDetails.KEYWORDS ? govtJobFullDetails.KEYWORDS : "Not Available"}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Created On</td>
                                            <td>
                                            
                                      {moment(govtJobFullDetails.CREATED_ON).format(
                                        "DD.MM.YYYY hh:mma"
                                      )}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Description</td>
                                            <td>
                                            
                                      {govtJobFullDetails.DESCRIPTION}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    )}
                                  </p>
                                </div>
                                <div className="modal-footer">
                                  <button
                                    type="button"
                                    className="btn btn-light"
                                    data-bs-dismiss="modal"
                                  >
                                    Close
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </tbody>
                      </table>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <ul className="pagination pagination-rounded justify-content-center mt-4">
                          <Pagination
                            activePage={
                              qParams?.p
                                ? +qParams?.p
                                : qParams?.pageno
                                ? Number(qParams.pageno)
                                : currentPage
                            }
                            itemsCountPerPage={10}
                            totalItemsCount={props.count}
                            pageRangeDisplayed={10}
                            onChange={handlePageChange}
                            itemClass="page-item"
                            linkClass="page-link"
                          />
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default GovtJobList;
