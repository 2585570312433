import React from 'react'
import CityList from '../../../component/Utilities/CountryCityAndState/Cities'
import constant from '../../../constant'
import { useEffect, useState } from 'react'
import { getCityList, updatePremiumCity } from '../../../actions/utilitiesAction'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

export default function City() {

    const [List, setList] = useState([])
    const [count, setCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const location = useLocation()

    useEffect(() => {
        const qParams = queryString.parse(location.search)
        getCityLists(qParams?.p ? qParams?.p : currentPage)
        document.title = constant.title.Cities
    }, [currentPage])


    const getCityLists = (page) => {

        getCityList(page).then((res) => {
            // console.log(res, 'ssfd');
            if (res.status) {
                setList(res.result.list)
                setCount(res.result.count.total)
            }
        }).catch((err) => {
            alert(err)
        })
    }


    const updateStatus = (model) => {

        updatePremiumCity(model).then((res) => {
            if (res.status) {
                getCityLists(currentPage)
            }
            else {
                alert(res.error)
            }
        }).catch((err) => {
            alert(err)
        })
    }


    return (
        <React.Fragment>
            <CityList
                List={List}
                setList={setList}
                getCityLists={(page) => { getCityLists(page) }}
                count={count}
                setPage={setCurrentPage}
                updateStatus={updateStatus}


            />
        </React.Fragment>
    )
}
