import React from 'react'
import Pagination from 'react-js-pagination'
import { useState } from 'react';
import moment from 'moment/moment';
import queryString from 'query-string'
import { useLocation, useNavigate } from 'react-router-dom';

export default function Cities(props) {

  const [currentPage, setCurrentPage] = useState(1);
  const location = useLocation()
  const Navigate = useNavigate()

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    Navigate(location.pathname + '?p=' + pageNumber)
    props.setPage(pageNumber)
  };


  const updateStatus = (CITY_ID, IS_PREMIUM_CITY) => {
    const model = {
      CITY_ID: CITY_ID,
      IS_PREMIUM_CITY: IS_PREMIUM_CITY === 'Y' ? 'N' : 'Y'
    }
    props.setPage(currentPage)
    props.updateStatus(model)
  }


  const searchList = () => {
    setCurrentPage(1)
    props.getStateList(1)
  }

  const qParams = queryString.parse(location.search)


  return (


    <React.Fragment>
      <div className="main-content">

        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0" style={{ fontSize: '18px' }}>City List</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item"><a href="javascript: void(0);">Utilities</a></li>
                      <li className="breadcrumb-item active">City List</li>
                    </ol>
                  </div>

                </div>
              </div>
            </div>


            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <form className="row gy-12 gx-12 align-items-center">
                      <div className="col-sm-2">
                        <label className="visually-hidden" for="autoSizingInput">
                          Name
                        </label>
                        Search City
                      </div>
                      <div className="col-sm-6">
                        <label
                          className="visually-hidden"
                          for="autoSizingInputGroup"
                        >
                          Username
                        </label>

                        <div className="input-group">
                          <input type="text"
                            onKeyDown={
                              (e) => {
                                if (e.key == 'Enter') {
                                  e.preventDefault()
                                  searchList()
                                }
                              }
                            } onChange={(e) => {
                              props.setKeyword(e.target.value)
                            }} className="form-control" id="autoSizingInputGroup" placeholder="Enter City , Keywords etc" />
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <button
                          type="button"
                          className="btn btn-success waves-effect btn-label waves-light"
                          onClick={(e) => {
                            e.preventDefault()
                            searchList()
                          }}
                        >
                          <i className="bx bx-check-double label-icon"></i> Search
                        </button>
                        &nbsp;

                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table align-middle table-nowrap table-hover">
                        <thead className="table-light">
                          <tr>
                            <th scope='col'>S.No</th>
                            <th scope="col">City </th>
                            <th scope="col">State[Country]</th>
                            <th scope="col">Added On</th>
                            <th scope='col'>Action</th>

                          </tr>
                        </thead>
                        <tbody>

                          {props.List && props.List.length > 0 ?
                            props.List.map((ele, index, list) => {

                              return (


                                <tr>

                                  <td>{20 * currentPage + (index + 1) - 20}</td>
                                  {/* <td>{20 * currentPage + (index + 1) - 20}</td> */}

                                  <td>{ele.CITY} {ele.IS_PREMIUM_CITY == 'Y' && <i className="bx bx-star" title="Premium City" style={{ color: "#f46a6a", fontSize: '16px' }}></i>} </td>

                                  <td>
                                    <p className="text-muted mb-0">{ele.STATE + ' [' + ele.COUNTRY + ']'}</p>
                                  </td>

                                  <td>
                                    {moment(ele.CREATED_ON).format(
                                      "DD.MM.YYYY on HH:MM A"
                                    )}
                                  </td>

                                  <td>

                                    <li className="list-inline-item px-2">
                                      <a href="javascript: void(0)" onClick={() => updateStatus(ele.CITY_ID, ele.IS_PREMIUM_CITY)}

                                        title={ele.IS_PREMIUM_CITY == 'Y' ? "UnMark as Premium" : "Mark as Premium"}>
                                        {
                                          ele.IS_PREMIUM_CITY == 'Y' ? <i className="bx bx-bookmark-minus" style={{ color: 'red' }}></i> :
                                            <i class="bx bx-bookmark-plus" style={{ color: 'blue' }}></i>
                                        }
                                      </a>
                                    </li>
                                  </td>


                                </tr>

                              )
                            })
                            :
                            props.List && props.List.length == 0 &&
                            <td colSpan={10} className="text-center text-danger pt-3">No Cites Found</td>
                          }

                        </tbody>
                      </table>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="pagination pagination-rounded justify-content-center mt-4">
                          <Pagination
                            activePage={qParams?.p ? + qParams?.p :
                              currentPage}
                            itemsCountPerPage={20}
                            totalItemsCount={props.count}
                            pageRangeDisplayed={5}
                            onChange={handlePageChange}
                            itemClass="page-item"
                            linkClass="page-link"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
