import React from 'react'
import { appliedJobs, statusList } from '../../actions/utilitiesAction';
import AppliedJObse from '../../component/common/AppliedJobs'
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string'
const AppliedJobs = () => {

    const [list, setList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1)
    const [count, setCount] = useState(0)
    const [StatusList, setStatusList] = useState([])
    const [showLoader, setShowLoader] = useState(false)
    const location = useLocation()
     const [keyword, setKeyword] = useState('')
     const [data, setData] = useState('')

    useEffect(() => {
        const qParams = queryString.parse(location.search)
        getAppliedJobList(qParams?.p ? qParams?.p : currentPage, '')
    }, [currentPage])

    const getAppliedJobList = (page) => {
        appliedJobs({page: page , KEYWORD: keyword, STATUS: data}).then((res) => {
            if (res.status) {
                setList(res.result.list)
                setCount(res.result.count)
            }
        }).catch((err) => {
            alert(err)
        })

        statusList().then((res) => {
            if (res.status) {
                setStatusList(res.result.list)
            }
        }).catch((err) => {
            alert(err)
        })
    }

    // const data = Object.values(StatusList).forEach((val) => val.STATUS_NAME)

    // console.log(data, 'ttsss');

    return (
        <React.Fragment>

            <AppliedJObse
                list={list}
                getAppliedJobList={((page, keyword) => getAppliedJobList(page, keyword))}
                count={count}
                setPage={setCurrentPage}
                StatusList={StatusList}
                showLoader={showLoader}
                setKeyword = {setKeyword}
                setData = {setData}
            />
        </React.Fragment>
    )
}
export default AppliedJobs;