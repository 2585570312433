import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Pic from "../../assets/images/profilePic/secondary.jfif"
import constant from '../../constant';
import Swal from 'sweetalert2'
import { NavItem } from 'reactstrap';
import axios from 'axios';
import { capitalize } from '../../utils';

export default function CandidatesProfile(props) {


  const diff_year_month_day = (dt1, dt2) => {
    var time = (dt2.getTime() - dt1.getTime()) / 1000;
    var year = Math.abs(Math.round((time / (60 * 60 * 24)) / 365.25));
    var month = Math.abs(Math.round(time / (60 * 60 * 24 * 7 * 4)));
    var days = Math.abs(Math.round(time / (3600 * 24)));

    let years = Math.floor(month / 12)
    let months = Math.floor(month % 12)
    return years + " " + "Years" + " " + months + " " + " Months"

  }
  const GetResume = () => {
    Swal.fire({
      icon: "error",
      text: "Resume Not Available",
      timer: 2000,
      showCancelButton: false,
      showConfirmButton: false,
    });


  }

  const { PersonalDetails, Profile, Rejected, Resume,
    Project, WorkSample, education, CareerProfile, itskills, Skillslist,
    employement, JobApply, resumeheadline, onlineprofile, profilesummary, certifications } = props


  const saveFile = (filename, model) => {
    const { RESUME_FILE, CANDIDATE_ID } = model
    axios.get(`${process.env.REACT_APP_BASE_URL}/candidate-resume/${model}/${filename}`).then((res) => {
    }).catch(err => {
    })
  }

  return (
    <React.Fragment>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Profile Information</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript:void(0);">
                          {/* // href="https://cp.jobzshala.com/candiates-list"> */}
                          <Link to={constant.component.totalcandidates.url}>
                            Candiates</Link>
                        </a>
                      </li>
                      <li className="breadcrumb-item active">Profile Information</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* end page title */}
            {/* DOWNLOAD RESUME */}
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">GO TO</h4>
                    <div className="d-flex flex-wrap gap-2">
                      {/* <button type="button" onclick="javascript:document.location.href='#summary'" class="btn btn-light btn-sm waves-effect waves-light"><i class="bx bx-chevrons-right"></i> SUMMARY</button>
											<button type="button" onclick="javascript:document.location.href='#resume'"  class="btn btn-light btn-sm waves-effect waves-light"><i class="bx bx-chevrons-right"></i> RESUME</button> */}
                      <a
                        type="button"
                        href='#headline'
                        className="btn btn-light btn-sm waves-effect waves-light"
                      >
                        <i className="bx bx-chevrons-right" /> RESUME HEADLINE
                      </a>
                      <a
                        type="button"
                        href='#keyskills'
                        className="btn btn-light btn-sm waves-effect waves-light"
                      >
                        <i className="bx bx-chevrons-right" /> KEY SKILLS
                      </a>

                      <a
                        type="button"
                        href='#employment'
                        className="btn btn-light btn-sm waves-effect waves-light"
                      >
                        <i className="bx bx-chevrons-right" /> EMPLOYMENT
                      </a>
                      <a
                        type="button"
                        href='#education'
                        className="btn btn-light btn-sm waves-effect waves-light"
                      >
                        <i className="bx bx-chevrons-right" /> EDUCATION
                      </a>
                      <a
                        type="button"
                        href='#itskills'
                        className="btn btn-light btn-sm waves-effect waves-light"
                      >
                        <i className="bx bx-chevrons-right" /> IT SKILLS
                      </a>
                      <a
                        type="button"
                        href='#projects'
                        className="btn btn-light btn-sm waves-effect waves-light"
                      >
                        <i className="bx bx-chevrons-right" /> PROJECTS
                      </a>
                      <a
                        type="button"
                        href='#onlineprofile'
                        className="btn btn-light btn-sm waves-effect waves-light"
                      >
                        <i className="bx bx-chevrons-right" /> ONLINE PROFILE
                      </a>
                      <a
                        type="button"
                        href='#worksample'
                        className="btn btn-light btn-sm waves-effect waves-light"
                      >
                        <i className="bx bx-chevrons-right" /> WORK SAMPLE
                      </a>
                      <a
                        type="button"
                        href='#profilesummary'
                        className="btn btn-light btn-sm waves-effect waves-light"
                      >
                        <i className="bx bx-chevrons-right" /> PROFILE SUMMEAY
                      </a>
                      <a
                        type="button"
                        href='#certification'
                        className="btn btn-light btn-sm waves-effect waves-light"
                      >
                        <i className="bx bx-chevrons-right" /> CERTIFICATION
                      </a>
                      <a
                        type="button"
                        href='#careerprofile'
                        className="btn btn-light btn-sm waves-effect waves-light"
                      >
                        <i className="bx bx-chevrons-right" /> CAREER PROFILE
                      </a>
                      <a
                        type="button"
                        href='#personaldetails'
                        className="btn btn-light btn-sm waves-effect waves-light"
                      >
                        <i className="bx bx-chevrons-right" /> PERSONAL DETAILS
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* end col */}
            </div>
            {/* DOWNLOAD RESUME END */}
            {/* BASIC INFO */}
            <div className="row" id="summary">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-7">
                        <div className="d-flex">
                          <div className="flex-shrink-0 me-3">
                            {Profile != null && Profile.PROFILE_IMAGE ?
                              <img src={`${Profile.PROFILE_IMAGE_PATH}/${Profile.PROFILE_IMAGE}`} className="avatar-md rounded-circle img-thumbnail" /> : <img src={Pic} className="avatar-md rounded-circle img-thumbnail" />}
                            {/* : <img src={Pic} /> */}

                            {/* <img
                        src="assets/images/users/avatar-1.jpg"
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      /> */}
                          </div>
                          <div className="flex-grow-1 align-self-center">
                            <div className="text-muted">
                              <h3 className="mb-1">{capitalize(Profile.CANDIDATE_NAME ? Profile.CANDIDATE_NAME : 'NA')}</h3>
                              <p className="mb-0">
                                <h6>
                                  {Profile.CurrentEmp ? Profile.CurrentEmp.CURRENT_DESIGNATION + ' ' + 'at' : ''} {Profile.CurrentEmp ? Profile.CurrentEmp.CURRENT_COMPANY + ' ' + '' : 'NA'}
                                </h6>
                              </p>
                              <table cellSpacing="2px" cellPadding="2px">
                                <tbody>
                                  <tr>
                                    <td colSpan={10} style={{ height: 10 }} />
                                  </tr>
                                  <tr>
                                    <td>
                                      <i className="bx bx bxs-map" style={{ color: "#f03818" }} />&nbsp;
                                      {Profile.CURRENT_LOCATION ? Profile.CURRENT_LOCATION + ' ' + 'India' : 'NA'}
                                    </td>
                                    <td>&nbsp;</td>
                                    <td >
                                      <i className="bx bxs-envelope" style={{ color: "#fae60f" }} />&nbsp;{Profile.EMAIL_ID ? Profile.EMAIL_ID : 'NA'}{" "}
                                      <i
                                        style={{ fontSize: 15 }}
                                        className={Profile.IS_EMAIL_VERIFIED === 'Y' ? "bx bx-check-circle text-success" : "bx bx-x-circle text-danger"}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <i className="bx bx-briefcase" style={{ color: "#eb8613" }} />{" "}
                                      <span>
                                        {Profile.TOTAL_EXP_YEAR ? Profile.TOTAL_EXP_YEAR + ' ' + 'Years' : 'NA'} {Profile.TOTAL_EXP_MONTH ? Profile.TOTAL_EXP_MONTH + ' ' + 'Months' : ''}
                                      </span>
                                    </td>
                                    <td>&nbsp;</td>
                                    <td>
                                      <i className="bx bxs-phone" style={{ color: "#299c0c" }} />&nbsp;{Profile.PHONENO ? Profile.PHONENO : 'NA'}{" "}
                                      <i
                                        style={{ fontSize: 15 }}
                                        className={Profile.IS_PHONE_VERIFIED === 'Y' ? "bx bx-check-circle text-success" : "bx bx-x-circle text-danger"}
                                        //className="bx bx-check-circle"
                                        title="Verified"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <i className="bx bxs-wallet" style={{ color: "#1a93d9" }} />&nbsp;
                                      <span>{Profile.CurrentEmp ? Profile.CurrentEmp.CURRENT_ANNUAL_SALARY_LACS / 100000 + ' ' + 'Lacs' : 0 + ' ' + 'Lacs'} {Profile.CurrentEmp ? Profile.CurrentEmp.CURRENT_ANNUAL_SALARY_THOUSANDS / 1000 + ' ' + 'Thousands' : 0 + ' ' + 'Thousand'}</span>
                                    </td>
                                    <td>&nbsp;</td>
                                    <td>
                                      <i className="bx bxs-microphone" />
                                      {PersonalDetails != null && PersonalDetails.Language.length > 0 ? PersonalDetails.Language.map((ele) => {
                                        return <React.Fragment>
                                          <span>&nbsp;{ele.LANGUAGE}&nbsp;
                                          </span>
                                        </React.Fragment>
                                      }) : "NA"
                                      }
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 align-self-center">
                        <div className="text-lg-center mt-4 mt-lg-0">
                          {/* <div className="row">
                    {JobApply!=undefined && JobApply.length>0 ? 
                   JobApply.map((ele)=>{
                 return(<React.Fragment>
                      <div className="col-4">
                        <div>
                          <p className="text-muted text-truncate mb-2">
                            Job Applied
                          </p>
                          <h5 className="mb-0">{JobApply.count}</h5>
                        </div>
                      </div>
                      <div className="col-4">
                        <div>
                          <p className="text-muted text-truncate mb-2">
                            Shortlisted
                          </p>
                          <h5 className="mb-0">0</h5>
                        </div>
                      </div>
                      <div className="col-4">
                        <div>
                          <p className="text-muted text-truncate mb-2">
                            Rejected
                          </p>
                          <h5 className="mb-0">0</h5>
                        </div>
                      </div>
                      </React.Fragment>)
              }) :""   
              }
                    </div> */}
                          {/* <div className="row">
                      <div className="col-4">
                       <i class="fa fa-trophy" aria-hidden="true"></i>&nbsp;
                       <span className='nubtext'>
                       <small>{Profile.reward_points}</small> Rewards</span>
                      </div>
                    </div> */}
                          <div className="row">
                            <div className="col-12">
                              <div>
                                <p
                                  className="text-muted text-left mb-2"
                                  style={{
                                    textAlign: "left",
                                    marginTop: 17,
                                    color: "#556ee6 !important"
                                  }}
                                >
                                  {/* Profile Strength (Excellent) - 67% */}
                                  {/* <progress max="100" value={Profile.ProfileWeigh} style={{ width: '100%' }} ><span>{Profile.ProfileWeigh}</span></progress> */}
                                  {/* {Profile.ProfileWeigh} */}
                                </p><b></b>
                                {Profile.ProfileWeigh > 60 ? <p className='pt-2 pb-0'>Profile Strength ({Profile.ProfileWeigh}% Completed)</p> : <p className='pt-2 pb-0'>Profile Strength <span style={{ fontWeight: "500" }}>  (Critical Fields Missing)  </span> <div style={{ display: "contents" }}>{Profile.ProfileWeigh}% </div>  </p>}
                                <div class="progress profile">
                                  <progress max="100" value={Profile.ProfileWeigh} style={{ width: '100%' }} ><span>{Profile.ProfileWeigh}</span></progress>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-lg-2 d-none d-lg-block">
                  <div className="clearfix mt-4 mt-lg-0">
                    <div className="dropdown float-end">
                      <button
                        className="btn btn-primary"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i className="bx bxs-cog align-middle me-1" /> Action
                      </button>
                      <div className="dropdown-menu dropdown-menu-end">
                        <a className="dropdown-item" href="#">
                          <i className="mdi mdi-email" /> Send Mail
                        </a>
                        <a className="dropdown-item" href="#">
                          <i className="mdi mdi-note-plus-outline" /> Add Note
                        </a>
                        <a className="dropdown-item" href="#">
                          <i className="mdi mdi-trash-can" /> Archive Profile
                        </a>
                        <a className="dropdown-item" href="#" id="downloadResume" download >
                          <i className="mdi mdi-download" /> Download Resume
                        </a>
                      </div>
                    </div>
                  </div>
                </div> */}
                    </div>
                    {/* end row */}
                  </div>
                </div>
              </div>
            </div>
            {/* SEARCH */}
            {/* DOWNLOAD RESUME */}
            <div className="row" id="resume">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    {/* <h4 className="card-title">Resume Attachment</h4>
              <p className="card-title-desc">
                Resume is the most important document recruiters look for.
                Recruiters generally do not look at profiles without resumes.
                Here is attached resume by Candidate.
              </p> */}
                    <div className="d-flex flex-wrap gap-2">
                      {/* Header */}
                      {Resume.RESUME_FILE == null ?
                        <a className="btn btn-warning waves-effect btn-label waves-light" onClick={(GetResume)}>
                          <span style={{ color: "#808040" }}>
                            Download Resume{" "}
                            <i style={{ fontSize: 20 }}
                              className="bx bx-cloud-download label-icon"></i>{" "}
                          </span>
                        </a> :
                        <a download href={`${process.env.REACT_APP_BASE_URL}/candidate-resume/${Resume.CANDIDATE_ID}/${Resume.RESUME_FILE}`}
                          className="btn btn-warning waves-effect btn-label waves-light">
                          <i
                            style={{ fontSize: 20 }}
                            className="bx bx-cloud-download label-icon"
                          />{""}
                          Download Resume
                        </a>}
                    </div>
                    <p
                      style={{ marginTop: 10 }} className="text-muted mb-0">
                      <span> {Profile.CANDIDATE_NAME} </span><span style={{ color: "#ff0000" }}><b>{Profile.RESUME_FILE ? Profile.RESUME_FILE : 'Resume Not Available'}</b></span>- Uploaded on - <b>{moment(Profile.RESUME_UPDATE_TIME).format('MMM Do YY')}</b>
                    </p>
                  </div>
                </div>
              </div>
              {/* end col */}
            </div>
            {/* DOWNLOAD RESUME END */}
            {/* RESUME HEADLINE */}
            <div className="row" id="headline">

              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Resume Headline </h4>
                    <p className="card-title-desc">{resumeheadline.RESUME_HEADLINE ? resumeheadline.RESUME_HEADLINE : 'Not Available'}
                    </p>
                  </div>
                </div>
              </div>
              {/* end col */}
            </div>
            {/* RESUME HEADLINE END */}
            {/* KEY SKILLS */}
            <div className="row" id="keyskills">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Key Skills </h4>

                    <p className="card-title-desc">

                      {itskills != undefined && itskills.length > 0 ?
                        itskills.map((ele) => {
                          return (<React.Fragment>
                            <a
                              type="button"
                              href='#employment'
                              className="btn btn-light btn-sm waves-effect waves-light"
                            >
                              <i className="bx bx-chevrons-right" /> {ele.SKILL}
                            </a>&nbsp;
                          </React.Fragment>)
                        }) : "Not Available "
                      }</p>
                  </div>
                </div>
              </div>
              {/* end col */}
            </div>
            {/* KEY SKILLS END */}
            {/* EMLOYEMENT */}
            <div className="row" id="employment">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title mb-5">Employment</h4>
                    <div className="">
                      {employement != undefined && employement.length > 0 ?
                        employement.map((ele) => {
                          return (<React.Fragment>
                            <ul className="verti-timeline list-unstyled">

                              <li className="event-list">
                                <div className="event-timeline-dot">
                                  <i className="bx bx-right-arrow-circle" />
                                </div>
                                <div className="d-flex">
                                  <div className="flex-shrink-0 me-3">
                                    <i className="bx bx-badge-check h2 text-primary" />
                                  </div>
                                  <div className="flex-grow-1">
                                    <div>
                                      <h5>{ele.CURRENT_DESIGNATION}</h5>
                                      <p
                                        className="text-muted"
                                        style={{ marginBottom: "5px !important" }}
                                      >
                                        {ele.CURRENT_COMPANY}
                                      </p>
                                      <p style={{ marginBottom: "5px !important" }}>
                                        {ele.JOINING_DATE_MONTH_NAME} {ele.JOINING_DATE_YEAR} to {ele.IS_THIS_YOUR_CURRENT_COMPANY == 'Y' ? `Present ( ${diff_year_month_day(new Date(), new Date(`${ele.JOINING_DATE_YEAR}-${ele.JOINING_DATE_MONTH}-31`))})` : `${ele.WORKING_TILL_DATE_MONTH_NAME} ${ele.WORKING_TILL_DATE_YEAR} ( ${diff_year_month_day(new Date(`${ele.WORKING_TILL_DATE_YEAR}-${ele.WORKING_TILL_DATE_MONTH}-01`), new Date(`${ele.JOINING_DATE_YEAR}-${ele.JOINING_DATE_MONTH}-31`))})`}
                                      </p>
                                      <p style={{ marginBottom: "5px !important" }}>
                                        {ele.SKILL_USED}
                                      </p>
                                      <p style={{ marginBottom: "5px !important" }}>
                                        {ele.JOB_PROFILE}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </React.Fragment>)
                        }) : " Not Available "
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* EMPLOYMENT END */}
            {/* EDUCATION */}
            <div className="row" id="education">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Education </h4>
                    {education != undefined && education.length > 0 ?
                      education.map((ele) => {

                        //console.log(ele.EDUCATION_QUALIFICATION[0].COURSE_STREAM,"ele");
                        return (<React.Fragment>
                          <div>
                            <h5 className="font-size-14 mb-1">
                              {/* M.Tech - Software Systems - Data Analytics */}
                              {/* {education.EDUCATION_QUALIFICATION.COURSE_STREAM} */}
                              {ele.EDUCATION_QUALIFICATION[0].COURSE_STREAM}
                            </h5>
                            <p
                              className="text-muted"
                              style={{ marginBottom: "5px !important" }}
                            >
                              {ele.UNIVERSITY_INSTITUTE}
                            </p>
                            <p style={{ marginBottom: "5px !important" }}>

                              {ele.PASSING_OUT_YEAR}&nbsp;({ele.COURSE_TYPE})
                            </p>
                            <hr className="mt-3" />
                          </div>
                        </React.Fragment>)
                      }) : " Not Available "
                    }
                  </div>
                </div>
              </div>
              {/* end col */}
            </div>
            {/* EDUCATION END */}
            {/* IT SKILLS */}
            <div className="row" id="itskills">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">IT Skills</h4>
                    <div className="table-responsive">

                      <table className="table mb-0">
                        <thead>
                          <tr>
                            <th></th>
                            <th>Skills</th>
                            <th>Version</th>
                            <th>Last Time Used</th>
                            <th>Experience</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Skillslist != undefined && itskills.length > 0 ?
                            Skillslist.map((ele) => {
                              return (<React.Fragment>
                                <tr>
                                  <td scope="row">*</td>
                                  <td>{ele.IT_SKILLS ? ele.IT_SKILLS : 'NA'}</td>
                                  <td>{ele.SOFTWARE_VERSION ? ele.SOFTWARE_VERSION : 'NA'}</td>
                                  <td>{ele.LAST_USED ? ele.LAST_USED : 'NA'} </td>
                                  <td>{ele.EXP_YEAR ? ele.EXP_YEAR : 'NA'}</td>
                                </tr>
                              </React.Fragment>)
                            }) : " Not Available "
                          }
                          {/* <tr>
                      <th scope="row">2</th>
                      <td>Complete Open Source Core &amp; Complex Code</td>
                      <td>Latest</td>
                      <td>2022</td>
                      <td>14 Years 0 Month</td>
                    </tr> */}
                        </tbody>
                      </table>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* IT SKILLS */}
            {/* PROJECTS */}
            <div className="row" id="projects">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Projects </h4>
                    {Project != undefined && Project.length > 0 ?
                      Project.map((ele) => {
                        return (<React.Fragment>
                          <div>
                            <h5 className="font-size-14 mb-1">{ele.PROJECT_TITLE}</h5>
                            <p
                              className="text-muted"
                              style={{ marginBottom: "5px !important" }}
                            >
                              {/* Value Innovation Labs (Offsite) */}
                              {ele.CURRENT_COMPANY}&nbsp;(&nbsp;{ele.PROJECT_ROLE}&nbsp;)
                            </p>
                            <p style={{ marginBottom: "5px !important" }}>
                              {/* May 2022 to Present (Full Time) */}
                              {ele.WORKED_FROM_MONTH_NAME} {ele.WORKED_FROM_YEAR} to {ele.IS_THIS_YOUR_CURRENT_COMPANY == 'Y' ? `Present ( ${diff_year_month_day(new Date(), new Date(`${ele.WORKED_FROM_YEAR}-${ele.WORKED_FROM_MONTH_NAME}-31`))})` : `${ele.WORKED_TILL_MONTH_NAME} ${ele.WORKED_TILL_YEAR} ( ${diff_year_month_day(new Date(`${ele.WORKED_TILL_YEAR}-${ele.WORKED_TILL_MONTH_NAME}-01`), new Date(`${ele.WORKED_FROM_YEAR}-${ele.WORKED_FROM_MONTH_NAME}-31`))})`}
                            </p>
                            <p style={{ marginBottom: "5px !important" }}>
                              {ele.ROLE_DETAILS}
                            </p>
                          </div>
                          <hr className="mt-3" />
                        </React.Fragment>)
                      }) : " Not Available "
                    }

                  </div>
                </div>
              </div>
            </div>
            <div className="row" id="onlineprofile">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Online Profile </h4>
                    {onlineprofile != undefined && onlineprofile.length > 0 ?
                      onlineprofile.map((ele) => {
                        return (<React.Fragment>
                          <div>
                            <h5 className="font-size-14 mb-1">{ele.SOCIAL_PROFILE}</h5>
                            <p
                              className="text-muted"
                              style={{ marginBottom: "5px !important" }}
                            >
                              {ele.URL}
                            </p>
                            <p style={{ marginBottom: "5px !important" }}>{ele.PROFILE_DESCRITION}</p>
                          </div>
                          <hr className="mt-3" />
                        </React.Fragment>)
                      }) : " Not Available "
                    }
                    {/* <div>
                <h5 className="font-size-14 mb-1">LinkedIn</h5>
                <p
                  className="text-muted"
                  style={{ marginBottom: "5px !important" }}
                >
                  https://www.linkedin.com/in/supriya-pathak/
                </p>
                <p style={{ marginBottom: "5px !important" }}>LinkedIn</p>
              </div>
              <hr className="mt-3" /> */}
                  </div>
                </div>
              </div>
              {/* end col */}
            </div>
            {/* Online Profile END */}
            {/* Work Sample */}
            <div className="row" id="worksample">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Work Sample </h4>
                    {WorkSample != undefined && WorkSample.length > 0 ?
                      WorkSample.map((ele) => {
                        return (<React.Fragment>
                          <div>
                            <h5 className="font-size-14 mb-1">
                              {/* BSE Ebix - B2B Insurance Portal */}
                              {ele.WORK_SAMPLE_TITLE}
                            </h5>
                            <p
                              className="text-muted"
                              style={{ marginBottom: "5px !important" }}
                            >
                              {/* https://portal.bseebix.com/Login.aspx */}
                              {ele.URL}
                            </p>
                            <p style={{ marginBottom: "5px !important" }}>
                              <strong>Duration:</strong> &nbsp;
                              {/* Apr 2019 - Jul 2021 */}
                              {ele.DURATION_FROM_MONTH_NAME} {ele.DURATION_FROM_YEAR} to {ele.IS_THIS_YOUR_CURRENT_COMPANY == 'Y' ? `Present ( ${diff_year_month_day(new Date(), new Date(`${ele.DURATION_FROM_YEAR}-${ele.DURATION_FROM_MONTH_NAME}-31`))})` : `${ele.DURATION_TO_MONTH_NAME} ${ele.DURATION_TO_YEAR} ( ${diff_year_month_day(new Date(`${ele.DURATION_TO_YEAR}-${ele.DURATION_TO_MONTH_NAME}-01`), new Date(`${ele.DURATION_FROM_YEAR}-${ele.DURATION_FROM_MONTH_NAME}-31`))})`}
                            </p>
                            <p style={{ marginBottom: "5px !important" }}>
                              {/* BSE Ebix Insurance Broking Private Limited ( BSE Ebix) is a
                  joint venture between India's leading stock exchange BSE and
                  one of leading insurance solution provider Ebix, Inc. (NASDAQ:
                  EBIX). Our Vision is to be the preferred Insurance Broking for
                  our Partner, Customers and insurance Company. BSE Ebix
                  believes that insurance plays an important role in protecting
                  organisations and individual aspirations. The goal is to
                  transform end-to-end sales and processing of Insurance in
                  India. */}
                              {ele.WORK_DETAILS}
                            </p>
                          </div>
                          <hr className="mt-3" />
                        </React.Fragment>)
                      }) : " Not Available "
                    }
                  </div>
                </div>
              </div>
              {/* end col */}
            </div>
            {/* Work Sample END */}
            {/* PROFILE SUMMARY */}
            <div className="row" id="profilesummary">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">PROFILE SUMMARY </h4>
                    <p className="card-title-desc">{profilesummary.PROFILE_SUMMARY ? profilesummary.PROFILE_SUMMARY : 'Not Available'}
                    </p>
                  </div>
                </div>
              </div>
              {/* end col */}
            </div>
            {/* Presentation END */}
            {/* Certification */}
            <div className="row" id="certification">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Certification </h4>
                    {certifications != undefined && certifications.length > 0 ?
                      certifications.map((ele) => {
                        return (<React.Fragment>
                          <div>
                            <h5 className="font-size-14 mb-1">
                              {ele.CERTIFICATION_TITLE}
                            </h5>
                            <p
                              className="text-muted"
                              style={{ marginBottom: "5px !important" }}
                            >
                              {/* Google Inc */}
                              {ele.DESCRIPTION}
                            </p>
                            <p style={{ marginBottom: "5px !important" }}>
                              {/* Valid from Jan '20 */}Valid from &nbsp;
                              {ele.VALID_FROM_MONTH_NAME ? ele.VALID_FROM_MONTH_NAME : 'NA'}&nbsp; {ele.VALID_FROM_YEAR ? ele.VALID_FROM_YEAR + ' ' + 'To' : 'NA'}&nbsp;
                              {ele.VALID_TO_MONTH_NAME}&nbsp; {ele.VALID_TO_YEAR ? ele.VALID_TO_YEAR : 'Present'}
                            </p>
                          </div>
                          <hr className="mt-3" />
                        </React.Fragment>)
                      }) : " Not Available"
                    }
                  </div>
                </div>
              </div>
              {/* end col */}
            </div>
            {/* Certification END */}
            {/* Career Profile */}
            <div className="row" id="careerprofile">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Career Profile </h4>
                    <p />
                    <div className="row">
                      <div className="col-3">
                        <p
                          className="text-muted"
                          style={{ marginBottom: "5px !important" }}
                        >
                          Current Industry
                        </p>
                        <p className="">{CareerProfile.INDUSTRY ? CareerProfile.INDUSTRY : 'NA'}</p>
                      </div>
                      <div className="col-3">
                        <p
                          className="text-muted"
                          style={{ marginBottom: "5px !important" }}
                        >
                          Department
                        </p>
                        <p className="">{CareerProfile.CURRENT_DEPARTMENT ? CareerProfile.CURRENT_DEPARTMENT : 'NA'}</p>
                      </div>
                      <div className="col-3">
                        <p
                          className="text-muted"
                          style={{ marginBottom: "5px !important" }}
                        >
                          Role Category
                        </p>
                        <p className="">{CareerProfile.ROLE_CATEGORY_NAME ? CareerProfile.ROLE_CATEGORY_NAME : 'NA'}</p>
                      </div>
                      <div className="col-3">
                        <p
                          className="text-muted"
                          style={{ marginBottom: "5px !important" }}
                        >
                          Job Role
                        </p>
                        <p className="">{CareerProfile.ROLE_NAME ? CareerProfile.ROLE_NAME : 'NA'}</p>
                      </div>
                    </div>
                    <p />
                    <div className="row">
                      {/* <div className="col-3">
                  <p
                    className="text-muted"
                    style={{ marginBottom: "5px !important" }}
                  >
                    Availability to Join
                  </p>
                  <p className="">Join Immediately</p>
                </div> */}
                      {/* <div className="col-3">
                  <p
                    className="text-muted"
                    style={{ marginBottom: "5px !important" }}
                  >
                    Desired Job Type
                  </p>
                  <p className="">Permanent</p>
                </div> */}
                      {/* <div className="col-3">
                  <p
                    className="text-muted"
                    style={{ marginBottom: "5px !important" }}
                  >
                    Desired Employment Type
                  </p>
                  <p className="">Full Time</p>
                </div> */}
                      <div className="col-3">
                        <p
                          className="text-muted"
                          style={{ marginBottom: "5px !important" }}
                        >
                          Desired Shift
                        </p>
                        <p className="">{CareerProfile.PREFERRED_SHIFT === 'D' ? 'Day' : CareerProfile.PREFERRED_SHIFT === 'N' ? 'Night' : CareerProfile.PREFERRED_SHIFT === 'F' ? 'FLEXIBLE' : 'NA'}</p>
                      </div>
                    </div>
                    <p />
                    <div className="row">
                      {/* <div className="col-3">
                  <p
                    className="text-muted"
                    style={{ marginBottom: "5px !important" }}
                  >
                    Preferred Work Location
                  </p>
                  <p className="">Delhi / NCR, Noida, Bangalore/Bengaluru
                  </p>
                </div> */}
                      <div className="col-3">
                        <p
                          className="text-muted"
                          style={{ marginBottom: "5px !important" }}
                        >
                          Expected Salary
                        </p>
                        <p className="">
                          {CareerProfile.EXPECTED_SALARY_CURRENCY ? CareerProfile.EXPECTED_SALARY_CURRENCY : 'NA'} {CareerProfile.EXPECTED_SALARY_LACS ? CareerProfile.EXPECTED_SALARY_LACS + ' ' + 'Lacs' : ''} {CareerProfile.EXPECTED_SALARY_THOUSANDS ? CareerProfile.EXPECTED_SALARY_THOUSANDS + ' ' + 'Thousands' : ''}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* end col */}
            </div>
            {/* Career Profile END */}
            {/* Personal Details */}
            <div className="row" id="personaldetails">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Personal Details </h4>


                    <p />
                    <div className="row">
                      <div className="col-3">
                        <p
                          className="text-muted"
                          style={{ marginBottom: "5px !important" }}
                        >
                          Date of Birth
                        </p>
                        <p className="">
                          {moment(PersonalDetails != null && PersonalDetails.DOB ? PersonalDetails != null && PersonalDetails.DOB : 'NA').format("DD-MM-YYYY")}
                        </p>
                      </div>
                      <div className="col-3">
                        <p
                          className="text-muted"
                          style={{ marginBottom: "5px !important" }}
                        >
                          Permanent Address
                        </p>
                        <p className="">{PersonalDetails != null && PersonalDetails.PERMANENT_ADDRESS ? PersonalDetails != null && PersonalDetails.PERMANENT_ADDRESS : 'NA'}</p>
                      </div>
                      <div className="col-3">
                        <p
                          className="text-muted"
                          style={{ marginBottom: "5px !important" }}
                        >
                          Gender
                        </p>
                        <p className="">{PersonalDetails != null && PersonalDetails.GENDER ? PersonalDetails != null && PersonalDetails.GENDER : 'NA'}</p>
                      </div>
                      <div className="col-3">
                        <p
                          className="text-muted"
                          style={{ marginBottom: "5px !important" }}
                        >
                          Area Pin Code
                        </p>
                        <p className="">{PersonalDetails != null && PersonalDetails.PINCODE ? PersonalDetails != null && PersonalDetails.PINCODE : 'NA'}</p>
                      </div>
                    </div>
                    <p />
                    <div className="row">
                      <div className="col-3">
                        <p
                          className="text-muted"
                          style={{ marginBottom: "5px !important" }}
                        >
                          Marital Status
                        </p>
                        <p className="">{PersonalDetails != null && PersonalDetails.MARITAL_STATUS ? PersonalDetails != null && PersonalDetails.MARITAL_STATUS : 'NA'}</p>
                      </div>
                      <div className="col-3">
                        <p
                          className="text-muted"
                          style={{ marginBottom: "5px !important" }}
                        >
                          Hometown
                        </p>
                        <p className="">{PersonalDetails != null && PersonalDetails.HOME_TOWN ? PersonalDetails != null && PersonalDetails.HOME_TOWN : 'NA'}</p>
                      </div>
                      <div className="col-3">
                        <p
                          className="text-muted"
                          style={{ marginBottom: "5px !important" }}
                        >
                          Category
                        </p>
                        <p className="">{PersonalDetails != null && PersonalDetails.CAST_CATEGORY ? PersonalDetails != null && PersonalDetails.CAST_CATEGORY : 'NA'}</p>
                      </div>
                      <div className="col-3">
                        <p
                          className="text-muted"
                          style={{ marginBottom: "5px !important" }}
                        >
                          Work permit for USA
                        </p>
                        <p className="">{PersonalDetails != null && PersonalDetails.WORK_PERMIT_USA ? PersonalDetails != null && PersonalDetails.WORK_PERMIT_USA : 'NA'}</p>
                      </div>
                    </div>
                    <p />
                    <div className="row">
                      <div className="col-3">
                        <p
                          className="text-muted"
                          style={{ marginBottom: "5px !important" }}
                        >
                          Work permit of other country
                        </p>
                        {PersonalDetails != null && PersonalDetails.WorkPermitForOtherCountries.length > 0 ? PersonalDetails.WorkPermitForOtherCountries.map((ele) => {
                          return <React.Fragment>
                            <p className="">
                              {ele.COUNTRY}
                              {/* {PersonalDetails!=null && PersonalDetails.WorkPermitForOtherCountries[0].COUNTRY} */}
                            </p>
                          </React.Fragment>
                        }) : "NA"
                        }
                      </div>
                      <div className="col-3">
                        <p
                          className="text-muted"
                          style={{ marginBottom: "5px !important" }}
                        >
                          Differently Abled
                        </p>
                        <p className="">{PersonalDetails != null && PersonalDetails.WORK_PERMIT_USA === 'Y' ? 'YES' : 'NO'}</p>
                      </div>
                    </div>
                    <p />
                    <div className="table-responsive">
                      <table className="table mb-0">
                        <thead>
                          <tr>
                            <th>Languages</th>
                            <th>Proficiency</th>
                            <th>Read</th>
                            <th>Write</th>
                            <th>Speak</th>
                          </tr>
                        </thead>
                        <tbody>

                          {PersonalDetails != null && PersonalDetails.Language.length > 0 ? PersonalDetails.Language.map((ele) => {
                            return <React.Fragment>
                              <tr>
                                <th scope="row">{ele.LANGUAGE}</th>
                                <td>{ele.PROFICIENCY}</td>
                                <td>{ele.READ_SKILL == "Y" ? <i className="mdi mdi-check-all me-2" /> : <i className="mdi mdi-check-all me-2" />}</td>
                                <td>{ele.WRITE_SKILL == "Y" ? <i className="mdi mdi-check-all me-2" /> : <i className="mdi mdi-check-all me-2" />}</td>
                                <td>{ele.SPEAK_SKILL == "Y" ? <i className="mdi mdi-check-all me-2" /> : <i className="mdi mdi-check-all me-2" />}</td>
                              </tr>

                            </React.Fragment>
                          }) : "Not Available"
                          }



                          {/* <tr>
                      <th scope="row">Hindi</th>
                      <td>Expert</td>
                      <td>
                        <i className="mdi mdi-check-all me-2" />
                      </td>
                      <td>
                        <i className="mdi mdi-check-all me-2" />
                      </td>
                      <td>
                        <i className="mdi mdi-check-all me-2" />
                      </td>
                    </tr> */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              {/* end col */}
            </div>
            {/* Personal Details END */}
          </div>{" "}
          {/* container-fluid */}
        </div>
        {/* End Page-content */}


        <footer className="footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                Copyright © 2020-{moment().format('YY')}, JobzShala.com. All Rights Reserved.
              </div>
              <div className="col-sm-6">
                <div className="text-sm-end d-none d-sm-block">
                  Powered by
                  Akshaki India (P) Limited.
                </div>
              </div>
            </div>
          </div>
        </footer>

      </div>

    </React.Fragment>
  )
}

