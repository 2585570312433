import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { EmployerBenefitById, updateEmployerBenefit } from '../../actions/employer';
import AddEmployeebenefit from '../../component/Employers/AddEmployeeBenefit'
import constant from '../../constant';
export default function AddEmployeeBenefit() {

    const [response, setResponse] = React.useState({})
    const [showMsg, setShowMsg] = React.useState(false)

    const [data, setData] = useState(null)
    const params = useParams()

    const navigation = useNavigate();

    useEffect(() => {
        document.title = constant.title.EditEmployeeBenefits;

        EmployerBenefitById(params.id).then(res => {
            if (res.status) {
                setData(res.result)
            }
            else {
                alert(res.error)
            }
        })
            .catch(err => {
                alert(err)
            })

    }, [])


    const onSubmit = (model) => {
        updateEmployerBenefit(model).then(res => {
            if (res.status) {
                navigation(constant.component.employeebenefitslist.url)
            }
            else {
                setResponse(res)
                setShowMsg(true)
                setTimeout(() => {
                    setShowMsg(false)
                }, 7000)
            }
        })
            .catch(err => {
                alert(err)
            })
    }

    return (
        <React.Fragment>
            {
                data &&
                <AddEmployeebenefit onSubmit={onSubmit} detail={data} response={response} showMsg={showMsg} />
            }
        </React.Fragment>
    )
}
