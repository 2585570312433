import React, { Component } from 'react'
import List from '../../../component/campus/university/universityList'
import { changeUniversityStatus, universityList } from '../../../actions/campus';
export default class list extends Component {
    constructor(props) {
        super(props);
        this.state = {
            universityList: null,
            count: null,
            page: 1,
            showLoader: false,
        }
    }

    componentDidMount() {
        this.loadList(this.state.page)
    }

    onPageChange = (page) => {
        this.setState({ page: page }, () => {
            this.loadList(page)
        })
    }


    onChangesStatus = (UNIVERSITY_ID, STATUS) => {

        changeUniversityStatus({ UNIVERSITY_ID: UNIVERSITY_ID, STATUS: STATUS }).then(res => {
            if (res.status) {
                this.setState({
                    universityList: this.state.universityList.map(item => {
                        if (item.UNIVERSITY_ID == UNIVERSITY_ID) {
                            item.STATUS = STATUS
                        }
                        return item
                    })
                })

            }
            else {
                console.error(res.error)
            }
        })
    }
    render() {
        const { universityList, count, page, showLoader } = this.state;
        return (
            <React.Fragment>
                {count && universityList &&
                    <List
                        updateStatus={(id, status) => { this.onChangesStatus(id, status) }}
                        showLoader={showLoader}
                        universityList={universityList}
                        count={count}
                        page={page}
                        onPageChange={(page) => { this.onPageChange(page) }}
                    />
                }
            </React.Fragment>
        )
    }

    loadList(page) {

        universityList(page).then(res => {
            if (res.status) {
                this.setState({ universityList: res.result.list, count: res.result.count })
            }
        })
    }
}
