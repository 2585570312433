import React from 'react'
import { useEffect, useState } from 'react'
import List from '../../component/Enquiry/EnquiryList'
import constant from '../../constant'
import { getEnquiryList, deleteEnquiry } from '../../actions/utilitiesAction'
import Swal from 'sweetalert2'
import { onTypeChangeData } from '../../actions/utilitiesAction'
export default function EnquiryList() {

    const [list, setList] = useState([])
    const [count, setCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [showLoader, setShowLoader] = useState(true)

    useEffect(() => {
        EnquiryLists(currentPage)
        document.title = constant.title.EnquiryList
    }, [currentPage])

    const EnquiryLists = (page) => {
        getEnquiryList({ page: page }).then((res) => {

            if (res.status) {
                setList(res.result.list)
                setCount(res.result.count)
                setShowLoader(false)
            }
            else {
                alert(res.error)
            }
        }).catch((err) => {
            alert(err)
        })
    }

    const deleteEnquiryList = (ENQUIRY_ID) => {
        deleteEnquiry({ ENQUIRY_ID: ENQUIRY_ID }).then((res) => {

            if (res.status) {

                Swal.fire(
                    'Deleted!',
                    'Your enquiry has been deleted.',
                    'success'
                )
                EnquiryLists(currentPage)
            }
            else {
                Swal.fire(
                    (res.result.error)
                )
            }
        })
    }


    const getTypesValue = (type) => {
        onTypeChangeData(type).then(res => {
            setList(res.result.list)
        }).catch(err => {
            console.log(err);
        })


    }




    return (
        <React.Fragment>
            <List
                list={list}
                setList={setList}
                EnquiryLists={(page => EnquiryLists(page))}
                count={count}
                setPage={setCurrentPage}
                deleteEnquiryList={deleteEnquiryList}
                getType={getTypesValue}
                showLoader={showLoader}
            />
        </React.Fragment>
    )
}
