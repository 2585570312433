import React from 'react';
import Stickynote from './Stickynote';
import moment from 'moment';
const Footer = () => {
    return (
        <>
        <footer class="footer">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-6">
                        Copyright &copy; 2020-{moment().format('YY')}, JobzShala.com. All Rights Reserved.
                    </div>
                    <div class="col-sm-6">
                        <div class="text-sm-end d-none d-sm-block">
                            Powered by Akshaki India (P) Limited.
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        {/* <Stickynote /> */}
        </>
    )

}
export default Footer;