import React, { useEffect, useState } from 'react'
import { archiveBannerById, archiveSkill, getBannerAnalytics, getBannerList, getSkillList } from '../../actions/utilitiesAction'
import BannerList from '../../component/Banner/BannerList'
import constant from '../../constant'
import queryString from 'query-string'
import { useLocation } from "react-router-dom";
import BannerAnalys from '../../component/Banner/BannerAnalytics'
import { useNavigate, useParams } from 'react-router-dom'


const BannerAnalytics = (props) => {

    const [List, setList] = useState(null)
    const [count, setCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [keyword, setKeyword] = useState('')
    const [showLoader, setShowLoader] = useState(false)
    const location = useLocation()
    const params = useParams()



    useEffect(() => {
        const qParams = queryString.parse(location.search)
        getBannersLists();
        document.title = constant.title.SkillList
    }, [currentPage])

    const getBannersLists = (BANNER_ID) => {
        
        setShowLoader(true)
        getBannerAnalytics(params.id).then((res) => {
            if (res.status) {
                setShowLoader(false)
                setList(res.result.list)
                setCount(res.result.count)
            }
        }).catch((err) => {
            alert(err)
        })
    }

    
    return (
        <React.Fragment>
            <BannerAnalys
                List={List}
                setList={setList}
                
                getBannersLists={(BANNER_ID) => { getBannersLists(BANNER_ID) }}
                count={count}
                setPage={setCurrentPage}
                setKeyword={setKeyword}
                showLoader={showLoader}
                cpage={currentPage}
            />
        </React.Fragment>
    )
}
export default BannerAnalytics