import React from 'react'
import { useState } from 'react';
import Pagination from 'react-js-pagination';
import { Link } from 'react-router-dom';
import moment from 'moment';
import constant from '../../constant';
import { Spinner } from 'reactstrap';
import Loader from '../common/Loader';

export default function ArchivedList(props) {

    const [currentPage, setCurrentPage] = useState(1);
    const { list } = props

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        props.setPage(pageNumber)
    };

    const updateStatus = (EMPLOYER_ID, STATUS) => {
        const model = {
            EMPLOYER_ID: EMPLOYER_ID,
            STATUS: STATUS === 'A' ? 'I' : 'A'
        }
        props.updateStatus(model);
    }


    return (
        <React.Fragment>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0" style={{ fontSize: "18px" }}>Archived Employers List</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="#">Employers</a></li>
                                            <li className="breadcrumb-item active">Archived Employers List</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>
                        {/* <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">


                                        <form className="row gy-12 gx-12 align-items-center">
                                            <div className="col-sm-2">
                                                <label className="visually-hidden" for="autoSizingInput">Name</label>
                                                Search Archive Employers
                                            </div>
                                            <div className="col-sm-6">
                                                <label className="visually-hidden" for="autoSizingInputGroup">Username</label>
                                                <div className="input-group">
                                                    <input type="text" className="form-control" id="autoSizingInputGroup" placeholder="Enter Employer ID, Name, Email ID, Phone etc" />
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <button type="button" className="btn btn-success waves-effect btn-label waves-light"><i className="bx bx-check-double label-icon"></i> Search</button>&nbsp; <button type="button" className="btn btn-outline-danger">Reset</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">Archive Employers</h4>
                                        <div className="table-responsive">
                                            <table className="table align-middle table-nowrap table-hover">
                                                {props.showLoader && <Loader />}
                                                {!props.showLoader && <thead className="table-light">
                                                    <tr>
                                                        <th scope="col">ID</th>
                                                        <th scope="col">Employer Information</th>
                                                        <th scope="col">JobzShala Stats</th>
                                                        <th scope="col">Profile Status</th>
                                                        <th scope="col">Other Information </th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>}
                                                <tbody>
                                                    {
                                                        list && list.length > 0 && list.map((item, index) => {
                                                            return (
                                                                <tr>
                                                                    <td style={{ color: "#ff8000", fontSize: "15px", fontFamily: "nunito" }}>
                                                                        {item.CUSTOM_EMPLOYER_ID}
                                                                    </td>
                                                                    <td>
                                                                        <h5 className="mb-1" style={{ fontSize: "14px" }}><a href="#" className="text-dark">{item.COMPANY_NAME}</a></h5>
                                                                        <p className="text-muted mb-0" style={{ color: "#00add2" }}>{item.EMPLOYER_ROLE}<span className="badge border border-light rounded-circle bg-success p-1"><span className="visually-hidden">Consultant & Recruiter</span></span></p>
                                                                        <p className="text-muted mb-0" style={{ color: "#0080ff" }}><i className="bx bx-user align-middle me-1" style={{ fontSize: "16px" }}></i>{item.CONTACT_PERSON}</p>
                                                                        <p className="text-muted mb-0">{item.EMAIL}</p>
                                                                        <p className="text-muted mb-0">{item.MOBILE}</p>
                                                                        <p className="text-muted mb-0"><span style={{ color: "#f46a6a" }}>Member Since</span> | {moment(item.CREATED_ON).format('DD-MM-YYYY')}</p>
                                                                    </td>
                                                                    <td>
                                                                        <h5 className="mb-1" style={{ fontSize: "14px" }}><a href="javascript:void(0);" className="text-dark">Jobs Posted (12)</a></h5>
                                                                        <p className="text-muted mb-0"><a href="javascript:void(0);" className="text-dark"><span style={{ color: "#dd006f" }}>Featured Jobs (12) </span></a> <br />
                                                                            <a href="javascript:void(0);" className="text-dark"><span style={{ color: "#8000ff" }}>Premium Jobs (12)</span></a> <br />
                                                                            <a href="javascript:void(0);" className="text-dark"><span style={{ color: "#008040" }}>Shortlisted (2)</span></a>
                                                                            <br />
                                                                            <a href="javascript:void(0);" className="text-dark"><span style={{ color: "#009999" }}>Rejected (5) </span></a><br />
                                                                            <a href="javascript:void(0);" className="text-dark"><span style={{ color: "#ff00ff" }}>Saved Candidates (23)</span></a>
                                                                        </p>
                                                                    </td>
                                                                    <td>
                                                                        Completeness <span className="badge rounded-pill bg-success">90%</span><br />
                                                                        <a href="javascript:void(0);" className="text-dark"><span style={{ color: "#008040" }}>Candidate Message (3) </span></a> <br />

                                                                        <a href="javascript:void(0);" className="text-dark"><span style={{ color: "#808040" }}>Subscription (4)</span></a>
                                                                        <br />
                                                                        <a href="javascript:void(0);" className="text-dark"><span style={{ color: "#444444" }}>Transaction History (5) </span></a> <br />   <a href="javascript:void(0);" className="text-dark"><span style={{ color: "#c80064" }}>Reviews(3) </span></a>
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.STATUS == 'A' ?
                                                                                <button type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop4" className="btn btn-info waves-effect btn-label waves-light"><i className="bx bx-street-view label-icon "></i> View Details </button> : <button type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop4" className="btn waves-effect btn-label waves-light text-light" style={{ backgroundColor: "#e81c28" }}><i className="bx bx-street-view label-icon "></i> View Details </button>
                                                                        }

                                                                        <div className="modal fade" id="staticBackdrop4" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                                                                                <div className="modal-content">
                                                                                    <div className="modal-header">
                                                                                        <h5 className="modal-title" id="staticBackdropLabel">Mount Talent Consulting - Information</h5>
                                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                    </div>

                                                                                    <div className="modal-body">
                                                                                        <p>
                                                                                            <table className="table table-striped mb-0">
                                                                                                <tbody>

                                                                                                    <tr>
                                                                                                        <td>Year Established</td>
                                                                                                        <td>{item.FOUNDED}</td>
                                                                                                    </tr>
                                                                                                    {/* <tr>
                                                                                                        <td>Certifications</td>
                                                                                                        <td>Google Certified</td>
                                                                                                    </tr> */}
                                                                                                    <tr>
                                                                                                        <td>Location</td>
                                                                                                        <td>{item.LOCATION}</td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>Logo</td>
                                                                                                        <td><img src={`${process.env.REACT_APP_BASE_URL}/company/logo/${item.COMPANY_LOGO}`} style={{ width: "100px" }} /></td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className="modal-footer">
                                                                                        <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <ul className="list-inline contact-links mb-0" style={{ fontSize: "20px" }}>
                                                                            <li className="list-inline-item px-2">
                                                                                <a href="javascript: void(0);" title="View Client" data-bs-toggle="modal" data-bs-target="#staticBackdrop11"><i className="bx bxs-zoom-in"></i></a>
                                                                            </li>
                                                                            <li className="list-inline-item px-2">
                                                                                <Link to={constant.component.editemployers.url.replace(':id', item.EMPLOYER_ID)} title="Edit Featured Employer"><i className="bx bx-edit"></i></Link>
                                                                            </li>
                                                                            <li className="list-inline-item px-2">
                                                                                <a href="javascript: void(0)" onClick={() => updateStatus(item.EMPLOYER_ID, item.STATUS)} style={{ color: 'red' }} title={item.STATUS == 'A' ? "Block Employer" : "Unblock Employer"}>
                                                                                    {
                                                                                        item.STATUS == 'A' ? <i className="bx bx-trash"></i> : <i class="bx bx-log-in"></i>
                                                                                    }


                                                                                </a>
                                                                            </li>
                                                                        </ul>

                                                                        <div className="modal fade" id="staticBackdrop11" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                                                                                <div className="modal-content">
                                                                                    <div className="modal-header">
                                                                                        <h5 className="modal-title" id="staticBackdropLabel">Employer Details - Mount Talent Consulting</h5>
                                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                    </div>
                                                                                    <div className="modal-body">
                                                                                        <p>
                                                                                            <table className="table table-striped mb-0">
                                                                                                <tbody>
                                                                                                    <tr>
                                                                                                        <td style={{ width: "32%" }} >ID</td>
                                                                                                        <td>{item.CUSTOM_EMPLOYER_ID}</td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>Companny</td>
                                                                                                        <td>Mount Talent Consulting</td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>Industry</td>
                                                                                                        <td>Consultant & Recruiter</td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>Contact Person</td>
                                                                                                        <td>Sushma Sharma</td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>Email</td>
                                                                                                        <td>susma@gmail.com</td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>Phone No.</td>
                                                                                                        <td>99902434443</td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>Member Since</td>
                                                                                                        <td>01-01-2021</td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>Year Established</td>
                                                                                                        <td>2010</td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>Address</td>
                                                                                                        <td>A - 51, Sector 10, Noida</td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>Certifications</td>
                                                                                                        <td>Google Certified</td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>Logo</td>
                                                                                                        <td><img src="https://www.mounttalent.com/wp-content/uploads/2022/03/cropped-mount-talent-1.png" width="100px" /></td>
                                                                                                    </tr>

                                                                                                    <tr>
                                                                                                        <td>Profile Status</td>
                                                                                                        <td><span className="badge rounded-pill bg-success">LIVE</span></td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className="modal-footer">
                                                                                        <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>

                                                            )
                                                        })
                                                    }
                                                    {!props.showLoader && list.length == 0 && < td colSpan={10} className="text-center text-danger">No Archived/Blocked Employer Record Found</td>}



                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="pagination pagination-rounded justify-content-center mt-4">
                                                    <Pagination
                                                        activePage={currentPage}
                                                        itemsCountPerPage={10}
                                                        totalItemsCount={props.count}
                                                        pageRangeDisplayed={5}
                                                        onChange={handlePageChange}
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
