import React, { useEffect, useState } from 'react'
import Add from '../../../component/campus/university/addUniversity'
import { editUniversity, getUniversityPrefilled } from '../../../actions/campus'
import Swal from 'sweetalert2'
import constant from '../../../constant'
import { useParams } from 'react-router-dom'
const EditUniversity = () => {
    const { id } = useParams();
    const [data, setData] = useState(null)

    useEffect(() => {
        getUniversityPrefilled(id).then(res => {
            if (res.status) {
                setData(res.result)
            }
        })
    }, [])
    const onSubmit = (model) => {
        debugger
        model.UNIVERSITY_ID = id;
        editUniversity(model).then(res => {
            if (res.status) {
                Swal.fire({
                    icon: "success",
                    text: "University Updated Successfully",
                    timer: 2000,
                    showCancelButton: false,
                    showConfirmButton: false,
                });
                window.location.href = constant.component.universityList.url
            }
        })
    }
    return (
        <React.Fragment>
            {data && <Add
                id={id}
                onSubmit={(e) => { onSubmit(e) }}
                data={data}
            />}
        </React.Fragment>
    )
}

export default EditUniversity