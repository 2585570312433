import React from 'react'
import { useState } from 'react';
import moment from 'moment';
import Pagination from 'react-js-pagination';
import constant from '../../../constant';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toTitleCase } from '../../../utils';
import Loader from '../../common/Loader';
import queryString from 'query-string'


export default function PracticeQuestionList(props) {
    const [currentPage, setCurrentPage] = useState(1);
    const location = useLocation()
    const Navigate = useNavigate()

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        Navigate(location.pathname + '?p=' + pageNumber)
        props.setPage(pageNumber)
    };

    const updateStatus = (PRACTICE_QUESTION__ID, STATUS) => {
        const model = {
            PRACTICE_QUESTION__ID: PRACTICE_QUESTION__ID,
            STATUS: STATUS === 'U' ? 'A' : 'U'
        }
        props.setPage(currentPage)
        props.updateStatus(model)
    }
    console.log("Listtt", props);

    const qParams = queryString.parse(location.search)

    const { cpage } = props

    return (
        <React.Fragment>
            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0" style={{ fontSize: "18px" }}>Practice Questions List</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="javascript: void(0);">Utilities</a></li>
                                            <li className="breadcrumb-item active">Practice Questions List</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table align-middle table-wrap table-hover">

                                                {!props.showLoader && <thead className="table-light">

                                                    <tr>
                                                        <th scope="col" style={{ width: "55px" }}>S.No.</th>
                                                        <th scope="col" style={{ width: "30%" }}>Practice Question</th>
                                                        <th scope="col">Skill</th>
                                                        <th scope="col">View Options & Correct Answer</th>
                                                        <th scope="col" style={{ width: "105px" }}>Added On</th>
                                                        <th scope="col" style={{ width: "105px" }}>Action</th>
                                                    </tr>
                                                </thead>}
                                                <tbody>

                                                    {props.showLoader ? <Loader /> : props.list && props.list.length > 0 ? props.list.map((ele, index) => {
                                                        return (
                                                            <tr>
                                                                <td>{currentPage == 1 ? `${index + 1}` : `${index == 9 ? currentPage : currentPage - 1}` + `${index == 9 ? 0 : index + 1}`}</td>
                                                                <td>{ele.QUESTION}</td>
                                                                <td>{toTitleCase(ele.SKILL.toString().replace(/,/g, ', '))}</td>
                                                                <td><a style={{ cursor: "pointer", }} data-bs-toggle="modal" data-bs-target={"#MODELID" + ele.PRACTICE_QUESTION__ID} ><u>Options & Correct Answer</u></a>

                                                                    <div className="modal fade" id={"MODELID" + ele.PRACTICE_QUESTION__ID}  tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                                                                            <div className="modal-content">
                                                                                <div className="modal-header">
                                                                                    <h5 className="modal-title" id="staticBackdropLabel">{ele.QUESTION}</h5>
                                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                </div>

                                                                                <div className="modal-body">
                                                                                    <p>
                                                                                        <table className="table table-striped mb-0">
                                                                                            <tbody>
                                                                                                <tr>
                                                                                                    <td><p><span style={{ color: "#e81c28" }}>OPTION1 : </span> {ele.OPTION1} </p></td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td><p><span style={{ color: "#e81c28" }}>OPTION2 :  </span> {ele.OPTION2} </p></td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td><p><span style={{ color: "#e81c28" }}>OPTION3 :  </span> {ele.OPTION3} </p></td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td><p><span style={{ color: "#e81c28" }}>OPTION4 : </span> {ele.OPTION4} </p></td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td><p><span style={{ color: "green" }}>Correct Answer :</span> {ele.ANSWER} </p></td>
                                                                                                </tr>
                                                                                            </tbody>

                                                                                        </table>
                                                                                    </p>
                                                                                </div>
                                                                                <div className="modal-footer">
                                                                                    <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </td>
                                                                <td>
                                                                    {moment(ele.CREATED_ON).format('DD MMM, YYYY')}                                                                </td>

                                                                <td>
                                                                    <ul className="list-inline contact-links mb-0" style={{ fontSize: "20px" }}>
                                                                        <li className="list-inline-item px-2">
                                                                            <Link to={constant.component.editPracticequestion.url.replace(':id', ele.PRACTICE_QUESTION__ID).replace(':pageno', cpage)
                                                                            } title="Edit Question"><i className="bx bx-edit"></i></Link>
                                                                        </li>
                                                                        <li className="list-inline-item px-2">
                                                                            <a href="javascript: void(0);" onClick={() => updateStatus(ele.PRACTICE_QUESTION__ID, ele.STATUS)} style={{ color: "red" }} title={ele.STATUS == 'U' ? "Archive Record" : "Unarchive Record"}>
                                                                                {
                                                                                    ele.STATUS == 'U' ? <i className="bx bx-trash"></i> : <i class="bx bx-log-in"></i>
                                                                                }
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                        :
                                                        props.list && props.list.length == 0 && props.showLoader == false &&
                                                        <td colSpan={10} className="text-center text-danger pt-3">No Practice Question Record Found</td>
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <ul className="pagination pagination-rounded justify-content-center mt-4">
                                                    <Pagination
                                                        activePage={qParams?.p ? +qParams?.p :
                                                            qParams?.pageno ? Number(qParams.pageno) : currentPage}
                                                        itemsCountPerPage={10}
                                                        totalItemsCount={props.count}
                                                        pageRangeDisplayed={5}
                                                        onChange={handlePageChange}
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                    />
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
