import React, { useEffect, useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import constant from "../../constant";
import Pagination from "react-js-pagination";
import { Spinner } from "react-bootstrap";
import Parser from "react-html-parser";
import { nl2br } from "react-js-nl2br";
import Loader from "../common/Loader";


function PostJobRequest(props) {
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, SetLoading] = useState(false)


    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        props.setPage(pageNumber)

    };

    useEffect(() => {
        document.title = constant.title.JobPostRequestList

    })

    const updateStatus = (JOB_ID,EMPLOYER_ID, ACTION_FROM_ADMIN, e) => {
        SetLoading(true)
        const model = {
            JOB_ID: JOB_ID,
            EMPLOYER_ID:EMPLOYER_ID,
            ACTION_FROM_ADMIN: e.target.value,
        };
        props.setPage(currentPage)
        props.updateStatus(model)
    };

    const searchList = () => {
        setCurrentPage(1)
        props.getpostjobList(1)
    }


    return (

        <React.Fragment>

            {
                console.log("props", props)
            }

            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0" style={{ fontSize: "18px" }}>
                                        Job Post
                                    </h4>


                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item">
                                                <a href="javascript:void(0)">Jobs</a>
                                            </li>
                                            <li className="breadcrumb-item active">Job Post</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <form className="row gy-12 gx-12 align-items-center">
                                            <div className="col-sm-2">
                                                <label className="visually-hidden" for="autoSizingInput">
                                                    Name
                                                </label>
                                                Search Jobs
                                            </div>
                                            <div className="col-sm-6">
                                                <label
                                                    className="visually-hidden"
                                                    for="autoSizingInputGroup"
                                                >
                                                    Username
                                                </label>

                                                <div className="input-group">
                                                    <input type="text"
                                                        onKeyDown={
                                                            (e) => {
                                                                if (e.key == 'Enter') {
                                                                    e.preventDefault()
                                                                    searchList()
                                                                }
                                                            }
                                                        } onChange={(e) => {
                                                            props.setKeyword(e.target.value)
                                                        }} className="form-control" id="autoSizingInputGroup" placeholder="Enter Job Title, Keywords etc" />
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <button
                                                    type="button"
                                                    className="btn btn-success waves-effect btn-label waves-light"
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        searchList()
                                                    }}
                                                >
                                                    <i className="bx bx-check-double label-icon"></i> Search
                                                </button>
                                                &nbsp;

                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table align-middle table-nowrap table-hover">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col">S.NO.</th>
                                                        <th scope="col">Jobs Title</th>
                                                        <th scope="col">Jobs Description</th>
                                                        <th scope="col">Added On</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col" style={{ width: "152px" }}>
                                                            Action
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {props.showLoader ? <Loader /> : props.postjobList && props.postjobList.length > 0 &&
                                                        props.postjobList.map((data, index) => {
                                                            console.log(data,"data");
                                                            return (

                                                                <tr>
                                                                    <td>{10 * currentPage + (index + 1) - 10}</td>
                                                                    <td>
                                                                        <p className="text-muted mb-0">
                                                                            {data.JOB_TITLE} <br />
                                                                           <span style={{color:"red"}}> {data.LISTNING_TYPE=="2"?"Featured Job":data.LISTNING_TYPE=="1"?"Regular Job":data.LISTNING_TYPE=="3"?"Premium Job":''}</span>
                                                                        </p>
                                                                    </td>
                                                                    <td>{data.JOB_DETAILS && data.JOB_DETAILS.length > 30 ? data.JOB_DETAILS.slice(0, 30) + "..." : data.JOB_DETAILS}  <a style={{ color: "blue", cursor: "pointer" }} data-bs-toggle="modal" data-bs-target={"#MODELID" + data.JOB_ID}>Job Details</a>

                                                                        <div className="modal fade" id={"MODELID" + data.JOB_ID}  tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                                                                                <div className="modal-content">
                                                                                    <div className="modal-header">
                                                                                        <h5 className="modal-title" id="staticBackdropLabel">Job Details - <span style={{ color: "red" }} >{data.JOB_TITLE}</span></h5>
                                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                    </div>

                                                                                    <div className="modal-body">
                                                                                        <p>
                                                                                            <table className="table table-striped mb-0">
                                                                                                <tbody>
                                                                                                <tr>
                                                                                                        <td><b> ID : </b></td>
                                                                                                        <td style={{ whiteSpace: "normal", maxWidth: '635px', display: 'flex' }}>
                                                                                                            {data.CUSTOM_JOB_ID}

                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td><b>Job Description : </b></td>
                                                                                                        <td style={{ whiteSpace: "normal", maxWidth: '635px', display: 'flex', textAlign: 'justify' }}>{nl2br(data.JOB_DETAILS)} </td>
                                                                                                    </tr>

                                                                                                    <tr>
                                                                                                        <td><b> Key Skills : </b></td>
                                                                                                        <td style={{ whiteSpace: "normal", maxWidth: '635px', display: 'flex' }}>
                                                                                                            {data.KEYWORDS}

                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td><b> Company Name : </b></td>
                                                                                                        <td style={{ whiteSpace: "normal", maxWidth: '635px', display: 'flex' }}>
                                                                                                            {data.COMPANY_NAME}

                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td><b> Location : </b></td>
                                                                                                        <td style={{ whiteSpace: "normal", maxWidth: '635px', display: 'flex' }}>
                                                                                                            {data.JOB_LOCATIONS?data.JOB_LOCATIONS:'-'}

                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td><b> Logo : </b></td>
                                                                                                        <td >
                                                                                                        <img src={`${process.env.REACT_APP_BASE_URL}/company/logo/${data.COMPANY_LOGO}`} style={{ width: "100px" }} />

                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </tbody>

                                                                                            </table>
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className="modal-footer">
                                                                                        <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>

                                                                    <td>{moment(data.CREATED_ON).format("lll")}</td>
                                                                    <td>

                                                                        {
                                                                            data.ACTION_FROM_ADMIN == 'PENDING' ?

                                                                                <button type="button" className="btn btn-warning btn-sm waves-effect waves-light" style={{ backgroundColor: "orange", width: "74px" }}>PENDING</button> :

                                                                                data.ACTION_FROM_ADMIN == 'APPROVED' ?
                                                                                    <button type="button" className="btn btn-success btn-sm waves-effect waves-light">APPROVED</button> :
                                                                                    data.ACTION_FROM_ADMIN == 'REJECTED' ?
                                                                                        <button type="button" className="btn btn-danger btn-sm waves-effect waves-light" style={{ width: "74px" }}>REJECTED</button> : ''
                                                                        }


                                                                    </td>
                                                                    <td>
                                                                        <ul className="list-inline font-size-20 contact-links mb-0" >
                                                                            <li className="list-inline-item px-2">

                                                                                <select className="form-select" name="ACTION_FROM_ADMIN"
                                                                                    value={data.ACTION_FROM_ADMIN} onChange={(e) => updateStatus(data.JOB_ID, data.EMPLOYER_ID, data.ACTION_FROM_ADMIN, e)}   >

                                                                                    <option SELECTED={!data.ACTION_FROM_ADMIN ? true : false} value="PENDING"  >Pending</option>

                                                                                    <option value="APPROVED" SELECTED={!data.ACTION_FROM_ADMIN ? true : false} >Approved</option>

                                                                                    <option value="REJECTED" SELECTED={!data.ACTION_FROM_ADMIN ? true : false} >Rejected</option>

                                                                                </select>

                                                                            </li>

                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}

                                                    {/* {props.count == 0 &&


                                                        <React.Fragment>
                                                            <div style={{
                                                                width: "80vw", height: "70vh", display: "flex", alignItems: "center",
                                                                justifyContent: "center"
                                                            }}>
                                                                <Spinner animation="border" variant="info"
                                                                    style={{ width: '3rem', height: '3rem' }} />

                                                            </div>
                                                        </React.Fragment>
                                                    } */}

                                                    {!props.showLoader && props.postjobList.length == 0 && < td colSpan={10} className="text-center text-danger">No Pending Job found</td>}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="pagination pagination-rounded justify-content-center mt-4">
                                                    <Pagination
                                                        activePage={currentPage}
                                                        itemsCountPerPage={10}
                                                        totalItemsCount={props.count}
                                                        pageRangeDisplayed={5}
                                                        onChange={handlePageChange}
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default PostJobRequest;
