import React from 'react'
import { addGovtDepartment } from '../../../actions/utilitiesAction'
import Add from '../../../component/Utilities/GovtDepartment/AddGovtDepartment'

const AddGovtDepartment = () => {
    const [response, setResponse] = React.useState({})
    const [showMsg, setShowMsg] = React.useState(false)

    const onSubmit = (model) => {
        addGovtDepartment(model).then((res) => {
            // if (res.status)
            //     alert(res.messageCode)
            setResponse(res)
            setShowMsg(true)
            setTimeout(() => {
                setShowMsg(false)
            }, 7000)
        }).catch((err) => {
            alert(err)
        })
    }

    return (
        <React.Fragment>
            <Add onSubmit={onSubmit} response={response} showMsg={showMsg} />
        </React.Fragment>
    )
}
export default AddGovtDepartment;