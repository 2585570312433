import React from 'react'
import Pagination from 'react-js-pagination'
import { useState } from 'react';
import Loader from '../../common/Loader';
import Parser from "react-html-parser";
import { getDateParts } from '../../../utils';
import { Link } from "react-router-dom";
import constant from '../../../constant';

const FeaturedjobList = (props) => {


    const [currentPage, setCurrentPage] = useState(1);
    const [searchKey, setSearchKey] = useState("");
    const [employerFullDetails, setEmployerFullDetails] = useState({})
    const [jobFullDetails, setJobFullDetails] = useState({});


    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        props.setPage(pageNumber)
    };

    const handleSearch = () => {
        setCurrentPage(1)
        props.handleSearch(searchKey, 1);
    };
    return (
        <React.Fragment>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0" style={{ fontSize: "18px" }}>
                                        Featured Job List
                                    </h4>
                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item">
                                                {/* <a href="#">Job</a> */}
                                            </li>
                                            <li className="breadcrumb-item active">Featured Job List</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <form className="row gy-12 gx-12 align-items-center">
                                            <div className="col-sm-2">
                                                <label
                                                    className="visually-hidden"
                                                    for="autoSizingInput"
                                                >
                                                    Name
                                                </label>
                                                Search Jobs
                                            </div>
                                            <div className="col-sm-6">
                                                <label
                                                    className="visually-hidden"
                                                    for="autoSizingInputGroup"
                                                >
                                                    Username
                                                </label>
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="autoSizingInputGroup"
                                                        placeholder="Enter Custom JOB ID, Job Title, Keywords etc"
                                                        onKeyDown={
                                                            (e) => {
                                                                if (e.key == 'Enter') {
                                                                    e.preventDefault()
                                                                    handleSearch()
                                                                }
                                                            }
                                                        }
                                                        onChange={(e) => {
                                                            setSearchKey(e.target.value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <button
                                                    type="button"
                                                    className="btn btn-success waves-effect btn-label waves-light"
                                                    onClick={handleSearch}
                                                >
                                                    <i className="bx bx-check-double label-icon"></i>{" "}
                                                    Search
                                                </button>

                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">Latest Jobs</h4>
                                        <div className="table-responsive">
                                            {props.showLoader && <Loader />}

                                            <table className="table align-middle table-nowrap table-hover">
                                                {!props.showLoader && (
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th scope="col" style={{ width: "70px" }}>
                                                                ID
                                                            </th>
                                                            <th scope="col">Job Information</th>
                                                            <th scope="col">JobzShala Stats</th>
                                                            <th scope="col">Jobs Details</th>
                                                            <th scope="col">Other Information </th>
                                                            {/* <th scope="col">Status </th> */}
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                    </thead>
                                                )}

                                                <tbody>
                                                    {props &&
                                                        props.list.length > 0 &&
                                                        props.list.map((data, index) => {

                                                            var date1 = new Date(data.CREATED_ON);
                                                            var date2 = new Date();
                                                            const diffTime = Math.abs(date2 - date1);
                                                            const diffDays = Math.ceil(
                                                                diffTime / (1000 * 60 * 60)
                                                            );
                                                            data.diffDays = diffDays;
                                                            return (
                                                                <tr>
                                                                    <td
                                                                        style={{
                                                                            color: "#ff8000",
                                                                            fontSize: "15px",
                                                                            fontFamily: "nunito",
                                                                        }}
                                                                    >
                                                                        {data.CUSTOM_JOB_ID}

                                                                        <p> {data.STATUS == "U" ? (
                                                                            <span className="badge rounded-pill bg-success">
                                                                                ACTIVE
                                                                            </span>
                                                                        ) : 
                                                                        (
                                                                            <span className="badge rounded-pill bg-danger">
                                                                                INACTIVE
                                                                            </span>
                                                                        )}
                                                                        </p>

                                                                    </td>

                                                                    <td>
                                                                        <h5
                                                                            className="mb-1"
                                                                            style={{ fontSize: "14px" }}

                                                                            title={data.JOB_TITLE}
                                                                        >
                                                                            <a href="#" className="text-dark">
                                                                                {data.JOB_TITLE.length > 25
                                                                                    ? data.JOB_TITLE.slice(0, 30) + "..."
                                                                                    : data.JOB_TITLE}

                                                                            </a>
                                                                        </h5>
                                                                        <p
                                                                            className="text-muted mb-0"
                                                                            style={{
                                                                                color: "#dd006f",
                                                                                fontSize: "12px",
                                                                            }}
                                                                        >
                                                                        </p>
                                                                        {data.CTC_MIN && data.CTC_MAX && (
                                                                            <p
                                                                                className="text-muted mb-0"
                                                                                style={{ color: "#00add2" }}
                                                                            >
                                                                                &#x20b9;{" "}
                                                                                {` ${data.CTC_MIN} -  ${data.CTC_MAX}  L.P.A.`}
                                                                            </p>
                                                                        )}


                                                                        <p
                                                                            className="text-muted mb-0"
                                                                            style={{ color: "#0080ff" }}
                                                                        >
                                                                            <i
                                                                                className="bx bx-street-view align-middle me-1"
                                                                                style={{ fontSize: "16px" }}
                                                                            ></i>{" "}
                                                                            {data.JOB_LOCATIONS &&
                                                                                data.JOB_LOCATIONS.length > 0
                                                                                ? data.JOB_LOCATIONS.map(
                                                                                    (data, index) => {
                                                                                        return data.CITY + " ";
                                                                                    }
                                                                                )
                                                                                : "Not Available "}

                                                                        </p>
                                                                        <p className="text-muted mb-0">
                                                                            <span style={{ color: "#f46a6a" }}>
                                                                                Posted{" "}

                                                                            </span>{" "}
                                                                            {/* {data.diffDays} days ago{" "} */}
                                                                            {getDateParts(data.CREATED_ON).relativeTime.split(' ')[0] == 'a' ? '1 ' + getDateParts(data.CREATED_ON).relativeTime.split(' ').splice(1).join(' ') : getDateParts(data.CREATED_ON).relativeTime}
                                                                        </p>
                                                                    </td>

                                                                    <td>
                                                                        <h5
                                                                            className="mb-1"
                                                                            style={{ fontSize: "14px" }}
                                                                        >
                                                                            <a
                                                                                href="javascript:void(0);"
                                                                                className="text-dark"
                                                                            >
                                                                                Candidates Applied {data.APPLIED
                                                                                }
                                                                            </a>
                                                                        </h5>
                                                                        <p className="text-muted mb-0">
                                                                            <a
                                                                                href="javascript:void(0);"
                                                                                className="text-dark"
                                                                            >
                                                                                <span style={{ color: "#009999" }}>
                                                                                    {/* Rejected (0){" "} */}
                                                                                    Shortlisted :  {data.SHORTLISTED}
                                                                                </span>
                                                                            </a>{" "}
                                                                            <br />



                                                                            <a
                                                                                href="javascript:void(0);"
                                                                                className="text-dark"
                                                                            >
                                                                                <span style={{ color: "#FF0000" }}>
                                                                                    {/* Rejected (0){" "} */}
                                                                                    Rejected :  {data.REJECTED}
                                                                                </span>
                                                                            </a>{" "}


                                                                            <br />
                                                                            <a
                                                                                href="javascript:void(0);"
                                                                                className="text-dark"
                                                                            >
                                                                                <span style={{ color: "#FFA500" }}>
                                                                                    Candidates Hold : {data.HOLD}
                                                                                </span>
                                                                            </a>{" "}
                                                                            <br />
                                                                            <a style={{ cursor: "pointer", color: "#000000" }} data-bs-toggle="modal" data-bs-target={"#MODELID" + data.QUESTION_ID}  >Questions : <span style={{
                                                                                color: "A1EDD5", background: "#00c694",
                                                                                padding: "1px 8px",
                                                                                borderRadius: "30px",
                                                                                color: "#fff",
                                                                                lineHeight: "9px",
                                                                                fontSize: "12px",
                                                                                paddingBottom: "3px"
                                                                            }} >View Questions</span></a>

                                                                            <div className="modal fade" id={"MODELID" + data.QUESTION_ID} tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                                                                                    <div className="modal-content">
                                                                                        <div className="modal-header">
                                                                                            <h5 className="modal-title" id="staticBackdropLabel">Question</h5>
                                                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                        </div>

                                                                                        <div className="modal-body">
                                                                                            <p>
                                                                                                <table className="table table-striped mb-0">
                                                                                                    <tbody>
                                                                                                        <tr>
                                                                                                            <td><p><span style={{ color: "#e81c28" }}>
                                                                                                            </span>  {Array.isArray(data.QUESTION) && data.QUESTION.length > 0 ? data.QUESTION.map((item, index) => {
                                                                                                                return (
                                                                                                                    <span>
                                                                                                                        <div>  {" "}{item.QUESTION}</div>
                                                                                                                    </span>
                                                                                                                )
                                                                                                            }) : <span> Not Available</span>} </p></td>
                                                                                                        </tr>




                                                                                                    </tbody>

                                                                                                </table>
                                                                                            </p>
                                                                                        </div>
                                                                                        <div className="modal-footer">
                                                                                            <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>


                                                                        </p>
                                                                    </td>
                                                                    <td
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#staticBackdrop42"
                                                                        style={{ cursor: 'pointer' }}
                                                                        onClick={(e) => {
                                                                            setEmployerFullDetails(data);
                                                                        }}
                                                                    >


                                                                        <h5
                                                                            className="mb-1"
                                                                            style={{ fontSize: "14px" }}
                                                                        >
                                                                            <a href="#" className="text-dark">

                                                                                {data.CLIENT_NAME && data.CLIENT_NAME.length > 30 ? data.CLIENT_NAME.slice(0, 30) + "..." : data.CLIENT_NAME} {" "}
                                                                            </a>
                                                                        </h5>
                                                                        <p
                                                                            className="text-muted mb-0"
                                                                            style={{ color: "#804000" }}
                                                                        >

                                                                            {data.COMPANY_NAME && data.COMPANY_NAME.length > 30 ? data.COMPANY_NAME.slice(0, 30) + "..." : data.COMPANY_NAME} {" "}
                                                                        </p>
                                                                        <p
                                                                            className="text-muted mb-0"
                                                                            style={{ color: "#0080ff" }}
                                                                        >
                                                                            {data.CLIENT_DETAILS && data.CLIENT_DETAILS.length > 30 ? data.CLIENT_DETAILS.slice(0, 30) + "..." : data.CLIENT_DETAILS} {" "}


                                                                        </p>
                                                                        <p>
                                                                            {data.ADDRESS && data.ADDRESS.length > 30 ? data.ADDRESS.slice(0, 30) + "..." : data.ADDRESS}
                                                                        </p>
                                                                    </td>
                                                                    <td>
                                                                        <button
                                                                            type="button"
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#staticBackdrop41"
                                                                            className="btn btn-info waves-effect btn-label waves-light"
                                                                            onClick={(e) => {
                                                                                setJobFullDetails(data);
                                                                            }}
                                                                        >
                                                                            <i className="bx bx-street-view label-icon "></i>{" "}
                                                                            View Details
                                                                            {/* <span className="badge rounded-pill bg-danger">
                                      20
                                    </span> */}
                                                                        </button>
                                                                    </td>
                                                                    {/* <td><span className="badge rounded-pill bg-success"> Active</span></td> */}

                                                                    <td>
                                                                        <ul
                                                                            className="list-inline contact-links mb-0"
                                                                            style={{ fontSize: "20px" }}
                                                                        >
                                                                            <li className="list-inline-item px-2">
                                                                                <a href="javascript:void(0);"
                                                                                    style={{ color: "red" }} title="edit"><i className="bx bxs-edit"></i></a>
                                                                            </li>
                                                                            <li className="list-inline-item px-2">
                                                                                <a href="javascript:void(0);"
                                                                                    style={{ color: "red" }} title="delete"><i className="bx bx-trash"></i></a>
                                                                            </li>

                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}

                                                    {<>
                                                        {props && props.list && props.list.length == 0 && props.showLoader == false && (<td colSpan={10} className="text-center text-danger pt-3" style={{
                                                            paddingRight: "250px",
                                                            position: "inherit",
                                                        }}
                                                        >
                                                            No Jobs Available
                                                        </td>
                                                        )}
                                                    </>
                                                    }

                                                    <div
                                                        className="modal fade"
                                                        id="staticBackdrop41"
                                                        tabindex="-1"
                                                        role="dialog"
                                                        aria-labelledby="staticBackdropLabel"
                                                        aria-hidden="true"
                                                        style={{ overflowY: "initial" }}
                                                    >
                                                        <div
                                                            className="modal-dialog modal-dialog-centered modal-lg"
                                                            role="document"
                                                        >
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5
                                                                        className="modal-title"
                                                                        id="staticBackdropLabel"
                                                                    >
                                                                        Job Information
                                                                    </h5>
                                                                    <button
                                                                        type="button"
                                                                        className="btn-close"
                                                                        data-bs-dismiss="modal"
                                                                        aria-label="Close"
                                                                    ></button>
                                                                </div>

                                                                <div
                                                                    className="modal-body weightauto"
                                                                    style={{ height: "80vh", overflow: "auto" }}
                                                                >
                                                                    {jobFullDetails && (
                                                                        <p>
                                                                            <table className="table table-striped mb-0">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td style={{ width: "20%" }}>
                                                                                            Job description
                                                                                        </td>
                                                                                        <td>
                                                                                            {jobFullDetails.JOB_DETAILS &&
                                                                                                Parser(
                                                                                                    jobFullDetails.JOB_DETAILS
                                                                                                )}
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>Required Candidate profile</td>
                                                                                        <td>
                                                                                            {jobFullDetails.COURSE_SPECIALIZATION &&
                                                                                                jobFullDetails
                                                                                                    .COURSE_SPECIALIZATION
                                                                                                    .length > 0 &&
                                                                                                jobFullDetails
                                                                                                    .COURSE_SPECIALIZATION[0]
                                                                                                    .COURSE_STREAM &&
                                                                                                ` Degree in ${jobFullDetails.COURSE_SPECIALIZATION &&
                                                                                                [...new Set(jobFullDetails.COURSE_SPECIALIZATION.map(
                                                                                                    (data, index) => {
                                                                                                        return data.COURSE_STREAM;
                                                                                                    }
                                                                                                ))]
                                                                                                }`}
                                                                                            <br />

                                                                                            <br />
                                                                                            {`Excellent command on  ${jobFullDetails &&
                                                                                                jobFullDetails.KEYWORDS
                                                                                                }`}

                                                                                            <br />
                                                                                            {`Excellent command on ${jobFullDetails.JOB_DETAILS && Parser(jobFullDetails.JOB_DETAILS)}`}
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>Role</td>
                                                                                        <td>{jobFullDetails.ROLE_NAME}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>Industry Type</td>
                                                                                        <td>{jobFullDetails.INDUSTRY}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>Functional Area</td>
                                                                                        <td>
                                                                                            {jobFullDetails.FUNCTIONAL_AREA}
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>job Type</td>
                                                                                        <td>
                                                                                            {jobFullDetails.EMPLOYMENT_TYPE}
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>Role Category</td>
                                                                                        <td>{jobFullDetails.JOB_TITLE}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>Education</td>
                                                                                        <td>
                                                                                            {`  ${jobFullDetails.COURSE_SPECIALIZATION &&
                                                                                                [...new Set(jobFullDetails.COURSE_SPECIALIZATION.map(
                                                                                                    (data, index) => {
                                                                                                        return data.QUALIFICATION_NAME;
                                                                                                    }
                                                                                                ))]
                                                                                                }`}
                                                                                            <br />
                                                                                            {`  ${jobFullDetails.COURSE_SPECIALIZATION &&
                                                                                                [...new Set(jobFullDetails.COURSE_SPECIALIZATION.map(
                                                                                                    (data, index) => {
                                                                                                        return data.COURSE_STREAM;
                                                                                                    }
                                                                                                ))]
                                                                                                }`}
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>Key Skills</td>
                                                                                        <td>{jobFullDetails.KEYWORDS}</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </p>
                                                                    )}
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-light"
                                                                        data-bs-dismiss="modal"
                                                                    >
                                                                        Close
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="modal fade"
                                                        id="staticBackdrop42"
                                                        tabindex="-1"
                                                        role="dialog"
                                                        aria-labelledby="staticBackdropLabel"
                                                        aria-hidden="true"
                                                        style={{ overflowY: "initial" }}
                                                    >
                                                        <div
                                                            className="modal-dialog modal-dialog-centered modal-lg"
                                                            role="document"
                                                        >
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5
                                                                        className="modal-title"
                                                                        id="staticBackdropLabel"
                                                                    >
                                                                        Job Information
                                                                    </h5>
                                                                    <button
                                                                        type="button"
                                                                        className="btn-close"
                                                                        data-bs-dismiss="modal"
                                                                        aria-label="Close"
                                                                    ></button>
                                                                </div>

                                                                <div
                                                                    className="modal-body weightauto"
                                                                    style={{ overflow: "auto" }}
                                                                >
                                                                    {employerFullDetails && (
                                                                        <p>
                                                                            <table className="table table-striped mb-0">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td style={{ width: "20%" }}>Job Name</td>
                                                                                        <td>{employerFullDetails.CLIENT_NAME}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>Company Name</td>
                                                                                        <td>{employerFullDetails.COMPANY_NAME}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>Job Details</td>
                                                                                        <td>{employerFullDetails.CLIENT_DETAILS}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>Job Address</td>
                                                                                        <td>{employerFullDetails.ADDRESS}</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </p>
                                                                    )}
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-light"
                                                                        data-bs-dismiss="modal"
                                                                    >
                                                                        Close
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="pagination pagination-rounded justify-content-center mt-4">

                                                    <Pagination
                                                        activePage={currentPage}
                                                        itemsCountPerPage={10}
                                                        totalItemsCount={props.count}
                                                        pageRangeDisplayed={5}
                                                        onChange={handlePageChange}
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                    />

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default FeaturedjobList
