import React, { useEffect, useState, useRef } from 'react'
import { uploadBlogPicture } from '../../../actions/utilitiesAction';
import constant from '../../../constant'
import { ToSeoUrl } from '../../../utils';
import NumberFormat from 'react-number-format';
import SuccessMessage from '../../common/SuccessMessage';
import ErrorMessage from '../../common/ErrorMessage';
import { useNavigate, useParams } from 'react-router-dom';
import SunEditor from 'suneditor-react';
// import { DefaultEditor } from 'react-simple-wysiwyg';




export default function AddBlog(props) {
    const { id } = useParams()
    const { detail } = props
    const [error, setError] = useState(false)
    const Navigate = useNavigate()
    const editor = useRef();

    const [data, setData] = useState({
        BLOG_TITLE: "",
        BLOG_DETAILS: "",
        PICTURE: "",
        AUTHOR: "",
        TAG: "",
        URL: "",
        CATEGORY_ID: "",
        SORT_NUMBER: "",
        META_DESCRIPTION: "",
        META_TITLE: ""
    })


    const onSubmit = (e) => {

        e.preventDefault();
        id && Navigate(constant.component.bloglist.url)
        if (data.BLOG_TITLE.trim().length == 0 ||
            data.BLOG_DETAILS.trim().length == 0 ||
            data.CATEGORY_ID.toString().trim().length == 0 ||
            data.AUTHOR.trim().length == 0 ||
            data.SORT_NUMBER.toString().trim().length == 0 ||
            data.PICTURE.trim().length == 0 ||
            data.TAG.trim().length == 0 || data.URL.length == 0 ||
            data.META_DESCRIPTION.trim().length == 0 || data.META_DESCRIPTION.length == 0
            || data.META_TITLE.trim().length == 0
        ) {
            setError(true)
        }
        else {
            const model = {
                BLOG_TITLE: data.BLOG_TITLE,
                BLOG_DETAILS: data.BLOG_DETAILS,
                PICTURE: data.PICTURE,
                TAG: data.TAG,
                AUTHOR: data.AUTHOR,
                URL: data.URL,
                CATEGORY_ID: data.CATEGORY_ID,
                SORT_NUMBER: data.SORT_NUMBER,
                META_DESCRIPTION: data.META_DESCRIPTION,
                META_TITLE: data.META_TITLE

            };
            if (detail) {
                model.BLOG_ID = detail.BLOG_ID
            }
            props.onSubmit(model)
        }
    }

    useEffect(() => {
        (id ? document.title = constant.title.EditBlog : document.title = constant.title.AddBlog)
        if (detail) {

            setData({
                BLOG_TITLE: detail.BLOG_TITLE,
                BLOG_DETAILS: detail.BLOG_DETAILS,
                PICTURE: detail.PICTURE,
                AUTHOR: detail.AUTHOR,
                TAG: detail.TAG,
                URL: detail.URL,
                CATEGORY_ID: detail.CATEGORY_ID,
                SORT_NUMBER: detail.SORT_NUMBER,
                CATEGORY: detail.BLOG_CATEGORY,
                META_DESCRIPTION: detail.META_DESCRIPTION,
                META_TITLE: detail.META_TITLE
            }, () => {
                console.log("data", data)
            })
        }
    }, [])
    const handleChange = (e) => {

        const { name, value } = e.target
        if (name === 'BLOG_TITLE') {
            let url = ToSeoUrl(value);

            setData({
                ...data,
                [name]: value,
                'URL': url
            })
        }
        else {
            setData({
                ...data,
                [name]: value
            })
        }



    }
    // const getSunEditorInstance = (sunEditor) => {
    //     editor.current = sunEditor;
    // };

    // const onHandleChange = (content) => {
    //     setData((prevState) => ({
    //         ...prevState,
    //         BLOG_DETAILS: content
    //     }))
    //     // data.BLOG_DETAILS(content)
    // }
    const onBlogPictureUpload = (e) => {
        e.preventDefault();
        let model = new FormData();
        model.append('file', e.target.files[0]);
        uploadBlogPicture(model).then((res) => {
            if (res.status) {
                setData((prevState) => ({
                    ...prevState,
                    PICTURE: res.result.fileName
                }))
            } else {
                alert(res.error);
            }

        })
    }
    console.log("data", data.BLOG_DETAILS);



    return (
        <React.Fragment>
            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">


                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 " style={{ fontSize: '18px' }}>{id ? "Update Blog" : "Add Blog"}</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="javascript: void(0);">Utilities</a></li>
                                            <li className="breadcrumb-item"><a href="javascript: void(0);">Blog</a></li>
                                            <li className="breadcrumb-item active">{id ? "Update Blog" : "Add Blog"}</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">Create New Blog</h4>
                                        {
                                            props.showMsg && (props.response.status ?
                                                <SuccessMessage message={props.response.messageCode} />
                                                :
                                                <ErrorMessage message={props.response.error} />)
                                        }
                                        <form>
                                            <div className="row mb-4">
                                                <label for="projectname" className="col-form-label col-lg-2">Title <span style={{ color: "#FF0000" }}>*</span></label>
                                                <div className="col-lg-10">
                                                    <input id="projectname"
                                                        name="BLOG_TITLE"
                                                        value={data.BLOG_TITLE}
                                                        onChange={handleChange}
                                                        type="text"
                                                        className={error && data.BLOG_TITLE.trim().length <= 0 ? 'form-control is-invalid ' : 'form-control'}
                                                        placeholder="Enter Blog Title..." />
                                                    {
                                                        error && data.BLOG_TITLE.trim().length <= 0 ? <span className='text-danger ml-1'>This Field is Required</span>
                                                            : ""}
                                                </div>
                                            </div>
                                            <div className="row mb-4">
                                                <label for="projectname" className="col-form-label col-lg-2">Author <span style={{ color: "#FF0000" }}>*</span></label>
                                                <div className="col-lg-10">
                                                    <input id="projectname"
                                                        name="AUTHOR"
                                                        value={data.AUTHOR}
                                                        onChange={handleChange}
                                                        type="text"
                                                        className={error && data.AUTHOR.trim().length <= 0 ? 'form-control is-invalid ' : 'form-control'}
                                                        placeholder="Author..." />
                                                    {
                                                        error && data.AUTHOR.trim().length <= 0 ? <span className='text-danger ml-1'>This Field is Required</span>
                                                            : ""}
                                                </div>
                                            </div>
                                            <div className="row mb-4">
                                                <label for="projectname" className="col-form-label col-lg-2">Slug <span style={{ color: "#FF0000" }}>*</span></label>
                                                <div className="col-lg-10">
                                                    <input id="projectname"
                                                        name="URL"
                                                        value={data.URL}
                                                        onChange={handleChange}
                                                        type="text"
                                                        // readOnly={true}
                                                        className={error && data.URL.trim().length <= 0 ? 'form-control is-invalid ' : 'form-control'}
                                                        placeholder="Slug..." />
                                                    {
                                                        error && data.URL.trim().length <= 0 ? <span className='text-danger ml-1'>This Field is Required</span>
                                                            : ""}
                                                </div>
                                            </div>
                                            <div className="row mb-4">
                                                <label for="projectdesc" className="col-form-label col-lg-2" >Description <span style={{ color: "#FF0000" }}>*</span></label>
                                                <div className="col-lg-10 ">
                                                    <textarea
                                                        className={error && data.BLOG_DETAILS.trim().length <= 0 ? 'form-control is-invalid ' : 'form-control'}
                                                        id="projectdesc"
                                                        rows="8"
                                                        name='BLOG_DETAILS'
                                                        onChange={handleChange}
                                                        value={data.BLOG_DETAILS}
                                                        placeholder="Enter Article Description..."
                                                    >
                                                    </textarea>
                                                    {/* <SunEditor getSunEditorInstance={getSunEditorInstance} onChange={onHandleChange} setContents={data.BLOG_DETAILS}
                                                        className={error && data.BLOG_DETAILS.trim().length <= 0 ? 'form-control is-invalid ' : 'form-control'}
                                                    /> */}
                                                    {
                                                        error && data.BLOG_DETAILS.trim().length <= 0 ? <span className='text-danger ml-1'>This Field is Required</span>
                                                            : ""}
                                                </div>
                                            </div>

                                            <div className="row mb-4">
                                                <label className="col-form-label col-lg-2">Category <span style={{ color: "#FF0000" }}>*</span></label>
                                                <div className="col-lg-10">
                                                    <div className="input-daterange input-group" id="project-date-inputgroup" data-provide="datepicker" data-date-format="dd M, yyyy" data-date-container='#project-date-inputgroup' data-date-autoclose="true">
                                                        <select
                                                            className={error && data.CATEGORY_ID.toString().trim().length <= 0 ? 'form-control is-invalid ' : 'form-control'}
                                                            name='CATEGORY_ID'
                                                            onChange={handleChange}
                                                            value={data.CATEGORY_ID}
                                                        >
                                                            <option value="">Select a Category</option>
                                                            {
                                                                props.list && props.list.length > 0 &&
                                                                props.list.map((item) => {
                                                                    return (
                                                                        <option value={item.CATEGORY_ID}>{item.CATEGORY}</option>
                                                                    )
                                                                })

                                                            }
                                                        </select>

                                                    </div>
                                                    {
                                                        error && data.CATEGORY_ID.toString().trim().length <= 0 ? <span className='text-danger ml-1'>This Field is Required</span>
                                                            : ""}
                                                </div>
                                            </div>
                                            <div className="row mb-4">
                                                <label for="projectname" className="col-form-label col-lg-2">Sort Number <span style={{ color: "#FF0000" }}>*</span></label>
                                                <div className="col-lg-10">
                                                    <NumberFormat id="projectname"
                                                        name="SORT_NUMBER"
                                                        value={data.SORT_NUMBER}
                                                        onChange={handleChange}
                                                        allowLeadingZeros={false}
                                                        allowNegative={false}
                                                        thousandSeparator={false}
                                                        type="text"
                                                        className={error && data.SORT_NUMBER.trim().length <= 0 ? 'form-control is-invalid ' : 'form-control'}
                                                        placeholder="Sort Number..." />
                                                    {
                                                        error && data.SORT_NUMBER.trim().length <= 0 && <span className='text-danger ml-1'>This Field is Required</span>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row mb-4">
                                                <label for="projectbudget" className="col-form-label col-lg-2">Tags <span style={{ color: "#FF0000" }}>*</span></label>
                                                <div className="col-lg-10">
                                                    <input id="projectbudget"
                                                        type="text"
                                                        name='TAG'
                                                        onChange={handleChange}
                                                        value={data.TAG}
                                                        placeholder="Enter Blog Tag. Seprated by comma ',' in case of more than one tag..."
                                                        className={error && data.TAG.trim().length <= 0 ? 'form-control is-invalid ' : 'form-control'} />
                                                    {
                                                        error && data.TAG.trim().length <= 0 && <span className='text-danger ml-1'>This Field is Required</span>
                                                    }
                                                </div>

                                            </div>

                                            <div className="row mb-4">
                                                <label for="projectbudget" className="col-form-label col-lg-2">Meta Title <span style={{ color: "#FF0000" }}>*</span></label>
                                                <div className="col-lg-10">
                                                    <input id="projectbudget"
                                                        type="text"
                                                        name='META_TITLE'
                                                        onChange={handleChange}
                                                        value={data.META_TITLE}
                                                        placeholder="Enter Meta Title"
                                                        className='form-control' />
                                                    {
                                                        error && data.META_TITLE.trim().length <= 0 && <span className='text-danger ml-1'>This Field is Required</span>
                                                    }
                                                </div>

                                            </div>

                                            <div className="row mb-4">
                                                <label for="projectbudget" className="col-form-label col-lg-2">Meta Description <span style={{ color: "#FF0000" }}>*</span></label>
                                                <div className="col-lg-10">
                                                    <input id="projectbudget"
                                                        type="text"
                                                        name='META_DESCRIPTION'
                                                        onChange={handleChange}
                                                        value={data.META_DESCRIPTION}
                                                        placeholder="Enter Meta Description"
                                                        className='form-control' />
                                                    {
                                                        error && data.META_DESCRIPTION.trim().length <= 0 && <span className='text-danger ml-1'>This Field is Required</span>
                                                    }
                                                </div>

                                            </div>

                                        </form>
                                        <div className="row mb-4">
                                            <label className="col-form-label col-lg-2">Picture <span style={{ color: "#FF0000" }}>*</span></label>
                                            <div className="col-lg-10">
                                                <div className="input-group">
                                                    <input
                                                        className={error && data.PICTURE.trim().length <= 0 ? 'form-control is-invalid ' : 'form-control'}
                                                        id="inputGroupFile02"
                                                        name='PICTURE'
                                                        type="file"
                                                        onChange={(e) => onBlogPictureUpload(e)}
                                                    />

                                                    <label className="input-group-text" for="inputGroupFile02">Upload</label>

                                                </div>
                                                {detail ? <a target='_blank' href={`${process.env.REACT_APP_BASE_URL}/blog/Picture/${detail.PICTURE}`}>{detail.PICTURE}</a> : ""}
                                                {
                                                    error && data.PICTURE.trim().length <= 0 && <span className='text-danger ml-1'>This Field is Required</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="row justify-content-end">
                                            <div className="col-lg-10">
                                                <button type="submit"
                                                    className="btn btn-primary"
                                                    onClick={onSubmit}
                                                >{detail ? "Save Changes" : "Create Blog"}</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
