import React, { useEffect, useState } from 'react'
import { createEntityType } from '../../actions/employer'
import AddEntitytype from '../../component/Employers/AddEntityType'
import constant from '../../constant'
export default function AddEntityType() {

    const [response, setResponse] = useState({})
    const [showMsg, setShowMsg] = useState(false)

    useEffect(() => {
        document.title = constant.title.AddEntityType;
    }, [])


    const onSubmit = (model) => {
        createEntityType(model).then(res => {
            setResponse(res)
            setShowMsg(true)
            setTimeout(() => {
                setShowMsg(false)
            }, 7000)
        })
            .catch(err => {
                alert(err)
            })
    }
    return (
        <React.Fragment>
            <AddEntitytype onSubmit={onSubmit} response={response} showMsg={showMsg} />
        </React.Fragment>
    )
}
