import React from 'react'
import { useState } from 'react'
import { addJobType } from '../../../actions/utilitiesAction'
import Add from '../../../component/Utilities/JobType/AddJobType'


const AddJobType = () => {
    const [response, setResponse] = useState({})
    const [showMsg, setShowMsg] = useState(false)

    const onSubmit = (model) => {

        addJobType(model).then((res) => {
            // if (res.status)
            //     alert(res.messageCode)
            setResponse(res)
            setShowMsg(true)
            setTimeout(() => {
                setShowMsg(false)
            }, 7000)
        }).catch((err) => {
            alert(err)
        })
    }


    return (
        <React.Fragment>
            <Add onSubmit={onSubmit} response={response} showMsg={showMsg} />
        </React.Fragment>
    )
}
export default AddJobType;