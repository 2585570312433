import React from 'react'
import Totaljobs from '../../component/Menu/TotalJobs'
import {
    archiveEducationQualification,
    archiveJob,
    createEducationQualification,
    createRole,
    listJob,
    updateEducationQualification,
    updateRole,
} from "../../actions/JobsAction";
import { useState, useEffect } from 'react';
export default function TotalJobs() {

    const [currentPage, setCurrentPage] = useState(1);
    const [list, setList] = useState([]);
    const [count, setCount] = useState(0);
    const [showLoader, setShowLoader] = useState(false)

    useEffect(() => {
        getJobList('', currentPage);
    }, [currentPage]);

    const getJobList = (keywords, pageNumber) => {
        setShowLoader(true)
        listJob({ KEYWORD: keywords, pageNumber: pageNumber })
            .then((res) => {

                if (res.status) {
                    setList(res.result.list);
                    setCount(res.result.count);
                    setShowLoader(false)
                }
            })
            .catch((err) => {
                alert(err);
            });
    };

    const updateStatus = (model) => {

        archiveJob(model)
            .then((res) => {
                if (res.status) {
                    getJobList('', currentPage);
                }
            })
            .catch((err) => {
                alert(err);
            });
    };

    return (
        <React.Fragment>
            <Totaljobs
                list={list}
                updateStatus={(data) => updateStatus(data)}
                getJobList={(page) => getJobList(page)}
                count={count}
                setPage={(page) => setCurrentPage(page)}
                handleSearch={(searchkey, pageNumber) => { getJobList(searchkey, pageNumber) }}
                setShowLoader={setShowLoader}
                showLoader={showLoader}

            />
        </React.Fragment>
    )
}
