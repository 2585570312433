import React from 'react'
import CountryList from '../../../component/Utilities/CountryCityAndState/Country'
import constant from '../../../constant'
import { useEffect, useState } from 'react'
import { getCountryList } from '../../../actions/utilitiesAction'


export default function Country() {


    const [List, setList] = useState([])
    const [count, setCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)


    useEffect(() => {
        getCountryLists(currentPage)
        document.title = constant.title.Country

    }, [currentPage])


    const getCountryLists = (page) => {

        getCountryList({ page: page }).then((res) => {

            if (res.status) {
                console.log(res.status, 'ssfd');
                setList(res.result.list)
            }
        }).catch((err) => {
            alert(err)
        })
    }


    return (
        <React.Fragment>
            <CountryList
                List={List}
                setList={setList}
                getCountryLists={(page) => { getCountryLists(page) }}
                count={count}
                setPage={setCurrentPage}


            />
        </React.Fragment>
    )
}
