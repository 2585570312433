import { useEffect } from 'react'
import Header from '../../../component/campus/student/StudentDetail/header'
// import constant from '../../../constant'
import React from 'react'
import { useState } from 'react'
import Overview from '../../../component/campus/student/StudentDetail/overview'
// import { myEducationChoice, myEducations } from '../../../actions/studentEducation'
import { StudentEducationChoice, StudentEducationDetail, getStudentDetails } from '../../../actions/campus'
import { useParams } from 'react-router-dom'

const StudentProfile = () => {
  const params = useParams()
  const [basicDetails, setBasicDetails] = useState(null)
  const [portFolio, setPortFolio] = useState(null)
  const [educationList, setEducationList] = useState(null)
  const [educationChoices, setEducationChoices] = useState(null)
  const [internshipList, setInternshipList] = useState(null)
  const [certificationList, setCertificationList] = useState(null)



  useEffect(() => {
    getStudentDetails(params.id, params.type === 'college' ? 'ON CAMPUS' : 'OFF CAMPUS').then(res => {
      debugger
      if (res.status) {
        setBasicDetails(res.result.basicDetails)
        setPortFolio(res.result.portfolio)
      }
      else {
        console.error(res.error)
      }
    })

    StudentEducationDetail(params.id, params.type === 'college' ? 'ON CAMPUS' : 'OFF CAMPUS').then(res => {
      debugger

      if (res.status) {
        setEducationList(res.result)
      }
    })

    StudentEducationChoice(params.id, params.type === 'college' ? 'ON CAMPUS' : 'OFF CAMPUS').then(res => {
      debugger

      if (res.status) {
        setEducationChoices(res.result.education_choices);
        setInternshipList(res.result.internshipList);
        setCertificationList(res.result.certificationList);
      }
      else {
        console.error(res.error)

      }
    })


  }, [])

  return (
    <React.Fragment>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="profile-foreground position-relative mx-n4 mt-n4">
              <div className="profile-wid-bg customProfileWidBGStyle">
              </div>
            </div>
            <Header
              basicDetails={basicDetails}
              TYPE={params.type === 'college' ? 'ON CAMPUS' : 'OFF CAMPUS'}
            />
            <div className="row">
              <div className="col-lg-12">
                <div>
                  {/* <div className="d-flex profile-wrapper">
                                        <div className="flex-shrink-0 profileEditButtonStyle">
                                            <a
                                                href={constant.studentcomponent.editStudentProfile.url}
                                                className="btn btn-success"
                                            >
                                                <i className="ri-edit-box-line align-bottom" /> 
                                            </a>
                                        </div>
                                        <div>

                                        </div>

                                    </div> */}

                  <div className="tab-content pt-4 text-muted">
                    <Overview
                      basicDetails={basicDetails}
                      portFolio={portFolio}
                      educationList={educationList}
                      educationChoices={educationChoices}
                      internshipList={internshipList}
                      certificationList={certificationList}
                      TYPE={params.type === 'college' ? 'ON CAMPUS' : 'OFF CAMPUS'}

                    />

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>


    </React.Fragment>
  )
}

export default StudentProfile