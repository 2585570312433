import React, { useEffect, useState } from 'react'
import NumberFormat from 'react-number-format'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { uploadPicture } from '../../../actions/ProfileAction'
import constant from '../../../constant'
import { getStorage } from '../../../utils'
import ErrorMessage from '../../common/ErrorMessage'
import SuccessMessage from '../../common/SuccessMessage'
export default function UpdateProfile(props) {
    const Navigate = useNavigate()
    const { id } = useParams()
    const [data, setData] = useState({
        FIRST_NAME: "",
        LAST_NAME: "",
        PHONE: "",
        ROLE_NAME: "",
        EMAIL_ID: "",
        PASSWORD: "",
        Confirm_Password: "",
        GENDER: "",
        OTHER_DETAILS: "",
        PROFILE_PICTURE: "",
    })
    const [error, setError] = useState(false)
    const [passwordShow, setPasswordShow] = useState(false)
    const [ConfirmPasswordShow, setConfirmPasswordShow] = useState(false)
    const [file, setFile] = React.useState(null)
    const detail = props.detail;
    const ud = getStorage(constant.keys.userDetails)


    const handleChange = (e) => {
        const { name, value } = e.target
        console.log(data.Confirm_Password, data.PASSWORD);
        if (e.target.name === 'Confirm PASSWORD') {
            if (data.PASSWORD != value && value != "") {
                setError(true)
            }
            else {
                setError(false)
            }

            setData({
                ...data,
                ['Confirm_Password']: value
            })
        }

        else {
            setData({
                ...data,
                [name]: value
            })

        }



    }

    const togglePasswordVisiblity = (e) => {
        setPasswordShow(!passwordShow);
    }
    const togglePassword = (e) => {
        setConfirmPasswordShow(!ConfirmPasswordShow);
    }
    const onSubmit = (e) => {
        e.preventDefault();

        if (data.Confirm_Password != data.PASSWORD) {
            setError(true)
            return false
        }
        if (data.FIRST_NAME.trim().length === 0 ||
            data.LAST_NAME.trim().length === 0 ||
            data.PHONE.trim().length === 0 ||
            data.GENDER.trim().length === 0 || data.OTHER_DETAILS.trim().length===0) {

            setError(true)
            return false
        }
        const model = {
            FIRST_NAME: data.FIRST_NAME,
            LAST_NAME: data.LAST_NAME,
            PHONE: data.PHONE,
            EMAIL_ID: data.EMAIL_ID,
            OTHER_DETAILS: data.OTHER_DETAILS,
            GENDER: data.GENDER,
            PROFILE_PICTURE: data.PROFILE_PICTURE,
            PASSWORD: data.PASSWORD && data.PASSWORD.length ? data.PASSWORD : ''
        }

        if (detail) {
            model.ADMIN_ID = detail.ADMIN_ID
        }
        props.onSubmit(model)
        // if (data.PASSWORD == data.Confirm_Password) {
        //     props.onSubmit(model)
        // } else {
        //     alert("password is not matched ")
        // }

    }
    const onBlogPictureUpload = (e) => {
        e.preventDefault();
        let model = new FormData();
        model.append('file', e.target.files[0]);

        uploadPicture(model).then((res) => {
            if (res.status) {
                setData((prevState) => ({
                    ...prevState,
                    PROFILE_PICTURE: res.result.fileName
                }))
                setFile(e.target.files[0])
            } else {
                alert(res.error);
            }

        })
    }
    useEffect(() => {
        if (detail) {
            setData({
                FIRST_NAME: detail.FIRST_NAME,
                LAST_NAME: detail.LAST_NAME,
                PHONE: detail.PHONE,
                ROLE_NAME: detail.ROLE_ID,
                EMAIL_ID: detail.EMAIL_ID,
                GENDER: detail.GENDER,
                OTHER_DETAILS: detail.OTHER_DETAILS,
                PROFILE_PICTURE: detail.PROFILE_PICTURE,
            })
        }

    }, [])



    const handleReset = (e) => {
        setError(false)
        e.preventDefault();
        Navigate(constant.component.dashboard.url)
    }


    return (

        <React.Fragment>

            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 font-size-18">Update Profile</h4>

                                    {
                                        props.showMsg && (props.response.status ?
                                            <SuccessMessage message={props.response.messageCode} />
                                            : <ErrorMessage message={props.response.error} />)
                                    }
                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="javascript: void(0);">My Account</a></li>
                                            <li className="breadcrumb-item active">Update Profile</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">Profile Information</h4>
                                        <div id="basic-example">
                                            <section>
                                                <form method="POST">
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label htmlFor="basicpill-firstname-input">First name <span className='text-danger'>*</span></label>
                                                                <input type="text"
                                                                    className={error && data.FIRST_NAME.trim().length === 0 ? 'form-control is-invalid ' : 'form-control'}
                                                                    id="basicpill-firstname-input"
                                                                    placeholder="Enter First Name"
                                                                    name="FIRST_NAME"
                                                                    value={data.FIRST_NAME}
                                                                    onChange={handleChange}
                                                                />
                                                                {
                                                                    error && data.FIRST_NAME.trim().length === 0 ? <span className='text-danger ml-1'>This Field is Required</span>
                                                                        : ""}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label htmlFor="basicpill-lastname-input">Last name <span className='text-danger'>*</span></label>
                                                                <input type="text"
                                                                    className={error && data.LAST_NAME.trim().length === 0 ? 'form-control is-invalid ' : 'form-control'}
                                                                    id="basicpill-lastname-input"
                                                                    placeholder="Enter Last Name"
                                                                    name='LAST_NAME'
                                                                    value={data.LAST_NAME}
                                                                    onChange={handleChange}
                                                                />
                                                                {
                                                                    error && data.LAST_NAME.trim().length === 0 ? <span className='text-danger ml-1'>This Field is Required</span>
                                                                        : ""}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label htmlFor="basicpill-phoneno-input">Phone <span className='text-danger'>*</span></label>
                                                                <NumberFormat
                                                                    allowLeadingZeros={false}
                                                                    allowNegative={false}
                                                                    thousandSeparator={false}
                                                                    maxlength={10}
                                                                    className={error && data.PHONE.trim().length === 0 ? 'form-control is-invalid ' : 'form-control'}
                                                                    id="basicpill-phoneno-input"
                                                                    placeholder="Enter Phone No."
                                                                    name='PHONE'
                                                                    value={data.PHONE}
                                                                    onChange={handleChange}
                                                                />
                                                                {
                                                                    error && data.PHONE.trim().length === 0 ? <span className='text-danger ml-1'>This Field is Required</span>
                                                                        : ""}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label htmlFor="basicpill-email-input">Email <span className='text-danger'>*</span></label>
                                                                <input type="email"
                                                                    className={error && data.EMAIL_ID.trim().length === 0 ? 'form-control is-invalid ' : 'form-control'}
                                                                    id="basicpill-email-input"
                                                                    placeholder="Enter Email ID"
                                                                    name='EMAIL_ID'
                                                                    value={data.EMAIL_ID}
                                                                    onChange={handleChange}
                                                                    disabled
                                                                />
                                                                {
                                                                    error && data.EMAIL_ID.trim().length === 0 ? <span className='text-danger ml-1'>This Field is Required</span>
                                                                        : ""}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label htmlFor="basicpill-phoneno-input">Role <span className='text-danger'>*</span></label>
                                                                <select className="form-select"
                                                                    name="ROLE"
                                                                    value={data.ROLE_NAME}
                                                                    onChange={handleChange}
                                                                    disabled
                                                                >
                                                                    <option value=''>Select Role</option>
                                                                    {
                                                                        props.roleList && props.roleList.length > 0 && props.roleList.map((item, index) => {
                                                                            return (
                                                                                <option value={item.ROLE_ID}>{item.ROLE_NAME}</option>
                                                                            )

                                                                        })

                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label htmlFor="basicpill-phoneno-input">Gender <span className='text-danger'>*</span></label>
                                                                <select className={error && data.GENDER.trim().length === 0 ? 'form-select is-invalid ' : 'form-select'}
                                                                    onChange={handleChange}
                                                                    value={data.GENDER}
                                                                    name='GENDER'
                                                                >
                                                                    <option value="">Select Gender</option>
                                                                    <option value="Male">Male</option>
                                                                    <option value="Female">Female</option>
                                                                    <option value="Others">Others</option>
                                                                </select>
                                                                {
                                                                    error && data.GENDER.trim().length === 0 && <span className='text-danger ml-1'>This Field is Required</span>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label htmlFor="basicpill-phoneno-input">New Password</label>
                                                                <input
                                                                    type={(passwordShow) ? "text" : "password"}

                                                                    className="form-control"
                                                                    id="basicpill-lastname-input"
                                                                    placeholder="Enter New Password"
                                                                    name='PASSWORD'
                                                                    value={data.PASSWORD}
                                                                    onChange={handleChange}
                                                                />
                                                                <button
                                                                    className="btn btn-light "
                                                                    style={{ position: "absolute", top: "27px", right: "12px" }}
                                                                    type="button"
                                                                    id="password-addon"
                                                                >
                                                                    <i className={(passwordShow) ? "fa fa-eye password-icon eyeicon" : "fa fa-eye-slash eyeicon"} onClick={(e) => togglePasswordVisiblity(e)}></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label htmlFor="basicpill-phoneno-input">Confirm Password</label>
                                                                <input type={(ConfirmPasswordShow) ? "text" : "password"}
                                                                    className="form-control"
                                                                    id="basicpill-lastname-input"
                                                                    placeholder="Confirm New Password"
                                                                    name='Confirm PASSWORD'
                                                                    value={data.Confirm_Password}
                                                                    onChange={handleChange}
                                                                />
                                                                <button
                                                                    className="btn btn-light "
                                                                    style={{ position: "absolute", top: "27px", right: "12px" }}
                                                                    type="button"
                                                                    id="password-addon"
                                                                >
                                                                    <i className={(ConfirmPasswordShow) ? "fa fa-eye password-icon eyeicon" : "fa fa-eye-slash eyeicon"} onClick={(e) => togglePassword(e)}></i>
                                                                </button>
                                                                {error ? <span className='text-danger'>* Password and confirm password does not match</span> : ""}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="mb-3">
                                                                <label htmlFor="basicpill-address-input">About Me <span className='text-danger'>*</span></label>
                                                                <textarea
                                                                    id="basicpill-address-input"
                                                                    className={error && data.OTHER_DETAILS.trim().length === 0 ? 'form-control is-invalid ' : 'form-control'}
                                                                    rows={2}
                                                                    placeholder="Enter About Me"
                                                                    name='OTHER_DETAILS'
                                                                    value={data.OTHER_DETAILS}
                                                                    onChange={handleChange}
                                                                />
                                                                {
                                                                    error && data.OTHER_DETAILS.trim().length === 0 && <span className='text-danger ml-1'>This Field is Required</span>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="mb-3">
                                                                <label htmlFor="basicpill-address-input">Profile Picture <span className='text-danger'>*</span></label>
                                                                <div className="input-group">

                                                                    <input
                                                                        className={error && data.PROFILE_PICTURE.length === 0 ? 'form-control is-invalid ' : 'form-control'}
                                                                        id="inputGroupFile02"
                                                                        name='PICTURE'
                                                                        type="file"

                                                                        onChange={(e) => onBlogPictureUpload(e)}
                                                                    />
                                                                    {
                                                                        error && data.PROFILE_PICTURE.length === 0 && <span className='text-danger ml-1'>This Field is Required</span>
                                                                    }
                                                                    <label className="input-group-text" htmlFor="inputGroupFile02">Upload</label>
                                                                    {/* <img src={file ? URL.createObjectURL(file) : null} alt={file ? file.name : null} style={{ width: '50px', height: "50px" }} /> */}
                                                                </div>

                                                                {/* <a target='_blank' href={`${process.env.REACT_APP_BASE_URL}/admin/profile/pic/${detail.PROFILE_PICTURE}`}>{detail.PROFILE_PICTURE}</a> */}
                                                                <img src={file ? URL.createObjectURL(file) : `${process.env.REACT_APP_BASE_URL}/admin/profile/pic/${detail.PROFILE_PICTURE}`} className="img-thumbnail rounded-circle rounded-circle header-profile-user " style={{ width: '50px', height: "50px" }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">

                                                                <button type="button"
                                                                    onClick={(e) => onSubmit(e)}
                                                                    className="btn btn-info waves-effect btn-label waves-light">
                                                                    <i className="bx bx-check-double label-icon" />
                                                                    Update Profile</button>&nbsp;&nbsp;

                                                                <button type="button"
                                                                    onClick={(e) => handleReset(e)}
                                                                    className="btn btn-danger waves-effect btn-label waves-light">
                                                                    <i className="bx bx-block label-icon " /> Cancel</button>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </section>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}
