import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import constant from '../../../constant'
import Shimmer from '../../common/Shimmer'
import { getDateParts } from '../../../utils'

export default function LatestJobs(props) {
    const { joblist } = props
    const [jobFullDetails, setJobFullDetails] = useState({})

    const updateStatus = (JOB_ID, STATUS) => {
        const model = {
            JOB_ID: JOB_ID,
            STATUS: STATUS === "U" ? "A" : "U",
        };

        props.updateStatus(model);
    };



    return (
        <React.Fragment>

            <div className="row" >
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title mb-4">Latest Jobs</h4>
                            <div className="table-responsive">
                                <table className="table align-middle table-nowrap table-hover">
                                    <thead className="table-light">
                                        <tr>
                                            <th scope="col" style={{ width: "70px" }}>ID</th>
                                            <th scope="col">Job Information</th>
                                            <th scope="col">JobzShala Stats</th>
                                            <th scope="col">Employer Details</th>
                                            <th scope="col">Other Information </th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {joblist && joblist.length ? joblist.map((data, index) => {
                                            if (index < 3) {
                                                return (
                                                    <tr>
                                                        <td
                                                            style={{
                                                                color: "#ff8000",
                                                                fontSize: "15px",
                                                                fontFamily: "nunito",
                                                            }}
                                                        >
                                                            {data.CUSTOM_JOB_ID}
                                                        </td>

                                                        <td>
                                                            <h5
                                                                className="mb-1"
                                                                style={{ fontSize: "14px" }}
                                                            >
                                                                <a href="#" className="text-dark">
                                                                    {data.JOB_TITLE.length > 30 ? data.JOB_TITLE.toUpperCase().substring(0, 30) : data.JOB_TITLE.toUpperCase()}
                                                                </a>
                                                            </h5>
                                                            <p
                                                                className="text-muted mb-0"
                                                                style={{
                                                                    color: "#dd006f",
                                                                    fontSize: "12px",
                                                                }}
                                                            >
                                                                { }
                                                            </p>

                                                            <p
                                                                className="text-muted mb-0"
                                                                style={{
                                                                    color: "#dd006f",
                                                                    fontSize: "12px",
                                                                }}
                                                            >
                                                                {data.LISTNING_TYPE == "1"
                                                                    ? "REGULAR"
                                                                    : data.LISTNING_TYPE == "2"
                                                                        ? "FEATURED"
                                                                        : data.LISTNING_TYPE == "2"
                                                                            ? "PREMIUM"
                                                                            : ""}
                                                            </p>
                                                            <p
                                                                className="text-muted mb-0"
                                                                style={{ color: "#00add2" }}
                                                            >
                                                                {data.CTC_MIN} - {data.CTC_MAX} P.A.
                                                            </p>

                                                            <p
                                                                className="text-muted mb-0"
                                                                style={{ color: "#0080ff" }}
                                                            >
                                                                <i
                                                                    className="bx bx-street-view align-middle me-1"
                                                                    style={{ fontSize: "16px" }}
                                                                ></i>{" "}
                                                                {data.JOB_LOCATIONS &&
                                                                    data.JOB_LOCATIONS.length > 0
                                                                    ? data.JOB_LOCATIONS.map(
                                                                        (data, index) => {
                                                                            return data.CITY + " ";
                                                                        }
                                                                    )
                                                                    : "Not Available "}
                                                            </p>


                                                            {/* <p
                                                                className="text-muted mb-0"
                                                                style={{ color: "#0080ff" }}
                                                            >
                                                                <div className='tabledataflow'>
                                                                    {data.ADDRESS}

                                                                </div>
                                                              
                                                            </p> */}
                                                            <p className="text-muted mb-0">
                                                                <span style={{ color: "#f46a6a" }}>
                                                                    Posted{" "}
                                                                </span>{" "}
                                                                {getDateParts(data.CREATED_ON).relativeTime.split(' ')[0] == 'a' ? '1 ' + getDateParts(data.CREATED_ON).relativeTime.split(' ').splice(1).join(' ') : getDateParts(data.CREATED_ON).relativeTime}
                                                            </p>
                                                        </td>

                                                        <td>
                                                            <h5
                                                                className="mb-1"
                                                                style={{ fontSize: "14px" }}
                                                            >
                                                                <a
                                                                    href="javascript:void(0);"
                                                                    className="text-dark"
                                                                >
                                                                    Candidates Applied {data.APPLIED
                                                                    }
                                                                </a>
                                                            </h5>
                                                            <p className="text-muted mb-0">
                                                                <a
                                                                    href="javascript:void(0);"
                                                                    className="text-dark"
                                                                >
                                                                    <span style={{ color: "#009999" }}>
                                                                        {/* Rejected (0){" "} */}
                                                                        Shortlisted :  {data.SHORTLISTED}
                                                                    </span>
                                                                </a>{" "}
                                                                <br />



                                                                <a
                                                                    href="javascript:void(0);"
                                                                    className="text-dark"
                                                                >
                                                                    <span style={{ color: "#FF0000" }}>
                                                                        {/* Rejected (0){" "} */}
                                                                        Rejected :  {data.REJECTED}
                                                                    </span>
                                                                </a>{" "}


                                                                <br />
                                                                <a
                                                                    href="javascript:void(0);"
                                                                    className="text-dark"
                                                                >
                                                                    <span style={{ color: "#FFA500" }}>
                                                                        Candidates Hold : {data.HOLD}
                                                                    </span>
                                                                </a>{" "}
                                                                <br />
                                                                <a style={{ cursor: "pointer", color: "#000000" }} data-bs-toggle="modal" data-bs-target={"#MODELID" + data.QUESTION_ID}  >Questions : <span style={{
                                                                    color: "A1EDD5", background: "#00c694",
                                                                    padding: "1px 8px",
                                                                    borderRadius: "30px",
                                                                    color: "#fff",
                                                                    lineHeight: "9px",
                                                                    fontSize: "12px",
                                                                    paddingBottom: "3px"
                                                                }} >View Questions</span></a>

                                                                <div className="modal fade" id={"MODELID" + data.QUESTION_ID} data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                                                                        <div className="modal-content">
                                                                            <div className="modal-header">
                                                                                <h5 className="modal-title" id="staticBackdropLabel">Question</h5>
                                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                            </div>

                                                                            <div className="modal-body">
                                                                                <p>
                                                                                    <table className="table table-striped mb-0">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td><p><span style={{ color: "#e81c28" }}>
                                                                                                </span>  {Array.isArray(data.QUESTION) && data.QUESTION.length > 0 ? data.QUESTION.map((item, index) => {
                                                                                                    return (
                                                                                                        <span>
                                                                                                            <div>  {" "}{item.QUESTION}</div>
                                                                                                        </span>
                                                                                                    )
                                                                                                }) : <span> Not Available</span>} </p></td>
                                                                                            </tr>




                                                                                        </tbody>

                                                                                    </table>
                                                                                </p>
                                                                            </div>
                                                                            <div className="modal-footer">
                                                                                <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                            </p>
                                                        </td>
                                                        <td>
                                                            <h5
                                                                className="mb-1"
                                                                style={{ fontSize: "14px" }}
                                                            >
                                                                <a href="#" className="text-dark">
                                                                    {data?.CLIENT_NAME?.charAt(0).toUpperCase() + data?.CLIENT_NAME?.slice(1)}
                                                                </a>
                                                            </h5>
                                                            <p
                                                                className="text-muted mb-0"
                                                                style={{ color: "#804000" }}
                                                            >
                                                                {data.COMPANY_NAME}
                                                            </p>
                                                            <p
                                                                className="text-muted mb-0"
                                                                style={{ color: "#0080ff" }}
                                                            >
                                                                {" "}
                                                                {'Website:' + data.WEBSITE}
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <button
                                                                type="button"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#staticBackdrop41"
                                                                className="btn btn-info waves-effect btn-label waves-light"
                                                                onClick={(e) => { console.log(data, 'jjjjjjjjjjjjjjjjjjjjjj'); setJobFullDetails(data) }}
                                                            >
                                                                <i className="bx bx-street-view label-icon "></i>{" "}
                                                                View Details
                                                                {/* <span className="badge rounded-pill bg-danger">
                                                          20
                                                        </span> */}
                                                            </button>


                                                        </td>
                                                        <td>
                                                            {
                                                                data.STATUS == 'U' ?
                                                                    <button type="button" className="btn btn-success btn-sm waves-effect waves-light">ACTIVE</button> : <button type="button" className="btn btn-danger btn-sm waves-effect waves-light">ARCHIVE</button>
                                                            }
                                                        </td>
                                                        <td>
                                                            <ul
                                                                className="list-inline contact-links mb-0"
                                                                style={{ fontSize: "20px" }}
                                                            >
                                                                <li className="list-inline-item px-2">
                                                                    <Link
                                                                        to={constant.component.editJobs.url.replace(
                                                                            ":id",
                                                                            data.JOB_ID
                                                                        )}
                                                                    >
                                                                        <i
                                                                            className="bx bx-edit"
                                                                            title="Edit Industry "
                                                                        />
                                                                    </Link>
                                                                </li>
                                                                <li className="list-inline-item px-2">
                                                                    <a
                                                                        href="javascript: void(0);"
                                                                        onClick={() =>
                                                                            updateStatus(
                                                                                data.JOB_ID,
                                                                                data.STATUS
                                                                            )
                                                                        }
                                                                        style={{ color: "red" }}
                                                                        title={
                                                                            data.STATUS == "U"
                                                                                ? "Archive Record"
                                                                                : "Unarchive Record"
                                                                        }
                                                                    >
                                                                        {data.STATUS == "U" ? (
                                                                            <i className="bx bx-trash"></i>
                                                                        ) : (
                                                                            <i class="bx bx-log-in"></i>
                                                                        )}
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        }) : (joblist && joblist.length == 0 ? < td colSpan={10} className="text-center text-danger pt-3">No Jobs Record Found</td> : <Shimmer />)

                                        }
                                        {/* <tr>
                                            <td style={{ color: "#ff8000", fontFamily: "nunito" }}>
                                                RZG0000113
                                            </td>
                                            <td>
                                                <h5 className="font-size-14 mb-1"><a href="#" className="text-dark">PHP Laravel Developer</a></h5>
                                                <p className="text-muted mb-0" style={{ color: "#dd006f !important" }}>Premium Job</p>
                                                <p className="text-muted mb-0" style={{ color: "#00add2 !important" }}>₹ 4,25,000 - 9,00,000 P.A.</p>
                                                <p className="text-muted mb-0" style={{ color: "#0080ff !important" }}><i className="bx bx-street-view  font-size-16 align-middle me-1"></i> Noida( Sector-16A Noida )</p>
                                                <p className="text-muted mb-0"><span style={{ color: "#f46a6a" }}>Posted </span> 14 days ago </p>
                                            </td>
                                            <td>
                                                <h5 className="font-size-14 mb-1"><a href="javascript:void(0);" className="text-dark">Candidates Applied (13)</a></h5>
                                                <p className="text-muted mb-0"><a href="javascript:void(0);" className="text-dark"><span style={{ color: "#009999" }}>Rejected (12) </span></a> <br />
                                                    <a href="javascript:void(0);" className="text-dark"><span style={{ color: "#008040" }}>Shortlisted (2)</span></a>
                                                    <br />
                                                    <a href="javascript:void(0);" className="text-dark"><span style={{ color: "#ff00ff" }}>Candidates Hold (23)</span></a>
                                                </p>
                                            </td>
                                            <td>
                                                <h5 className="font-size-14 mb-1"><a href="#" className="text-dark">Gyanwave</a></h5>
                                                <p className="text-muted mb-0" style={{ color: "#804000 !important" }}>GYANWAVE TECHNOLOGY PRIVATE LIMITED</p>
                                                <p className="text-muted mb-0" style={{ color: "#0080ff !important" }}> Website : https://gyanwave.com</p>

                                            </td>
                                            <td>


                                                <button type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop41" className="btn btn-info waves-effect btn-label waves-light"><i className="bx bx-street-view label-icon "></i> View Details </button>

                                                <div className="modal fade" id="staticBackdrop41" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title" id="staticBackdropLabel">Job Information</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>

                                                            <div className="modal-body">
                                                                <p>
                                                                </p><table className="table table-striped mb-0">
                                                                    <tbody>

                                                                        <tr>
                                                                            <td>Job description</td>
                                                                            <td>Understand requirements &amp; functional specifications<br />Develop web applications, dashboards,APIs<br />Troubleshoot, test, maintain code &amp; its document<br />Analyse, design, code, debug, test, document &amp; deploy changes<br />Ensure foolproof performance of deliverable</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Required Candidate profile</td>
                                                                            <td>Degree in B.TECH, BCA or equivalent<br />Excellent command on PHP/MySQL with Laravel Framework<br />Experienced in Vue, Laravel, Jquery &amp; AJAX<br />Good OOP knowledge<br />API based development knowledge is must</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Role</td>
                                                                            <td>Software Development - Other</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Industry Type</td>
                                                                            <td>Advertising &amp; Marketing</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Functional Area</td>
                                                                            <td>Engineering - Software</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Employment Type</td>
                                                                            <td>Full Time, Permanent</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Role Category</td>
                                                                            <td>Software Development</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Education</td>
                                                                            <td>UG : BCA in Any Specialization, B.Tech/B.E. in Any Specialization, Other Graduate</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Key Skills</td>
                                                                            <td>Mysql Database, Web Application Development, JQuery<br /> Laravel, Laravel Php, Vue.Js, Laravel, Php<br /> Framework, MySQL, Javascript, PHP Vue Framework, API<br /> Web Development, Ajax</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                                <p></p>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>

                                            <td>
                                                <ul className="list-inline font-size-20 contact-links mb-0">
                                                    <li className="list-inline-item px-2">
                                                        <a href="javascript: void(0);" title="View Client" data-bs-toggle="modal" data-bs-target="#staticBackdrop111"><i className="bx bxs-zoom-in"></i></a>
                                                    </li>
                                                    <li className="list-inline-item px-2">
                                                        <a href="javascript: void(0);" title="Edit User"><i className="bx bx-edit"></i></a>
                                                    </li>
                                                    <li className="list-inline-item px-2">
                                                        <a href="javascript: void(0);" style={{ color: "red" }} title="Delete User"><i className="bx bx-trash"></i></a>
                                                    </li>
                                                </ul>


                                            </td>
                                        </tr> */}

                                    </tbody>
                                </table>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <Link to={constant.component.totaljobs.url} className="btn btn-primary waves-effect btn-label waves-light"><i className="bx bxs-right-arrow-square label-icon"></i> View All Jobs</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </React.Fragment>
    )
}
