import React from 'react'
import { useState } from 'react';
import Pagination from 'react-js-pagination';
import moment from 'moment';
import Loader from '../../common/Loader';
import constant from '../../../constant';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toTitleCase } from '../../../utils';
import { AddStatusUpdate } from '../../../actions/utilitiesAction';
import queryString from 'query-string'

export default function InterviewQuestionList(props) {

    const [currentPage, setCurrentPage] = useState(1)
    const location = useLocation();
    const navigate = useNavigate()


    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        navigate(location.pathname + '?p=' + pageNumber)
        props.setPage(pageNumber)
    };

    const updateStatus = (INTERVIEW_QUESTIONS_ID, STATUS) => {
        const model = {
            INTERVIEW_QUESTIONS_ID: INTERVIEW_QUESTIONS_ID,
            STATUS: STATUS == 'U' ? 'A' : 'U'
        }
        props.setPage(currentPage)
        props.updateStatus(model)
    }

    const qParams = queryString.parse(location.search)

    return (
        <React.Fragment>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">

                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0" style={{ fontSize: "18px" }}>Shared Interview Questions</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="javascript: void(0);">Utilities</a></li>
                                            <li className="breadcrumb-item active">|</li>
                                            <li className="breadcrumb-item active">Interview Questions</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table align-middle table-wrap table-hover">
                                              
                                                {!props.showLoader && <thead className="table-light">
                                                    <tr>
                                                        <th scope="col">S.No.</th>
                                                        <th scope="col" style={{ width: "30%" }}>Candidate Details</th>
                                                        <th scope="col" style={{ width: "25%" }}>Company Name</th>
                                                        <th scope="col">Questions</th>
                                                        <th scope="col">Added On</th>
                                                        <th scope="col">Status</th>
                                                        {/* <th scope="col">Action</th> */}
                                                    </tr>
                                                </thead>}
                                                <tbody>
                                                    {props.showLoader ? <Loader /> : props.list && props.list.length > 0 &&
                                                        props.list.map((item, index, list) => {
                                                            return (
                                                                <tr>
                                                                    <td> {10 * currentPage + (index + 1) - 10}</td>
                                                                    <td>
                                                                        <p className="text-primary mb-0" style={{ textTransform: "capitalize" }}>{item.CREATED ? item.CREATED.CANDIDATE_NAME : 'Admin'}</p>
                                                                        <p className="text-muted mb-0"><strong>Skill: </strong>{toTitleCase(item.SKILL.toString().replace(/,/g, ', '))}</p>

                                                                        <p className="text-muted mb-0"><strong>ROLE: </strong>{toTitleCase(item.ROLES.toString().replace(/,/g, ', '))}</p>
                                                                    </td>
                                                                    <td>{item.COMPANIES}</td>
                                                                    {
                                                                        <td>
                                                                            <Link style={{ cursor: "pointer" }}
                                                                                to={{
                                                                                    pathname: constant.component.shareInterviewAnswer.url.replace(':id', item.INTERVIEW_QUESTIONS_ID),
                                                                                    state: [{ INTERVIEW_QUESTIONS_ID: item.INTERVIEW_QUESTIONS_ID }]
                                                                                }}
                                                                            // to={constant.component.interviewAnswer.url}
                                                                            >Questions <span className="badge rounded-pill bg-danger">{item.ANSWER_COUNT}</span></Link> </td>
                                                                    }
                                                                    <td>
                                                                        {moment(item.MODIFIED_ON).format('DD.MM.YYYY on hh:mma')}
                                                                    </td>
                                                                    <td>
                                                                        {item.STATUS == 'U' ?
                                                                            <button type="button" className="btn btn-success"
                                                                                onClick={() => updateStatus(item.INTERVIEW_QUESTIONS_ID, item.STATUS)} data-toggle="button"
                                                                                aria-pressed="false" autocomplete="off">
                                                                                Approved
                                                                            </button>
                                                                            :
                                                                            <button type="button" className="btn btn-warning" onClick={() => {
                                                                                updateStatus(item.INTERVIEW_QUESTIONS_ID, item.STATUS)
                                                                            }} data-toggle="button" aria-pressed="false" autocomplete="off">
                                                                                Pending
                                                                            </button>
                                                                        }
                                                                    </td>
                                                                    {/* <td>
                                                                        <ul className="list-inline contact-links mb-0" style={{ fontSize: "20px" }}>
                                                                            <li className="list-inline-item px-2">
                                                                                <Link to={constant.component.editShareInterviewQuestion.url.replace(':id', item.INTERVIEW_QUESTIONS_ID)} title="Edit User"><i className="bx bx-edit"></i></Link>
                                                                            </li>
                                                                            
                                                                        </ul>
                                                                    </td> */}
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        <>
                                                            {
                                                                props.list && props.list.length == 0 && !props.showLoader &&
                                                                <td colSpan={10} className="text-center text-danger pt-3">No Interview Question Record Found</td>
                                                            }
                                                        </>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <ul className="pagination pagination-rounded justify-content-center mt-4">
                                                    <Pagination
                                                        activePage={qParams?.p ? +qParams?.p : currentPage}
                                                        itemsCountPerPage={10}
                                                        totalItemsCount={props.count}
                                                        pageRangeDisplayed={5}
                                                        onChange={handlePageChange}
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                    />
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
