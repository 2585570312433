import React, { useEffect, useState } from 'react'
import AddAddressProoftype from '../../component/Employers/AddAddressProofType'
import constant from '../../constant';
import { AddressproofTypeById, updateAddressproofType } from '../../actions/employer'
import { useNavigate, useParams } from 'react-router-dom';
export default function AddAddressProofType() {

    const [response, setResponse] = React.useState({})
    const [showMsg, setShowMsg] = React.useState(false)

    const [data, setData] = useState(null)

    const params = useParams()

    const navigation = useNavigate();

    useEffect(() => {
        document.title = constant.title.EditAddressProofType;
        AddressproofTypeById(params.id).then(res => {
            if (res.status) {
                setData(res.result)
            }
            else {
                alert(res.error)
            }
        }).catch(err => {
            alert(err)
        })

    }, [])


    const onSubmit = (model) => {
        updateAddressproofType(model).then(res => {
            if (res.status) {
                navigation(constant.component.addressprooftypelist.url)
            }
            else {
                setResponse(res)
                setShowMsg(true)
                setTimeout(() => {
                    setShowMsg(false)
                }, 7000)
            }
        })
            .catch(err => {
                alert(err)
            })
    }

    return (
        <React.Fragment>
            {data && <AddAddressProoftype onSubmit={onSubmit} detail={data} response={response} showMsg={showMsg} />}
        </React.Fragment>
    )
}
