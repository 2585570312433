import React, { useEffect, useState } from 'react'
import { CityListDropdown, functionalAreaDropdown, genderDropdown, RoleDropdown } from '../../actions/CandidateAction'
import { getIndustryListsForJobs } from '../../actions/JobsAction'
import { educationType, totalcandidatesList } from '../../actions/ProfileAction'
import { activeSkillList } from '../../actions/utilitiesAction'
import Totalcandidates from '../../component/Menu/TotalCandidates'
import constant from '../../constant'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'

export default function TotalCandidates() {

  const [list, setList] = useState([])
  const [skill, setSkills] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0)
  const [showLoader, setShowLoader] = useState(false)
  const [KEYWORD, setKeyword] = useState('')
  const [expYear, setExpYear] = useState('')
  const [education, setEducation] = useState('')
  const [educationtype, setEducationType] = useState('')
  const [Skilltype, setSkillType] = useState('')
  const [dateFrom, setDateFrom] = useState('')
  const [dateTo, setDateTo] = useState('')
  const [gender, setGender] = useState('')
  const [genderList, setGenderList] = useState([])
  const [cast, setCast] = useState('')
  const [age, setAge] = useState('')
  const [location, setLocation] = useState('')
  const [locationList, setLocationList] = useState([])
  const [pincode, setPincode] = useState('')
  const [PhysicallyDisabled, setPhysicallyDisabled] = useState('')
  const [role, setRole] = useState('')
  const [roleList, setRoleList] = useState([])
  const [industry, setIndustry] = useState('')
  const [industryList, setIndustryList] = useState([])
  const [salary, setSalary] = useState('')

  
const locations = useLocation()

  useEffect(() => {
    const qParams = queryString.parse(locations.search)
    document.title = constant.title.TotalCandidates
    totalCandidatesLists(qParams?.p ? qParams?.p :currentPage)
    educationtypes()
    Skilltypes()
    getGender()
    getIndustryListForJob()
    getLocation()
  }, [currentPage])

  

  const handleReset = (e) => {
    e.preventDefault();
    setShowLoader(true)
    window.location.reload(false);
    totalCandidatesLists( )
    setKeyword('')
    setExpYear('')
    setAge('')
    setGender('')
    setEducation('')
    setLocation('')
    setPincode('')
    setSalary('')
    setCast('')
    setPhysicallyDisabled('')
    setIndustry('')
    setRole('')
    setDateFrom('')
    setDateTo('')
    setSkills('')
  }

  const totalCandidatesLists = (pageNumber = 1) => {
    

    if (pageNumber == 1) {
     
    }
    setShowLoader(true);
    totalcandidatesList({
      pageNumber: pageNumber,
      KEYWORD: KEYWORD,
      skill: skill,
      age: age,
      year_exp: expYear,
      education: education,
      dateFrom: dateFrom,
      dateTo: dateTo,
      gender: gender,
      salary: salary,
      location: location,
      pincode: pincode,
      cast: cast,
      physical: PhysicallyDisabled,
      industry: industry,
      jobrole: role,
    }).then((res) => {
      if (res.status) {
        setList(res.result.list)
        setCount(res.result.count)
        setShowLoader(false)
      }
    }).catch(err => {
      alert(err);
      setShowLoader(false);

    })
  }

  const educationtypes = () => {
    educationType().then((res) => {
      if (res.status) {
        setEducationType(res.result)
      }
    }).catch(err => {
      alert(err)
    })
  }

  const Skilltypes = () => {
    activeSkillList().then((res) => {
      if (res.status) {
        setSkillType(res.result)
      }
    }).catch(err => {
      alert(err)
    })
  }

  const getGender = () => {
    genderDropdown().then((res) => {
      if (res.result) {
        setGenderList(res.result)
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  const getIndustryListForJob = () => {
    functionalAreaDropdown()
      .then((res) => {
        if (res.status) {
          setIndustryList(res.result);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getRole = (e) => {
    RoleDropdown({ RoleCategory: e }).then((res) => {
      if (res.status) {
        setRoleList(res.result)
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  const getLocation = () => {
    CityListDropdown().then((res) => {
      if (res.status) {
        setLocationList(res.result)
      }
    }).catch((err) => {
      console.log(err)
    })
  }




  return (
    <React.Fragment>
      <Totalcandidates

        list={list}
        totalCandidatesLists={(page => totalCandidatesLists(page))}
        count={count}
        setShowLoader={setShowLoader}
        showLoader={showLoader}
        setPage={setCurrentPage}
        KEYWORD={KEYWORD}
        setKeyword={setKeyword}
        setEducation={setEducation}
        education={education}
        handleReset={handleReset}
        setExpYear={setExpYear}
        expYear={expYear}
        setDateTo={setDateTo}
        dateTo={dateTo}
        setDateFrom={setDateFrom}
        dateFrom={dateFrom}
        states={setKeyword}
        setSkills={(val) => {setSkills([...skill, ...val])}}
        educationtype={educationtype}
        Skilltype={Skilltype}
        gender={gender}
        genderList={genderList}
        setGender={setGender}
        industryList={industryList}
        industry={industry}
        setIndustry={setIndustry}
        getRole={getRole}
        roleList={roleList}
        setRole={setRole}
        role={role}
        locationList={locationList}
        setLocation={(location)=>setLocation(location)}
        location={location}
        pincode={pincode}
        setPincode={setPincode}
        cast={cast}
        setCast={setCast}
        age={age}
        setAge={setAge}
        setPhysicallyDisabled={setPhysicallyDisabled}
        PhysicallyDisabled={PhysicallyDisabled}
        setSalary={setSalary}
        salary={salary}
      />
    </React.Fragment>
  )
}
