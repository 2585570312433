import React from 'react'
import { useEffect, useState } from "react";
import constant from '../../../constant';
import moment from 'moment';
import Pagination from 'react-js-pagination';
import ReactReadMoreReadLess from "react-read-more-read-less";
import Loader from '../../common/Loader';
import { Link } from 'react-router-dom';

function ArticleList(props) {

    const [currentPage, setCurrentPage] = useState(1);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        props.setPage(pageNumber)
    };

    const updateStatus = (ARTICLE_ID, STATUS) => {

        console.log(STATUS, ARTICLE_ID);

        const model = {
            ARTICLE_ID: ARTICLE_ID,
            STATUS: STATUS === 'Y' ? 'N' : 'Y'
        }

        props.setPage(currentPage)
        props.updateStatus(model)
    }

    // useEffect(() => {
    //     document.title = constant.title.ListArticle
    // }, [])

    return (
        <React.Fragment>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 font-size-18">Article List</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="javascript:void(0);">Article</a></li>
                                            <li className="breadcrumb-item active">Article List</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xl-9 col-lg-8">
                                <div className="card">
                                    {/* <ul className="nav nav-tabs nav-tabs-custom justify-content-center pt-2" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" data-bs-toggle="tab" href="#all-post" role="tab">
                                                All Articles
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-bs-toggle="tab" href="#archive" role="tab">
                                                Archive
                                            </a>
                                        </li>
                                    </ul> */}

                                    <div className="tab-content p-4">
                                        <div className="tab-pane active" id="all-post" role="tabpanel">
                                            <div>
                                                <div className="row justify-content-center">
                                                    {
                                                        props.showLoader && <Loader />
                                                    }
                                                    <div className="col-xl-8">
                                                        <div>

                                                            {/* <div className="row align-items-center">
                                                                <div className="col-4">
                                                                    <div>
                                                                        <h5 className="mb-0">Article List</h5>
                                                                    </div>
                                                                </div>

                                                                <div className="col-8">
                                                                    <div>
                                                                        <ul className="nav nav-pills justify-content-end">
                                                                            <li className="nav-item">
                                                                                <a className="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">View :</a>
                                                                            </li>
                                                                            <li className="nav-item" data-bs-placement="top" title="List">
                                                                                <a className="nav-link active" href="#">
                                                                                    <i className="mdi mdi-format-list-bulleted"></i>
                                                                                </a>
                                                                            </li>
                                                                            <li className="nav-item" data-bs-placement="top" title="Grid">
                                                                                <a className="nav-link" href="#">
                                                                                    <i className="mdi mdi-view-grid-outline"></i>
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>

                                                            </div> */}

                                                            {/* <hr className="mb-4" /> */}


                                                            {
                                                                props.list && props.list.length > 0 && props.list.map((ele, index) => {
                                                                    const articlePicture = `${process.env.REACT_APP_BASE_URL}/article/Picture/${ele.PICTURE}`
                                                                    return (
                                                                        <div className='hrline'>
                                                                            {/* <h5><a href="#" className="text-dark">{ele.ARTICLE}</a></h5>
                                                                            <p className="text-muted">{moment(ele.CREATED_ON).format('DD MMM, YYYY')}</p> */}
                                                                            <div style={{ display: "flex", justifyContent: "space-between", }} >
                                                                                <div >
                                                                                    <h5><a className="text-dark">{ele.ARTICLE}</a></h5>
                                                                                </div>

                                                                                <div style={{ fontSize: "20px" }} >
                                                                                    <Link to={constant.component.editarticle.url.replace(':id', ele.ARTICLE_ID)} title="Edit Blog"><i className="bx bx-edit"></i></Link>
                                                                                </div>

                                                                            </div>





                                                                            <div style={{ display: "flex", justifyContent: "space-between", }} >
                                                                                <div >
                                                                                    <p className="text-muted">{moment(ele.CREATED_ON).format('DD MMM, YYYY')}</p>

                                                                                </div>

                                                                                <div style={{ fontSize: "20px" }} >
                                                                                    <a href="javascript: void(0)" onClick={() => updateStatus(ele.ARTICLE_ID, ele.STATUS)} style={{ color: 'red' }} title={ele.STATUS == 'Y' ? "Archive Record" : "Unarchive Record"}>
                                                                                        {
                                                                                            ele.STATUS == 'Y' ? <i className="bx bx-trash"></i> : <i class="bx bx-log-in"></i>
                                                                                        }
                                                                                    </a>
                                                                                </div>

                                                                            </div>


                                                                            <div className="position-relative mb-3">
                                                                                <img src={`${process.env.REACT_APP_BASE_URL}/admin/article/image/${ele.PICTURE}`} alt="" className="img-thumbnail" />
                                                                            </div>

                                                                            <ul className="list-inline">
                                                                                <li className="list-inline-item me-3">
                                                                                    <a href="javascript: void(0);" className="text-muted">
                                                                                        <i className="bx bx-purchase-tag-alt align-middle text-muted me-1"></i>{ele.ARTICLE_CATEGORY}
                                                                                    </a>
                                                                                </li>
                                                                                {/* <li className="list-inline-item me-3">
                                                                                    <a href="javascript: void(0);" className="text-muted">
                                                                                        <i className="bx bx-comment-dots align-middle text-muted me-1"></i> 12 Comments
                                                                                    </a>
                                                                                </li> */}
                                                                            </ul>
                                                                            {/* <p>{ele.ARTICLE_DETAILS}</p> */}
                                                                            <ReactReadMoreReadLess
                                                                                charLimit={100}
                                                                                readMoreText={<a href="javascript: void(0);" className="text-primary">  Read more   <i className="mdi mdi-arrow-right" /></a>}
                                                                                readLessText={<a href="javascript: void(0);" className="text-primary"> <i className="mdi mdi-arrow-left" /> Read Less   </a>}
                                                                                readMoreClassName="read-more-less--more"
                                                                                readLessClassName="read-more-less--less" >
                                                                                {ele.ARTICLE_DETAILS}
                                                                            </ReactReadMoreReadLess>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                            {
                                                                <>
                                                                    {
                                                                        props.list && props.list.length == 0 && props.showLoader == false &&
                                                                        <td colSpan={10} className='text-center text-danger pt-3 d-block'>No Record Found</td>
                                                                    }
                                                                </>
                                                            }
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <ul className="pagination pagination-rounded justify-content-center mt-4">
                                                                        <Pagination
                                                                            activePage={currentPage}
                                                                            itemsCountPerPage={10}
                                                                            totalItemsCount={props.count}
                                                                            pageRangeDisplayed={5}
                                                                            onChange={handlePageChange}
                                                                            itemClass="page-item"
                                                                            linkClass="page-link"
                                                                        />
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-4">
                                <div className="card">
                                    <div className="card-body p-4">
                                        {/* <div className="search-box">
                                            <p className="text-muted">Search</p>
                                            <div className="position-relative">
                                                <input type="text" className="form-control rounded bg-light border-light" placeholder="Search..." />
                                                <i className="mdi mdi-magnify search-icon"></i>
                                            </div>
                                        </div> */}

                                        {/* <hr className="my-4" /> */}

                                        <div>
                                            <p className="text-muted text-bold">Categories</p>


                                            <ul className="list-unstyled fw-medium">
                                                {
                                                    props.showLoader && <Loader />
                                                }
                                                {
                                                    props.categoryList && props.categoryList.length > 0 && props.categoryList.map((item, index) => {

                                                        return (
                                                            <li><a href="javascript: void(0);" className="text-muted py-2 d-block"><i className="mdi mdi-chevron-right me-1"></i> {item.CATEGORY}
                                                                {/* <span className="badge badge-soft-success rounded-pill float-end ms-1 font-size-12">04</span> */}
                                                            </a></li>
                                                        )

                                                    })
                                                }
                                                {
                                                    <>
                                                        {
                                                            props.categoryList && props.categoryList.length == 0 && props.showLoader == false &&
                                                            <td colSpan={10} className='text-center text-danger pt-3 d-block'>No Categories Available</td>
                                                        }
                                                    </>
                                                }

                                            </ul>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ArticleList