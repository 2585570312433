import React from 'react'

export default function CandidatesInformation() {
  return (
    <React.Fragment>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Profile Information</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="https://wireframecp.jobzshala.com/">
                          Candiates
                        </a>
                      </li>
                      <li className="breadcrumb-item active">Profile Information</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* end page title */}
            {/* DOWNLOAD RESUME */}
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">GO TO</h4>
                    <div className="d-flex flex-wrap gap-2">
                      {/* <button type="button" onclick="javascript:document.location.href='#summary'" class="btn btn-light btn-sm waves-effect waves-light"><i class="bx bx-chevrons-right"></i> SUMMARY</button>
											<button type="button" onclick="javascript:document.location.href='#resume'"  class="btn btn-light btn-sm waves-effect waves-light"><i class="bx bx-chevrons-right"></i> RESUME</button> */}
                      <button
                        type="button"
                        onclick="javascript:document.location.href='#headline'"
                        className="btn btn-light btn-sm waves-effect waves-light"
                      >
                        <i className="bx bx-chevrons-right" /> RESUME HEADLINE
                      </button>
                      <button
                        type="button"
                        onclick="javascript:document.location.href='#keyskills'"
                        className="btn btn-light btn-sm waves-effect waves-light"
                      >
                        <i className="bx bx-chevrons-right" /> KEY SKILLS
                      </button>
                      <button
                        type="button"
                        onclick="javascript:document.location.href='#employment'"
                        className="btn btn-light btn-sm waves-effect waves-light"
                      >
                        <i className="bx bx-chevrons-right" /> EMPLOYMENT
                      </button>
                      <button
                        type="button"
                        onclick="javascript:document.location.href='#education'"
                        className="btn btn-light btn-sm waves-effect waves-light"
                      >
                        <i className="bx bx-chevrons-right" /> EDUCATION
                      </button>
                      <button
                        type="button"
                        onclick="javascript:document.location.href='#itskills'"
                        className="btn btn-light btn-sm waves-effect waves-light"
                      >
                        <i className="bx bx-chevrons-right" /> IT SKILLS
                      </button>
                      <button
                        type="button"
                        onclick="javascript:document.location.href='#projects'"
                        className="btn btn-light btn-sm waves-effect waves-light"
                      >
                        <i className="bx bx-chevrons-right" /> PROJECTS
                      </button>
                      <button
                        type="button"
                        onclick="javascript:document.location.href='#onlineprofile'"
                        className="btn btn-light btn-sm waves-effect waves-light"
                      >
                        <i className="bx bx-chevrons-right" /> ONLINE PROFILE
                      </button>
                      <button
                        type="button"
                        onclick="javascript:document.location.href='#worksample'"
                        className="btn btn-light btn-sm waves-effect waves-light"
                      >
                        <i className="bx bx-chevrons-right" /> WORK SAMPLE
                      </button>
                      <button
                        type="button"
                        onclick="javascript:document.location.href='#presentation'"
                        className="btn btn-light btn-sm waves-effect waves-light"
                      >
                        <i className="bx bx-chevrons-right" /> PRESENTATION
                      </button>
                      <button
                        type="button"
                        onclick="javascript:document.location.href='#certification'"
                        className="btn btn-light btn-sm waves-effect waves-light"
                      >
                        <i className="bx bx-chevrons-right" /> CERTIFICATION
                      </button>
                      <button
                        type="button"
                        onclick="javascript:document.location.href='#careerprofile'"
                        className="btn btn-light btn-sm waves-effect waves-light"
                      >
                        <i className="bx bx-chevrons-right" /> CAREER PROFILE
                      </button>
                      <button
                        type="button"
                        onclick="javascript:document.location.href='#personaldetails'"
                        className="btn btn-light btn-sm waves-effect waves-light"
                      >
                        <i className="bx bx-chevrons-right" /> PERSONAL DETAILS
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* end col */}
            </div>
            {/* DOWNLOAD RESUME END */}
            {/* BASIC INFO */}
            <div className="row" id="summary">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-5">
                        <div className="d-flex">
                          <div className="flex-shrink-0 me-3">
                            <img
                              src="assets/images/users/avatar-1.jpg"
                              alt=""
                              className="avatar-md rounded-circle img-thumbnail"
                            />
                          </div>
                          <div className="flex-grow-1 align-self-center">
                            <div className="text-muted">
                              <h5 className="mb-1">Supriya Pathak</h5>
                              <p className="mb-0">
                                UI / UX Designer at Value Innovation Labs
                              </p>
                              <table cellSpacing="2px" cellPadding="2px">
                                <tbody>
                                  <tr>
                                    <td colSpan={10} style={{ height: 10 }} />
                                  </tr>
                                  <tr>
                                    <td style={{ paddingRight: 42 }}>
                                      <i className="bx bx bxs-map" />{" "}
                                      <span style={{ color: "#004e9b" }}>
                                        Noida, India
                                      </span>{" "}
                                    </td>
                                    <td>&nbsp;</td>
                                    <td style={{ color: "#004e9b" }}>
                                      <i className="bx bxs-envelope" />{" "}
                                      satyaprakash@gmail.com{" "}
                                      <i
                                        style={{ color: "green", fontSize: 15 }}
                                        className="bx bx-check-circle"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ paddingRight: 42 }}>
                                      <i className="bx bx-briefcase" />{" "}
                                      <span style={{ color: "#004e9b" }}>
                                        12 Years
                                      </span>{" "}
                                    </td>
                                    <td>&nbsp;</td>
                                    <td>
                                      <i className="bx bxs-phone" /> 99998891234{" "}
                                      <i
                                        style={{ color: "green", fontSize: 15 }}
                                        className="bx bx-check-circle"
                                        title="Verified"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <i className="bx bxs-wallet" /> 10 Lac
                                    </td>
                                    <td>&nbsp;</td>
                                    <td>
                                      <i className="bx bxs-microphone" />{" "}
                                      <span style={{ color: "#004e9b" }}>
                                        English, Hindi
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-5 align-self-center">
                        <div className="text-lg-center mt-4 mt-lg-0">
                          <div className="row">
                            <div className="col-4">
                              <div>
                                <p className="text-muted text-truncate mb-2">
                                  Job Applied
                                </p>
                                <h5 className="mb-0">48</h5>
                              </div>
                            </div>
                            <div className="col-4">
                              <div>
                                <p className="text-muted text-truncate mb-2">
                                  Shortlisted
                                </p>
                                <h5 className="mb-0">40</h5>
                              </div>
                            </div>
                            <div className="col-4">
                              <div>
                                <p className="text-muted text-truncate mb-2">
                                  Rejected
                                </p>
                                <h5 className="mb-0">8</h5>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <div>
                                <p
                                  className="text-muted text-left mb-2"
                                  style={{
                                    textAlign: "left",
                                    marginTop: 17,
                                    color: "#556ee6 !important"
                                  }}
                                >
                                  Profile Strength (Excellent) - 67%
                                </p>
                                <div className="progress">
                                  <div
                                    className="progress-bar bg-danger progress-bar-striped progress-bar-animated"
                                    role="progressbar"
                                    aria-valuenow={75}
                                    aria-valuemin={0}
                                    aria-valuemax={100}
                                    style={{ width: "75%" }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 d-none d-lg-block">
                        <div className="clearfix mt-4 mt-lg-0">
                          <div className="dropdown float-end">
                            <button
                              className="btn btn-primary"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i className="bx bxs-cog align-middle me-1" /> Action
                            </button>
                            <div className="dropdown-menu dropdown-menu-end">
                              <a className="dropdown-item" href="#">
                                <i className="mdi mdi-email" /> Send Mail
                              </a>
                              <a className="dropdown-item" href="#">
                                <i className="mdi mdi-note-plus-outline" /> Add Note
                              </a>
                              <a className="dropdown-item" href="#">
                                <i className="mdi mdi-trash-can" /> Archive Profile
                              </a>
                              <a className="dropdown-item" href="#">
                                <i className="mdi mdi-download" /> Download Resume
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* end row */}
                  </div>
                </div>
              </div>
            </div>
            {/* SEARCH */}
            {/* DOWNLOAD RESUME */}
            <div className="row" id="resume">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Resume Attachment</h4>
                    <p className="card-title-desc">
                      Resume is the most important document recruiters look for.
                      Recruiters generally do not look at profiles without resumes.
                      Here is attached resume by Candidate.
                    </p>
                    <div className="d-flex flex-wrap gap-2">
                      {/* Header */}
                      <button
                        type="button"
                        className="btn btn-warning waves-effect btn-label waves-light"
                      >
                        <i
                          style={{ fontSize: 20 }}
                          className="bx bx-cloud-download label-icon"
                        />{" "}
                        Download Resume
                      </button>
                    </div>
                    <p style={{ marginTop: 10 }} className="text-muted mb-0">
                      Supriya Pathak - Resume.pdf -{" "}
                      <span style={{ color: "#ff0080" }}>
                        Uploaded on Jan 31, 2022
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              {/* end col */}
            </div>
            {/* DOWNLOAD RESUME END */}
            {/* RESUME HEADLINE */}
            <div className="row" id="headline">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Resume Headline </h4>
                    <p className="card-title-desc">
                      10+ years of solid experience with specialization in Designing,
                      Graphic Designing, AWS Solution Architect &amp; Cloud
                      Practitioner, PMP, BSMM IIT Delhi, CSM, CSPO &amp; Google
                      Certified Servant Leader with experience in Developing &amp;
                      Managing Cross-Functional Application.
                    </p>
                  </div>
                </div>
              </div>
              {/* end col */}
            </div>
            {/* RESUME HEADLINE END */}
            {/* KEY SKILLS */}
            <div className="row" id="keyskills">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Key Skills </h4>
                    <div className="d-flex flex-wrap gap-2">
                      <button type="button" className="btn btn-light">
                        Project Management
                      </button>
                      <button type="button" className="btn btn-light">
                        IT Head
                      </button>
                      <button type="button" className="btn btn-light">
                        IT Director
                      </button>
                      <button type="button" className="btn btn-light">
                        LAMP
                      </button>
                      <button type="button" className="btn btn-light">
                        Animation
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* end col */}
            </div>
            {/* KEY SKILLS END */}
            {/* EMLOYEMENT */}
            <div className="row" id="employment">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title mb-5">Employment</h4>
                    <div className="">
                      <ul className="verti-timeline list-unstyled">
                        <li className="event-list active">
                          <div className="event-timeline-dot ">
                            <i className="bx bx-right-arrow-circle bx-fade-right" />
                          </div>
                          <div className="d-flex">
                            <div className="flex-shrink-0 me-3">
                              <i className="bx bx-copy-alt h2 text-primary" />
                            </div>
                            <div className="flex-grow-1">
                              <div>
                                <h5>Asst. Project Manager</h5>
                                <p
                                  className="text-muted"
                                  style={{ marginBottom: "5px !important" }}
                                >
                                  Value Innovation Labs
                                </p>
                                <p style={{ marginBottom: "5px !important" }}>
                                  Feb 2022 to Present (4 months)
                                </p>
                                <p
                                  style={{
                                    marginBottom: "5px !important",
                                    color: "#f46b6b"
                                  }}
                                >
                                  Available to join in 1 Months
                                </p>
                                <p style={{ marginBottom: "5px !important" }}>
                                  Driving Technology Transformation and Enhancing
                                  Business Capabilities Head of Centre of
                                  Excellence(COE) Team along with Supervising the Tech
                                  Leaders Sr. Technology Leader offering 16+ years of
                                  expertise in leveraging cost-effective, cutting-edge
                                  solutions, Accelerate processes, Strengthen
                                  collaboration, Advance capabilities &amp; minimize
                                  cost. Working on AWS infrastructure and services
                                  within a managed service env maintaining enterprise
                                  class security, network and systems management
                                  applications within an AWS environment. Performing
                                  data migration from on premises environments into
                                  AWS and supporting the whole business development
                                  life-cycle. Designing major aspects of the
                                  architecture of an application, including components
                                  such as user interface, middleware and
                                  infrastructure. Providing technical leadership to
                                  the application development team. Performing design
                                  and code reviews. Savvy strategists who positions
                                  team and roadmaps to successfully executive
                                  initiatives, anticipating and translating business
                                  needs into robusts solutions and tools and driving
                                  adoptions. Build a culture of commitment,
                                  accountability and shared success across teams of up
                                  to 100+ members Revolutionised operations and
                                  mobilized workforce...
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle" />
                          </div>
                          <div className="d-flex">
                            <div className="flex-shrink-0 me-3">
                              <i className="bx bx-badge-check h2 text-primary" />
                            </div>
                            <div className="flex-grow-1">
                              <div>
                                <h5>Team Lead</h5>
                                <p
                                  className="text-muted"
                                  style={{ marginBottom: "5px !important" }}
                                >
                                  Scaler LLP
                                </p>
                                <p style={{ marginBottom: "5px !important" }}>
                                  Mar 2015 to Jul 2021 (6 years 6 months)
                                </p>
                                <p style={{ marginBottom: "5px !important" }}>
                                  Managing Team of 8+Technocrat &amp; assign roles and
                                  responsibility for projects - Responsible for
                                  Solution Designing with HLD and LLD and Developing
                                  &amp; Deploying N-tier-based applications. Analysis
                                  and Architecting of new business requirements along
                                  with impact analysis on system and task allocation
                                  to the team. - Managing Cloud Technologies like AWS
                                  VPC, EC2, Cloud Formation, S3, IAM, RDS, Route 53,
                                  CloudWatch, Dynamo DB and Elastic cache. - Handling
                                  all Critical &amp; Technical Issue - Project
                                  Planning, Execution &amp; Delivery Management -
                                  Worked on Critical Projects - Developing &amp;
                                  Managing Web Application on XHTML, HTML, JavaScript,
                                  SASS, PHP, MYSQL, MongoDB, PSQL, Python, Client-side
                                  scripting with ECMAScript 6, Drupal 8 and
                                  Breadcrumbs. - Database Design &amp; Administration
                                  - Client Meeting &amp; Interaction - Responsible for
                                  developing, executing and delivering the company?s
                                  digital / online marketing strategy, planning and
                                  budget to include online, new media, web, e-commerce
                                  and SEO to drive the business forward through key
                                  marketing channels. - Review technologies and
                                  explore new developments in the digital landscape to
                                  find ways to more effectively deliver the
                                  communication and business objectives - Responsible
                                  for improving the design, usability, content and
                                  conversion points of the company website and for the
                                  promotion and growth of the company?s web properties
                                  - Rectification of website architecture related
                                  issues like domain redirects, site code validations,
                                  breadcrumbs - Onsite Project Implementation &amp;
                                  Training - Special Government Projects - Team
                                  Training &amp; Knowledge Share
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle" />
                          </div>
                          <div className="d-flex">
                            <div className="flex-shrink-0 me-3">
                              <i className="bx bx-badge-check h2 text-primary" />
                            </div>
                            <div className="flex-grow-1">
                              <div>
                                <h5>Web &amp; Graphic Designer</h5>
                                <p
                                  className="text-muted"
                                  style={{ marginBottom: "5px !important" }}
                                >
                                  Forbes India
                                </p>
                                <p style={{ marginBottom: "5px !important" }}>
                                  Aug 2008 to Mar 2015 (6 years 8 months)
                                </p>
                                <p style={{ marginBottom: "5px !important" }}>
                                  Handling all Technical Issue Project Management
                                  Worked on Critical Projects Developing a websites on
                                  XHTML, HTML, JAVASCRIPT, PHP, MYSQL &amp; PSQL.
                                  Database Administration Client Interaction
                                  Responsible for developing, executing and delivering
                                  the company's digital / online marketing strategy,
                                  planning and budget to include online, new media,
                                  web, e-commerce and SEO to drive the business
                                  forward through key marketing channels. Review
                                  technologies and explore new developments in the
                                  digital landscape to find ways to more effectively
                                  deliver the communication and business objectives
                                  Responsible for improving the design, usability,
                                  content and conversion points of the company website
                                  and for the promotion and growth of the company's
                                  web properties Rectification of website architecture
                                  related issues like domain redirects, site code
                                  validations, breadcrumbs
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle" />
                          </div>
                          <div className="d-flex">
                            <div className="flex-shrink-0 me-3">
                              <i className="bx bx-badge-check h2 text-primary" />
                            </div>
                            <div className="flex-grow-1">
                              <div>
                                <h5>Jr. Graphic Designer</h5>
                                <p
                                  className="text-muted"
                                  style={{ marginBottom: "5px !important" }}
                                >
                                  L AND Q SURVEYS PRIVATE LIMITED
                                </p>
                                <p style={{ marginBottom: "5px !important" }}>
                                  Oct 2005 to Jun 2008 (2 years 9 months)
                                </p>
                                <p style={{ marginBottom: "5px !important" }}>
                                  Developing &amp; Managing a websites on XHTML, HTML,
                                  JAVASCRIPT, PHP, MYSQL &amp; PSQL. Digital Marketing
                                  Activity - Keywords Management Database
                                  Administration Client Interaction
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* EMPLOYMENT END */}
            {/* EDUCATION */}
            <div className="row" id="education">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Education </h4>
                    <div>
                      <h5 className="font-size-14 mb-1">
                        M.Tech - Software Systems - Data Analytics
                      </h5>
                      <p
                        className="text-muted"
                        style={{ marginBottom: "5px !important" }}
                      >
                        Shinghania University
                      </p>
                      <p style={{ marginBottom: "5px !important" }}>
                        2022 (Full Time)
                      </p>
                    </div>
                    <hr className="mt-3" />
                    <div>
                      <h5 className="font-size-14 mb-1">B.Tech/B.E. - Computers</h5>
                      <p
                        className="text-muted"
                        style={{ marginBottom: "5px !important" }}
                      >
                        Uttar Pradesh Technical University Lucknow
                      </p>
                      <p style={{ marginBottom: "5px !important" }}>
                        2005 (Full Time)
                      </p>
                    </div>
                    <hr className="mt-3" />
                    <div>
                      <h5 className="font-size-14 mb-1">
                        Sr. Secondary School (12th)
                      </h5>
                      <p
                        className="text-muted"
                        style={{ marginBottom: "5px !important" }}
                      >
                        Uttar Pradesh Board
                      </p>
                      <p style={{ marginBottom: "5px !important" }}>1999</p>
                    </div>
                    <hr className="mt-3" />
                    <div>
                      <h5 className="font-size-14 mb-1">Secondary School (10th)</h5>
                      <p
                        className="text-muted"
                        style={{ marginBottom: "5px !important" }}
                      >
                        Uttar Pradesh Board
                      </p>
                      <p style={{ marginBottom: "5px !important" }}>1997</p>
                    </div>
                  </div>
                </div>
              </div>
              {/* end col */}
            </div>
            {/* EDUCATION END */}
            {/* IT SKILLS */}
            <div className="row" id="itskills">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">IT Skills</h4>
                    <div className="table-responsive">
                      <table className="table mb-0">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Skills</th>
                            <th>Version</th>
                            <th>Last Time Used</th>
                            <th>Experience</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">1</th>
                            <td>WEB SERVICES</td>
                            <td>Latest</td>
                            <td>2022</td>
                            <td>14 Years 8 Months</td>
                          </tr>
                          <tr>
                            <th scope="row">2</th>
                            <td>Complete Open Source Core &amp; Complex Code</td>
                            <td>Latest</td>
                            <td>2022</td>
                            <td>14 Years 0 Month</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* IT SKILLS */}
            {/* PROJECTS */}
            <div className="row" id="projects">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Projects </h4>
                    <div>
                      <h5 className="font-size-14 mb-1">jobzshala.com</h5>
                      <p
                        className="text-muted"
                        style={{ marginBottom: "5px !important" }}
                      >
                        Value Innovation Labs (Offsite)
                      </p>
                      <p style={{ marginBottom: "5px !important" }}>
                        May 2022 to Present (Full Time)
                      </p>
                      <p style={{ marginBottom: "5px !important" }}>
                        Its Leading Job Portal with AI / ML enabled advance features
                        for Candidate to get better and best opportunities with
                        recommendation engine, fast searches, chatbot support, Resume
                        Searches, Astrology Services and lots more...
                      </p>
                    </div>
                    <hr className="mt-3" />
                    <div>
                      <h5 className="font-size-14 mb-1">HRMS JobzShala</h5>
                      <p
                        className="text-muted"
                        style={{ marginBottom: "5px !important" }}
                      >
                        Value Innovation Labs (Offsite)
                      </p>
                      <p style={{ marginBottom: "5px !important" }}>
                        May 2022 to Present (Full Time)
                      </p>
                      <p style={{ marginBottom: "5px !important" }}>
                        Its Leading Job Portal with AI / ML enabled advance features
                        for Candidate to get better and best opportunities with
                        recommendation engine, fast searches, chatbot support, Resume
                        Searches, Astrology Services and lots more...
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* end col */}
            </div>
            {/* PROJECTS END */}
            {/* Online Profile */}
            <div className="row" id="onlineprofile">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Online Profile </h4>
                    <div>
                      <h5 className="font-size-14 mb-1">LinkedIn</h5>
                      <p
                        className="text-muted"
                        style={{ marginBottom: "5px !important" }}
                      >
                        https://www.linkedin.com/in/supriya-pathak/
                      </p>
                      <p style={{ marginBottom: "5px !important" }}>LinkedIn</p>
                    </div>
                    <hr className="mt-3" />
                    <div>
                      <h5 className="font-size-14 mb-1">Facebook</h5>
                      <p
                        className="text-muted"
                        style={{ marginBottom: "5px !important" }}
                      >
                        https://www.facebook.com/supriya-pathak/
                      </p>
                      <p style={{ marginBottom: "5px !important" }}>Facebook</p>
                    </div>
                  </div>
                </div>
              </div>
              {/* end col */}
            </div>
            {/* Online Profile END */}
            {/* Work Sample */}
            <div className="row" id="worksample">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Work Sample </h4>
                    <div>
                      <h5 className="font-size-14 mb-1">
                        BSE Ebix - B2B Insurance Portal
                      </h5>
                      <p
                        className="text-muted"
                        style={{ marginBottom: "5px !important" }}
                      >
                        https://portal.bseebix.com/Login.aspx
                      </p>
                      <p style={{ marginBottom: "5px !important" }}>
                        <strong>Duration:</strong> Apr 2019 - Jul 2021
                      </p>
                      <p style={{ marginBottom: "5px !important" }}>
                        BSE Ebix Insurance Broking Private Limited ( BSE Ebix) is a
                        joint venture between India's leading stock exchange BSE and
                        one of leading insurance solution provider Ebix, Inc. (NASDAQ:
                        EBIX). Our Vision is to be the preferred Insurance Broking for
                        our Partner, Customers and insurance Company. BSE Ebix
                        believes that insurance plays an important role in protecting
                        organisations and individual aspirations. The goal is to
                        transform end-to-end sales and processing of Insurance in
                        India.
                      </p>
                    </div>
                    <hr className="mt-3" />
                    <div>
                      <h5 className="font-size-14 mb-1">
                        BSE Ebix - B2B Insurance Portal
                      </h5>
                      <p
                        className="text-muted"
                        style={{ marginBottom: "5px !important" }}
                      >
                        https://portal.bseebix.com/Login.aspx
                      </p>
                      <p style={{ marginBottom: "5px !important" }}>
                        <strong>Duration:</strong> Apr 2019 - Jul 2021
                      </p>
                      <p style={{ marginBottom: "5px !important" }}>
                        BSE Ebix Insurance Broking Private Limited ( BSE Ebix) is a
                        joint venture between India's leading stock exchange BSE and
                        one of leading insurance solution provider Ebix, Inc. (NASDAQ:
                        EBIX). Our Vision is to be the preferred Insurance Broking for
                        our Partner, Customers and insurance Company. BSE Ebix
                        believes that insurance plays an important role in protecting
                        organisations and individual aspirations. The goal is to
                        transform end-to-end sales and processing of Insurance in
                        India.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* end col */}
            </div>
            {/* Work Sample END */}
            {/* Presentation */}
            <div className="row" id="presentation">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Presentation </h4>
                    <div>
                      <h5 className="font-size-14 mb-1">Banner Design Work</h5>
                      <p
                        className="text-muted"
                        style={{ marginBottom: "5px !important" }}
                      >
                        https://www.slideshare.net/Slideshare/slideshare-is-joining-scribd-237760779
                      </p>
                      <p style={{ marginBottom: "5px !important" }}>
                        Like SlideShare, Scribd offers an endless supply of content
                        gems to feed your curiosity and advance your career. We look
                        forward to SlideShare and Scribd coming together as the
                        destination for some of the world's favorite content.
                      </p>
                    </div>
                    <hr className="mt-3" />
                    <div>
                      <h5 className="font-size-14 mb-1">Banner Design Work</h5>
                      <p
                        className="text-muted"
                        style={{ marginBottom: "5px !important" }}
                      >
                        https://www.slideshare.net/Slideshare/slideshare-is-joining-scribd-237760779
                      </p>
                      <p style={{ marginBottom: "5px !important" }}>
                        Like SlideShare, Scribd offers an endless supply of content
                        gems to feed your curiosity and advance your career. We look
                        forward to SlideShare and Scribd coming together as the
                        destination for some of the world's favorite content.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* end col */}
            </div>
            {/* Presentation END */}
            {/* Certification */}
            <div className="row" id="certification">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Certification </h4>
                    <div>
                      <h5 className="font-size-14 mb-1">
                        Google The Digital Garage UK Certified
                      </h5>
                      <p
                        className="text-muted"
                        style={{ marginBottom: "5px !important" }}
                      >
                        Google Inc
                      </p>
                      <p style={{ marginBottom: "5px !important" }}>
                        Valid from Jan '20
                      </p>
                    </div>
                    <hr className="mt-3" />
                    <div>
                      <h5 className="font-size-14 mb-1">
                        Business Strategy &amp; Marketing Management IIT Delhi (BSMM)
                      </h5>
                      <p
                        className="text-muted"
                        style={{ marginBottom: "5px !important" }}
                      >
                        Indian Institute of Technologies Delhi
                      </p>
                      <p style={{ marginBottom: "5px !important" }}>
                        Valid from Jan '20
                      </p>
                    </div>
                    <hr className="mt-3" />
                    <div>
                      <h5 className="font-size-14 mb-1">
                        Certified Scrum Product Owner® (CSPO®)
                      </h5>
                      <p
                        className="text-muted"
                        style={{ marginBottom: "5px !important" }}
                      >
                        Scrum Alliance
                      </p>
                      <p style={{ marginBottom: "5px !important" }}>
                        Valid from Jan '19
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* end col */}
            </div>
            {/* Certification END */}
            {/* Career Profile */}
            <div className="row" id="careerprofile">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Career Profile </h4>
                    <p />
                    <div className="row">
                      <div className="col-3">
                        <p
                          className="text-muted"
                          style={{ marginBottom: "5px !important" }}
                        >
                          Current Industry
                        </p>
                        <p className="">Software Product</p>
                      </div>
                      <div className="col-3">
                        <p
                          className="text-muted"
                          style={{ marginBottom: "5px !important" }}
                        >
                          Department
                        </p>
                        <p className="">Engineering - Software &amp; QA</p>
                      </div>
                      <div className="col-3">
                        <p
                          className="text-muted"
                          style={{ marginBottom: "5px !important" }}
                        >
                          Role Category
                        </p>
                        <p className="">Software Development</p>
                      </div>
                      <div className="col-3">
                        <p
                          className="text-muted"
                          style={{ marginBottom: "5px !important" }}
                        >
                          Job Role
                        </p>
                        <p className="">Head - Engineering</p>
                      </div>
                    </div>
                    <p />
                    <div className="row">
                      <div className="col-3">
                        <p
                          className="text-muted"
                          style={{ marginBottom: "5px !important" }}
                        >
                          Availability to Join
                        </p>
                        <p className="">Join Immediately</p>
                      </div>
                      <div className="col-3">
                        <p
                          className="text-muted"
                          style={{ marginBottom: "5px !important" }}
                        >
                          Desired Job Type
                        </p>
                        <p className="">Permanent</p>
                      </div>
                      <div className="col-3">
                        <p
                          className="text-muted"
                          style={{ marginBottom: "5px !important" }}
                        >
                          Desired Employment Type
                        </p>
                        <p className="">Full Time</p>
                      </div>
                      <div className="col-3">
                        <p
                          className="text-muted"
                          style={{ marginBottom: "5px !important" }}
                        >
                          Desired Shift
                        </p>
                        <p className="">Flexible</p>
                      </div>
                    </div>
                    <p />
                    <div className="row">
                      <div className="col-3">
                        <p
                          className="text-muted"
                          style={{ marginBottom: "5px !important" }}
                        >
                          Preferred Work Location
                        </p>
                        <p className="">Delhi / NCR, Noida, Bangalore/Bengaluru</p>
                      </div>
                      <div className="col-3">
                        <p
                          className="text-muted"
                          style={{ marginBottom: "5px !important" }}
                        >
                          Expected Salary
                        </p>
                        <p className="">INR 20 Lakh(s)</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* end col */}
            </div>
            {/* Career Profile END */}
            {/* Personal Details */}
            <div className="row" id="personaldetails">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Personal Details </h4>
                    <p />
                    <div className="row">
                      <div className="col-3">
                        <p
                          className="text-muted"
                          style={{ marginBottom: "5px !important" }}
                        >
                          Date of Birth
                        </p>
                        <p className="">10 Mar 1983</p>
                      </div>
                      <div className="col-3">
                        <p
                          className="text-muted"
                          style={{ marginBottom: "5px !important" }}
                        >
                          Permanent Address
                        </p>
                        <p className="">H-1414, Galaxy North Avenue 2, Gaur City 2</p>
                      </div>
                      <div className="col-3">
                        <p
                          className="text-muted"
                          style={{ marginBottom: "5px !important" }}
                        >
                          Gender
                        </p>
                        <p className="">Male</p>
                      </div>
                      <div className="col-3">
                        <p
                          className="text-muted"
                          style={{ marginBottom: "5px !important" }}
                        >
                          Area Pin Code
                        </p>
                        <p className="">201305</p>
                      </div>
                    </div>
                    <p />
                    <div className="row">
                      <div className="col-3">
                        <p
                          className="text-muted"
                          style={{ marginBottom: "5px !important" }}
                        >
                          Marital Status
                        </p>
                        <p className="">Married</p>
                      </div>
                      <div className="col-3">
                        <p
                          className="text-muted"
                          style={{ marginBottom: "5px !important" }}
                        >
                          Hometown
                        </p>
                        <p className="">NOIDA Extension</p>
                      </div>
                      <div className="col-3">
                        <p
                          className="text-muted"
                          style={{ marginBottom: "5px !important" }}
                        >
                          Category
                        </p>
                        <p className="">General</p>
                      </div>
                      <div className="col-3">
                        <p
                          className="text-muted"
                          style={{ marginBottom: "5px !important" }}
                        >
                          Work permit for USA
                        </p>
                        <p className="">Need H1 Visa</p>
                      </div>
                    </div>
                    <p />
                    <div className="row">
                      <div className="col-3">
                        <p
                          className="text-muted"
                          style={{ marginBottom: "5px !important" }}
                        >
                          Work permit of other country
                        </p>
                        <p className="">Canada, Dubai</p>
                      </div>
                      <div className="col-3">
                        <p
                          className="text-muted"
                          style={{ marginBottom: "5px !important" }}
                        >
                          Differently Abled
                        </p>
                        <p className="">None</p>
                      </div>
                    </div>
                    <p />
                    <div className="table-responsive">
                      <table className="table mb-0">
                        <thead>
                          <tr>
                            <th>Languages</th>
                            <th>Proficiency</th>
                            <th>Read</th>
                            <th>Write</th>
                            <th>Speak</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">English</th>
                            <td>Expert</td>
                            <td>
                              <i className="mdi mdi-check-all me-2" />
                            </td>
                            <td>
                              <i className="mdi mdi-check-all me-2" />
                            </td>
                            <td>
                              <i className="mdi mdi-check-all me-2" />
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Hindi</th>
                            <td>Expert</td>
                            <td>
                              <i className="mdi mdi-check-all me-2" />
                            </td>
                            <td>
                              <i className="mdi mdi-check-all me-2" />
                            </td>
                            <td>
                              <i className="mdi mdi-check-all me-2" />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              {/* end col */}
            </div>
            {/* Personal Details END */}
          </div>{" "}
          {/* container-fluid */}
        </div>
        {/* End Page-content */}

      </div>

    </React.Fragment>
  )
}
