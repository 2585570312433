import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { ShimmerText } from "react-shimmer-effects";

export default function ApexChart(props) {
    var options = {
        series: props.data.map(i => {
            return i.value
        }),
        options: {
            chart: {
                width: 380,
                type: 'pie',
            },
            labels: props.data.map(i => {
                return i.title
            }),
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        },
    };


    return (
        <React.Fragment>
            {props.data[0]['value'] == 0 || props.data[1]['value'] == 0 || props.data[2]['value'] == 0 ? <ShimmerText line={5} gap={30} /> :
                <div>
                    <div>
                        <h4 className="card-title ">
                            JOBZSHALA STATISTICS - LIFETIME
                        </h4>
                        <p>All Time Candidates, Jobs & Employers</p>

                        <div className="ms-auto">


                        </div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "80%",
                        }}
                    >
                        <div style={{ height: "100%", width: "94%" }}>


                            <Chart
                                options={options.options}
                                series={options.series}
                                type="pie"
                                width={"100%"}
                            />
                        </div>
                    </div></div>}
        </React.Fragment>
    )
}
