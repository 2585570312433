import React, { useEffect } from 'react';
import constant from '../../../constant';
import NumberFormat from 'react-number-format';
import { useState } from 'react';
import SuccessMessage from '../../common/SuccessMessage';
import ErrorMessage from '../../common/ErrorMessage';
import { useNavigate, useParams } from 'react-router-dom';


const AddDesignation = (props) => {
    const { id } = useParams()
    const { detail } = props
    const [error, setError] = useState(false)
    const Navigate = useNavigate()
    const params = useParams()

    const [data, setData] = useState({
        DESIGNATION: "",
        SORT_NUMBER: "",
        DESIGNATION_DETAILS: "",
    })


    const handleChange = (e) => {
        const { name, value } = e.target
        setData({
            ...data,
            [name]: value
        })
    }

    const onSubmit = (e) => {
        let pn = params.pageno
        e.preventDefault();
        if (data.DESIGNATION.trim().length == 0 || data.SORT_NUMBER.toString().trim().length == 0) {
            setError(true)
        }
        else {
            const model = {
                DESIGNATION: data.DESIGNATION,
                SORT_NUMBER: data.SORT_NUMBER,
                DESIGNATION_DETAILS: data.DESIGNATION_DETAILS,
            };

            if (detail) {
                model.DESIGNATION_ID = detail.DESIGNATION_ID
            }
            props.onSubmit(model)
            model.pageno = pn
        }
    }

    const handleReset = (e) => {
        setError(false)
        e.preventDefault();
        id && Navigate(constant.component.designationlist.url)
        setData(PrevState => ({
            ...PrevState,
            DESIGNATION: "",
            SORT_NUMBER: "",
            DESIGNATION_DETAILS: "",
        }))
    }
    useEffect(() => {
        (id ? document.title = constant.title.EditDesignation : document.title = constant.title.AddDesignation)
    }, [])

    useEffect(() => {
        if (detail) {
            setData({
                DESIGNATION: detail.DESIGNATION,
                SORT_NUMBER: detail.SORT_NUMBER,
                DESIGNATION_DETAILS: detail.DESIGNATION_DETAILS,
            })
        }

    }, [])

    return (
        <React.Fragment>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 " style={{ fontSize: '18px' }}>{id ? 'Update Designation' : 'Add Designation'}</h4>
                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="javascript: void(0);">Utilities</a></li>
                                            <li className="breadcrumb-item active">{id ? 'Update Designation' : 'Add Designation'}</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">Designation Information</h4>
                                        {
                                            props.showMsg && (props.response.status ?
                                                <SuccessMessage message={props.response.messageCode} />
                                                :
                                                <ErrorMessage message={props.response.error} />)
                                        }
                                        <div id="basic-example">
                                            <section>
                                                <form method="POST">
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label for="basicpill-firstname-input">Designation <span className='text-danger'>*</span></label>
                                                                <input
                                                                    name="DESIGNATION"
                                                                    value={data.DESIGNATION}
                                                                    type="text"
                                                                    className={error && data.DESIGNATION.trim().length == 0 ? 'form-control is-invalid ' : 'form-control'}
                                                                    id="basicpill-firstname-input"
                                                                    placeholder="Enter Designation"
                                                                    onChange={handleChange}
                                                                />
                                                                {
                                                                    error && data.DESIGNATION.trim().length == 0 ? <span className='text-danger ml-1'>This Field is Required</span>
                                                                        : ""}
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label for="basicpill-address-input">Sort Number <span className='text-danger'>*</span></label>
                                                                <NumberFormat
                                                                    name="SORT_NUMBER"
                                                                    value={data.SORT_NUMBER}
                                                                    type="text"
                                                                    className={error && data.SORT_NUMBER.toString().trim().length == 0 ? 'form-control is-invalid ' : 'form-control'}
                                                                    id="basicpill-firstname-input"
                                                                    placeholder="Enter Sort Number"
                                                                    onChange={handleChange}
                                                                    maxlength={10}

                                                                />
                                                                {
                                                                    error && data.SORT_NUMBER.toString().trim().length == 0 ? <span className='text-danger ml-1'>This Field is Required</span>
                                                                        : ""}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="mb-3">
                                                                <label for="basicpill-address-input">Designation Details</label>
                                                                <textarea id="basicpill-address-input"
                                                                    className="form-control"
                                                                    rows="2"
                                                                    placeholder="Enter Designation Details"
                                                                    name="DESIGNATION_DETAILS"
                                                                    value={data.DESIGNATION_DETAILS}
                                                                    onChange={handleChange}
                                                                ></textarea>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <button type="button" onClick={onSubmit} className="btn btn-info waves-effect btn-label waves-light"><i className="bx bx-check-double label-icon"></i>{detail ? "Save Changes" : "Create Designation"}</button>
                                                                &nbsp;&nbsp;
                                                                <button type="button" onClick={handleReset} className="btn btn-danger waves-effect btn-label waves-light"><i className="bx bx-block label-icon "></i> Cancel</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </section>
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}


export default AddDesignation;