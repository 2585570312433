import React, { useEffect, useState } from 'react'
import NumberFormat from 'react-number-format'
import { activeCompanyType } from '../../actions/employer'
import constant from '../../constant'
import SuccessMessage from '../common/SuccessMessage'
import ErrorMessage from '../common/ErrorMessage'
import { useNavigate, useParams } from 'react-router-dom'

export default function AddIndustry(props) {
    const { id } = useParams()

    const Navigate = useNavigate()
    const [error, setError] = useState(false);

    const [companyType, setCompanyType] = useState([])

    const [data, setData] = useState({
        COMPANY_TYPE_ID: '',
        INDUSTRY: '',
        INDUSTRY_DETAILS: '',
        SORT_NUMBER: ''
    })
    const detail = props.detail;
    const handleChange = (e) => {
        const { name, value } = e.target
        setData(prevData => ({
            ...prevData,
            [name]: value
        }))
    }

    const onSubmit = () => {
        if (data.COMPANY_TYPE_ID.toString().trim().length === 0 || data.INDUSTRY.trim().length === 0 || data.SORT_NUMBER.toString().trim().length === 0) {
            setError(true)
            return false
        }

        const model = {
            COMPANY_TYPE_ID: data.COMPANY_TYPE_ID,
            INDUSTRY: data.INDUSTRY,
            INDUSTRY_DETAILS: data.INDUSTRY_DETAILS,
            SORT_NUMBER: data.SORT_NUMBER
        }

        if (detail) {
            model.EMPLOYER_INDUSTRY_ID = detail.EMPLOYER_INDUSTRY_ID
        }
        props.onSubmit(model)
    }

    const handleReset = (e) => {
        e.preventDefault();
        id && Navigate(constant.component.industrylist.url)
        setError(false)
        setData({
            COMPANY_TYPE_ID: '',
            INDUSTRY: '',
            SORT_NUMBER: '',
            INDUSTRY_DETAILS: ''
        })

    }

    useEffect(() => {
        if (detail) {

            setData({
                COMPANY_TYPE_ID: detail.COMPANY_TYPE_ID,
                INDUSTRY: detail.INDUSTRY,
                INDUSTRY_DETAILS: detail.INDUSTRY_DETAILS,
                SORT_NUMBER: detail.SORT_NUMBER
            })
        }
        activeCompanyType().then(res => {
            if (res.result) {
                setCompanyType(res.result.list)
            }
            else {
                alert(res.error)
            }
        }).catch(err => {
            alert(err)
        })


    }, [])

    return (
        <React.Fragment>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">{detail ? "Update Employer Inudustry" : "Add Employer Industry"}</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="javascript: void(0);">Employers</a></li>
                                            <li className="breadcrumb-item active">{detail ? "Update Employer Industry" : "Add Employer Industry"}</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">Employer Industry Information</h4>
                                        {
                                            props.showMsg && (props.response.status ?
                                                <SuccessMessage message={props.response.messageCode} />
                                                :
                                                <ErrorMessage message={props.response.error} />)
                                        }
                                        <div id="basic-example">
                                            <section>
                                                <form method="POST">
                                                    <div className="row">
                                                        <div className="col-lg-4">
                                                            <div className="mb-3">
                                                                <label for="basicpill-firstname-input">Company Type <span className='text-danger'>*</span></label>
                                                                <select
                                                                    className={error && data.COMPANY_TYPE_ID.toString().trim().length <= 0 ? 'form-control is-invalid form-select' : 'form-control form-select'}
                                                                    // className="form-select"
                                                                    name='COMPANY_TYPE_ID'
                                                                    onChange={handleChange}
                                                                    value={data.COMPANY_TYPE_ID}
                                                                >
                                                                    <option value="">Select Company Type</option>
                                                                    {
                                                                        companyType && companyType.length > 0 &&
                                                                        companyType.map((item) => {
                                                                            return (
                                                                                <option value={item.COMPANY_TYPE_ID}>{item.COMPANY_TYPE}</option>
                                                                            )
                                                                        })

                                                                    }
                                                                </select>
                                                                {error && data.COMPANY_TYPE_ID.toString().trim().length <= 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <div className="mb-3">
                                                                <label for="basicpill-firstname-input">Employer Industry <span className='text-danger'>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    className={error && data.INDUSTRY.trim().length <= 0 ? 'form-control is-invalid ' : 'form-control'}
                                                                    id="basicpill-firstname-input"
                                                                    placeholder="Enter Employer Industry"
                                                                    name='INDUSTRY'
                                                                    value={data.INDUSTRY}
                                                                    onChange={handleChange}
                                                                />
                                                                {error && data.INDUSTRY.trim().length <= 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-4">
                                                            <div className="mb-3">
                                                                <label for="basicpill-address-input">Sort Number <span className='text-danger'>*</span></label>
                                                                <NumberFormat
                                                                    className={error && data.SORT_NUMBER.toString().trim().length <= 0 ? 'form-control is-invalid ' : 'form-control'}
                                                                    id="basicpill-firstname-input"
                                                                    placeholder="Enter Sort Number"
                                                                    name='SORT_NUMBER'
                                                                    value={data.SORT_NUMBER}
                                                                    onChange={handleChange}

                                                                />
                                                                {error && data.SORT_NUMBER.toString().trim().length <= 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">

                                                        <div className="col-lg-12">
                                                            <div className="mb-3">
                                                                <label for="basicpill-address-input">Employer Industry Details</label>
                                                                <textarea
                                                                    id="basicpill-address-input"
                                                                    className="form-control"
                                                                    rows="2"
                                                                    placeholder="Enter Employer Industry Details"
                                                                    name='INDUSTRY_DETAILS'
                                                                    value={data.INDUSTRY_DETAILS}
                                                                    onChange={handleChange}
                                                                ></textarea>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <button type="button" onClick={onSubmit} className="btn btn-info waves-effect btn-label waves-light"><i className="bx bx-check-double label-icon"></i> {detail ? 'Save Changes' : 'Create Industry'}</button>&nbsp;&nbsp;
                                                                <button type="button" onClick={handleReset} className="btn btn-danger waves-effect btn-label waves-light"><i className="bx bx-block label-icon "></i> Cancel</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </section>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
