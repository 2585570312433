import * as axios from "axios";
import { getAuthHeader } from "./../utils";

export const genderDropdown = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/jzsh/options/gender`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const functionalAreaDropdown = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/FunctionalAreaDropdown`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const RoleDropdown = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/RoleDropdown`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const CityListDropdown = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/CityListDropdown`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};