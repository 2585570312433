import React, { useEffect, useState } from "react";
import { archiveFaqCategory, archiveJobsFaqCategory, getfaqCategoryList, getjobsfaqCategoryList } from "../../../actions/utilitiesAction";
import CategoryFAQList from "../../../component/Utilities/JobsFAQs/ListCategory";
import constant from "../../../constant";

const CategoryList = () => {

    const [categoryList, setCategoryList] = useState([])
    const [count, setCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [showLoader, setShowLoader] = useState(false)

    useEffect(() => {
        getLists(currentPage)
        document.title = constant.title.FaqListCategory
    }, [currentPage])

    const getLists = (page) => {
        setShowLoader(true)
        getjobsfaqCategoryList({ page: page }).then((res) => {
            if (res.status) {
                
                setShowLoader(false)
                setCategoryList(res.result.list)
                setCount(res.result.count[0].total)
            }
        }).catch((err) => {
            alert(err)
        })
    }

    const updateStatus = (model) => {
        archiveJobsFaqCategory(model).then((res) => {
            if (res.status) {
                getLists(currentPage)
            } else {
                alert(res.error)
            }
        }).catch((err) => {
            alert(err)
        })
    }
    return (
        <React.Fragment>
            <CategoryFAQList
                getLists={(page => getLists(page))}
                count={count}
                categoryList={categoryList}
                updateStatus={updateStatus}
                setPage={setCurrentPage}
                showLoader={showLoader}
            />

        </React.Fragment>
    )
}

export default CategoryList;