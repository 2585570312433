import React, { useState } from 'react'
import moment from 'moment'
import constant from '../../../constant'
import { Link } from 'react-router-dom'
import Pagination from "react-js-pagination";
import Loader from '../../common/Loader';

export default function List(props) {


    const { universityList, page, count, showLoader } = props
    const updateStatus = (UNIVERSITY_ID, STATUS) => {
        props.updateStatus(UNIVERSITY_ID, STATUS);
    }

    return (
        <React.Fragment>

            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">University List</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="javascript: void(0);">Campus</a></li>
                                            <li className="breadcrumb-item active">University List</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table align-middle table-nowrap table-hover">
                                                <thead className="table-light">
                                                    {!showLoader && < tr >
                                                        <th scope="col">S.No.</th>
                                                        <th scope="col">Univeristy Name</th>
                                                        <th scope="col">University Detail</th>
                                                        <th scope="col">Added On</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col" style={{ width: "100px" }}>Action</th>
                                                    </tr>}
                                                </thead>
                                                {showLoader && <Loader />}
                                                <tbody>

                                                    {universityList && universityList.length > 0 &&
                                                        universityList.map((item, index) => {
                                                            return (
                                                                <tr>
                                                                    <td>{page == 1 ? `${index + 1}` : `${index == 9 ? page : page - 1}` + `${index == 9 ? 0 : index + 1}`}</td>
                                                                    <td>
                                                                        <p className="text-muted mb-0">{item.UNIVERSITY_NAME}</p>
                                                                    </td>
                                                                    <td>
                                                                        <tr><b>SPOC Name : </b>{item.SPOC_NAME}</tr>
                                                                        <tr><b>SPOC Email : </b>{item.SPOC_EMAIL}</tr>
                                                                        <tr><b>SPOC Phone : </b>{item.SPOC_PHONE}</tr>
                                                                        <tr><b>Location : </b>{item.ADDRESS}
                                                                            <tr>{item.CITY + ', ' + item.STATE}</tr>
                                                                            <tr>{item.PIN_CODE}</tr></tr>





                                                                    </td>
                                                                    <td>
                                                                        {moment(item.CREATED_ON).format('DD.MM.YYYY on HH:MM A')}
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.STATUS == 'A' ?
                                                                                <button type="button" className="btn btn-success btn-sm waves-effect waves-light">ACTIVE</button> : <button type="button" className="btn btn-danger btn-sm waves-effect waves-light">ARCHIVE</button>
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        <ul className="list-inline contact-links mb-0" style={{ fontSize: "18px" }}>
                                                                            <li className="list-inline-item px-2">
                                                                                <Link to={constant.component.editUniversity.url.replace(':id', item.UNIVERSITY_ID)
                                                                                } ><i className="bx bx-edit"></i></Link>
                                                                            </li>
                                                                            <li className="list-inline-item px-2">
                                                                                <a href="javascript: void(0)"
                                                                                    onClick={() => updateStatus(item.UNIVERSITY_ID, item.STATUS === 'A' ? 'I' : 'A')} style={{ color: 'red' }}
                                                                                >
                                                                                    {
                                                                                        item.STATUS == 'A' ? <i className="bx bx-trash"></i> : <i class="bx bx-log-in"></i>
                                                                                    }
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })


                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="pagination pagination-rounded justify-content-center mt-4">
                                                    {/* <Pagination
                                                        activePage={qParams?.p ? + qParams.p :
                                                            qParams?.pageno ? Number(qParams.pageno) : page}
                                                        itemsCountPerPage={10}
                                                        totalItemsCount={props.count}
                                                        pageRangeDisplayed={5}
                                                        onChange={handlePageChange}
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                    /> */}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >)
}



