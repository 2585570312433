import React, { useEffect, useState } from 'react'
import NumberFormat from 'react-number-format';
import { useNavigate, useParams } from 'react-router-dom';
import ErrorMessage from '../common/ErrorMessage';
import SuccessMessage from '../common/SuccessMessage';
import constant from '../../constant';

export default function AddEntityType(props) {

    const [error, setError] = useState(false)

    const Navigate = useNavigate()

    const { id } = useParams()

    const [data, setData] = useState({
        ENTITY_TYPE: '',
        ENTITY_TYPE_DETAILS: '',
        SORT_NUMBER: ''
    })
    const { detail } = props;
    const handleChange = (e) => {
        const { name, value } = e.target
        setData(prevData => ({
            ...prevData,
            [name]: value
        }))

    }

    const onSubmit = (e) => {
        
        e.preventDefault();
        if (data.ENTITY_TYPE.trim().length === 0 || data.SORT_NUMBER.toString().trim().length == 0) {
            setError(true)
        }
        else {
            const model = {
                ENTITY_TYPE: data.ENTITY_TYPE,
                ENTITY_TYPE_DETAILS: data.ENTITY_TYPE_DETAILS,
                SORT_NUMBER: data.SORT_NUMBER
            }
            if (detail) {
                model.ENTITY_TYPE_ID = detail.ENTITY_TYPE_ID
            }
            props.onSubmit(model)
        }

    }

    const handleReset = (e) => {
        e.preventDefault();
        id && Navigate(constant.component.entitytypelist.url)
        setError(false)
        setData({
            ENTITY_TYPE: '',
            ENTITY_TYPE_DETAILS: '',
            SORT_NUMBER: ''
        })
    }

    useEffect(() => {
        if (detail) {
            setData({
                ENTITY_TYPE: detail.ENTITY_TYPE,
                ENTITY_TYPE_DETAILS: detail.ENTITY_TYPE_DETAILS,
                SORT_NUMBER: detail.SORT_NUMBER
            })
        }

    }, [])


    return (
        <React.Fragment>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0" style={{ fontSize: "18px" }}>{detail ? "Update Entity Type" : " Add Entity Type"}</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="javascript: void(0);">Employers</a></li>
                                            <li className="breadcrumb-item active">{detail ? "Update Entity Type" : "Add Entity Type"}</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">Entity Type Information</h4>
                                        {
                                            props.showMsg && (props.response.status ?
                                                <SuccessMessage message={props.response.messageCode} />
                                                :
                                                <ErrorMessage message={props.response.error} />)
                                        }
                                        <div id="basic-example">
                                            <section>
                                                <form method="POST">
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label for="basicpill-firstname-input">Entity Type <span className='text-danger'>*</span></label>
                                                                 <input
                                                                    type="text"
                                                                    className={error && data.ENTITY_TYPE.trim().length === 0 ? 'form-control is-invalid' : 'form-control'}
                                                                    id="basicpill-firstname-input"
                                                                    placeholder="Enter Entity Type"
                                                                    name='ENTITY_TYPE'
                                                                    value={data.ENTITY_TYPE}
                                                                    onChange={handleChange}
                                                                /> 
                                                                {error && data.ENTITY_TYPE.trim().length === 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label for="basicpill-address-input">Sort Number <span className='text-danger'>*</span></label>
                                                                <NumberFormat
                                                                    className={error && data.SORT_NUMBER.toString().trim().length === 0 ? 'form-control is-invalid' : 'form-control'}
                                                                    id="basicpill-firstname-input"
                                                                    placeholder="Enter Sort Number"
                                                                    name='SORT_NUMBER'
                                                                    value={data.SORT_NUMBER}
                                                                    onChange={handleChange}
                                                                />
                                                                {error && data.SORT_NUMBER.toString().trim().length === 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">

                                                        <div className="col-lg-12">
                                                            <div className="mb-3">
                                                                <label for="basicpill-address-input">Entity Type Details</label>
                                                                <textarea
                                                                    id="basicpill-address-input"
                                                                    className="form-control"
                                                                    rows="2"
                                                                    placeholder="Enter Entity Type Details"
                                                                    name='ENTITY_TYPE_DETAILS'
                                                                    value={data.ENTITY_TYPE_DETAILS}
                                                                    onChange={handleChange}
                                                                />
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <button type="button" onClick={onSubmit} className="btn btn-info waves-effect btn-label waves-light"><i className="bx bx-check-double label-icon"></i> {detail ? 'Save Changes' : 'Create Entity Type'}</button>&nbsp;&nbsp;
                                                                <button type="button" onClick={handleReset} className="btn btn-danger waves-effect btn-label waves-light"><i className="bx bx-block label-icon "></i> Cancel</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </section>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </React.Fragment>)
}
