import React from 'react'
import Subscription from '../../component/SubscriptionPackageDetails/SubscriptionPackage'
import { SubscriptionList } from '../../actions/Subscription'
import { useState, useEffect } from 'react'
import constant from '../../constant'
import { useLocation, useParams } from 'react-router-dom'
import queryString from 'query-string'


const SubscriptionPackage = () => {

    let parms = useParams()
    const location = useLocation()


    const [list, setList] = useState([])
    const [count, setCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [keyword, setKeyword] = useState('')

    useEffect(() => {
        const qParams = queryString.parse(location.search)
        SubscriptionLists(qParams?.p ? qParams?.p : currentPage, '')
        document.title = constant.title.subscriptionpackage
    }, [currentPage])

    const SubscriptionLists = (page) => {

        SubscriptionList({ page: page, KEYWORD: keyword }).then((res) => {

            if (res.status) {
                setList(res.result.list)
                setCount(res.result.count)
            }
        }).catch((err) => {
            alert(err)
        })
    }


    return (
        <React.Fragment>
            <Subscription
                List={list}
                setList={setList}
                SubscriptionLists={(page => SubscriptionLists(page))}
                count={count}
                setPage={(num) => setCurrentPage(num)}

            />
        </React.Fragment>
    )
}

export default SubscriptionPackage
