import React from 'react'
import Employer from '../../component/Employers/UpdateEmployerIndustry'
import { useEffect } from 'react'
import constant from '../../constant'
import { updateemployers } from '../../actions/employer'

const UpdateEmployerIndustry=()=> {

    const [response, setResponse] = React.useState({})
    const [showMsg, setShowMsg] = React.useState(false)


    useEffect(() => {
        document.title = constant.title.updateIndustry
    }, [])

    const onSubmit = (model) => {
        updateemployers(model).then((res) => {
            setResponse(res)
            setShowMsg(true)
            setTimeout(() => {
                setShowMsg(false)
            }, 7000)
        }).catch((err) => [
            alert(err)
        ])
    }

  return (
    <React.Fragment>
    <Employer onSubmit={onSubmit} response={response} showMsg={showMsg}
    />
    </React.Fragment>
  )
}

export default UpdateEmployerIndustry