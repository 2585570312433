import React, { useEffect, useState } from 'react'
import { featuredEmployerList, updateEmployerStatus } from '../../actions/employer'
import List from '../../component/Employers/FeaturedList'
import constant from '../../constant'

export default function FeaturedList() {

    const [list, setList] = useState([])
    const [count, setCount] = useState(0)
    const [showLoader, setShowLoader] = useState(true)
    const [currentPage, setCurrentPage] = useState(1)

    useEffect(() => {
        document.title = constant.title.FeaturedEmployerList
        featuredEmployerLists(currentPage, "")
    }, [currentPage])


    const featuredEmployerLists = (page, KEYWORD) => {
        featuredEmployerList({ page: page, KEYWORD: KEYWORD }).then(res => {
            setShowLoader(false)
            if (res.status) {
                setList(res.result.list)
                setCount(res.result.count.total)
            }
            else {
                alert(res.error)
            }
        }).catch(err => {
            alert(err)
        })
    }

    const updateStatus = (model) => {
        updateEmployerStatus(model).then((res) => {
            if (res.status) {
                featuredEmployerLists(currentPage, "")
            }
        })
    }


    return (
        <React.Fragment>
            <List
                list={list}
                count={count}
                featuredEmployerLists={(page) => { featuredEmployerLists(page) }}
                updateStatus={updateStatus}
                setPage={setCurrentPage}
                setShowLoader={setShowLoader}
                showLoader={showLoader}
            />

        </React.Fragment>
    )
}
