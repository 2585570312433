import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { UpdateCompanyType, companyTypeById } from '../../actions/employer'
import AddCompanytype from '../../component/Employers/AddCompanyType'
import constant from '../../constant'


export default function AddCompanyType(props) {

    const [response, setResponse] = React.useState({})
    const [showMsg, setShowMsg] = React.useState(false)

    const [data, setData] = useState(null)
    const params = useParams();
    const navigation = useNavigate();


    useEffect(() => {
        document.title = constant.title.UpdateCompanyType;

        companyTypeById(params.id).then((res) => {
            if (res.status) {
                setData(res.result)
            }
            else {
                alert(res.error)
            }
        }).catch((err) => {
            alert(err)
        })
    }, [])

    const onSubmit = (model) => {
        UpdateCompanyType(model).then((res) => {
            if (res.status) {
                navigation(constant.component.companytypelist.url)
            }
            else {
                setResponse(res)
                setShowMsg(true)
                setTimeout(() => {
                    setShowMsg(false)
                }, 7000)
            }
        }).catch((err) => {
            alert(err)
        })
    }
    return (
        <React.Fragment>
            {data && <AddCompanytype detail={data} onSubmit={onSubmit} response={response} showMsg={showMsg} />}
        </React.Fragment>
    )
}
