import React, { useState } from 'react'
import { useEffect } from 'react';
import NumberFormat from 'react-number-format'
import { useNavigate, useParams } from 'react-router-dom';
import { uploadBanner } from '../../actions/utilitiesAction';
import constant from '../../constant';
import SuccessMessage from '../common/SuccessMessage';
import ErrorMessage from '../common/ErrorMessage';
import { appCategoryType } from '../../actions/employer';
const AddThird = (props) => {

    const { id } = useParams()
    const { detail } = props

    const [Banner, setBanner] = useState({
        TITLE: "",
        PAGE_LOCATION: "",
        POSITION: "",
        AD_SCRIPT: "",
        SORT_NUMBER: "",

    })
    const Navigate = useNavigate()
    const [error, setError] = useState(false)






    const handleReset = (e) => {
        setError(false)
        e.preventDefault();
        id && Navigate(constant.component.addthirdpartybanner.url)
        setBanner((PrevState) => ({
            ...PrevState,
            TITLE: "",
            PAGE_LOCATION: "",
            POSITION: "",
            AD_SCRIPT: "",
            SORT_NUMBER: "",
        }));
    }


    const onChange = (e) => {
        const { name, value } = e.target
        setBanner({
            ...Banner,
            [name]: value
        })
    }



    const onSubmit = (e) => {
        debugger
        e.preventDefault()
        if (Banner.TITLE.trim().length == 0 ||
            Banner.PAGE_LOCATION.trim().length == 0 ||
            Banner.SORT_NUMBER.length == 0 ||
            Banner.POSITION.trim().length == 0 ||
            Banner.AD_SCRIPT.trim().length == 0

        ) {
            setError(true);
            return false;
        }

        const model = {
            TITLE: Banner.TITLE,
            PAGE_LOCATION: Banner.PAGE_LOCATION,
            AD_SCRIPT: Banner.AD_SCRIPT,
            SORT_NUMBER: Banner.SORT_NUMBER,
            POSITION: Banner.POSITION,

        };
        if (detail) {
            model.BANNER_ID = detail.BANNER_ID
        }
        props.onSubmit(model)

        setBanner((PrevState) => ({
            ...PrevState,
            TITLE: "",
            PAGE_LOCATION: "",
            POSITION: "",
            AD_SCRIPT: "",
            SORT_NUMBER: "",
        }));

        setError(false);

    }
    useEffect(() => {
        (id ? document.title = constant.title.EditBanner : document.title = constant.title.AddBanner)
    }, [])
    useEffect(() => {
        if (detail) {
            setBanner({

                TITLE: detail.TITLE,
                PAGE_LOCATION: detail.PAGE_LOCATION,
                AD_SCRIPT: detail.AD_SCRIPT,
                POSITION: detail.POSITION,
                SORT_NUMBER: detail.SORT_NUMBER,

            })

        }
    }, [])


    return (

        <React.Fragment>

            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 " style={{ fontSize: '18px' }}>Banner Third Party Advertisement</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="javascript: void(0);">Advertisement</a></li>
                                            <li className="breadcrumb-item active"> Add Banner</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">Banner Information</h4>

                                        {
                                            props.showMsg && (props.response.status ?
                                                <SuccessMessage message={props.response.messageCode} />
                                                :
                                                <ErrorMessage message={props.response.error} />)
                                        }
                                        <div className='row'>

                                            <div className='col-lg-12'>

                                                <div id="basic-example">
                                                    <section>
                                                        <form>
                                                            <div className="row">



                                                                <div className="col-lg-4">
                                                                    <div className="mb-3">
                                                                        <label for="basicpill-firstname-input">Page Location<span style={{ color: "#FF0000" }}>*</span></label>
                                                                        <select
                                                                            value={Banner.PAGE_LOCATION}
                                                                            className='form-control'
                                                                            onChange={onChange}
                                                                            name="PAGE_LOCATION"
                                                                        >
                                                                            <option value="">Select Page  Location </option>
                                                                            <option value="All">All</option>
                                                                            <option value="Job by skill">Job by skill</option>
                                                                            <option value="JOb by designation">Job by designation </option>
                                                                            <option value="Job by company">Job by company</option>
                                                                            <option value="Job by category">Job by category</option>
                                                                            <option value="Jobs by location">Jobs by location</option>
                                                                            <option value="Career Explorer">Career Explorer</option>
                                                                            <option value="Job Details">Job Details</option>


                                                                        </select>
                                                                        {error && Banner.PAGE_LOCATION.trim().length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4">
                                                                    <div className="mb-3">
                                                                        <label for="basicpill-address-input">Position <span style={{ color: "#FF0000" }}>*</span></label>
                                                                        <select
                                                                            value={Banner.POSITION}
                                                                            onChange={onChange}
                                                                            className='form-control'
                                                                            name="POSITION"
                                                                        >
                                                                            <option value="">Select  Position </option>
                                                                            <option value="LEFT">Left </option>
                                                                            <option value="RIGHT">Right </option>
                                                                            <option value="TOP">Top</option>
                                                                            <option value="BOTTOM">Bottom</option>
                                                                            <option value="MIDDLE LEFT">Middle Left</option>
                                                                            <option value="MIDDLE RIGHT">Middle Right</option>
                                                                            <option value="MIDDLE CENTRE">Middle Centre</option>



                                                                        </select>
                                                                        {error && Banner.POSITION.trim().length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}
                                                                    </div>

                                                                </div>
                                                                <div className="col-lg-4">
                                                                    <div className="mb-3">
                                                                        <label for="basicpill-firstname-input">Headline / Title <span style={{ color: "#FF0000" }}>*</span></label>
                                                                        <input
                                                                            value={Banner.TITLE}
                                                                            onChange={onChange}
                                                                            className='form-control'
                                                                            placeholder="Enter Title"
                                                                            // id="basicpill-firstname-input"
                                                                            // value={banner.TITLE}
                                                                            name='TITLE'
                                                                        />
                                                                        {error && Banner.TITLE.trim().length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}

                                                                    </div>
                                                                </div>


                                                            </div>




                                                            <div className="row">

                                                            <div className="col-lg-4">
                                                            <div className="mb-3">
                                                                <label for="basicpill-firstname-input">Sort Number<span style={{ color: "#FF0000" }}>*</span></label>
                                                                <NumberFormat
                                                                    value={Banner.SORT_NUMBER}
                                                                    allowLeadingZeros={false}
                                                                    allowNegative={false}
                                                                    thousandSeparator={false}
                                                                    className='form-control'
                                                                    placeholder="Enter Sort Number"
                                                                    name="SORT_NUMBER"
                                                                    onChange={onChange}
                                                                //id="basicpill-firstname-input"
                                                                />
                                                                {error && Banner.SORT_NUMBER.length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}
                                                            </div>
                                                        </div>


                                                                <div className="col-lg-8">
                                                                    <div className="mb-3">
                                                                        <label for="basicpill-firstname-input">AD SCRIPT<span style={{ color: "#FF0000" }}>*</span></label>
                                                                        <textarea
                                                                            value={Banner.AD_SCRIPT}
                                                                            className='form-control'
                                                                            onChange={onChange}
                                                                            placeholder="Enter AD SCRIPT"
                                                                            // id="basicpill-firstname-input"
                                                                            name="AD_SCRIPT"


                                                                        />
                                                                        {error && Banner.AD_SCRIPT.trim().length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row">

                                                           

                                                            <div className="col-lg-12">
                                                                <div className="mb-3">
                                                                    <button onClick={onSubmit}
                                                                        type="button" className="btn btn-info waves-effect btn-label waves-light"> <i className="bx bx-check-double label-icon"></i>{detail ? "Save Changes" : "Create Banner"}</button>
                                                                    &nbsp;&nbsp;
                                                                    <button onClick={(e) => handleReset(e)} type="button" className="btn btn-danger waves-effect btn-label waves-light"><i className="bx bx-block label-icon "></i> Cancel</button>
                                                                </div>
                                                            </div>
                                                        </div>



                                                        </form>

                                                    </section>
                                                </div>
                                            </div>


                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>


                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AddThird
