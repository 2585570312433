import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import constant from '../../../constant'
import { useNavigate } from 'react-router-dom'
import SuccessMessage from '../../common/SuccessMessage'
import ErrorMessage from '../../common/ErrorMessage'

const AddAdminRole = (props) => {
    const { id } = useParams()
    const { detail } = props
    const [error, setError] = useState(false)
    const Navigate = useNavigate()

    const [Role, setRole] = useState({
        ROLE_NAME: "",
        OTHER_DETAILS: "",
    })



    const handleChange = (e) => {
        const { name, value } = e.target
        setRole({
            ...Role,
            [name]: value
        })
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if (Role.ROLE_NAME.trim().length == 0 || Role.OTHER_DETAILS.trim().length == 0) {
            setError(true)
            //return false
        }
        else {
            const model = {
                ROLE_NAME: Role.ROLE_NAME,
                OTHER_DETAILS: Role.OTHER_DETAILS
            };
            if (detail) {
                model.ROLE_ID = detail.ROLE_ID
            }
            props.onSubmit(model)
        }
    }

    useEffect(() => {
        if (detail) {
            setRole({
                ROLE_NAME: detail.ROLE_NAME,
                OTHER_DETAILS: detail.OTHER_DETAILS,
            })
        }

    }, [])

    useEffect(() => {
        (id ? document.title = constant.title.UpdateRole : document.title = constant.title.AddRole)
    }, [])


    const handleReset = (e) => {
        e.preventDefault();
        setError(false)
        id && Navigate(constant.component.adminrolelist.url)
        setRole(PrevState => ({
            ...PrevState,
            ROLE_NAME: "",
            OTHER_DETAILS: "",
        }))
    }



    return (
        <React.Fragment>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">

                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 " style={{ fontSize: '18px' }}>{detail ? "Update Role" : "Add Role"}</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="javascript: void(0);">Roles</a></li>
                                            <li className="breadcrumb-item active">{detail ? "Update Role" : "Add Role"}</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">Role Information</h4>
                                        {
                                            props.showMsg && (props.response.status ?
                                                <SuccessMessage message={props.response.messageCode} />
                                                :
                                                <ErrorMessage message={props.response.error} />)
                                        }
                                        <div id="basic-example">
                                            <section>
                                                <form method="POST">
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label for="basicpill-firstname-input">Role <span className='text-danger'>*</span></label>
                                                                <input type="text"
                                                                    className={error && Role.ROLE_NAME.trim().length <= 0 ? 'form-control is-invalid ' : 'form-control'}
                                                                    name="ROLE_NAME"
                                                                    value={Role.ROLE_NAME}
                                                                    onChange={handleChange}
                                                                    id="basicpill-firstname-input" placeholder="Enter Role" />
                                                                {
                                                                    error && Role.ROLE_NAME.trim().length <= 0 ? <span className='text-danger'>This Field is Required</span>
                                                                        : ""}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label for="basicpill-address-input">Role Details</label>
                                                                <textarea id="basicpill-address-input"
                                                                    name="OTHER_DETAILS"
                                                                    value={Role.OTHER_DETAILS}
                                                                    onChange={handleChange}

                                                                    className={error && Role.OTHER_DETAILS.trim().length <= 0 ? 'form-control is-invalid ' : 'form-control'}
                                                                    rows="2" placeholder="Enter Role Details">


                                                                </textarea>
                                                                {
                                                                    error && Role.OTHER_DETAILS.trim().length <= 0 ? <span className='text-danger'>This Field is Required</span>
                                                                        : ""}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <button type="button"
                                                                    onClick={onSubmit}
                                                                    className="btn btn-info waves-effect btn-label waves-light"><i className="bx bx-check-double label-icon"></i> {detail ? 'Save Changes' : 'Create Role '}</button>&nbsp;&nbsp;

                                                                <button type="button"
                                                                    onClick={handleReset}
                                                                    className="btn btn-danger waves-effect btn-label waves-light"><i className="bx bx-block label-icon "></i> Cancel</button>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </form>
                                            </section>
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AddAdminRole;