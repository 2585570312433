import * as axios from "axios";
import { getAuthHeader } from "./../utils";

export const uploadUniversityLogo = async (file) => {
    const url = `${process.env.REACT_APP_BASE_URL}/upload-university-logo`;
    return axios.post(url, file, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const addUniversity = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/add-university`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}


export const editUniversity = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/edit-university`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}
export const editCollege = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/edit-college`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}


export const universityList = async (page) => {
    const url = `${process.env.REACT_APP_BASE_URL}/university-list?page=${page}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const collegeList = async (page) => {
    const url = `${process.env.REACT_APP_BASE_URL}/college-list?page=${page}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}
export const collegeStudentList = async (page,id) => {
    const url = `${process.env.REACT_APP_BASE_URL}/college-student-list?page=${page}&&id=${id}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const offCampusStudentList = async (page) => {
    const url = `${process.env.REACT_APP_BASE_URL}/off-campus-student-list?page=${page}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}



export const getUniversityPrefilled = async (id) => {
    const url = `${process.env.REACT_APP_BASE_URL}/prefill-university-data/${id}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const changeUniversityStatus = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/update-university-visibility`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}
export const changeCollegeStatus = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/update-college-visibility`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}
export const collegeProfile = async (id) => {
    const url = `${process.env.REACT_APP_BASE_URL}/college-profile?COLLEGE_ID=${id}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}
export const updateAdminCampusLatestNews = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/cjzs-update-admin-campus-latest-news`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const getAdminCampusLatestNews = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/cjzs-get-admin-campus-latest-news`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}



export const uploadAnnouncementDocument = async (file) => {
    const url = `${process.env.REACT_APP_BASE_URL}/cjzs-upload-announcement-doc`;
    return axios.post(url, file, getAuthHeader()).then((res) => {
        return res.data;
    })
}



export const addNewAnnouncement = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/add-new-campus-announcement`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const editNewAnnouncement = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/cjzs-edit-campus-announcement`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const AnnouncementList = async (page) => {
    const url = `${process.env.REACT_APP_BASE_URL}/cjzs-campus-announcement-list/${page}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const prefillAnnouncement = async (id) => {
    const url = `${process.env.REACT_APP_BASE_URL}/cjzs-prefill-campus-announcement/${id}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}


export const changeAnnouncementStatus = async (id, status) => {
    const url = `${process.env.REACT_APP_BASE_URL}/cjzs-campus-announcement-status-change/${id}/${status}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const getStudentDetails = async (student_id,type) => {
    const url = `${process.env.REACT_APP_BASE_URL}/student-details/${student_id}/${type}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const StudentEducationDetail = async (student_id,type) => {
    const url = `${process.env.REACT_APP_BASE_URL}/student-education/${student_id}/${type}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}



export const StudentEducationChoice = async (student_id,type) => {
    const url = `${process.env.REACT_APP_BASE_URL}/student-education-choice/${student_id}/${type}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}

