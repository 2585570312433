import React, { useState } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import constant from '../../../constant'
import Pagination from 'react-js-pagination'
import Loader from '../../common/Loader'

export default function ListCategory(props) {

  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    props.setPage(pageNumber)
  };

  const updateStatus = (CATEGORY_ID, STATUS) => {
    const model = {
      CATEGORY_ID: CATEGORY_ID,
      STATUS: STATUS === 'U' ? 'A' : 'U'
    }
    props.setPage(currentPage)
    props.updateStatus(model)
  }

  return (
    <React.Fragment>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Job's FAQ Category List</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item"><a href="javascript:void(0);">Job's FAQs Category List</a></li>
                      <li className="breadcrumb-item active">FAQs List</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <form className="row gy-12 gx-12 align-items-center">
                      <div className="col-sm-2">
                        <label className="visually-hidden" for="autoSizingInput">Name</label>
                        Search FAQ Category
                      </div>
                      <div className="col-sm-6">
                        <label className="visually-hidden" for="autoSizingInputGroup">Username</label>
                        <div className="input-group">
                          <input type="text" className="form-control" id="autoSizingInputGroup" placeholder="Enter Faq Category" />
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <button type="button" className="btn btn-success waves-effect btn-label waves-light"><i className="bx bx-check-double label-icon"></i> Search</button>&nbsp;
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table align-middle table-nowrap table-hover">
                        {
                          props.showLoader && <Loader />
                        }
                        {!props.showLoader && <thead className="table-light">
                          <tr>
                            <th scope="col">S.No.</th>
                            <th scope="col">Category</th>
                            <th scope="col">Linked to Blog</th>
                            <th scope="col">Added On</th>
                            <th scope="col">status</th>
                            <th scope="col" style={{ width: '100px' }}>Action</th>
                          </tr>
                        </thead>}
                        <tbody>
                          {
                            props.categoryList && props.categoryList.length &&
                            props.categoryList.map((data, index) => {
                              return (
                                <tr>
                                  <td>{10 * currentPage + (index + 1) - 10}</td>
                                  <td>
                                    <p className="text-muted mb-0">{data.CATEGORY}</p>
                                  </td>
                                  <td>{data.SORT_NUMBER}</td>
                                  <td>
                                    {moment(data.CREATED_ON).format("lll")}
                                  </td>
                                  <td>
                                    {
                                      data.STATUS == 'U' ?
                                        <button type="button" className="btn btn-success btn-sm waves-effect waves-light">ACTIVE</button> : <button type="button" className="btn btn-danger btn-sm waves-effect waves-light">Archive</button>
                                    }
                                  </td>
                                  <td>
                                    <ul className="list-inline font-size-20 contact-links mb-0" >
                                      <li className="list-inline-item px-2">
                                        <Link to={constant.component.jobsfaqeditcategory.url.replace(':id', data.CATEGORY_ID)}><i className="bx bx-edit" /></Link>
                                      </li>
                                      <li className="list-inline-item px-2">
                                        <a href="javascript: void(0)" onClick={() => updateStatus(data.CATEGORY_ID, data.STATUS)} style={{ color: 'red' }} title="Archive Record">
                                          {
                                            data.STATUS == 'U' ? <i className="bx bx-trash"></i> : <i class="bx bx-log-in"></i>
                                          }


                                        </a>
                                      </li>
                                    </ul>
                                  </td>
                                </tr>
                              )
                            })
                          }
                          {
                            <>
                              {
                                props.categoryList && props.categoryList.length == 0 && props.showLoader == false &&
                                <td colSpan={10} className='text-center text-danger pt-3' style={{ paddingRight: "250px", position: "inherit" }}>No Faq Category Available</td>
                              }
                            </>
                          }

                        </tbody>
                      </table>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <ul className="pagination pagination-rounded justify-content-center mt-4">

                          <Pagination

                            activePage={currentPage}
                            itemsCountPerPage={10}
                            totalItemsCount={props.count}
                            pageRangeDisplayed={5}
                            onChange={handlePageChange}
                            itemClass="page-item"
                            linkClass="page-link"
                          />
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </React.Fragment>
  )
}
