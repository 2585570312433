
import * as axios from "axios";
import CONSTANT from "./../constant";
import { setStorage, getAuthHeader, setsessionStorage } from "./../utils";
import moment from "moment";
 
export const login = (param) => {
  const url = `${process.env.REACT_APP_BASE_URL}/login`;
  return axios.post(url, param).then((res) => {
    if (res.data.status) {
      setStorage(CONSTANT.keys.token, res.data.result.token);
      setStorage(CONSTANT.keys.userDetails, res.data.result);
      setsessionStorage(CONSTANT.keys.token, res.data.result.token);
      setStorage(CONSTANT.keys.loggedIn, moment().format('X'));
      setStorage(CONSTANT.keys.remeber_me, param.REMEMBER_ME);
    }
    return res.data;
  });
};

export const resetPassword = (param) => {
  const url = `${process.env.REACT_APP_BASE_URL}/reset-password-admin`;
  return axios.post(url, param).then((res) => {
    return res.data;
  });
};



export const forgotPassword = (param) => {
  const url = `${process.env.REACT_APP_BASE_URL}/forgot-password-admin`;
  return axios.post(url, param).then((res) => {
    return res.data;
  });
};



export const lastActivity = () => {
  const url = `${process.env.REACT_APP_BASE_URL}/last-activity`;
  return axios.get(url, getAuthHeader()).then((res) => {
    return res.data;
  });
};



export const unlockScreen = (model) => {
  const url = `${process.env.REACT_APP_BASE_URL}/unlock-screen`;
  return axios.post(url, model, getAuthHeader()).then((res) => {
    if (res.data.status) {
      setStorage(CONSTANT.keys.token, res.data.result.token);
    }
    return res.data
  })
};