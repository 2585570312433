import React from 'react'
import Add from "../../../component/Utilities/Article/AddArticle"
import { useState, useEffect } from 'react'
import { getActiveArticleCategory, addArticle } from '../../../actions/utilitiesAction'

export default function AddArticle() {

    const [response, setResponse] = useState({})
    const [showMsg, setShowMsg] = useState(false)

    const [list, setList] = useState([])

    const onSubmit = (model) => {
        
        addArticle(model).then((res) => {
            // if (res.status)
            //     alert(res.messageCode)
            setResponse(res)
            setShowMsg(true)
            setTimeout(() => {
                setShowMsg(false)
            }, 7000)
        }).catch((err) => {
            alert(err)
        })
    }

    useEffect(() => {
        getActiveArticleCategory().then((res) => {
            setList(res.result)
        }).catch((err) => {
            alert(err)
        })
    }, [])

    return (
        <React.Fragment>
            <Add list={list} onSubmit={onSubmit} response={response} showMsg={showMsg} />
        </React.Fragment>
    )
}
