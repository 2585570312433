export const panValidation = (input) => {
    let regex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    if (regex.test(input)) {
        return true;
    }
    else {
        return false;
    }
}

export const gstValidation = (input) => {
    let regex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[A-Z]{1}[0-9]{1}$/;
    if (regex.test(input)) {
        return true;
    }
    else {
        return false;
    }
}

export const tanValidation = (input) => {
    let regex = /^[A-Z]{4}[0-9]{5}[A-Z]{1}$/;
    if (regex.test(input)) {
        return true;
    }
    else {
        return false;
    }
}



