import React, { useEffect, useState, useMemo } from 'react'
import constant from '../../constant'
import { phone_code } from '../../assets/data/phone_code'
import { useParams } from 'react-router-dom'
import {
    activeAddressproofType, activeCompanyType, activeEmployerBenefits, ActiveEmployerRoles, activeEntityType,
    activeIndustryType, uploadCompanyHeader, uploadCompanyLogo, uploadCompanyGST, uploadAddressproof, uploadCompanyPAN
} from '../../actions/employer'
import { cityList, countryList, deleteOutplacement, stateList } from '../../actions/utilitiesAction'
import NumberFormat from 'react-number-format'
import { Form } from 'react-bootstrap'
import { setError } from '../../utils'
import SuccessMessage from '../common/SuccessMessage'
import ErrorMessage from '../common/ErrorMessage'
import { panValidation, gstValidation, tanValidation } from '../../validation'
import { useNavigate } from 'react-router-dom'
import isUrl from 'is-url'
export default function AddEmployer(props) {
    let params = useParams()
    const { detail } = props
    const [roleList, setRoleList] = useState([])
    const [companyTypeList, setCompanyTypeList] = useState([])
    const [industryTypeList, setIndustryTypeList] = useState([])
    const [employerBenefitsList, setEmployerBenefitsList] = useState([])
    const [entityList, setEntityList] = useState([])
    const [countrylist, setCountryList] = useState([])
    const [statelist, setStateList] = useState([])
    const [citylist, setCityList] = useState([])
    const [addressProofList, setAddressProofList] = useState([])
    const [error, setError] = useState(false)
    const [panError, setPanError] = useState(false)
    const [gstError, setGstError] = useState(false)
    const [tanError, setTanError] = useState(false)
    const navigate = useNavigate()

    const [data, setData] = useState({
        USERNAME: '',
        EMAIL: '',
        SECONDARY_EMAIL: '',
        EMPLOYER_ROLE_ID: '',
        PHONE_CODE: '+91',
        MOBILE: '',
        LOCATION: '',
        COMPANY_NAME: '',
        COMPANY_TYPE_ID: '',
        EMPLOYER_INDUSTRY_ID: '',
        CONTACT_PERSON: '',
        CONTACT_PERSON_DESIGNATION: '',
        WEBSITE: '',
        PHONE_1: '',
        PHONE_2: '',
        FAX_NUMBER: '',
        COMPANY_LOGO: '',
        COMPANY_HEADER_BACKGROUND: '',
        FB_URL: '',
        LINKEDIN_URL: '',
        COMPANY_SIZE: '',
        FOUNDED: '',
        HEADQUARTERS: '',
        EMPLOYER_BENEFITS_ID: '',
        ENTITY_TYPE_ID: '',
        ADDRESS: '',
        COUNTRY_ID: '102',
        STATE_ID: '',
        CITY_ID: '',
        PINCODE: '',
        ADDRESS_PROOF_TYPE_ID: '',
        UPLOAD_ADDRESS_PROOF: '',
        PAN_CARD_STATUS: 'Y',
        NAME_ON_PAN: '',
        PAN_NUMBER: '',
        UPLOAD_PAN: '',
        GST: 'N',
        GSTIN: '',
        UPLOAD_GSTIN_CERTIFICATE: '',
        TAN_NUMBER: '',
        PROFILE_FOR_PREMIUM_FEATURED: '',
        PROFILE_FOR_REGULAR: '',
        ABOUT_COMPANY: '',
        TWITTER_URL: '',
        LISTING_TYPE: 'R'
    })


    useEffect(() => {

        if (detail) {

            setData({
                USERNAME: detail.USERNAME,
                EMAIL: detail.EMAIL,
                SECONDARY_EMAIL: detail.SECONDARY_EMAIL,
                EMPLOYER_ROLE_ID: detail.EMPLOYER_ROLE_ID,
                PHONE_CODE: detail.PHONE_CODE,
                MOBILE: detail.MOBILE,
                LOCATION: detail.LOCATION,
                COMPANY_NAME: detail.COMPANY_NAME,
                COMPANY_TYPE_ID: detail.COMPANY_TYPE_ID,
                EMPLOYER_INDUSTRY_ID: detail.EMPLOYER_INDUSTRY_ID,
                CONTACT_PERSON: detail.CONTACT_PERSON,
                CONTACT_PERSON_DESIGNATION: detail.CONTACT_PERSON_DESIGNATION,
                WEBSITE: detail.WEBSITE,
                PHONE_1: detail.PHONE_1,
                PHONE_2: detail.PHONE_2,
                FAX_NUMBER: detail.FAX_NUMBER,
                COMPANY_LOGO: detail.COMPANY_LOGO,
                COMPANY_HEADER_BACKGROUND: detail.COMPANY_HEADER_BACKGROUND,
                FB_URL: detail.FB_URL,
                LINKEDIN_URL: detail.LINKEDIN_URL,
                COMPANY_SIZE: detail.COMPANY_SIZE,
                FOUNDED: detail.FOUNDED,
                HEADQUARTERS: detail.HEADQUARTERS,
                EMPLOYER_BENEFITS_ID: detail.EMPLOYER_BENEFITS_ID,
                ENTITY_TYPE_ID: detail.ENTITY_TYPE_ID,
                ADDRESS: detail.ADDRESS,
                COUNTRY_ID: detail.COUNTRY_ID,
                STATE_ID: detail.STATE_ID,
                CITY_ID: detail.CITY_ID,
                PINCODE: detail.PINCODE,
                ADDRESS_PROOF_TYPE_ID: detail.ADDRESS_PROOF_TYPE_ID,
                UPLOAD_ADDRESS_PROOF: detail.UPLOAD_ADDRESS_PROOF,
                PAN_CARD_STATUS: detail.PAN_CARD_STATUS,
                NAME_ON_PAN: detail.NAME_ON_PAN,
                PAN_NUMBER: detail.PAN_NUMBER,
                UPLOAD_PAN: detail.UPLOAD_PAN,
                GST: detail.GST,
                GSTIN: detail.GSTIN,
                UPLOAD_GSTIN_CERTIFICATE: detail.UPLOAD_GSTIN_CERTIFICATE,
                TAN_NUMBER: detail.TAN_NUMBER,
                PROFILE_FOR_PREMIUM_FEATURED: detail.PROFILE_FOR_PREMIUM_FEATURED,
                PROFILE_FOR_REGULAR: detail.PROFILE_FOR_REGULAR,
                ABOUT_COMPANY: detail.ABOUT_COMPANY,
                TWITTER_URL: detail.TWITTER_URL,
                LISTING_TYPE: detail.LISTING_TYPE,
            })
            stateList(detail.COUNTRY_ID).then(res => {
                setStateList(res.result.list)
            }).catch(err => {
                alert(err)
            })



            cityList(detail.STATE_ID).then(res => {
                setCityList(res.result.list)
            }).catch(err => {
                alert(err)
            })
        }

        stateList('102').then(res => {
            setStateList(res.result.list)
        }).catch(err => {
            alert(err)
        })

        ActiveEmployerRoles().then(res => {
            if (res.status) {
                setRoleList(res.result)
            }
            else {
                alert(res.error)
            }
        }).catch(err => {
            alert(err)
        })

        activeCompanyType().then(res => {
            if (res.status) {
                setCompanyTypeList(res.result.list)
            }
            else {
                alert(res.error)
            }
        }).catch(err => {
            alert(err)
        })



        activeIndustryType().then(res => {
            if (res.status) {
                setIndustryTypeList(res.result.list)
            }
            else {
                alert(res.error)
            }
        }).catch(err => {
            alert(err)
        })



        activeEmployerBenefits().then(res => {
            if (res.status) {
                let result;
                if (detail) {

                    const arr = detail.EMPLOYER_BENEFITS_ID?.split(',');
                    result = res.result.list.map(item => {
                        if (arr?.includes(item?.EMPLOYER_BENEFITS_ID?.toString())) {
                            item.checked = true
                            return item
                        }
                        else {
                            item.checked = false
                            return item
                        }
                    })
                }
                else {
                    result = res.result.list.map(item => {
                        item.checked = false
                        return item
                    })
                }
                setEmployerBenefitsList(result)
            }
            else {
                alert(res.error)
            }
        }).catch(err => {
            alert(err)
        })
        activeEntityType().then(res => {
            if (res.status) {
                setEntityList(res.result)
            }
            else {
                alert(res.error)
            }
        }).catch(err => {
            alert(err)
        })

        countryList().then(res => {
            if (res.status) {
                setCountryList(res.result.list)
            }
            else {
                alert(res.error)

            }
        }).catch(err => {
            alert(err)
        })

        activeAddressproofType().then(res => {
            if (res.status) {
                setAddressProofList(res.result)
            }
            else {
                alert(res.error)
            }
        }).catch(err => {
            alert(err)
        })

    }, [])


    const handleChange = (e) => {
        const { name, value } = e.target



        if (name == "PAN_NUMBER") {
            let pan = data.PAN_NUMBER.concat(e.target.value.charAt(e.target.value.length - 1))
            if (!panValidation(pan)) {
                setPanError(true)
            }
            else {
                setData({
                    ...data,
                    [name]: value
                })
                setPanError(false)
            }
        }


        if (name == "GSTIN") {
            let gst = data.GSTIN.concat(e.target.value.charAt(e.target.value.length - 1))
            if (!gstValidation(gst)) {

                setGstError(true)
            }
            else {

                setData({
                    ...data,
                    [name]: value
                })
                setGstError(false)
            }
        }

        if (name == "TAN_NUMBER") {
            let tan = data.TAN_NUMBER.concat(e.target.value.charAt(e.target.value.length - 1))
            if (!tanValidation(tan)) {

                setTanError(true)
            }
            else {

                setData({
                    ...data,
                    [name]: value
                })
                setTanError(false)
            }
        }

        if (name === 'COUNTRY_ID') {
            stateList(value).then(res => {
                setStateList(res.result.list)
            }).catch(err => {
                alert(err)
            })
        }
        else if (name === 'STATE_ID') {
            cityList(value).then(res => {
                setCityList(res.result.list)
            }).catch(err => {
                alert(err)
            })
        }

        setData({
            ...data,
            [name]: value
        })


    }


    // function validate(objModel) {
    //     
    //     let status = false
    //     for (let [key, val] of Object.entries(objModel)) {

    //         console.log(objModel[key])

    //         if (key == "WEBSITE") {
    //             if (!isUrl(objModel[key].trim()))
    //                 return status
    //         } else if (key == "LINKEDIN_URL") {
    //             if (!isUrl(objModel[key].trim()))
    //                 return status
    //         } else if (key == "FB_URL") {
    //             if (!isUrl(objModel[key].trim()))
    //                 return status

    //         }
    //         else if (key === 'GSTIN') {
    //             if (objModel.GST === 'Y') {
    //                 return true
    //             }
    //             else {
    //                 if (objModel[key].trim().length == 0) {
    //                     return status
    //                 }
    //             }
    //         }
    //         else if (key === 'UPLOAD_GSTIN_CERTIFICATE') {
    //             if (objModel.GST === 'Y') {
    //                 return true
    //             }
    //             else {
    //                 if (objModel[key].trim().length == 0) {
    //                     return status
    //                 }
    //             }
    //         }
    //         else if (key === 'NAME_ON_PAN') {
    //             if (objModel.PAN_CARD_STATUS === 'N') {
    //                 return true
    //             }
    //             else {
    //                 if (objModel[key].trim().length == 0) {
    //                     return status
    //                 }
    //             }
    //         } else if (key === 'PAN_NUMBER') {
    //             if (objModel.PAN_CARD_STATUS === 'N') {
    //                 return true
    //             }
    //             else {
    //                 if (objModel[key].trim().length == 0) {
    //                     return status
    //                 }
    //             }
    //         } else if (key === 'UPLOAD_PAN') {
    //             if (objModel.PAN_CARD_STATUS === 'N') {
    //                 return true
    //             }
    //             else {
    //                 if (objModel[key].trim().length == 0) {
    //                     return status
    //                 }
    //             }
    //         }

    //         else {
    //             if (objModel[key].trim().length == 0) {
    //                 return status
    //             }

    //         }
    //     }

    //     return true
    // }

    const onSubmit = (e) => {
        e.preventDefault();
        const model = data;


        let pn = params.pageNo
        let BENEFIT_ID = employerBenefitsList.filter(item => item.checked === true)
        BENEFIT_ID = BENEFIT_ID.map(item => { return item.EMPLOYER_BENEFITS_ID }).toString()
        model.EMPLOYER_BENEFITS_ID = BENEFIT_ID
        model.pageNo = pn



        for (let [key, val] of Object.entries(model)) {
            if (model[key] === null) {
                model[key] = ""
            }
        }



        if (model.USERNAME.trim().length === 0 ||
            model.EMAIL.trim().length === 0 ||
            // model.SECONDARY_EMAIL.trim().length === 0 ||
            model.EMPLOYER_ROLE_ID.toString().trim().length === 0 ||
            model.PHONE_CODE.trim().length === 0 ||
            model.MOBILE.toString().trim().length === 0 ||
            model.COMPANY_NAME.trim().length === 0 ||
            model.COMPANY_TYPE_ID.toString().trim().length === 0 ||
            model.EMPLOYER_INDUSTRY_ID.toString().trim().length === 0 ||
            model.CONTACT_PERSON.trim().length === 0 ||
            model.WEBSITE.trim().length === 0 ||
            model.COMPANY_LOGO.trim().length === 0 ||
            model.COMPANY_HEADER_BACKGROUND.trim().length === 0 ||
            model.FB_URL.trim().length === 0 ||
            model.LINKEDIN_URL.trim().length === 0 ||
            model.COMPANY_SIZE.toString().trim().length === 0 ||
            model.FOUNDED.toString().trim().length < 4 ||
            model.HEADQUARTERS.trim().length === 0 ||
            model.ENTITY_TYPE_ID.toString().trim().length === 0 ||
            model.COUNTRY_ID.toString().trim().length === 0 ||
            model.STATE_ID.toString().trim().length === 0 ||
            model.CITY_ID.toString().trim().length === 0 ||
            model.PINCODE.toString().trim().length === 0 ||
            model.ADDRESS_PROOF_TYPE_ID.toString().trim().length === 0 ||
            model.UPLOAD_ADDRESS_PROOF.trim().length === 0 ||
            model.PAN_CARD_STATUS.trim().length === 0 ||
            // model.NAME_ON_PAN.trim().length === 0 ||
            // model.PAN_NUMBER.trim().length === 0 ||
            // model.UPLOAD_PAN.trim().length === 0 ||
            model.GST.trim().length === 0 ||
            // model.GSTIN.trim().length === 0 ||
            // model.UPLOAD_GSTIN_CERTIFICATE.trim().length === 0 ||
            model.TAN_NUMBER.trim().length === 0 ||
            model.PROFILE_FOR_PREMIUM_FEATURED.trim().length === 0 ||
            model.PROFILE_FOR_REGULAR.trim().length === 0 ||
            model.ABOUT_COMPANY.trim().length === 0 ||
            model.TWITTER_URL.trim().length === 0
            || model.LISTING_TYPE.trim().length === 0 ||
            !isUrl(model.WEBSITE.trim()) || !isUrl(model.FB_URL.trim()) ||
            !isUrl(model.LINKEDIN_URL.trim())) {
            setError(true)
        }
        else if ((model.GST.trim().length === 'N' && (model.GSTIN.trim().length === 0 ||
            model.UPLOAD_GSTIN_CERTIFICATE.trim().length === 0
        )) || (model.PAN_CARD_STATUS.trim().length === 'Y' && (model.NAME_ON_PAN.trim().length === 0 ||
            model.PAN_NUMBER.trim().length === 0 ||
            model.UPLOAD_PAN.trim().length === 0
        ))) {
            setError(true)

        }



        else {
            if (detail) {
                model.EMPLOYER_ID = detail.EMPLOYER_ID
            }
            props.onSubmit(model)
        }

    }
    const onReset = (e) => {

        e.preventDefault();
        if (detail) {
            navigate(constant.component.employerslist.url)
        }
        else {
            setError(false)
            setData({
                USERNAME: '', EMAIL: '', SECONDARY_EMAIL: '', EMPLOYER_ROLE_ID: '', PHONE_CODE: '+91', MOBILE: '', LOCATION: '', COMPANY_NAME: '', COMPANY_TYPE_ID: '',
                EMPLOYER_INDUSTRY_ID: '', CONTACT_PERSON: '', CONTACT_PERSON_DESIGNATION: '', WEBSITE: '', PHONE_1: '', PHONE_2: '', FAX_NUMBER: '', COMPANY_LOGO: '',
                COMPANY_HEADER_BACKGROUND: '', FB_URL: '', LINKEDIN_URL: '', COMPANY_SIZE: '', FOUNDED: '', HEADQUARTERS: '', EMPLOYER_BENEFITS_ID: '', ENTITY_TYPE_ID: '',
                ADDRESS: '', COUNTRY_ID: '', STATE_ID: '', CITY_ID: '', PINCODE: '', ADDRESS_PROOF_TYPE_ID: '', UPLOAD_ADDRESS_PROOF: '', PAN_CARD_STATUS: '',
                NAME_ON_PAN: '', PAN_NUMBER: '', UPLOAD_PAN: '', GST: '', GSTIN: '', UPLOAD_GSTIN_CERTIFICATE: '', TAN_NUMBER: '', PROFILE_FOR_PREMIUM_FEATURED: '',
                PROFILE_FOR_REGULAR: '', ABOUT_COMPANY: '', TWITTER_URL: '', LISTING_TYPE: ''
            })
        }

        const changedArry = []
        employerBenefitsList.map(item => {
            item.checked = false
            changedArry.push(item)
        })
        setEmployerBenefitsList(changedArry)
    }


    const onCheck = (e) => {
        const { id, checked, value } = e.target;
        const changedArry = []
        employerBenefitsList.map(item => {
            if (item.EMPLOYER_BENEFITS_ID == parseInt(id)) {
                item.checked = !item.checked
                changedArry.push(item)
            }
            else {
                changedArry.push(item)
            }
        })
        setEmployerBenefitsList(changedArry)
    }

    const uploadPAN = (e) => {
        e.preventDefault();
        let model = new FormData();
        model.append('file', e.target.files[0]);
        uploadCompanyPAN(model).then((res) => {
            if (res.status) {
                setData({
                    ...data,
                    [e.target.name]: res.result.fileName
                })

            } else {
                alert(res.error);
            }

        }).catch(err => {
            alert(err)
        })
    }

    const uploadaddrssproof = (e) => {
        e.preventDefault();
        let model = new FormData();
        model.append('file', e.target.files[0]);
        uploadAddressproof(model).then((res) => {
            if (res.status) {
                setData({
                    ...data,
                    [e.target.name]: res.result.fileName
                })

            } else {
                alert(res.error);
            }

        }).catch(err => {
            alert(err)
        })
    }



    const uploadHeader = (e) => {
        e.preventDefault();
        let model = new FormData();
        model.append('file', e.target.files[0]);
        uploadCompanyHeader(model).then((res) => {
            if (res.status) {
                setData({
                    ...data,
                    [e.target.name]: res.result.fileName
                })

            } else {
                alert(res.error);
            }

        }).catch(err => {
            alert(err)
        })

    }

    const uploadGST = (e) => {
        e.preventDefault();
        let model = new FormData();
        model.append('file', e.target.files[0]);
        uploadCompanyGST(model).then((res) => {
            if (res.status) {
                setData({
                    ...data,
                    [e.target.name]: res.result.fileName
                })

            } else {
                alert(res.error);
            }

        }).catch(err => {
            alert(err)
        })

    }
    const uploadLogo = (e) => {

        e.preventDefault();
        let model = new FormData();
        model.append('file', e.target.files[0]);

        uploadCompanyLogo(model).then((res) => {
            if (res.status) {
                setData({
                    ...data,
                    [e.target.name]: res.result.fileName
                })

            } else {
                alert(res.error);
            }

        }).catch(err => {
            alert(err)
        })

    }

    return (
        <React.Fragment>
            <div className='main-content'>
                <div className='page-content'>
                    <div className='container-fluid'>
                        <div class="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">{detail ? "Update Employer" : "Add Employer"}</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="javascript: void(0);">Employers</a></li>
                                            <li className="breadcrumb-item active">{detail ? "Update Employer" : "Add Employer"}</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4" style={{ color: "#f46d6d" }}>ACCOUNT DETAILS</h4>
                                        {
                                            props.showMsg && (props.response.status ?
                                                <SuccessMessage message={props.response.messageCode} />
                                                :
                                                <ErrorMessage message={props.response.error} />)
                                        }
                                        <div id="basic-example">
                                            <section>
                                                <form onSubmit={onSubmit} onReset={onReset} autocomplete="on">
                                                    <div className="row">
                                                        <div className="col-lg-3">
                                                            <div className="mb-3">
                                                                <label for="basicpill-firstname-input">Username<span className='text-danger'> *</span></label>
                                                                <input
                                                                    type="text"
                                                                    className={error && data.USERNAME.trim().length === 0 ? "form-control is-invalid" : "form-control"}
                                                                    id="basicpill-firstname-input"
                                                                    placeholder="Enter Username"
                                                                    value={data.USERNAME}
                                                                    name='USERNAME'
                                                                    onChange={handleChange}
                                                                />
                                                                {error && data.USERNAME.trim().length === 0 && <span className='text-danger'>This field is required</span>}
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-3">
                                                            <div className="mb-3">
                                                                <label for="basicpill-lastname-input">Primary Email<span className='text-danger'> *</span></label>
                                                                <input
                                                                    type="email"
                                                                    className={error && data.EMAIL.trim().length === 0 ? "form-control is-invalid" : "form-control"}
                                                                    id="basicpill-lastname-input"
                                                                    placeholder="Enter Primary Email"
                                                                    value={data.EMAIL}
                                                                    name='EMAIL'
                                                                    onChange={handleChange}
                                                                />
                                                                {error && data.EMAIL.trim().length === 0 && <span className='text-danger'>This field is required</span>}

                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <div className="mb-3">
                                                                <label for="basicpill-lastname-input">Secondry Email</label>
                                                                <input
                                                                    type="email"
                                                                    className="form-control"
                                                                    id="basicpill-lastname-input"
                                                                    placeholder="Enter Secondry"
                                                                    value={data.SECONDARY_EMAIL}
                                                                    name='SECONDARY_EMAIL'
                                                                    onChange={handleChange}

                                                                />
                                                                {/* {error && data.SECONDARY_EMAIL.trim().length === 0 && <span className='text-danger'>This field is required</span>} */}

                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <div className="mb-3">
                                                                <label for="basicpill-firstname-input">Role<span className='text-danger'> *</span></label>
                                                                <select
                                                                    className={error && data.EMPLOYER_ROLE_ID.toString().trim().length === 0 ? "form-select is-invalid" : "form-select"}
                                                                    value={data.EMPLOYER_ROLE_ID}
                                                                    name='EMPLOYER_ROLE_ID'
                                                                    onChange={handleChange}
                                                                >
                                                                    <option value=''>Select Role</option>
                                                                    {
                                                                        roleList.length > 0 && roleList.map((item, index) => {
                                                                            return (
                                                                                <option key={index} value={item.EMPLOYER_ROLE_ID}>{item.EMPLOYER_ROLE}</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </select>
                                                                {error && data.EMPLOYER_ROLE_ID.toString().trim().length === 0 && <span className='text-danger'>This field is required</span>}

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-1">
                                                            <div className="mb-2">
                                                                <label for="basicpill-phoneno-input">Code<span className='text-danger'> *</span></label>
                                                                <select
                                                                    className={error && data.PHONE_CODE.trim().length === 0 ? "form-select is-invalid" : "form-select"}
                                                                    name='PHONE_CODE'
                                                                    value={data.PHONE_CODE}
                                                                    onChange={handleChange}
                                                                >
                                                                    <option value={+91}>+91</option>
                                                                    {phone_code && phone_code.length > 0
                                                                        && phone_code.map((item, index) => {
                                                                            return (
                                                                                < option key={index} value={item.dial_code}> {item.dial_code}</option>
                                                                            )
                                                                        })

                                                                    }
                                                                </select>

                                                            </div>
                                                        </div>

                                                        <div className="col-lg-2">
                                                            <div className="mb-3">
                                                                <label for="basicpill-phoneno-input">Mobile<span className='text-danger'> *</span></label>
                                                                <NumberFormat
                                                                    className="form-control"
                                                                    id="basicpill-phoneno-input"
                                                                    placeholder="Enter Mobile"
                                                                    onChange={handleChange}
                                                                    name='MOBILE'
                                                                    maxlength={10}
                                                                    minlength={10}
                                                                    value={data.MOBILE}
                                                                />
                                                                {/* {error && data.MOBILE.trim().length === 0 && <span className='text-danger'>This field is required</span>} */}

                                                            </div>
                                                        </div>

                                                        <div className="col-lg-3">
                                                            <div className="mb-3">
                                                                <label for="basicpill-firstname-input">Location</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="basicpill-firstname-input"
                                                                    placeholder="Enter Current Location"
                                                                    onChange={handleChange}
                                                                    name='LOCATION'
                                                                    value={data.LOCATION}
                                                                />

                                                            </div>
                                                        </div>

                                                    </div>

                                                    <h4 className="card-title mb-4" style={{ marginTop: "16px", color: "#f46d6d" }}>COMPANY INFORMATION </h4>

                                                    <div className="row">

                                                        <div className="col-lg-3">
                                                            <div className="mb-3">
                                                                <label for="basicpill-firstname-input">Company Name<span className='text-danger'> *</span></label>
                                                                <input
                                                                    type="text"
                                                                    className={error && data.COMPANY_NAME.trim().length === 0 ? "form-control is-invalid" : "form-control"}
                                                                    id="basicpill-phoneno-input"
                                                                    placeholder="Enter Company Name"
                                                                    onChange={handleChange}
                                                                    name='COMPANY_NAME'
                                                                    value={data.COMPANY_NAME}
                                                                />
                                                                {error && data.COMPANY_NAME
                                                                    .trim().length === 0 && <span className='text-danger'>This field is required</span>}

                                                            </div>
                                                        </div>

                                                        <div className="col-lg-3">
                                                            <div className="mb-3">
                                                                <label for="basicpill-firstname-input">Company Type<span className='text-danger'> *</span></label>
                                                                <select
                                                                    className={error && data.COMPANY_TYPE_ID.toString().trim().length === 0 ? "form-select is-invalid" : "form-select"}
                                                                    name='COMPANY_TYPE_ID'
                                                                    value={data.COMPANY_TYPE_ID}
                                                                    onChange={handleChange} >

                                                                    <option>Select Company Type</option>
                                                                    {
                                                                        companyTypeList.length > 0 && companyTypeList.map((item, index) => {
                                                                            return (
                                                                                <option key={index} value={item.COMPANY_TYPE_ID}>{item.COMPANY_TYPE}</option>
                                                                            )
                                                                        })

                                                                    }

                                                                </select>
                                                                {error && data.COMPANY_TYPE_ID.toString().trim().length === 0 && <span className='text-danger'>This field is required</span>}

                                                            </div>
                                                        </div>

                                                        <div className="col-lg-3">
                                                            <div className="mb-3">
                                                                <label for="basicpill-firstname-input">Industry Type<span className='text-danger'> *</span></label>
                                                                <select className={error && data.EMPLOYER_INDUSTRY_ID.toString().trim().length === 0 ? "form-select is-invalid" : "form-select"}
                                                                    value={data.EMPLOYER_INDUSTRY_ID}
                                                                    name='EMPLOYER_INDUSTRY_ID'
                                                                    onChange={handleChange}

                                                                >
                                                                    <option>Select Industry Type</option>
                                                                    {industryTypeList.length > 0 && industryTypeList.map((item, index) => {
                                                                        return (
                                                                            <option key={index} value={item.EMPLOYER_INDUSTRY_ID}>{item.INDUSTRY}</option>
                                                                        )

                                                                    })}
                                                                </select>
                                                                {error && data.EMPLOYER_INDUSTRY_ID.toString().trim().length === 0 && <span className='text-danger'>This field is required</span>}

                                                            </div>
                                                        </div>

                                                        <div className="col-lg-3">
                                                            <div className="mb-3">
                                                                <label for="basicpill-firstname-input">Contact Person<span className='text-danger'> *</span></label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="basicpill-firstname-input"
                                                                    placeholder="Enter Contact Person"
                                                                    name='CONTACT_PERSON'
                                                                    value={data.CONTACT_PERSON}
                                                                    onChange={handleChange}
                                                                />
                                                                {/* {error && data.CONTACT_PERSON.trim().length === 0 && <span className='text-danger'>This field is required</span>} */}

                                                            </div>

                                                        </div>

                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-3">
                                                            <div className="mb-3">
                                                                <label for="basicpill-firstname-input">Contact Person's Designation</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="basicpill-firstname-input"
                                                                    placeholder="Enter Contact Person's Designation"
                                                                    name='CONTACT_PERSON_DESIGNATION'
                                                                    onChange={handleChange}
                                                                    value={data.CONTACT_PERSON_DESIGNATION}
                                                                />

                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <div className="mb-3">
                                                                <label for="basicpill-firstname-input">Website URL<span className='text-danger'> *</span></label>
                                                                <input
                                                                    type="text"
                                                                    className={error && data.WEBSITE.trim().length === 0 || error && !isUrl(data.WEBSITE.trim()) ? "form-control is-invalid" : "form-control"}
                                                                    id="basicpill-firstname-input"
                                                                    placeholder="Enter Website URL"
                                                                    name='WEBSITE'
                                                                    value={data.WEBSITE}
                                                                    onChange={handleChange}
                                                                />
                                                                {error && data.WEBSITE.trim().length === 0 ? <span className='text-danger'>This field is required</span> : error && !isUrl(data.WEBSITE.trim()) && <span className='text-danger'>Incorrect Url</span>}


                                                            </div>

                                                        </div>
                                                        <div className="col-lg-3">
                                                            <div className="mb-3">
                                                                <label for="basicpill-firstname-input">Phone 1</label>
                                                                <NumberFormat
                                                                    className="form-control"
                                                                    id="basicpill-firstname-input"
                                                                    placeholder="Enter Phone 1"
                                                                    name='PHONE_1'
                                                                    maxlength={10}
                                                                    minlength={10}
                                                                    value={data.PHONE_1}
                                                                    onChange={handleChange}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <div className="mb-3">
                                                                <label for="basicpill-firstname-input">Phone 2</label>
                                                                <NumberFormat
                                                                    className="form-control"
                                                                    id="basicpill-firstname-input"
                                                                    placeholder="Enter Phone 1"
                                                                    name='PHONE_2'
                                                                    maxlength={10}
                                                                    minlength={10}
                                                                    value={data.PHONE_2}
                                                                    onChange={handleChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-3">
                                                            <div className="mb-3">
                                                                <label for="basicpill-firstname-input">FAX Number</label>
                                                                <NumberFormat
                                                                    className="form-control"
                                                                    id="basicpill-firstname-input"
                                                                    placeholder="Enter FAX Number"
                                                                    name='FAX_NUMBER'
                                                                    maxlength={10}
                                                                    minlength={10}
                                                                    value={data.FAX_NUMBER}
                                                                    onChange={handleChange}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <div className="mb-3">
                                                                <label for="basicpill-firstname-input">Company Logo<span className='text-danger'> *</span></label>
                                                                <input
                                                                    type="file"
                                                                    className={error && data.COMPANY_LOGO.trim().length === 0 ? "form-control is-invalid" : "form-control"}
                                                                    id="basicpill-firstname-input"
                                                                    accept=".jpg,.png,.jpeg,"
                                                                    placeholder="Company Logo"
                                                                    name='COMPANY_LOGO'
                                                                    onChange={uploadLogo}

                                                                />
                                                                {detail ? <a target='_blank' href={`${process.env.REACT_APP_BASE_URL}/company/logo/${detail.COMPANY_LOGO}`}>{detail.COMPANY_LOGO}</a> : ""}
                                                                {error && data.COMPANY_LOGO.trim().length === 0 && <span className='text-danger'>This field is required</span>}

                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <div className="mb-3">
                                                                <label for="basicpill-firstname-input">Company Header Background<span className='text-danger'> *</span></label>
                                                                <input
                                                                    type="file"
                                                                    className={error && data.COMPANY_HEADER_BACKGROUND.trim().length === 0 ? "form-control is-invalid" : "form-control"}
                                                                    id="basicpill-firstname-input"
                                                                    accept=".jpg,.png,.jpeg,"
                                                                    placeholder="Company Header"
                                                                    name='COMPANY_HEADER_BACKGROUND'
                                                                    onChange={uploadHeader}
                                                                />
                                                                {detail ? < a target='_blank' href={`${process.env.REACT_APP_BASE_URL}/company/header-background/${detail.COMPANY_HEADER_BACKGROUND}`}>{detail.COMPANY_HEADER_BACKGROUND}</a> : ""}
                                                                {error && data.COMPANY_HEADER_BACKGROUND.trim().length === 0 && <span className='text-danger'>This field is required</span>}

                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <div className="mb-3">
                                                                <label for="basicpill-firstname-input">Facebook Page URL<span className='text-danger'> *</span></label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="basicpill-firstname-input"
                                                                    placeholder="Enter Facebook Page URL"
                                                                    name='FB_URL'
                                                                    value={data.FB_URL}
                                                                    onChange={handleChange}

                                                                />

                                                                {error && data.FB_URL.trim().length === 0 ? <span className='text-danger'>This field is required</span> : error && !isUrl(data.FB_URL.trim()) && <span className='text-danger'>Incorrect Url</span>}

                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <div className="mb-3">
                                                                <label for="basicpill-firstname-input">Linkedin Profile URL<span className='text-danger'> *</span></label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="basicpill-firstname-input"
                                                                    placeholder="Enter Linkedin Profile URL"
                                                                    name='LINKEDIN_URL'
                                                                    value={data.LINKEDIN_URL}
                                                                    onChange={handleChange}
                                                                />
                                                                
                                                                {error && data.LINKEDIN_URL.trim().length === 0 ? <span className='text-danger'>This field is required</span> : error && !isUrl(data.LINKEDIN_URL.trim()) && <span className='text-danger'>Incorrect Url</span>}

                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <div className="mb-3">
                                                                <label for="basicpill-firstname-input">Twitter URL<span className='text-danger'> *</span></label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="basicpill-firstname-input"
                                                                    placeholder="Enter Twitter URL"
                                                                    name='TWITTER_URL'
                                                                    value={data.TWITTER_URL}
                                                                    onChange={handleChange}
                                                                />

                                                                {error && data.TWITTER_URL.trim().length === 0 ? <span className='text-danger'>This field is required</span> : error && !isUrl(data.TWITTER_URL.trim()) && <span className='text-danger'>Incorrect Url</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <div className="mb-3">
                                                                <label for="basicpill-firstname-input">Company Size<span className='text-danger'> *</span></label>
                                                                <NumberFormat
                                                                    className={error && data.COMPANY_SIZE.toString().trim().length === 0 ? "form-control is-invalid" : "form-control"} id="basicpill-firstname-input"
                                                                    placeholder="Enter Company Size"
                                                                    name='COMPANY_SIZE'
                                                                    value={data.COMPANY_SIZE}
                                                                    onChange={handleChange}
                                                                    minlength={1}
                                                                    maxlength={7}
                                                                />
                                                                {error && data.COMPANY_SIZE.toString().trim().length === 0 && <span className='text-danger'>This field is required</span>}

                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <div className="mb-3">
                                                                <label for="basicpill-firstname-input">Founded<span className='text-danger'> *</span></label>
                                                                <NumberFormat
                                                                    className={error && data.FOUNDED.toString().trim().length === 0 ? "form-control is-invalid" : "form-control"} id="basicpill-firstname-input"
                                                                    placeholder="Enter Founded Year"
                                                                    name='FOUNDED'
                                                                    value={data.FOUNDED}
                                                                    onChange={handleChange}
                                                                    minlength={4}
                                                                    maxlength={4}
                                                                />
                                                                {error && data.FOUNDED.toString().trim().length === 0 && <span className='text-danger'>This field is required</span>}

                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <div className="mb-3">
                                                                <label for="basicpill-firstname-input">Headquarters<span className='text-danger'> *</span></label>
                                                                <input
                                                                    type="text"
                                                                    className={error && data.HEADQUARTERS.trim().length === 0 ? "form-control is-invalid" : "form-control"} id="basicpill-firstname-input"
                                                                    placeholder="Enter Company Headquarters"
                                                                    name='HEADQUARTERS'
                                                                    value={data.HEADQUARTERS}
                                                                    onChange={handleChange}
                                                                />
                                                                {error && data.HEADQUARTERS.trim().length === 0 && <span className='text-danger'>This field is required</span>}

                                                            </div>
                                                        </div>

                                                    </div>

                                                    <h4 className="card-title mb-4" style={{ marginTop: "16px", color: "#f46d6d" }}>EMPLOYEE BENEFITS</h4>
                                                    <div className="row">
                                                        {employerBenefitsList.length > 0 && employerBenefitsList.map((item, index) => {
                                                            return (
                                                                <div className="col-lg-2">
                                                                    <div className="mb-3">
                                                                        <Form.Check
                                                                            type="checkbox"
                                                                            id={item.EMPLOYER_BENEFITS_ID}
                                                                            label={item.EMPLOYER_BENEFITS}
                                                                            onClick={onCheck}
                                                                            checked={item.checked}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>

                                                    <h4 className="card-title mb-4" style={{ marginTop: "14px", color: "#f46d6d" }}>KYC INFORMATION <br />
                                                        <span style={{ fontWeight: "normal", fontSize: "12px", color: "#804040" }}>Your company details are required to meet KYC compliance</span></h4>

                                                    <div className="row">

                                                        <div className="col-lg-3">
                                                            <div className="mb-4">
                                                                <label for="basicpill-phoneno-input">Entity type<span className='text-danger'> *</span></label>
                                                                <select
                                                                    className="form-select"
                                                                    name='ENTITY_TYPE_ID'
                                                                    value={data.ENTITY_TYPE_ID}
                                                                    onChange={handleChange}
                                                                >

                                                                    <option value=''>Select Entity Type</option>
                                                                    {
                                                                        entityList && entityList.length > 0 && entityList.map((item, index) => {
                                                                            return (
                                                                                <option key={index} value={item.ENTITY_TYPE_ID}>{item.ENTITY_TYPE}</option>
                                                                            )
                                                                        })

                                                                    }
                                                                </select>
                                                                {/* {error && data.ENTITY_TYPE_ID.toString().trim().length === 0 && <span className='text-danger'>This field is required</span>} */}

                                                            </div>
                                                        </div>

                                                        <div className="col-lg-3">
                                                            <div className="mb-3">
                                                                <label for="basicpill-phoneno-input">Address</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="basicpill-phoneno-input"
                                                                    placeholder="Enter Address"
                                                                    name='ADDRESS'
                                                                    value={data.ADDRESS}
                                                                    onChange={handleChange}

                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-3">
                                                            <div className="mb-3">
                                                                <label for="basicpill-phoneno-input">Country<span className='text-danger'> *</span></label>
                                                                <select
                                                                    className={error && data.COUNTRY_ID.toString().trim().length === 0 ? "form-select is-invalid" : "form-select"}
                                                                    name='COUNTRY_ID'
                                                                    value={data.COUNTRY_ID}
                                                                    onChange={handleChange}
                                                                    disabled={true}

                                                                >
                                                                    <option value=''>Select Country</option>
                                                                    {countrylist.length > 0 && countrylist.map((item, index) => {
                                                                        return (
                                                                            <option key={index} value={item.COUNTRY_ID}>{item.COUNTRY}</option>
                                                                        )
                                                                    })}

                                                                </select>
                                                                {error && data.COUNTRY_ID.toString().trim().length === 0 && <span className='text-danger'>This field is required</span>}

                                                            </div>
                                                        </div>

                                                        <div className="col-lg-3">
                                                            <div className="mb-3">
                                                                <label for="basicpill-phoneno-input">State<span className='text-danger'> *</span></label>
                                                                <select
                                                                    className="form-select"

                                                                    name='STATE_ID'
                                                                    value={data.STATE_ID}
                                                                    onChange={handleChange}>

                                                                    <option value=''>Select State</option>
                                                                    {
                                                                        statelist.length > 0 && statelist.map((item, index) => {
                                                                            return (
                                                                                <option key={index} value={item.STATE_ID}>{item.STATE}</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </select>
                                                                {/* {error && data.STATE_ID.toString().trim().length === 0 && <span className='text-danger'>This field is required</span>} */}

                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className="row">

                                                        <div className="col-lg-3">
                                                            <div className="mb-4">
                                                                <label for="basicpill-phoneno-input">City<span className='text-danger'> *</span></label>
                                                                <select
                                                                    className="form-select"

                                                                    name='CITY_ID'
                                                                    value={data.CITY_ID}
                                                                    onChange={handleChange}
                                                                >
                                                                    <option value=''>Select City</option>
                                                                    {
                                                                        citylist.length && citylist.map((item, index) => {
                                                                            return (
                                                                                <option key={index} value={item.CITY_ID}>{item.CITY}</option>
                                                                            )


                                                                        })
                                                                    }
                                                                </select>
                                                                {/* {error && data.CITY_ID.toString().trim().length === 0 && <span className='text-danger'>This field is required</span>} */}

                                                            </div>
                                                        </div>

                                                        <div className="col-lg-3">
                                                            <div className="mb-3">
                                                                <label for="basicpill-phoneno-input">PIN<span className='text-danger'> *</span></label>
                                                                <NumberFormat
                                                                    className={error && data.PINCODE.toString().trim().length === 0 ? "form-control is-invalid" : "form-control"}
                                                                    id="basicpill-phoneno-input"
                                                                    placeholder="Enter PIN"
                                                                    name='PINCODE'
                                                                    value={data.PINCODE}
                                                                    maxlength={6}
                                                                    minlength={6}
                                                                    onChange={handleChange}
                                                                />
                                                                {error && data.PINCODE.toString().trim().length === 0 && <span className='text-danger'>This field is required</span>}

                                                            </div>
                                                        </div>

                                                        <div className="col-lg-3">
                                                            <div className="mb-3">
                                                                <label for="basicpill-phoneno-input">Address Proof Type<span className='text-danger'> *</span></label>
                                                                <select
                                                                    className="form-select"
                                                                    name='ADDRESS_PROOF_TYPE_ID'
                                                                    value={data.ADDRESS_PROOF_TYPE_ID}
                                                                    onChange={handleChange}
                                                                >
                                                                    <option value=''>Select Proof Type</option>
                                                                    {
                                                                        addressProofList.length && addressProofList.map((item, index) => {
                                                                            return (
                                                                                <option key={index} value={item.ADDRESS_PROOF_TYPE_ID}>{item.ADDRESS_PROOF_TYPE}</option>
                                                                            )
                                                                        })
                                                                    }

                                                                </select>
                                                                {/* {error && data.ADDRESS_PROOF_TYPE_ID.toString().trim().length === 0 && <span className='text-danger'>This field is required</span>} */}

                                                            </div>
                                                        </div>

                                                        <div className="col-lg-3">
                                                            <div className="mb-3">
                                                                <label for="basicpill-phoneno-input">Upload Address Proof<span className='text-danger'> *</span></label>
                                                                <input
                                                                    type="file"
                                                                    className="form-control"
                                                                    id="basicpill-phoneno-input"
                                                                    placeholder="Upload Address Proof"
                                                                    name='UPLOAD_ADDRESS_PROOF'
                                                                    accept=".pdf,.jpeg,.png,.doc,.docx "
                                                                    onChange={uploadaddrssproof}
                                                                />
                                                                {detail ? <a target='_blank' href={`${process.env.REACT_APP_BASE_URL}/company/address-proof/${detail.UPLOAD_ADDRESS_PROOF}`}>{detail.UPLOAD_ADDRESS_PROOF}</a> : ""}

                                                                {detail ? "" :
                                                                    <span className='text-danger' style={{ fontSize: "10px" }}>Only pdf, jpeg, png, doc or docx allowed.</span>}
                                                                <br />
                                                                {/* {error && data.UPLOAD_ADDRESS_PROOF.toString().trim().length === 0 && <span className='text-danger'>This field is required</span>} */}

                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className="row">

                                                        <div className="col-lg-3">
                                                            <div className="mb-2">
                                                                <label for="basicpill-phoneno-input">PAN Card Status<span className='text-danger'> *</span></label>
                                                                <select className="form-select"
                                                                    value={data.PAN_CARD_STATUS}
                                                                    name='PAN_CARD_STATUS'
                                                                    onChange={handleChange}
                                                                >
                                                                    <option value='Y'>I have company's PAN card</option>
                                                                    <option value='N'>Applied for company's PAN card</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        {data.PAN_CARD_STATUS == "Y" && (<div className="col-lg-3">
                                                            <div className="mb-3">
                                                                <label for="basicpill-phoneno-input">Name on PAN card<span className='text-danger'> *</span></label>
                                                                <input
                                                                    type="text"
                                                                    className='form-control'
                                                                    // className={error && data.NAME_ON_PAN.trim().length === 0 ? "form-control is-invalid" : "form-control"}
                                                                    id="basicpill-phoneno-input"
                                                                    placeholder="Enter Name on PAN card"
                                                                    name='NAME_ON_PAN'
                                                                    value={data.NAME_ON_PAN}
                                                                    onChange={handleChange}
                                                                />
                                                                {/* {error && data.NAME_ON_PAN.toString().trim().length === 0 && <span className='text-danger'>This field is required</span>} */}

                                                            </div>
                                                        </div>
                                                        )}

                                                        {data.PAN_CARD_STATUS == "Y" && (<div className="col-lg-3">
                                                            <div className="mb-3">
                                                                <label for="basicpill-phoneno-input">PAN<span className='text-danger'> *</span></label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="basicpill-phoneno-input"
                                                                    placeholder="Enter PAN Number"
                                                                    name='PAN_NUMBER'
                                                                    value={data.PAN_NUMBER}
                                                                    onChange={handleChange}

                                                                />
                                                                {panError && <span className='text-danger ml-1'> Please Enter a Valid PAN Number</span>}

                                                                {/* {error && data.PAN_NUMBER.toString().trim().length === 0 && <span className='text-danger'>This field is required</span>} */}

                                                            </div>
                                                        </div>
                                                        )}


                                                        {data.PAN_CARD_STATUS == "Y" && (<div className="col-lg-3">
                                                            <div className="mb-3">
                                                                <label for="basicpill-phoneno-input">Upload PAN<span className='text-danger'> *</span></label>
                                                                <input
                                                                    type="file"
                                                                    className="form-control"
                                                                    id="basicpill-phoneno-input"
                                                                    placeholder="Upload PAN"
                                                                    name='UPLOAD_PAN'
                                                                    accept=".pdf,.jpeg,.png,.doc,.docx "
                                                                    onChange={uploadPAN}
                                                                />
                                                                {detail ? <a target='_blank' href={`${process.env.REACT_APP_BASE_URL}/company/pan/${detail.UPLOAD_PAN}`}>{detail.UPLOAD_PAN}</a> : ""}
                                                                {detail ? "" : <span className='text-danger' style={{ fontSize: "10px" }}>Only pdf, jpeg, png, doc or docx allowed.</span>}
                                                                <br />
                                                                {/* {error && data.UPLOAD_PAN.toString().trim().length === 0 && <span className='text-danger'>This field is required</span>} */}

                                                            </div>
                                                        </div>

                                                        )}
                                                    </div>





                                                    <div className="row">
                                                        <div className="col-lg-3">
                                                            <div className="mb-2">
                                                                <label for="basicpill-phoneno-input">GST<span className='text-danger'> *</span></label>
                                                                <select
                                                                    className="form-select"
                                                                    onChange={handleChange}
                                                                    name='GST'
                                                                    value={data.GST}
                                                                >
                                                                    <option value={''} > Select GST </option>
                                                                    <option value='N' >Registered</option>
                                                                    <option value='Y'>Unregistered</option>
                                                                </select>
                                                            </div>
                                                        </div>


                                                        {data.GST !== "Y" ? (
                                                            <div className="col-lg-3">
                                                                <div className="mb-3">
                                                                    <label for="basicpill-phoneno-input">GSTIN<span className='text-danger'> *</span></label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="basicpill-phoneno-input"
                                                                        placeholder="Enter GSTIN"
                                                                        name='GSTIN'
                                                                        value={data.GSTIN}
                                                                        onChange={handleChange}
                                                                    />

                                                                    {/* {error && data.GSTIN.toString().trim().length <= 0 ? <span className='text-danger'>This field is required</span> : ''} */}


                                                                </div>
                                                            </div>

                                                        ) : ''}

                                                        {data.GST !== "Y" ? (<div className="col-lg-3">
                                                            <div className="mb-3">
                                                                <label for="basicpill-lastname-input">Upload GSTIN Certificate<span className='text-danger'> *</span></label>
                                                                <input
                                                                    type="file"
                                                                    className="form-control"
                                                                    id="basicpill-lastname-input"
                                                                    placeholder="Upload GSTIN Certificate"
                                                                    accept=".pdf,.jpeg,.png,.doc,.docx "
                                                                    name='UPLOAD_GSTIN_CERTIFICATE'
                                                                    onChange={uploadGST}
                                                                />
                                                                {detail ? <a target='_blank' href={`${process.env.REACT_APP_BASE_URL}/company/gst-certificate/${detail.UPLOAD_GSTIN_CERTIFICATE}`}>{detail.UPLOAD_GSTIN_CERTIFICATE}</a> : ""}
                                                                {detail ? "" : <span className='text-danger' style={{ fontSize: "10px" }}>Only pdf, jpeg, png, doc or docx allowed.</span>}
                                                                <br />
                                                                {/* {error && data.UPLOAD_GSTIN_CERTIFICATE.toString().trim().length === 0 && <span className='text-danger'>This field is required</span>} */}

                                                            </div>
                                                        </div>
                                                        ) : ''}





                                                        <div className="col-lg-3">
                                                            <div className="mb-3">
                                                                <label for="basicpill-firstname-input">TAN Number<span className='text-danger'> *</span></label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="basicpill-firstname-input"
                                                                    placeholder="Enter TAN Number"
                                                                    name='TAN_NUMBER'
                                                                    value={data.TAN_NUMBER}
                                                                    onChange={handleChange}
                                                                />
                                                                {/* {tanError && <span className='text-danger ml-1'> Please Enter a Valid TAN Number</span>}
                                                                {error && data.TAN_NUMBER.toString().trim().length === 0 && <span className='text-danger'>This field is required</span>} */}

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <h4 className="card-title mb-4" style={{ marginTop: "14px", color: "#f46d6d" }}>PROFILE</h4>
                                                    <div className='row'>
                                                        <div className="col-lg-4">
                                                            <div className="mb-3">
                                                                <label for="basicpill-address-input">Listing Type <span className='text-danger'>*</span></label>
                                                                <select
                                                                    className="form-control form-select"
                                                                    onChange={handleChange}
                                                                    name="LISTING_TYPE"
                                                                    value={data.LISTING_TYPE}
                                                                >
                                                                    <option value='R'>Regular</option>
                                                                    <option value='F'>Featured</option>
                                                                    <option value='P'>Premium</option>
                                                                </select>
                                                                {/* {error && data.LISTING_TYPE.toString().trim().length === 0 && <span className='text-danger'>This field is required</span>} */}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label for="basicpill-address-input">Profile for Featured & Premium Job Listing<span className='text-danger'> *</span></label>
                                                                <textarea
                                                                    id="basicpill-address-input"
                                                                    className="form-control"
                                                                    rows="2"
                                                                    placeholder="Enter Profile for Featured & Premium Job Listing"
                                                                    name='PROFILE_FOR_PREMIUM_FEATURED'
                                                                    value={data.PROFILE_FOR_PREMIUM_FEATURED}
                                                                    onChange={handleChange}
                                                                ></textarea>
                                                                {/* {error && data.PROFILE_FOR_PREMIUM_FEATURED.toString().trim().length === 0 && <span className='text-danger'>This field is required</span>} */}

                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label for="basicpill-address-input">Profile for Regular Job Listing<span className='text-danger'> *</span></label>
                                                                <textarea
                                                                    id="basicpill-address-input"
                                                                    className="form-control"
                                                                    rows="2"
                                                                    placeholder="Enter Profile for Regular Job Listing"
                                                                    name='PROFILE_FOR_REGULAR'
                                                                    value={data.PROFILE_FOR_REGULAR}
                                                                    onChange={handleChange}
                                                                ></textarea>
                                                                {/* {error && data.PROFILE_FOR_REGULAR.toString().trim().length === 0 && <span className='text-danger'>This field is required</span>} */}

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="mb-3">
                                                                <label for="basicpill-address-input">About Company<span className='text-danger'> *</span></label>
                                                                <textarea
                                                                    id="basicpill-address-input"
                                                                    className={error && data.ABOUT_COMPANY.trim().length === 0 ? "form-control is-invalid" : "form-control"}
                                                                    rows="2"
                                                                    placeholder="Enter About Company"
                                                                    name='ABOUT_COMPANY'
                                                                    value={data.ABOUT_COMPANY}
                                                                    onChange={handleChange}
                                                                ></textarea>
                                                                {/* {error && data.ABOUT_COMPANY.toString().trim().length === 0 && <span className='text-danger'>This field is required</span>} */}

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <button type="submit" className="btn btn-info waves-effect btn-label waves-light"><i className="bx bx-check-double label-icon"></i>{detail ? "Save Changes" : "Create Employer"}</button>
                                                                &nbsp;&nbsp;
                                                                <button type="reset" className="btn btn-danger waves-effect btn-label waves-light"><i className="bx bx-block label-icon "></i> Cancel</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </section>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}
