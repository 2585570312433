
import * as axios from "axios";
import { getAuthHeader } from "./../utils";

export const createEmployer = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/create-employer`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const listEmployer = (data) => {
    const url = `${process.env.REACT_APP_BASE_URL}/employer-list?KEYWORD=${data.KEYWORD}&page=${data.page}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const deleteempnotes = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/cp-employer-notes-delete?NOTES_ID=${model.NOTES_ID}`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

// export const listFeaturedEmployer = (data) => {
//     const url = `${process.env.REACT_APP_BASE_URL}/employer-list?EMPLOYER_ID=${data.EMPLOYER_ID}`;
//     return axios.get(url, getAuthHeader()).then((res) => {
//         return res.data;
//     })
// };

export const listEmployernotes = (EMPLOYER_ID) => {
    const url = `${process.env.REACT_APP_BASE_URL}/cp-employer-notes?EMPLOYER_ID=${EMPLOYER_ID}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const createEmployernotes = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/create-employer-notes`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};


export const updateEmployer = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/update-employer`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const employerDetailsByID = (EMPLOYER_ID) => {
    const url = `${process.env.REACT_APP_BASE_URL}/employer-details-id?EMPLOYER_ID=${EMPLOYER_ID}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const updateEmployerStatus = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/update-employer-status`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const createCompanyType = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/create-company-type`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};


export const UpdateCompanyType = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/update-company-type`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const listCompanyType = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/company-type-list?page=${model.page}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const companyTypeById = (id) => {
    const url = `${process.env.REACT_APP_BASE_URL}/CompanyType-By-Id/?COMPANY_TYPE_ID=${id}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const archiveCompanyType = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/archive-company-type`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const updateemployers = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/update-employer-industry-admin`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};


export const createEmployerIndustry = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/create-employer-industry`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const activeCompanyType = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/active-company-type`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};


export const activeIndustryType = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/active-industry-type`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const appCategoryType = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/app-category`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const employerIndustryList = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/employer-industry-list?page=${model.page}&KEYWORD=${model.KEYWORD}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};


export const archiveIndustry = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/archive-employer-industry`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};


export const updateEmployerIndustry = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/update-employer-industry`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};



export const EmployerIndutryById = (id) => {
    const url = `${process.env.REACT_APP_BASE_URL}/EmployerIndustry-By-Id/?EMPLOYER_INDUSTRY_ID=${id}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};




export const createEntityType = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/create-entity-type`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};


export const ListEntityType = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/entity-type-list?page=${model.page}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};


export const EntityById = (id) => {
    const url = `${process.env.REACT_APP_BASE_URL}/Entity-By-Id/?ENTITY_TYPE_ID=${id}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const activeEntityType = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/active-entity-type-list`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const updateEntityType = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/update-entity-type`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};


export const archiveEntityType = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/archive-entity-type`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const createEmployerBenefit = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/create-employer-benefits`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};


export const listEmployerBenefit = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/employer-benefits-list?page=${model.page}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const archiveEmployerBenefitType = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/archive-employer-benefits`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const updateEmployerBenefit = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/update-employer-benefits`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};



export const EmployerBenefitById = (id) => {
    const url = `${process.env.REACT_APP_BASE_URL}/employer-benefits-by-id/?EMPLOYER_BENEFITS_ID=${id}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const activeEmployerBenefits = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/active-employer-benefits`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};


export const createAddressproofType = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/create-address-proof-type`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const listAddressProofType = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/address-proof-type-list?page=${model.page}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};


export const archiveAddressProofType = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/archive-AddressProof-type`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const updateAddressproofType = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/update-address-proof-type`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const AddressproofTypeById = (id) => {
    const url = `${process.env.REACT_APP_BASE_URL}/AddressproofType-By-Id/?ADDRESS_PROOF_TYPE_ID=${id}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const activeAddressproofType = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/active-address-proof-type-list`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};



export const ActiveEmployerRoles = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/active-employer-role`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const skillById = (id) => {
    const url = `${process.env.REACT_APP_BASE_URL}/skill-By-Id/?SKILL_ID=${id}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const uploadCompanyLogo = (file) => {
    const url = `${process.env.REACT_APP_BASE_URL}/upload-company-logo`;
    return axios.post(url, file, getAuthHeader()).then((res) => {
        return res.data;
    })
}


export const employerKYC = (model) => {
    
    const url = `${process.env.REACT_APP_BASE_URL}/employer-kyc-list?page=${model.page}&KEYWORD=${model.KEYWORD}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}



export const onKeywordSearch = (keyword, currentPage) => {
    const url = `${process.env.REACT_APP_BASE_URL}/kyc-keyword-search?keyword=${keyword}&page=${currentPage ? currentPage : 1}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })

}




export const employerKycUpdateStatus = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/kyc-verification`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}





export const uploadCompanyHeader = (file) => {
    const url = `${process.env.REACT_APP_BASE_URL}/upload-company-header`;
    return axios.post(url, file, getAuthHeader()).then((res) => {
        return res.data;
    })

}

export const uploadCompanyGST = (file) => {
    const url = `${process.env.REACT_APP_BASE_URL}/upload-gst`;
    return axios.post(url, file, getAuthHeader()).then((res) => {
        return res.data;
    })
}


export const uploadCompanyPAN = (file) => {
    const url = `${process.env.REACT_APP_BASE_URL}/upload-pan`;
    return axios.post(url, file, getAuthHeader()).then((res) => {
        return res.data;
    })
}


export const uploadAddressproof = (file) => {
    const url = `${process.env.REACT_APP_BASE_URL}/upload-address-proof`;
    return axios.post(url, file, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const archiveEmployerList = (data) => {
    const url = `${process.env.REACT_APP_BASE_URL}/archive-employer-list?KEYWORD=${data.KEYWORD}&page=${data.page}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const featuredEmployerList = (data) => {
    const url = `${process.env.REACT_APP_BASE_URL}/featured-employer-list?KEYWORD=${data.KEYWORD}&page=${data.page}&EMPLOYER_ID=${data.EMPLOYER_ID}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}


export const premiumEmployerList = (data) => {
    const url = `${process.env.REACT_APP_BASE_URL}/premium-employer-list?KEYWORD=${data.KEYWORD}&page=${data.page}`;
    //const url = `${process.env.REACT_APP_BASE_URL}/premium-employer-list`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const tempemployerapi = (data) => {
    const url = `${process.env.REACT_APP_BASE_URL}/temp-employer-api?KEYWORD=${data.KEYWORD}&page=${data.page}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const deleteEmployer = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/delete-employer`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};
export const collegeDetailsByID = (COLLEGE_ID) => {
    const url = `${process.env.REACT_APP_BASE_URL}/cp-college-details-id?COLLEGE_ID=${COLLEGE_ID}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const activeCompany = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/admin-employer-list`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};
