import React, { useState } from 'react'
import Info from './info'
import nl2br from 'nl2br';

const Overview = (props) => {
    const { basicDetails, portFolio, educationChoices, educationList, certificationList, internshipList } = props;

    return (

        <React.Fragment>
            <div
                className="tab-pane active"
                id="overview-tab"
                role="tabpanel"
            >
                <div className="row">
                    <div className="col-xl-4">
                        <Info
                            basicDetails={basicDetails}
                            TYPE={props.TYPE}

                        />
                        <div className="card profilePortfolioTabSection">
                            <h5 className="card-title m-0">Portfolio</h5>
                            <div className="card-body">
                                <div className="d-flex flex-wrap gap-2">
                                    <div>
                                        <a
                                            href={portFolio?.GITHUB}
                                            target='_blank'
                                            className="avatar-xs d-block"
                                        >
                                            <span className="avatar-title rounded-circle fs-16 bg-dark text-light">
                                                <i className="ri-github-fill" />
                                            </span>
                                        </a>
                                    </div>

                                    <div>
                                        <a
                                            href={portFolio?.LINKED_IN}
                                            className="avatar-xs d-block"
                                        >
                                            <span className="avatar-title rounded-circle fs-16 bg-primary">
                                                <i className="ri-linkedin-fill" />
                                            </span>
                                        </a>
                                    </div>
                                    <div>
                                        <a
                                            href={portFolio?.SELF}
                                            className="avatar-xs d-block"
                                        >
                                            <span className="avatar-title rounded-circle fs-16 bg-success">
                                                <i className="ri-global-fill" />
                                            </span>
                                        </a>
                                    </div>


                                </div>
                            </div>
                        </div>
                        {/* <div className="card">
                            <div className="card-body">
                                <h5 className="card-title mb-4">Skills</h5>
                                <div className="d-flex flex-wrap gap-2 fs-15">
                                    <a
                                        href="javascript:void(0);"
                                        className="badge bg-primary-subtle text-primary"
                                    >
                                        Photoshop
                                    </a>
                                    <a
                                        href="javascript:void(0);"
                                        className="badge bg-primary-subtle text-primary"
                                    >
                                        illustrator
                                    </a>
                                    <a
                                        href="javascript:void(0);"
                                        className="badge bg-primary-subtle text-primary"
                                    >
                                        HTML
                                    </a>
                                    <a
                                        href="javascript:void(0);"
                                        className="badge bg-primary-subtle text-primary"
                                    >
                                        CSS
                                    </a>
                                    <a
                                        href="javascript:void(0);"
                                        className="badge bg-primary-subtle text-primary"
                                    >
                                        Javascript
                                    </a>
                                    <a
                                        href="javascript:void(0);"
                                        className="badge bg-primary-subtle text-primary"
                                    >
                                        Php
                                    </a>
                                    <a
                                        href="javascript:void(0);"
                                        className="badge bg-primary-subtle text-primary"
                                    >
                                        Python
                                    </a>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className="col-xl-8 profileSummaryTabSection">
                        {props.TYPE === 'OFF CAMPUS' &&
                            <div className="card">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="card-header align-items-center d-flex">
                                            <h4 className="card-title mb-0  me-2">
                                                University {basicDetails?.UNIVERSITY ? basicDetails?.UNIVERSITY : '--'}
                                            </h4>

                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="card-header align-items-center d-flex">
                                            <h4 className="card-title mb-0  me-2">
                                                College {basicDetails?.COLLEGE ? basicDetails?.COLLEGE : '--'}
                                            </h4>

                                        </div>
                                    </div>


                                    <div className="col-lg-6">
                                        <div className="card-header align-items-center d-flex">
                                            <h4 className="card-title mb-0  me-2">
                                                Highest Qualification : {basicDetails?.QUALIFICATION_NAME ? basicDetails?.QUALIFICATION_NAME : '--'}
                                            </h4>

                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="card-header align-items-center d-flex">
                                            <h4 className="card-title mb-0  me-2">
                                                Course : {basicDetails?.COURSE_STREAM ? basicDetails?.COURSE_STREAM : '--'}
                                            </h4>

                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="card-header align-items-center d-flex">
                                            <h4 className="card-title mb-0  me-2">
                                                Specialization : {basicDetails?.SPECIALIZATION ? basicDetails?.SPECIALIZATION : '--'}
                                            </h4>

                                        </div>
                                    </div>
                                </div>
                            </div>}
                        <div className="card" style={{ borderRadius: 8 }}>
                            <h5 className="card-title mb-0">Profile Summary</h5>
                            <div className="card-body">
                                <p>
                                    <h4 dangerouslySetInnerHTML={{ __html: nl2br(basicDetails?.PROFILE_SUMMARY ? basicDetails?.PROFILE_SUMMARY : '--') }} />
                                </p>


                                {educationList && <div className="live-preview mb-3">
                                    <hr />
                                    <h5 className="card-title mb-3">Education List</h5>

                                    <div className="table-responsive">
                                        <table className="table table-striped table-nowrap align-middle mb-0">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Qualification</th>
                                                    <th scope="col">Course</th>
                                                    <th scope="col">Specialization</th>
                                                    <th scope="col">School / University</th>
                                                    <th scope="col">Year of Passout</th>
                                                    <th scope="col">Marks</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {educationList && educationList.map(item => {
                                                    return (
                                                        <tr>
                                                            <td className="fw-medium">{item.QUALIFICATION_NAME}</td>
                                                            <td>{item.COURSE_STREAM}</td>
                                                            <td>{item.SPECIALIZATION}</td>
                                                            <td>{item.SCHOOL_COLLEGE}</td>
                                                            <td>{item.PASSOUT_YEAR}</td>
                                                            <td>{item.GRADING_SYSTEM_TYPE} ({item.MARKS})</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>}



                                <h5 className="card-title mb-3">Completed Certifications</h5>
                                <p>
                                    {educationChoices && educationChoices.COMPLETED_ANY_CERTIFICATION === 'Y' ? <div className="live-preview mb-3">
                                        {/* <hr /> */}
                                        <div className="table-responsive">
                                            <table className="table table-striped table-nowrap align-middle mb-0">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Certification Name</th>
                                                        <th scope="col">Certification ID</th>
                                                        <th scope="col">Certification URL</th>
                                                        <th scope="col">Valid From</th>
                                                        <th scope="col">Valid To</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {certificationList && certificationList.map(item => {
                                                        return (
                                                            <tr>
                                                                <td>{item.CERTIFICATION_NAME}</td>
                                                                <td>{item.CERTIFICATION_ID}</td>
                                                                <td>{item.CERTIFICATION_URL}</td>
                                                                <td>{item.VALID_FROM}</td>
                                                                <td>{item.VALID_TO}</td>

                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div> : "No"}
                                </p>
                                <h5 className="card-title mb-3">Completed Internships</h5>
                                <p>
                                    {educationChoices && educationChoices.COMPLETED_ANY_INTERNSHIP === 'Y' ? <div className="live-preview mb-3">
                                        {/* <hr /> */}
                                        <div className="table-responsive">
                                            <table className="table table-striped table-nowrap align-middle mb-0">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Title</th>
                                                        <th scope="col">Company Name</th>
                                                        <th scope="col">From</th>
                                                        <th scope="col">To</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {internshipList && internshipList.map(item => {
                                                        return (
                                                            <tr>
                                                                <td>{item.TITLE}</td>
                                                                <td>{item.COMPANY_NAME}</td>
                                                                <td>{item.FROM_DATE}</td>
                                                                <td>{item.TO_DATE}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div> : "No"}
                                </p>

                                <h5 className="card-title mb-3">Interested in higher education ?</h5>
                                {/* <hr/> */}
                                <p>
                                    {educationChoices && educationChoices.INTERSTED_IN_HIGHER_EDUCATION === 'Y' ? "Yes" : "No"}
                                </p>

                                <h5 className="card-title mb-3">Future Goal ?</h5>
                                {/* <hr/> */}

                                <p>
                                    {educationChoices && educationChoices.INTERESTED_JOB_TYPE ? educationChoices.INTERESTED_JOB_TYPE : "--"}
                                </p>


                            </div>

                        </div>

                        {/* <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-header align-items-center d-flex">
                                        <h4 className="card-title mb-0  me-2">
                                            Experience
                                        </h4>

                                    </div>
                                    <div className="card-body">
                                        <div className="tab-content text-muted">
                                            <div
                                                className="tab-pane active"
                                                id="today"
                                                role="tabpanel"
                                            >
                                                <div className="profile-timeline">
                                                    <div
                                                        className="accordion accordion-flush"
                                                        id="todayExample"
                                                    >
                                                        <div className="accordion-item border-0">
                                                            <div
                                                                className="accordion-header"
                                                                id="headingOne"
                                                            >
                                                                <a
                                                                    className="accordion-button p-2 shadow-none"
                                                                    data-bs-toggle="collapse"
                                                                    href="#collapseOne"
                                                                    aria-expanded="true"
                                                                >
                                                                    <div className="d-flex">
                                                                        <div className="flex-shrink-0 avatar-xs">
                                                                            <div className="avatar-title bg-light text-success rounded-circle">
                                                                                M
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex-grow-1 ms-3">
                                                                            <h6 className="fs-14 mb-1">
                                                                                MindClick Tech.
                                                                            </h6>

                                                                            <small className="text-muted">
                                                                                Jan,2021 - Mar,2021
                                                                            </small>
                                                                        </div>

                                                                    </div>

                                                                </a>
                                                            </div>
                                                            <div
                                                                id="collapseOne"
                                                                className="accordion-collapse collapse show"
                                                                aria-labelledby="headingOne"
                                                                data-bs-parent="#accordionExample"
                                                            >
                                                                <div className="accordion-body ms-2 ps-5">
                                                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique, ex sit. Laudantium sit eaque, cupiditate repellendus aperiam assumenda veniam, deserunt praesentium fuga facilis ullam mollitia ipsa aliquam voluptatem perferendis minus..
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="accordion-item border-0">
                                                            <div
                                                                className="accordion-header"
                                                                id="headingOne"
                                                            >
                                                                <a
                                                                    className="accordion-button p-2 shadow-none"
                                                                    data-bs-toggle="collapse"
                                                                    href="#collapseOne"
                                                                    aria-expanded="true"
                                                                >
                                                                    <div className="d-flex">
                                                                        <div className="flex-shrink-0 avatar-xs">
                                                                            <div className="avatar-title bg-light text-success rounded-circle">
                                                                                M
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex-grow-1 ms-3">
                                                                            <h6 className="fs-14 mb-1">
                                                                                MindClick Tech.
                                                                            </h6>
                                                                            <small className="text-muted">
                                                                                Jan,2021 - Mar,2021
                                                                            </small>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                            <div
                                                                id="collapseOne"
                                                                className="accordion-collapse collapse show"
                                                                aria-labelledby="headingOne"
                                                                data-bs-parent="#accordionExample"
                                                            >
                                                                <div className="accordion-body ms-2 ps-5">
                                                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique, ex sit. Laudantium sit eaque, cupiditate repellendus aperiam assumenda veniam, deserunt praesentium fuga facilis ullam mollitia ipsa aliquam voluptatem perferendis minus..
                                                                </div>
                                                            </div>
                                                        </div> <div className="accordion-item border-0">
                                                            <div
                                                                className="accordion-header"
                                                                id="headingOne"
                                                            >
                                                                <a
                                                                    className="accordion-button p-2 shadow-none"
                                                                    data-bs-toggle="collapse"
                                                                    href="#collapseOne"
                                                                    aria-expanded="true"
                                                                >
                                                                    <div className="d-flex">
                                                                        <div className="flex-shrink-0 avatar-xs">
                                                                            <div className="avatar-title bg-light text-success rounded-circle">
                                                                                M
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex-grow-1 ms-3">
                                                                            <h6 className="fs-14 mb-1">
                                                                                MindClick Tech.
                                                                            </h6>
                                                                            <small className="text-muted">
                                                                                Jan,2021 - Mar,2021
                                                                            </small>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                            <div
                                                                id="collapseOne"
                                                                className="accordion-collapse collapse show"
                                                                aria-labelledby="headingOne"
                                                                data-bs-parent="#accordionExample"
                                                            >
                                                                <div className="accordion-body ms-2 ps-5">
                                                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique, ex sit. Laudantium sit eaque, cupiditate repellendus aperiam assumenda veniam, deserunt praesentium fuga facilis ullam mollitia ipsa aliquam voluptatem perferendis minus..
                                                                </div>
                                                            </div>
                                                        </div> <div className="accordion-item border-0">
                                                            <div
                                                                className="accordion-header"
                                                                id="headingOne"
                                                            >
                                                                <a
                                                                    className="accordion-button p-2 shadow-none"
                                                                    data-bs-toggle="collapse"
                                                                    href="#collapseOne"
                                                                    aria-expanded="true"
                                                                >
                                                                    <div className="d-flex">
                                                                        <div className="flex-shrink-0 avatar-xs">
                                                                            <div className="avatar-title bg-light text-success rounded-circle">
                                                                                M
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex-grow-1 ms-3">
                                                                            <h6 className="fs-14 mb-1">
                                                                                MindClick Tech.
                                                                            </h6>
                                                                            <small className="text-muted">
                                                                                Jan,2021 - Mar,2021
                                                                            </small>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                            <div
                                                                id="collapseOne"
                                                                className="accordion-collapse collapse show"
                                                                aria-labelledby="headingOne"
                                                                data-bs-parent="#accordionExample"
                                                            >
                                                                <div className="accordion-body ms-2 ps-5">
                                                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique, ex sit. Laudantium sit eaque, cupiditate repellendus aperiam assumenda veniam, deserunt praesentium fuga facilis ullam mollitia ipsa aliquam voluptatem perferendis minus..
                                                                </div>
                                                            </div>
                                                        </div> <div className="accordion-item border-0">
                                                            <div
                                                                className="accordion-header"
                                                                id="headingOne"
                                                            >
                                                                <a
                                                                    className="accordion-button p-2 shadow-none"
                                                                    data-bs-toggle="collapse"
                                                                    href="#collapseOne"
                                                                    aria-expanded="true"
                                                                >
                                                                    <div className="d-flex">
                                                                        <div className="flex-shrink-0 avatar-xs">
                                                                            <div className="avatar-title bg-light text-success rounded-circle">
                                                                                M
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex-grow-1 ms-3">
                                                                            <h6 className="fs-14 mb-1">
                                                                                MindClick Tech.
                                                                            </h6>
                                                                            <small className="text-muted">
                                                                                Jan,2021 - Mar,2021
                                                                            </small>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                            <div
                                                                id="collapseOne"
                                                                className="accordion-collapse collapse show"
                                                                aria-labelledby="headingOne"
                                                                data-bs-parent="#accordionExample"
                                                            >
                                                                <div className="accordion-body ms-2 ps-5">
                                                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique, ex sit. Laudantium sit eaque, cupiditate repellendus aperiam assumenda veniam, deserunt praesentium fuga facilis ullam mollitia ipsa aliquam voluptatem perferendis minus..
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="tab-pane"
                                                id="weekly"
                                                role="tabpanel"
                                            >
                                                <div className="profile-timeline">
                                                    <div
                                                        className="accordion accordion-flush"
                                                        id="weeklyExample"
                                                    >
                                                        <div className="accordion-item border-0">
                                                            <div
                                                                className="accordion-header"
                                                                id="heading6"
                                                            >
                                                                <a
                                                                    className="accordion-button p-2 shadow-none"
                                                                    data-bs-toggle="collapse"
                                                                    href="#collapse6"
                                                                    aria-expanded="true"
                                                                >
                                                                    <div className="d-flex">
                                                                        <div className="flex-shrink-0">
                                                                            <img
                                                                                src="assets/images/users/avatar-3.jpg"
                                                                                alt=""
                                                                                className="avatar-xs rounded-circle"
                                                                            />
                                                                        </div>
                                                                        <div className="flex-grow-1 ms-3">
                                                                            <h6 className="fs-14 mb-1">
                                                                                Joseph Parker
                                                                            </h6>
                                                                            <small className="text-muted">
                                                                                New people joined with our
                                                                                company - Yesterday
                                                                            </small>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                            <div
                                                                id="collapse6"
                                                                className="accordion-collapse collapse show"
                                                                aria-labelledby="heading6"
                                                                data-bs-parent="#accordionExample"
                                                            >
                                                                <div className="accordion-body ms-2 ps-5">
                                                                    It makes a statement, it’s impressive
                                                                    graphic design. Increase or decrease
                                                                    the letter spacing depending on the
                                                                    situation and try, try again until it
                                                                    looks right, and each letter has the
                                                                    perfect spot of its own.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="accordion-item border-0">
                                                            <div
                                                                className="accordion-header"
                                                                id="heading7"
                                                            >
                                                                <a
                                                                    className="accordion-button p-2 shadow-none"
                                                                    data-bs-toggle="collapse"
                                                                    href="#collapse7"
                                                                    aria-expanded="false"
                                                                >
                                                                    <div className="d-flex">
                                                                        <div className="avatar-xs">
                                                                            <div className="avatar-title rounded-circle bg-light text-danger">
                                                                                <i className="ri-shopping-bag-line" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex-grow-1 ms-3">
                                                                            <h6 className="fs-14 mb-1">
                                                                                Your order is placed{" "}
                                                                                <span className="badge bg-success-subtle text-success align-middle">
                                                                                    Completed
                                                                                </span>
                                                                            </h6>
                                                                            <small className="text-muted">
                                                                                These customers can rest assured
                                                                                their order has been placed - 1
                                                                                week Ago
                                                                            </small>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="accordion-item border-0">
                                                            <div
                                                                className="accordion-header"
                                                                id="heading8"
                                                            >
                                                                <a
                                                                    className="accordion-button p-2 shadow-none"
                                                                    data-bs-toggle="collapse"
                                                                    href="#collapse8"
                                                                    aria-expanded="true"
                                                                >
                                                                    <div className="d-flex">
                                                                        <div className="flex-shrink-0 avatar-xs">
                                                                            <div className="avatar-title bg-light text-success rounded-circle">
                                                                                <i className="ri-home-3-line" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex-grow-1 ms-3">
                                                                            <h6 className="fs-14 mb-1">
                                                                                Velzon admin dashboard templates
                                                                                layout upload
                                                                            </h6>
                                                                            <small className="text-muted">
                                                                                We talked about a project on
                                                                                linkedin - 1 week Ago
                                                                            </small>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                            <div
                                                                id="collapse8"
                                                                className="accordion-collapse collapse show"
                                                                aria-labelledby="heading8"
                                                                data-bs-parent="#accordionExample"
                                                            >
                                                                <div className="accordion-body ms-2 ps-5 fst-italic">
                                                                    Powerful, clean &amp; modern
                                                                    responsive bootstrap 5 admin template.
                                                                    The maximum file size for uploads in
                                                                    this demo :
                                                                    <div className="row mt-2">
                                                                        <div className="col-xxl-6">
                                                                            <div className="row border border-dashed gx-2 p-2">
                                                                                <div className="col-3">
                                                                                    <img
                                                                                        src="assets/images/small/img-3.jpg"
                                                                                        alt=""
                                                                                        className="img-fluid rounded"
                                                                                    />
                                                                                </div>
                                                                                <div className="col-3">
                                                                                    <img
                                                                                        src="assets/images/small/img-5.jpg"
                                                                                        alt=""
                                                                                        className="img-fluid rounded"
                                                                                    />
                                                                                </div>
                                                                                <div className="col-3">
                                                                                    <img
                                                                                        src="assets/images/small/img-7.jpg"
                                                                                        alt=""
                                                                                        className="img-fluid rounded"
                                                                                    />
                                                                                </div>
                                                                                <div className="col-3">
                                                                                    <img
                                                                                        src="assets/images/small/img-9.jpg"
                                                                                        alt=""
                                                                                        className="img-fluid rounded"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="accordion-item border-0">
                                                            <div
                                                                className="accordion-header"
                                                                id="heading9"
                                                            >
                                                                <a
                                                                    className="accordion-button p-2 shadow-none"
                                                                    data-bs-toggle="collapse"
                                                                    href="#collapse9"
                                                                    aria-expanded="false"
                                                                >
                                                                    <div className="d-flex">
                                                                        <div className="flex-shrink-0">
                                                                            <img
                                                                                src="assets/images/users/avatar-6.jpg"
                                                                                alt=""
                                                                                className="avatar-xs rounded-circle"
                                                                            />
                                                                        </div>
                                                                        <div className="flex-grow-1 ms-3">
                                                                            <h6 className="fs-14 mb-1">
                                                                                New ticket created{" "}
                                                                                <span className="badge bg-info-subtle text-info align-middle">
                                                                                    Inprogress
                                                                                </span>
                                                                            </h6>
                                                                            <small className="text-muted mb-2">
                                                                                User
                                                                                <span className="text-secondary">
                                                                                    Jack365
                                                                                </span>
                                                                                submitted a ticket - 2 week Ago
                                                                            </small>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="accordion-item border-0">
                                                            <div
                                                                className="accordion-header"
                                                                id="heading10"
                                                            >
                                                                <a
                                                                    className="accordion-button p-2 shadow-none"
                                                                    data-bs-toggle="collapse"
                                                                    href="#collapse10"
                                                                    aria-expanded="true"
                                                                >
                                                                    <div className="d-flex">
                                                                        <div className="flex-shrink-0">
                                                                            <img
                                                                                src="assets/images/users/avatar-5.jpg"
                                                                                alt=""
                                                                                className="avatar-xs rounded-circle"
                                                                            />
                                                                        </div>
                                                                        <div className="flex-grow-1 ms-3">
                                                                            <h6 className="fs-14 mb-1">
                                                                                Jennifer Carter
                                                                            </h6>
                                                                            <small className="text-muted">
                                                                                Commented - 4 week Ago
                                                                            </small>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                            <div
                                                                id="collapse10"
                                                                className="accordion-collapse collapse show"
                                                                aria-labelledby="heading10"
                                                                data-bs-parent="#accordionExample"
                                                            >
                                                                <div className="accordion-body ms-2 ps-5">
                                                                    <p className="text-muted fst-italic mb-2">
                                                                        " This is an awesome admin dashboard
                                                                        template. It is extremely well
                                                                        structured and uses state of the art
                                                                        components (e.g. one of the only
                                                                        templates using boostrap 5.1.3 so
                                                                        far). I integrated it into a Rails 6
                                                                        project. Needs manual integration
                                                                        work of course but the template
                                                                        structure made it easy. "
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="tab-pane"
                                                id="monthly"
                                                role="tabpanel"
                                            >
                                                <div className="profile-timeline">
                                                    <div
                                                        className="accordion accordion-flush"
                                                        id="monthlyExample"
                                                    >
                                                        <div className="accordion-item border-0">
                                                            <div
                                                                className="accordion-header"
                                                                id="heading11"
                                                            >
                                                                <a
                                                                    className="accordion-button p-2 shadow-none"
                                                                    data-bs-toggle="collapse"
                                                                    href="#collapse11"
                                                                    aria-expanded="false"
                                                                >
                                                                    <div className="d-flex">
                                                                        <div className="flex-shrink-0 avatar-xs">
                                                                            <div className="avatar-title bg-light text-success rounded-circle">
                                                                                M
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex-grow-1 ms-3">
                                                                            <h6 className="fs-14 mb-1">
                                                                                Megan Elmore
                                                                            </h6>
                                                                            <small className="text-muted">
                                                                                Adding a new event with
                                                                                attachments - 1 month Ago.
                                                                            </small>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                            <div
                                                                id="collapse11"
                                                                className="accordion-collapse collapse show"
                                                                aria-labelledby="heading11"
                                                                data-bs-parent="#accordionExample"
                                                            >
                                                                <div className="accordion-body ms-2 ps-5">
                                                                    <div className="row g-2">
                                                                        <div className="col-auto">
                                                                            <div className="d-flex border border-dashed p-2 rounded position-relative">
                                                                                <div className="flex-shrink-0">
                                                                                    <i className="ri-image-2-line fs-17 text-danger" />
                                                                                </div>
                                                                                <div className="flex-grow-1 ms-2">
                                                                                    <h6>
                                                                                        <a
                                                                                            href="javascript:void(0);"
                                                                                            className="stretched-link"
                                                                                        >
                                                                                            Business Template - UI/UX
                                                                                            design
                                                                                        </a>
                                                                                    </h6>
                                                                                    <small>685 KB</small>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-auto">
                                                                            <div className="d-flex border border-dashed p-2 rounded position-relative">
                                                                                <div className="flex-shrink-0">
                                                                                    <i className="ri-file-zip-line fs-17 text-info" />
                                                                                </div>
                                                                                <div className="flex-grow-1 ms-2">
                                                                                    <h6>
                                                                                        <a
                                                                                            href="javascript:void(0);"
                                                                                            className="stretched-link"
                                                                                        >
                                                                                            Bank Management System -
                                                                                            PSD
                                                                                        </a>
                                                                                    </h6>
                                                                                    <small>8.78 MB</small>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-auto">
                                                                            <div className="d-flex border border-dashed p-2 rounded position-relative">
                                                                                <div className="flex-shrink-0">
                                                                                    <i className="ri-file-zip-line fs-17 text-info" />
                                                                                </div>
                                                                                <div className="flex-grow-1 ms-2">
                                                                                    <h6>
                                                                                        <a
                                                                                            href="javascript:void(0);"
                                                                                            className="stretched-link"
                                                                                        >
                                                                                            Bank Management System -
                                                                                            PSD
                                                                                        </a>
                                                                                    </h6>
                                                                                    <small>8.78 MB</small>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="accordion-item border-0">
                                                            <div
                                                                className="accordion-header"
                                                                id="heading12"
                                                            >
                                                                <a
                                                                    className="accordion-button p-2 shadow-none"
                                                                    data-bs-toggle="collapse"
                                                                    href="#collapse12"
                                                                    aria-expanded="true"
                                                                >
                                                                    <div className="d-flex">
                                                                        <div className="flex-shrink-0">
                                                                            <img
                                                                                src="assets/images/users/avatar-2.jpg"
                                                                                alt=""
                                                                                className="avatar-xs rounded-circle"
                                                                            />
                                                                        </div>
                                                                        <div className="flex-grow-1 ms-3">
                                                                            <h6 className="fs-14 mb-1">
                                                                                Jacqueline Steve
                                                                            </h6>
                                                                            <small className="text-muted">
                                                                                We has changed 2 attributes on 3
                                                                                month Ago
                                                                            </small>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                            <div
                                                                id="collapse12"
                                                                className="accordion-collapse collapse show"
                                                                aria-labelledby="heading12"
                                                                data-bs-parent="#accordionExample"
                                                            >
                                                                <div className="accordion-body ms-2 ps-5">
                                                                    In an awareness campaign, it is vital
                                                                    for people to begin put 2 and 2
                                                                    together and begin to recognize your
                                                                    cause. Too much or too little spacing,
                                                                    as in the example below, can make
                                                                    things unpleasant for the reader. The
                                                                    goal is to make your text as
                                                                    comfortable to read as possible. A
                                                                    wonderful serenity has taken
                                                                    possession of my entire soul, like
                                                                    these sweet mornings of spring which I
                                                                    enjoy with my whole heart.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="accordion-item border-0">
                                                            <div
                                                                className="accordion-header"
                                                                id="heading13"
                                                            >
                                                                <a
                                                                    className="accordion-button p-2 shadow-none"
                                                                    data-bs-toggle="collapse"
                                                                    href="#collapse13"
                                                                    aria-expanded="false"
                                                                >
                                                                    <div className="d-flex">
                                                                        <div className="flex-shrink-0">
                                                                            <img
                                                                                src="assets/images/users/avatar-5.jpg"
                                                                                alt=""
                                                                                className="avatar-xs rounded-circle"
                                                                            />
                                                                        </div>
                                                                        <div className="flex-grow-1 ms-3">
                                                                            <h6 className="fs-14 mb-1">
                                                                                New ticket received
                                                                            </h6>
                                                                            <small className="text-muted mb-2">
                                                                                User
                                                                                <span className="text-secondary">
                                                                                    Erica245
                                                                                </span>
                                                                                submitted a ticket - 5 month Ago
                                                                            </small>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="accordion-item border-0">
                                                            <div
                                                                className="accordion-header"
                                                                id="heading14"
                                                            >
                                                                <a
                                                                    className="accordion-button p-2 shadow-none"
                                                                    data-bs-toggle="collapse"
                                                                    href="#collapse14"
                                                                    aria-expanded="true"
                                                                >
                                                                    <div className="d-flex">
                                                                        <div className="flex-shrink-0 avatar-xs">
                                                                            <div className="avatar-title bg-light text-muted rounded-circle">
                                                                                <i className="ri-user-3-fill" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex-grow-1 ms-3">
                                                                            <h6 className="fs-14 mb-1">
                                                                                Nancy Martino
                                                                            </h6>
                                                                            <small className="text-muted">
                                                                                Commented on 24 Nov, 2021.
                                                                            </small>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                            <div
                                                                id="collapse14"
                                                                className="accordion-collapse collapse show"
                                                                aria-labelledby="heading14"
                                                                data-bs-parent="#accordionExample"
                                                            >
                                                                <div className="accordion-body ms-2 ps-5 fst-italic">
                                                                    " A wonderful serenity has taken
                                                                    possession of my entire soul, like
                                                                    these sweet mornings of spring which I
                                                                    enjoy with my whole heart. Each design
                                                                    is a new, unique piece of art birthed
                                                                    into this world, and while you have
                                                                    the opportunity to be creative and
                                                                    make your own style choices. "
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="accordion-item border-0">
                                                            <div
                                                                className="accordion-header"
                                                                id="heading15"
                                                            >
                                                                <a
                                                                    className="accordion-button p-2 shadow-none"
                                                                    data-bs-toggle="collapse"
                                                                    href="#collapse15"
                                                                    aria-expanded="true"
                                                                >
                                                                    <div className="d-flex">
                                                                        <div className="flex-shrink-0">
                                                                            <img
                                                                                src="assets/images/users/avatar-7.jpg"
                                                                                alt=""
                                                                                className="avatar-xs rounded-circle"
                                                                            />
                                                                        </div>
                                                                        <div className="flex-grow-1 ms-3">
                                                                            <h6 className="fs-14 mb-1">
                                                                                Lewis Arnold
                                                                            </h6>
                                                                            <small className="text-muted">
                                                                                Create new project buildng
                                                                                product - 8 month Ago
                                                                            </small>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                            <div
                                                                id="collapse15"
                                                                className="accordion-collapse collapse show"
                                                                aria-labelledby="heading15"
                                                                data-bs-parent="#accordionExample"
                                                            >
                                                                <div className="accordion-body ms-2 ps-5">
                                                                    <p className="text-muted mb-2">
                                                                        Every team project can have a
                                                                        velzon. Use the velzon to share
                                                                        information with your team to
                                                                        understand and contribute to your
                                                                        project.
                                                                    </p>
                                                                    <div className="avatar-group">
                                                                        <a
                                                                            href="javascript: void(0);"
                                                                            className="avatar-group-item"
                                                                            data-bs-toggle="tooltip"
                                                                            data-bs-trigger="hover"
                                                                            data-bs-placement="top"
                                                                            title=""
                                                                            data-bs-original-title="Christi"
                                                                        >
                                                                            <img
                                                                                src="assets/images/users/avatar-4.jpg"
                                                                                alt=""
                                                                                className="rounded-circle avatar-xs"
                                                                            />
                                                                        </a>
                                                                        <a
                                                                            href="javascript: void(0);"
                                                                            className="avatar-group-item"
                                                                            data-bs-toggle="tooltip"
                                                                            data-bs-trigger="hover"
                                                                            data-bs-placement="top"
                                                                            title=""
                                                                            data-bs-original-title="Frank Hook"
                                                                        >
                                                                            <img
                                                                                src="assets/images/users/avatar-3.jpg"
                                                                                alt=""
                                                                                className="rounded-circle avatar-xs"
                                                                            />
                                                                        </a>
                                                                        <a
                                                                            href="javascript: void(0);"
                                                                            className="avatar-group-item"
                                                                            data-bs-toggle="tooltip"
                                                                            data-bs-trigger="hover"
                                                                            data-bs-placement="top"
                                                                            title=""
                                                                            data-bs-original-title=" Ruby"
                                                                        >
                                                                            <div className="avatar-xs">
                                                                                <div className="avatar-title rounded-circle bg-light text-primary">
                                                                                    R
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                        <a
                                                                            href="javascript: void(0);"
                                                                            className="avatar-group-item"
                                                                            data-bs-toggle="tooltip"
                                                                            data-bs-trigger="hover"
                                                                            data-bs-placement="top"
                                                                            title=""
                                                                            data-bs-original-title="more"
                                                                        >
                                                                            <div className="avatar-xs">
                                                                                <div className="avatar-title rounded-circle">
                                                                                    2+
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Projects</h5>
                                <div className="swiper project-swiper mt-n4">
                                    <div className="d-flex justify-content-end gap-2 mb-2">
                                        <div className="slider-button-prev">
                                            <div className="avatar-title fs-18 rounded px-1">
                                                <i className="ri-arrow-left-s-line" />
                                            </div>
                                        </div>
                                        <div className="slider-button-next">
                                            <div className="avatar-title fs-18 rounded px-1">
                                                <i className="ri-arrow-right-s-line" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="swiper-wrapper">
                                        <div className="swiper-slide">
                                            <div className="card profile-project-card shadow-none profile-project-success mb-0">
                                                <div className="card-body p-4">
                                                    <div className="d-flex">
                                                        <div className="flex-grow-1 text-muted overflow-hidden">
                                                            <h5 className="fs-14 text-truncate mb-1">
                                                                <a href="#" className="text-body">
                                                                    ABC Project Customization
                                                                </a>
                                                            </h5>
                                                            <p className="text-muted text-truncate mb-0">
                                                                Period :{" "}
                                                                <span className="fw-semibold text-body">
                                                                    Jan,2021 - Mar,2021
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <div className="flex-shrink-0 ms-2">
                                                            <div className="badge bg-warning-subtle text-warning fs-10">
                                                                Inprogress
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex mt-4">
                                                        <div className="flex-grow-1">
                                                            <div className="d-flex align-items-center gap-2">
                                                                <div>
                                                                    <h5 className="fs-12 text-muted mb-0">
                                                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore perspiciatis rerum, iste recusandae earum laborum nobis. Blanditiis incidunt, temporibus, et praesentium necessitatibus repudiandae totam sed, maiores nostrum optio beatae quis.
                                                                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Qui cumque magni, nostrum ex tempora at laudantium perspiciatis! Quis porro quam consectetur numquam consequuntur voluptate veritatis similique, harum illo dolorem ipsam.
                                                                    </h5>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="card profile-project-card shadow-none profile-project-danger mb-0">
                                                <div className="card-body p-4">
                                                    <div className="d-flex">
                                                        <div className="flex-grow-1 text-muted overflow-hidden">
                                                            <h5 className="fs-14 text-truncate mb-1">
                                                                <a href="#" className="text-body">
                                                                    Client - John
                                                                </a>
                                                            </h5>
                                                            <p className="text-muted text-truncate mb-0">
                                                                Last Update :{" "}
                                                                <span className="fw-semibold text-body">
                                                                    1 hr Ago
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <div className="flex-shrink-0 ms-2">
                                                            <div className="badge bg-success-subtle text-success fs-10">
                                                                Completed
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex mt-4">
                                                        <div className="flex-grow-1">
                                                            <div className="d-flex align-items-center gap-2">
                                                                <div>
                                                                    <h5 className="fs-12 text-muted mb-0">
                                                                        Members :
                                                                    </h5>
                                                                </div>
                                                                <div className="avatar-group">
                                                                    <div className="avatar-group-item">
                                                                        <div className="avatar-xs">
                                                                            <img
                                                                                src="assets/images/users/avatar-2.jpg"
                                                                                alt=""
                                                                                className="rounded-circle img-fluid"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="avatar-group-item">
                                                                        <div className="avatar-xs">
                                                                            <div className="avatar-title rounded-circle bg-light text-primary">
                                                                                C
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="card profile-project-card shadow-none profile-project-info mb-0">
                                                <div className="card-body p-4">
                                                    <div className="d-flex">
                                                        <div className="flex-grow-1 text-muted overflow-hidden">
                                                            <h5 className="fs-14 text-truncate mb-1">
                                                                <a href="#" className="text-body">
                                                                    Brand logo Design
                                                                </a>
                                                            </h5>
                                                            <p className="text-muted text-truncate mb-0">
                                                                Last Update :{" "}
                                                                <span className="fw-semibold text-body">
                                                                    2 hr Ago
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <div className="flex-shrink-0 ms-2">
                                                            <div className="badge bg-warning-subtle text-warning fs-10">
                                                                Inprogress
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex mt-4">
                                                        <div className="flex-grow-1">
                                                            <div className="d-flex align-items-center gap-2">
                                                                <div>
                                                                    <h5 className="fs-12 text-muted mb-0">
                                                                        Members :
                                                                    </h5>
                                                                </div>
                                                                <div className="avatar-group">
                                                                    <div className="avatar-group-item">
                                                                        <div className="avatar-xs">
                                                                            <img
                                                                                src="assets/images/users/avatar-5.jpg"
                                                                                alt=""
                                                                                className="rounded-circle img-fluid"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="card profile-project-card shadow-none profile-project-danger mb-0">
                                                <div className="card-body p-4">
                                                    <div className="d-flex">
                                                        <div className="flex-grow-1 text-muted overflow-hidden">
                                                            <h5 className="fs-14 text-truncate mb-1">
                                                                <a href="#" className="text-body">
                                                                    Project update
                                                                </a>
                                                            </h5>
                                                            <p className="text-muted text-truncate mb-0">
                                                                Last Update :{" "}
                                                                <span className="fw-semibold text-body">
                                                                    4 hr Ago
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <div className="flex-shrink-0 ms-2">
                                                            <div className="badge bg-success-subtle text-success fs-10">
                                                                Completed
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex mt-4">
                                                        <div className="flex-grow-1">
                                                            <div className="d-flex align-items-center gap-2">
                                                                <div>
                                                                    <h5 className="fs-12 text-muted mb-0">
                                                                        Members :
                                                                    </h5>
                                                                </div>
                                                                <div className="avatar-group">
                                                                    <div className="avatar-group-item">
                                                                        <div className="avatar-xs">
                                                                            <img
                                                                                src="assets/images/users/avatar-4.jpg"
                                                                                alt=""
                                                                                className="rounded-circle img-fluid"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="avatar-group-item">
                                                                        <div className="avatar-xs">
                                                                            <img
                                                                                src="assets/images/users/avatar-5.jpg"
                                                                                alt=""
                                                                                className="rounded-circle img-fluid"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="card profile-project-card shadow-none profile-project-warning mb-0">
                                                <div className="card-body p-4">
                                                    <div className="d-flex">
                                                        <div className="flex-grow-1 text-muted overflow-hidden">
                                                            <h5 className="fs-14 text-truncate mb-1">
                                                                <a href="#" className="text-body">
                                                                    Chat App
                                                                </a>
                                                            </h5>
                                                            <p className="text-muted text-truncate mb-0">
                                                                Last Update :{" "}
                                                                <span className="fw-semibold text-body">
                                                                    1 hr Ago
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <div className="flex-shrink-0 ms-2">
                                                            <div className="badge bg-warning-subtle text-warning fs-10">
                                                                Inprogress
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex mt-4">
                                                        <div className="flex-grow-1">
                                                            <div className="d-flex align-items-center gap-2">
                                                                <div>
                                                                    <h5 className="fs-12 text-muted mb-0">
                                                                        Members :
                                                                    </h5>
                                                                </div>
                                                                <div className="avatar-group">
                                                                    <div className="avatar-group-item">
                                                                        <div className="avatar-xs">
                                                                            <img
                                                                                src="assets/images/users/avatar-4.jpg"
                                                                                alt=""
                                                                                className="rounded-circle img-fluid"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="avatar-group-item">
                                                                        <div className="avatar-xs">
                                                                            <img
                                                                                src="assets/images/users/avatar-5.jpg"
                                                                                alt=""
                                                                                className="rounded-circle img-fluid"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="avatar-group-item">
                                                                        <div className="avatar-xs">
                                                                            <div className="avatar-title rounded-circle bg-light text-primary">
                                                                                A
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Overview