import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { activeRoleList } from '../../actions/AdminUserAction'
import { getProfileInfromation, updateProfileInfromation } from '../../actions/ProfileAction'
import UpdateProfiles from '../../component/Menu/Dashboard/UpdateProfile'
import constant from '../../constant'
export default function UpdateProfile() {
    const [roleList, setRoleList] = useState([])
    const navigation = useNavigate();
    const [data, setData] = useState(null)
    const params = useParams();
    const [response, setResponse] = React.useState({})
    const [showMsg, setShowMsg] = React.useState(false)

    useEffect(() => {
        document.title = constant.title.UpdateProfile
        getProfileInfromation(params.id).then((res) => {
            if (res.status) {
                setData(res.result)

            }
            else {
                alert(res.error)
            }
        }).catch((err) => {
            alert(err)
        })

        activeRoleList().then(res => {
            if (res.status) {
                setRoleList(res.result)
            }
            else {
                alert(res.error)
            }
        }).catch((err) => {
            alert(err)
        })
    }, [])

    const onSubmit = (model) => {

        updateProfileInfromation(model).then(res => {
            console.log("updated ", res.result);

            if (res.status) {
                navigation(constant.component.profile.url)
                window.location.reload()

            }

            else {
                setResponse(res)
                setShowMsg(true)
                setTimeout(() => {
                    setShowMsg(false)
                }, 7000)
            }
        })
            .catch(err => {
                alert(err)
            })
    }
    return (
        <React.Fragment>
            {
                data && <UpdateProfiles
                    roleList={roleList}
                    detail={data} onSubmit={onSubmit}
                    response={response} showMsg={showMsg} />
            }
        </React.Fragment>
    )
}
