import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getQuestiontById,
  listJobQuestionType,
} from "../../actions/JobsAction";
import SuccessMessage from "../common/SuccessMessage";
import ErrorMessage from "../common/ErrorMessage";
import constant from "../../constant";

function AddQuestions(props) {
  const { id } = useParams();
  const [QUESTIONTYPE, setQUESTIONTYPE] = useState([]);
  const [QUESTION_TYPE_ID, setQUESTION_TYPE_ID] = useState("");
  const [QUESTION, setQUESTION] = useState("");
  const [IS_MANADATORY, setIS_MANADATORY] = useState("Y");
  const [OPTION1, setOPTION1] = useState("");
  const [OPTION2, setOPTION2] = useState("");
  const [OPTION3, setOPTION3] = useState("");
  const [OPTION4, setOPTION4] = useState("");
  const [QUESTION_ID, setQUESTION_ID] = useState("");
  const [error, setError] = useState(false)
  const Navigate = useNavigate()

  const onSubmit = (e) => {
    e.preventDefault();
    if(QUESTION_TYPE_ID !== 3 ){
      if (QUESTION_TYPE_ID.toString().trim().length === 0 || QUESTION.toString().trim().length === 0) {
        setError(true)
        return false
      }
    }else{
      if (QUESTION_TYPE_ID.toString().trim().length === 0 || QUESTION.toString().trim().length === 0 || OPTION1.trim().length === 0 || OPTION2.trim().length === 0 || OPTION3.trim().length === 0 || OPTION4.trim().length === 0) {
        setError(true)
        return false
      }
    }
   
  
    if (id) {
      const model = {
        QUESTION_ID: QUESTION_ID,
        QUESTION_TYPE_ID: QUESTION_TYPE_ID,
        IS_MANADATORY: IS_MANADATORY,
        QUESTION: QUESTION,
        OPTION1: OPTION1,
        OPTION2: OPTION2,
        OPTION3: OPTION3,
        OPTION4: OPTION4,
        type: "UPDATE",
      };
      props.onSubmit(model);
    } else {
      const model = {
        QUESTION_TYPE_ID: QUESTION_TYPE_ID,
        IS_MANADATORY: IS_MANADATORY,
        QUESTION: QUESTION,
        OPTION1: OPTION1,
        OPTION2: OPTION2,
        OPTION3: OPTION3,
        OPTION4: OPTION4,
        type: "ADD",
      };
      props.onSubmit(model);
    }
  };

  useEffect(() => {
    id && getJobQuestionDetails(id);
    (id ? document.title = constant.title.UpdateJobQuestion : document.title = constant.title.AddJobQuestion)
    getJobQuestionType();
  }, []);

  const getJobQuestionType = () => {
    listJobQuestionType()
      .then((res) => {
        if (res.status) {
          setQUESTIONTYPE(res.result);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const getJobQuestionDetails = (id) => {
    getQuestiontById({ QUESTION_ID: id })
      .then((res) => {
        const { OPTION1, OPTION2, OPTION3, OPTION4 } = JSON.parse(res.result[0].ANSWER)
        if (res.status) {
          setQUESTION_ID(res.result[0].QUESTION_ID)
          setQUESTION_TYPE_ID(res.result[0].QUESTION_TYPE_ID.toString())
          setIS_MANADATORY(res.result[0].IS_MANADATORY)
          setQUESTION(res.result[0].QUESTION)
          setOPTION1(OPTION1)
          setOPTION2(OPTION2)
          setOPTION3(OPTION3)
          setOPTION4(OPTION4)
        }
      })
      .catch((err) => {
        alert(err);
      });
  };
  const handleReset = () => {
    id && Navigate(constant.component.listJobQuest.url)
    setError(false)
    setQUESTION_TYPE_ID('')
    setIS_MANADATORY('')
    setQUESTION("")
    setOPTION1("")
    setOPTION2("")
    setOPTION3("")
    setOPTION4("")
  }
  return (
    <React.Fragment>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0" style={{ fontSize: "18px" }}>
                    {id ? " Update  Job Questions " : "  Add   Job Questions "}
                  </h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Jobs</a>
                      </li>
                      <li className="breadcrumb-item active">
                        {id ? " Update  Job Questions " : "  Add   Job Questions "}
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title mb-4">
                      Job Questions Information
                    </h4>
                    {
                      props.showMsg && (props.response.status ?
                        <SuccessMessage message={props.response.messageCode} />
                        :
                        <ErrorMessage message={props.response.error} />)
                    }
                    <div id="basic-example">
                      <section>
                        <form method="POST">
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label for="basicpill-firstname-input">
                                  Question Type <span className="text-danger"> *</span>
                                </label>
                                <select
                                  className={error && QUESTION_TYPE_ID.toString().trim().length <= 0 ? 'form-control is-invalid ' : 'form-control'}
                                  name="qt"
                                  id="qt"
                                  value={QUESTION_TYPE_ID}
                                  onChange={(e) => {
                                    setQUESTION_TYPE_ID(e.target.value);
                                  }}
                                >
                                  <option>Select Question Type</option>
                                  {

                                    QUESTIONTYPE.map((data, index) => {
                                      return <option value={data.QUESTION_TYPE_ID}>{data.QUESTION_TYPE}</option>
                                    })
                                  }
                                </select>
                                {
                                  error && QUESTION_TYPE_ID.toString().trim().length <= 0 ? <span className='text-danger ml-1'>This Field is Required</span>
                                    : ""}
                              </div>
                            </div>

                            <div className="col-lg-6">
                              <div
                                className="mb-12"
                                style={{ marginTop: "32px" }}
                              >
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="formCheck1"
                                  value={IS_MANADATORY}
                                  onChange={(e) => {
                                    setIS_MANADATORY(e.target.checked ? "Y" : "N");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  for="formCheck1"
                                >
                                  &nbsp;Manadatory
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label for="basicpill-address-input">
                                  Type your question <span className="text-danger"> *</span>
                                </label>
                                <input
                                  type="text"
                                  className={error && QUESTION.toString().trim().length <= 0 ? 'form-control is-invalid ' : 'form-control'}
                                  id="basicpill-firstname-input"
                                  placeholder="Enter Type your question"
                                  name="QUESTION"
                                  value={QUESTION}
                                  onChange={(e) => {
                                    setQUESTION(e.target.value);
                                  }}
                                />
                                {
                                  error && QUESTION.toString().trim().length <= 0 ? <span className='text-danger ml-1'>This Field is Required</span>
                                    : ""}
                              </div>
                            </div>
                          </div>
                          {
                            QUESTION_TYPE_ID !=="3"
                             &&
                            (
                              <div id="attid">
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="mb-3">
                                    <label for="basicpill-address-input">
                                      Add answer options <span className="text-danger"> *</span>
                                    </label>
                                    <input
                                      type="text"
                                      className={error && OPTION1.toString().trim().length <= 0 ? 'form-control is-invalid ' : 'form-control'}
                                      id="basicpill-firstname-input"
                                      placeholder="Option 1"
                                      value={OPTION1}
                                      onChange={(e) => {
                                        setOPTION1(e.target.value);
                                      }}
                                    />
                                    {
                                      error && OPTION1.toString().trim().length <= 0 ? <span className='text-danger ml-1'>This Field is Required</span>
                                        : ""}
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="mb-3">
                                    <input
                                      type="text"
                                      className={error && OPTION2.toString().trim().length <= 0 ? 'form-control is-invalid ' : 'form-control'}
                                      id="basicpill-firstname-input"
                                      placeholder="Option 2"
                                      value={OPTION2}
                                      onChange={(e) => {
                                        setOPTION2(e.target.value);
                                      }}
                                    />
                                    {
                                      error && OPTION2.toString().trim().length <= 0 ? <span className='text-danger ml-1'>This Field is Required</span>
                                        : ""}
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="mb-3">
                                    <input
                                      type="text"
                                      className={error && OPTION3.trim().length <= 0 ? 'form-control is-invalid ' : 'form-control'}
                                      id="basicpill-firstname-input"
                                      placeholder="Option 3"
                                      value={OPTION3}
                                      onChange={(e) => {
                                        setOPTION3(e.target.value);
                                      }}
                                    />
                                    {
                                      error && OPTION3.trim().length <= 0 ? <span className='text-danger ml-1'>This Field is Required</span>
                                        : ""}
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="mb-3">
                                    <input
                                      type="text"
                                      className={error && OPTION4.toString().trim().length <= 0 ? 'form-control is-invalid ' : 'form-control'}
                                      id="basicpill-firstname-input"
                                      placeholder="Option 4"
                                      value={OPTION4}
                                      onChange={(e) => {
                                        setOPTION4(e.target.value);
                                      }}
                                    />
                                    {
                                      error && OPTION4.toString().trim().length <= 0 ? <span className='text-danger ml-1'>This Field is Required</span>
                                        : ""}
                                  </div>
                                </div>
                              </div>
                            </div>
                            )
                          }
                        
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <button
                                  type="button"
                                  className="btn btn-info waves-effect btn-label waves-light"
                                  onClick={onSubmit}
                                >
                                  <i className="bx bx-check-double label-icon"></i>{" "}
                                  {id ? "Save Changes" : "Create Question"}
                                </button>
                                &nbsp;&nbsp;
                                <button
                                  type="button"
                                  onClick={handleReset}
                                  className="btn btn-danger waves-effect btn-label waves-light"
                                >
                                  <i className="bx bx-block label-icon "></i>{" "}
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AddQuestions;
