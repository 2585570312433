import React from 'react'
import List from '../../../component/Utilities/PracticeQuestion/PracticeQuestionList'
import constant from '../../../constant'
import { useEffect, useState } from 'react'
import queryString from 'query-string'
import { useLocation } from "react-router-dom";
import { archivePractiseQuestion, practiceQuestionList } from '../../../actions/utilitiesAction'

export default function PracticeQuestionList() {

    const [list, setList] = useState([])
    const [count, setCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    // const [keyword, setKeyword] = useState('')
    const [showLoader, setShowLoader] = useState(true)
    const location = useLocation()


    useEffect(() => {
        const qParams = queryString.parse(location.search)
        getPracticeQuestionlists(qParams?.p ? qParams?.p : qParams?.pageno ? qParams.pageno : currentPage)
        document.title = constant.title.ListPracticeQuestion
    }, [currentPage])

    const getPracticeQuestionlists = (page) => {
        setShowLoader(true)
        practiceQuestionList({ page: page }).then((res) => {

            if (res.status) {
                setList(res.result.list)
                setCount(res.result.count)
                setShowLoader(false)
            }
        }).catch((err) => {
            alert(err)
        })
    }

    const updateStatus = (model) => {
        archivePractiseQuestion(model).then((res) => {
            if (res.status) {
                getPracticeQuestionlists(currentPage)
            } else {
                alert(res.error)
            }
        }).catch((err) => {
            alert(err)
        })
    }

    return (
        <React.Fragment>
            <List list={list}
                getPracticeQuestionlists={(page => getPracticeQuestionlists(page))}
                updateStatus={updateStatus}
                count={count}
                showLoader={showLoader}
                setPage={setCurrentPage}
                cpage={currentPage}
            />
        </React.Fragment>
    )
}
