import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { employerIndustryList, archiveIndustry } from '../../actions/employer'
import Industrylist from '../../component/Employers/IndustryList'
import constant from '../../constant'
import queryString from 'query-string'

export default function IndustryList() {
    const [list, setList] = useState([])
    const [count, setCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [showLoader, setShowLoader] = useState(true)
    const [keyword, setKeyword] = useState('')
    const location = useLocation()

    useEffect(() => {
        const qParams = queryString.parse(location.search)
        getemployerIndustryList(qParams?.p ? qParams?.p : qParams?.pageno ? qParams.pageno : currentPage)
        document.title = constant.title.IndustryList
    }, [currentPage])

    // const getemployerIndustryList = (page) => {
    //     employerIndustryList({ page: page }).then(res => {
    //         if (res.status) {
    //             setList(res.result.list)
    //             setCount(res.result.count.total)
    //             setShowLoader(false)
    //         }
    //         else {
    //             alert(res.error)
    //         }
    //     }).catch(err => {
    //         alert(err)
    //     })
    // }
    const getemployerIndustryList = (pageNumber) => {

        employerIndustryList({ page: pageNumber, KEYWORD: keyword }).then(res => {

            if (res.status) {
                setList(res.result.list)
                setCount(res.result.count.total)
                setShowLoader(false)


            }
            else {
                alert(res.error)
            }
        }).catch(err => {
            alert(err)
        })
    }
    const updateStatus = (model) => {
        archiveIndustry(model).then((res) => {
            if (res.status) {
                getemployerIndustryList(currentPage)
            }
        })
    }

    return (
        <React.Fragment>
            {<Industrylist
                list={list}
                updateStatus={updateStatus}
                getemployerIndustryList={(page) => { getemployerIndustryList(page) }}
                count={count}
                setShowLoader={setShowLoader}
                showLoader={showLoader}
                setPage={setCurrentPage}
                keyword={setKeyword}
                cpage={currentPage}
            />}
        </React.Fragment>
    )
}
