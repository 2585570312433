import React from 'react'
import Discussion from './Discussionforumactivethread'

const EditDiscussionThread = () => {

  return (
    <React.Fragment>
    
<Discussion/>
    </React.Fragment>
  )
}

export default EditDiscussionThread
