import React from 'react'
import { capFirstLetterInSentence } from '../../utils';

export default function Statisic(props) {


   const list = props.statisicsList
   const CollegeList = props.CollegeStatisicsList
   const EmployeeList = props.EmployerStaticsList?.list
   console.log(EmployeeList, "EmployeeList");
   return (
      <React.Fragment>

         <div className='main-content main-content-statics'>
            <div className='page-content'>
               <div className='row'>
                  <div className='col-md-12'>
                     <div className='statisics-item'>
                        <div className='row'>
                           <div className='col-md-12'>
                              <h4 className="card-title mb-2">Overall Statistics</h4>
                           </div>
                        </div>
                        <div className='row'>
                           <div className='col-md-6'>
                              <div className="card">
                                 <div className="card-body">
                                    <div className="row">
                                       <div className="col-sm-12">
                                          <div className="d-flex justify-content-between align-items-center statisics-info-item">
                                             <p className="m-0">Total Employers</p>
                                             <h6 className="m-0">
                                                <span className="badge rounded-pill bg-light">{list && list.employer.employeCount}</span>
                                             </h6>
                                          </div>
                                          <div className="d-flex justify-content-between align-items-center statisics-info-item">
                                             <p className="m-0">Total Candidates</p>
                                             <h6 className="m-0">
                                                <span className="badge rounded-pill bg-light">{list && list.candidate.candidateCount}</span>
                                             </h6>
                                          </div>
                                          <div className="d-flex justify-content-between align-items-center statisics-info-item">
                                             <p className="m-0">Total Jobs</p>
                                             <h6 className="m-0">
                                                <span className="badge rounded-pill bg-light">
                                                   {list && list.jobs.jobsCount}
                                                </span>
                                             </h6>
                                          </div>
                                         

                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                          
                        </div>
                     </div>
                  </div>
               </div>
               <div className='row'>
                  <div className='col-md-12'>
                     <div className='statisics-item'>
                        <div className='row'>
                           <div className='col-md-12'>
                              <h4 className="card-title mb-2">Past 1 Day Statistics</h4>
                           </div>
                        </div>
                        <div className='row'>
                           <div className='col-md-6'>
                              <div className="card">
                                 <div className="card-body">
                                    <div className="row">
                                       <div className="col-sm-12">
                                          <div className="d-flex justify-content-between align-items-center statisics-info-item">
                                             <p className="m-0">Employers</p>
                                             <h6 className="m-0">
                                                <span className="badge rounded-pill bg-light">{list && list.pastdayemployer.pastdayEmployer}</span>
                                             </h6>
                                          </div>
                                          <div className="d-flex justify-content-between align-items-center statisics-info-item">
                                             <p className="m-0">Candidates</p>
                                             <h6 className="m-0">
                                                <span className="badge rounded-pill bg-light">{list && list.pastdaycandidate.pastdayCandidate}</span>
                                             </h6>
                                          </div>
                                          <div className="d-flex justify-content-between align-items-center statisics-info-item">
                                             <p className="m-0">Jobs</p>
                                             <h6 className="m-0">
                                                <span className="badge rounded-pill bg-light">
                                                   {list && list.pastdayjobs.pastdayjobs}
                                                </span>
                                             </h6>
                                          </div>

                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           
                        </div>
                     </div>
                  </div>
               </div>

               <div className="row">
                  <div className="col-lg-12">
                     <div className="card" >
                        <div className="card-body">
                           <h4 className="card-title mb-4">Job Posting Statistics - Packages & Responses</h4>
                           <div className="table-responsive">

                              <table className="table align-middle statisics-table table-nowrap table-hover">
                                 <thead className="table-light">
                                    <tr>
                                       <th scope="col">
                                          Employer Details

                                       </th>
                                       <th scope="col">Free</th>
                                       <th scope="col">Featured jobs</th>
                                       <th scope="col">Video JD</th>

                                    </tr>
                                 </thead>
                                 {EmployeeList && EmployeeList.map((item, index) => {
                                    console.log(item, "item");
                                    return (
                                       <tbody>
                                          <tr>
                                             <td>
                                                <span style={{ color: 'rgb(232, 28, 40)' }}>{item.COMPANY_NAME == null ? "NA" : item.COMPANY_NAME}</span>&nbsp; <span className='breck'> |&nbsp; </span><span style={{ color: 'rgb(0 175 21)' }}>{item.CONTACT_PERSON == null ? "NA" : item.CONTACT_PERSON.charAt(0).toUpperCase() + item.CONTACT_PERSON.slice(1)}</span>  <span className='breck'>&nbsp; |&nbsp;</span>{item.MOBILE == null ? "NA" : item.MOBILE}<span className='breck'> &nbsp;|&nbsp; </span> <span style={{ color: '#0000FF' }}>{item.EMAIL == null ? "NA" : item.EMAIL}</span>
                                             </td>
                                             <td>
                                                ({item.regularjobpost}/5) (0 Responses)
                                             </td>
                                             <td>
                                                ({item.featuredjobpost}/{item.totalfeaturejobsbalance}) (0 Responses)
                                             </td>

                                             <td>
                                                ({item.videojdjobpost}/{item.totaljdjobsbalance}) (0 Responses)
                                             </td>
                                          </tr>



                                       </tbody>
                                    )
                                 })}
                              </table>


                           </div>

                        </div>
                     </div>
                  </div>
               </div>

            </div>
         </div>
      </React.Fragment>
   )
}
