import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { archiveBlog, blogCategory, blogList } from '../../../actions/utilitiesAction'
import Bloglist from '../../../component/Utilities/Blog/BlogList'
import constant from '../../../constant'
import { getCategory } from '../../../actions/utilitiesAction';

export default function BlogList() {

    const [list, setList] = useState([])
    const [count, setCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [keyword, setKeyword] = useState('')
    const [category, setCategory] = useState([])
    const params = useParams()
    const [showLoader, setShowLoader] = useState(false)

    useEffect(() => {

        blogLists(currentPage, "")
        document.title = constant.title.BlogList

        getCategory().then((res) => {
            // this.setState({ blogCategory: res.result.list })
            setCategory(res.result.list)

        }).catch((err) => {
            alert(err)
        })


    }, [currentPage])

    const blogLists = (page) => {
        setShowLoader(true)
        blogList({ page: page, KEYWORD: keyword }).then((res) => {
            setShowLoader(false)
            setList(res.result.list)
            setCount(res.result.count)
        }).catch((err) => {
            alert(err)
        })
    }

    const updateStatus = (model) => {
        archiveBlog(model).then((res) => {
            if (res.status) {
                blogLists(currentPage, "")

            } else {
                alert(res.error)
            }
        }).catch((err) => {
            alert(err)
        })
    }




    return (
        <React.Fragment>
            {
                <Bloglist
                    list={list}
                    blogLists={(page => blogLists(page))}
                    count={count}
                    setPage={setCurrentPage}
                    keyword={setKeyword}
                    updateStatus={updateStatus}
                    categoryList={category.length > 0 ? category : []}
                    showLoader={showLoader}
                />
            }
        </React.Fragment>
    )
}
