import React, { useEffect } from "react";
import { addFaq, addJobsFaq, getActiveFQACategory, getActiveJobsFQACategory, updateFaq, updateJobsFaq } from "../../../actions/utilitiesAction";
import Add from "../../../component/Utilities/JobsFAQs/AddFAQ";
import constant from "../../../constant";
import { useState } from "react";

const AddFAQ = () => {

    const [response, setResponse] = useState({})
    const [showMsg, setShowMsg] = useState(false)
    const [list, setList] = useState([])

    const onSubmit = (model) => {
        if (model.type === 'ADD') {
            addJobsFaq(model).then((res) => {
                setResponse(res)
                setShowMsg(true)
                setTimeout(() => {
                    setShowMsg(false)
                    if (res.status) {

                        window.location.href = constant.component.jobsfaqlist.url;
                    }

                }, 7000)
                // if (res.status) {
                //     alert(res.messageCode)
                //     window.location.href = constant.component.faqlist.url;
                // }
            }).catch((err) => {
                alert(err)
            })
        } else {
            updateJobsFaq(model).then((res) => {
                // if (res.status) {
                //     alert(res.messageCode)
                //     window.location.href = constant.component.faqlist.url;
                // }
                setResponse(res)
                setShowMsg(true)
                setTimeout(() => {
                    setShowMsg(false)
                    if (res.status) {
                        window.location.href = constant.component.jobsfaqlist.url;

                    }
                }, 7000)
            }).catch((err) => {
                alert(err)
            })
        }

    }
    useEffect(() => {
        getActiveJobsFQACategory().then((res) => {
            setList(res.result)
        }).catch((err) => {
            alert(err)
        })
    }, [])

    return (
        <React.Fragment>
            <Add list={list} onSubmit={(data) => onSubmit(data)} response={response} showMsg={showMsg} />
        </React.Fragment>
    )
}

export default AddFAQ;