import * as axios from "axios";
import { getAuthHeader } from "./../utils";

export const createEducationQualification = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/create-educational-qualification`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const updateEducationQualification = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/update-educational-qualification`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const educationalQualificationList = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/educational-qualification-list`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};
export const qualificationGovtList = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/educational-qualification-govt`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};
export const govtDepartmentList = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/department-list?STATE_ID=${model.STATE_ID}&CATEGORY=${model.CATEGORY}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};
export const archiveEducationQualification = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/archive-educational-qualification`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const educationQualificationType = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/educational-qualification-type`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const getEducationQualificationById = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/get-educational-qualification-by-id`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};


//question 
export const createJobQuestion = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/create-job-question`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const updateJobQuestion = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/update-job-question`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const listJobQuestion = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/job-question-list`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const archiveJobQuestion = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/archive-job-question`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const listJobQuestionType = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/job-questiontype-list`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const getQuestiontById = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/get-job-question-by-id`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

//Role

export const createRole = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/create-job-role`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const updateRole = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/update-job-role`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const roleList = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/job-role-list`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const archiveRole = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/archive-job-role`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const getRoleByID = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/get-job-role-by-id`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

//Functional Area

export const createFunctionalarea = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/create-functional-area`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const updateFunctionalarea = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/update-functional-area`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const functionalareaList = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/functional-area-list`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const archiveFunctionalarea = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/archive-functional-area`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const getFunctionalAreaById = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/get-functional-area-by-id`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

//Industry

export const createIndustry = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/create-industry`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const updateIndustry = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/update-industry`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const industryList = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/industry-list`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const archiveIndustry = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/archive-industry`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const getIndustryById = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/get-industry-by-id`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

//Employment Type

export const createEmploymentType = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/create-employment-type`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const updateEmploymentType = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/update-employment-type`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const employmentTypeList = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/employment-type-list`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const archiveEmploymentType = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/archive-employment-type`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const getEmploymentTypeById = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/get-employment-type-by-id`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

//Course Specialization

export const createCourseSpecZ = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/create-course-specz`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const updateCourseSpecZ = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/update-course-specz`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const CourseSpecZList = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/course-specz-list`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const archiveCourseSpecZ = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/archive-course-specz`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const getCourseSpecZById = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/get-course-specz-by-id`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const getCourseType = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/get-course-type-list`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

//Apis for jobs in job section

export const employmentTypeListsForJobs = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/employment-type-dropdown-list`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const getKeywordSkillsListsForJobs = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/kewords-list`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const getIndustryListsForJobs = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/industry-dropdown-list`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const getFunctionalAreaListsForJobs = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/functional-area-dropdown-list`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const getQuestionListsForJobs = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/job-question-dropdown-list`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const getRoleListsForJobs = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/role-dropdown-list`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const getQualificationTypeListsForJobs = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/educational-qualification-type`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const getEmployerCompanyListsForJobs = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/rzg-jobs-by-company-list`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const getEduQualificationTypeListsForJobs = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/get-course-type-list`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const getCourseSpeczListForJobs = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/course-list-dropdown`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const getStateListsForJobs = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/job-state-list`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const getCityListsForJobs = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/job-city-list`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const getLocalityListsForJobs = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/job-locality-list`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};


// Create Job

export const createJob = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/create-job`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};


export const listJob = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/list-job?page=${model.pageNumber}&KEYWORD=${model.KEYWORD}`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const listcompanyreview = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/admin/getEmployerReviewList?page=${model.pageNumber ? model.pageNumber:1}&STATUS=${model.STATUS}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const getcompanyreview = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/admin/getreview-byCompany?page=${model.page ? model.page : 1}&CANDIDATE_EMPLOYER_ID=${model.CANDIDATE_EMPLOYER_ID}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const updatereview = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/admin/UpdateEmployerReviewStatus`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const ScrappedlistJob = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/scrapped-list-job`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};


export const archiveJob = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/archive-job`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const updateJob = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/update-job`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const getJobDetailById = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/job-detail`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const removeJobCity = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/remove-job-city`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const removeJobLocality = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/remove-job-locality`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const removeJobEducationQualification = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/remove-job-education-qualification`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const removeJobCourseSpecialization = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/remove-job-course-specialization`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const salaryLists = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/salary-lists`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const listJobPosts = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/approved-post-jobs-list?page=${model.pageNumber}&STATUS=${model.STATUS}&KEYWORDS=${model.KEYWORDS}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const listJobPost = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/post-jobs-list?page=${model.pageNumber}&STATUS=${model.STATUS}&KEYWORDS=${model.KEYWORDS}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const JobPostStatus = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/job-post-status?`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

// export const JobPostRequestList = (model) => {
//     const url = `${process.env.REACT_APP_BASE_URL}/job-post-request`;
//     return axios.get(url, getAuthHeader()).then((res) => {
//         return res.data;
//     })
// };

// Counts apis

// export const getTotalJobsCount = (model) => {
//     const url = `${process.env.REACT_APP_BASE_URL}/total-job`;
//     return axios.get(url, getAuthHeader()).then((res) => {
//         return res.data;
//     })
// };

// export const getTotalFaqCount = (model) => {
//     const url = `${process.env.REACT_APP_BASE_URL}/total-faq`;
//     return axios.get(url, getAuthHeader()).then((res) => {
//         return res.data;
//     })
// };

// export const getTotalEmployerCount = (model) => {
//     const url = `${process.env.REACT_APP_BASE_URL}/total-employer`;
//     return axios.get(url, getAuthHeader()).then((res) => {
//         return res.data;
//     })
// };

// export const getTotalBlogCount = (model) => {
//     const url = `${process.env.REACT_APP_BASE_URL}/total-blog`;
//     return axios.get(url, getAuthHeader()).then((res) => {
//         return res.data;
//     })
// };

export const premiumJobList = (pageNumber) => {
    const url = `${process.env.REACT_APP_BASE_URL}/cppremium-list-job?page=${pageNumber}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const emppremiumJobList = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/cppremium-list-job?EMPLOYER_ID=${model.EMPLOYER_ID}&page=${model.page}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const empfeaturedJobList = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/cpfeatured-list-job?EMPLOYER_ID=${model.EMPLOYER_ID}&page=${model.page}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const employersjobs = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/list-job?EMPLOYER_ID=${model.EMPLOYER_ID}&page=${model.page}`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const employersjobprofile = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/employer-list?EMPLOYER_ID=${model.EMPLOYER_ID}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};


export const featuredJobList = (pageNumber) => {
    const url = `${process.env.REACT_APP_BASE_URL}/cpfeatured-list-job?page=${pageNumber}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};