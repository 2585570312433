import React, { useEffect, useState } from 'react'
import DetailComponent from '../../../component/campus/college/collegeDetail'
import { useParams } from 'react-router-dom'
import { collegeProfile } from '../../../actions/campus';
import Loader from '../../../component/common/Loader'
const CollegeDetail = () => {
    const params = useParams();
    const [details, setDetails] = useState(null);
    const [showLoader, setShowLoader] = useState(true)
    useEffect(() => {
        collegeProfile(params.id).then((res) => {
            if (res.status) {
                setShowLoader(false)
                setDetails(res.result)

            }
            else {
                alert(res.error)
            }
        })
    }, [])
    return (
        <React.Fragment>
            {showLoader && <Loader />}
            {details && <DetailComponent
                details={details}
            />}
        </React.Fragment>
    )
}

export default CollegeDetail