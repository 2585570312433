import React, { useEffect, useState } from 'react'
import Pagination from 'react-js-pagination';
import moment from 'moment';
import Swal from 'sweetalert2';
import Loader from '../../common/Loader';
import { Link } from 'react-router-dom';
import constant from '../../../constant';
import Modal from 'react-modal';
import "../../../assets/css/secondary.css"
import { addinterviewQuestionbyAdmin, addShareInterviewQuestionbyAdmin } from '../../../actions/utilitiesAction';
import { getStorage } from '../../../utils';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        width: '50%',

        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
    overlay: {
        zIndex: 9999,
        backgroundColor: "rgba(1, 0, 0, 0.66)",
    }
};

export default function InterviewAnswer(props) {

    const [currentPage, setCurrentPage] = useState(1)
    const [showData, setShowData] = useState({})
    const [modal, setModal] = useState(false)
    const [tempData, setTempData] = useState()
    const [question, setQuestion] = useState()
    const [answer, setAnswer] = useState()
    const [questionId, setQuestionID] = useState()
    const [modal1, setModal1] = useState(false)
    const [interviewQuestionAnswer, setInterviewQuestionAnswer] = useState()

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        props.setPage(pageNumber)
    };

    const updateStatus = (INTERVIEW_QUESTIONS_ID, STATUS) => {
        const Details = JSON.parse(localStorage.getItem("ud.jobzshala.com"))
        const model = {
            INTERVIEW_QUESTIONS_ID: INTERVIEW_QUESTIONS_ID,
            STATUS: STATUS == 'U' ? 'A' : 'U',
            MODIFIED_BY: Details.ADMIN_ID
        }
        props.setPage(currentPage)
        props.updateStatus(model)
    }
    const updateStatus1 = (INTERVIEW_QUESTIONS_ID, STATUS) => {
        const Details = JSON.parse(localStorage.getItem("ud.jobzshala.com"))
        const model = {
            INTERVIEW_QUESTIONS_ID: INTERVIEW_QUESTIONS_ID,
            STATUS: STATUS == 'U' ? 'A' : 'U',
            MODIFIED_BY: Details.ADMIN_ID
        }
        props.setPage(currentPage)
        props.updateStatus1(model)
    }

    const deleteAnswer = (INTERVIEW_QUESTIONS_ANSWER_ID) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to delete this Answer? ",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                props.deleteAnswerId(INTERVIEW_QUESTIONS_ANSWER_ID)
            }
        })
    }
    const onClickOpenPopUp = (INTERVIEW_QUESTIONS_ID, Question, Answer, INTERVIEW_QUESTIONS_ANSWER_ID) => {
        setModal(true)
        setInterviewQuestionAnswer(INTERVIEW_QUESTIONS_ANSWER_ID)
        setQuestionID(INTERVIEW_QUESTIONS_ID)
        setQuestion(Question)
        setAnswer(Answer)
    }
    const onClickOpenPopUp1 = (INTERVIEW_QUESTIONS_ID, Question, Answer) => {
        setModal1(true)
        setQuestionID(INTERVIEW_QUESTIONS_ID)
        setQuestion(Question)
        setAnswer(Answer)
    }
    function closeModal() {
        setModal(false);
    }
    function closeModal1() {
        setModal1(false);
    }
    const onFormSubmit = (e) => {
        const AdminID = JSON.parse(localStorage.getItem('ud.jobzshala.com'))
        console.log("AdminID", AdminID.ADMIN_ID)
        const modal = {
            INTERVIEW_QUESTIONS_ID: questionId,
            INTERVIEW_QUESTIONS_ANSWER_ID: interviewQuestionAnswer,
            QUESTION_TITLE: question,
            ANSWER: answer,
            AdminID: AdminID.ADMIN_ID
        }
        addinterviewQuestionbyAdmin(modal).then((res) => {
            Swal.fire({
                icon: "success",
                text: "Changes Added Successfully",
                timer: 2000,
                showCancelButton: false,
                showConfirmButton: false,
            });
            props.getInterviewAnswerList()
            closeModal()

        }).catch((err) => {
            alert(err)
        })
    }


    const onFormSubmit1 = (e) => {
        const AdminID = JSON.parse(localStorage.getItem('ud.jobzshala.com'))
        console.log("AdminID", AdminID.ADMIN_ID)
        const modal = {
            INTERVIEW_QUESTIONS_ID: questionId,
            QUESTION_TITLE: question,
            ANSWER: answer,
            AdminID: AdminID.ADMIN_ID
        }
        addShareInterviewQuestionbyAdmin(modal).then((res) => {
            Swal.fire({
                icon: "success",
                text: "Changes Added Successfully",
                timer: 2000,
                showCancelButton: false,
                showConfirmButton: false,
            });
            props.getInterviewAnswerList()
            closeModal1()
        }).catch((err) => {
            alert(err)
        })
    }



    return (
        <React.Fragment>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0" style={{ fontSize: "18px" }}><span style={{ color: "#df0070" }}>{props.questionTitle} </span>{props.list[0] ? `Interview Questions |  ${props.list[0].CANDIDATE_NAME} | ${props.list[0].CANDIDATE_COMPANY ? props.list[0].CANDIDATE_COMPANY : null}` : null}</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href={constant.component.shareInterviewQuestion.url}>Shared Interview Question</a> |</li>
                                            <li className="breadcrumb-item active"><Link to={constant.component.shareInterviewQuestion.url} className="waves-effect">Questions list
                                            </Link></li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table align-middle table-nowrap table-hover">
                                                {props.showLoader &&
                                                    <Loader />
                                                }
                                                {!props.showLoader && <thead className="table-light">
                                                    <tr>
                                                        <th scope="col" style={{ width: "70px" }}>S.No.</th>
                                                        <th scope="col" style={{ width: "500px" }}>Questions</th>
                                                        <th scope="col" style={{ width: "500px" }}>Answers</th>

                                                        <th scope="col" style={{ width: "100px" }}>Added On</th>
                                                        <th scope="col" style={{ width: "100px" }}>Status</th>
                                                        <th scope="col" style={{ width: "100px" }}>Action</th>
                                                    </tr>
                                                </thead>}
                                                <tbody>
                                                    {console.log("props", props.list)}
                                                    {props.list && props.list.length > 0 &&
                                                        props.list.map((item, index, list) => {
                                                            return (
                                                                <tr >
                                                                    <td>
                                                                        {currentPage == 1 ? `${index + 1}` : `${index == 9 ? currentPage : currentPage - 1}` + `${index == 9 ? 0 : index + 1}`}
                                                                    </td>

                                                                    <td style={{ whiteSpace: "normal" }}>
                                                                        {item.QUESTION_TITLE.slice(0, 70)}
                                                                    </td>
                                                                    <td style={{ whiteSpace: "normal" }}>
                                                                        {item.ANSWER != null && Object.keys(item.ANSWER).length != 0 ? item.ANSWER.ANSWER.slice(0, 70) : ""}
                                                                        {item.ANSWER != null && Object.keys(item.ANSWER).length != 0 && item.ANSWER.length > 60 ? <a href="#" onClick={() => setShowData(item.ANSWER)} data-bs-toggle="modal" data-bs-target=".bs-example-modal-lg">...read more</a> : " "}
                                                                    </td>
                                                                    <td>

                                                                        {moment(item.CREATED_ON).format('DD.MM.YYYY on hh:mma')}
                                                                    </td>
                                                                    <td>
                                                                        <ul className="list-inline contact-links mb-0" style={{ fontSize: "20px" }}>
                                                                            <li className="list-inline-item px-2" >
                                                                                <a href="javascript: void(0)" onClick={() => updateStatus(item.INTERVIEW_QUESTIONS_ID, item.STATUS)}>                                                                                    {
                                                                                    item.STATUS == 'U' ?

                                                                                        <button type="button" className="btn btn-success btn-sm waves-effect waves-light">APPROVED</button>
                                                                                        : <button type="button" className="btn btn-danger btn-sm waves-effect waves-light" style={{ width: "74px" }}>PENDING</button>
                                                                                }

                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </td>


                                                                    <td>
                                                                        <ul className="list-inline contact-links mb-0" style={{ fontSize: "20px", cursor: 'pointer' }} onClick={() => onClickOpenPopUp(item.INTERVIEW_QUESTIONS_ID, item.QUESTION_TITLE, item.ANSWER.ANSWER, item.ANSWER.INTERVIEW_QUESTIONS_ANSWER_ID)}>
                                                                            <li className="list-inline-item px-2">
                                                                                <i className="bx bx-edit"></i>
                                                                            </li>

                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                            )

                                                        })
                                                    }


                                                    {props.list1 && props.list1.length > 0 &&
                                                        props.list1.map((item, index, list) => {
                                                            return (
                                                                <tr >
                                                                    <td>
                                                                        {currentPage == 1 ? `${index + 2}` : `${index == 9 ? currentPage : currentPage - 1}` + `${index == 9 ? 0 : index + 1}`}
                                                                    </td>

                                                                    <td style={{ whiteSpace: "normal" }}>
                                                                        {item.QUESTION_TITLE.slice(0, 70)}
                                                                    </td>
                                                                    <td style={{ whiteSpace: "normal" }}>
                                                                        {item.ANSWER != null && Object.keys(item.ANSWER).length != 0 ? item.ANSWER.ANSWER.slice(0, 70) : ""}
                                                                        {item.ANSWER != null && Object.keys(item.ANSWER).length != 0 && item.ANSWER.ANSWER.length > 60 ? <a href="#" onClick={() => setShowData(item.ANSWER)} data-bs-toggle="modal" data-bs-target=".bs-example-modal-lg">...read more</a> : ""}

                                                                    </td>
                                                                    <td>
                                                                        {moment(item.CREATED_ON).format('DD.MM.YYYY on hh:mma')}
                                                                    </td>
                                                                    <td>
                                                                        <ul className="list-inline contact-links mb-0" style={{ fontSize: "20px" }}>
                                                                            <li className="list-inline-item px-2" >
                                                                                <a href="javascript: void(0)" onClick={() => updateStatus1(item.INTERVIEW_QUESTIONS_ID, item.STATUS)}>                                                                                    {
                                                                                    item.STATUS == 'U' ?
                                                                                        <button type="button" className="btn btn-success btn-sm waves-effect waves-light">APPROVED</button>
                                                                                        : <button type="button" className="btn btn-danger btn-sm waves-effect waves-light" style={{ width: "74px" }}>PENDING</button>
                                                                                }

                                                                                </a>
                                                                            </li>


                                                                        </ul>
                                                                    </td>
                                                                    <td>
                                                                        <ul className="list-inline contact-links mb-0" style={{ fontSize: "20px", cursor: 'pointer' }} onClick={() => onClickOpenPopUp1(item.INTERVIEW_QUESTIONS_ID, item.QUESTION_TITLE, item.ANSWER.ANSWER)}>
                                                                            <li className="list-inline-item px-2">
                                                                                <i className="bx bx-edit"></i>
                                                                            </li>

                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                            )

                                                        })

                                                    }
                                                    {
                                                        <>
                                                            {
                                                                props.list && props.list.length == 0 && props.showLoader == false &&
                                                                <td colSpan={10} className="text-center text-danger pt-3">No Answers are Avaliable <br />Please Go Back</td>
                                                            }
                                                        </>
                                                    }
                                                    {
                                                        <div className="modal fade bs-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                                                            <div className="modal-dialog modal-lg">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title" id="myLargeModalLabel">{props.questionTitle}</h5>
                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                    </div>
                                                                    <div className="modal-body">

                                                                        <div className="mb-3">
                                                                            {showData.ANSWER}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <ul className="pagination pagination-rounded justify-content-center mt-4">
                                                    {/* <Pagination
                                                        activePage={currentPage}
                                                        itemsCountPerPage={10}
                                                        totalItemsCount={props.count}
                                                        pageRangeDisplayed={5}
                                                        onChange={handlePageChange}
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                    /> */}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal
                        isOpen={modal}
                        onRequestClose={closeModal}
                        style={customStyles}
                        contentLabel="Example Modal"

                    >
                        <heder style={{ fontSize: '1.5em' }}>Update Interview</heder>
                        <hr />
                        <div >
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <label htmlFor='' style={{ fontSize: '1.2em' }}>Question</label>
                                <input type='text' className="form-control" value={question} onChange={(e) => setQuestion(e.target.value)} style={{ border: '1px solid #ddd', outline: 'none', padding: '0 0.5em', lineHeight: '2.9' }} />
                            </div>
                            {console.log("testQuestion", question)}
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <label htmlFor='' style={{ fontSize: '1.2em' }}>Answer</label>
                                <textarea value={answer} rows="4" className="form-control" onChange={(e) => setAnswer(e.target.value)} style={{ border: '1px solid #ddd', outline: 'none', padding: '0 0.7em', lineHeight: '2.9' }}>
                                </textarea>

                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '1em 0' }}>
                                <button type="button" className="pichu" onClick={closeModal} style={{ color: 'white', backgroundColor: 'grey', border: 'none', fontSize: '1.5em', textAlign: 'center' }}>Cancel</button>
                                <button type="button" className='pikachu' onClick={onFormSubmit} style={{ border: 'none', color: 'white', backgroundColor: 'red', fontSize: '1.5em', textAlign: 'center' }}>Submit</button>
                            </div>
                        </div>
                    </Modal>

                    {/* for mapper */}
                    <Modal
                        isOpen={modal1}
                        onRequestClose={closeModal1}
                        style={customStyles}
                        contentLabel="Example Modal"
                    >
                        <heder style={{ fontSize: '1.5em' }}>Update Interview</heder>
                        <hr />
                        <div >
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <label htmlFor='' style={{ fontSize: '1.5em' }}>Question</label>
                                <input type='text' value={question} onChange={(e) => setQuestion(e.target.value)} style={{ border: '1px solid #ddd', outline: 'none', padding: '0 0.5em' }} />
                            </div>
                            {console.log("testQuestion", question)}
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <label htmlFor='' style={{ fontSize: '1.5em' }}>Answer</label>
                                <textarea value={answer} rows="4" onChange={(e) => setAnswer(e.target.value)} style={{ border: '1px solid #ddd', outline: 'none', padding: '0 0.7em' }}>
                                </textarea>

                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '1em 0' }}>
                                <button type="button" className="pichu" onClick={closeModal1} style={{ color: 'white', backgroundColor: 'grey', border: 'none', fontSize: '1.5em', textAlign: 'center' }}>Cancel</button>
                                <button type="button" className='pikachu' onClick={onFormSubmit1} style={{ border: 'none', color: 'white', backgroundColor: 'red', fontSize: '1.5em', textAlign: 'center' }}>Submit</button>
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>
        </React.Fragment >
    )
}
