import React from 'react'
import Pagination from 'react-js-pagination'
import { useState } from 'react'
import Swal from 'sweetalert2'
import Loader from '../common/Loader'


const ComplaintList = (props) => {

    const [currentPage, setCurrentPage] = useState(1)

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber)
        props.setPage(pageNumber)
    }


    const deleteComplaint = (COMPLAINT_ID) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to delete this comment? ",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                props.deleteComplaintList(COMPLAINT_ID)
            }
        })

    }

    return (
        <React.Fragment>

            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0" style={{ fontSize: "18px" }}>Complaint List</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="#">Operations</a></li>
                                            <li className="breadcrumb-item active">Complaint List</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table align-middle table-nowrap table-hover">

                                                {
                                                    props.showLoader &&
                                                    <Loader />
                                                }
                                                {!props.showLoader && <thead className="table-light">
                                                    <tr>
                                                        <th scope="col" style={{ width: "70px" }}>#</th>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">Email</th>
                                                        <th scope="col">Phone</th>
                                                        <th scope="col" width={{ width: "300px" }}>Message</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>}
                                                <tbody>
                                                    {props.list && props.list.length > 0 &&
                                                        props.list.map((item, index, list) => {
                                                            return (
                                                                <tr>
                                                                    <td>
                                                                        <div className="avatar-xs">
                                                                            <span className="avatar-title rounded-circle">
                                                                                {item.NAME.charAt(0).toUpperCase()}
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <h5 className="mb-1" style={{ fontSize: "14px", textTransform: "capitalize" }}><a href="javascript:void(0);" className="text-dark">{item.NAME}</a></h5>

                                                                    </td>
                                                                    <td>{item.EMAIL}</td>
                                                                    <td> {item.PHONE}</td>
                                                                    <td>
                                                                        <span style={{ color: "#f46a6a" }}></span>
                                                                        <p className="text-muted mb-0" style={{ whiteSpace: "normal" }}>{item.MESSAGE}</p><br />
                                                                        <a title="Click to View reply" data-bs-toggle="modal" style={{ cursor: "pointer", color: "blue" }} data-bs-target={"#MODELID" + item.COMPLAINT_ID} >Reply</a>

                                                                        <div className="modal fade" id={"MODELID" + item.COMPLAINT_ID} data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                                                                                <div className="modal-content">
                                                                                    <div className="modal-header">
                                                                                        <h5 className="modal-title" id="staticBackdropLabel">{item.MESSAGE}</h5>
                                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                    </div>

                                                                                    <div className="modal-body">

                                                                                        <table className="table table-striped mb-0">
                                                                                            <tbody>
                                                                                                <tr>
                                                                                                    <td><p><span style={{ color: "black" }}>Message:</span> {item.MESSAGE} </p></td>
                                                                                                </tr>

                                                                                                <tr>
                                                                                                    <td><textarea name="reply"></textarea></td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>

                                                                                    </div>
                                                                                    <div className="modal-footer">
                                                                                        <button type="button" className="btn btn-light" data-bs-dismiss="modal">Send</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <ul className="list-inline contact-links mb-0" style={{ fontSize: "20px" }}>
                                                                            <li className="list-inline-item px-2">
                                                                                <a href="javascript:void(0);" onClick={() => deleteComplaint(item.COMPLAINT_ID)} style={{ color: "red" }} title="Delete User"><i className="bx bx-trash"></i></a>
                                                                            </li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }

                                                    {
                                                        <>
                                                            {
                                                                props.list && props.list.length == 0 && props.showLoader == false &&
                                                                <td colSpan={10} className="text-center text-danger pt-3">No Record Found</td>
                                                            }
                                                        </>
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <ul className="pagination pagination-rounded justify-content-center mt-4">
                                                    <Pagination
                                                        activePage={currentPage}
                                                        itemsCountPerPage={10}
                                                        totalItemsCount={props.count}
                                                        pageRangeDisplayed={5}
                                                        onChange={handlePageChange}
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                    />
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </React.Fragment>
    )
}

export default ComplaintList
