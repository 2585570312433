import React, { useEffect } from "react";
import Sidebar from "./component/common/Sidebar"
import Footer from "./component/common/Footer"
import Header from "./component/common/Header"

import { getStorage } from "./utils";
import constant from "./constant";
import { getProfileInfromation } from "./actions/ProfileAction";

export const Layout = ({ children }) => {

    const token = getStorage(constant.keys.token);

    const [ud, setState] = React.useState(getStorage(constant.keys.userDetails))
    const [ProfileInfromation, setProfileInfromation] = React.useState('')


    useEffect(() => {
        const tk = getStorage(constant.keys.token)
        if (ud && tk) {
            getProfileInfromation(ud.ADMIN_ID).then((res) => {
                setProfileInfromation(res.result)
            }).catch((err) => {
                alert(err)
            })
            setProfileInfromation(ud)
        }




    }, [])

    return (
        <React.Fragment >
            {ud && token && <Header pp={ProfileInfromation} />}
            {ud && token && <Sidebar />}
            {children}
            {ud && token && <Footer />}
        </React.Fragment >
    )


};

