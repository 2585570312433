import React, { Children, useEffect, useRef, useState } from "react";
import { addFaq, updateFaq } from "../../actions/utilitiesAction";
import { useLocation } from "react-router-dom";
import constant from "../../constant";
import QualificationLists from "../../component/jobs/QualificationList";
import { archiveEducationQualification, archiveJobQuestion, createEducationQualification, listJobQuestion, updateEducationQualification } from "../../actions/JobsAction";
import QuestionLists from "../../component/jobs/QuestionList";
import queryString from 'query-string'

const QuestionList = () => {
    const [questionList, setQuestionLists] = useState([]);
    const [currentPage, setCurrentPage] = useState(1)
    const [count, setCount] = useState(0)
    const location = useLocation()



    useEffect(() => {
        const qParams = queryString.parse(location.search)
        getJobQuestionList(qParams?.p ? qParams?.p : qParams?.pageno ? qParams.pageno : currentPage);
    }, [currentPage]);

    const getJobQuestionList = (page) => {
        listJobQuestion({ pageNumber: page })
            .then((res) => {

                console.log(res.result);
                if (res.status) {
                    setQuestionLists(res.result.list);
                    setCount(res.result.count)
                }
            })
            .catch((err) => {
                alert(err);
            });
    };

    const updateStatus = (model) => {
        archiveJobQuestion(model)
            .then((res) => {
                if (res.status) {
                    getJobQuestionList(currentPage);
                }
            })
            .catch((err) => {
                alert(err);
            });
    }


    return (
        <React.Fragment>
            <QuestionLists
                questionList={questionList}
                updateStatus={updateStatus}
                getJobQuestionList={(page => getJobQuestionList(page))}
                count={count} setPage={setCurrentPage} />

        </React.Fragment>
    )
}

export default QuestionList;