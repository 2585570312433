import React from 'react'
import { useEffect, useState } from 'react'
import List from '../../component/Enquiry/saleEnquiryList'
import constant from '../../constant'
import { deletesalesEnquiryId, salesEnquiryList } from '../../actions/utilitiesAction'
import Swal from 'sweetalert2'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

export default function EnquiryList() {

    const [list, setList] = useState([])
    const [count, setCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [showLoader, setShowLoader] = useState(false)
    const location = useLocation()

    useEffect(() => {
        const qParams = queryString.parse(location.search)
        salesEnquiryList(qParams?.p ? qParams?.p :currentPage).then(res => {
            if (res.status) {
                setList(res.result.list)
                setCount(res.result.count)
            }
            else {
                alert(res.error)
            }

        }).catch(err => {
            alert(err)
        })

    }, [currentPage])
    const deleteSalesEnquiryList = (ENQUIRY_ID) => {
        deletesalesEnquiryId({ ENQUIRY_ID: ENQUIRY_ID }).then((res) => {
            if (res.status) {
                Swal.fire(
                    'Deleted!',
                    'Your enquiry has been deleted.',
                    'success'
                )
                salesEnquiryList(currentPage).then(res => {
                    setShowLoader(false)
                    if (res.status) {
                        setList(res.result.list)
                        setCount(res.result.count)
                    }
                    else {
                        alert(res.error)
                    }
        
                }).catch(err => {
                    alert(err)
                })
            }
            else {
                Swal.fire(
                    (res.result.error)
                )
            }
        })
    }
    return (
        <React.Fragment>
            <List
                list={list}
                count={count}
                currentPage={currentPage}
                setPage={setCurrentPage}
                showLoader={showLoader}
                deleteSalesEnquiryList={deleteSalesEnquiryList}

            />
        </React.Fragment>
    )
}
