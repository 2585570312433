import React, { useEffect } from 'react'
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import constant from '../../constant';
import { useState } from 'react';
import { appliedJobCount, totalBlog, totalCandidate, totalcandidatesList, totalEmployer, totalEnquiryCount, totalFAQ, totalJob, totalJobAlertCount, totalPostJob, totalReviewCount } from '../../actions/ProfileAction';
import { appliedJobs, TotalInterviewCount, TotalShareInterviewCount } from '../../actions/utilitiesAction';

const Sidebar = (props) => {
    const [jobsFaq, setJobsFaq] = useState(false)
    const [employer, setEmployer] = useState(false);
    const [cvsearch, setCvsearch] = useState(false);
    const [hrms, setHrms] = useState(false);
    const [jobs, setJobs] = useState(false);
    const [premiumjobs, setPremiumjobs] = useState(false);
    const [featuredjobs, setFeaturedjobs] = useState(false);
    const [govtjobs, setGovtjobs] = useState(false);
    const [govtresult, setGovtresult] = useState(false);

    const [jobcandidate, setJobcandidate] = useState(false);
    const [jobapplication, setJobapplication] = useState(false);
    const [candidate, setCandidate] = useState(false);
    const [cvbooster, setCvbooster] = useState(false);
    const [resumesample, setResumesample] = useState(false);
    const [jobposting, setJobposting] = useState(false);
    const [resumesearch, setResumesearch] = useState(false);
    const [profilebooster, setproFilebooster] = useState(false);
    const [adsmanagement, setAdsmanagement] = useState(false);
    const [discountAndcoupons, setDiscountAndcoupons] = useState(false);

    const [cityads, setCityads] = useState(false);
    const [astrologyservices, setAstrologyservices] = useState(false);
    const [astrologyfaq, setAstrologyfaq] = useState(false);
    const [study, setStudy] = useState(false);
    const [skills, setSkills] = useState(false);
    const [designation, setDesignation] = useState(false);
    const [salary, setSalary] = useState(false);
    const [jobcategory, setJobcategory] = useState(false);
    const [jobtype, setJobtype] = useState(false);
    const [govtdepartment, setGovtdepartment] = useState(false);
    const [interviewquestion, setinterviewQuestion] = useState(false);
    const [practicequestion, setPracticequestion] = useState(false);
    const [blog, setBlog] = useState(false);
    const [article, setArticle] = useState(false);
    const [faq, setFaq] = useState(false);
    const [testimonial, setTestimonial] = useState(false);
    const [discussion, setDiscussion] = useState(false);
    const [country, setCountry] = useState(false);
    const [enquiry, setEnquiry] = useState(false);
    const [ReportIssue, setReportIssue] = useState(false);
    const [cmspage, setCmspage] = useState(false);
    const [downloads, setDownloads] = useState(false);
    const [pressrelease, setPressrelease] = useState(false);
    const [news, setNews] = useState(false);
    const [adminuser, setAdminuser] = useState(false);
    const [module, setModule] = useState(false);
    const [skilllearning, setSkillLearning] = useState(false);
    const [affiliateCo, setAffiliateCo] = useState(false)
    const [globalSettings, setGlobalSettings] = useState(false);
    const [subscription, SetSubscription] = useState(false);
    const [packageSubscription, SetpackageSubscription] = useState(false)
    const [banner, SetBanner] = useState(false)
    const [campusUniversity, setCampusUniversity] = useState(false)
    const [campusUniversityNewsAndAnnouncement, setCampusUniversityNewsAndAnnouncement] = useState(false)

    const [campusCollege, setCampusCollege] = useState(false)

    const [shareInterviewCount, setShareInterviewCount] = useState(0)
    const location = useLocation()
    const pathname = location.pathname;
    const { id } = useParams()

    const selectedSection = () => {

        if (pathname == constant.component.addemployers.url ||
            pathname == constant.component.employerslist.url ||
            pathname == constant.component.archiveEmployerList.url ||
            pathname == constant.component.featuredEmployerList.url ||
            pathname == constant.component.premiumEmployerList.url ||
            pathname == constant.component.addcompanytype.url ||
            pathname == constant.component.companytypelist.url ||
            pathname == constant.component.addindustry.url ||
            pathname == constant.component.industrylist.url ||
            pathname == constant.component.addemployeebenefits.url ||
            pathname == constant.component.employeebenefitslist.url ||
            pathname == constant.component.addentitytype.url ||
            pathname == constant.component.addentitytype.url ||
            pathname == constant.component.entitytypelist.url ||
            pathname == constant.component.addaddressprooftype.url ||
            pathname == constant.component.addressprooftypelist.url
        ) {
            setEmployer(true)
            setCvsearch(false)
            setHrms(false)
            setJobs(false)
            setPremiumjobs(false)
            setFeaturedjobs(false)
            setGovtjobs(false)
            setJobcandidate(false)
            setJobapplication(false)
            setCandidate(false)
            setCvbooster(false)
            setResumesample(false)
            setJobposting(false)
            setResumesearch(false)
            setproFilebooster(false)
            setAdsmanagement(false)
            setDiscountAndcoupons(false)

            setCityads(false)
            setAstrologyservices(false)
            setAstrologyfaq(false)
            setStudy(false)
            setSkills(false)
            setDesignation(false)
            setSalary(false)
            setJobcategory(false)
            setJobtype(false)
            setGovtdepartment(false)
            setGovtresult(false)
            setinterviewQuestion(false)
            setPracticequestion(false)
            setBlog(false)
            setArticle(false)
            setFaq(false)
            setTestimonial(false)
            setDiscussion(false)
            setCountry(false)
            setEnquiry(false)
            setCmspage(false)
            setDownloads(false)
            setPressrelease(false)
            setNews(false)
            setAdminuser(false)
            setModule(false)
        }
        else if (pathname == constant.component.cvsearch) {
            setEmployer(false)
            setCvsearch(true)
            setHrms(false)
            setJobs(false)
            setPremiumjobs(false)
            setFeaturedjobs(false)
            setGovtjobs(false)
            setJobcandidate(false)
            setJobapplication(false)
            setCandidate(false)
            setCvbooster(false)
            setResumesample(false)
            setJobposting(false)
            setResumesearch(false)
            setproFilebooster(false)
            setAdsmanagement(false)
            setDiscountAndcoupons(false)
            setCityads(false)
            setAstrologyservices(false)
            setAstrologyfaq(false)
            setStudy(false)
            setSkills(false)
            setDesignation(false)
            setSalary(false)
            setJobcategory(false)
            setJobtype(false)
            setGovtdepartment(false)
            setGovtresult(false)
            setinterviewQuestion(false)
            setPracticequestion(false)
            setBlog(false)
            setArticle(false)
            setFaq(false)
            setTestimonial(false)
            setDiscussion(false)
            setCountry(false)
            setEnquiry(false)
            setCmspage(false)
            setDownloads(false)
            setPressrelease(false)
            setNews(false)
            setAdminuser(false)
            setModule(false)

        }
        else if (pathname == constant.component.hrms) {
            setEmployer(false)
            setCvsearch(false)
            setHrms(true)
            setJobs(false)
            setPremiumjobs(false)
            setFeaturedjobs(false)
            setGovtjobs(false)
            setJobcandidate(false)
            setJobapplication(false)
            setCandidate(false)
            setCvbooster(false)
            setResumesample(false)
            setJobposting(false)
            setResumesearch(false)
            setproFilebooster(false)
            setAdsmanagement(false)
            setDiscountAndcoupons(false)
            setCityads(false)
            setAstrologyservices(false)
            setAstrologyfaq(false)
            setStudy(false)
            setSkills(false)
            setDesignation(false)
            setSalary(false)
            setJobcategory(false)
            setJobtype(false)
            setGovtdepartment(false)
            setGovtresult(false)
            setinterviewQuestion(false)
            setPracticequestion(false)
            setBlog(false)
            setArticle(false)
            setFaq(false)
            setTestimonial(false)
            setDiscussion(false)
            setCountry(false)
            setEnquiry(false)
            setCmspage(false)
            setDownloads(false)
            setPressrelease(false)
            setNews(false)
            setAdminuser(false)
            setModule(false)

        }
        else if (pathname == constant.component.addJobs.url ||
            pathname == constant.component.listJobs.url ||
            pathname == constant.component.addEmployementType.url ||
            pathname == constant.component.listEmployementType.url ||
            pathname == constant.component.addIndustry.url ||
            pathname == constant.component.listIndustry.url ||
            pathname == constant.component.addFunctionalArea.url ||
            pathname == constant.component.listFunctionalArea.url ||
            pathname == constant.component.addRole.url ||
            pathname == constant.component.listRole.url ||
            pathname == constant.component.addQualification.url ||
            pathname == constant.component.listQualification.url ||
            pathname == constant.component.addCourseSpecialization.url ||
            pathname == constant.component.listCourseSpecialization.url ||
            pathname == constant.component.addJobQuest.url ||
            pathname == constant.component.listJobQuest.url
        ) {
            setEmployer(false)
            setCvsearch(false)
            setHrms(false)
            setJobs(true)
            setPremiumjobs(false)
            setFeaturedjobs(false)
            setGovtjobs(false)
            setJobcandidate(false)
            setJobapplication(false)
            setCandidate(false)
            setCvbooster(false)
            setResumesample(false)
            setJobposting(false)
            setResumesearch(false)
            setproFilebooster(false)
            setAdsmanagement(false)

            setCityads(false)
            setAstrologyservices(false)
            setAstrologyfaq(false)
            setStudy(false)
            setSkills(false)
            setDesignation(false)
            setSalary(false)
            setJobcategory(false)
            setJobtype(false)
            setGovtdepartment(false)
            setGovtresult(false)
            setinterviewQuestion(false)
            setPracticequestion(false)
            setBlog(false)
            setArticle(false)
            setFaq(false)
            setTestimonial(false)
            setDiscussion(false)
            setCountry(false)
            setEnquiry(false)
            setCmspage(false)
            setDownloads(false)
            setPressrelease(false)
            setNews(false)
            setAdminuser(false)
            setModule(false)

        }
        else if (pathname == constant.component.premiumjobs) {
            setEmployer(false)
            setCvsearch(false)
            setHrms(false)
            setJobs(false)
            setPremiumjobs(true)
            setFeaturedjobs(false)
            setGovtjobs(false)
            setJobcandidate(false)
            setJobapplication(false)
            setCandidate(false)
            setCvbooster(false)
            setResumesample(false)
            setJobposting(false)
            setResumesearch(false)
            setproFilebooster(false)
            setAdsmanagement(false)
            setCityads(false)
            setAstrologyservices(false)
            setAstrologyfaq(false)
            setStudy(false)
            setSkills(false)
            setDesignation(false)
            setSalary(false)
            setJobcategory(false)
            setJobtype(false)
            setGovtdepartment(false)
            setGovtresult(false)
            setinterviewQuestion(false)
            setPracticequestion(false)
            setBlog(false)
            setArticle(false)
            setFaq(false)
            setTestimonial(false)
            setDiscussion(false)
            setCountry(false)
            setEnquiry(false)
            setCmspage(false)
            setDownloads(false)
            setPressrelease(false)
            setNews(false)
            setAdminuser(false)
            setModule(false)

        }
        else if (pathname == constant.component.featuredjobs) {
            setEmployer(false)
            setCvsearch(false)
            setHrms(false)
            setJobs(false)
            setPremiumjobs(false)
            setFeaturedjobs(true)
            setGovtjobs(false)
            setJobcandidate(false)
            setJobapplication(false)
            setCandidate(false)
            setCvbooster(false)
            setResumesample(false)
            setJobposting(false)
            setResumesearch(false)
            setproFilebooster(false)
            setAdsmanagement(false)
            setCityads(false)
            setAstrologyservices(false)
            setAstrologyfaq(false)
            setStudy(false)
            setSkills(false)
            setDesignation(false)
            setSalary(false)
            setJobcategory(false)
            setJobtype(false)
            setGovtdepartment(false)
            setGovtresult(false)
            setinterviewQuestion(false)
            setPracticequestion(false)
            setBlog(false)
            setArticle(false)
            setFaq(false)
            setTestimonial(false)
            setDiscussion(false)
            setCountry(false)
            setEnquiry(false)
            setCmspage(false)
            setDownloads(false)
            setPressrelease(false)
            setNews(false)
            setAdminuser(false)
            setModule(false)

        }
        else if (pathname == constant.component.govtjobs) {
            setEmployer(false)
            setCvsearch(false)
            setHrms(false)
            setJobs(false)
            setPremiumjobs(false)
            setFeaturedjobs(false)
            setGovtjobs(true)
            setJobcandidate(false)
            setJobapplication(false)
            setCandidate(false)
            setCvbooster(false)
            setResumesample(false)
            setJobposting(false)
            setResumesearch(false)
            setproFilebooster(false)
            setAdsmanagement(false)
            setCityads(false)
            setAstrologyservices(false)
            setAstrologyfaq(false)
            setStudy(false)
            setSkills(false)
            setDesignation(false)
            setSalary(false)
            setJobcategory(false)
            setJobtype(false)
            setGovtdepartment(false)
            setGovtresult(false)
            setinterviewQuestion(false)
            setPracticequestion(false)
            setBlog(false)
            setArticle(false)
            setFaq(false)
            setTestimonial(false)
            setDiscussion(false)
            setCountry(false)
            setEnquiry(false)
            setCmspage(false)
            setDownloads(false)
            setPressrelease(false)
            setNews(false)
            setAdminuser(false)
            setModule(false)

        }
        else if (pathname == constant.component.jobcandidate) {
            setEmployer(false)
            setCvsearch(false)
            setHrms(false)
            setJobs(false)
            setPremiumjobs(false)
            setFeaturedjobs(false)
            setGovtjobs(false)
            setJobcandidate(true)
            setJobapplication(false)
            setCandidate(false)
            setCvbooster(false)
            setResumesample(false)
            setJobposting(false)
            setResumesearch(false)
            setproFilebooster(false)
            setAdsmanagement(false)
            setCityads(false)
            setAstrologyservices(false)
            setAstrologyfaq(false)
            setStudy(false)
            setSkills(false)
            setDesignation(false)
            setSalary(false)
            setJobcategory(false)
            setJobtype(false)
            setGovtdepartment(false)
            setGovtresult(false)
            setinterviewQuestion(false)
            setPracticequestion(false)
            setBlog(false)
            setArticle(false)
            setFaq(false)
            setTestimonial(false)
            setDiscussion(false)
            setCountry(false)
            setEnquiry(false)
            setCmspage(false)
            setDownloads(false)
            setPressrelease(false)
            setNews(false)
            setAdminuser(false)
            setModule(false)

        }
        else if (pathname == constant.component.jobapplication) {
            setEmployer(false)
            setCvsearch(false)
            setHrms(false)
            setJobs(false)
            setPremiumjobs(false)
            setFeaturedjobs(false)
            setGovtjobs(false)
            setJobcandidate(false)
            setJobapplication(true)
            setCandidate(false)
            setCvbooster(false)
            setResumesample(false)
            setJobposting(false)
            setResumesearch(false)
            setproFilebooster(false)
            setAdsmanagement(false)
            setCityads(false)
            setAstrologyservices(false)
            setAstrologyfaq(false)
            setStudy(false)
            setSkills(false)
            setDesignation(false)
            setSalary(false)
            setJobcategory(false)
            setJobtype(false)
            setGovtdepartment(false)
            setGovtresult(false)
            setinterviewQuestion(false)
            setPracticequestion(false)
            setBlog(false)
            setArticle(false)
            setFaq(false)
            setTestimonial(false)
            setDiscussion(false)
            setCountry(false)
            setEnquiry(false)
            setCmspage(false)
            setDownloads(false)
            setPressrelease(false)
            setNews(false)
            setAdminuser(false)
            setModule(false)

        }
        else if (pathname == constant.component.candidateslist.url) {
            setEmployer(false)
            setCvsearch(false)
            setHrms(false)
            setJobs(false)
            setPremiumjobs(false)
            setFeaturedjobs(false)
            setGovtjobs(false)
            setJobcandidate(false)
            setJobapplication(false)
            setCandidate(true)
            setCvbooster(false)
            setResumesample(false)
            setJobposting(false)
            setResumesearch(false)
            setproFilebooster(false)
            setAdsmanagement(false)
            setCityads(false)
            setAstrologyservices(false)
            setAstrologyfaq(false)
            setStudy(false)
            setSkills(false)
            setDesignation(false)
            setSalary(false)
            setJobcategory(false)
            setJobtype(false)
            setGovtdepartment(false)
            setGovtresult(false)
            setinterviewQuestion(false)
            setPracticequestion(false)
            setBlog(false)
            setArticle(false)
            setFaq(false)
            setTestimonial(false)
            setDiscussion(false)
            setCountry(false)
            setEnquiry(false)
            setCmspage(false)
            setDownloads(false)
            setPressrelease(false)
            setNews(false)
            setAdminuser(false)
            setModule(false)

        }
        else if (pathname == constant.component.cvbooster) {
            setEmployer(false)
            setCvsearch(false)
            setHrms(false)
            setJobs(false)
            setPremiumjobs(false)
            setFeaturedjobs(false)
            setGovtjobs(false)
            setJobcandidate(false)
            setJobapplication(false)
            setCandidate(false)
            setCvbooster(true)
            setResumesample(false)
            setJobposting(false)
            setResumesearch(false)
            setproFilebooster(false)
            setAdsmanagement(false)
            setCityads(false)
            setAstrologyservices(false)
            setAstrologyfaq(false)
            setStudy(false)
            setSkills(false)
            setDesignation(false)
            setSalary(false)
            setJobcategory(false)
            setJobtype(false)
            setGovtdepartment(false)
            setGovtresult(false)
            setinterviewQuestion(false)
            setPracticequestion(false)
            setBlog(false)
            setArticle(false)
            setFaq(false)
            setTestimonial(false)
            setDiscussion(false)
            setCountry(false)
            setEnquiry(false)
            setCmspage(false)
            setDownloads(false)
            setPressrelease(false)
            setNews(false)
            setAdminuser(false)
            setModule(false)

        }
        else if (pathname == constant.component.resumesample) {
            setEmployer(false)
            setCvsearch(false)
            setHrms(false)
            setJobs(false)
            setPremiumjobs(false)
            setFeaturedjobs(false)
            setGovtjobs(false)
            setJobcandidate(false)
            setJobapplication(false)
            setCandidate(false)
            setCvbooster(false)
            setResumesample(true)
            setJobposting(false)
            setResumesearch(false)
            setproFilebooster(false)
            setAdsmanagement(false)
            setCityads(false)
            setAstrologyservices(false)
            setAstrologyfaq(false)
            setStudy(false)
            setSkills(false)
            setDesignation(false)
            setSalary(false)
            setJobcategory(false)
            setJobtype(false)
            setGovtdepartment(false)
            setGovtresult(false)
            setinterviewQuestion(false)
            setPracticequestion(false)
            setBlog(false)
            setArticle(false)
            setFaq(false)
            setTestimonial(false)
            setDiscussion(false)
            setCountry(false)
            setEnquiry(false)
            setCmspage(false)
            setDownloads(false)
            setPressrelease(false)
            setNews(false)
            setAdminuser(false)
            setModule(false)

        }
        else if (pathname == constant.component.jobposting) {
            setEmployer(false)
            setCvsearch(false)
            setHrms(false)
            setJobs(false)
            setPremiumjobs(false)
            setFeaturedjobs(false)
            setGovtjobs(false)
            setJobcandidate(false)
            setJobapplication(false)
            setCandidate(false)
            setCvbooster(false)
            setResumesample(false)
            setJobposting(true)
            setResumesearch(false)
            setproFilebooster(false)
            setAdsmanagement(false)
            setCityads(false)
            setAstrologyservices(false)
            setAstrologyfaq(false)
            setStudy(false)
            setSkills(false)
            setDesignation(false)
            setSalary(false)
            setJobcategory(false)
            setJobtype(false)
            setGovtdepartment(false)
            setGovtresult(false)
            setinterviewQuestion(false)
            setPracticequestion(false)
            setBlog(false)
            setArticle(false)
            setFaq(false)
            setTestimonial(false)
            setDiscussion(false)
            setCountry(false)
            setEnquiry(false)
            setCmspage(false)
            setDownloads(false)
            setPressrelease(false)
            setNews(false)
            setAdminuser(false)
            setModule(false)

        }
        else if (pathname == constant.component.resumesearch) {
            setEmployer(false)
            setCvsearch(false)
            setHrms(false)
            setJobs(false)
            setPremiumjobs(false)
            setFeaturedjobs(false)
            setGovtjobs(false)
            setJobcandidate(false)
            setJobapplication(false)
            setCandidate(false)
            setCvbooster(false)
            setResumesample(false)
            setJobposting(false)
            setResumesearch(true)
            setproFilebooster(false)
            setAdsmanagement(false)
            setCityads(false)
            setAstrologyservices(false)
            setAstrologyfaq(false)
            setStudy(false)
            setSkills(false)
            setDesignation(false)
            setSalary(false)
            setJobcategory(false)
            setJobtype(false)
            setGovtdepartment(false)
            setGovtresult(false)
            setinterviewQuestion(false)
            setPracticequestion(false)
            setBlog(false)
            setArticle(false)
            setFaq(false)
            setTestimonial(false)
            setDiscussion(false)
            setCountry(false)
            setEnquiry(false)
            setCmspage(false)
            setDownloads(false)
            setPressrelease(false)
            setNews(false)
            setAdminuser(false)
            setModule(false)

        }
        else if (pathname == constant.component.profilebooster) {
            setEmployer(false)
            setCvsearch(false)
            setHrms(false)
            setJobs(false)
            setPremiumjobs(false)
            setFeaturedjobs(false)
            setGovtjobs(false)
            setJobcandidate(false)
            setJobapplication(false)
            setCandidate(false)
            setCvbooster(false)
            setResumesample(false)
            setJobposting(false)
            setResumesearch(false)
            setproFilebooster(true)
            setAdsmanagement(false)
            setCityads(false)
            setAstrologyservices(false)
            setAstrologyfaq(false)
            setStudy(false)
            setSkills(false)
            setDesignation(false)
            setSalary(false)
            setJobcategory(false)
            setJobtype(false)
            setGovtdepartment(false)
            setGovtresult(false)
            setinterviewQuestion(false)
            setPracticequestion(false)
            setBlog(false)
            setArticle(false)
            setFaq(false)
            setTestimonial(false)
            setDiscussion(false)
            setCountry(false)
            setEnquiry(false)
            setCmspage(false)
            setDownloads(false)
            setPressrelease(false)
            setNews(false)
            setAdminuser(false)
            setModule(false)

        }
        else if (pathname == constant.component.adsmanagement) {
            setEmployer(false)
            setCvsearch(false)
            setHrms(false)
            setJobs(false)
            setPremiumjobs(false)
            setFeaturedjobs(false)
            setGovtjobs(false)
            setJobcandidate(false)
            setJobapplication(false)
            setCandidate(false)
            setCvbooster(false)
            setResumesample(false)
            setJobposting(false)
            setResumesearch(false)
            setproFilebooster(false)
            setAdsmanagement(true)
            setCityads(false)
            setAstrologyservices(false)
            setAstrologyfaq(false)
            setStudy(false)
            setSkills(false)
            setDesignation(false)
            setSalary(false)
            setJobcategory(false)
            setJobtype(false)
            setGovtdepartment(false)
            setGovtresult(false)
            setinterviewQuestion(false)
            setPracticequestion(false)
            setBlog(false)
            setArticle(false)
            setFaq(false)
            setTestimonial(false)
            setDiscussion(false)
            setCountry(false)
            setEnquiry(false)
            setCmspage(false)
            setDownloads(false)
            setPressrelease(false)
            setNews(false)
            setAdminuser(false)
            setModule(false)

        }
        else if (pathname == constant.component.cityads) {
            setEmployer(false)
            setCvsearch(false)
            setHrms(false)
            setJobs(false)
            setPremiumjobs(false)
            setFeaturedjobs(false)
            setGovtjobs(false)
            setJobcandidate(false)
            setJobapplication(false)
            setCandidate(false)
            setCvbooster(false)
            setResumesample(false)
            setJobposting(false)
            setResumesearch(false)
            setproFilebooster(false)
            setAdsmanagement(false)
            setCityads(true)
            setAstrologyservices(false)
            setAstrologyfaq(false)
            setStudy(false)
            setSkills(false)
            setDesignation(false)
            setSalary(false)
            setJobcategory(false)
            setJobtype(false)
            setGovtdepartment(false)
            setGovtresult(false)
            setinterviewQuestion(false)
            setPracticequestion(false)
            setBlog(false)
            setArticle(false)
            setFaq(false)
            setTestimonial(false)
            setDiscussion(false)
            setCountry(false)
            setEnquiry(false)
            setCmspage(false)
            setDownloads(false)
            setPressrelease(false)
            setNews(false)
            setAdminuser(false)
            setModule(false)

        }
        else if (pathname == constant.component.astrologyservices) {
            setEmployer(false)
            setCvsearch(false)
            setHrms(false)
            setJobs(false)
            setPremiumjobs(false)
            setFeaturedjobs(false)
            setGovtjobs(false)
            setJobcandidate(false)
            setJobapplication(false)
            setCandidate(false)
            setCvbooster(false)
            setResumesample(false)
            setJobposting(false)
            setResumesearch(false)
            setproFilebooster(false)
            setAdsmanagement(false)
            setCityads(false)
            setAstrologyservices(true)
            setAstrologyfaq(false)
            setStudy(false)
            setSkills(false)
            setDesignation(false)
            setSalary(false)
            setJobcategory(false)
            setJobtype(false)
            setGovtdepartment(false)
            setGovtresult(false)
            setinterviewQuestion(false)
            setPracticequestion(false)
            setBlog(false)
            setArticle(false)
            setFaq(false)
            setTestimonial(false)
            setDiscussion(false)
            setCountry(false)
            setEnquiry(false)
            setCmspage(false)
            setDownloads(false)
            setPressrelease(false)
            setNews(false)
            setAdminuser(false)
            setModule(false)

        }
        else if (pathname == constant.component.astrologyfaq) {
            setEmployer(false)
            setCvsearch(false)
            setHrms(false)
            setJobs(false)
            setPremiumjobs(false)
            setFeaturedjobs(false)
            setGovtjobs(false)
            setJobcandidate(false)
            setJobapplication(false)
            setCandidate(false)
            setCvbooster(false)
            setResumesample(false)
            setJobposting(false)
            setResumesearch(false)
            setproFilebooster(false)
            setAdsmanagement(false)
            setCityads(false)
            setAstrologyservices(false)
            setAstrologyfaq(true)
            setStudy(false)
            setSkills(false)
            setDesignation(false)
            setSalary(false)
            setJobcategory(false)
            setJobtype(false)
            setGovtdepartment(false)
            setGovtresult(false)
            setinterviewQuestion(false)
            setPracticequestion(false)
            setBlog(false)
            setArticle(false)
            setFaq(false)
            setTestimonial(false)
            setDiscussion(false)
            setCountry(false)
            setEnquiry(false)
            setCmspage(false)
            setDownloads(false)
            setPressrelease(false)
            setNews(false)
            setAdminuser(false)
            setModule(false)

        }
        else if (pathname == constant.component.study) {
            setEmployer(false)
            setCvsearch(false)
            setHrms(false)
            setJobs(false)
            setPremiumjobs(false)
            setFeaturedjobs(false)
            setGovtjobs(false)
            setJobcandidate(false)
            setJobapplication(false)
            setCandidate(false)
            setCvbooster(false)
            setResumesample(false)
            setJobposting(false)
            setResumesearch(false)
            setproFilebooster(false)
            setAdsmanagement(false)
            setCityads(false)
            setAstrologyservices(false)
            setAstrologyfaq(false)
            setStudy(true)
            setSkills(false)
            setDesignation(false)
            setSalary(false)
            setJobcategory(false)
            setJobtype(false)
            setGovtdepartment(false)
            setGovtresult(false)
            setinterviewQuestion(false)
            setPracticequestion(false)
            setBlog(false)
            setArticle(false)
            setFaq(false)
            setTestimonial(false)
            setDiscussion(false)
            setCountry(false)
            setEnquiry(false)
            setCmspage(false)
            setDownloads(false)
            setPressrelease(false)
            setNews(false)
            setAdminuser(false)
            setModule(false)

        }
        else if (pathname == constant.component.addskills.url || pathname == constant.component.skilllist.url) {
            setEmployer(false)
            setCvsearch(false)
            setHrms(false)
            setJobs(false)
            setPremiumjobs(false)
            setFeaturedjobs(false)
            setGovtjobs(false)
            setJobcandidate(false)
            setJobapplication(false)
            setCandidate(false)
            setCvbooster(false)
            setResumesample(false)
            setJobposting(false)
            setResumesearch(false)
            setproFilebooster(false)
            setAdsmanagement(false)
            setCityads(false)
            setAstrologyservices(false)
            setAstrologyfaq(false)
            setStudy(false)
            setSkills(true)
            setDesignation(false)
            setSalary(false)
            setJobcategory(false)
            setJobtype(false)
            setGovtdepartment(false)
            setGovtresult(false)
            setinterviewQuestion(false)
            setPracticequestion(false)
            setBlog(false)
            setArticle(false)
            setFaq(false)
            setTestimonial(false)
            setDiscussion(false)
            setCountry(false)
            setEnquiry(false)
            setCmspage(false)
            setDownloads(false)
            setPressrelease(false)
            setNews(false)
            setAdminuser(false)
            setModule(false)

        }
        else if (pathname == constant.component.adddesignation.url || pathname == constant.component.designationlist.url) {
            setEmployer(false)
            setCvsearch(false)
            setHrms(false)
            setJobs(false)
            setPremiumjobs(false)
            setFeaturedjobs(false)
            setGovtjobs(false)
            setJobcandidate(false)
            setJobapplication(false)
            setCandidate(false)
            setCvbooster(false)
            setResumesample(false)
            setJobposting(false)
            setResumesearch(false)
            setproFilebooster(false)
            setAdsmanagement(false)
            setCityads(false)
            setAstrologyservices(false)
            setAstrologyfaq(false)
            setStudy(false)
            setSkills(false)
            setDesignation(true)
            setSalary(false)
            setJobcategory(false)
            setJobtype(false)
            setGovtdepartment(false)
            setGovtresult(false)
            setinterviewQuestion(false)
            setPracticequestion(false)
            setBlog(false)
            setArticle(false)
            setFaq(false)
            setTestimonial(false)
            setDiscussion(false)
            setCountry(false)
            setEnquiry(false)
            setCmspage(false)
            setDownloads(false)
            setPressrelease(false)
            setNews(false)
            setAdminuser(false)
            setModule(false)

        }
        else if (pathname == constant.component.addsalary.url || pathname == constant.component.salarylist.url) {
            setEmployer(false)
            setCvsearch(false)
            setHrms(false)
            setJobs(false)
            setPremiumjobs(false)
            setFeaturedjobs(false)
            setGovtjobs(false)
            setJobcandidate(false)
            setJobapplication(false)
            setCandidate(false)
            setCvbooster(false)
            setResumesample(false)
            setJobposting(false)
            setResumesearch(false)
            setproFilebooster(false)
            setAdsmanagement(false)
            setCityads(false)
            setAstrologyservices(false)
            setAstrologyfaq(false)
            setStudy(false)
            setSkills(false)
            setDesignation(false)
            setSalary(true)
            setJobcategory(false)
            setJobtype(false)
            setGovtdepartment(false)
            setGovtresult(false)
            setinterviewQuestion(false)
            setPracticequestion(false)
            setBlog(false)
            setArticle(false)
            setFaq(false)
            setTestimonial(false)
            setDiscussion(false)
            setCountry(false)
            setEnquiry(false)
            setCmspage(false)
            setDownloads(false)
            setPressrelease(false)
            setNews(false)
            setAdminuser(false)
            setModule(false)

        }
        else if (pathname == constant.component.addjobcategory.url || pathname == constant.component.jobcategorylist.url) {
            setEmployer(false)
            setCvsearch(false)
            setHrms(false)
            setJobs(false)
            setPremiumjobs(false)
            setFeaturedjobs(false)
            setGovtjobs(false)
            setJobcandidate(false)
            setJobapplication(false)
            setCandidate(false)
            setCvbooster(false)
            setResumesample(false)
            setJobposting(false)
            setResumesearch(false)
            setproFilebooster(false)
            setAdsmanagement(false)
            setCityads(false)
            setAstrologyservices(false)
            setAstrologyfaq(false)
            setStudy(false)
            setSkills(false)
            setDesignation(false)
            setSalary(false)
            setJobcategory(true)
            setJobtype(false)
            setGovtdepartment(false)
            setGovtresult(false)
            setinterviewQuestion(false)
            setPracticequestion(false)
            setBlog(false)
            setArticle(false)
            setFaq(false)
            setTestimonial(false)
            setDiscussion(false)
            setCountry(false)
            setEnquiry(false)
            setCmspage(false)
            setDownloads(false)
            setPressrelease(false)
            setNews(false)
            setAdminuser(false)
            setModule(false)

        }
        else if (pathname == constant.component.addjobtype.url || pathname == constant.component.jobtypelist.url) {
            setEmployer(false)
            setCvsearch(false)
            setHrms(false)
            setJobs(false)
            setPremiumjobs(false)
            setFeaturedjobs(false)
            setGovtjobs(false)
            setJobcandidate(false)
            setJobapplication(false)
            setCandidate(false)
            setCvbooster(false)
            setResumesample(false)
            setJobposting(false)
            setResumesearch(false)
            setproFilebooster(false)
            setAdsmanagement(false)
            setCityads(false)
            setAstrologyservices(false)
            setAstrologyfaq(false)
            setStudy(false)
            setSkills(false)
            setDesignation(false)
            setSalary(false)
            setJobcategory(false)
            setJobtype(true)
            setGovtdepartment(false)
            setGovtresult(false)
            setinterviewQuestion(false)
            setPracticequestion(false)
            setBlog(false)
            setArticle(false)
            setFaq(false)
            setTestimonial(false)
            setDiscussion(false)
            setCountry(false)
            setEnquiry(false)
            setCmspage(false)
            setDownloads(false)
            setPressrelease(false)
            setNews(false)
            setAdminuser(false)
            setModule(false)

        }
        else if (pathname == constant.component.addgovtdepartment.url || pathname == constant.component.govtdepartmentlist.url) {
            setEmployer(false)
            setCvsearch(false)
            setHrms(false)
            setJobs(false)
            setPremiumjobs(false)
            setFeaturedjobs(false)
            setGovtjobs(false)
            setJobcandidate(false)
            setJobapplication(false)
            setCandidate(false)
            setCvbooster(false)
            setResumesample(false)
            setJobposting(false)
            setResumesearch(false)
            setproFilebooster(false)
            setAdsmanagement(false)
            setCityads(false)
            setAstrologyservices(false)
            setAstrologyfaq(false)
            setStudy(false)
            setSkills(false)
            setDesignation(false)
            setSalary(false)
            setJobcategory(false)
            setJobtype(false)
            setGovtdepartment(true)
            setinterviewQuestion(false)
            setPracticequestion(false)
            setBlog(false)
            setArticle(false)
            setFaq(false)
            setTestimonial(false)
            setDiscussion(false)
            setCountry(false)
            setEnquiry(false)
            setCmspage(false)
            setDownloads(false)
            setPressrelease(false)
            setNews(false)
            setAdminuser(false)
            setModule(false)

        }
        else if (pathname == constant.component.addInterviewQuestion.url || pathname == constant.component.interviewQuestionList.url) {
            setEmployer(false)
            setCvsearch(false)
            setHrms(false)
            setJobs(false)
            setPremiumjobs(false)
            setFeaturedjobs(false)
            setGovtjobs(false)
            setJobcandidate(false)
            setJobapplication(false)
            setCandidate(false)
            setCvbooster(false)
            setResumesample(false)
            setJobposting(false)
            setResumesearch(false)
            setproFilebooster(false)
            setAdsmanagement(false)
            setCityads(false)
            setAstrologyservices(false)
            setAstrologyfaq(false)
            setStudy(false)
            setSkills(false)
            setDesignation(false)
            setSalary(false)
            setJobcategory(false)
            setJobtype(false)
            setGovtdepartment(false)
            setGovtresult(false)
            setinterviewQuestion(true)
            setPracticequestion(false)
            setBlog(false)
            setArticle(false)
            setFaq(false)
            setTestimonial(false)
            setDiscussion(false)
            setCountry(false)
            setEnquiry(false)
            setCmspage(false)
            setDownloads(false)
            setPressrelease(false)
            setNews(false)
            setAdminuser(false)
            setModule(false)

        }
        else if (pathname == constant.component.addPracticeQuestion.url || pathname == constant.component.practiceQuestionList.url) {
            setEmployer(false)
            setCvsearch(false)
            setHrms(false)
            setJobs(false)
            setPremiumjobs(false)
            setFeaturedjobs(false)
            setGovtjobs(false)
            setJobcandidate(false)
            setJobapplication(false)
            setCandidate(false)
            setCvbooster(false)
            setResumesample(false)
            setJobposting(false)
            setResumesearch(false)
            setproFilebooster(false)
            setAdsmanagement(false)
            setCityads(false)
            setAstrologyservices(false)
            setAstrologyfaq(false)
            setStudy(false)
            setSkills(false)
            setDesignation(false)
            setSalary(false)
            setJobcategory(false)
            setJobtype(false)
            setGovtdepartment(false)
            setGovtresult(false)
            setinterviewQuestion(false)
            setPracticequestion(true)
            setBlog(false)
            setArticle(false)
            setFaq(false)
            setTestimonial(false)
            setDiscussion(false)
            setCountry(false)
            setEnquiry(false)
            setCmspage(false)
            setDownloads(false)
            setPressrelease(false)
            setNews(false)
            setAdminuser(false)
            setModule(false)

        }
        else if (pathname == constant.component.addblogcategory.url || pathname == constant.component.blogcategorylist.url || pathname == constant.component.addblog.url || pathname == constant.component.bloglist.url || pathname == constant.component.allCommentList.url) {
            setEmployer(false)
            setCvsearch(false)
            setHrms(false)
            setJobs(false)
            setPremiumjobs(false)
            setFeaturedjobs(false)
            setGovtjobs(false)
            setJobcandidate(false)
            setJobapplication(false)
            setCandidate(false)
            setCvbooster(false)
            setResumesample(false)
            setJobposting(false)
            setResumesearch(false)
            setproFilebooster(false)
            setAdsmanagement(false)
            setCityads(false)
            setAstrologyservices(false)
            setAstrologyfaq(false)
            setStudy(false)
            setSkills(false)
            setDesignation(false)
            setSalary(false)
            setJobcategory(false)
            setJobtype(false)
            setGovtdepartment(false)
            setGovtresult(false)
            setinterviewQuestion(false)
            setPracticequestion(false)
            setBlog(true)
            setArticle(false)
            setFaq(false)
            setTestimonial(false)
            setDiscussion(false)
            setCountry(false)
            setEnquiry(false)
            setCmspage(false)
            setDownloads(false)
            setPressrelease(false)
            setNews(false)
            setAdminuser(false)
            setModule(false)
        }
        else if (pathname == constant.component.addArticle.url || pathname == constant.component.listArticle.url || pathname == constant.component.articleAddCategory.url || pathname == constant.component.articleCategoryList.url) {
            setEmployer(false)
            setCvsearch(false)
            setHrms(false)
            setJobs(false)
            setPremiumjobs(false)
            setFeaturedjobs(false)
            setGovtjobs(false)
            setJobcandidate(false)
            setJobapplication(false)
            setCandidate(false)
            setCvbooster(false)
            setResumesample(false)
            setJobposting(false)
            setResumesearch(false)
            setproFilebooster(false)
            setAdsmanagement(false)
            setCityads(false)
            setAstrologyservices(false)
            setAstrologyfaq(false)
            setStudy(false)
            setSkills(false)
            setDesignation(false)
            setSalary(false)
            setJobcategory(false)
            setJobtype(false)
            setGovtdepartment(false)
            setGovtresult(false)
            setinterviewQuestion(false)
            setPracticequestion(false)
            setBlog(false)
            setArticle(true)
            setFaq(false)
            setTestimonial(false)
            setDiscussion(false)
            setCountry(false)
            setEnquiry(false)
            setCmspage(false)
            setDownloads(false)
            setPressrelease(false)
            setNews(false)
            setAdminuser(false)
            setModule(false)
        }
        else if (pathname == constant.component.faqaddcategory.url || pathname == constant.component.faqlistcategory.url || pathname == constant.component.faqadd.url || pathname == constant.component.faqlist) {
            setEmployer(false)
            setCvsearch(false)
            setHrms(false)
            setJobs(false)
            setPremiumjobs(false)
            setFeaturedjobs(false)
            setGovtjobs(false)
            setJobcandidate(false)
            setJobapplication(false)
            setCandidate(false)
            setCvbooster(false)
            setResumesample(false)
            setJobposting(false)
            setResumesearch(false)
            setproFilebooster(false)
            setAdsmanagement(false)
            setCityads(false)
            setAstrologyservices(false)
            setAstrologyfaq(false)
            setStudy(false)
            setSkills(false)
            setDesignation(false)
            setSalary(false)
            setJobcategory(false)
            setJobtype(false)
            setGovtdepartment(false)
            setGovtresult(false)
            setinterviewQuestion(false)
            setPracticequestion(false)
            setBlog(false)
            setArticle(false)
            setFaq(true)
            setTestimonial(false)
            setDiscussion(false)
            setCountry(false)
            setEnquiry(false)
            setCmspage(false)
            setDownloads(false)
            setPressrelease(false)
            setNews(false)
            setAdminuser(false)
            setModule(false)
        }
        else if (pathname == constant.component.addTestimonial.url || pathname == constant.component.testimonialList.url) {
            setEmployer(false)
            setCvsearch(false)
            setHrms(false)
            setJobs(false)
            setPremiumjobs(false)
            setFeaturedjobs(false)
            setGovtjobs(false)
            setJobcandidate(false)
            setJobapplication(false)
            setCandidate(false)
            setCvbooster(false)
            setResumesample(false)
            setJobposting(false)
            setResumesearch(false)
            setproFilebooster(false)
            setAdsmanagement(false)
            setCityads(false)
            setAstrologyservices(false)
            setAstrologyfaq(false)
            setStudy(false)
            setSkills(false)
            setDesignation(false)
            setSalary(false)
            setJobcategory(false)
            setJobtype(false)
            setGovtdepartment(false)
            setGovtresult(false)
            setinterviewQuestion(false)
            setPracticequestion(false)
            setBlog(false)
            setArticle(false)
            setFaq(false)
            setTestimonial(true)
            setDiscussion(false)
            setCountry(false)
            setEnquiry(false)
            setCmspage(false)
            setDownloads(false)
            setPressrelease(false)
            setNews(false)
            setAdminuser(false)
            setModule(false)
        }
        else if (pathname == constant.component.Discussionforum.url || pathname == constant.component.DiscussionforumList.url) {
            setEmployer(false)
            setCvsearch(false)
            setHrms(false)
            setJobs(false)
            setPremiumjobs(false)
            setFeaturedjobs(false)
            setGovtjobs(false)
            setJobcandidate(false)
            setJobapplication(false)
            setCandidate(false)
            setCvbooster(false)
            setResumesample(false)
            setJobposting(false)
            setResumesearch(false)
            setproFilebooster(false)
            setAdsmanagement(false)
            setCityads(false)
            setAstrologyservices(false)
            setAstrologyfaq(false)
            setStudy(false)
            setSkills(false)
            setDesignation(false)
            setSalary(false)
            setJobcategory(false)
            setJobtype(false)
            setGovtdepartment(false)
            setGovtresult(false)
            setinterviewQuestion(false)
            setPracticequestion(false)
            setBlog(false)
            setArticle(false)
            setFaq(false)
            setTestimonial(false)
            setDiscussion(true)
            setCountry(false)
            setEnquiry(false)
            setCmspage(false)
            setDownloads(false)
            setPressrelease(false)
            setNews(false)
            setAdminuser(false)
            setModule(false)
        }
        else if (pathname == constant.component.calender) {
            setEmployer(false)
            setCvsearch(false)
            setHrms(false)
            setJobs(false)
            setPremiumjobs(false)
            setFeaturedjobs(false)
            setGovtjobs(false)
            setJobcandidate(false)
            setJobapplication(false)
            setCandidate(false)
            setCvbooster(false)
            setResumesample(false)
            setJobposting(false)
            setResumesearch(false)
            setproFilebooster(false)
            setAdsmanagement(false)
            setCityads(false)
            setAstrologyservices(false)
            setAstrologyfaq(false)
            setStudy(false)
            setSkills(false)
            setDesignation(false)
            setSalary(false)
            setJobcategory(false)
            setJobtype(false)
            setGovtdepartment(false)
            setGovtresult(false)
            setinterviewQuestion(false)
            setPracticequestion(false)
            setBlog(false)
            setArticle(false)
            setFaq(false)
            setTestimonial(false)
            setDiscussion(false)
            setCountry(false)
            setEnquiry(false)
            setCmspage(false)
            setDownloads(false)
            setPressrelease(false)
            setNews(false)
            setAdminuser(false)
            setModule(false)
        }
        else if (pathname == constant.component.stateList.url || pathname == constant.component.localityList.url || pathname == constant.component.countryList.url || pathname == constant.component.premiumCityList.url) {
            setEmployer(false)
            setCvsearch(false)
            setHrms(false)
            setJobs(false)
            setPremiumjobs(false)
            setFeaturedjobs(false)
            setGovtjobs(false)
            setJobcandidate(false)
            setJobapplication(false)
            setCandidate(false)
            setCvbooster(false)
            setResumesample(false)
            setJobposting(false)
            setResumesearch(false)
            setproFilebooster(false)
            setAdsmanagement(false)
            setCityads(false)
            setAstrologyservices(false)
            setAstrologyfaq(false)
            setStudy(false)
            setSkills(false)
            setDesignation(false)
            setSalary(false)
            setJobcategory(false)
            setJobtype(false)
            setGovtdepartment(false)
            setGovtresult(false)
            setinterviewQuestion(false)
            setPracticequestion(false)
            setBlog(false)
            setArticle(false)
            setFaq(false)
            setTestimonial(false)
            setDiscussion(false)
            setCountry(true)
            setEnquiry(false)
            setCmspage(false)
            setDownloads(false)
            setPressrelease(false)
            setNews(false)
            setAdminuser(false)
            setModule(false)
        }
        else if (pathname == constant.component.enquiryList.url) {
            setEmployer(false)
            setCvsearch(false)
            setHrms(false)
            setJobs(false)
            setPremiumjobs(false)
            setFeaturedjobs(false)
            setGovtjobs(false)
            setJobcandidate(false)
            setJobapplication(false)
            setCandidate(false)
            setCvbooster(false)
            setResumesample(false)
            setJobposting(false)
            setResumesearch(false)
            setproFilebooster(false)
            setAdsmanagement(false)
            setCityads(false)
            setAstrologyservices(false)
            setAstrologyfaq(false)
            setStudy(false)
            setSkills(false)
            setDesignation(false)
            setSalary(false)
            setJobcategory(false)
            setJobtype(false)
            setGovtdepartment(false)
            setGovtresult(false)
            setinterviewQuestion(false)
            setPracticequestion(false)
            setBlog(false)
            setArticle(false)
            setFaq(false)
            setTestimonial(false)
            setDiscussion(false)
            setCountry(false)
            setEnquiry(true)
            setCmspage(false)
            setDownloads(false)
            setPressrelease(false)
            setNews(false)
            setAdminuser(false)
            setModule(false)
        }
        // else if (pathname == constant.component.ComplaintList.url) {
        //     setEmployer(false)
        //     setCvsearch(false)
        //     setHrms(false)
        //     setJobs(false)
        //     setPremiumjobs(false)
        //     setFeaturedjobs(false)
        //     setGovtjobs(false)
        //     setJobcandidate(false)
        //     setJobapplication(false)
        //     setCandidate(false)
        //     setCvbooster(false)
        //     setResumesample(false)
        //     setJobposting(false)
        //     setResumesearch(false)
        //     setproFilebooster(false)
        //     setAdsmanagement(false)
        //     setCityads(false)
        //     setAstrologyservices(false)
        //     setAstrologyfaq(false)
        //     setStudy(false)
        //     setSkills(false)
        //     setDesignation(false)
        //     setSalary(false)
        //     setJobcategory(false)
        //     setJobtype(false)
        //     setGovtdepartment(false)
        //     setinterviewQuestion(false)
        //     setPracticequestion(false)
        //     setBlog(false)
        //     setArticle(false)
        //     setFaq(false)
        //     setTestimonial(false)
        //     setDiscussion(false)
        //     setCountry(false)
        //     setEnquiry(false)
        //     setCmspage(false)
        //     setDownloads(false)
        //     setPressrelease(false)
        //     setNews(false)
        //     setAdminuser(false)
        //     setModule(false)
        //     setComplaint(true)
        // }
        else if (pathname == constant.component.addCmsPage.url || pathname == constant.component.cmsPageList.url) {
            setEmployer(false)
            setCvsearch(false)
            setHrms(false)
            setJobs(false)
            setPremiumjobs(false)
            setFeaturedjobs(false)
            setGovtjobs(false)
            setJobcandidate(false)
            setJobapplication(false)
            setCandidate(false)
            setCvbooster(false)
            setResumesample(false)
            setJobposting(false)
            setResumesearch(false)
            setproFilebooster(false)
            setAdsmanagement(false)
            setCityads(false)
            setAstrologyservices(false)
            setAstrologyfaq(false)
            setStudy(false)
            setSkills(false)
            setDesignation(false)
            setSalary(false)
            setJobcategory(false)
            setJobtype(false)
            setGovtdepartment(false)
            setGovtresult(false)
            setinterviewQuestion(false)
            setPracticequestion(false)
            setBlog(false)
            setArticle(false)
            setFaq(false)
            setTestimonial(false)
            setDiscussion(false)
            setCountry(false)
            setEnquiry(false)
            setCmspage(true)
            setDownloads(false)
            setPressrelease(false)
            setNews(false)
            setAdminuser(false)
            setModule(false)
        }
        else if (pathname == constant.component.addDownloads.url || pathname == constant.component.downloadList.url || pathname == constant.component.adddownloadcategory.url || pathname == constant.component.downloadcategorylist.url || pathname == constant.component.addsubcategory.url || pathname == constant.component.addsubcategorylist.url) {
            setEmployer(false)
            setCvsearch(false)
            setHrms(false)
            setJobs(false)
            setPremiumjobs(false)
            setFeaturedjobs(false)
            setGovtjobs(false)
            setJobcandidate(false)
            setJobapplication(false)
            setCandidate(false)
            setCvbooster(false)
            setResumesample(false)
            setJobposting(false)
            setResumesearch(false)
            setproFilebooster(false)
            setAdsmanagement(false)
            setCityads(false)
            setAstrologyservices(false)
            setAstrologyfaq(false)
            setStudy(false)
            setSkills(false)
            setDesignation(false)
            setSalary(false)
            setJobcategory(false)
            setJobtype(false)
            setGovtdepartment(false)
            setGovtresult(false)
            setinterviewQuestion(false)
            setPracticequestion(false)
            setBlog(false)
            setArticle(false)
            setFaq(false)
            setTestimonial(false)
            setDiscussion(false)
            setCountry(false)
            setEnquiry(false)
            setCmspage(false)
            setDownloads(true)
            setPressrelease(false)
            setNews(false)
            setAdminuser(false)
            setModule(false)
        }
        else if (pathname == constant.component.addPressRelease.url || pathname == constant.component.pressReleaseList.url) {
            setEmployer(false)
            setCvsearch(false)
            setHrms(false)
            setJobs(false)
            setPremiumjobs(false)
            setFeaturedjobs(false)
            setGovtjobs(false)
            setJobcandidate(false)
            setJobapplication(false)
            setCandidate(false)
            setCvbooster(false)
            setResumesample(false)
            setJobposting(false)
            setResumesearch(false)
            setproFilebooster(false)
            setAdsmanagement(false)
            setCityads(false)
            setAstrologyservices(false)
            setAstrologyfaq(false)
            setStudy(false)
            setSkills(false)
            setDesignation(false)
            setSalary(false)
            setJobcategory(false)
            setJobtype(false)
            setGovtdepartment(false)
            setGovtresult(false)
            setinterviewQuestion(false)
            setPracticequestion(false)
            setBlog(false)
            setArticle(false)
            setFaq(false)
            setTestimonial(false)
            setDiscussion(false)
            setCountry(false)
            setEnquiry(false)
            setCmspage(false)
            setDownloads(false)
            setPressrelease(true)
            setNews(false)
            setAdminuser(false)
            setModule(false)
        }
        else if (pathname == constant.component.addNews.url || pathname == constant.component.newsList.url) {
            setEmployer(false)
            setCvsearch(false)
            setHrms(false)
            setJobs(false)
            setPremiumjobs(false)
            setFeaturedjobs(false)
            setGovtjobs(false)
            setJobcandidate(false)
            setJobapplication(false)
            setCandidate(false)
            setCvbooster(false)
            setResumesample(false)
            setJobposting(false)
            setResumesearch(false)
            setproFilebooster(false)
            setAdsmanagement(false)
            setCityads(false)
            setAstrologyservices(false)
            setAstrologyfaq(false)
            setStudy(false)
            setSkills(false)
            setDesignation(false)
            setSalary(false)
            setJobcategory(false)
            setJobtype(false)
            setGovtdepartment(false)
            setGovtresult(false)
            setinterviewQuestion(false)
            setPracticequestion(false)
            setBlog(false)
            setArticle(false)
            setFaq(false)
            setTestimonial(false)
            setDiscussion(false)
            setCountry(false)
            setEnquiry(false)
            setCmspage(false)
            setDownloads(false)
            setPressrelease(false)
            setNews(true)
            setAdminuser(false)
            setModule(false)
        }
        else if (pathname == constant.component.addadminuser.url || pathname == constant.component.adminuserlist.url || pathname == constant.component.addadminrole.url || pathname == constant.component.adminrolelist.url || pathname == constant.component.adminuserlog.url) {
            setEmployer(false)
            setCvsearch(false)
            setHrms(false)
            setJobs(false)
            setPremiumjobs(false)
            setFeaturedjobs(false)
            setGovtjobs(false)
            setJobcandidate(false)
            setJobapplication(false)
            setCandidate(false)
            setCvbooster(false)
            setResumesample(false)
            setJobposting(false)
            setResumesearch(false)
            setproFilebooster(false)
            setAdsmanagement(false)
            setCityads(false)
            setAstrologyservices(false)
            setAstrologyfaq(false)
            setStudy(false)
            setSkills(false)
            setDesignation(false)
            setSalary(false)
            setJobcategory(false)
            setJobtype(false)
            setGovtdepartment(false)
            setGovtresult(false)
            setinterviewQuestion(false)
            setPracticequestion(false)
            setBlog(false)
            setArticle(false)
            setFaq(false)
            setTestimonial(false)
            setDiscussion(false)
            setCountry(false)
            setEnquiry(false)
            setCmspage(false)
            setDownloads(false)
            setPressrelease(false)
            setNews(false)
            setAdminuser(true)
            setModule(false)
        }
        else if (pathname == constant.component.module) {
            setEmployer(false)
            setCvsearch(false)
            setHrms(false)
            setJobs(false)
            setPremiumjobs(false)
            setFeaturedjobs(false)
            setGovtjobs(false)
            setJobcandidate(false)
            setJobapplication(false)
            setCandidate(false)
            setCvbooster(false)
            setResumesample(false)
            setJobposting(false)
            setResumesearch(false)
            setproFilebooster(false)
            setAdsmanagement(false)
            setCityads(false)
            setAstrologyservices(false)
            setAstrologyfaq(false)
            setStudy(false)
            setSkills(false)
            setDesignation(false)
            setSalary(false)
            setJobcategory(false)
            setJobtype(false)
            setGovtdepartment(false)
            setGovtresult(false)
            setinterviewQuestion(false)
            setPracticequestion(false)
            setBlog(false)
            setArticle(false)
            setFaq(false)
            setTestimonial(false)
            setDiscussion(false)
            setCountry(false)
            setEnquiry(false)
            setCmspage(false)
            setDownloads(false)
            setPressrelease(false)
            setNews(false)
            setAdminuser(false)
            setModule(true)
        }
        else { }
    }


    const [faqCount, setFaqCount] = useState(0);
    const [blogCount, setblogCount] = useState(0);
    const [employerCount, setEmployerCount] = useState(0);
    const [jobCount, setJobCount] = useState(0);
    const [postjobCount, setPostJobCount] = useState(0);

    const [pendingjobCount, setPendingJobCount] = useState(0);
    /**Add By Jitender*** */
    /***ende*** */

    const [interviewCount, setInterviewCount] = useState(0)
    const [candidateCount, setCandidateCount] = useState(0)
    const [JobAlertCount, setJobAlertCount] = useState(0)
    const [JobEnquiryCount, setJobEnquiryCount] = useState(0)
    const [appliedJobsCount, setAppliedJobsCount] = useState(0)
    // const [futureHiringCount, setfutureHiringCount] = useState(0)
    const [reviewCount, setReviewCount] = useState(0)

    useEffect(() => {

        totalJobAlertCount().then(res => {
            if (res.status) {
                setJobAlertCount(res.result.count)
            }
            else {
                alert(res.error)
            }
        })

        appliedJobCount().then((res) => {
            if (res.status) {
                setAppliedJobsCount(res.result.count)
            }
        }).catch((err) => {
            alert(err)
        })

        totalEnquiryCount().then(res => {
            if (res.status) {
                setJobEnquiryCount(res.result.count)
            }
            else {
                alert(res.error)
            }
        })
        // totalFutureHiringCount().then(res => {
        //     if (res.status) {
        //         setfutureHiringCount(res.result.count)
        //     }
        //     else {
        //         alert(res.error)
        //     }
        // })
        totalJob().then(res => {
            if (res.status) {
                setJobCount(res.result.count)
            }
            else {
                alert(res.error)
            }
        })

        totalPostJob().then(res => {
            if (res.status) {
                setPostJobCount(res.result.count)
                setPendingJobCount(res.result.count1)
            }
            else {
                alert(res.error)
            }
        })

        totalCandidate().then(res => {
            if (res.status) {
                setCandidateCount(res.result.count)
            }
            else {
                alert(res.error)
            }
        })

        totalBlog().then(res => {
            if (res.status) {
                setblogCount(res.result.count)
            }
            else {
                alert(res.error)
            }
        })

        totalEmployer().then(res => {
            if (res.status) {
                setEmployerCount(res.result.count)
            }
            else {
                alert(res.error)
            }
        })

        TotalInterviewCount().then(res => {
            if (res.status) {
                setInterviewCount(res.result.count)
            }
            else {
                alert(res.error)
            }
        })
        TotalShareInterviewCount().then(res => {
            if (res.status) {
                setShareInterviewCount(res.result.count)

            }
            else {
                alert(res.error)
            }
        })

        totalFAQ().then(res => {
            if (res.status) {
                setFaqCount(res.result.count)
            }
            else {
                alert(res.error)
            }
        })

        totalReviewCount().then((res) => {
            if (res.status) {
                setReviewCount(res.result.count)
            }
            else {
            }
        })
        selectedSection()
    }, [])
    return (
        <div className="vertical-menu">
            <div data-simplebar className="h-100">
                <div id="sidebar-menu">
                    <ul className="metismenu list-unstyled" id="side-menu">

                        <li className="menu-title" key="t-menu">Menu</li>

                        <li className={pathname == constant.component.dashboard.url ? "mm-active" : ""}>
                            <Link className="waves-effect" to={constant.component.dashboard.url}>
                                <i className="bx bx-home-circle"></i>
                                <span key="t-dashboards">Dashboards</span>
                            </Link>
                        </li>
                        <li className={pathname == constant.component.totalcandidates.url ? "mm-active" : ""}>
                            <Link to={constant.component.totalcandidates.url} className="waves-effect">
                                <i className="bx bxs-user-circle"></i><span className="badge rounded-pill bg-success float-end">{candidateCount}</span>
                                <span key="t-dashboards">Total Candidates</span>
                            </Link>
                        </li>
                        <li className={pathname == constant.component.totalemployers.url ? "mm-active" : ""}>
                            <Link to={constant.component.employerslist.url} className="waves-effect">
                                <i className="bx bx-building-house"></i><span className="badge rounded-pill bg-warning float-end">{employerCount}</span>
                                <span key="t-dashboards">Total Employers</span>
                            </Link>
                        </li>
                        <li className={pathname == constant.component.totaljobs.url ? "mm-active" : ""}>
                            <Link to={constant.component.listJobs.url} className="waves-effect">
                                <i className="bx bxs-notepad"></i><span className="badge rounded-pill bg-primary float-end">{jobCount}</span>
                                <span key="t-dashboards">Total Jobs</span>
                            </Link>
                        </li>

                        <li className={pathname == constant.component.JobPostRequestList.url ? "mm-active" : ""}>
                            <Link to={constant.component.JobPostRequestList.url} className="waves-effect">
                                <i className="bx bxs-notepad"></i><span className="badge rounded-pill bg-primary float-end">{postjobCount}</span>
                                <span key="t-dashboards">Approved Jobs</span>
                            </Link>
                        </li>
                        <li className={pathname == constant.component.PendingJobPostRequestList.url ? "mm-active" : ""}>
                            <Link to={constant.component.PendingJobPostRequestList.url} className="waves-effect">
                                <i className="bx bxs-notepad"></i><span className="badge rounded-pill bg-primary float-end">{pendingjobCount}</span>
                                <span key="t-dashboards">Pending Jobs</span>
                            </Link>
                        </li>

                        {/* <li className="">
                            <Link to="/dashboard" className="waves-effect">
                                <i className="bx bxs-badge-check"></i><span className="badge rounded-pill bg-danger float-end">0</span>
                                <span key="t-dashboards">Total Ads</span>
                            </Link>
                        </li> */}
                        {/* <li className={pathname == constant.component.HRMSSubscriptionpackage.url ? "mm-active" : ""}>
                            <Link to={constant.component.HRMSSubscriptionpackage.url} className="waves-effect">
                                <i class='bx bx-link-external' ></i><span className="badge rounded-pill bg-success float-end">0</span>
                                <span key="t-dashboards">HRMS Subscribed </span>
                            </Link>
                        </li> */}
                        {/* <li className=''>
                            <Link to="/dashboard" className="waves-effect">
                                <i className="bx bx-barcode"></i><span className="badge rounded-pill bg-info float-end">0</span>
                                <span key="t-dashboards">Job Ads Packages</span>
                            </Link>
                        </li> */}
                        <li className={pathname == constant.component.interviewQuestionList.url ? "mm-active" : ""}>
                            <Link to={constant.component.interviewQuestionList.url} className="waves-effect">
                                <i className="bx bx-question-mark"></i><span className="badge rounded-pill bg-warning float-end">{interviewCount}</span>
                                <span key="t-dashboards">Interview Questions </span>
                            </Link>
                        </li>
                        <li className={pathname == constant.component.shareInterviewQuestion.url ? "mm-active" : ""}>
                            <Link to={constant.component.shareInterviewQuestion.url} className="waves-effect">
                                <i class='bx bxs-share-alt' ></i><span className="badge rounded-pill bg-success float-end">{shareInterviewCount}</span>
                                <span key="t-dashboards">Shared Interview </span>
                            </Link>
                        </li>
                        <li className=''>
                            <Link to={constant.component.bloglist.url} className="waves-effect">
                                <i className="bx bxl-blogger"></i><span className="badge rounded-pill bg-info float-end">{blogCount}</span>
                                <span key="t-dashboards">Total Blogs </span>
                            </Link>
                        </li>

                        <li className=''>
                            <Link to={constant.component.faqlist.url} className="waves-effect">
                                <i className="bx bx-task"></i><span className="badge rounded-pill bg-danger float-end">{faqCount}</span>
                                <span key="t-dashboards">Total FAQs  </span>
                            </Link>
                        </li>
                        {/* <li className=''>
                            <Link to={constant.component.loanApplications.url} className="waves-effect">
                                <i className="bx bx-rupee"></i><span className="badge rounded-pill bg-danger float-end">{ }</span>
                                <span key="t-dashboards">Loan Applications</span>
                            </Link>
                        </li> */}
                        <li className=''>
                            <Link to={constant.component.enquiryList.url} className="waves-effect">
                                <i className="bx bx-line-chart"></i><span className="badge rounded-pill bg-info float-end">{JobEnquiryCount}</span>
                                <span key="t-dashboards">General Enquiries </span>
                            </Link>
                        </li>

                        <li className=''>
                            <Link to={constant.component.appliedJObs.url} className="waves-effect">
                                <i className="bx bx-line-chart"></i><span className="badge rounded-pill bg-warning float-end">{appliedJobsCount}</span>
                                <span key="t-dashboards">Applied Jobs </span>
                            </Link>
                            <Link to={constant.component.Companyreview.url} className="waves-effect">
                                <i className="bx bx-building-house"></i><span className="badge rounded-pill bg-danger float-end"> {reviewCount} </span>
                                <span key="t-dashboards">Company Review </span>
                            </Link>
                        </li>
                        {/* <li className=''>
                            <Link to={constant.component.recruiterEnquiryList.url} className="waves-effect">
                                <i className="bx bx-line-chart"></i><span className="badge rounded-pill bg-danger float-end">17</span>
                                <span key="t-dashboards">Recruiter Enquiries </span>
                            </Link>
                        </li> */}

                        <li className="menu-title" >EMPLOYERS</li>

                        <li>
                            <a className={employer ? "has-arrow waves-effect mm-active" : "has-arrow waves-effect"} onClick={() => {
                                if (employer === false) {
                                    setEmployer(!employer)
                                    setCvsearch(false)
                                    setHrms(false)
                                    setJobs(false)
                                    setPremiumjobs(false)
                                    setFeaturedjobs(false)
                                    setGovtjobs(false)
                                    setJobcandidate(false)
                                    setJobapplication(false)
                                    setCandidate(false)
                                    setCvbooster(false)
                                    setResumesample(false)
                                    setJobposting(false)
                                    setResumesearch(false)
                                    setproFilebooster(false)
                                    setAdsmanagement(false)
                                    setCityads(false)
                                    setAstrologyservices(false)
                                    setAstrologyfaq(false)
                                    setStudy(false)
                                    setSkills(false)
                                    setDesignation(false)
                                    setSalary(false)
                                    setJobcategory(false)
                                    setJobtype(false)
                                    setGovtdepartment(false)
                                    setGovtresult(false)
                                    setinterviewQuestion(false)
                                    setPracticequestion(false)
                                    setBlog(false)
                                    setArticle(false)
                                    setFaq(false)
                                    setTestimonial(false)
                                    setDiscussion(false)
                                    setCountry(false)
                                    setEnquiry(false)
                                    setCmspage(false)
                                    setDownloads(false)
                                    setPressrelease(false)
                                    setNews(false)
                                    setAdminuser(false)
                                    setModule(false)
                                }
                                else {
                                    setEmployer(!employer)
                                }
                            }}>
                                <i className="bx bx-building-house"></i>
                                <span key="t-contacts">Employers</span>
                            </a>
                            {
                                employer ?
                                    <ul className="sub-menu" aria-expanded="false">
                                        <li className={pathname == constant.component.addemployers.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.addemployers.url} >Add Employer</Link>
                                        </li>
                                        <li className={pathname == constant.component.employerslist.url || pathname == constant.component.editArticleCategory.url.replace(':id', id) ? 'mm-active' : ''}>
                                            <Link to={constant.component.employerslist.url} key="t-user-grid">Employer List</Link>
                                        </li>
                                        <li className={pathname == constant.component.archiveEmployerList.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.archiveEmployerList.url} key="t-user-grid">Archived / Blocked </Link>
                                        </li>
                                        {/* <li>
                                            <Link to="/dashboard" key="t-user-grid">Employers Jobs Posted</Link>
                                        </li> */}
                                        <li className={pathname == constant.component.Companyreview.url ? "mm-active" : ''}>
                                            <Link to={constant.component.Companyreview.url} key="t-user-grid">Employers Reviewed</Link>
                                        </li>
                                        <li className={pathname == constant.component.featuredEmployerList.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.featuredEmployerList.url} key="t-user-grid">Featured Employers</Link>
                                        </li>
                                        <li className={pathname == constant.component.premiumEmployerList.url ? 'mm-active' : ''} >
                                            <Link to={constant.component.premiumEmployerList.url} key="t-user-grid">Premium Employers</Link>
                                        </li>
                                        <li className={pathname == constant.component.addcompanytype.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.addcompanytype.url} key="t-user-grid">Add Company Type</Link>
                                        </li>
                                        <li className={pathname == constant.component.companytypelist.url || pathname == constant.component.editcompanytype.url.replace(':id', id) ? 'mm-active' : ''}>
                                            <Link to={constant.component.companytypelist.url} key="t-user-grid">Company Type List</Link>
                                        </li>
                                        <li className={pathname == constant.component.addindustry.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.addindustry.url} key="t-user-grid">Add Industry</Link>
                                        </li>
                                        {/* <li className={pathname == constant.component.updateIndustry.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.updateIndustry.url} key="t-user-grid">Update Employer Industry</Link>
                                        </li> */}
                                        <li className={pathname == constant.component.industrylist.url || pathname == constant.component.editindustry.url.replace(':id', id) ? 'mm-active' : ''}>
                                            <Link to={constant.component.industrylist.url} key="t-user-grid">Industry List</Link>
                                        </li>
                                        <li className={pathname == constant.component.addemployeebenefits.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.addemployeebenefits.url} key="t-user-grid">Add Employee Benefits</Link>
                                        </li>
                                        <li className={pathname == constant.component.employeebenefitslist.url || pathname == constant.component.editEmployeeBenefitTytpe.url.replace(':id', id) ? 'mm-active' : ''}>
                                            <Link to={constant.component.employeebenefitslist.url} key="t-user-grid">Employee Benefits List</Link>
                                        </li>
                                        <li className={pathname == constant.component.addentitytype.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.addentitytype.url} key="t-user-grid">Add Entity Type</Link>
                                        </li>
                                        <li className={pathname == constant.component.entitytypelist.url || pathname == constant.component.editentitytype.url.replace(':id', id) ? 'mm-active' : ''}>
                                            <Link to={constant.component.entitytypelist.url} key="t-user-grid">Entity Type List</Link>
                                        </li>
                                        <li className={pathname == constant.component.addaddressprooftype.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.addaddressprooftype.url} key="t-user-grid">Add Address Proof Type</Link>
                                        </li>
                                        <li className={pathname == constant.component.addressprooftypelist.url || pathname == constant.component.editaddressprooftype.url.replace(':id', id) ? 'mm-active' : ''}>
                                            <Link to={constant.component.addressprooftypelist.url} key="t-user-grid">Address Proof Type List</Link>
                                        </li>

                                        <li className={pathname == constant.component.employerKYC.url || pathname == constant.component.employerKYC.url.replace(':id', id) ? 'mm-active' : ''}>
                                            <Link to={constant.component.employerKYC.url} key="t-user-grid">Employer KYC </Link>
                                        </li>

                                    </ul> : ""
                            }

                        </li>




                        {/* 
                        <li>
                            <a className={cvsearch ? "has-arrow waves-effect mm-active" : "has-arrow waves-effect"} onClick={() => {
                                if (cvsearch === false) {
                                    setEmployer(false)
                                    setCvsearch(!cvsearch)
                                    setHrms(false)
                                    setJobs(false)
                                    setPremiumjobs(false)
                                    setFeaturedjobs(false)
                                    setGovtjobs(false)
                                    setJobcandidate(false)
                                    setJobapplication(false)
                                    setCandidate(false)
                                    setCvbooster(false)
                                    setResumesample(false)
                                    setJobposting(false)
                                    setResumesearch(false)
                                    setproFilebooster(false)
                                    setAdsmanagement(false)
                                    setCityads(false)
                                    setAstrologyservices(false)
                                    setAstrologyfaq(false)
                                    setStudy(false)
                                    setSkills(false)
                                    setDesignation(false)
                                    setSalary(false)
                                    setJobcategory(false)
                                    setJobtype(false)
                                    setGovtdepartment(false)
                                    setGovtresult(false)
                                    setinterviewQuestion(false)
                                    setPracticequestion(false)
                                    setBlog(false)
                                    setArticle(false)
                                    setFaq(false)
                                    setTestimonial(false)
                                    setDiscussion(false)
                                    setCountry(false)
                                    setEnquiry(false)
                                    setCmspage(false)
                                    setDownloads(false)
                                    setPressrelease(false)
                                    setNews(false)
                                    setAdminuser(false)
                                    setModule(false)
                                }
                                else {
                                    setCvsearch(!cvsearch)
                                }
                            }}>
                                <i className="bx bx-search-alt-2"></i>
                                <span key="t-contacts">CV Search Subscription</span>
                            </a>
                            <ul className="sub-menu" aria-expanded="false">
                                {
                                    cvsearch ?
                                        <li className={pathname == constant.component ? 'mm-active' : ''}>
                                            <Link to="/dashboard" key="t-user-grid">Resume Search Subscription List</Link>
                                        </li> : ""
                                }
                            </ul>
                        </li> */}



                        <li>
                            <a className={packageSubscription ? "has-arrow waves-effect mm-active" : "has-arrow waves-effect"} onClick={() => {
                                if (packageSubscription === false) {
                                    setEmployer(false)
                                    setCvsearch(false)
                                    SetpackageSubscription(!packageSubscription)
                                    setHrms(false)
                                    setJobs(false)
                                    setPremiumjobs(false)
                                    setFeaturedjobs(false)
                                    setGovtjobs(false)
                                    setJobcandidate(false)
                                    setJobapplication(false)
                                    setCandidate(false)
                                    setCvbooster(false)
                                    setResumesample(false)
                                    setJobposting(false)
                                    setResumesearch(false)
                                    setproFilebooster(false)
                                    setAdsmanagement(false)
                                    setCityads(false)
                                    setAstrologyservices(false)
                                    setAstrologyfaq(false)
                                    setStudy(false)
                                    setSkills(false)
                                    setDesignation(false)
                                    setSalary(false)
                                    setJobcategory(false)
                                    setJobtype(false)
                                    setGovtdepartment(false)
                                    setGovtresult(false)
                                    setinterviewQuestion(false)
                                    setPracticequestion(false)
                                    setBlog(false)
                                    setArticle(false)
                                    setFaq(false)
                                    setTestimonial(false)
                                    setDiscussion(false)
                                    setCountry(false)
                                    setEnquiry(false)
                                    setCmspage(false)
                                    setDownloads(false)
                                    setPressrelease(false)
                                    setNews(false)
                                    setAdminuser(false)
                                    setModule(false)
                                }
                                else {
                                    SetpackageSubscription(!packageSubscription)
                                }
                            }}>
                                <i className="mdi mdi-youtube-subscription"></i>
                                <span key="t-contacts"> Subscription</span>
                            </a>
                            <ul className="sub-menu" aria-expanded="false">
                                {
                                    packageSubscription ?
                                        <li className={pathname == constant.component.Subscriptionpackage.url || pathname == constant.component.Subscriptionpackage.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.Subscriptionpackage.url} key="t-user-grid">Subscription </Link>
                                        </li> : ""
                                }
                            </ul>
                        </li>







                        {/* <li>
                            <a className={hrms ? "has-arrow waves-effect mm-active" : "has-arrow waves-effect"} onClick={() => {
                                if (hrms === false) {
                                    setEmployer(false)
                                    setCvsearch(false)
                                    setHrms(!hrms)
                                    setJobs(false)
                                    setPremiumjobs(false)
                                    setFeaturedjobs(false)
                                    setGovtjobs(false)
                                    setJobcandidate(false)
                                    setJobapplication(false)
                                    setCandidate(false)
                                    setCvbooster(false)
                                    setResumesample(false)
                                    setJobposting(false)
                                    setResumesearch(false)
                                    setproFilebooster(false)
                                    setAdsmanagement(false)
                                    setCityads(false)
                                    setAstrologyservices(false)
                                    setAstrologyfaq(false)
                                    setStudy(false)
                                    setSkills(false)
                                    setDesignation(false)
                                    setSalary(false)
                                    setJobcategory(false)
                                    setJobtype(false)
                                    setGovtdepartment(false)
                                    setinterviewQuestion(false)
                                    setPracticequestion(false)
                                    setBlog(false)
                                    setArticle(false)
                                    setFaq(false)
                                    setTestimonial(false)
                                    setDiscussion(false)
                                    setCountry(false)
                                    setEnquiry(false)
                                    setCmspage(false)
                                    setDownloads(false)
                                    setPressrelease(false)
                                    setNews(false)
                                    setAdminuser(false)
                                    setModule(false)
                                }
                                else {
                                    setHrms(!hrms)
                                }
                            }}>
                                <i className="bx bx-link-external"></i>
                                <span key="t-contacts">HRMS Subscriptions</span>
                            </a>
                            {
                                hrms ?
                                    <ul className="sub-menu" aria-expanded="false">
                                        <li className={pathname == constant.component.HRMSSubscriptionpackage.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.HRMSSubscriptionpackage.url} key="t-user-grid">HRMS Subscriptions List</Link>
                                        </li>
                                    </ul> : ""
                            }
                        </li> */}
                        {/* <li className=''>
                            <Link to={constant.component.futureHiringList.url} className="waves-effect">
                                <i class="fa fa-calendar" aria-hidden="true"></i><span className="badge rounded-pill bg-danger float-end">{futureHiringCount}</span>
                                <span key="t-dashboards">Future Event Hiring List</span>
                            </Link>
                        </li> */}

                        <li className="menu-title" key="t-components">JOBS</li>

                        <li>
                            <a className={jobs ? "has-arrow waves-effect mm-active" : "has-arrow waves-effect"} onClick={() => {
                                if (jobs === false) {
                                    setEmployer(false)
                                    setCvsearch(false)
                                    setHrms(false)
                                    setJobs(!jobs)
                                    setPremiumjobs(false)
                                    setFeaturedjobs(false)
                                    setGovtjobs(false)
                                    setJobcandidate(false)
                                    setJobapplication(false)
                                    setCandidate(false)
                                    setCvbooster(false)
                                    setResumesample(false)
                                    setJobposting(false)
                                    setResumesearch(false)
                                    setproFilebooster(false)
                                    setAdsmanagement(false)
                                    setCityads(false)
                                    setAstrologyservices(false)
                                    setAstrologyfaq(false)
                                    setStudy(false)
                                    setSkills(false)
                                    setDesignation(false)
                                    setSalary(false)
                                    setJobcategory(false)
                                    setJobtype(false)
                                    setGovtdepartment(false)
                                    setGovtresult(false)
                                    setinterviewQuestion(false)
                                    setPracticequestion(false)
                                    setBlog(false)
                                    setArticle(false)
                                    setFaq(false)
                                    setTestimonial(false)
                                    setDiscussion(false)
                                    setCountry(false)
                                    setEnquiry(false)
                                    setCmspage(false)
                                    setDownloads(false)
                                    setPressrelease(false)
                                    setNews(false)
                                    setAdminuser(false)
                                    setModule(false)
                                }
                                else {
                                    setJobs(!jobs)
                                }
                            }}>
                                <i className="bx bx-task"></i>
                                <span key="t-contacts">Jobs</span>
                            </a>
                            {
                                jobs ?
                                    <ul className="sub-menu" aria-expanded="false">
                                        <li className={pathname == constant.component.addJobs.url ? "mm-active" : ""}>
                                            <Link to={constant.component.addJobs.url} key="t-user-grid">Add Jobs</Link>
                                        </li>
                                        <li className={pathname == constant.component.listJobs.url || pathname == constant.component.editJobs.url.replace(':id', id) ? "mm-active" : ""}>
                                            <Link to={constant.component.listJobs.url} key="t-user-grid">Jobs List</Link>
                                        </li>

                                        <li className={pathname == constant.component.addEmployementType.url ? "mm-active" : ""}>
                                            <Link to={constant.component.addEmployementType.url} key="t-user-grid">Add Employment Type</Link>
                                        </li>
                                        <li className={pathname == constant.component.listEmployementType.url || pathname == constant.component.editEmployementType.url.replace(':id', id) ? "mm-active" : ""}>
                                            <Link to={constant.component.listEmployementType.url} key="t-user-grid">Employment Type List</Link>
                                        </li>

                                        <li className={pathname == constant.component.addIndustry.url ? "mm-active" : ""}>
                                            <Link to={constant.component.addIndustry.url} key="t-user-grid">Add Industry</Link>
                                        </li>
                                        <li className={pathname == constant.component.listIndustry.url || pathname == constant.component.editIndustry.url.replace(':id', id) ? "mm-active" : ""}>
                                            <Link to={constant.component.listIndustry.url} key="t-user-grid">Industry List</Link>
                                        </li>
                                        <li className={pathname == constant.component.addFunctionalArea.url ? "mm-active" : ""}>
                                            <Link to={constant.component.addFunctionalArea.url} key="t-user-grid">Add Functional Area</Link>
                                        </li>
                                        <li className={pathname == constant.component.listFunctionalArea.url || pathname == constant.component.editFunctionalArea.url.replace(':id', id) ? "mm-active" : ""}>
                                            <Link to={constant.component.listFunctionalArea.url} key="t-user-grid">Functional Area List</Link>
                                        </li>

                                        <li className={pathname == constant.component.addRole.url ? "mm-active" : ""}>
                                            <Link to={constant.component.addRole.url} key="t-user-grid">Add Role</Link>
                                        </li>
                                        <li className={pathname == constant.component.listRole.url || pathname == constant.component.editRole.url.replace(':id', id) ? "mm-active" : ""}>
                                            <Link to={constant.component.listRole.url} key="t-user-grid">Role List</Link>
                                        </li>

                                        <li className={pathname == constant.component.addQualification.url ? "mm-active" : ""}>
                                            <Link to={constant.component.addQualification.url} key="t-user-grid">Add Qualification</Link>
                                        </li>
                                        <li className={pathname == constant.component.listQualification.url || pathname == constant.component.editQualification.url.replace(':id', id) ? "mm-active" : ""}>
                                            <Link to={constant.component.listQualification.url} key="t-user-grid">Qualification List</Link>
                                        </li>
                                        <li className={pathname == constant.component.addCourseSpecialization.url ? "mm-active" : ""}>
                                            <Link to={constant.component.addCourseSpecialization.url} key="t-user-grid">Add Course Specialization</Link>
                                        </li>
                                        <li className={pathname == constant.component.listCourseSpecialization.url || pathname == constant.component.editCourseSpecialization.url.replace(':id', id) ? "mm-active" : ""}>
                                            <Link to={constant.component.listCourseSpecialization.url} key="t-user-grid">Course Specialization List</Link>
                                        </li>
                                        <li className={pathname == constant.component.addJobQuest.url ? "mm-active" : ""}>
                                            <Link to={constant.component.addJobQuest.url} key="t-user-grid">Add Job Questions</Link>
                                        </li>
                                        <li className={pathname == constant.component.listJobQuest.url || pathname == constant.component.editJobQuest.url.replace(':id', id) ? "mm-active" : ""}>
                                            <Link to={constant.component.listJobQuest.url} key="t-user-grid">Job Questions List</Link>
                                        </li>
                                    </ul> : ""
                            }
                        </li>

                        <li>
                            <a className={premiumjobs ? "has-arrow waves-effect mm-active" : "has-arrow waves-effect"} onClick={() => {
                                if (premiumjobs === false) {
                                    setEmployer(false)
                                    setCvsearch(false)
                                    setHrms(false)
                                    setJobs(false)
                                    setPremiumjobs(!premiumjobs)
                                    setFeaturedjobs(false)
                                    setGovtjobs(false)
                                    setJobcandidate(false)
                                    setJobapplication(false)
                                    setCandidate(false)
                                    setCvbooster(false)
                                    setResumesample(false)
                                    setJobposting(false)
                                    setResumesearch(false)
                                    setproFilebooster(false)
                                    setAdsmanagement(false)
                                    setCityads(false)
                                    setAstrologyservices(false)
                                    setAstrologyfaq(false)
                                    setStudy(false)
                                    setSkills(false)
                                    setDesignation(false)
                                    setSalary(false)
                                    setJobcategory(false)
                                    setJobtype(false)
                                    setGovtdepartment(false)
                                    setGovtresult(false)
                                    setinterviewQuestion(false)
                                    setPracticequestion(false)
                                    setBlog(false)
                                    setArticle(false)
                                    setFaq(false)
                                    setTestimonial(false)
                                    setDiscussion(false)
                                    setCountry(false)
                                    setEnquiry(false)
                                    setCmspage(false)
                                    setDownloads(false)
                                    setPressrelease(false)
                                    setNews(false)
                                    setAdminuser(false)
                                    setModule(false)
                                }
                                else {
                                    setPremiumjobs(!premiumjobs)
                                }
                            }}>
                                <i className="bx bx-shield"></i>
                                <span key="t-contacts">Premium Jobs</span>
                            </a>
                            {
                                premiumjobs ?
                                    <ul className="sub-menu" aria-expanded="false">
                                        {/* <li className={pathname == constant.component ? 'mm-active' : ''}>
                                            <Link to="/dashboard" key="t-user-grid">Add Premium  Jobs</Link>
                                        </li> */}
                                        <li className={pathname == constant.component ? 'mm-active' : ''}>
                                            <Link to={constant.component.premiumJobsList.url} key="t-user-grid">Premium Jobs List</Link>
                                        </li>
                                    </ul> : ""
                            }
                        </li>

                        <li>
                            <a className={featuredjobs ? "has-arrow waves-effect mm-active" : "has-arrow waves-effect"} onClick={() => {
                                if (featuredjobs === false) {
                                    setEmployer(false)
                                    setCvsearch(false)
                                    setHrms(false)
                                    setJobs(false)
                                    setPremiumjobs(false)
                                    setFeaturedjobs(!featuredjobs)
                                    setGovtjobs(false)
                                    setJobcandidate(false)
                                    setJobapplication(false)
                                    setCandidate(false)
                                    setCvbooster(false)
                                    setResumesample(false)
                                    setJobposting(false)
                                    setResumesearch(false)
                                    setproFilebooster(false)
                                    setAdsmanagement(false)
                                    setCityads(false)
                                    setAstrologyservices(false)
                                    setAstrologyfaq(false)
                                    setStudy(false)
                                    setSkills(false)
                                    setDesignation(false)
                                    setSalary(false)
                                    setJobcategory(false)
                                    setJobtype(false)
                                    setGovtdepartment(false)
                                    setGovtresult(false)
                                    setinterviewQuestion(false)
                                    setPracticequestion(false)
                                    setBlog(false)
                                    setArticle(false)
                                    setFaq(false)
                                    setTestimonial(false)
                                    setDiscussion(false)
                                    setCountry(false)
                                    setEnquiry(false)
                                    setCmspage(false)
                                    setDownloads(false)
                                    setPressrelease(false)
                                    setNews(false)
                                    setAdminuser(false)
                                    setModule(false)
                                }
                                else {
                                    setFeaturedjobs(!featuredjobs)
                                }
                            }}>
                                <i className="bx bx-right-top-arrow-circle"></i>
                                <span key="t-contacts">Featured Jobs</span>
                            </a>
                            {
                                featuredjobs ?
                                    <ul className="sub-menu" aria-expanded="false">
                                        {/* <li className={pathname == constant.component ? 'mm-active' : ''}>
                                            <Link to="/dashboard" key="t-user-grid">Add Featured  Jobs</Link>
                                        </li> */}
                                        <li className={pathname == constant.component ? 'mm-active' : ''}>
                                            <Link to={constant.component.featuredJobsList.url} key="t-user-grid">Featured Jobs List</Link>
                                        </li>
                                    </ul> : ""
                            }
                        </li>


                        {/* <li>
                            <a className={jobcandidate ? "has-arrow waves-effect mm-active" : "has-arrow waves-effect"} onClick={() => {
                                if (jobcandidate === false) {
                                    setEmployer(false)
                                    setCvsearch(false)
                                    setHrms(false)
                                    setJobs(false)
                                    setPremiumjobs(false)
                                    setFeaturedjobs(false)
                                    setGovtjobs(false)
                                    setJobcandidate(!jobcandidate)
                                    setJobapplication(false)
                                    setCandidate(false)
                                    setCvbooster(false)
                                    setResumesample(false)
                                    setJobposting(false)
                                    setResumesearch(false)
                                    setproFilebooster(false)
                                    setAdsmanagement(false)
                                    setCityads(false)
                                    setAstrologyservices(false)
                                    setAstrologyfaq(false)
                                    setStudy(false)
                                    setSkills(false)
                                    setDesignation(false)
                                    setSalary(false)
                                    setJobcategory(false)
                                    setJobtype(false)
                                    setGovtdepartment(false)
                                    setinterviewQuestion(false)
                                    setPracticequestion(false)
                                    setBlog(false)
                                    setArticle(false)
                                    setFaq(false)
                                    setTestimonial(false)
                                    setDiscussion(false)
                                    setCountry(false)
                                    setEnquiry(false)
                                    setCmspage(false)
                                    setDownloads(false)
                                    setPressrelease(false)
                                    setNews(false)
                                    setAdminuser(false)
                                    setModule(false)
                                }
                                else {
                                    setJobcandidate(!jobcandidate)
                                }
                            }}>
                                <i className="bx bx-select-multiple"></i>
                                <span key="t-contacts">Jobs by Candidate</span>
                            </a>
                            {
                                jobcandidate ?
                                    <ul className="sub-menu" aria-expanded="false">
                                        <li className={pathname == constant.component ? 'mm-active' : ''}>
                                            <Link to="/dashboard" key="t-user-grid">Jobs by Candidate List</Link>
                                        </li>
                                    </ul> : ""
                            }
                        </li> */}


                        {/* <li>
                            <a className={jobapplication ? "has-arrow waves-effect mm-active" : "has-arrow waves-effect"} onClick={() => {
                                if (jobapplication === false) {
                                    setEmployer(false)
                                    setCvsearch(false)
                                    setHrms(false)
                                    setJobs(false)
                                    setPremiumjobs(false)
                                    setFeaturedjobs(false)
                                    setGovtjobs(false)
                                    setJobcandidate(false)
                                    setJobapplication(!jobapplication)
                                    setCandidate(false)
                                    setCvbooster(false)
                                    setResumesample(false)
                                    setJobposting(false)
                                    setResumesearch(false)
                                    setproFilebooster(false)
                                    setAdsmanagement(false)
                                    setCityads(false)
                                    setAstrologyservices(false)
                                    setAstrologyfaq(false)
                                    setStudy(false)
                                    setSkills(false)
                                    setDesignation(false)
                                    setSalary(false)
                                    setJobcategory(false)
                                    setJobtype(false)
                                    setGovtdepartment(false)
                                    setinterviewQuestion(false)
                                    setPracticequestion(false)
                                    setBlog(false)
                                    setArticle(false)
                                    setFaq(false)
                                    setTestimonial(false)
                                    setDiscussion(false)
                                    setCountry(false)
                                    setEnquiry(false)
                                    setCmspage(false)
                                    setDownloads(false)
                                    setPressrelease(false)
                                    setNews(false)
                                    setAdminuser(false)
                                    setModule(false)
                                }
                                else {
                                    setJobapplication(!jobapplication)
                                }
                            }}>
                                <i className="bx bx-briefcase-alt-2"></i>
                                <span key="t-contacts">Jobs Applications</span>
                            </a>
                            {
                                jobapplication ?
                                    <ul className="sub-menu" aria-expanded="false">
                                        <li className={pathname == constant.component ? 'mm-active' : ''}>
                                            <Link to="/dashboard" key="t-user-grid">Jobs Applications List</Link>
                                        </li>
                                    </ul> : ""
                            }
                        </li> */}
                        <li className="menu-title" key="t-components">Government</li>
                        <li>
                            <a className={govtjobs ? "has-arrow waves-effect mm-active" : "has-arrow waves-effect"} onClick={() => {
                                if (govtjobs === false) {
                                    setEmployer(false)
                                    setCvsearch(false)
                                    setHrms(false)
                                    setJobs(false)
                                    setPremiumjobs(false)
                                    setFeaturedjobs(false)
                                    setGovtjobs(!govtjobs)
                                    setJobcandidate(false)
                                    setJobapplication(false)
                                    setCandidate(false)
                                    setCvbooster(false)
                                    setResumesample(false)
                                    setJobposting(false)
                                    setResumesearch(false)
                                    setproFilebooster(false)
                                    setAdsmanagement(false)
                                    setCityads(false)
                                    setAstrologyservices(false)
                                    setAstrologyfaq(false)
                                    setStudy(false)
                                    setSkills(false)
                                    setDesignation(false)
                                    setSalary(false)
                                    setJobcategory(false)
                                    setJobtype(false)
                                    setGovtdepartment(false)
                                    setGovtresult(false)
                                    setinterviewQuestion(false)
                                    setPracticequestion(false)
                                    setBlog(false)
                                    setArticle(false)
                                    setFaq(false)
                                    setTestimonial(false)
                                    setDiscussion(false)
                                    setCountry(false)
                                    setEnquiry(false)
                                    setCmspage(false)
                                    setDownloads(false)
                                    setPressrelease(false)
                                    setNews(false)
                                    setAdminuser(false)
                                    setModule(false)
                                }
                                else {
                                    setGovtjobs(!govtjobs)
                                }
                            }}>
                                <i className="bx bx-stop-circle"></i>
                                <span key="t-contacts">Government Jobs</span>
                            </a>
                            {
                                govtjobs ?
                                    <ul className="sub-menu" aria-expanded="false">
                                        <li className={pathname == constant.component ? 'mm-active' : ''}>
                                            <Link to="/create-govt-jobs" key="t-user-grid">Add Government Jobs</Link>
                                        </li>
                                        <li className={pathname == constant.component ? 'mm-active' : ''}>
                                            <Link to="/govt-jobs-list" key="t-user-grid">Government Jobs List</Link>
                                        </li>
                                    </ul> : ""
                            }
                        </li>
                        <li>
                            <a className={govtdepartment ? "has-arrow waves-effect mm-active" : "has-arrow waves-effect"} onClick={() => {
                                if (govtdepartment === false) {
                                    setEmployer(false)
                                    setCvsearch(false)
                                    setHrms(false)
                                    setJobs(false)
                                    setPremiumjobs(false)
                                    setFeaturedjobs(false)
                                    setGovtjobs(false)
                                    setJobcandidate(false)
                                    setJobapplication(false)
                                    setCandidate(false)
                                    setCvbooster(false)
                                    setResumesample(false)
                                    setJobposting(false)
                                    setResumesearch(false)
                                    setproFilebooster(false)
                                    setAdsmanagement(false)
                                    setCityads(false)
                                    setAstrologyservices(false)
                                    setAstrologyfaq(false)
                                    setStudy(false)
                                    setSkills(false)
                                    setDesignation(false)
                                    setSalary(false)
                                    setJobcategory(false)
                                    setJobtype(false)
                                    setGovtdepartment(!govtdepartment)
                                    setinterviewQuestion(false)
                                    setPracticequestion(false)
                                    setBlog(false)
                                    setArticle(false)
                                    setFaq(false)
                                    setTestimonial(false)
                                    setDiscussion(false)
                                    setCountry(false)
                                    setEnquiry(false)
                                    setCmspage(false)
                                    setDownloads(false)
                                    setPressrelease(false)
                                    setNews(false)
                                    setAdminuser(false)
                                    setModule(false)
                                }
                                else {
                                    setGovtdepartment(!govtdepartment)
                                }
                            }}>
                                <i className="bx bxs-calendar-event"></i>
                                <span key="t-contacts">Govt. Department</span>
                            </a>
                            {
                                govtdepartment ?
                                    <ul className="sub-menu" aria-expanded="false">
                                        <li className={pathname == constant.component.addgovtdepartment.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.addgovtdepartment.url} key="t-user-grid">Add Govt. Department</Link>
                                        </li>
                                        <li className={pathname == constant.component.govtdepartmentlist.url || pathname == constant.component.editgovtdepartment.url.replace(':id', id) ? 'mm-active' : ''}>
                                            <Link to={constant.component.govtdepartmentlist.url} key="t-user-grid">Govt. Department List</Link>
                                        </li>
                                    </ul> : ""
                            }
                        </li>

                        <li>
                            <a className={govtresult ? "has-arrow waves-effect mm-active" : "has-arrow waves-effect"} onClick={() => {
                                if (govtresult === false) {
                                    setEmployer(false)
                                    setCvsearch(false)
                                    setHrms(false)
                                    setJobs(false)
                                    setPremiumjobs(false)
                                    setFeaturedjobs(false)
                                    setGovtjobs(false)
                                    setJobcandidate(false)
                                    setJobapplication(false)
                                    setCandidate(false)
                                    setCvbooster(false)
                                    setResumesample(false)
                                    setJobposting(false)
                                    setResumesearch(false)
                                    setproFilebooster(false)
                                    setAdsmanagement(false)
                                    setCityads(false)
                                    setAstrologyservices(false)
                                    setAstrologyfaq(false)
                                    setStudy(false)
                                    setSkills(false)
                                    setDesignation(false)
                                    setSalary(false)
                                    setJobcategory(false)
                                    setJobtype(false)
                                    setGovtdepartment(false)
                                    setGovtresult(!govtresult)
                                    setinterviewQuestion(false)
                                    setPracticequestion(false)
                                    setBlog(false)
                                    setArticle(false)
                                    setFaq(false)
                                    setTestimonial(false)
                                    setDiscussion(false)
                                    setCountry(false)
                                    setEnquiry(false)
                                    setCmspage(false)
                                    setDownloads(false)
                                    setPressrelease(false)
                                    setNews(false)
                                    setAdminuser(false)
                                    setModule(false)
                                }
                                else {
                                    setGovtresult(!govtresult)
                                }
                            }}>
                                <i className="bx bxs-calendar-event"></i>
                                <span key="t-contacts">Govt. Result</span>
                            </a>
                            {
                                govtresult ?
                                    <ul className="sub-menu" aria-expanded="false">
                                        <li className={pathname == constant.component.addgovtresult.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.addgovtresult.url} key="t-user-grid">Add Govt. Result</Link>
                                        </li>
                                        <li className={pathname == constant.component.govtjobsresult.url || pathname == constant.component.editgovtresult.url.replace(':id', id) ? 'mm-active' : ''}>
                                            <Link to={constant.component.govtjobsresult.url} key="t-user-grid">Govt. Job Result List</Link>
                                        </li>
                                    </ul> : ""
                            }
                        </li>
                        <li className="menu-title" key="t-components">CANDIDATES</li>

                        <li>
                            <a className={candidate ? "has-arrow waves-effect mm-active" : "has-arrow waves-effect"} onClick={() => {
                                if (candidate === false) {
                                    setEmployer(false)
                                    setCvsearch(false)
                                    setHrms(false)
                                    setJobs(false)
                                    setPremiumjobs(false)
                                    setFeaturedjobs(false)
                                    setGovtjobs(false)
                                    setJobcandidate(false)
                                    setJobapplication(false)
                                    setCandidate(!candidate)
                                    setCvbooster(false)
                                    setResumesample(false)
                                    setJobposting(false)
                                    setResumesearch(false)
                                    setproFilebooster(false)
                                    setAdsmanagement(false)
                                    setCityads(false)
                                    setAstrologyservices(false)
                                    setAstrologyfaq(false)
                                    setStudy(false)
                                    setSkills(false)
                                    setDesignation(false)
                                    setSalary(false)
                                    setJobcategory(false)
                                    setJobtype(false)
                                    setGovtdepartment(false)
                                    setGovtresult(false)
                                    setinterviewQuestion(false)
                                    setPracticequestion(false)
                                    setBlog(false)
                                    setArticle(false)
                                    setFaq(false)
                                    setTestimonial(false)
                                    setDiscussion(false)
                                    setCountry(false)
                                    setEnquiry(false)
                                    setCmspage(false)
                                    setDownloads(false)
                                    setPressrelease(false)
                                    setNews(false)
                                    setAdminuser(false)
                                    setModule(false)
                                }
                                else {
                                    setCandidate(!candidate)
                                }
                            }}>
                                <i className="bx bxs-user-detail"></i>
                                <span key="t-contacts">Candidates</span>
                            </a>
                            {
                                candidate ?
                                    <ul className="sub-menu" aria-expanded="false">
                                        <li className={pathname == constant.component.candidateslist.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.candidateslist.url} key="t-user-grid">Candidates List</Link>
                                        </li>
                                    </ul> : ""
                            }
                        </li>

                        {/* <li>
                            <a className={cvbooster ? "has-arrow waves-effect mm-active" : "has-arrow waves-effect"} onClick={() => {
                                if (cvbooster === false) {
                                    setEmployer(false)
                                    setCvsearch(false)
                                    setHrms(false)
                                    setJobs(false)
                                    setPremiumjobs(false)
                                    setFeaturedjobs(false)
                                    setGovtjobs(false)
                                    setJobcandidate(false)
                                    setJobapplication(false)
                                    setCandidate(false)
                                    setCvbooster(!cvbooster)
                                    setResumesample(false)
                                    setJobposting(false)
                                    setResumesearch(false)
                                    setproFilebooster(false)
                                    setAdsmanagement(false)
                                    setCityads(false)
                                    setAstrologyservices(false)
                                    setAstrologyfaq(false)
                                    setStudy(false)
                                    setSkills(false)
                                    setDesignation(false)
                                    setSalary(false)
                                    setJobcategory(false)
                                    setJobtype(false)
                                    setGovtdepartment(false)
                                    setGovtresult(false)
                                    setinterviewQuestion(false)
                                    setPracticequestion(false)
                                    setBlog(false)
                                    setArticle(false)
                                    setFaq(false)
                                    setTestimonial(false)
                                    setDiscussion(false)
                                    setCountry(false)
                                    setEnquiry(false)
                                    setCmspage(false)
                                    setDownloads(false)
                                    setPressrelease(false)
                                    setNews(false)
                                    setAdminuser(false)
                                    setModule(false)
                                }
                                else {
                                    setCvbooster(!cvbooster)
                                }
                            }}>
                                <i className="bx bx-dock-top"></i>
                                <span key="t-contacts">CV Booster Packages</span>
                            </a>
                            {
                                cvbooster ?
                                    <ul className="sub-menu" aria-expanded="false">
                                        <li className={pathname == constant.component ? 'mm-active' : ''}>
                                            <Link to="/dashboard" key="t-user-grid">Subscribed Candidates</Link>
                                        </li>
                                    </ul> : ""
                            }
                        </li> */}

                        {/* <li>
                            <a className={resumesample ? "has-arrow waves-effect mm-active" : "has-arrow waves-effect"} onClick={() => {
                                if (resumesample === false) {
                                    setEmployer(false)
                                    setCvsearch(false)
                                    setHrms(false)
                                    setJobs(false)
                                    setPremiumjobs(false)
                                    setFeaturedjobs(false)
                                    setGovtjobs(false)
                                    setJobcandidate(false)
                                    setJobapplication(false)
                                    setCandidate(false)
                                    setCvbooster(false)
                                    setResumesample(!resumesample)
                                    setJobposting(false)
                                    setResumesearch(false)
                                    setproFilebooster(false)
                                    setAdsmanagement(false)
                                    setCityads(false)
                                    setAstrologyservices(false)
                                    setAstrologyfaq(false)
                                    setStudy(false)
                                    setSkills(false)
                                    setDesignation(false)
                                    setSalary(false)
                                    setJobcategory(false)
                                    setJobtype(false)
                                    setGovtdepartment(false)
                                    setGovtresult(false)
                                    setinterviewQuestion(false)
                                    setPracticequestion(false)
                                    setBlog(false)
                                    setArticle(false)
                                    setFaq(false)
                                    setTestimonial(false)
                                    setDiscussion(false)
                                    setCountry(false)
                                    setEnquiry(false)
                                    setCmspage(false)
                                    setDownloads(false)
                                    setPressrelease(false)
                                    setNews(false)
                                    setAdminuser(false)
                                    setModule(false)
                                }
                                else {
                                    setResumesample(!resumesample)
                                }
                            }}>
                                <i className="bx bx-card"></i>
                                <span key="t-contacts">Resume Samples</span>
                            </a>
                            {
                                resumesample ?
                                    <ul className="sub-menu" aria-expanded="false">
                                        <li className={pathname == constant.component ? 'mm-active' : ''}>
                                            <Link to="/dashboard" key="t-user-grid">Add Resume Samples List</Link>
                                        </li>
                                        <li className={pathname == constant.component ? 'mm-active' : ''}>
                                            <Link to="/dashboard" key="t-user-grid">Resume Samples List</Link>
                                        </li>
                                    </ul> : ""
                            }
                        </li> */}

                        <li className=''>
                            <Link to={constant.component.jobAlert.url} className="waves-effect">
                                <i class="bx bx-bell "></i><span className="badge rounded-pill bg-danger float-end">{JobAlertCount}</span>
                                <span key="t-dashboards">Job Alert List</span>
                            </Link>
                        </li>
                        <li className="menu-title" >Campus</li>

                        <li>
                            <a className={campusUniversity ? "has-arrow waves-effect mm-active" : "has-arrow waves-effect"} onClick={() => {
                                if (campusUniversity === false) {
                                    setCampusUniversity(!campusUniversity)
                                    setEmployer(false)
                                    setCvsearch(false)
                                    setHrms(false)
                                    setJobs(false)
                                    setPremiumjobs(false)
                                    setFeaturedjobs(false)
                                    setGovtjobs(false)
                                    setJobcandidate(false)
                                    setJobapplication(false)
                                    setCandidate(false)
                                    setCvbooster(false)
                                    setResumesample(false)
                                    setJobposting(false)
                                    setResumesearch(false)
                                    setproFilebooster(false)
                                    setAdsmanagement(false)
                                    setCityads(false)
                                    setAstrologyservices(false)
                                    setAstrologyfaq(false)
                                    setStudy(false)
                                    setSkills(false)
                                    setDesignation(false)
                                    setSalary(false)
                                    setJobcategory(false)
                                    setJobtype(false)
                                    setGovtdepartment(false)
                                    setGovtresult(false)
                                    setinterviewQuestion(false)
                                    setPracticequestion(false)
                                    setBlog(false)
                                    setArticle(false)
                                    setFaq(false)
                                    setTestimonial(false)
                                    setDiscussion(false)
                                    setCountry(false)
                                    setEnquiry(false)
                                    setCmspage(false)
                                    setDownloads(false)
                                    setPressrelease(false)
                                    setNews(false)
                                    setAdminuser(false)
                                    setModule(false)
                                }
                                else {
                                    setCampusUniversity(!campusUniversity)
                                }
                            }}>
                                <i className="bx bxs-graduation"></i>
                                <span key="t-contacts">University</span>
                            </a>
                            {
                                campusUniversity ?
                                    <ul className="sub-menu" aria-expanded="false">
                                        <li className={pathname == constant.component.addUniversity.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.addUniversity.url} >Add University</Link>
                                        </li>
                                        <li className={pathname == constant.component.universityList.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.universityList.url} >University List</Link>
                                        </li>
                                        {/* <li className={pathname == constant.component.updateLatestNews.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.updateLatestNews.url} >Latest News and Updates</Link>
                                        </li> */}

                                    </ul> : ""
                            }

                        </li>

                        <li>
                            <a className={campusUniversityNewsAndAnnouncement ? "has-arrow waves-effect mm-active" : "has-arrow waves-effect"} onClick={() => {
                                if (campusUniversity === false) {
                                    setCampusUniversityNewsAndAnnouncement(!campusUniversityNewsAndAnnouncement)

                                    setEmployer(false)
                                    setCvsearch(false)
                                    setHrms(false)
                                    setJobs(false)
                                    setPremiumjobs(false)
                                    setFeaturedjobs(false)
                                    setGovtjobs(false)
                                    setJobcandidate(false)
                                    setJobapplication(false)
                                    setCandidate(false)
                                    setCvbooster(false)
                                    setResumesample(false)
                                    setJobposting(false)
                                    setResumesearch(false)
                                    setproFilebooster(false)
                                    setAdsmanagement(false)
                                    setCityads(false)
                                    setAstrologyservices(false)
                                    setAstrologyfaq(false)
                                    setStudy(false)
                                    setSkills(false)
                                    setDesignation(false)
                                    setSalary(false)
                                    setJobcategory(false)
                                    setJobtype(false)
                                    setGovtdepartment(false)
                                    setGovtresult(false)
                                    setinterviewQuestion(false)
                                    setPracticequestion(false)
                                    setBlog(false)
                                    setArticle(false)
                                    setFaq(false)
                                    setTestimonial(false)
                                    setDiscussion(false)
                                    setCountry(false)
                                    setEnquiry(false)
                                    setCmspage(false)
                                    setDownloads(false)
                                    setPressrelease(false)
                                    setNews(false)
                                    setAdminuser(false)
                                    setModule(false)
                                }
                                else {
                                    setCampusUniversityNewsAndAnnouncement(!campusUniversityNewsAndAnnouncement)
                                }
                            }}>
                                <i className="fas fa-bullhorn"></i>
                                <span key="t-contacts">News and Announcements</span>
                            </a>
                            {
                                campusUniversityNewsAndAnnouncement ?
                                    <ul className="sub-menu" aria-expanded="false">
                                        <li className={pathname == constant.component.updateLatestNews.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.updateLatestNews.url} >Latest News and Updates</Link>
                                        </li>
                                        <li className={pathname == constant.component.addNewCampusAnnouncement.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.addNewCampusAnnouncement.url} >Add Announcement</Link>
                                        </li>
                                        <li className={pathname == constant.component.listCampusAnnouncement.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.listCampusAnnouncement.url} >Announcements List</Link>
                                        </li>

                                    </ul> : ""
                            }

                        </li>
                        <li>
                            <a className={campusCollege ? "has-arrow waves-effect mm-active" : "has-arrow waves-effect"} onClick={() => {
                                if (campusCollege === false) {
                                    setCampusCollege(!campusCollege)
                                    setCampusUniversity(false)
                                    setEmployer(false)
                                    setCvsearch(false)
                                    setHrms(false)
                                    setJobs(false)
                                    setPremiumjobs(false)
                                    setFeaturedjobs(false)
                                    setGovtjobs(false)
                                    setJobcandidate(false)
                                    setJobapplication(false)
                                    setCandidate(false)
                                    setCvbooster(false)
                                    setResumesample(false)
                                    setJobposting(false)
                                    setResumesearch(false)
                                    setproFilebooster(false)
                                    setAdsmanagement(false)
                                    setCityads(false)
                                    setAstrologyservices(false)
                                    setAstrologyfaq(false)
                                    setStudy(false)
                                    setSkills(false)
                                    setDesignation(false)
                                    setSalary(false)
                                    setJobcategory(false)
                                    setJobtype(false)
                                    setGovtdepartment(false)
                                    setGovtresult(false)
                                    setinterviewQuestion(false)
                                    setPracticequestion(false)
                                    setBlog(false)
                                    setArticle(false)
                                    setFaq(false)
                                    setTestimonial(false)
                                    setDiscussion(false)
                                    setCountry(false)
                                    setEnquiry(false)
                                    setCmspage(false)
                                    setDownloads(false)
                                    setPressrelease(false)
                                    setNews(false)
                                    setAdminuser(false)
                                    setModule(false)
                                }
                                else {
                                    setCampusCollege(!campusCollege)
                                }
                            }}>
                                <i className="bx bxs-graduation"></i>
                                <span key="t-contacts">College</span>
                            </a>
                            {
                                campusCollege ?
                                    <ul className="sub-menu" aria-expanded="false">
                                        <li className={pathname == constant.component.collegeList.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.collegeList.url} >College List</Link>
                                        </li>
                                        {/* <li className={pathname == constant.component.universityList.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.universityList.url} >University List</Link>
                                        </li>
                                        <li className={pathname == constant.component.updateLatestNews.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.updateLatestNews.url} >Latest News and Updates</Link>
                                        </li> */}

                                    </ul> : ""
                            }

                        </li>
                        <li className={pathname == constant.component.offCampusStudentList.url ? "mm-active" : ""}>
                            <Link to={constant.component.offCampusStudentList.url} className="waves-effect">
                                <i className="bx bxs-user-circle"></i>
                                <span key="t-dashboards">Off Campus Students</span>
                            </Link>
                        </li>

                        <li className="menu-title" key="t-components">PACKAGES</li>

                        <li>
                            <a className={jobposting ? "has-arrow waves-effect mm-active" : "has-arrow waves-effect"} onClick={() => {
                                if (jobposting === false) {
                                    setEmployer(false)
                                    setCvsearch(false)
                                    setHrms(false)
                                    setJobs(false)
                                    setPremiumjobs(false)
                                    setFeaturedjobs(false)
                                    setGovtjobs(false)
                                    setJobcandidate(false)
                                    setJobapplication(false)
                                    setCandidate(false)
                                    setCvbooster(false)
                                    setResumesample(false)
                                    setJobposting(!jobposting)
                                    setResumesearch(false)
                                    setproFilebooster(false)
                                    setAdsmanagement(false)
                                    setCityads(false)
                                    setAstrologyservices(false)
                                    setAstrologyfaq(false)
                                    setStudy(false)
                                    setSkills(false)
                                    setDesignation(false)
                                    setSalary(false)
                                    setJobcategory(false)
                                    setJobtype(false)
                                    setGovtdepartment(false)
                                    setGovtresult(false)
                                    setinterviewQuestion(false)
                                    setPracticequestion(false)
                                    setBlog(false)
                                    setArticle(false)
                                    setFaq(false)
                                    setTestimonial(false)
                                    setDiscussion(false)
                                    setCountry(false)
                                    setEnquiry(false)
                                    setCmspage(false)
                                    setDownloads(false)
                                    setPressrelease(false)
                                    setNews(false)
                                    setAdminuser(false)
                                    setModule(false)
                                }
                                else {
                                    setJobposting(!jobposting)
                                }
                            }}>
                                <i className="bx bxs-widget"></i>
                                <span key="t-contacts">Job Posting</span>

                            </a>
                            {
                                jobposting ?
                                    <ul className="sub-menu" aria-expanded="false">
                                        {/* <li className={pathname == constant.component ? 'mm-active' : ''}>
                                            <Link to="/dashboard" key="t-user-grid">Add Job Posting Packages</Link>
                                        </li> */}
                                        <li className={pathname == constant.component.jobPackageList.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.jobPackageList.url} key="t-user-grid">Job Posting Packages List</Link>
                                        </li>
                                    </ul> : ""
                            }
                        </li>



                        {/* <li>
                            <a className={resumesearch ? "has-arrow waves-effect mm-active" : "has-arrow waves-effect"} onClick={() => {
                                if (resumesearch === false) {
                                    setEmployer(false)
                                    setCvsearch(false)
                                    setHrms(false)
                                    setJobs(false)
                                    setPremiumjobs(false)
                                    setFeaturedjobs(false)
                                    setGovtjobs(false)
                                    setJobcandidate(false)
                                    setJobapplication(false)
                                    setCandidate(false)
                                    setCvbooster(false)
                                    setResumesample(false)
                                    setJobposting(false)
                                    setResumesearch(!resumesearch)
                                    setproFilebooster(false)
                                    setAdsmanagement(false)
                                    setCityads(false)
                                    setAstrologyservices(false)
                                    setAstrologyfaq(false)
                                    setStudy(false)
                                    setSkills(false)
                                    setDesignation(false)
                                    setSalary(false)
                                    setJobcategory(false)
                                    setJobtype(false)
                                    setGovtdepartment(false)
                                    setinterviewQuestion(false)
                                    setPracticequestion(false)
                                    setBlog(false)
                                    setArticle(false)
                                    setFaq(false)
                                    setTestimonial(false)
                                    setDiscussion(false)
                                    setCountry(false)
                                    setEnquiry(false)
                                    setCmspage(false)
                                    setDownloads(false)
                                    setPressrelease(false)
                                    setNews(false)
                                    setAdminuser(false)
                                    setModule(false)
                                }
                                else {
                                    setResumesearch(!resumesearch)
                                }
                            }}>
                                <i className="bx bx-search-alt"></i>
                                <span key="t-contacts">Resume Search</span>
                            </a>
                            {
                                resumesearch ?
                                    <ul className="sub-menu" aria-expanded="false">
                                        <li className={pathname == constant.component ? 'mm-active' : ''}>
                                            <Link to="/dashboard" key="t-user-grid">Add Packages</Link>
                                        </li>
                                        <li className={pathname == constant.component ? 'mm-active' : ''}>
                                            <Link to="/dashboard" key="t-user-grid">Packages List</Link>
                                        </li>
                                    </ul> : ""
                            }
                        </li> */}

                        {/* <li>
                            <a className={profilebooster ? "has-arrow waves-effect mm-active" : "has-arrow waves-effect"} onClick={() => {
                                if (profilebooster === false) {
                                    setEmployer(false)
                                    setCvsearch(false)
                                    setHrms(false)
                                    setJobs(false)
                                    setPremiumjobs(false)
                                    setFeaturedjobs(false)
                                    setGovtjobs(false)
                                    setJobcandidate(false)
                                    setJobapplication(false)
                                    setCandidate(false)
                                    setCvbooster(false)
                                    setResumesample(false)
                                    setJobposting(false)
                                    setResumesearch(false)
                                    setproFilebooster(!profilebooster)
                                    setAdsmanagement(false)
                                    setCityads(false)
                                    setAstrologyservices(false)
                                    setAstrologyfaq(false)
                                    setStudy(false)
                                    setSkills(false)
                                    setDesignation(false)
                                    setSalary(false)
                                    setJobcategory(false)
                                    setJobtype(false)
                                    setGovtdepartment(false)
                                    setinterviewQuestion(false)
                                    setPracticequestion(false)
                                    setBlog(false)
                                    setArticle(false)
                                    setFaq(false)
                                    setTestimonial(false)
                                    setDiscussion(false)
                                    setCountry(false)
                                    setEnquiry(false)
                                    setCmspage(false)
                                    setDownloads(false)
                                    setPressrelease(false)
                                    setNews(false)
                                    setAdminuser(false)
                                    setModule(false)
                                }
                                else {
                                    setproFilebooster(!profilebooster)
                                }
                            }}>
                                <i className="bx bx-happy"></i>
                                <span key="t-contacts">Profile Booster</span>
                            </a>
                            {
                                profilebooster ?
                                    <ul className="sub-menu" aria-expanded="false">
                                        <li className={pathname == constant.component ? 'mm-active' : ''}>
                                            <Link to="/dashboard" key="t-user-grid">Add Packages</Link>
                                        </li>
                                        <li className={pathname == constant.component ? 'mm-active' : ''}>
                                            <Link to="/dashboard" key="t-user-grid">Packages List</Link>
                                        </li>
                                    </ul> : ""
                            }
                        </li> */}

                        <li>
                            <a className={subscription ? "has-arrow waves-effect mm-active" : "has-arrow waves-effect"} onClick={() => {
                                if (subscription === false) {
                                    setEmployer(false)
                                    setCvsearch(false)
                                    setHrms(false)
                                    setJobs(false)
                                    setPremiumjobs(false)
                                    setFeaturedjobs(false)
                                    setGovtjobs(false)
                                    setJobcandidate(false)
                                    setJobapplication(false)
                                    setCandidate(false)
                                    setCvbooster(false)
                                    setResumesample(false)
                                    setJobposting(false)
                                    setResumesearch(false)
                                    setproFilebooster(false)
                                    SetSubscription(!subscription)
                                    setAdsmanagement(false)
                                    setCityads(false)
                                    setAstrologyservices(false)
                                    setAstrologyfaq(false)
                                    setStudy(false)
                                    setSkills(false)
                                    setDesignation(false)
                                    setSalary(false)
                                    setJobcategory(false)
                                    setJobtype(false)
                                    setGovtdepartment(false)
                                    setGovtresult(false)
                                    setinterviewQuestion(false)
                                    setPracticequestion(false)
                                    setBlog(false)
                                    setArticle(false)
                                    setFaq(false)
                                    setTestimonial(false)
                                    setDiscussion(false)
                                    setCountry(false)
                                    setEnquiry(false)
                                    setCmspage(false)
                                    setDownloads(false)
                                    setPressrelease(false)
                                    setNews(false)
                                    setAdminuser(false)
                                    setModule(false)
                                }
                                else {
                                    SetSubscription(!subscription)
                                }
                            }}>
                                <i className="mdi mdi-youtube-subscription"></i>
                                <span key="t-contacts">Subscription Packages</span>
                            </a>
                            {
                                subscription ?
                                    <ul className="sub-menu" aria-expanded="false">
                                        <li className={pathname == constant.component.addSubscription.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.addSubscription.url}>Add Packages</Link>
                                        </li>
                                        <li className={pathname == constant.component.SubscriptionList.url ? 'mm-active' : ''}>

                                            <Link to={constant.component.SubscriptionList.url}>Packages List</Link>
                                        </li>
                                    </ul> : ""
                            }
                        </li>

                        <li className="menu-title" key="t-components">DISCOUNT AND COUPONS</li>
                        <li>
                            <a className={discountAndcoupons ? "has-arrow waves-effect mm-active" : "has-arrow waves-effect"} onClick={() => {
                                if (discountAndcoupons === false) {
                                    setEmployer(false)
                                    setCvsearch(false)
                                    setHrms(false)
                                    setJobs(false)
                                    setPremiumjobs(false)
                                    setFeaturedjobs(false)
                                    setGovtjobs(false)
                                    setJobcandidate(false)
                                    setJobapplication(false)
                                    setCandidate(false)
                                    setCvbooster(false)
                                    setResumesample(false)
                                    setJobposting(false)
                                    setResumesearch(false)
                                    setproFilebooster(false)
                                    setAdsmanagement(false)
                                    setDiscountAndcoupons(!discountAndcoupons)
                                    setCityads(false)
                                    setAstrologyservices(false)
                                    setAstrologyfaq(false)
                                    setStudy(false)
                                    setSkills(false)
                                    setDesignation(false)
                                    setSalary(false)
                                    setJobcategory(false)
                                    setJobtype(false)
                                    setGovtdepartment(false)
                                    setGovtresult(false)
                                    setinterviewQuestion(false)
                                    setPracticequestion(false)
                                    setBlog(false)
                                    setArticle(false)
                                    setFaq(false)
                                    setTestimonial(false)
                                    setDiscussion(false)
                                    setCountry(false)
                                    setEnquiry(false)
                                    setCmspage(false)
                                    setDownloads(false)
                                    setPressrelease(false)
                                    setNews(false)
                                    setAdminuser(false)
                                    setModule(false)
                                }
                                else {
                                    setDiscountAndcoupons(!discountAndcoupons)
                                }
                            }}>
                                <i className="bx bx-book-content"></i>
                                <span key="t-contacts">Discount and Coupons</span>
                            </a>
                            {
                                discountAndcoupons ?
                                    <ul className="sub-menu" aria-expanded="false">
                                        <li className={pathname == constant.component ? 'mm-active' : ''}>
                                            <Link to="/discount-and-coupons" key="t-user-grid">Add Discount and Coupons</Link>
                                        </li>
                                        <li className={pathname == constant.component ? 'mm-active' : ''}>
                                            <Link to="/discount-and-coupons-list" key="t-user-grid">Discount and Coupons List</Link>
                                        </li>

                                    </ul> : ""
                            }
                        </li>

                        <li className="menu-title" key="t-components">ADVERTISEMENT</li>


                        <li>
                            <a className={banner ? "has-arrow waves-effect mm-active" : "has-arrow waves-effect"} onClick={() => {
                                if (banner === false) {
                                    setEmployer(false)
                                    setCvsearch(false)
                                    setHrms(false)
                                    setJobs(false)
                                    setPremiumjobs(false)
                                    setFeaturedjobs(false)
                                    setGovtjobs(false)
                                    setJobcandidate(false)
                                    setJobapplication(false)
                                    setCandidate(false)
                                    setCvbooster(false)
                                    setResumesample(false)
                                    setJobposting(false)
                                    setResumesearch(false)
                                    setproFilebooster(false)
                                    setAdsmanagement(false)
                                    setCityads(false)
                                    setAstrologyservices(false)
                                    setAstrologyfaq(false)
                                    setStudy(false)
                                    setSkills(false)
                                    setDesignation(false)
                                    setSalary(false)
                                    setJobcategory(false)
                                    setJobtype(false)
                                    setGovtdepartment(false)
                                    setGovtresult(false)
                                    setinterviewQuestion(false)
                                    setPracticequestion(false)
                                    setBlog(false)
                                    setArticle(false)
                                    setFaq(false)
                                    setTestimonial(false)
                                    setDiscussion(false)
                                    setCountry(false)
                                    setEnquiry(false)
                                    setCmspage(false)
                                    setDownloads(false)
                                    setPressrelease(false)
                                    setNews(false)
                                    setAdminuser(false)
                                    setModule(false)
                                    SetBanner(!banner)

                                }
                                else {
                                    SetBanner(!banner)
                                }
                            }}>
                                <i className="fa fa-paint-brush" style={{ fontSize: "18px" }}></i>
                                <span key="t-contacts">Banner</span>
                            </a>
                            {
                                banner ?
                                    <ul className="sub-menu" aria-expanded="false">
                                        <li className={pathname == constant.component.addbanner.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.addbanner.url} key="t-user-grid">Add Banner</Link>
                                        </li>
                                        <li className={pathname == constant.component.bannerlist.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.bannerlist.url} key="t-user-grid">Banner List</Link>
                                        </li>
                                        <li className={pathname == constant.component.addthirdpartybanner.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.addthirdpartybanner.url} key="t-user-grid">Add Third Party Banner</Link>
                                        </li>

                                        {/* <li className={pathname == constant.component.editbanner.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.editbanner.url} key="t-user-grid">Edit Banner</Link>
                                        </li> */}
                                    </ul> : ""
                            }
                        </li>


                        {/* <li>
                            <a className={adsmanagement ? "has-arrow waves-effect mm-active" : "has-arrow waves-effect"} onClick={() => {
                                if (adsmanagement === false) {
                                    setEmployer(false)
                                    setCvsearch(false)
                                    setHrms(false)
                                    setJobs(false)
                                    setPremiumjobs(false)
                                    setFeaturedjobs(false)
                                    setGovtjobs(false)
                                    setJobcandidate(false)
                                    setJobapplication(false)
                                    setCandidate(false)
                                    setCvbooster(false)
                                    setResumesample(false)
                                    setJobposting(false)
                                    setResumesearch(false)
                                    setproFilebooster(false)
                                    setAdsmanagement(!adsmanagement)
                                    setCityads(false)
                                    setAstrologyservices(false)
                                    setAstrologyfaq(false)
                                    setStudy(false)
                                    setSkills(false)
                                    setDesignation(false)
                                    setSalary(false)
                                    setJobcategory(false)
                                    setJobtype(false)
                                    setGovtdepartment(false)
                                    setGovtresult(false)
                                    setinterviewQuestion(false)
                                    setPracticequestion(false)
                                    setBlog(false)
                                    setArticle(false)
                                    setFaq(false)
                                    setTestimonial(false)
                                    setDiscussion(false)
                                    setCountry(false)
                                    setEnquiry(false)
                                    setCmspage(false)
                                    setDownloads(false)
                                    setPressrelease(false)
                                    setNews(false)
                                    setAdminuser(false)
                                    setModule(false)
                                }
                                else {
                                    setAdsmanagement(!adsmanagement)
                                }
                            }}>
                                <i className="bx bx-book-content"></i>
                                <span key="t-contacts">Ads Management</span>
                            </a>
                            {
                                adsmanagement ?
                                    <ul className="sub-menu" aria-expanded="false">
                                        <li className={pathname == constant.component ? 'mm-active' : ''}>
                                            <Link to="/dashboard" key="t-user-grid">Active Ads</Link>
                                        </li>
                                        <li className={pathname == constant.component ? 'mm-active' : ''}>
                                            <Link to="/dashboard" key="t-user-grid">Transaction History</Link>
                                        </li>
                                    </ul> : ""
                            }
                        </li> */}

                        {/* <li>
                            <a className={cityads ? "has-arrow waves-effect mm-active" : "has-arrow waves-effect"} onClick={() => {
                                if (cityads === false) {
                                    setEmployer(false)
                                    setCvsearch(false)
                                    setHrms(false)
                                    setJobs(false)
                                    setPremiumjobs(false)
                                    setFeaturedjobs(false)
                                    setGovtjobs(false)
                                    setJobcandidate(false)
                                    setJobapplication(false)
                                    setCandidate(false)
                                    setCvbooster(false)
                                    setResumesample(false)
                                    setJobposting(false)
                                    setResumesearch(false)
                                    setproFilebooster(false)
                                    setAdsmanagement(false)
                                    setCityads(!cityads)
                                    setAstrologyservices(false)
                                    setAstrologyfaq(false)
                                    setStudy(false)
                                    setSkills(false)
                                    setDesignation(false)
                                    setSalary(false)
                                    setJobcategory(false)
                                    setJobtype(false)
                                    setGovtdepartment(false)
                                    setGovtresult(false)
                                    setinterviewQuestion(false)
                                    setPracticequestion(false)
                                    setBlog(false)
                                    setArticle(false)
                                    setFaq(false)
                                    setTestimonial(false)
                                    setDiscussion(false)
                                    setCountry(false)
                                    setEnquiry(false)
                                    setCmspage(false)
                                    setDownloads(false)
                                    setPressrelease(false)
                                    setNews(false)
                                    setAdminuser(false)
                                    setModule(false)
                                }
                                else {
                                    setCityads(!cityads)
                                }
                            }}>
                                <i className="bx bxs-city"></i>
                                <span key="t-contacts">City Wise Ads</span>
                            </a>
                            {
                                cityads ?
                                    <ul className="sub-menu" aria-expanded="false">
                                        <li className={pathname == constant.component ? 'mm-active' : ''}>
                                            <Link to="/dashboard" key="t-user-grid">City Wise Ads List</Link>
                                        </li>
                                    </ul> : ""
                            }
                        </li> */}

                        {/* <li className="menu-title" key="t-components">ASTROLOGY SERVICES </li> */}

                        {/* <li>
                            <a className={astrologyservices ? "has-arrow waves-effect mm-active" : "has-arrow waves-effect"} onClick={() => {
                                if (astrologyservices === false) {
                                    setEmployer(false)
                                    setCvsearch(false)
                                    setHrms(false)
                                    setJobs(false)
                                    setPremiumjobs(false)
                                    setFeaturedjobs(false)
                                    setGovtjobs(false)
                                    setJobcandidate(false)
                                    setJobapplication(false)
                                    setCandidate(false)
                                    setCvbooster(false)
                                    setResumesample(false)
                                    setJobposting(false)
                                    setResumesearch(false)
                                    setproFilebooster(false)
                                    setAdsmanagement(false)
                                    setCityads(false)
                                    setAstrologyservices(!astrologyservices)
                                    setAstrologyfaq(false)
                                    setStudy(false)
                                    setSkills(false)
                                    setDesignation(false)
                                    setSalary(false)
                                    setJobcategory(false)
                                    setJobtype(false)
                                    setGovtdepartment(false)
                                    setGovtresult(false)
                                    setinterviewQuestion(false)
                                    setPracticequestion(false)
                                    setBlog(false)
                                    setArticle(false)
                                    setFaq(false)
                                    setTestimonial(false)
                                    setDiscussion(false)
                                    setCountry(false)
                                    setEnquiry(false)
                                    setCmspage(false)
                                    setDownloads(false)
                                    setPressrelease(false)
                                    setNews(false)
                                    setAdminuser(false)
                                    setModule(false)
                                }
                                else {
                                    setAstrologyservices(!astrologyservices)
                                }
                            }}>
                                <i className="bx bx-cool"></i>
                                <span key="t-contacts">Astrology Services</span>
                            </a>
                            {
                                astrologyservices ?
                                    <ul className="sub-menu" aria-expanded="false">
                                        <li className={pathname == constant.component ? 'mm-active' : ''}>
                                            <Link to="/dashboard" key="t-user-grid">Add Astrology Services</Link>
                                        </li>
                                        <li className={pathname == constant.component ? 'mm-active' : ''}>
                                            <Link to="/dashboard" key="t-user-grid">Astrology Services List</Link>
                                        </li>
                                    </ul> : ""
                            }
                        </li> */}

                        {/* <li>
                            <a className={astrologyfaq ? "has-arrow waves-effect mm-active" : "has-arrow waves-effect"} onClick={() => {
                                if (astrologyfaq === false) {
                                    setEmployer(false)
                                    setCvsearch(false)
                                    setHrms(false)
                                    setJobs(false)
                                    setPremiumjobs(false)
                                    setFeaturedjobs(false)
                                    setGovtjobs(false)
                                    setJobcandidate(false)
                                    setJobapplication(false)
                                    setCandidate(false)
                                    setCvbooster(false)
                                    setResumesample(false)
                                    setJobposting(false)
                                    setResumesearch(false)
                                    setproFilebooster(false)
                                    setAdsmanagement(false)
                                    setCityads(false)
                                    setAstrologyservices(false)
                                    setAstrologyfaq(!astrologyfaq)
                                    setStudy(false)
                                    setSkills(false)
                                    setDesignation(false)
                                    setSalary(false)
                                    setJobcategory(false)
                                    setJobtype(false)
                                    setGovtdepartment(false)
                                    setGovtresult(false)
                                    setinterviewQuestion(false)
                                    setPracticequestion(false)
                                    setBlog(false)
                                    setArticle(false)
                                    setFaq(false)
                                    setTestimonial(false)
                                    setDiscussion(false)
                                    setCountry(false)
                                    setEnquiry(false)
                                    setCmspage(false)
                                    setDownloads(false)
                                    setPressrelease(false)
                                    setNews(false)
                                    setAdminuser(false)
                                    setModule(false)
                                }
                                else {
                                    setAstrologyfaq(!astrologyfaq)
                                }
                            }}>
                                <i className="bx bxs-help-circle"></i>
                                <span key="t-contacts">Astrology FAQ</span>
                            </a>
                            {
                                astrologyfaq ?
                                    <ul className="sub-menu" aria-expanded="false">
                                        <li className={pathname == constant.component ? 'mm-active' : ''}>
                                            <Link to="/dashboard" key="t-user-grid">Add Astrology FAQ</Link>
                                        </li>
                                        <li className={pathname == constant.component ? 'mm-active' : ''}>
                                            <Link to="/dashboard" key="t-user-grid">Astrology FAQ List</Link>
                                        </li>
                                    </ul> : ""
                            }
                        </li> */}

                        {/* <li className="menu-title" key="t-components">Study Abroad</li> */}



                        <li className="menu-title" key="t-components">Utilities</li>

                        <li>
                            <a className={skills ? "has-arrow waves-effect mm-active" : "has-arrow waves-effect"} onClick={() => {
                                if (skills === false) {
                                    setEmployer(false)
                                    setCvsearch(false)
                                    setHrms(false)
                                    setJobs(false)
                                    setPremiumjobs(false)
                                    setFeaturedjobs(false)
                                    setGovtjobs(false)
                                    setJobcandidate(false)
                                    setJobapplication(false)
                                    setCandidate(false)
                                    setCvbooster(false)
                                    setResumesample(false)
                                    setJobposting(false)
                                    setResumesearch(false)
                                    setproFilebooster(false)
                                    setAdsmanagement(false)
                                    setCityads(false)
                                    setAstrologyservices(false)
                                    setAstrologyfaq(false)
                                    setStudy(false)
                                    setSkills(!skills)
                                    setDesignation(false)
                                    setSalary(false)
                                    setJobcategory(false)
                                    setJobtype(false)
                                    setGovtdepartment(false)
                                    setGovtresult(false)
                                    setinterviewQuestion(false)
                                    setPracticequestion(false)
                                    setBlog(false)
                                    setArticle(false)
                                    setFaq(false)
                                    setTestimonial(false)
                                    setDiscussion(false)
                                    setCountry(false)
                                    setEnquiry(false)
                                    setCmspage(false)
                                    setDownloads(false)
                                    setPressrelease(false)
                                    setNews(false)
                                    setAdminuser(false)
                                    setModule(false)
                                }
                                else {
                                    setSkills(!skills)
                                }
                            }}>
                                <i className="bx bx-star"></i>
                                <span>Skills</span>
                            </a>
                            {
                                skills ?
                                    <ul className="sub-menu" aria-expanded="false">
                                        <li className={pathname == constant.component.addskills.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.addskills.url}>Add Skills</Link>
                                        </li>
                                        <li className={pathname == constant.component.skilllist.url || pathname == constant.component.editSkill.url.replace(':id', id) ? 'mm-active' : ''}>
                                            <Link to={constant.component.skilllist.url}>Skills List</Link>
                                        </li>
                                    </ul> : ""
                            }
                        </li>

                        <li>
                            <a className={designation ? "has-arrow waves-effect mm-active" : "has-arrow waves-effect"} onClick={() => {
                                if (designation === false) {
                                    setEmployer(false)
                                    setCvsearch(false)
                                    setHrms(false)
                                    setJobs(false)
                                    setPremiumjobs(false)
                                    setFeaturedjobs(false)
                                    setGovtjobs(false)
                                    setJobcandidate(false)
                                    setJobapplication(false)
                                    setCandidate(false)
                                    setCvbooster(false)
                                    setResumesample(false)
                                    setJobposting(false)
                                    setResumesearch(false)
                                    setproFilebooster(false)
                                    setAdsmanagement(false)
                                    setCityads(false)
                                    setAstrologyservices(false)
                                    setAstrologyfaq(false)
                                    setStudy(false)
                                    setSkills(false)
                                    setDesignation(!designation)
                                    setSalary(false)
                                    setJobcategory(false)
                                    setJobtype(false)
                                    setGovtdepartment(false)
                                    setGovtresult(false)
                                    setinterviewQuestion(false)
                                    setPracticequestion(false)
                                    setBlog(false)
                                    setArticle(false)
                                    setFaq(false)
                                    setTestimonial(false)
                                    setDiscussion(false)
                                    setCountry(false)
                                    setEnquiry(false)
                                    setCmspage(false)
                                    setDownloads(false)
                                    setPressrelease(false)
                                    setNews(false)
                                    setAdminuser(false)
                                    setModule(false)
                                }
                                else {
                                    setDesignation(!designation)
                                }
                            }}>
                                <i className="bx bx-bolt-circle"></i>
                                <span key="t-contacts">Designation</span>
                            </a>
                            {
                                designation ?
                                    <ul className="sub-menu" aria-expanded="false">
                                        <li className={pathname == constant.component.adddesignation.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.adddesignation.url} key="t-user-grid">Add Designation</Link>
                                        </li>
                                        <li className={pathname == constant.component.designationlist.url || pathname == constant.component.editDesignation.url.replace(':id', id) ? 'mm-active' : ''}>
                                            <Link to={constant.component.designationlist.url} key="t-user-grid">Designation List</Link>
                                        </li>
                                    </ul> : ""
                            }
                        </li>

                        <li>
                            <a className={salary ? "has-arrow waves-effect mm-active" : "has-arrow waves-effect"} onClick={() => {
                                if (salary === false) {
                                    setEmployer(false)
                                    setCvsearch(false)
                                    setHrms(false)
                                    setJobs(false)
                                    setPremiumjobs(false)
                                    setFeaturedjobs(false)
                                    setGovtjobs(false)
                                    setJobcandidate(false)
                                    setJobapplication(false)
                                    setCandidate(false)
                                    setCvbooster(false)
                                    setResumesample(false)
                                    setJobposting(false)
                                    setResumesearch(false)
                                    setproFilebooster(false)
                                    setAdsmanagement(false)
                                    setCityads(false)
                                    setAstrologyservices(false)
                                    setAstrologyfaq(false)
                                    setStudy(false)
                                    setSkills(false)
                                    setDesignation(false)
                                    setSalary(!salary)
                                    setJobcategory(false)
                                    setJobtype(false)
                                    setGovtdepartment(false)
                                    setGovtresult(false)
                                    setinterviewQuestion(false)
                                    setPracticequestion(false)
                                    setBlog(false)
                                    setArticle(false)
                                    setFaq(false)
                                    setTestimonial(false)
                                    setDiscussion(false)
                                    setCountry(false)
                                    setEnquiry(false)
                                    setCmspage(false)
                                    setDownloads(false)
                                    setPressrelease(false)
                                    setNews(false)
                                    setAdminuser(false)
                                    setModule(false)
                                }
                                else {
                                    setSalary(!salary)
                                }
                            }}>
                                <i className="bx bx-rupee"></i>
                                <span key="t-contacts">Salary</span>
                            </a>
                            {
                                salary ?
                                    <ul className="sub-menu" aria-expanded="false">
                                        {/* // <li className={pathname == constant.component.addsalary.url ? 'mm-active' : ''}>
                                        //     <Link to={constant.component.addsalary.url} key="t-user-grid">Add Salary</Link>
                                        // </li> */}
                                        <li className={pathname == constant.component.salarylist.url || pathname == constant.component.editSalary.url.replace(':id', id) ? 'mm-active' : ''}>
                                            <Link to={constant.component.salarylist.url} key="t-user-grid">Salary List</Link>
                                        </li>
                                    </ul> : ""
                            }
                        </li>

                        <li>
                            <a className={jobcategory ? "has-arrow waves-effect mm-active" : "has-arrow waves-effect"} onClick={() => {
                                if (jobcategory === false) {
                                    setEmployer(false)
                                    setCvsearch(false)
                                    setHrms(false)
                                    setJobs(false)
                                    setPremiumjobs(false)
                                    setFeaturedjobs(false)
                                    setGovtjobs(false)
                                    setJobcandidate(false)
                                    setJobapplication(false)
                                    setCandidate(false)
                                    setCvbooster(false)
                                    setResumesample(false)
                                    setJobposting(false)
                                    setResumesearch(false)
                                    setproFilebooster(false)
                                    setAdsmanagement(false)
                                    setCityads(false)
                                    setAstrologyservices(false)
                                    setAstrologyfaq(false)
                                    setStudy(false)
                                    setSkills(false)
                                    setDesignation(false)
                                    setSalary(false)
                                    setJobcategory(!jobcategory)
                                    setJobtype(false)
                                    setGovtdepartment(false)
                                    setGovtresult(false)
                                    setinterviewQuestion(false)
                                    setPracticequestion(false)
                                    setBlog(false)
                                    setArticle(false)
                                    setFaq(false)
                                    setTestimonial(false)
                                    setDiscussion(false)
                                    setCountry(false)
                                    setEnquiry(false)
                                    setCmspage(false)
                                    setDownloads(false)
                                    setPressrelease(false)
                                    setNews(false)
                                    setAdminuser(false)
                                    setModule(false)
                                }
                                else {
                                    setJobcategory(!jobcategory)
                                }
                            }}>
                                <i className="bx bx-dialpad-alt"></i>
                                <span key="t-contacts">Job Category</span>
                            </a>
                            {
                                jobcategory ?
                                    <ul className="sub-menu" aria-expanded="false">
                                        <li className={pathname == constant.component.addjobcategory.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.addjobcategory.url} key="t-user-grid">Add Job Category</Link>
                                        </li>
                                        <li className={pathname == constant.component.jobcategorylist.url || pathname == constant.component.editjobcategory.url.replace(':id', id) ? 'mm-active' : ''}>
                                            <Link to={constant.component.jobcategorylist.url} key="t-user-grid">Job Category List</Link>
                                        </li>
                                    </ul> : ""
                            }
                        </li>


                        <li>
                            <a className={jobtype ? "has-arrow waves-effect mm-active" : "has-arrow waves-effect"} onClick={() => {
                                if (jobtype === false) {
                                    setEmployer(false)
                                    setCvsearch(false)
                                    setHrms(false)
                                    setJobs(false)
                                    setPremiumjobs(false)
                                    setFeaturedjobs(false)
                                    setGovtjobs(false)
                                    setJobcandidate(false)
                                    setJobapplication(false)
                                    setCandidate(false)
                                    setCvbooster(false)
                                    setResumesample(false)
                                    setJobposting(false)
                                    setResumesearch(false)
                                    setproFilebooster(false)
                                    setAdsmanagement(false)
                                    setCityads(false)
                                    setAstrologyservices(false)
                                    setAstrologyfaq(false)
                                    setStudy(false)
                                    setSkills(false)
                                    setDesignation(false)
                                    setSalary(false)
                                    setJobcategory(false)
                                    setJobtype(!jobtype)
                                    setGovtdepartment(false)
                                    setGovtresult(false)
                                    setinterviewQuestion(false)
                                    setPracticequestion(false)
                                    setBlog(false)
                                    setArticle(false)
                                    setFaq(false)
                                    setTestimonial(false)
                                    setDiscussion(false)
                                    setCountry(false)
                                    setEnquiry(false)
                                    setCmspage(false)
                                    setDownloads(false)
                                    setPressrelease(false)
                                    setNews(false)
                                    setAdminuser(false)
                                    setModule(false)
                                }
                                else {
                                    setJobtype(!jobtype)
                                }
                            }}>
                                <i className="bx bxs-notepad"></i>
                                <span key="t-contacts">Jobs Type</span>
                            </a>
                            {
                                jobtype ?
                                    <ul className="sub-menu" aria-expanded="false">
                                        <li className={pathname == constant.component.addjobtype.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.addjobtype.url} key="t-user-grid">Add Jobs Type</Link>
                                        </li>
                                        <li className={pathname == constant.component.jobtypelist || pathname == constant.component.jobtypelist.url.replace(':id', id) ? 'mm-active' : ''}>
                                            <Link to={constant.component.jobtypelist.url} key="t-user-grid">Jobs Type List</Link>
                                        </li>
                                    </ul> : ""
                            }
                        </li>




                        <li>
                            <a className={interviewquestion ? "has-arrow waves-effect mm-active" : "has-arrow waves-effect"} onClick={() => {
                                if (interviewquestion === false) {
                                    setEmployer(false)
                                    setCvsearch(false)
                                    setHrms(false)
                                    setJobs(false)
                                    setPremiumjobs(false)
                                    setFeaturedjobs(false)
                                    setGovtjobs(false)
                                    setJobcandidate(false)
                                    setJobapplication(false)
                                    setCandidate(false)
                                    setCvbooster(false)
                                    setResumesample(false)
                                    setJobposting(false)
                                    setResumesearch(false)
                                    setproFilebooster(false)
                                    setAdsmanagement(false)
                                    setCityads(false)
                                    setAstrologyservices(false)
                                    setAstrologyfaq(false)
                                    setStudy(false)
                                    setSkills(false)
                                    setDesignation(false)
                                    setSalary(false)
                                    setJobcategory(false)
                                    setJobtype(false)
                                    setGovtdepartment(false)
                                    setGovtresult(false)
                                    setinterviewQuestion(!interviewquestion)
                                    setPracticequestion(false)
                                    setBlog(false)
                                    setArticle(false)
                                    setFaq(false)
                                    setTestimonial(false)
                                    setDiscussion(false)
                                    setCountry(false)
                                    setEnquiry(false)
                                    setCmspage(false)
                                    setDownloads(false)
                                    setPressrelease(false)
                                    setNews(false)
                                    setAdminuser(false)
                                    setModule(false)
                                }
                                else {
                                    setinterviewQuestion(!interviewquestion)
                                }
                            }}>
                                <i className="bx bx-user-voice"></i>
                                <span key="t-contacts">Interview Questions</span>
                            </a>
                            {
                                interviewquestion ?
                                    <ul className="sub-menu" aria-expanded="false">
                                        <li className={pathname == constant.component.addInterviewQuestion.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.addInterviewQuestion.url} key="t-user-grid">Add Interview Questions</Link>
                                        </li>
                                        <li className={pathname == constant.component.interviewQuestionList.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.interviewQuestionList.url} key="t-user-grid">Interview Questions List</Link>
                                        </li>
                                        <li className={pathname == constant.component.shareInterviewQuestion.url ? "mm-active" : ""}>
                                            <Link to={constant.component.shareInterviewQuestion.url} className="waves-effect">
                                                Shared Interview
                                            </Link>
                                        </li>
                                    </ul> : ""
                            }
                        </li>

                        <li>
                            <a className={practicequestion ? "has-arrow waves-effect mm-active" : "has-arrow waves-effect"} onClick={() => {
                                if (practicequestion === false) {
                                    setEmployer(false)
                                    setCvsearch(false)
                                    setHrms(false)
                                    setJobs(false)
                                    setPremiumjobs(false)
                                    setFeaturedjobs(false)
                                    setGovtjobs(false)
                                    setJobcandidate(false)
                                    setJobapplication(false)
                                    setCandidate(false)
                                    setCvbooster(false)
                                    setResumesample(false)
                                    setJobposting(false)
                                    setResumesearch(false)
                                    setproFilebooster(false)
                                    setAdsmanagement(false)
                                    setCityads(false)
                                    setAstrologyservices(false)
                                    setAstrologyfaq(false)
                                    setStudy(false)
                                    setSkills(false)
                                    setDesignation(false)
                                    setSalary(false)
                                    setJobcategory(false)
                                    setJobtype(false)
                                    setGovtdepartment(false)
                                    setGovtresult(false)
                                    setinterviewQuestion(false)
                                    setPracticequestion(!practicequestion)
                                    setBlog(false)
                                    setArticle(false)
                                    setFaq(false)
                                    setTestimonial(false)
                                    setDiscussion(false)
                                    setCountry(false)
                                    setEnquiry(false)
                                    setCmspage(false)
                                    setDownloads(false)
                                    setPressrelease(false)
                                    setNews(false)
                                    setAdminuser(false)
                                    setModule(false)
                                }
                                else {
                                    setPracticequestion(!practicequestion)
                                }
                            }}>
                                <i className="bx bx-test-tube"></i>
                                <span key="t-contacts">Practice Questions</span>
                            </a>
                            {
                                practicequestion ?
                                    <ul className="sub-menu" aria-expanded="false">
                                        {/* <li className={pathname == constant.component ? 'mm-active' : ''}>
                                            <Link to="/dashboard" key="t-user-grid">Add Questions Set</Link>
                                        </li>
                                        <li className={pathname == constant.component ? 'mm-active' : ''}>
                                            <Link to="/dashboard" key="t-user-grid">Questions Set List</Link>
                                        </li> */}
                                        <li className={pathname == constant.component.addPracticeQuestion.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.addPracticeQuestion.url} key="t-user-grid">Add Practice Questions</Link>
                                        </li>
                                        <li className={pathname == constant.component.practiceQuestionList.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.practiceQuestionList.url} key="t-user-grid">Practice Questions List</Link>
                                        </li>
                                    </ul> : ""
                            }
                        </li>

                        <li>
                            <a className={blog ? "has-arrow waves-effect mm-active" : "has-arrow waves-effect"} onClick={() => {
                                if (blog === false) {
                                    setEmployer(false)
                                    setCvsearch(false)
                                    setHrms(false)
                                    setJobs(false)
                                    setPremiumjobs(false)
                                    setFeaturedjobs(false)
                                    setGovtjobs(false)
                                    setJobcandidate(false)
                                    setJobapplication(false)
                                    setCandidate(false)
                                    setCvbooster(false)
                                    setResumesample(false)
                                    setJobposting(false)
                                    setResumesearch(false)
                                    setproFilebooster(false)
                                    setAdsmanagement(false)
                                    setCityads(false)
                                    setAstrologyservices(false)
                                    setAstrologyfaq(false)
                                    setStudy(false)
                                    setSkills(false)
                                    setDesignation(false)
                                    setSalary(false)
                                    setJobcategory(false)
                                    setJobtype(false)
                                    setGovtdepartment(false)
                                    setGovtresult(false)
                                    setinterviewQuestion(false)
                                    setPracticequestion(false)
                                    setBlog(!blog)
                                    setArticle(false)
                                    setFaq(false)
                                    setTestimonial(false)
                                    setDiscussion(false)
                                    setCountry(false)
                                    setEnquiry(false)
                                    setCmspage(false)
                                    setDownloads(false)
                                    setPressrelease(false)
                                    setNews(false)
                                    setAdminuser(false)
                                    setModule(false)
                                }
                                else {
                                    setBlog(!blog)
                                }
                            }}>
                                <i class="fas fa-blog"></i>
                                <span key="t-contacts">Blog</span>
                            </a>
                            {
                                blog ?
                                    <ul className="sub-menu" aria-expanded="false">
                                        <li className={pathname == constant.component.addblogcategory.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.addblogcategory.url} key="t-user-grid">Add Category</Link>
                                        </li>
                                        <li className={pathname == constant.component.blogcategorylist.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.blogcategorylist.url} key="t-user-grid">Category List</Link>
                                        </li>
                                        <li className={pathname == constant.component.addblog.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.addblog.url} key="t-user-grid">Add Blog</Link>
                                        </li>
                                        <li className={pathname == constant.component.bloglist.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.bloglist.url} key="t-user-grid">Blog List</Link>
                                        </li>
                                        <li className={pathname == constant.component.allCommentList.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.allCommentList.url} key="t-user-grid">Blog Comments</Link>
                                        </li>
                                    </ul> : ""
                            }
                        </li>
                        <li>
                            <a className={article ? "has-arrow waves-effect mm-active" : "has-arrow waves-effect"} onClick={() => {
                                if (article === false) {
                                    setEmployer(false)
                                    setCvsearch(false)
                                    setHrms(false)
                                    setJobs(false)
                                    setPremiumjobs(false)
                                    setFeaturedjobs(false)
                                    setGovtjobs(false)
                                    setJobcandidate(false)
                                    setJobapplication(false)
                                    setCandidate(false)
                                    setCvbooster(false)
                                    setResumesample(false)
                                    setJobposting(false)
                                    setResumesearch(false)
                                    setproFilebooster(false)
                                    setAdsmanagement(false)
                                    setCityads(false)
                                    setAstrologyservices(false)
                                    setAstrologyfaq(false)
                                    setStudy(false)
                                    setSkills(false)
                                    setDesignation(false)
                                    setSalary(false)
                                    setJobcategory(false)
                                    setJobtype(false)
                                    setGovtdepartment(false)
                                    setGovtresult(false)
                                    setinterviewQuestion(false)
                                    setPracticequestion(false)
                                    setBlog(false)
                                    setArticle(!article)
                                    setFaq(false)
                                    setTestimonial(false)
                                    setDiscussion(false)
                                    setCountry(false)
                                    setEnquiry(false)
                                    setCmspage(false)
                                    setDownloads(false)
                                    setPressrelease(false)
                                    setNews(false)
                                    setAdminuser(false)
                                    setModule(false)
                                }
                                else {
                                    setArticle(!article)
                                }
                            }}>
                                <i className="bx bx-notepad"></i>
                                <span key="t-contacts">Article</span>
                            </a>
                            {
                                article ?
                                    <ul className="sub-menu" aria-expanded="false">
                                        <li className={pathname == constant.component.articleAddCategory.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.articleAddCategory.url} key="t-user-grid">Add Category</Link>
                                        </li>
                                        <li className={pathname == constant.component.articleCategoryList.url || pathname == constant.component.editArticleCategory.url.replace(':id', id) ? 'mm-active' : ''}>
                                            <Link to={constant.component.articleCategoryList.url} key="t-user-grid">Category List</Link>
                                        </li>
                                        <li className={pathname == constant.component.addArticle.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.addArticle.url} key="t-user-grid">Add Article</Link>
                                        </li>
                                        <li className={pathname == constant.component.listArticle.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.listArticle.url} key="t-user-grid">Article List</Link>
                                        </li>
                                    </ul> : ""
                            }
                        </li>
                        <li>
                            <a className={faq ? "has-arrow waves-effect mm-active" : "has-arrow waves-effect"} onClick={() => {
                                debugger

                                if (faq === false) {
                                    setEmployer(false)
                                    setCvsearch(false)
                                    setHrms(false)
                                    setJobs(false)
                                    setPremiumjobs(false)
                                    setFeaturedjobs(false)
                                    setGovtjobs(false)
                                    setJobcandidate(false)
                                    setJobapplication(false)
                                    setCandidate(false)
                                    setCvbooster(false)
                                    setResumesample(false)
                                    setJobposting(false)
                                    setResumesearch(false)
                                    setproFilebooster(false)
                                    setAdsmanagement(false)
                                    setCityads(false)
                                    setAstrologyservices(false)
                                    setAstrologyfaq(false)
                                    setStudy(false)
                                    setSkills(false)
                                    setDesignation(false)
                                    setSalary(false)
                                    setJobcategory(false)
                                    setJobtype(false)
                                    setGovtdepartment(false)
                                    setGovtresult(false)
                                    setinterviewQuestion(false)
                                    setPracticequestion(false)
                                    setBlog(false)
                                    setArticle(false)
                                    setFaq(!faq)
                                    setTestimonial(false)
                                    setDiscussion(false)
                                    setCountry(false)
                                    setEnquiry(false)
                                    setCmspage(false)
                                    setDownloads(false)
                                    setPressrelease(false)
                                    setNews(false)
                                    setAdminuser(false)
                                    setModule(false)
                                }
                                else {
                                    setFaq(!faq)
                                }
                            }}>
                                <i className="bx bxs-help-circle"></i>
                                <span key="t-contacts">FAQs</span>
                            </a>
                            {
                                faq ?
                                    <ul className="sub-menu" aria-expanded="false">
                                        <li className={pathname == constant.component.faqaddcategory.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.faqaddcategory.url} key="t-user-grid">Add FAQ Category</Link>
                                        </li>
                                        <li className={pathname == constant.component.faqlistcategory.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.faqlistcategory.url} key="t-user-grid">FAQ Category List</Link>
                                        </li>
                                        <li className={pathname == constant.component.faqadd.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.faqadd.url} key="t-user-grid">Add FAQ</Link>
                                        </li>
                                        <li className={pathname == constant.component.faqlist.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.faqlist.url} key="t-user-grid">FAQ List</Link>
                                        </li>
                                    </ul> : ""
                            }
                        </li>
                        <li>
                            <a className={jobsFaq ? "has-arrow waves-effect mm-active" : "has-arrow waves-effect"} onClick={() => {
                                if (jobsFaq === false) {
                                    setEmployer(false)
                                    setCvsearch(false)
                                    setHrms(false)
                                    setJobs(false)
                                    setPremiumjobs(false)
                                    setFeaturedjobs(false)
                                    setGovtjobs(false)
                                    setJobcandidate(false)
                                    setJobapplication(false)
                                    setCandidate(false)
                                    setCvbooster(false)
                                    setResumesample(false)
                                    setJobposting(false)
                                    setResumesearch(false)
                                    setproFilebooster(false)
                                    setAdsmanagement(false)
                                    setCityads(false)
                                    setAstrologyservices(false)
                                    setAstrologyfaq(false)
                                    setStudy(false)
                                    setSkills(false)
                                    setDesignation(false)
                                    setSalary(false)
                                    setJobcategory(false)
                                    setJobtype(false)
                                    setGovtdepartment(false)
                                    setGovtresult(false)
                                    setinterviewQuestion(false)
                                    setPracticequestion(false)
                                    setBlog(false)
                                    setArticle(false)
                                    setFaq(false)
                                    setJobsFaq(!jobsFaq)

                                    setTestimonial(false)
                                    setDiscussion(false)
                                    setCountry(false)
                                    setEnquiry(false)
                                    setCmspage(false)
                                    setDownloads(false)
                                    setPressrelease(false)
                                    setNews(false)
                                    setAdminuser(false)
                                    setModule(false)
                                }
                                else {
                                    setJobsFaq(!jobsFaq)
                                }
                            }}>
                                <i className="bx bxs-help-circle"></i>
                                <span key="t-contacts">Job's FAQs</span>
                            </a>
                            {
                                jobsFaq ?
                                    <ul className="sub-menu" aria-expanded="false">
                                        <li className={pathname == constant.component.faqaddcategory.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.jobsfaqaddcategory.url} key="t-user-grid">Add FAQ Category</Link>
                                        </li>
                                        <li className={pathname == constant.component.faqlistcategory.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.jobsfaqlistcategory.url} key="t-user-grid">FAQ Category List</Link>
                                        </li>
                                        <li className={pathname == constant.component.faqadd.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.jobsfaqadd.url} key="t-user-grid">Add FAQ</Link>
                                        </li>
                                        <li className={pathname == constant.component.faqlist.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.jobsfaqlist.url} key="t-user-grid">FAQ List</Link>
                                        </li>
                                    </ul> : ""
                            }
                        </li>

                        <li>
                            <a className={testimonial ? "has-arrow waves-effect mm-active" : "has-arrow waves-effect"} onClick={() => {
                                if (testimonial === false) {
                                    setEmployer(false)
                                    setCvsearch(false)
                                    setHrms(false)
                                    setJobs(false)
                                    setPremiumjobs(false)
                                    setFeaturedjobs(false)
                                    setGovtjobs(false)
                                    setJobcandidate(false)
                                    setJobapplication(false)
                                    setCandidate(false)
                                    setCvbooster(false)
                                    setResumesample(false)
                                    setJobposting(false)
                                    setResumesearch(false)
                                    setproFilebooster(false)
                                    setAdsmanagement(false)
                                    setCityads(false)
                                    setAstrologyservices(false)
                                    setAstrologyfaq(false)
                                    setStudy(false)
                                    setSkills(false)
                                    setDesignation(false)
                                    setSalary(false)
                                    setJobcategory(false)
                                    setJobtype(false)
                                    setGovtdepartment(false)
                                    setGovtresult(false)
                                    setinterviewQuestion(false)
                                    setPracticequestion(false)
                                    setBlog(false)
                                    setArticle(false)
                                    setFaq(false)
                                    setTestimonial(!testimonial)
                                    setDiscussion(false)
                                    setCountry(false)
                                    setEnquiry(false)
                                    setCmspage(false)
                                    setDownloads(false)
                                    setPressrelease(false)
                                    setNews(false)
                                    setAdminuser(false)
                                    setModule(false)
                                }
                                else {
                                    setTestimonial(!testimonial)
                                }
                            }}>
                                <i className="bx bx bxs-chat"></i>
                                <span key="t-contacts">Testimonial</span>
                            </a>
                            {
                                testimonial ?
                                    <ul className="sub-menu" aria-expanded="false">
                                        <li className={pathname == constant.component.addTestimonial.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.addTestimonial.url} key="t-user-grid">Add Testimonial</Link>
                                        </li>
                                        <li className={pathname == constant.component.testimonialList.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.testimonialList.url} key="t-user-grid">Testimonial List</Link>
                                        </li>
                                    </ul> : ""
                            }
                        </li>
                        <li>
                            <a className={discussion ? "has-arrow waves-effect mm-active" : "has-arrow waves-effect"} onClick={() => {
                                if (discussion === false) {
                                    setEmployer(false)
                                    setCvsearch(false)
                                    setHrms(false)
                                    setJobs(false)
                                    setPremiumjobs(false)
                                    setFeaturedjobs(false)
                                    setGovtjobs(false)
                                    setJobcandidate(false)
                                    setJobapplication(false)
                                    setCandidate(false)
                                    setCvbooster(false)
                                    setResumesample(false)
                                    setJobposting(false)
                                    setResumesearch(false)
                                    setproFilebooster(false)
                                    setAdsmanagement(false)
                                    setCityads(false)
                                    setAstrologyservices(false)
                                    setAstrologyfaq(false)
                                    setStudy(false)
                                    setSkills(false)
                                    setDesignation(false)
                                    setSalary(false)
                                    setJobcategory(false)
                                    setJobtype(false)
                                    setGovtdepartment(false)
                                    setGovtresult(false)
                                    setinterviewQuestion(false)
                                    setPracticequestion(false)
                                    setBlog(false)
                                    setArticle(false)
                                    setFaq(false)
                                    setTestimonial(false)
                                    setDiscussion(!discussion)
                                    setCountry(false)
                                    setEnquiry(false)
                                    setCmspage(false)
                                    setDownloads(false)
                                    setPressrelease(false)
                                    setNews(false)
                                    setAdminuser(false)
                                    setModule(false)
                                }
                                else {
                                    setDiscussion(!discussion)
                                }
                            }}>
                                <i className="bx bxl-discourse"></i>
                                <span key="t-contacts">Discussion Forum</span>
                            </a>
                            {
                                discussion ?
                                    <ul className="sub-menu" aria-expanded="false">
                                        <li className={pathname == constant.component.Discussionforum.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.Discussionforum.url} key="t-user-grid">Add Forum Category</Link>
                                        </li>
                                        <li className={pathname == constant.component.DiscussionforumList.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.DiscussionforumList.url} key="t-user-grid">Forum Category List</Link>
                                        </li>
                                        <li className={pathname == constant.component.Discussionforumactivethread.url || pathname == constant.component.Discussionforumactivethread.url.replace(':id', id) ? 'mm-active' : ''}>
                                            <Link to={constant.component.Discussionforumactivethread.url} key="t-user-grid">List of Active Threads</Link></li>
                                    </ul> : ""
                            }
                        </li>

                        {/* <li>
                            <a className={calender ? "has-arrow waves-effect mm-active" : "has-arrow waves-effect"} onClick={() => {
                                if (calender === false) {
                                    setEmployer(false)
                                    setCvsearch(false)
                                    setHrms(false)
                                    setJobs(false)
                                    setPremiumjobs(false)
                                    setFeaturedjobs(false)
                                    setGovtjobs(false)
                                    setJobcandidate(false)
                                    setJobapplication(false)
                                    setCandidate(false)
                                    setCvbooster(false)
                                    setResumesample(false)
                                    setJobposting(false)
                                    setResumesearch(false)
                                    setproFilebooster(false)
                                    setAdsmanagement(false)
                                    setCityads(false)
                                    setAstrologyservices(false)
                                    setAstrologyfaq(false)
                                    setStudy(false)
                                    setSkills(false)
                                    setDesignation(false)
                                    setSalary(false)
                                    setJobcategory(false)
                                    setJobtype(false)
                                    setGovtdepartment(false)
                                    setGovtresult(false)
                                    setinterviewQuestion(false)
                                    setPracticequestion(false)
                                    setBlog(false)
                                    setArticle(false)
                                    setFaq(false)
                                    setTestimonial(false)
                                    setDiscussion(false)
                                    setCountry(false)
                                    setEnquiry(false)
                                    setCmspage(false)
                                    setDownloads(false)
                                    setPressrelease(false)
                                    setNews(false)
                                    setAdminuser(false)
                                    setModule(false)
                                }
                                else {
                                }
                            }}>
                                <i className="bx bx-calendar"></i>
                                <span key="t-dashboards">Calendars</span>
                            </a>
                            {
                                calender ?
                                    <ul className="sub-menu" aria-expanded="false">
                                        <li className={pathname == constant.component ? 'mm-active' : ''}>
                                            <Link to="/dashboard" key="t-user-grid">Manage your Calendar</Link>
                                        </li>
                                    </ul> : ""
                            }
                        </li> */}

                        <li>
                            <a className={country ? "has-arrow waves-effect mm-active" : "has-arrow waves-effect"} onClick={() => {
                                if (country === false) {
                                    setEmployer(false)
                                    setCvsearch(false)
                                    setHrms(false)
                                    setJobs(false)
                                    setPremiumjobs(false)
                                    setFeaturedjobs(false)
                                    setGovtjobs(false)
                                    setJobcandidate(false)
                                    setJobapplication(false)
                                    setCandidate(false)
                                    setCvbooster(false)
                                    setResumesample(false)
                                    setJobposting(false)
                                    setResumesearch(false)
                                    setproFilebooster(false)
                                    setAdsmanagement(false)
                                    setCityads(false)
                                    setAstrologyservices(false)
                                    setAstrologyfaq(false)
                                    setStudy(false)
                                    setSkills(false)
                                    setDesignation(false)
                                    setSalary(false)
                                    setJobcategory(false)
                                    setJobtype(false)
                                    setGovtdepartment(false)
                                    setGovtresult(false)
                                    setinterviewQuestion(false)
                                    setPracticequestion(false)
                                    setBlog(false)
                                    setArticle(false)
                                    setFaq(false)
                                    setTestimonial(false)
                                    setDiscussion(false)
                                    setCountry(!country)
                                    setEnquiry(false)
                                    setCmspage(false)
                                    setDownloads(false)
                                    setPressrelease(false)
                                    setNews(false)
                                    setAdminuser(false)
                                    setModule(false)
                                }
                                else {
                                    setCountry(!country)
                                }
                            }}>
                                <i className="bx bx-pin"></i>
                                <span key="t-dashboards">Country, State & City</span>
                            </a>
                            {
                                country ?
                                    <ul className="sub-menu" aria-expanded="false">


                                        <li className={pathname == constant.component.countryList.url ? 'mm-active' : ''}>

                                            <Link to={constant.component.countryList.url} key="t-user-grid">Countries List</Link>
                                        </li>
                                        <li className={pathname == constant.component.stateList.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.stateList.url} key="t-user-grid">States</Link>
                                        </li>
                                        <li className={pathname == constant.component.addCity.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.addCity.url} key="t-user-grid"> Add Cities</Link>
                                        </li>
                                        <li className={pathname == constant.component.cityList.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.cityList.url} key="t-user-grid">Cities</Link>
                                        </li>
                                        <li className={pathname == constant.component.premiumCityList.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.premiumCityList.url} key="t-user-grid">Premium Cities</Link>
                                        </li>
                                        <li className={pathname == constant.component.addLocality.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.addLocality.url} key="t-user-grid"> Add Localities</Link>
                                        </li>
                                        <li className={pathname == constant.component.localityList.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.localityList.url} key="t-user-grid">Localities</Link>
                                        </li>
                                    </ul> : ""
                            }
                        </li>

                        <li className="menu-title" key="t-components">OPERATION</li>

                        <li>
                            <a className={enquiry ? "has-arrow waves-effect mm-active" : "has-arrow waves-effect"} onClick={() => {
                                if (enquiry === false) {
                                    setEmployer(false)
                                    setCvsearch(false)
                                    setHrms(false)
                                    setJobs(false)
                                    setPremiumjobs(false)
                                    setFeaturedjobs(false)
                                    setGovtjobs(false)
                                    setJobcandidate(false)
                                    setJobapplication(false)
                                    setCandidate(false)
                                    setCvbooster(false)
                                    setResumesample(false)
                                    setJobposting(false)
                                    setResumesearch(false)
                                    setproFilebooster(false)
                                    setAdsmanagement(false)
                                    setCityads(false)
                                    setAstrologyservices(false)
                                    setAstrologyfaq(false)
                                    setStudy(false)
                                    setSkills(false)
                                    setDesignation(false)
                                    setSalary(false)
                                    setJobcategory(false)
                                    setJobtype(false)
                                    setGovtdepartment(false)
                                    setGovtresult(false)
                                    setinterviewQuestion(false)
                                    setPracticequestion(false)
                                    setBlog(false)
                                    setArticle(false)
                                    setFaq(false)
                                    setTestimonial(false)
                                    setDiscussion(false)
                                    setCountry(false)
                                    setEnquiry(!enquiry)
                                    setCmspage(false)
                                    setDownloads(false)
                                    setPressrelease(false)
                                    setNews(false)
                                    setAdminuser(false)
                                    setModule(false)
                                }
                                else {
                                    setEnquiry(!enquiry)
                                }
                            }}>
                                <i className="fa fa-question-circle" aria-hidden="true"></i>
                                <span key="t-contacts">Enquiries</span>
                            </a>
                            {
                                enquiry ?
                                    <ul className="sub-menu" aria-expanded="false">
                                        {/* <li className={pathname == constant.component.enquiryList.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.enquiryList.url} key="t-user-grid">Enquiries List</Link>
                                        </li> */}
                                        {/* <li className={pathname == constant.component.enquiryList.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.recruiterEnquiryList.url} key="t-user-grid">Recruiter Enquiries List</Link>
                                        </li> */}

                                        {/* <li className={pathname == constant.component.ComplaintList.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.ComplaintList.url} key="t-user-grid">Complaint</Link>
                                        </li> */}

                                        <li className={pathname == constant.component.salesEnqueryList.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.salesEnqueryList.url} key="t-user-grid">Sales Enquiries List</Link>
                                        </li>

                                        <li className={pathname == constant.component.UnderMaintainanceEnquiryList.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.UnderMaintainanceEnquiryList.url} key="t-user-grid">Under Maintainance Enquiries List </Link>
                                        </li>

                                        {/* <li className={pathname == constant.component.OutplacementList.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.OutplacementList.url} key="t-user-grid"> OutPlacement List </Link>
                                        </li> */}

                                    </ul> : ""
                            }
                        </li>

                        <li>
                            <a className={ReportIssue ? "has-arrow waves-effect mm-active" : "has-arrow waves-effect"} onClick={() => {
                                if (ReportIssue === false) {
                                    setEmployer(false)
                                    setCvsearch(false)
                                    setHrms(false)
                                    setJobs(false)
                                    setPremiumjobs(false)
                                    setFeaturedjobs(false)
                                    setGovtjobs(false)
                                    setJobcandidate(false)
                                    setJobapplication(false)
                                    setCandidate(false)
                                    setCvbooster(false)
                                    setResumesample(false)
                                    setJobposting(false)
                                    setResumesearch(false)
                                    setproFilebooster(false)
                                    setAdsmanagement(false)
                                    setCityads(false)
                                    setAstrologyservices(false)
                                    setAstrologyfaq(false)
                                    setStudy(false)
                                    setSkills(false)
                                    setDesignation(false)
                                    setSalary(false)
                                    setJobcategory(false)
                                    setJobtype(false)
                                    setGovtdepartment(false)
                                    setGovtresult(false)
                                    setinterviewQuestion(false)
                                    setPracticequestion(false)
                                    setBlog(false)
                                    setArticle(false)
                                    setFaq(false)
                                    setTestimonial(false)
                                    setDiscussion(false)
                                    setCountry(false)
                                    setEnquiry(false)
                                    setReportIssue(!ReportIssue)
                                    setCmspage(false)
                                    setDownloads(false)
                                    setPressrelease(false)
                                    setNews(false)
                                    setAdminuser(false)
                                    setModule(false)
                                }
                                else {
                                    setReportIssue(!ReportIssue)
                                }
                            }}>
                                <i class="fas fa-comments"></i>
                                <span key="t-contacts">Feedback</span>
                            </a>
                            {
                                ReportIssue ?
                                    <ul className="sub-menu" aria-expanded="false">
                                        <li className={pathname == constant.component.ReportIssueList.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.ReportIssueList.url} key="t-user-grid">Report A Problem</Link>
                                        </li>

                                    </ul> : ""
                            }
                        </li>
                        {/* <li className="menu-title" key="t-components">Affiliation</li>
                        <li>
                            <a className={affiliateCo ? "has-arrow waves-effect mm-active" : "has-arrow waves-effect"} onClick={() => {

                                if (affiliateCo === false) {
                                    setEmployer(false)
                                    setCvsearch(false)
                                    setHrms(false)
                                    setJobs(false)
                                    setPremiumjobs(false)
                                    setFeaturedjobs(false)
                                    setGovtjobs(false)
                                    setJobcandidate(false)
                                    setJobapplication(false)
                                    setCandidate(false)
                                    setCvbooster(false)
                                    setResumesample(false)
                                    setJobposting(false)
                                    setResumesearch(false)
                                    setproFilebooster(false)
                                    setAdsmanagement(false)
                                    setCityads(false)
                                    setAstrologyservices(false)
                                    setAstrologyfaq(false)
                                    setStudy(false)
                                    setSkills(false)
                                    setDesignation(false)
                                    setSalary(false)
                                    setJobcategory(false)
                                    setJobtype(false)
                                    setGovtdepartment(false)
                                    setGovtresult(false)
                                    setinterviewQuestion(false)
                                    setPracticequestion(false)
                                    setBlog(false)
                                    setArticle(false)
                                    setFaq(false)
                                    setTestimonial(false)
                                    setDiscussion(false)
                                    setCountry(false)
                                    setEnquiry(false)
                                    setCmspage(false)
                                    setDownloads(false)
                                    setPressrelease(false)
                                    setNews(false)
                                    setAdminuser(false)
                                    setModule(false)
                                    setSkillLearning(false)
                                    setAffiliateCo(!affiliateCo)
                                }
                                else {
                                    setAffiliateCo(!affiliateCo)
                                }
                            }}>
                                <i className="fas fa-handshake"></i>
                                <span key="t-contacts">Affiliate</span>
                            </a>
                            {
                                affiliateCo ?
                                    <ul className="sub-menu" aria-expanded="false">

                                        <li className={pathname == constant.component.addAffiliation.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.addAffiliation.url} key="t-user-grid">Add Affiliate </Link>
                                        </li>

                                        <li className={pathname == constant.component.affiliationList.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.affiliationList.url} key="t-user-grid"> Affiliate List </Link>
                                        </li>


                                    </ul> : ""
                            }
                        </li> */}


                        {/* <li className="menu-title" key="t-components">COURSE MODULE</li> */}


                        {/* <li>
                            <a className={skilllearning ? "has-arrow waves-effect mm-active" : "has-arrow waves-effect"} onClick={() => {

                                if (skilllearning === false) {
                                    setEmployer(false)
                                    setCvsearch(false)
                                    setHrms(false)
                                    setJobs(false)
                                    setPremiumjobs(false)
                                    setFeaturedjobs(false)
                                    setGovtjobs(false)
                                    setJobcandidate(false)
                                    setJobapplication(false)
                                    setCandidate(false)
                                    setCvbooster(false)
                                    setResumesample(false)
                                    setJobposting(false)
                                    setResumesearch(false)
                                    setproFilebooster(false)
                                    setAdsmanagement(false)
                                    setCityads(false)
                                    setAstrologyservices(false)
                                    setAstrologyfaq(false)
                                    setStudy(false)
                                    setSkills(false)
                                    setDesignation(false)
                                    setSalary(false)
                                    setJobcategory(false)
                                    setJobtype(false)
                                    setGovtdepartment(false)
                                    setGovtresult(false)
                                    setinterviewQuestion(false)
                                    setPracticequestion(false)
                                    setBlog(false)
                                    setArticle(false)
                                    setFaq(false)
                                    setTestimonial(false)
                                    setDiscussion(false)
                                    setCountry(false)
                                    setEnquiry(false)
                                    setCmspage(false)
                                    setDownloads(false)
                                    setPressrelease(false)
                                    setNews(false)
                                    setAdminuser(false)
                                    setModule(false)
                                    setSkillLearning(!skilllearning)
                                }
                                else {
                                    setSkillLearning(!skilllearning)
                                }
                            }}>
                                <i className="fas fa-book-open"></i>
                                <span key="t-contacts">Course Module</span>
                            </a>
                            {
                                skilllearning ?
                                    <ul className="sub-menu" aria-expanded="false">

                                        <li className={pathname == constant.component.addCourseContentModule.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.addCourse.url} key="t-user-grid">Add Course </Link>
                                        </li>
                                        <li className={pathname == constant.component.coursecontentmoduleList.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.courseList.url} key="t-user-grid">Course List</Link>
                                        </li>

                                        <li className={pathname == constant.component.addSkillLearning.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.addSkillLearning.url} key="t-user-grid">Add Skill Learning</Link>
                                        </li>
                                        <li className={pathname == constant.component.skillLearningList.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.skillLearningList.url} key="t-user-grid">Skill Learning List</Link>
                                        </li>
                                       

                                        <li className={pathname == constant.component.addCourseContent.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.addCourseContent.url} key="t-user-grid">Add Course Content </Link>
                                        </li>
                                        <li className={pathname == constant.component.coursecontentList.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.coursecontentList.url} key="t-user-grid">Course Content List</Link>
                                        </li>
                                        <li className={pathname == constant.component.addCourseContentModule.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.addCourseContentModule.url} key="t-user-grid">Add Course Content Module</Link>
                                        </li>
                                        <li className={pathname == constant.component.coursecontentmoduleList.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.coursecontentmoduleList.url} key="t-user-grid">Course Content Module List</Link>
                                        </li>
                                        <li className={pathname == constant.component.CourseSubscription.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.CourseSubscription.url} key="t-user-grid">Course Subscription</Link>
                                        </li>

                                    </ul> : ""
                            }
                        </li> */}



                        {/* <li className="menu-title" key="t-components">COMPONENTS</li> */}

                        {/* <li>
                            <a className={cmspage ? "has-arrow waves-effect mm-active" : "has-arrow waves-effect"} onClick={() => {
                                if (cmspage === false) {
                                    setEmployer(false)
                                    setCvsearch(false)
                                    setHrms(false)
                                    setJobs(false)
                                    setPremiumjobs(false)
                                    setFeaturedjobs(false)
                                    setGovtjobs(false)
                                    setJobcandidate(false)
                                    setJobapplication(false)
                                    setCandidate(false)
                                    setCvbooster(false)
                                    setResumesample(false)
                                    setJobposting(false)
                                    setResumesearch(false)
                                    setproFilebooster(false)
                                    setAdsmanagement(false)
                                    setCityads(false)
                                    setAstrologyservices(false)
                                    setAstrologyfaq(false)
                                    setStudy(false)
                                    setSkills(false)
                                    setDesignation(false)
                                    setSalary(false)
                                    setJobcategory(false)
                                    setJobtype(false)
                                    setGovtdepartment(false)
                                    setGovtresult(false)
                                    setinterviewQuestion(false)
                                    setPracticequestion(false)
                                    setBlog(false)
                                    setArticle(false)
                                    setFaq(false)
                                    setTestimonial(false)
                                    setDiscussion(false)
                                    setCountry(false)
                                    setEnquiry(false)
                                    setCmspage(!cmspage)
                                    setDownloads(false)
                                    setPressrelease(false)
                                    setNews(false)
                                    setAdminuser(false)
                                    setModule(false)
                                }
                                else {
                                    setCmspage(!cmspage)
                                }
                            }}>
                                <i className="fa fa-tasks"></i>
                                <span key="t-contacts">CMS Page</span>
                            </a>
                            {
                                cmspage ?
                                    <ul className="sub-menu" aria-expanded="false">


                                        <li className={pathname == constant.component.addCmsPage.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.addCmsPage.url} key="t-user-grid">Add Page</Link>
                                        </li>
                                        <li className={pathname == constant.component.cmsPageList.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.cmsPageList.url} key="t-user-grid">Page List</Link>
                                        </li>
                                    </ul> : ""
                            }
                        </li> */}

                        {/* <li>
                            <a className={downloads ? "has-arrow waves-effect mm-active" : "has-arrow waves-effect"} onClick={() => {
                                if (downloads === false) {
                                    setEmployer(false)
                                    setCvsearch(false)
                                    setHrms(false)
                                    setJobs(false)
                                    setPremiumjobs(false)
                                    setFeaturedjobs(false)
                                    setGovtjobs(false)
                                    setJobcandidate(false)
                                    setJobapplication(false)
                                    setCandidate(false)
                                    setCvbooster(false)
                                    setResumesample(false)
                                    setJobposting(false)
                                    setResumesearch(false)
                                    setproFilebooster(false)
                                    setAdsmanagement(false)
                                    setCityads(false)
                                    setAstrologyservices(false)
                                    setAstrologyfaq(false)
                                    setStudy(false)
                                    setSkills(false)
                                    setDesignation(false)
                                    setSalary(false)
                                    setJobcategory(false)
                                    setJobtype(false)
                                    setGovtdepartment(false)
                                    setGovtresult(false)
                                    setinterviewQuestion(false)
                                    setPracticequestion(false)
                                    setBlog(false)
                                    setArticle(false)
                                    setFaq(false)
                                    setTestimonial(false)
                                    setDiscussion(false)
                                    setCountry(false)
                                    setEnquiry(false)
                                    setCmspage(false)
                                    setDownloads(!downloads)
                                    setPressrelease(false)
                                    setNews(false)
                                    setAdminuser(false)
                                    setModule(false)
                                }
                                else {
                                    setDownloads(!downloads)
                                }
                            }}>
                                <i className="bx bx-cloud-download"></i>
                                <span key="t-contacts">Downloads</span>
                            </a>
                            {
                                downloads ?
                                    <ul className="sub-menu" aria-expanded="false">
                                        <li className={pathname == constant.component.addDownloads.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.addDownloads.url} key="t-user-grid">Add Downloads</Link>
                                        </li>
                                        <li className={pathname == constant.component.downloadList.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.downloadList.url} key="t-user-grid">Downloads List</Link>
                                        </li>
                                        <li className={pathname == constant.component.adddownloadcategory.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.adddownloadcategory.url} key="t-user-grid">Add Category</Link>
                                        </li>
                                        <li className={pathname == constant.component.downloadcategorylist.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.downloadcategorylist.url} key="t-user-grid">Category List</Link>
                                        </li>
                                        <li className={pathname == constant.component.addsubcategory.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.addsubcategory.url} key="t-user-grid">Add Sub Category</Link>
                                        </li>
                                        <li className={pathname == constant.component.addsubcategorylist.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.addsubcategorylist.url} key="t-user-grid">Sub Category List</Link>
                                        </li>
                                    </ul> : ""
                            }
                        </li> */}

                        {/* <li>
                            <a className={pressrelease ? "has-arrow waves-effect mm-active" : "has-arrow waves-effect"} onClick={() => {
                                if (pressrelease === false) {
                                    setEmployer(false)
                                    setCvsearch(false)
                                    setHrms(false)
                                    setJobs(false)
                                    setPremiumjobs(false)
                                    setFeaturedjobs(false)
                                    setGovtjobs(false)
                                    setJobcandidate(false)
                                    setJobapplication(false)
                                    setCandidate(false)
                                    setCvbooster(false)
                                    setResumesample(false)
                                    setJobposting(false)
                                    setResumesearch(false)
                                    setproFilebooster(false)
                                    setAdsmanagement(false)
                                    setCityads(false)
                                    setAstrologyservices(false)
                                    setAstrologyfaq(false)
                                    setStudy(false)
                                    setSkills(false)
                                    setDesignation(false)
                                    setSalary(false)
                                    setJobcategory(false)
                                    setJobtype(false)
                                    setGovtdepartment(false)
                                    setGovtresult(false)
                                    setinterviewQuestion(false)
                                    setPracticequestion(false)
                                    setBlog(false)
                                    setArticle(false)
                                    setFaq(false)
                                    setTestimonial(false)
                                    setDiscussion(false)
                                    setCountry(false)
                                    setEnquiry(false)
                                    setCmspage(false)
                                    setDownloads(false)
                                    setPressrelease(!pressrelease)
                                    setNews(false)
                                    setAdminuser(false)
                                    setModule(false)
                                }
                                else {
                                    setPressrelease(!pressrelease)
                                }
                            }}>
                                <i className="bx bx-news"></i>
                                <span key="t-contacts">Press Release</span>
                            </a>
                            {
                                pressrelease ?
                                    <ul className="sub-menu" aria-expanded="false">
                                        <li className={pathname == constant.component.addPressRelease.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.addPressRelease.url} key="t-user-grid">Add Press Release</Link>
                                        </li>
                                        <li className={pathname == constant.component.pressReleaseList.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.pressReleaseList.url} key="t-user-grid">Press Release List</Link>
                                        </li>
                                    </ul> : ""
                            }
                        </li> */}


                        {/* <li>
                            <a className={news ? "has-arrow waves-effect mm-active" : "has-arrow waves-effect"} onClick={() => {
                                if (news === false) {
                                    setEmployer(false)
                                    setCvsearch(false)
                                    setHrms(false)
                                    setJobs(false)
                                    setPremiumjobs(false)
                                    setFeaturedjobs(false)
                                    setGovtjobs(false)
                                    setJobcandidate(false)
                                    setJobapplication(false)
                                    setCandidate(false)
                                    setCvbooster(false)
                                    setResumesample(false)
                                    setJobposting(false)
                                    setResumesearch(false)
                                    setproFilebooster(false)
                                    setAdsmanagement(false)
                                    setCityads(false)
                                    setAstrologyservices(false)
                                    setAstrologyfaq(false)
                                    setStudy(false)
                                    setSkills(false)
                                    setDesignation(false)
                                    setSalary(false)
                                    setJobcategory(false)
                                    setJobtype(false)
                                    setGovtdepartment(false)
                                    setGovtresult(false)
                                    setinterviewQuestion(false)
                                    setPracticequestion(false)
                                    setBlog(false)
                                    setArticle(false)
                                    setFaq(false)
                                    setTestimonial(false)
                                    setDiscussion(false)
                                    setCountry(false)
                                    setEnquiry(false)
                                    setCmspage(false)
                                    setDownloads(false)
                                    setPressrelease(false)
                                    setNews(!news)
                                    setAdminuser(false)
                                    setModule(false)
                                }
                                else {
                                    setNews(!news)
                                }
                            }}>
                                <i className="bx bxs-news"></i>
                                <span key="t-contacts">News & Updates</span>
                            </a>
                            {
                                news ?
                                    <ul className="sub-menu" aria-expanded="false">
                                        <li className={pathname == constant.component.addNews.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.addNews.url} key="t-user-grid">Add News & Updates</Link>
                                        </li>
                                        <li className={pathname == constant.component.newsList.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.newsList.url} key="t-user-grid">News & Updates List</Link>
                                        </li>
                                    </ul> : ""
                            }
                        </li> */}


                        {/* <li>
                            <a className={globalSettings ? "has-arrow waves-effect mm-active" : "has-arrow waves-effect"} onClick={() => {
                                if (globalSettings === false) {
                                    setEmployer(false)
                                    setCvsearch(false)
                                    setHrms(false)
                                    setJobs(false)
                                    setPremiumjobs(false)
                                    setFeaturedjobs(false)
                                    setGovtjobs(false)
                                    setJobcandidate(false)
                                    setJobapplication(false)
                                    setCandidate(false)
                                    setCvbooster(false)
                                    setResumesample(false)
                                    setJobposting(false)
                                    setResumesearch(false)
                                    setproFilebooster(false)
                                    setAdsmanagement(false)
                                    setCityads(false)
                                    setAstrologyservices(false)
                                    setAstrologyfaq(false)
                                    setStudy(false)
                                    setSkills(false)
                                    setDesignation(false)
                                    setSalary(false)
                                    setJobcategory(false)
                                    setJobtype(false)
                                    setGovtdepartment(false)
                                    setGovtresult(false)
                                    setinterviewQuestion(false)
                                    setPracticequestion(false)
                                    setBlog(false)
                                    setArticle(false)
                                    setFaq(false)
                                    setTestimonial(false)
                                    setDiscussion(false)
                                    setCountry(false)
                                    setEnquiry(false)
                                    setCmspage(false)
                                    setDownloads(false)
                                    setPressrelease(false)
                                    setNews(false)
                                    setGlobalSettings(!globalSettings)
                                    setAdminuser(false)
                                    setModule(false)
                                }
                                else {
                                    setGlobalSettings(!globalSettings)
                                }
                            }}>
                                <i className="bx bxs-cog"></i>
                                <span key="t-contacts">Global Settings</span>
                            </a>
                            {
                                globalSettings ?
                                    <ul className="sub-menu" aria-expanded="false">
                                        <li className={pathname == constant.component.addglobalsettings.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.addglobalsettings.url} key="t-user-grid">Add Global Setting</Link>
                                        </li>

                                    </ul> : ""
                            }
                        </li> */}


                        <li className="menu-title" key="t-components">Administration</li>

                        <li>
                            <a className={adminuser ? "has-arrow waves-effect mm-active" : "has-arrow waves-effect"} onClick={() => {
                                if (adminuser === false) {
                                    setEmployer(false)
                                    setCvsearch(false)
                                    setHrms(false)
                                    setJobs(false)
                                    setPremiumjobs(false)
                                    setFeaturedjobs(false)
                                    setGovtjobs(false)
                                    setJobcandidate(false)
                                    setJobapplication(false)
                                    setCandidate(false)
                                    setCvbooster(false)
                                    setResumesample(false)
                                    setJobposting(false)
                                    setResumesearch(false)
                                    setproFilebooster(false)
                                    setAdsmanagement(false)
                                    setCityads(false)
                                    setAstrologyservices(false)
                                    setAstrologyfaq(false)
                                    setStudy(false)
                                    setSkills(false)
                                    setDesignation(false)
                                    setSalary(false)
                                    setJobcategory(false)
                                    setJobtype(false)
                                    setGovtdepartment(false)
                                    setGovtresult(false)
                                    setinterviewQuestion(false)
                                    setPracticequestion(false)
                                    setBlog(false)
                                    setArticle(false)
                                    setFaq(false)
                                    setTestimonial(false)
                                    setDiscussion(false)
                                    setCountry(false)
                                    setEnquiry(false)
                                    setCmspage(false)
                                    setDownloads(false)
                                    setPressrelease(false)
                                    setNews(false)
                                    setAdminuser(!adminuser)
                                    setModule(false)
                                }
                                else {
                                    setAdminuser(!adminuser)
                                }
                            }}>
                                <i className="bx bxs-user"></i>
                                <span key="t-contacts">Admin User</span>
                            </a>
                            {
                                adminuser ?
                                    <ul className="sub-menu" aria-expanded="false">
                                        <li className={pathname == constant.component.addadminuser.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.addadminuser.url} key="t-user-grid">Add Admin User</Link>
                                        </li>
                                        <li className={pathname == constant.component.adminuserlist.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.adminuserlist.url} key="t-user-list">Admin User List</Link>
                                        </li>
                                        <li className={pathname == constant.component.addadminrole.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.addadminrole.url} key="t-profile">Add Roles</Link>
                                        </li>
                                        <li className={pathname == constant.component.adminrolelist.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.adminrolelist.url} key="t-profile">Roles List</Link>
                                        </li>
                                        {/* <li className={pathname == constant.component.adminuserlog.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.adminuserlog.url} key="t-profile">Admin User Log</Link>
                                        </li> */}
                                    </ul> : ""
                            }
                        </li>
                        {/* <li>
                            <a className={module ? "has-arrow waves-effect mm-active" : "has-arrow waves-effect"} onClick={() => {
                                if (module === false) {
                                    setEmployer(false)
                                    setCvsearch(false)
                                    setHrms(false)
                                    setJobs(false)
                                    setPremiumjobs(false)
                                    setFeaturedjobs(false)
                                    setGovtjobs(false)
                                    setJobcandidate(false)
                                    setJobapplication(false)
                                    setCandidate(false)
                                    setCvbooster(false)
                                    setResumesample(false)
                                    setJobposting(false)
                                    setResumesearch(false)
                                    setproFilebooster(false)
                                    setAdsmanagement(false)
                                    setCityads(false)
                                    setAstrologyservices(false)
                                    setAstrologyfaq(false)
                                    setStudy(false)
                                    setSkills(false)
                                    setDesignation(false)
                                    setSalary(false)
                                    setJobcategory(false)
                                    setJobtype(false)
                                    setGovtdepartment(false)
                                    setGovtresult(false)
                                    setinterviewQuestion(false)
                                    setPracticequestion(false)
                                    setBlog(false)
                                    setArticle(false)
                                    setFaq(false)
                                    setTestimonial(false)
                                    setDiscussion(false)
                                    setCountry(false)
                                    setEnquiry(false)
                                    setCmspage(false)
                                    setDownloads(false)
                                    setPressrelease(false)
                                    setNews(false)
                                    setAdminuser(false)
                                    setModule(!module)

                                }
                                else {
                                    setModule(!module)
                                }
                            }}>
                                <i className="bx bxs-cog"></i>
                                <span key="t-contacts">Modules</span>
                            </a>
                            {
                                module ?
                                    <ul className="sub-menu" aria-expanded="false">
                                        <li className={pathname == constant.component.addmodule.url ? 'mm-active' : ''}>
                                            <Link to={constant.component.addmodule.url} key="t-user-grid">Add Modules</Link>
                                        </li>
                                        <li className={pathname == constant.component.modulelist ? 'mm-active' : ''}>
                                            <Link to={constant.component.modulelist.url} key="t-user-grid">Modules List</Link>
                                        </li>
                                        <li className={pathname == constant.component ? 'mm-active' : ''}>
                                            <Link to="/dashboard" key="t-user-grid">Assign Modules</Link>
                                        </li>
                                    </ul> : ""
                            }
                        </li> */}
                        <li className="text-danger">
                            <Link to="/dashboard" className="waves-effect active">
                                <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
                                <span key="t-chat" className="text-danger">Logout</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Sidebar;
