import React, { useEffect, useState } from "react";
import constant from "../../../constant";
import LatestCandidates from "./LatestCandidates";
import LatestEmployers from "./LatestEmployers";
import LatestJobs from "./LatestJobs";
import { getStorage } from "../../../utils";
import avatar from '../../../assets/images/users/avatar-2.webp'
import {
  archieveCandidateProfile,
  getProfileInfromation,
  totalCandidate,
  totalcandidatesList,
  totalEmployer,
  totalJob,
} from "../../../actions/ProfileAction";
import { Link } from "react-router-dom";
import { archiveJob, listJob } from "../../../actions/JobsAction";
import { listEmployer, updateEmployerStatus } from "../../../actions/employer";
import ApexChart from "./ApexChart";
import PieChart from "./PieChart";

export default function Dashboard() {

  const [ud, setState] = React.useState(getStorage(constant.keys.userDetails));
  const [profileDetail, setProfileDetail] = React.useState(getStorage(constant.keys.userDetails));
  const [profilePic, setProfilePic] = React.useState("");
  const [candidateCount, setCandidateCount] = useState(0);
  const [newCandidateCount, setNewCandidateCount] = useState(0)

  useEffect(() => {

    document.title = constant.title.Dashboard;

    const tk = getStorage(constant.keys.token);

    if (ud && tk) {

      getProfileInfromation(ud.ADMIN_ID)
        .then((res) => {
          setProfileDetail(res.result)
          setProfilePic(res.result.PROFILE_PICTURE);
        })
        .catch((err) => {
          alert(err);
        });
      setProfilePic(ud);
    }

    getJobList();
    getEmployerList();
    getCandidateList();
    totalJob().then((res) => {
      if (res.status) {
        setJobCount(res.result.count);
      } else {
        alert(res.error);
      }
    });

    totalEmployer().then((res) => {
      if (res.status) {
        setEmployerCount(res.result.count);
      } else {
        alert(res.error);
      }
    });



    totalCandidate().then((res) => {
      if (res.status) {
        setCandidateCount(res.result.count);
        setNewCandidateCount(res.result.count2)
      } else {
        alert(res.error);
      }
    });

  }, []);

  const [joblist, setJoblist] = useState(undefined);
  const [candidatelist, setCandidatelist] = useState(undefined);
  const [employerCount, setEmployerCount] = useState(0);
  const [jobCount, setJobCount] = useState(0);
  const [employerList, setEmployerList] = useState(undefined);

  const updateStatus = (model) => {
    updateEmployerStatus(model)
      .then((res) => {
        getEmployerList(1, "");
      })
      .catch((err) => {
        alert(err);
      });
  };

  const getCandidateList = () => {
    totalcandidatesList({ pageNumber: 1 }).then((res) => {
      setCandidatelist(res.result.list);
    });
  };

  const getJobList = () => {
    listJob({ KEYWORD: "", pageNumber: 1 }).then((res) => {
      if (res.status) {
        setJoblist(res.result.list);
      }
    })
      .catch((err) => {
        alert(err);
      });
  };
  const getEmployerList = () => {
    listEmployer({ page: 1, KEYWORD: "" })
      .then((res) => {
        if (res.status) {
          setEmployerList(res.result.list);
        } else {
          alert(res.error);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const updateJobStatus = (model) => {
    archiveJob(model)
      .then((res) => {
        if (res.status) {
          getJobList();
        }
      })
      .catch((err) => {
        alert(err);
      });
  };





  const archievecandidate = (model) => {
    archieveCandidateProfile(model)
      .then((res) => {
        getCandidateList(1, "");
      })
      .catch((err) => {
        alert(err);
      });
  };


  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="main-content">
          <div className="page-content">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">
                    JobzShala Administrative Control Panel{" "}
                  </h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="#">Home</a>
                      </li>
                      <li className="breadcrumb-item active"> Dashboard</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="d-flex">
                          <div className="flex-shrink-0 me-3">
                            {profilePic ? (
                              <img
                                src={`${process.env.REACT_APP_BASE_URL}/admin/profile/pic/${profilePic}`}
                                className="img-thumbnail rounded-circle rounded-circle header-profile-user "
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  objectFit: "cover",
                                }}
                              />
                            ) : (
                              <img
                                src={avatar}
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  objectFit: "cover",
                                  borderRadius: "50%",
                                }}
                              />
                            )}
                          </div>
                          <div className="flex-grow-1 align-self-center">
                            <div className="text-muted">
                              <p className="mb-2">Welcome to JobzShala Dashboard !</p>
                              <h5 className="mb-1">{profileDetail.FIRST_NAME} {profileDetail.LAST_NAME}</h5>
                              <p className="mb-0">{profileDetail.ROLE_NAME}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 align-self-center">
                        <div className="text-lg-center mt-4 mt-lg-0">
                          <div className="row">
                            <div className="col-4">
                              <div>
                                <p className="text-muted text-truncate mb-2">
                                  Total Employers
                                </p>
                                <h5 className="mb-0">{employerCount}</h5>
                              </div>
                            </div>
                            <div className="col-4">
                              <div>
                                <p className="text-muted text-truncate mb-2">Total Candidates</p>
                                <h5 className="mb-0">{candidateCount}</h5>
                              </div>
                            </div>
                            <div className="col-4">
                              <div>
                                <p className="text-muted text-truncate mb-2">Total Jobs</p>
                                <h5 className="mb-0">{jobCount}</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>  <div className="col-lg-4 d-none d-lg-block">
                        <Link
                          to={constant.component.profile.url}>

                          <div className="clearfix mt-4 mt-lg-0">
                            <div className="dropdown float-end">
                              <button
                                className="btn btn-primary"
                                type="button"
                              >
                                View Profile<i className="mdi mdi-arrow-right ms-1" />
                              </button>

                            </div>
                          </div></Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-6">
                <div className="card card-chart">
                  <div className="card-body">
                    <ApexChart />
                  </div>
                </div>
              </div>



              <div className="col-xl-6">
                <div className="card card-chart">
                  <div className="card-body">
                    <PieChart data={[{ title: 'Candidates', value: candidateCount, color: '#008ffb' },
                    { title: 'Jobs', value: jobCount, color: '#00e396' },
                    { title: 'Employers', value: employerCount, color: '#feb019' }]} />
                  </div>
                </div>
              </div>
            </div>


            <LatestEmployers
              updateStatus={updateStatus}
              employerList={employerList}
            />
            <LatestJobs updateJobStatus={updateJobStatus} joblist={joblist} />

            <LatestCandidates
              archievecandidate={archievecandidate}
              candidatelist={candidatelist}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );

}
