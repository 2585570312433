import React, { useState } from "react";
import Pagination from "react-js-pagination";
import nl2br from "react-nl2br";
import { Link, useLocation, useNavigate } from "react-router-dom";
import constant from "../../constant";
import Loader from "../common/Loader";
import Parser from "react-html-parser";
import { getDateParts } from "../../utils";
import queryString from 'query-string'
import ReactReadMoreReadLess from "react-read-more-read-less";

function ListJobs(props) {

  const [currentPage, setCurrentPage] = useState(1);
  const [jobFullDetails, setJobFullDetails] = useState({});
  const [hideTitle, setHideTitle] = useState(false);
  const [employerFullDetails, setEmployerFullDetails] = useState({})
  const location = useLocation()
  const Navigate = useNavigate()

  const handlePageChange = (pageNumber) => {
    window.scroll(0, 0)
    setCurrentPage(pageNumber);
    Navigate(location.pathname + '?p=' + pageNumber)
    props.setPage(pageNumber);
  };

  const updateStatus = (JOB_ID, STATUS) => {
    const model = {
      JOB_ID: JOB_ID,
      STATUS: STATUS === "U" ? "A" : "U",
    };
    props.setPage(currentPage);
    props.updateStatus(model);
  };

  const handleSearch = () => {
    setCurrentPage(1)
    props.getJobList(1);

  };

  const qParams = queryString.parse(location.search)

  //   const handleReset = (e) => {
  //     setSearchKey('')
  // }

  const { cpage } = props
  return (
    <React.Fragment>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0" style={{ fontSize: "18px" }}>
                    Job List
                  </h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="#">Job</a>
                      </li>
                      <li className="breadcrumb-item active">Job List</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <form className="row gy-12 gx-12 align-items-center">
                      <div className="col-sm-2">
                        <label
                          className="visually-hidden"
                          for="autoSizingInput"
                        >
                          Name
                        </label>
                        Search Jobs
                      </div>
                      <div className="col-sm-6">
                        <label
                          className="visually-hidden"
                          for="autoSizingInputGroup"
                        >
                          Username
                        </label>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            id="autoSizingInputGroup"
                            placeholder="Enter Custom JOB ID, Job Title, Keywords etc"
                            // value={searchKey}
                            onKeyDown={
                              (e) => {
                                if (e.key == 'Enter') {
                                  e.preventDefault()
                                  handleSearch()
                                }
                              }
                            }
                            onChange={(e) => {
                              props.setKeywords(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <button
                          type="button"
                          className="btn btn-success waves-effect btn-label waves-light"
                          onClick={(e) => {
                            e.preventDefault()
                            handleSearch()
                          }}
                        >
                          <i className="bx bx-check-double label-icon"></i>{" "}
                          Search
                        </button>
                        {/* &nbsp;{" "}
                        <button
                          type="button"
                          className="btn btn-outline-danger"
                          onClick={handleReset}
                        >
                          Reset
                        </button> */}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title mb-4">Latest Jobs</h4>
                    <div className="table-responsive">
                      {/* {props.showLoader && <Loader />} */}

                      <table className="table align-middle table-nowrap table-hover">
                        {!props.showLoader && (
                          <thead className="table-light">
                            <tr>
                              <th scope="col" style={{ width: "70px" }}>
                                ID
                              </th>
                              <th scope="col">Job Information</th>
                              <th scope="col">JobzShala Stats</th>
                              <th scope="col">Employer Details</th>
                              <th scope="col">Other Information </th>
                              {/* <th scope="col">Status </th> */}
                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                        )}

                        <tbody>
                          {props.showLoader ? <Loader /> : props &&
                            props.list.length > 0 &&
                            props.list.map((data, index) => {

                              var date1 = new Date(data.CREATED_ON);
                              var date2 = new Date();
                              const diffTime = Math.abs(date2 - date1);
                              const diffDays = Math.ceil(
                                diffTime / (1000 * 60 * 60)
                              );
                              data.diffDays = diffDays;
                              return (
                                <tr>
                                  <td
                                    style={{
                                      color: "#ff8000",
                                      fontSize: "15px",
                                      fontFamily: "nunito",
                                    }}
                                  >
                                    {data.CUSTOM_JOB_ID}
                                    <p> {data.STATUS == "U" ? (
                                      <button
                                        type="button"
                                        className="btn btn-success btn-sm waves-effect waves-light" style={{
                                          padding: "4px 8px",
                                          borderRadius: "30px",
                                          lineHeight: "9px",
                                          fontSize: "12px",
                                          paddingBottom: "5px"
                                        }}
                                      >
                                        ACTIVE
                                      </button>
                                    ) : (
                                      <button
                                        type="button"
                                        className="btn btn-danger btn-sm waves-effect waves-light" style={{
                                          padding: "4px 8px",
                                          borderRadius: "30px",
                                          lineHeight: "9px",
                                          fontSize: "12px",
                                          paddingBottom: "3px"
                                        }}
                                      >
                                        ARCHIVE
                                      </button>
                                    )}</p>

                                  </td>

                                  <td>
                                    <h5
                                      className="mb-1"
                                      style={{ fontSize: "14px" }}
                                      title={data.JOB_TITLE}
                                      onClick={(e) => {
                                        setJobFullDetails(data);
                                      }}
                                      data-bs-toggle="modal" data-bs-target={"#staticBackdrop41"}
                                    >
                                      <a href="javascript:void(0)" className="text-dark">
                                        {data.JOB_TITLE.length > 25
                                          ? data.JOB_TITLE.slice(0, 30) + "..."
                                          : data.JOB_TITLE}
                                      </a>
                                    </h5>
                                    <p
                                      className="text-muted mb-0"
                                      style={{
                                        color: "#dd006f",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {data.LISTNING_TYPE == "1"
                                        ? "REGULAR"
                                        : data.LISTNING_TYPE == "2"
                                          ? "FEATURED"
                                          : data.LISTNING_TYPE == "2"
                                            ? "PREMIUM"
                                            : ""}
                                    </p>
                                    {data.CTC_MIN && data.CTC_MAX && (
                                      <p
                                        className="text-muted mb-0"
                                        style={{ color: "#00add2" }}
                                      >
                                        &#x20b9;{" "}
                                        {` ${data.CTC_MIN} -  ${data.CTC_MAX}  L.P.A.`}
                                      </p>
                                    )}

                                    <p
                                      className="text-muted mb-0"
                                      style={{ color: "#0080ff", whiteSpace: "break-spaces" }}
                                    >
                                      <div className="tabledataflow">
                                        <i
                                          className="bx bx-street-view align-middle me-1"
                                          style={{ fontSize: "16px" }}
                                        ></i>{" "}
                                        {data.JOB_LOCATIONS &&
                                          data.JOB_LOCATIONS.length > 0
                                          ? data.JOB_LOCATIONS.map(
                                            (data, index) => {
                                              return <span style={{ paddingRight: '6px', whiteSpace: 'inherit' }}>{data.CITY + " "}</span>
                                            }
                                          )
                                          : "Not Available "}
                                      </div>
                                    </p>
                                    <p className="text-muted mb-0">
                                      <span style={{ color: "#f46a6a" }}>
                                        Posted{" "}
                                      </span>{" "}
                                      {/* {data.diffDays} days ago{" "} */}
                                      {getDateParts(data.CREATED_ON).relativeTime.split(' ')[0] == 'a' ? '1 ' + getDateParts(data.CREATED_ON).relativeTime.split(' ').splice(1).join(' ') : getDateParts(data.CREATED_ON).relativeTime}
                                    </p>
                                  </td>

                                  <td>
                                    <h5
                                      className="mb-1"
                                      style={{ fontSize: "14px" }}
                                    >
                                      {data.APPLIED > 0 ?
                                        <td><a href="javascript:void(0);" className="text-dark" >
                                          {/* Candidates Applied {data.APPLIED} */}
                                          <Link to={{
                                            pathname: constant.component.candidateAppliedJob.url.replace(':id', data.JOB_ID),
                                            state: [{ JOB_ID: data.JOB_ID }]
                                          }} className="text-dark">
                                            Candidates Applied ({data.APPLIED})
                                          </Link>
                                        </a></td> : <td><a data-bs-toggle="modal" data-bs-target="#staticBackdrop3" > Candidates Applied ({data.APPLIED})</a></td>}
                                    </h5>
                                    <p className="text-muted mb-0">
                                      <a
                                        href="javascript:void(0);"
                                        className="text-dark"
                                      >
                                        <span style={{ color: "#009999" }}>
                                          {/* Rejected (0){" "} */}
                                          Shortlisted :  {data.SHORTLISTED}
                                        </span>
                                      </a>{" "}
                                      <br />



                                      <a
                                        href="javascript:void(0);"
                                        className="text-dark"
                                      >
                                        <span style={{ color: "#FF0000" }}>
                                          {/* Rejected (0){" "} */}
                                          Rejected :  {data.REJECTED}
                                        </span>
                                      </a>{" "}


                                      <br />
                                      <a
                                        href="javascript:void(0);"
                                        className="text-dark"
                                      >
                                        <span style={{ color: "#FFA500" }}>
                                          Candidates Hold : {data.HOLD}
                                        </span>
                                      </a>{" "}
                                      <br />
                                      <a style={{ cursor: "pointer", color: "#000000" }} data-bs-toggle="modal" data-bs-target={"#MODELID" + data.QUESTION_ID}  >Questions : <span style={{
                                        color: "A1EDD5",
                                        background: "#00c694",
                                        padding: "1px 8px",
                                        borderRadius: "30px",
                                        color: "#fff",
                                        lineHeight: "9px",
                                        fontSize: "12px",
                                        paddingBottom: "3px"
                                      }} >View Questions</span></a>

                                      <div className="modal fade" id={"MODELID" + data.QUESTION_ID} tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                                          <div className="modal-content">
                                            <div className="modal-header">
                                              <h5 className="modal-title" id="staticBackdropLabel">Question</h5>
                                              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>

                                            <div className="modal-body">
                                              <p>
                                                <table className="table table-striped mb-0">
                                                  <tbody>
                                                    <tr>
                                                      <td><p><span style={{ color: "#e81c28" }}>
                                                      </span>  {Array.isArray(data.QUESTION) && data.QUESTION.length > 0 ? data.QUESTION.map((item, index) => {
                                                        return (
                                                          <span>
                                                            <div>  {" "}{item.QUESTION}</div>
                                                          </span>
                                                        )
                                                      }) : <span> Not Available</span>} </p></td>
                                                    </tr>




                                                  </tbody>

                                                </table>
                                              </p>
                                            </div>
                                            <div className="modal-footer">
                                              <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>


                                    </p>
                                  </td>
                                  <td
                                    data-bs-toggle="modal"
                                    data-bs-target="#staticBackdrop42"
                                    style={{ cursor: 'pointer' }}
                                    onClick={(e) => {
                                      setEmployerFullDetails(data);
                                    }}
                                  >
                                    <h5
                                      className="mb-1"
                                      style={{ fontSize: "14px" }}
                                    >
                                      <a href="#" className="text-dark">

                                        <i className="bx bx-user align-middle me-1" style={{ fontSize: "16px", color: "#1894ed" }}></i>

                                        {data.CLIENT_NAME && data.CLIENT_NAME.length > 30 ? data.CLIENT_NAME.slice(0, 30) + "..." : data.CLIENT_NAME} {" "}

                                        {/* <i className='fa fa-arrow-circle-left'></i> */}



                                      </a>

                                    </h5>

                                    <p
                                      className="text-muted mb-0"
                                      style={{ color: "#804000" }}
                                    >

                                      <i className="bx bx-building-house align-middle me-1" style={{ fontSize: "16px", color: "#940c35" }}></i>
                                      {data.COMPANY_NAME && data.COMPANY_NAME.length > 30 ? data.COMPANY_NAME.slice(0, 30) + "..." : data.COMPANY_NAME} {" "}
                                    </p>

                                    <p
                                      className="text-muted mb-0"
                                      style={{ color: "#804000" }}
                                    >

                                      <i className="bx bxs-envelope" style={{ color: "#fae60f", padding: "2px" }} />


                                      {data.EMAIL}
                                    </p>
                                    <p
                                      className="text-muted mb-0"
                                      style={{ color: "#804000" }}
                                    >

                                      <i className="bx bxs-phone" style={{ color: "#299c0c" }} />&nbsp;
                                      {data.MOBILE}
                                    </p>









                                    {/* <p
                                      className="text-muted mb-0"
                                      style={{ color: "#0080ff" }}
                                    >
                                      {data.MOBILE && data.MOBILE.length > 30 ? data.MOBILE.slice(0, 30) + "..." : data.MOBILE} {" "}

                                    </p>

                                    <p>
                                      {data.EMAIL && data.ADDRESS.length > 30 ? data.EMAIL.slice(0, 30) + "..." : data.EMAIL}
                                    </p>  */}




                                  </td>
                                  <td>
                                    <button
                                      type="button"
                                      data-bs-toggle="modal"
                                      data-bs-target="#staticBackdrop41"
                                      className="btn btn-info waves-effect btn-label waves-light"
                                      onClick={(e) => {
                                        setJobFullDetails(data);
                                      }}
                                    >
                                      <i className="bx bx-street-view label-icon "></i>{" "}
                                      View Details
                                      {/* <span className="badge rounded-pill bg-danger">
                                        20
                                      </span> */}
                                    </button>
                                  </td>
                                  {/* <td>
                                    {data.STATUS == "U" ? (
                                      <button
                                        type="button"
                                        className="btn btn-success btn-sm waves-effect waves-light"
                                      >
                                        ACTIVE
                                      </button>
                                    ) : (
                                      <button
                                        type="button"
                                        className="btn btn-danger btn-sm waves-effect waves-light"
                                      >
                                        ARCHIVE
                                      </button>
                                    )}
                                  </td> */}
                                  <td>
                                    <ul
                                      className="list-inline contact-links mb-0"
                                      style={{ fontSize: "20px" }}
                                    >
                                      <li className="list-inline-item px-2" >
                                        <Link
                                          to={constant.component.editJobs.url.replace(
                                            ":id",
                                            data.JOB_ID
                                          ).replace(':pageno', cpage)}

                                          target='_blank'
                                        >
                                          <i
                                            className="bx bx-edit"
                                            title="Edit Industry "


                                          />

                                        </Link>
                                      </li>
                                      <li className="list-inline-item px-2">
                                        <a
                                          href="javascript: void(0);"
                                          onClick={() =>
                                            updateStatus(
                                              data.JOB_ID,
                                              data.STATUS
                                            )
                                          }
                                          style={{ color: "red" }}
                                          title={
                                            data.STATUS == "U"
                                              ? "Archive Record"
                                              : "Unarchive Record"
                                          }
                                        >
                                          {data.STATUS == "U" ? (
                                            <i className="bx bx-trash"></i>
                                          ) : (
                                            <i class="bx bx-log-in"></i>
                                          )}
                                        </a>
                                      </li>
                                    </ul>
                                  </td>
                                </tr>
                              );
                            })}

                          {<>
                            {props && props.list && props.list.length == 0 && props.showLoader == false && (<td colSpan={10} className="text-center text-danger pt-3" style={{
                              paddingRight: "250px",
                              position: "inherit",
                            }}
                            >
                              No Jobs Available
                            </td>
                            )}
                          </>
                          }

                          <div
                            className="modal fade"
                            id="staticBackdrop41"
                            tabindex="-1"
                            role="dialog"
                            aria-labelledby="staticBackdropLabel"
                            aria-hidden="true"
                            style={{ overflowY: "initial" }}
                          >
                            <div
                              className="modal-dialog modal-dialog-centered modal-lg"
                              role="document"
                            >
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h5
                                    className="modal-title"
                                    id="staticBackdropLabel"
                                  >
                                    Job Information
                                  </h5>
                                  <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>

                                <div
                                  className="modal-body weightauto"
                                  style={{ height: "80vh", overflow: "auto" }}
                                >
                                  {jobFullDetails && (
                                    <p>
                                      <table className="table table-striped mb-0">
                                        <tbody>
                                          <tr>
                                            <td style={{ width: "20%" }}>
                                              Job description
                                            </td>
                                            <td>
                                              {jobFullDetails.JOB_DETAILS &&
                                                Parser(
                                                  jobFullDetails.JOB_DETAILS
                                                )}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Required Candidate profile</td>
                                            <td>
                                              {jobFullDetails.COURSE_SPECIALIZATION &&
                                                jobFullDetails
                                                  .COURSE_SPECIALIZATION
                                                  .length > 0 &&
                                                jobFullDetails
                                                  .COURSE_SPECIALIZATION[0]
                                                  .COURSE_STREAM &&
                                                ` Degree in ${jobFullDetails.COURSE_SPECIALIZATION &&
                                                [...new Set(jobFullDetails.COURSE_SPECIALIZATION.map(
                                                  (data, index) => {
                                                    return data.COURSE_STREAM;
                                                  }
                                                ))]
                                                }`}
                                              {/* {`  ${
                                                jobFullDetails.COURSE_SPECIALIZATION &&
                                                jobFullDetails.COURSE_SPECIALIZATION.map(
                                                  (data, index) => {
                                                    return data.SPECIALIZATION;
                                                  }
                                                )
                                              }`} */}

                                              {/* {(`Degree in ${jobFullDetails.COURSE_SPECIALIZATION && jobFullDetails.COURSE_SPECIALIZATION.map((data,index)=>{
                                                          return data.COURSE_STREAM
                                                        }) }`)} */}
                                              <br />
                                              {/* { (` ${jobFullDetails.COURSE_SPECIALIZATION && jobFullDetails.COURSE_SPECIALIZATION.map((data,index)=>{
                                                          return data.SPECIALIZATION
                                                        }) }`)} */}
                                              <br />
                                              {`Excellent command on  ${jobFullDetails &&
                                                jobFullDetails.KEYWORDS
                                                }`}

                                              <br />
                                              {/* {`Excellent command on ${jobFullDetails.JOB_DETAILS && Parser(jobFullDetails.JOB_DETAILS)}`} */}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Role</td>
                                            <td>{jobFullDetails.ROLE_NAME}</td>
                                          </tr>
                                          <tr>
                                            <td>Industry Type</td>
                                            <td>{jobFullDetails.INDUSTRY}</td>
                                          </tr>
                                          <tr>
                                            <td>Functional Area</td>
                                            <td>
                                              {jobFullDetails.FUNCTIONAL_AREA}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Employment Type</td>
                                            <td>
                                              {jobFullDetails.EMPLOYMENT_TYPE}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Role Category</td>
                                            <td>{jobFullDetails.JOB_TITLE}</td>
                                          </tr>
                                          <tr>
                                            <td>Education</td>
                                            <td>
                                              {`  ${jobFullDetails.COURSE_SPECIALIZATION &&
                                                [...new Set(jobFullDetails.COURSE_SPECIALIZATION.map(
                                                  (data, index) => {
                                                    return data.QUALIFICATION_NAME;
                                                  }
                                                ))]
                                                }`}
                                              <br />
                                              {`  ${jobFullDetails.COURSE_SPECIALIZATION &&
                                                [...new Set(jobFullDetails.COURSE_SPECIALIZATION.map(
                                                  (data, index) => {
                                                    return data.COURSE_STREAM;
                                                  }
                                                ))]
                                                }`}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Key Skills</td>
                                            <td>{jobFullDetails.KEYWORDS}</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </p>
                                  )}
                                </div>
                                <div className="modal-footer">
                                  <button
                                    type="button"
                                    className="btn btn-light"
                                    data-bs-dismiss="modal"
                                  >
                                    Close
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="modal fade"
                            id="staticBackdrop42"
                            tabindex="-1"
                            role="dialog"
                            aria-labelledby="staticBackdropLabel"
                            aria-hidden="true"
                            style={{ overflowY: "initial" }}
                          >
                            <div
                              className="modal-dialog modal-dialog-centered modal-lg"
                              role="document"
                            >
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h5
                                    className="modal-title"
                                    id="staticBackdropLabel"
                                  >
                                    Employer Information
                                  </h5>
                                  <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>




                                <div
                                  className="modal-body weightauto"
                                  style={{ overflow: "auto" }}
                                >
                                  {employerFullDetails && (
                                    <p>
                                      <table className="table table-striped mb-0">
                                        <tbody>
                                          <tr>
                                            <td style={{ width: "20%" }}>Employer Name</td>
                                            <td>{employerFullDetails.CLIENT_NAME}</td>
                                          </tr>
                                          <tr>
                                            <td>Company Name</td>
                                            <td>{employerFullDetails.COMPANY_NAME}</td>
                                          </tr>
                                          <tr>
                                            <td>Employer Mail ID</td>
                                            <td>{employerFullDetails.EMAIL}</td>
                                          </tr>
                                          <tr>
                                            <td>Employer Description</td>
                                            <td>{nl2br(Parser(employerFullDetails.CLIENT_DETAILS))}</td>
                                          </tr>
                                          <tr>
                                            <td>Contact Number </td>
                                            <td>{employerFullDetails.MOBILE}</td>
                                          </tr>
                                          <tr>
                                            <td>Employer Address</td>
                                            <td>{employerFullDetails.ADDRESS}</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </p>
                                  )}
                                </div>




                                <div className="modal-footer">
                                  <button
                                    type="button"
                                    className="btn btn-light"
                                    data-bs-dismiss="modal"
                                  >
                                    Close
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <tr>
                            <td
                              style={{
                                color: "#ff8000",
                                fontSize: "15px",
                                fontFamily: "nunito",
                              }}
                            >
                              RZG0000113
                            </td>
                            <td>
                              <h5 className="mb-1" style={{ fontSize: "14px" }}>
                                <a href="#" className="text-dark">
                                  PHP Laravel Developer
                                </a>
                              </h5>
                              <p
                                className="text-muted mb-0"
                                style={{ color: "#dd006f", fontSize: "12px" }}
                              >
                                Premium Job
                              </p>
                              <p
                                className="text-muted mb-0"
                                style={{ color: "#00add2" }}
                              >
                                ₹ 4,25,000 - 9,00,000 P.A.
                              </p>
                              <p
                                className="text-muted mb-0"
                                style={{ color: "#0080ff" }}
                              >
                                <i
                                  className="bx bx-street-view align-middle me-1"
                                  style={{ fontSize: "16px" }}
                                ></i>{" "}
                                Noida( Sector-16A Noida )
                              </p>
                              <p className="text-muted mb-0">
                                <span style={{ color: "#f46a6a" }}>
                                  Posted{" "}
                                </span>{" "}
                                14 days ago{" "}
                              </p>
                            </td>
                            <td>
                              <h5 className="mb-1" style={{ fontSize: "14px" }}>
                                <a
                                  href="javascript:void(0);"
                                  className="text-dark"
                                >
                                  Candidates Applied (13)
                                </a>
                              </h5>
                              <p className="text-muted mb-0">
                                <a
                                  href="javascript:void(0);"
                                  className="text-dark"
                                >
                                  <span style={{ color: "#009999" }}>
                                    Rejected (12){" "}
                                  </span>
                                </a>{" "}
                                <br />
                                <a
                                  href="javascript:void(0);"
                                  className="text-dark"
                                >
                                  <span style={{ color: "#008040" }}>
                                    Shortlisted (2)
                                  </span>
                                </a>
                                <br />
                                <a
                                  href="javascript:void(0);"
                                  className="text-dark"
                                >
                                  <span style={{ color: "#ff00ff" }}>
                                    Candidates Hold (23)
                                  </span>
                                </a>{" "}
                                <br />
                                <a
                                  href="javascript:void(0);"
                                  className="text-dark"
                                >
                                  <span style={{ color: "#004080" }}>
                                    Questions (23)
                                  </span>
                                </a>
                              </p>
                            </td>
                            <td>
                              <h5 className="mb-1" style={{ fontSize: "14px" }}>
                                <a href="#" className="text-dark">
                                  Gyanwave
                                </a>
                              </h5>
                              <p
                                className="text-muted mb-0"
                                style={{ color: "#804000" }}
                              >
                                GYANWAVE TECHNOLOGY PRIVATE LIMITED
                              </p>
                              <p
                                className="text-muted mb-0"
                                style={{ color: "#0080ff" }}
                              >
                                {" "}
                                Website : https://gyanwave.com
                              </p>
                            </td>
                            <td>
                              <button
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#staticBackdrop41"
                                className="btn btn-info waves-effect btn-label waves-light"
                              >
                                <i className="bx bx-street-view label-icon "></i>{" "}
                                View Details
                                <span className="badge rounded-pill bg-danger">
                                  20
                                </span>
                              </button>

                              <div
                                className="modal fade"
                                id="staticBackdrop41"
                                data-bs-backdrop="static"
                                data-bs-keyboard="false"
                                tabindex="-1"
                                role="dialog"
                                aria-labelledby="staticBackdropLabel"
                                aria-hidden="true"
                              >
                                <div
                                  className="modal-dialog modal-dialog-centered modal-lg"
                                  role="document"
                                >
                                  <div className="modal-content">
                                    <div className="modal-header">
                                      <h5
                                        className="modal-title"
                                        id="staticBackdropLabel"
                                      >
                                        Job Information
                                      </h5>
                                      <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      ></button>
                                    </div>

                                    <div className="modal-body">
                                      <p>
                                        <table className="table table-striped mb-0">
                                          <tbody>
                                            <tr>
                                              <td>Job description</td>
                                              <td>
                                                Understand requirements &
                                                functional specifications
                                                <br />
                                                Develop web applications,
                                                dashboards,APIs
                                                <br />
                                                Troubleshoot, test, maintain
                                                code & its document
                                                <br />
                                                Analyse, design, code, debug,
                                                test, document & deploy changes
                                                <br />
                                                Ensure foolproof performance of
                                                deliverable
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                Required Candidate profile
                                              </td>
                                              <td>
                                                Degree in B.TECH, BCA or
                                                equivalent
                                                <br />
                                                Excellent command on PHP/MySQL
                                                with Laravel Framework
                                                <br />
                                                Experienced in Vue, Laravel,
                                                Jquery & AJAX
                                                <br />
                                                Good OOP knowledge
                                                <br />
                                                API based development knowledge
                                                is must
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Role</td>
                                              <td>
                                                Software Development - Other
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Industry Type</td>
                                              <td>Advertising & Marketing</td>
                                            </tr>
                                            <tr>
                                              <td>Functional Area</td>
                                              <td>Engineering - Software</td>
                                            </tr>
                                            <tr>
                                              <td>Employment Type</td>
                                              <td>Full Time, Permanent</td>
                                            </tr>
                                            <tr>
                                              <td>Role Category</td>
                                              <td>Software Development</td>
                                            </tr>
                                            <tr>
                                              <td>Education</td>
                                              <td>
                                                UG : BCA in Any Specialization,
                                                B.Tech/B.E. in Any
                                                Specialization, Other Graduate
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Key Skills</td>
                                              <td>
                                                Mysql Database, Web Application
                                                Development, JQuery
                                                <br /> Laravel, Laravel Php,
                                                Vue.Js, Laravel, Php
                                                <br /> Framework, MySQL,
                                                Javascript, PHP Vue Framework,
                                                API
                                                <br /> Web Development, Ajax
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </p>
                                    </div>
                                    <div className="modal-footer">
                                      <button
                                        type="button"
                                        className="btn btn-light"
                                        data-bs-dismiss="modal"
                                      >
                                        Close
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>

                            <td>
                              <ul
                                className="list-inline contact-links mb-0"
                                style={{ fontSize: "20px" }}
                              >
                                <li className="list-inline-item px-2">
                                  <a
                                    href="javascript: void(0);"
                                    title="Edit User"
                                  >
                                    <i className="bx bx-edit"></i>
                                  </a>
                                </li>
                                <li className="list-inline-item px-2">
                                  <a
                                    href="javascript: void(0);"
                                    style={{ color: "red" }}
                                    title="Delete User"
                                  >
                                    <i className="bx bx-trash"></i>
                                  </a>
                                </li>
                              </ul>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                color: "#ff8000",
                                fontSize: "15px",
                                fontFamily: "nunito",
                              }}
                            >
                              RZG0000113
                            </td>
                            <td>
                              <h5 className="mb-1" style={{ fontSize: "14px" }}>
                                <a href="#" className="text-dark">
                                  PHP Laravel Developer
                                </a>
                              </h5>
                              <p
                                className="text-muted mb-0"
                                style={{ color: "#dd006f", fontSize: "12px" }}
                              >
                                Premium Job
                              </p>
                              <p
                                className="text-muted mb-0"
                                style={{ color: "#00add2" }}
                              >
                                ₹ 4,25,000 - 9,00,000 P.A.
                              </p>
                              <p
                                className="text-muted mb-0"
                                style={{ color: "#0080ff" }}
                              >
                                <i
                                  className="bx bx-street-view align-middle me-1"
                                  style={{ fontSize: "16px" }}
                                ></i>{" "}
                                Noida( Sector-16A Noida )
                              </p>
                              <p className="text-muted mb-0">
                                <span style={{ color: "#f46a6a" }}>
                                  Posted{" "}
                                </span>{" "}
                                14 days ago{" "}
                              </p>
                            </td>
                            <td>
                              <h5 className="mb-1" style={{ fontSize: "14px" }}>
                                <a
                                  href="javascript:void(0);"
                                  className="text-dark"
                                >
                                  Candidates Applied (13)
                                </a>
                              </h5>
                              <p className="text-muted mb-0">
                                <a
                                  href="javascript:void(0);"
                                  className="text-dark"
                                >
                                  <span style={{ color: "#009999" }}>
                                    Rejected (12){" "}
                                  </span>
                                </a>{" "}
                                <br />
                                <a
                                  href="javascript:void(0);"
                                  className="text-dark"
                                >
                                  <span style={{ color: "#008040" }}>
                                    Shortlisted (2)
                                  </span>
                                </a>
                                <br />
                                <a
                                  href="javascript:void(0);"
                                  className="text-dark"
                                >
                                  <span style={{ color: "#ff00ff" }}>
                                    Candidates Hold (23)
                                  </span>
                                </a>
                                <br />
                                <a
                                  href="javascript:void(0);"
                                  className="text-dark"
                                >
                                  <span style={{ color: "#004080" }}>
                                    Questions (23)
                                  </span>
                                </a>
                              </p>
                            </td>
                            <td>
                              <h5 className="mb-1" style={{ fontSize: "14px" }}>
                                <a href="#" className="text-dark">
                                  Gyanwave
                                </a>
                              </h5>
                              <p
                                className="text-muted mb-0"
                                style={{ color: "#804000" }}
                              >
                                GYANWAVE TECHNOLOGY PRIVATE LIMITED
                              </p>
                              <p
                                className="text-muted mb-0"
                                style={{ color: "#0080ff" }}
                              >
                                {" "}
                                Website : https://gyanwave.com
                              </p>
                            </td>
                            <td>
                              <button
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#staticBackdrop41"
                                className="btn btn-info waves-effect btn-label waves-light"
                              >
                                <i className="bx bx-street-view label-icon "></i>{" "}
                                View Details
                                <span className="badge rounded-pill bg-danger">
                                  20
                                </span>
                              </button>

                              <div
                                className="modal fade"
                                id="staticBackdrop41"
                                data-bs-backdrop="static"
                                data-bs-keyboard="false"
                                tabindex="-1"
                                role="dialog"
                                aria-labelledby="staticBackdropLabel"
                                aria-hidden="true"
                              >
                                <div
                                  className="modal-dialog modal-dialog-centered modal-lg"
                                  role="document"
                                >
                                  <div className="modal-content">
                                    <div className="modal-header">
                                      <h5
                                        className="modal-title"
                                        id="staticBackdropLabel"
                                      >
                                        Job Information
                                      </h5>
                                      <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      ></button>
                                    </div>

                                    <div className="modal-body">
                                      <p>
                                        <table className="table table-striped mb-0">
                                          <tbody>
                                            <tr>
                                              <td>Job description</td>
                                              <td>
                                                Understand requirements &
                                                functional specifications
                                                <br />
                                                Develop web applications,
                                                dashboards,APIs
                                                <br />
                                                Troubleshoot, test, maintain
                                                code & its document
                                                <br />
                                                Analyse, design, code, debug,
                                                test, document & deploy changes
                                                <br />
                                                Ensure foolproof performance of
                                                deliverable
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                Required Candidate profile
                                              </td>
                                              <td>
                                                Degree in B.TECH, BCA or
                                                equivalent
                                                <br />
                                                Excellent command on PHP/MySQL
                                                with Laravel Framework
                                                <br />
                                                Experienced in Vue, Laravel,
                                                Jquery & AJAX
                                                <br />
                                                Good OOP knowledge
                                                <br />
                                                API based development knowledge
                                                is must
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Role</td>
                                              <td>
                                                Software Development - Other
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Industry Type</td>
                                              <td>Advertising & Marketing</td>
                                            </tr>
                                            <tr>
                                              <td>Functional Area</td>
                                              <td>Engineering - Software</td>
                                            </tr>
                                            <tr>
                                              <td>Employment Type</td>
                                              <td>Full Time, Permanent</td>
                                            </tr>
                                            <tr>
                                              <td>Role Category</td>
                                              <td>Software Development</td>
                                            </tr>
                                            <tr>
                                              <td>Education</td>
                                              <td>
                                                UG : BCA in Any Specialization,
                                                B.Tech/B.E. in Any
                                                Specialization, Other Graduate
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Key Skills</td>
                                              <td>
                                                Mysql Database, Web Application
                                                Development, JQuery
                                                <br /> Laravel, Laravel Php,
                                                Vue.Js, Laravel, Php
                                                <br /> Framework, MySQL,
                                                Javascript, PHP Vue Framework,
                                                API
                                                <br /> Web Development, Ajax
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </p>
                                    </div>
                                    <div className="modal-footer">
                                      <button
                                        type="button"
                                        className="btn btn-light"
                                        data-bs-dismiss="modal"
                                      >
                                        Close
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>

                            <td>
                              <ul
                                className="list-inline contact-links mb-0"
                                style={{ fontSize: "20px" }}
                              >
                                <li className="list-inline-item px-2">
                                  <a
                                    href="javascript: void(0);"
                                    title="Edit User"
                                  >
                                    <i className="bx bx-edit"></i>
                                  </a>
                                </li>
                                <li className="list-inline-item px-2">
                                  <a
                                    href="javascript: void(0);"
                                    style={{ color: "red" }}
                                    title="Delete User"
                                  >
                                    <i className="bx bx-trash"></i>
                                  </a>
                                </li>
                              </ul>
                            </td>
                          </tr> */}
                        </tbody>
                      </table>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="pagination pagination-rounded justify-content-center mt-4">

                          <Pagination
                            activePage={qParams?.p ? +qParams?.p :
                              qParams?.pageno ? Number(qParams.pageno) : currentPage}
                            itemsCountPerPage={20}
                            totalItemsCount={props.count}
                            pageRangeDisplayed={5}
                            onChange={handlePageChange}
                            itemClass="page-item"
                            linkClass="page-link"
                          />

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ListJobs;
