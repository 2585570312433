import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { updateEntityType, EntityById } from '../../actions/employer'
import AddEntitytype from '../../component/Employers/AddEntityType'
import constant from '../../constant'
export default function AddEntityType() {

    const [response, setResponse] = React.useState({})
    const [showMsg, setShowMsg] = React.useState(false)

    const params = useParams()

    const navigation = useNavigate();

    const [data, setData] = useState(null);

    useEffect(() => {
        document.title = constant.title.EditEntityType;
        EntityById(params.id).then(res => {
            if (res.status) {
                setData(res.result)
            }
            else {
                alert(res.error)
            }
        }).catch(err => {
            alert(err)
        })
    }, [])


    const onSubmit = (model) => {
        updateEntityType(model).then(res => {
            if (res.status) {
                navigation(constant.component.entitytypelist.url)
            }
            else {
                setResponse(res)
                setShowMsg(true)
                setTimeout(() => {
                    setShowMsg(false)
                }, 7000)
            }
        })
            .catch(err => {
                alert(err)
            })
    }
    return (
        <React.Fragment>
            {data &&
                <AddEntitytype
                    onSubmit={onSubmit}
                    detail={data} response={response} showMsg={showMsg}
                />
            }
        </React.Fragment>
    )
}
