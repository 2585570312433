import React from 'react'
import { deleteAlertListById, JobAlertList } from '../../actions/utilitiesAction'
import Alert from '../../component/common/JobAlert'
import { useParams } from 'react-router-dom'
import { useState } from 'react'
import Swal from 'sweetalert2'
import { useEffect } from 'react'
import constant from '../../constant'
export default function JobAlert() {

    const [alertList, setAlertList] = useState(undefined)
    const [count, setCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const params = useParams()


    useEffect(() => {
        
        getAlertList(currentPage)
        document.title = constant.title.JobAlertList
    }, [currentPage])

    const getAlertList = (page) => {
        JobAlertList({page:page}).then((res) => {
            
            if (res.status) {
                setAlertList(res.result.list)
                setCount(res.result.count)
            }
            else {
                alert(res.error)
            }
        }).catch((err) => {
            alert(err)
        })
    }

    const deleteAlertId = (ALERT_ID) => {
        deleteAlertListById({ ALERT_ID: ALERT_ID }).then((res) => {
            if (res.status) {
                Swal.fire(
                    'Deleted!',
                    'Your Answer has been deleted.',
                    'success'
                )
                getAlertList(currentPage)
            }
            else {
                Swal.fire(
                    (res.result.error)
                )
            }

        })
    }


  return (
    <React.Fragment>
    <Alert
    alertList={alertList}
    getAlertList={(page => getAlertList(page))}
    count={count}
    setPage={setCurrentPage}
    deleteAlertId={deleteAlertId}
    
    />
    </React.Fragment>
  )
}
