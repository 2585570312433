import React, { useEffect } from 'react'
import DashBoard from '../../component/Menu/Dashboard/Dashboard'
function Dashboard() {
    
     useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <React.Fragment>
            <DashBoard />
        </React.Fragment>
    )
}
export default Dashboard