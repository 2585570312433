import React from 'react'
import PremiumCityList from '../../../component/Utilities/CountryCityAndState/PremiumCities'
import constant from '../../../constant'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { getPremiumCity, updatePremiumCity } from '../../../actions/utilitiesAction'

export default function PremiumCities() {

    const [List, setList] = useState([])
    const [count, setCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const location = useLocation()



    useEffect(() => {
        const qParams = queryString.parse(location.search)
        getPremiumCityLists(qParams?.p ? qParams?.p : currentPage)
        document.title = constant.title.PremiumCities
    }, [currentPage])

    const getPremiumCityLists = (page) => {
        
        getPremiumCity(page).then((res) => {
            if (res.status) {
                setList(res.result.list)
                setCount(res.result.count.total)
            }

        }).catch((err) => {
            alert(err)

        })
    }


    const updateStatus = (model) => {

        updatePremiumCity(model).then((res) => {
            if (res.status) {
                getPremiumCityLists(currentPage)
            }
            else {
                alert(res.error)
            }
        }).catch((err) => {
            alert(err)
        })
    }


    return (
        <React.Fragment>
            <PremiumCityList
                List={List}
                setList={setList}
                getPremiumCityLists={(page) => { getPremiumCityLists(page) }}
                count={count}
                setPage={setCurrentPage}
                updateStatus={updateStatus}
            />
        </React.Fragment>
    )
}
