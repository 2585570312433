import React from 'react'
import { addModule } from '../../actions/ModuleAction'
import Addmodules from '../../component/Administration/adminUser/AddModules'
 function AddModules() {

    const [response, setResponse] = React.useState({})
    const [showMsg, setShowMsg] = React.useState(false)

    const onSubmit = (model) => {
        addModule(model).then((res) => {
            console.log(res.result);
            // if (res.status)
            //     alert(res.messageCode)
            setResponse(res)
            setShowMsg(true)
            setTimeout(() => {
                setShowMsg(false)
            }, 7000)
        }).catch((err) => {
            alert(err)
        })
    }


    return (
        <React.Fragment>
       <Addmodules onSubmit={onSubmit} response={response} showMsg={showMsg} />
        </React.Fragment>
    )
}
export default AddModules;