import React, { useState } from 'react'
import Add from '../../../component/Utilities/PracticeQuestion/AddPracticeQuestion'
import constant from '../../../constant'
import { useEffect } from 'react'
import { activeSkillList, addPracticeQuestion } from '../../../actions/utilitiesAction'

export default function AddPracticeQuestion() {

    const [list, setlist] = useState([])
    const [response, setResponse] = useState({})
    const [showMsg, setShowMsg] = useState(false)
    const onSubmit = (model) => {
        addPracticeQuestion(model).then((res) => {
            if (res.status) {
                setResponse(res)
                setShowMsg(true)
                setTimeout(() => {
                    setShowMsg(false)
                }, 7000)
            }
            else {
                setResponse(res)
                setShowMsg(true)
                setTimeout(() => {
                    setShowMsg(false)
                }, 7000)
            }
        }).catch((err) => {
            alert(err)
        })
    }

    useEffect(() => {
        document.title = constant.title.AddPracticeQuestion
        activeSkillList().then((res) => {
            if (res.status) {
                setlist(res.result.list)
            }
            else {
                alert(res.error)
            }
        }).catch((err) => {
            alert(err)
        })
    }, [])


    return (
        <React.Fragment>
            <Add list={list}
                onSubmit={onSubmit}
                response={response}
                showMsg={showMsg} />
        </React.Fragment>
    )
}
