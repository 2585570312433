import React, { Children, useRef } from "react";
import { addFaq, updateFaq } from "../../actions/utilitiesAction";
import constant from "../../constant";
import QualificationLists from "../../component/jobs/QualificationList";
import { archiveEducationQualification, createEducationQualification, createJob, createRole, updateEducationQualification, updateJob, updateRole } from "../../actions/JobsAction";
import QuestionLists from "../../component/jobs/QuestionList";
import RoleLists from "../../component/jobs/RoleList";
import AddRoles from "../../component/jobs/AddRole";
import AddFunctionalAreas from "../../component/jobs/AddFunctionalArea";
import AddIndustrys from "../../component/jobs/AddIndustry";
import AddJobs from "../../component/jobs/AddJobs";
import { useNavigate } from "react-router-dom";

const AddJob = () => {
    const navigation = useNavigate();
    const [response, setResponse] = React.useState({})
    const [showMsg, setShowMsg] = React.useState(false)
    const onSubmit = (model) => {
        debugger
        if(model.type==='ADD'){
            createJob(model).then((res) => {
                if (res.status){
                    setResponse(res)
                setShowMsg(true)
                setTimeout(() => {
                    setShowMsg(false)
                }, 7000)
                    // navigation(constant.component.listJobs.url)s
                }
            }).catch((err) => {
                alert(err)
            })
        }
      
    }

   
    return (
        <React.Fragment>
            <AddJobs onSubmit={(data)=>onSubmit(data)} response={response} showMsg={showMsg}/>
        </React.Fragment>
    )
}

export default AddJob;