import React, { useState } from 'react'
import constant from '../../../constant'
import moment from 'moment'
import Pagination from 'react-js-pagination'
import { Link } from 'react-router-dom'
import { Spinner } from 'reactstrap'

export default function CategoryList(props) {
    const [currentPage, setCurrentPage] = useState(1);
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        // props.getBlogCategorys(pageNumber)
        props.setPage(pageNumber)
    };
    const updateStatus = (CATEGORY_ID, STATUS) => {
        const model = {
            CATEGORY_ID: CATEGORY_ID,
            STATUS: STATUS === 'U' ? 'A' : 'U'
        }
        props.setPage(currentPage)
        props.updateStatus(model)
    }
    return (
        <React.Fragment>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">

                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 " style={{ fontSize: '18px' }}>Category List</h4>
                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href=""> Utilities</a></li>
                                            <li className="breadcrumb-item"><a href=""> Blog</a></li>
                                            <li className="breadcrumb-item active">Category List</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table align-middle table-nowrap table-hover">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col">S.No.</th>
                                                        <th scope="col">Category</th>
                                                        <th scope="col">Linked to Blog</th>
                                                        <th scope="col">Added On</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col" style={{ width: '100px' }}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {props.list && props.list.length > 0 ?
                                                        props.list.map((ele, index, list) => {
                                                            return (

                                                                <tr>
                                                                    <td>{currentPage == 1 ? `${index + 1}` : `${index == 9 ? currentPage : currentPage - 1}` + `${index == 9 ? 0 : index + 1}`}</td>
                                                                    <td>
                                                                        <p className="text-muted mb-0">{ele.CATEGORY}</p>
                                                                    </td>
                                                                    <td>0</td>
                                                                    <td>
                                                                        {moment(ele.CREATED_ON).format('DD.MM.YYYY on hh:mma')}
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            ele.STATUS == 'U' ?
                                                                                <button type="button" className="btn btn-success btn-sm waves-effect waves-light">ACTIVE</button> : <button type="button" className="btn btn-danger btn-sm waves-effect waves-light">ARCHIVE</button>
                                                                        }

                                                                    </td>
                                                                    <td>
                                                                        <ul className="list-inline font-size-20 contact-links mb-0" >
                                                                            <li className="list-inline-item px-2">
                                                                                <Link to={constant.component.editblogcategory.url.replace(':id', ele.CATEGORY_ID)} title="Edit Skill"><i className="bx bx-edit"></i></Link>                                                                                </li>
                                                                            <li className="list-inline-item px-2">
                                                                                <a href="javascript: void(0);" onClick={() => updateStatus(ele.CATEGORY_ID, ele.STATUS)} style={{ color: 'red' }} title={ele.STATUS == 'U' ? "Archive Record" : "Unarchive Record"}>
                                                                                    {
                                                                                        ele.STATUS == 'U' ? <i className="bx bx-trash" ></i> : <i class="bx bx-log-in"></i>
                                                                                    }
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>

                                                            )

                                                        })
                                                        : <React.Fragment>
                                                            < td colSpan={10} className="text-center text-danger pt-3">

                                                                < div style={{
                                                                    width: "80vw", height: "70vh", display: "flex", alignItems: "center",
                                                                    justifyContent: "center"
                                                                }}>
                                                                    <Spinner animation="border" variant="info"
                                                                        style={{ width: '3rem', height: '3rem' }} />

                                                                </div>
                                                            </td>
                                                        </React.Fragment>

                                                    }
                                                    {props.list && props.list.length == 0 && < td colSpan={10} className="text-center text-danger pt-3">No Category Record Found </td>}


                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <ul className="pagination pagination-rounded justify-content-center mt-4">
                                                    <Pagination
                                                        activePage={currentPage}
                                                        itemsCountPerPage={10}
                                                        totalItemsCount={props.count}
                                                        pageRangeDisplayed={10}
                                                        onChange={handlePageChange}
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                    />
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
