import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FAQbyId } from "../../../actions/utilitiesAction";
import SuccessMessage from "../../common/SuccessMessage";
import ErrorMessage from "../../common/ErrorMessage";
import NumberFormat from "react-number-format";
import constant from "../../../constant";
import { ToSeoUrl } from "../../../utils";


export default function AddFAQ(props) {
  const [error, setError] = useState(false)
  const [question, setQuestion] = useState("");
  const [slug, setSlug] = useState("");
  const [answer, setAnswer] = useState("");
  const [CATEGORY_ID, setCategory] = useState("");
  const [sort, setSort] = useState("");
  const [faqId, setFaqId] = useState("");
  const { id } = useParams();


  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    let url = ToSeoUrl(value);
    if (name == 'question') {
      setQuestion(value)
      setSlug(url)
    }


  }
  const onSubmit = (e) => {
    e.preventDefault();
    if (question.trim().length === 0 || sort.length === 0 || answer.trim().length === 0 || CATEGORY_ID.length === 0) {
      setError(true)
      return false
    }

    if (id) {
      const model = {
        FAQ_ID: faqId,
        QUESTION: question,
        ANSWER: answer,
        CATEGORY_ID: CATEGORY_ID,
        SORT_NUMBER: sort,
        type: "UPDATE",
      };
      props.onSubmit(model);
    } else {
      const model = {
        QUESTION: question,
        ANSWER: answer,
        CATEGORY_ID: CATEGORY_ID,
        SORT_NUMBER: sort,
        type: "ADD",
      };
      props.onSubmit(model);
    }
  };

  useEffect(() => {
    id && getCategoryDetails(id);
    (id ? document.title = constant.title.EditFAQ : document.title = constant.title.FaqAdd)
  }, []);
  const getCategoryDetails = (id) => {
    FAQbyId({ FAQ_ID: id })
      .then((res) => {
        console.log(res.result);
        if (res.status) {
          setQuestion(res.result.QUESTION);
          setAnswer(res.result.ANSWER);
          setSlug(res.result.URL);
          setSort(res.result.SORT_NUMBER);
          setCategory(res.result.CATEGORY_ID);
          setFaqId(res.result.FAQ_ID);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };


  return (
    <React.Fragment>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">{id ? "Update FAQ " : "Add FAQ "}</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">FAQ</a>
                      </li>
                      <li className="breadcrumb-item active">
                        {id ? "Update FAQ" : "Add FAQ "}
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title mb-4">Create New FAQ</h4>
                    {
                      props.showMsg && (props.response.status ?
                        <SuccessMessage message={props.response.messageCode} />
                        :
                        <ErrorMessage message={props.response.error} />)
                    }
                    <form>
                      <div className="row mb-4">
                        <label
                          htmlFor="projectname"
                          className="col-form-label col-lg-2"
                        >
                          Question <span style={{ color: "#FF0000" }}>*</span>
                        </label>
                        <div className="col-lg-10">
                          <input
                            id="projectname"
                            name="question"
                            type="text"
                            className={error && question.trim().length <= 0 ? 'form-control is-invalid ' : 'form-control'}
                            placeholder="Enter Question..."
                            value={question}
                            onChange={(e) => {
                              // setQuestion(e.target.value);
                              handleChange(e)
                            }}
                          />
                          {
                            error && question.trim().length <= 0 ? <span className='text-danger ml-1'>This Field is Required</span>
                              : ""}
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label
                          htmlFor="projectname"
                          className="col-form-label col-lg-2"
                        >
                          Slug <span style={{ color: "#FF0000" }}>*</span>
                        </label>
                        <div className="col-lg-10">
                          <input
                            id="projectname"
                            name="slug"
                            type="text"
                            readOnly={true}
                            className="form-control"
                            placeholder="Slug..."
                            value={slug}
                            onChange={(e) => {
                              // setSlug(e.target.value);
                              handleChange(e)
                            }}
                          />
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label
                          htmlFor="projectdesc"
                          className="col-form-label col-lg-2"
                        >
                          Answer <span style={{ color: "#FF0000" }}>*</span>
                        </label>
                        <div className="col-lg-10">
                          <textarea
                            className={error && answer.trim().length <= 0 ? 'form-control is-invalid ' : 'form-control'}
                            id="projectdesc"
                            rows={8}
                            placeholder="Enter Answer..."
                            defaultValue={""}
                            value={answer}
                            onChange={(e) => {
                              setAnswer(e.target.value);
                            }}
                          />
                          {
                            error && answer.trim().length <= 0 ? <span className='text-danger ml-1'>This Field is Required</span>
                              : ""}
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label className="col-form-label col-lg-2">
                          Category <span style={{ color: "#FF0000" }}>*</span>
                        </label>
                        <div className="col-lg-10">
                          <div
                            className="input-daterange input-group"
                            id="project-date-inputgroup"
                            data-provide="datepicker"
                            data-date-format="dd M, yyyy"
                            data-date-container="#project-date-inputgroup"
                            data-date-autoclose="true"

                          >
                            <select
                              value={CATEGORY_ID}
                              onChange={(e) => {
                                setCategory(e.target.value);
                              }}
                              className={error && CATEGORY_ID.length <= 0 ? 'form-control is-invalid ' : 'form-control'}
                            >
                              <option value="">Select a Category</option>
                              {
                                props.list && props.list.length > 0 &&
                                props.list.map((item) => {
                                  return (
                                    <option value={item.CATEGORY_ID}>{item.CATEGORY}</option>
                                  )
                                })

                              }
                            </select>
                          </div>
                          {
                            error && CATEGORY_ID.length <= 0 ? <span className='text-danger ml-1'>This Field is Required</span>
                              : ""}
                        </div>

                      </div>
                      <div className="row mb-4">
                        <label
                          htmlFor="projectname"
                          className="col-form-label col-lg-2"
                        >
                          Sort Number <span style={{ color: "#FF0000" }}>*</span>
                        </label>
                        <div className="col-lg-10">
                          <NumberFormat
                            id="projectname"
                            name="projectname"
                            type="text"
                            maxlength={10}
                            className={error && sort.length <= 0 ? 'form-control is-invalid ' : 'form-control'}
                            placeholder="Sort Number..."
                            value={sort}
                            allowLeadingZeros={false}
                            allowNegative={false}
                            thousandSeparator={false}
                            onChange={(e) => {
                              setSort(e.target.value);
                            }}
                          />
                          {
                            error && sort.length <= 0 ? <span className='text-danger ml-1'>This Field is Required</span> : ""
                          }
                        </div>
                      </div>
                      <div className="row justify-content-end">
                        <div className="col-lg-10">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            onClick={onSubmit}
                          >
                            {id ? "Update FAQ" : "Create FAQ"}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
