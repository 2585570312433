import React, { useEffect, useMemo, useState } from 'react'
import constant from '../../../constant'
import moment from 'moment';
import Pagination from 'react-js-pagination';
import { Link } from 'react-router-dom';
import ReactReadMoreReadLess from "react-read-more-read-less";
import { Spinner } from 'reactstrap';
import Loader from '../../common/Loader'
import nl2br from 'react-nl2br'
import Parser from 'html-react-parser'; 

export default function BlogList(props) {

    const [currentPage, setCurrentPage] = useState(1);
    const [showLess, setShowLess] = useState(false);
    //const [blog, setBlog] = useState('')

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        props.setPage(pageNumber)
    };
    const updateStatus = (BLOG_ID, STATUS) => {

        console.log(STATUS, BLOG_ID);

        const model = {
            BLOG_ID: BLOG_ID,
            STATUS: STATUS === 'Y' ? 'N' : 'Y'
        }

        props.setPage(currentPage)
        props.updateStatus(model)
    }
    useEffect(() => {
        document.title = constant.title.BlogList



    })

    const searchList = () => {
        props.blogLists(currentPage)
    }


    // const rl = React.useMemo(() => {
    //     setShowLess(true)
    //     return (<>
    //         <a href="javascript: void(0);" className="text-primary">  Read more   <i className="mdi mdi-arrow-right" /></a>
    //     </>)
    // }, [showLess])



    return (
        <React.Fragment>

            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        {/* start page title */}

                        {console.log("props.list", props.categoryList)}


                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0" style={{ fontSize: '18px' }}>Blog List</h4>
                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="javascript:void(0);">Blog</a></li>
                                            <li className="breadcrumb-item active">Blog List</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <form className="row gy-12 gx-12 align-items-center">
                                            <div className="col-sm-2">
                                                <label className="visually-hidden" for="autoSizingInput">Name</label>
                                                Search Blog
                                            </div>
                                            <div className="col-sm-6">
                                                <label className="visually-hidden" for="autoSizingInputGroup">Username</label>
                                                <div className="input-group">
                                                    <input type="text" onChange={(e) => {
                                                        props.keyword(e.target.value)
                                                    }} className="form-control" id="autoSizingInputGroup" placeholder="Enter Blog" />
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <button type="submit" onClick={(e) => {
                                                    e.preventDefault()
                                                    searchList()
                                                }} className="btn btn-success waves-effect btn-label waves-light"><i className="bx bx-check-double label-icon"></i> Search</button>&nbsp;
                                                {/* <button type="button" className="btn btn-outline-danger">Reset</button> */}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* end page title */}
                        <div className="row">
                            <div className="col-xl-9 col-lg-8">
                                <div className="card">
                                    <ul className="nav nav-tabs nav-tabs-custom justify-content-center " role="tablist">
                                        {/* <li className="nav-item">
                                            <a className="nav-link active" data-bs-toggle="tab" href="#all-post" role="tab">
                                                All Post
                                            </a>
                                        </li> */}
                                        {/* <li className="nav-item">
                                            <a className="nav-link" data-bs-toggle="tab" href="#archive" role="tab">
                                                Archive
                                            </a>
                                        </li> */}
                                    </ul>
                                    <div className="tab-content p-4">
                                        <div className="tab-pane active" id="all-post" role="tabpanel">
                                            <div>
                                                <div className="row justify-content-center">
                                                    <div className="col-xl-8">
                                                        <div>
                                                            <div className="row align-items-center">
                                                                {/* <div className="col-4">
                                                                    <div>
                                                                        <h5 className="mb-0">Blog List</h5>
                                                                    </div>
                                                                </div> */}
                                                                {/* <div className="col-8">
                                                                    <div>
                                                                        <ul className="nav nav-pills justify-content-end">
                                                                            <li className="nav-item">
                                                                                <a className="nav-link disabled" href="#" tabIndex={-1} aria-disabled="true">View :</a>
                                                                            </li>
                                                                            <li className="nav-item" data-bs-placement="top" title="List">
                                                                                <a className="nav-link active" href="blog-list.html">
                                                                                    <i className="mdi mdi-format-list-bulleted" />
                                                                                </a>
                                                                            </li>
                                                                            <li className="nav-item" data-bs-placement="top" title="Grid">
                                                                                <a className="nav-link" href="blog-grid.html">
                                                                                    <i className="mdi mdi-view-grid-outline" />
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div> */}
                                                            </div>
                                                            {/* end row */}

                                                            {
                                                                props.showLoader && <Loader />
                                                            }
                                                            {
                                                                props.list && props.list.length > 0 && props.list.map((ele, index) => {
                                                                    return (

                                                                        <div className='hrline'>

                                                                            {/* <div style={{ textAlign: "right", fontSize: "20px" }}> <Link to={constant.component.editblog.url.replace(':id', ele.BLOG_ID)} title="Edit Blog"><i className="bx bx-edit"></i></Link></div>
                                                                            <div style={{ textAlign: "right", fontSize: "20px" }}> <a href="javascript: void(0)" onClick={() => updateStatus(ele.BLOG_ID, ele.STATUS)} style={{ color: 'red' }} title={ele.STATUS == 'U' ? "Archive Record" : "Unarchive Record"}>
                                                                                {
                                                                                    ele.STATUS == 'U' ? <i className="bx bx-trash"></i> : <i class="bx bx-log-in"></i>
                                                                                }
                                                                            </a>
                                                                            </div> */}

                                                                            <div style={{ display: "flex", justifyContent: "space-between", }} >
                                                                                <div >
                                                                                    <h5><a className="text-dark">{ele.BLOG_TITLE}</a></h5>
                                                                                </div>

                                                                                <div style={{ fontSize: "20px" }} >
                                                                                    <Link to={constant.component.editblog.url.replace(':id', ele.BLOG_ID)} title="Edit Blog"><i className="bx bx-edit"></i></Link>
                                                                                </div>

                                                                            </div>





                                                                            <div style={{ display: "flex", justifyContent: "space-between", }} >
                                                                                <div >
                                                                                    <p className="text-muted">{moment(ele.CREATED_ON).format('DD MMM, YYYY')}</p>

                                                                                </div>

                                                                                <div style={{ fontSize: "20px" }} >
                                                                                    <a href="javascript: void(0)" onClick={() => updateStatus(ele.BLOG_ID, ele.STATUS)} style={{ color: 'red' }} title={ele.STATUS == 'Y' ? "Archive Record" : "Unarchive Record"}>
                                                                                        {
                                                                                            ele.STATUS == 'Y' ? <i className="bx bx-trash"></i> : <i class="bx bx-log-in"></i>
                                                                                        }
                                                                                    </a>
                                                                                </div>

                                                                            </div>


                                                                            <div className="position-relative mb-3">

                                                                                <img src={`${process.env.REACT_APP_BASE_URL}/blog/Picture/${ele.PICTURE}`} alt="BLOG" className="img-thumbnail" />
                                                                                {/* <h5><a href="blog-details.html" className="text-dark">{ele.BLOG_CATEGORY}</a></h5> */}
                                                                            </div>
                                                                            <ul className="list-inline">
                                                                                <li className="list-inline-item me-3 text-primary">
                                                                                    <a href="javascript: void(0);" className="">
                                                                                        <i className="bx bx-purchase-tag-alt align-middle text-primary me-1 " /> {ele.BLOG_CATEGORY}
                                                                                    </a>
                                                                                </li>
                                                                                <li className="list-inline-item me-3">
                                                                                    <Link to={{
                                                                                        pathname: constant.component.blogCommentList.url.replace(':id', ele.BLOG_ID)
                                                                                    }}
                                                                                        className="text-muted">
                                                                                        <i className="bx bx-comment-dots align-middle text-muted me-1" /> {ele.commentCount} Comments
                                                                                    </Link>
                                                                                </li>
                                                                            </ul>




                                                                            {/* <p>{ele.BLOG_DETAILS.length > 100 ? ele.BLOG_DETAILS.slice(0, 100) + '...' : ele.BLOG_DETAILS}</p> */}
                                                                            {/* {nl2br(ele.BLOG_DETAILS)} */}

                                                                            {Parser(ele.BLOG_DETAILS)}



                                                                            { /* <ReactReadMoreReadLess
                                                                                charLimit={50}
                                                                                readMoreText={<a href="javascript: void(0);" className="text-primary">  Read more   <i className="mdi mdi-arrow-right" /></a>}
                                                                                readLessText={rl}
                                                                                readMoreClassName="read-more-less--more"
                                                                                readLessClassName="read-more-less--less" >
                                                                                {/* {Parser(ele.BLOG_DETAILS)} */}

                                                                            {/* {ele.BLOG_DETAILS.replace(/<[^>]+>/g, '')} */}


                                                                            {/* {!showLess ? ele.BLOG_DETAILS.replace(/<[^>]+>/g, '') : ele.BLOG_DETAILS  } */}

                                                                            { /* </ReactReadMoreReadLess> */}

                                                                            {/* <div>
                                                                                <a href="javascript: void(0);" className="text-primary">  Read more   <i className="mdi mdi-arrow-right" /></a>
                                                                            </div> */}







                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                            {
                                                                <>
                                                                    {
                                                                        props.list && props.list.length == 0 && props.showLoader == false &&
                                                                        <td colSpan={10} className='text-center text-danger pt-3 d-block'>No Record Found</td>
                                                                    }
                                                                </>
                                                            }
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <ul className="pagination pagination-rounded justify-content-center mt-4">
                                                                        <Pagination
                                                                            activePage={currentPage}
                                                                            itemsCountPerPage={5}
                                                                            totalItemsCount={props.count}
                                                                            pageRangeDisplayed={10}
                                                                            onChange={handlePageChange}
                                                                            itemClass="page-item"
                                                                            linkClass="page-link"
                                                                        />
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="tab-pane" id="archive" role="tabpanel">
                                            <div>
                                                <div className="row justify-content-center">
                                                    <div className="col-xl-8">
                                                        <h5>Archive</h5>
                                                        <div className="mt-5">
                                                            <div className="d-flex flex-wrap">
                                                                <div className="me-2">
                                                                    <h4>2020</h4>
                                                                </div>
                                                                <div className="ms-auto">
                                                                    <span className="badge badge-soft-success rounded-pill float-end ms-1 font-size-12">03</span>
                                                                </div>
                                                            </div>
                                                            <hr className="mt-2" />
                                                            <div className="list-group list-group-flush">
                                                                <a href="blog-details.html" className="list-group-item text-muted"><i className="mdi mdi-circle-medium me-1" /> Beautiful Day with Friends</a>
                                                                <a href="blog-details.html" className="list-group-item text-muted"><i className="mdi mdi-circle-medium me-1" /> Drawing a sketch</a>
                                                                <a href="blog-details.html" className="list-group-item text-muted"><i className="mdi mdi-circle-medium me-1" /> Project discussion with team</a>
                                                            </div>
                                                        </div>
                                                        <div className="mt-5">
                                                            <div className="d-flex flex-wrap">
                                                                <div className="me-2">
                                                                    <h4>2019</h4>
                                                                </div>
                                                                <div className="ms-auto">
                                                                    <span className="badge badge-soft-success rounded-pill float-end ms-1 font-size-12">06</span>
                                                                </div>
                                                            </div>
                                                            <hr className="mt-2" />
                                                            <div className="list-group list-group-flush">
                                                                <a href="blog-details.html" className="list-group-item text-muted"><i className="mdi mdi-circle-medium me-1" /> Coffee with Friends</a>
                                                                <a href="blog-details.html" className="list-group-item text-muted"><i className="mdi mdi-circle-medium me-1" /> Neque porro quisquam est</a>
                                                                <a href="blog-details.html" className="list-group-item text-muted"><i className="mdi mdi-circle-medium me-1" /> Quis autem vel eum iure</a>
                                                                <a href="blog-details.html" className="list-group-item text-muted"><i className="mdi mdi-circle-medium me-1" /> Cras mi eu turpis</a>
                                                                <a href="blog-details.html" className="list-group-item text-muted"><i className="mdi mdi-circle-medium me-1" /> Drawing a sketch</a>
                                                                <a href="blog-details.html" className="list-group-item text-muted"><i className="mdi mdi-circle-medium me-1" /> Project discussion with team</a>
                                                            </div>
                                                        </div>
                                                        <div className="mt-5">
                                                            <div className="d-flex flex-wrap">
                                                                <div className="me-2">
                                                                    <h4>2018</h4>
                                                                </div>
                                                                <div className="ms-auto">
                                                                    <span className="badge badge-soft-success rounded-pill float-end ms-1 font-size-12">03</span>
                                                                </div>
                                                            </div>
                                                            <hr className="mt-2" />
                                                            <div className="list-group list-group-flush">
                                                                <a href="blog-details.html" className="list-group-item text-muted"><i className="mdi mdi-circle-medium me-1" /> Beautiful Day with Friends</a>
                                                                <a href="blog-details.html" className="list-group-item text-muted"><i className="mdi mdi-circle-medium me-1" /> Drawing a sketch</a>
                                                                <a href="blog-details.html" className="list-group-item text-muted"><i className="mdi mdi-circle-medium me-1" /> Project discussion with team</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4">
                                <div className="card">
                                    <div className="card-body p-4">
                                        {/* <div className="search-box">
                                            <p className="text-muted">Search</p>
                                            <div className="position-relative">
                                                <input type="text" className="form-control rounded bg-light border-light" placeholder="Search..." />
                                                <i className="mdi mdi-magnify search-icon" />
                                            </div>
                                        </div> */}
                                        <div>
                                            <h5 className="text-muted">Categories</h5>
                                            <hr className="my-4" />

                                            <ul className="list-unstyled fw-medium">

                                                {props.categoryList && props.categoryList.length > 0 ?
                                                    props.categoryList.map((ele) => {
                                                        return (

                                                            <>
                                                                <li><a href="javascript: void(0);" className="text-muted py-2 d-block"><i className="mdi mdi-chevron-right me-1" /> {ele.CATEGORY}</a></li>

                                                            </>
                                                        )

                                                    })
                                                    :
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>

                                                        <Spinner animation="border" variant="info"
                                                            style={{ width: '1rem', height: '1rem' }} />

                                                    </div>

                                                }


                                                {/*                   
                                                <li><a href="javascript: void(0);" className="text-muted py-2 d-block"><i className="mdi mdi-chevron-right me-1" /> Design</a></li>
                                                <li><a href="javascript: void(0);" className="text-muted py-2 d-block"><i className="mdi mdi-chevron-right me-1" /> Development <span className="badge badge-soft-success rounded-pill float-end ms-1 font-size-12">04</span></a></li>
                                                <li><a href="javascript: void(0);" className="text-muted py-2 d-block"><i className="mdi mdi-chevron-right me-1" /> Business</a></li>
                                                <li><a href="javascript: void(0);" className="text-muted py-2 d-block"><i className="mdi mdi-chevron-right me-1" /> Project</a></li>
                                                <li><a href="javascript: void(0);" className="text-muted py-2 d-block"><i className="mdi mdi-chevron-right me-1" /> Travel<span className="badge badge-soft-success rounded-pill ms-1 float-end font-size-12">12</span></a></li> */}


                                            </ul>
                                        </div>
                                        {/* <hr className="my-4" /> */}
                                        {/* <div>
                                            <p className="text-muted">Archive</p>
                                            <ul className="list-unstyled fw-medium">
                                                <li><a href="javascript: void(0);" className="text-muted py-2 d-block"><i className="mdi mdi-chevron-right me-1" /> 2020 <span className="badge badge-soft-success rounded-pill float-end ms-1 font-size-12">03</span></a></li>
                                                <li><a href="javascript: void(0);" className="text-muted py-2 d-block"><i className="mdi mdi-chevron-right me-1" /> 2019 <span className="badge badge-soft-success rounded-pill float-end ms-1 font-size-12">06</span></a></li>
                                                <li><a href="javascript: void(0);" className="text-muted py-2 d-block"><i className="mdi mdi-chevron-right me-1" /> 2018 <span className="badge badge-soft-success rounded-pill float-end ms-1 font-size-12">05</span></a></li>
                                            </ul>
                                        </div> */}
                                        {/* <hr className="my-4" />

                                        <hr className="my-4" /> */}
                                        {/* <div>
                                            <p className="text-muted">Tags</p>
                                            <div className="d-flex flex-wrap gap-2 widget-tag">
                                                <div><a href="javascript: void(0);" className="badge bg-light font-size-12">Design</a></div>
                                                <div><a href="javascript: void(0);" className="badge bg-light font-size-12">Development</a></div>
                                                <div><a href="javascript: void(0);" className="badge bg-light font-size-12">Business</a></div>
                                                <div><a href="javascript: void(0);" className="badge bg-light font-size-12">Project</a></div>
                                                <div><a href="javascript: void(0);" className="badge bg-light font-size-12">Travel</a></div>
                                                <div><a href="javascript: void(0);" className="badge bg-light font-size-12">Lifestyle</a></div>
                                                <div><a href="javascript: void(0);" className="badge bg-light font-size-12">Photography</a></div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment >
    )
}
