import { logDOM } from '@testing-library/react';
import React, { useState } from 'react'
import { getDateParts } from '../../../utils';
import Parser from "react-html-parser";
import { Link } from "react-router-dom";
import constant from '../../../constant';


export default function Profile(props) {
    const detail = props.detail;
    const [jobFullDetails, setJobFullDetails] = useState({});

    const updateStatus = (JOB_ID, STATUS) => {
        const model = {
          JOB_ID: JOB_ID,
          STATUS: STATUS === "U" ? "A" : "U",
        };
        props.updateStatus(model);
      };
    
   
  console.log(props.list,"props.list");
    return (
        <React.Fragment>

            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 font-size-18">Profile</h4>
                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="javascript: void(0);">Admin User</a></li>
                                            <li className="breadcrumb-item active">Profile</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-4">
                                <div className="card overflow-hidden">
                                    <div className="bg-primary bg-soft">
                                        <div className="row">
                                            <div className="col-7">
                                                <div className="text-primary p-3">
                                                    <h5 className="text-primary">Welcome Back !</h5>
                                                    <p>It will seem like simplified</p>
                                                </div>
                                            </div>
                                            <div className="col-5 align-self-end">
                                                <img src="assets/images/profile-img.png" alt="" className="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body pt-0">
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <div className="avatar-md profile-user-wid mb-4">
                                                    {detail.PROFILE_PICTURE ? <img src={`${process.env.REACT_APP_BASE_URL}/admin/profile/pic/${detail.PROFILE_PICTURE}`} alt="" className="img-thumbnail rounded-circle" style={{ objectFit: "cover" }} /> : <img src="assets/images/users/avatar-2.webp" alt="" style={{ width: '50px', height: "50px", objectFit: "cover", borderRadius: "50%" }} />}
                                                </div>
                                                <h5 className="font-size-15 text-truncate">{detail.FIRST_NAME} {detail.LAST_NAME}</h5>
                                                <p className="text-muted mb-0 text-truncate">{detail.ROLE_NAME}</p>
                                            </div>
                                            <div className="col-sm-8">
                                                <div className="pt-4">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <h5 className="font-size-15">{props.candidateCount}</h5>
                                                            <p className="text-muted mb-0">Candidates</p>
                                                        </div>
                                                        <div className="col-6">
                                                            <h5 className="font-size-15">{props.employerCount}</h5>
                                                            <p className="text-muted mb-0">Employers</p>
                                                        </div>
                                                    </div>
                                                    {/* <div className="mt-4">
                                                        <a href="" className="btn btn-primary waves-effect waves-light btn-sm">Activity Log <i className="mdi mdi-arrow-right ms-1" /></a>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">Personal Information</h4>
                                        <p className="text-muted mb-4">{detail.OTHER_DETAILS}</p>
                                        <div className="table-responsive">
                                            <table className="table table-nowrap mb-0">
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">Full Name :</th>
                                                        <td>{detail.FIRST_NAME} {detail.LAST_NAME}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Mobile :</th>
                                                        <td>{detail.PHONE}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">E-mail :</th>
                                                        <td>{detail.EMAIL_ID}</td>
                                                    </tr>
                                                    {/* <tr>
                                                        <th scope="row">Location :</th>
                                                        <td>Mumbai, India</td>
                                                    </tr> */}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                {/* end card */}
                                {/* <div class="card">
                                    <div class="card-body">
                                        <h4 class="card-title mb-5">Experience</h4>
                                        <div class="">
                                            <ul class="verti-timeline list-unstyled">
                                                <li class="event-list active">
                                                    <div class="event-timeline-dot">
                                                        <i class="bx bx-right-arrow-circle bx-fade-right"></i>
                                                    </div>
                                                    <div class="d-flex">
                                                        <div class="flex-shrink-0 me-3">
                                                            <i class="bx bx-server h4 text-primary"></i>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <div>
                                                                <h5 class="font-size-15"><a href="javascript: void(0);" class="text-dark">Back end Developer</a></h5>
                                                                <span class="text-primary">2016 - 19</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li class="event-list">
                                                    <div class="event-timeline-dot">
                                                        <i class="bx bx-right-arrow-circle"></i>
                                                    </div>
                                                    <div class="d-flex">
                                                        <div class="flex-shrink-0 me-3">
                                                            <i class="bx bx-code h4 text-primary"></i>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <div>
                                                                <h5 class="font-size-15"><a href="javascript: void(0);" class="text-dark">Front end Developer</a></h5>
                                                                <span class="text-primary">2013 - 16</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li class="event-list">
                                                    <div class="event-timeline-dot">
                                                        <i class="bx bx-right-arrow-circle"></i>
                                                    </div>
                                                    <div class="d-flex">
                                                        <div class="flex-shrink-0 me-3">
                                                            <i class="bx bx-edit h4 text-primary"></i>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <div>
                                                                <h5 class="font-size-15"><a href="javascript: void(0);" class="text-dark">UI /UX Designer</a></h5>
                                                                <span class="text-primary">2011 - 13</span>
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>   */}
                                {/* end card */}
                            </div>
                            <div className="col-xl-8">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="card mini-stats-wid">
                                            <div className="card-body">
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <p className="text-muted fw-medium mb-2">Job Posted</p>
                                                        <h4 className="mb-0">{props.jobCount}</h4>
                                                    </div>
                                                    <div className="flex-shrink-0 align-self-center">
                                                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary">
                                                            <span className="avatar-title">
                                                                <i className="bx bx-paper-plane font-size-24" />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="card mini-stats-wid">
                                            <div className="card-body">
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <p className="text-muted fw-medium mb-2">Pending Jobs</p>
                                                        <h4 className="mb-0">{props.pendingjobCount}</h4>
                                                    </div>
                                                    <div className="flex-shrink-0 align-self-center">
                                                        <div className="avatar-sm mini-stat-icon rounded-circle bg-primary">
                                                            <span className="avatar-title">
                                                                <i className="bx bx-briefcase font-size-24" />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="card mini-stats-wid">
                                            <div className="card-body">
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <p className="text-muted fw-medium mb-2">Employers</p>
                                                        <h4 className="mb-0">{props.employerCount}</h4>
                                                    </div>
                                                    <div className="flex-shrink-0 align-self-center">
                                                        <div className="avatar-sm mini-stat-icon rounded-circle bg-primary">
                                                            <span className="avatar-title">
                                                                <i className="bx bx-user font-size-24" />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">Latest Jobs</h4>
                                        <div className="table-responsive">
                                            <table className="table align-middle table-nowrap table-hover">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col" style={{ width: '70px' }}>Job ID</th>
                                                        <th scope="col">Job Information	</th>
                                                        <th scope="col">JobzShala Stats</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    { props.list.map((item,index)=>{
                                                        if(index<3){
                                                   return(
                                                    <tr>
                                                    <td style={{ color: '#ff8000', fontSize: '15px', fontFamily: 'nunito' }}>
                                                        {item.CUSTOM_JOB_ID}<br />
                                                    </td>
                                                    <td>
                                                        <h5 className="font-size-14 mb-1"><a href="#" className="text-dark"> <a href="#" className="text-dark">
                                        {item.JOB_TITLE.length > 25
                                          ? item.JOB_TITLE.slice(0, 30) + "..."
                                          : item.JOB_TITLE}
                                      </a></a></h5>
                                                        <p className="text-muted mb-0" style={{ color: '#dd006f !important', fontSize: '12px' }}> {item.LISTNING_TYPE == "1"
                                        ? "REGULAR"
                                        : item.LISTNING_TYPE == "2"
                                          ? "FEATURED"
                                          : item.LISTNING_TYPE == "2"
                                            ? "PREMIUM"
                                            : ""}</p>
                                                       {item.CTC_MIN && item.CTC_MAX && (
                                      <p
                                        className="text-muted mb-0"
                                        style={{ color: "#00add2" }}
                                      >
                                        &#x20b9;{" "}
                                        {` ${item.CTC_MIN} -  ${item.CTC_MAX}  L.P.A.`}
                                      </p>
                                    )}
                                                        <p className="text-muted mb-0" style={{ color: '#0080ff !important' }}><i className="bx bx-street-view  font-size-16 align-middle me-1" />  {item.JOB_LOCATIONS &&
                                        item.JOB_LOCATIONS.length > 0
                                        ? item.JOB_LOCATIONS.map(
                                          (item, index) => {
                                            return item.CITY + " ";
                                          }
                                        )
                                        : "Not Available "}</p>
                                                        <p className="text-muted mb-0"><span style={{ color: '#f46a6a' }}>Posted </span> {getDateParts(item.CREATED_ON).relativeTime.split(' ')[0] == 'a' ? '1 ' + getDateParts(item.CREATED_ON).relativeTime.split(' ').splice(1).join(' ') : getDateParts(item.CREATED_ON).relativeTime} </p>
                                                    </td>
                                                    <td>
                                                        <h5 className="font-size-14 mb-1"><a href="javascript:void(0);" className="text-dark">Candidates Applied (0)</a></h5>
                                                        <p className="text-muted mb-0"><a href="javascript:void(0);" className="text-dark"><span style={{ color: '#009999' }}>Rejected (0) </span></a> <br />
                                                            <a href="javascript:void(0);" className="text-dark"><span style={{ color: '#008040' }}>Shortlisted (0)</span></a>
                                                            <br />
                                                            <a href="javascript:void(0);" className="text-dark"><span style={{ color: '#ff00ff' }}>Candidates Hold ()</span></a>
                                                        </p>
                                                    </td>
                                                    <td>
                                    {item.STATUS == "U" ? (
                                      <button
                                        type="button"
                                        className="btn btn-success btn-sm waves-effect waves-light"
                                      >
                                        ACTIVE
                                      </button>
                                    ) : (
                                      <button
                                        type="button"
                                        className="btn btn-danger btn-sm waves-effect waves-light"
                                      >
                                        ARCHIVE
                                      </button>
                                    )}
                                  </td>
                                  <td>
                                      <ul className="list-inline font-size-20 contact-links mb-0">
                                      <li className="list-inline-item px-2"><a href="javascript: void(0);"
                                        data-bs-toggle="modal"
                                        data-bs-target="#staticBackdrop41"

                                        onClick={(e) => {
                                          setJobFullDetails(item);
                                        }}
                                                        
                                        title="View Jobs"><i className="bx bxs-zoom-in"></i></a></li>
                                        <li className="list-inline-item px-2">
                                        <Link
                                          to={constant.component.editJobs.url.replace(
                                            ":id",
                                            item.JOB_ID
                                          )}
                                        >
                                          <i
                                            className="bx bx-edit"
                                            title="Edit Jobs "
                                          />
                                        </Link>
                                      </li>
                                      <li className="list-inline-item px-2">
                                        <a
                                          href="javascript: void(0);"
                                          onClick={() =>
                                            updateStatus(
                                              item.JOB_ID,
                                              item.STATUS
                                            )
                                          }
                                          style={{ color: "red" }}
                                          title={
                                            item.STATUS == "U"
                                              ? "Archive Record"
                                              : "Unarchive Record"
                                          }
                                        >
                                          {item.STATUS == "U" ? (
                                            <i className="bx bx-trash"></i>
                                          ) : (
                                            <i class="bx bx-log-in"></i>
                                          )}
                                        </a>
                                      </li>
                                                        </ul>

                                                        <div className="modal fade" id="staticBackdrop4112" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title" id="staticBackdropLabel">Job Information</h5>
                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <p>
                                                                        </p><table className="table table-striped mb-0">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>Job description</td>
                                                                                    <td>Understand requirements &amp; functional specifications<br />Develop web applications, dashboards,APIs<br />Troubleshoot, test, maintain code &amp; its document<br />Analyse, design, code, debug, test, document &amp; deploy changes<br />Ensure foolproof performance of deliverable</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Required Candidate profile</td>
                                                                                    <td>Degree in B.TECH, BCA or equivalent<br />Excellent command on PHP/MySQL with Laravel Framework<br />Experienced in Vue, Laravel, Jquery &amp; AJAX<br />Good OOP knowledge<br />API based development knowledge is must</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Role</td>
                                                                                    <td>Software Development - Other</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Industry Type</td>
                                                                                    <td>Advertising &amp; Marketing</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Functional Area</td>
                                                                                    <td>Engineering - Software</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Employment Type</td>
                                                                                    <td>Full Time, Permanent</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Role Category</td>
                                                                                    <td>Software Development</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Education</td>
                                                                                    <td>UG : BCA in Any Specialization, B.Tech/B.E. in Any Specialization, Other Graduate</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Key Skills</td>
                                                                                    <td>Mysql Database, Web Application Development, JQuery<br /> Laravel, Laravel Php, Vue.Js, Laravel, Php<br /> Framework, MySQL, Javascript, PHP Vue Framework, API<br /> Web Development, Ajax</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                        <p />
                                                                    </div>
                                                                    <div className="modal-footer">
                                                                        <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </td>
                                                </tr>
                                                   )}
                                                    })}
                                                  
            
                                                </tbody>
                                            </table>
                                        </div>
                                        <div
                            className="modal fade"
                            id="staticBackdrop41"
                            data-bs-backdrop="static"
                            data-bs-keyboard="false"
                            tabindex="-1"
                            role="dialog"
                            aria-labelledby="staticBackdropLabel"
                            aria-hidden="true"
                            style={{ overflowY: "initial" }}
                          >
                            <div
                              className="modal-dialog modal-dialog-centered modal-lg"
                              role="document"
                            >
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h5
                                    className="modal-title"
                                    id="staticBackdropLabel"
                                  >
                                    Job Information
                                  </h5>
                                  <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>

                                <div
                                  className="modal-body weightauto"
                                  style={{ height: "80vh", overflow: "auto" }}
                                >
                                  {jobFullDetails && (
                                    <p>
                                      <table className="table table-striped mb-0">
                                        <tbody>
                                          <tr>
                                            <td style={{ width: "20%" }}>
                                              Job description
                                            </td>
                                            <td>
                                              {jobFullDetails.JOB_DETAILS &&
                                                Parser(
                                                  jobFullDetails.JOB_DETAILS
                                                )}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Required Candidate profile</td>
                                            <td>
                                              {jobFullDetails.COURSE_SPECIALIZATION &&
                                                jobFullDetails
                                                  .COURSE_SPECIALIZATION
                                                  .length > 0 &&
                                                jobFullDetails
                                                  .COURSE_SPECIALIZATION[0]
                                                  .COURSE_STREAM &&
                                                ` Degree in ${jobFullDetails.COURSE_SPECIALIZATION &&
                                                [...new Set(jobFullDetails.COURSE_SPECIALIZATION.map(
                                                  (data, index) => {
                                                    return data.COURSE_STREAM;
                                                  }
                                                ))]
                                                }`}
                                              {/* {`  ${
                                                jobFullDetails.COURSE_SPECIALIZATION &&
                                                jobFullDetails.COURSE_SPECIALIZATION.map(
                                                  (data, index) => {
                                                    return data.SPECIALIZATION;
                                                  }
                                                )
                                              }`} */}

                                              {/* {(`Degree in ${jobFullDetails.COURSE_SPECIALIZATION && jobFullDetails.COURSE_SPECIALIZATION.map((data,index)=>{
                                                          return data.COURSE_STREAM
                                                        }) }`)} */}
                                              <br />
                                              {/* { (` ${jobFullDetails.COURSE_SPECIALIZATION && jobFullDetails.COURSE_SPECIALIZATION.map((data,index)=>{
                                                          return data.SPECIALIZATION
                                                        }) }`)} */}
                                              <br />
                                              {`Excellent command on  ${jobFullDetails &&
                                                jobFullDetails.KEYWORDS
                                                }`}

                                              <br />
                                              {/* {`Excellent command on ${jobFullDetails.JOB_DETAILS && Parser(jobFullDetails.JOB_DETAILS)}`} */}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Role</td>
                                            <td>{jobFullDetails.ROLE_NAME}</td>
                                          </tr>
                                          <tr>
                                            <td>Industry Type</td>
                                            <td>{jobFullDetails.INDUSTRY}</td>
                                          </tr>
                                          <tr>
                                            <td>Functional Area</td>
                                            <td>
                                              {jobFullDetails.FUNCTIONAL_AREA}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Employment Type</td>
                                            <td>
                                              {jobFullDetails.EMPLOYMENT_TYPE}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Role Category</td>
                                            <td>{jobFullDetails.JOB_TITLE}</td>
                                          </tr>
                                          <tr>
                                            <td>Education</td>
                                            <td>
                                              {`  ${jobFullDetails.COURSE_SPECIALIZATION &&
                                                [...new Set(jobFullDetails.COURSE_SPECIALIZATION.map(
                                                  (data, index) => {
                                                    return data.QUALIFICATION_NAME;
                                                  }
                                                ))]
                                                }`}
                                              <br />
                                              {`  ${jobFullDetails.COURSE_SPECIALIZATION &&
                                                [...new Set(jobFullDetails.COURSE_SPECIALIZATION.map(
                                                  (data, index) => {
                                                    return data.COURSE_STREAM;
                                                  }
                                                ))]
                                                }`}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Key Skills</td>
                                            <td>{jobFullDetails.KEYWORDS}</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </p>
                                  )}
                                </div>
                                <div className="modal-footer">
                                  <button
                                    type="button"
                                    className="btn btn-light"
                                    data-bs-dismiss="modal"
                                  >
                                    Close
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <Link to={constant.component.listJobs.url}>
                                                <button type="button" className="btn btn-primary waves-effect btn-label waves-light"><i className="bx bxs-right-arrow-square label-icon" /> View All Jobs</button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
