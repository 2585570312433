import React, { useEffect, useState } from 'react'
import { addBlog, getActiveBlogCategory, uploadBlogPicture } from '../../../actions/utilitiesAction'
import Addblog from '../../../component/Utilities/Blog/AddBlog'
export default function AddBlog() {

    const [response, setResponse] = React.useState({})
    const [showMsg, setShowMsg] = React.useState(false)


    const [list, setList] = useState([])
    const onSubmit = (model) => {
        
        addBlog(model).then((res) => {
            // if (res.status)
            //     alert(res.messageCode)
            setResponse(res)
            setShowMsg(true)
            setTimeout(() => {
                setShowMsg(false)
            }, 7000)
        }).catch((err) => {
            alert(err)
        })
    }

    useEffect(() => {
        getActiveBlogCategory().then((res) => {
            setList(res.result)
        }).catch((err) => {
            alert(err)
        })
    }, [])

    return (
        <React.Fragment>
            <Addblog
                list={list}
                onSubmit={onSubmit}
                response={response}
                showMsg={showMsg} />
        </React.Fragment>
    )
}
