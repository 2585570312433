import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { listEmployer, updateEmployerStatus, createEmployernotes } from '../../actions/employer'
import Employerslist from '../../component/Employers/EmployerList'
import constant from '../../constant'
import queryString from 'query-string'
import { getStorage } from '../../utils'
import { listEmployernotes } from '../../actions/employer'
import { deleteempnotes } from '../../actions/employer'

export default function EmployersList() {
    let params = useParams()
    const [list, setList] = useState([])
    const [notes, setNotes] = useState([])
    const [count, setCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [showLoader, setShowLoader] = useState(true)
    const [keyword, setKeyword] = useState('')
    const [response, setResponse] = React.useState({})

    const location = useLocation()


    const {ADMIN_ID} = getStorage(constant.keys.userDetails)

    useEffect(() => {

        const qParams = queryString.parse(location.search)
        document.title = constant.title.EmployersList
        getEmployerList(qParams?.p ? qParams?.p :
            qParams?.pageNo ? qParams.pageNo : currentPage, '')

    }, [currentPage])

    const getEmployerList = (page) => {
        setShowLoader(true)
        listEmployer({ page: page, KEYWORD: keyword.trim().toLowerCase() }).then(res => {
            if (res.status) {
                setList(res.result.list)
                setCount(res.result.count.total)
                setShowLoader(false)
            }
            else {
                alert(res.error)
            }
        }).catch(err => {
            alert(err)
        })

    }

    const EmployerNotesList = (EMPLOYER_ID) => {
        listEmployernotes(EMPLOYER_ID).then(res => {
            if (res.status) {
                setNotes(res.result.list)
                setShowLoader(false)
               
            }
            else {
                alert(res.error)
            }
        }).catch(err => {
            alert(err)
        })
    }



    const updateStatus = (model) => {
        updateEmployerStatus(model).then(res => {
            getEmployerList(currentPage, "")
        }).catch(err => {
            alert(err)
        })
    }

    const saveNotes = (note, EMPLOYER_ID) => {

        let model = {
            NOTES: note,
            EMPLOYER_ID: EMPLOYER_ID,
            CREATED_BY:ADMIN_ID,
            MODIFIED_BY:ADMIN_ID
        }

        createEmployernotes(model).then((res) => {
            setResponse(res)
            EmployerNotesList(EMPLOYER_ID)
        }).catch((err) => [
            alert(err)
        ])
    }

    const deletenotes = (NOTES_ID,EMPLOYER_ID) => {
        deleteempnotes({NOTES_ID:NOTES_ID}).then((res) => {
            if (res.status) {
                EmployerNotesList(EMPLOYER_ID)
            }
            else {
                alert(res.error)
            }
        })
    }






    return (
        <React.Fragment>

            <Employerslist
                list={list}
                notes={notes}
                getEmployerList={(page) => { getEmployerList(page) }}
                count={count}
                createNote={saveNotes}
                setPage={setCurrentPage}
                updateStatus={updateStatus}
                setShowLoader={setShowLoader}
                cp={currentPage}
                showLoader={showLoader}
                keyword={setKeyword}
                value={keyword}
                EmployerNotesList={(EMPLOYER_ID) => { EmployerNotesList(EMPLOYER_ID) }}
                delete={deletenotes}
                response={response}
            />
        </React.Fragment>
    )
}
