import React, { useEffect, useState } from 'react'
import Pagination from 'react-js-pagination';
import moment from 'moment';
import Swal from 'sweetalert2';
import Loader from '../../common/Loader';

export default function InterviewAnswer(props) {

    const [currentPage, setCurrentPage] = useState(1)
    const [showData, setShowData] = useState({})


    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        props.setPage(pageNumber)
    };

    const updateStatus = (INTERVIEW_QUESTIONS_ANSWER_ID, STATUS) => {
        const model = {
            INTERVIEW_QUESTIONS_ANSWER_ID: INTERVIEW_QUESTIONS_ANSWER_ID,
            STATUS: STATUS == 'U' ? 'A' : 'U'
        }
        props.setPage(currentPage)
        props.updateStatus(model)
    }

    const deleteAnswer = (INTERVIEW_QUESTIONS_ANSWER_ID) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to delete this Answer? ",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                props.deleteAnswerId(INTERVIEW_QUESTIONS_ANSWER_ID)
            }
        })

    }

    return (
        <React.Fragment>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0" style={{ fontSize: "18px" }}><span style={{ color: "#df0070" }}>{props.questionTitle} </span>| Answers</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="#">Answers</a></li>
                                            <li className="breadcrumb-item active">Answers</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table align-middle table-nowrap table-hover">
                                                {props.showLoader &&
                                                    <Loader />
                                                }
                                                {!props.showLoader && <thead className="table-light">
                                                    <tr>
                                                        <th scope="col" style={{ width: "70px" }}>S.No.</th>
                                                        <th scope="col" style={{ width: "500px" }}>Answers</th>
                                                        <th scope="col" style={{ width: "100px" }}>Added On</th>
                                                        <th scope="col" style={{ width: "100px" }}>Action</th>
                                                    </tr>
                                                </thead>}
                                                <tbody>
                                                    {props.list && props.list.length > 0 &&
                                                        props.list.map((item, index, list) => {
                                                            return (
                                                                <tr >
                                                                    <td>
                                                                        {currentPage == 1 ? `${index + 1}` : `${index == 9 ? currentPage : currentPage - 1}` + `${index == 9 ? 0 : index + 1}`}
                                                                    </td>

                                                                    <td style={{ whiteSpace: "normal" }}>
                                                                        {item.ANSWER.slice(0, 60)}
                                                                        {item.ANSWER.length > 50 && <a href="#" onClick={() => setShowData(item)} data-bs-toggle="modal" data-bs-target=".bs-example-modal-lg">...read more</a>}
                                                                    </td>
                                                                    <td>

                                                                        {moment(item.CREATED_ON).format('DD.MM.YYYY on hh:mma')}
                                                                    </td>
                                                                    <td>
                                                                        <ul className="list-inline contact-links mb-0" style={{ fontSize: "20px" }}>
                                                                            <li className="list-inline-item px-2" >
                                                                                <a href="javascript: void(0)" onClick={() => updateStatus(item.INTERVIEW_QUESTIONS_ANSWER_ID, item.STATUS)}>
                                                                                    {item.STATUS == 'U' ?
                                                                                        <i className="bx bx-check-circle" style={{ color: "#00ae00" }} title="Approved Comment! Reject Now"></i> : <i className="bx bx bxs-error" style={{ color: "#ff0f87" }} title="Pending Comment! Approve Now"></i>
                                                                                    }
                                                                                </a>
                                                                            </li>

                                                                            <li className="list-inline-item px-2">
                                                                                <a href="javascript: void(0);" onClick={() => deleteAnswer(item.INTERVIEW_QUESTIONS_ANSWER_ID)} style={{ color: "red" }} title="Delete User"><i className="bx bx-trash"></i></a>
                                                                            </li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                            )

                                                        })

                                                    }
                                                    {
                                                        <>
                                                            {
                                                                props.list && props.list.length == 0 && props.showLoader == false &&
                                                                <td colSpan={10} className="text-center text-danger pt-3">No Answers are Avaliable <br />Please Go Back</td>
                                                            }
                                                        </>
                                                    }
                                                    {
                                                        <div className="modal fade bs-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                                                            <div className="modal-dialog modal-lg">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title" id="myLargeModalLabel">{props.questionTitle}</h5>
                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                    </div>
                                                                    <div className="modal-body">

                                                                        <div className="mb-3">
                                                                            {showData.ANSWER}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <ul className="pagination pagination-rounded justify-content-center mt-4">
                                                    <Pagination
                                                        activePage={currentPage}
                                                        itemsCountPerPage={10}
                                                        totalItemsCount={props.count}
                                                        pageRangeDisplayed={5}
                                                        onChange={handlePageChange}
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                    />
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}
