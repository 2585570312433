import React, { useState } from 'react'
import Pagination from 'react-js-pagination';
import Loader from "../common/Loader"
import Swal from 'sweetalert2';
import FileSaver from 'file-saver';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import encodeurl from 'encodeurl';
import moment from 'moment';
import queryString from 'query-string'
const AppliedJobs = (props) => {

    const [currentPage, setCurrentPage] = useState(1);
    const [status, setStatus] = useState('');
    const location = useLocation()
    const navigate = useNavigate()

    const handlePageChange = (pageNumber) => {
        
        if (status != '') {
            setCurrentPage(pageNumber);
            navigate(location.pathname + '?p=' + pageNumber)
            props.getAppliedJobList(currentPage)
        }
        else {
            setCurrentPage(pageNumber);
            navigate(location.pathname + '?p=' + pageNumber)
            props.setPage(pageNumber)

        }
    };

    const qParams = queryString.parse(location.search)


    const onStatusChange = (value) => {
        handlePageChange(1)
        setStatus(value)
        props.setData(value)
        props.getAppliedJobList(currentPage, value)
    }

    const searchList = (val) => {
        setCurrentPage(1)
        // setKeyword(val)
        props.getAppliedJobList(1)

    }


    const downloadResume = async (input) => {
        const encoded = escape(input.RESUME_FILE);
        const RESUME_FILE = encoded;

        const CANDIDATE_ID = input.CANDIDATE_ID;
        FileSaver.saveAs(
            `${process.env.REACT_APP_BASE_URL}/candidate-resume/${CANDIDATE_ID}/${RESUME_FILE}`,
            RESUME_FILE
        );
    };

    const handleReset = () => {
        window.location.reload()
    }


    return (
        <React.Fragment>
            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">

                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 font-size-18">Jobs Applied</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="https://wireframecp.jobzshala.com/jobs-list.php">Job</a></li>
                                            <li className="breadcrumb-item active">Jobs Applied</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">


                                        <form className="row gy-12 gx-12 align-items-center">
                                            <div className="col-sm-2">
                                                <label className="visually-hidden" for="autoSizingInput">Name</label>
                                                Search Candidate Jobs
                                            </div>

                                            <div className="col-sm-3">
                                                <label className="visually-hidden" for="autoSizingInputGroup">Username</label>
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="autoSizingInputGroup"
                                                        placeholder="Enter Company Name"
                                                        // value={keyword}
                                                        onChange={(e) => {
                                                            props.setKeyword(e.target.value)
                                                        }}
                                                        onKeyDown={
                                                            (e) => {
                                                                if (e.key == 'Enter') {
                                                                    e.preventDefault()
                                                                    searchList()
                                                                }
                                                            }
                                                        }
                                                    />

                                                </div>
                                            </div>

                                            <div className="col-sm-3">
                                                <label className="visually-hidden" for="autoSizingInputGroup">Username</label>
                                                <div className="input-group">
                                                    <select className="form-select"
                                                        onChange={e => { onStatusChange(e.target.value) }}
                                                    >
                                                        <option>Select Status</option>
                                                        {props.StatusList && props.StatusList.map((item, index) => (
                                                            <option key={index} value={item.STATUS_ID}>{item.STATUS_NAME}</option>
                                                        ))}

                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-3">
                                                <button type="button" onClick={(e) => {
                                                    e.preventDefault()
                                                    searchList()
                                                }}
                                                    className="btn btn-primary waves-effect btn-label waves-light">
                                                    <i className="bx bx bx-box label-icon"></i> Search</button>
                                                &nbsp; <button type="button" className="btn btn-outline-danger" onClick={handleReset}>Reset</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4" style={{ fontSize: "18px", fontFamily: "Nunito", fontWeight: "500" }}></h4>
                                        <div className="table-responsive">
                                            <table className="table align-middle table-nowrap table-hover">
                                                {
                                                    props.showLoader &&
                                                    <Loader />
                                                }
                                                {!props.showLoader && <thead className="table-light">
                                                    <tr>
                                                        <th scope="col" style={{ width: "70px" }}>ID</th>

                                                        <th scope="col">Job Information</th>
                                                        <th scope="col">Candidate Details</th>
                                                        <th scope="col">Status</th>
                                                        {/* <th scope="col">Action</th> */}
                                                    </tr>
                                                </thead>
                                                }
                                                <tbody>

                                                    {props.list && props.list.length > 0 && props.list.map((item, index) => {
                                                        return (
                                                            <tr>
                                                                <td style={{ color: "#ff8000", fontSize: "15px", fontFamily: "nunito" }}>
                                                                    {item.CUSTOM_JOB_ID}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        item.JOB_TITLE !== null && <h5 className="font-size-14 mb-1" data-bs-toggle="modal" data-bs-target={"#staticBackdrop" + item.JOB_ID} title={item.JOB_TITLE}><a href='javascript:void(0)' className="text-dark">{item.JOB_TITLE.length > 25 ? item.JOB_TITLE.slice(0, 30) + "..." : item.JOB_TITLE}</a></h5>
                                                                    }
                                                                    {
                                                                        item.COMPANY_NAME !== null && <p className="text-muted mb-0" style={{ color: "#804000 !important" }}>{item.COMPANY_NAME}</p>
                                                                    }                                                                    <p className="text-muted mb-0" style={{ color: "#00add2" }}><i className="bx bx-rupee" style={{ color: "#f03818 " }} />{item.CTC_MIN} - {item.CTC_MAX} P.A.</p>
                                                                    {item.JOB_LOCATIONS !== null && <p className="text-muted mb-0" style={{ color: " #0080ff !important" }}><i className="bx bx-street-view  font-size-16 align-middle me-1"></i> {item.JOB_LOCATIONS}</p>}
                                                                    <p className="text-muted mt-2"><button type="button" data-bs-toggle="modal" data-bs-target={"#staticBackdrop" + item.JOB_ID} className="btn btn-info waves-effect  btn-label waves-light h-20"><i className="bx bx-street-view label-icon "></i> View Details</button></p>
                                                                    <div className="modal fade" id={"staticBackdrop" + item.JOB_ID} tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                                                                            <div className="modal-content">
                                                                                <div className="modal-header">
                                                                                    <h5 className="modal-title" id="staticBackdropLabel">Job Information</h5>
                                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                </div>

                                                                                <div className="modal-body weightauto" style={{overflow: "auto" }}>
                                                                                    <p>
                                                                                        <table className="table table-striped mb-0">
                                                                                            <tbody>

                                                                                                <tr>
                                                                                                    <td>Job description</td>
                                                                                                    <td>{item.JOB_DETAILS}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>Role</td>
                                                                                                    <td>{item.ROLE_NAME}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>Industry Type</td>
                                                                                                    <td>{item.INDUSTRY}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>Functional Area</td>
                                                                                                    <td>{item.FUNCTIONAL_AREA}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>Employment Type</td>
                                                                                                    <td>{item.EMPLOYMENT_TYPE}</td>
                                                                                                </tr>
                                                                                                {/* <tr>
                                                                                                    <td>Role Category</td>
                                                                                                    <td>Software Development</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>Education</td>
                                                                                                    <td>UG : BCA in Any Specialization, B.Tech/B.E. in Any Specialization, Other Graduate</td>
                                                                                                </tr> */}
                                                                                                <tr>
                                                                                                    <td>Key Skills</td>
                                                                                                    <td>{item.KEYWORDS}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>Address</td>
                                                                                                    <td>{item.ADDRESS?item.ADDRESS:'null'}</td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </p>
                                                                                </div>
                                                                                <div className="modal-footer">
                                                                                    <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </td>
                                                                <td>
                                                                    {item.CANDIDATE_NAME !== null && <h5 className="font-size-14 mb-1"><a href="#" className="text-dark">{item.CANDIDATE_NAME}</a></h5>}
                                                                    {item.CANDIDATE_EMAIL !== null && <p className="text-muted mb-0" > <i style={{ color: "#fae60f " }} className="bx bx-envelope"></i> {item.CANDIDATE_EMAIL}</p>}
                                                                    {item.CANDIDATE_PHONE !== null && <p className="text-muted mb-0" > <i style={{ color: "#299c0c " }} className="bx bx-phone"></i> <a href="tel:+91-99902343434">{item.CANDIDATE_PHONE}</a></p>}
                                                                    <p style={{ color: "red" }}> Applied On: <a >{moment(item.APPLIED_DATE).format("lll")}</a></p>
                                                                    <a className="text-dark" onClick={() => {
                                                                        downloadResume(item);
                                                                    }}>
                                                                        <span style={{ color: "#808040", cursor: 'pointer' }}>
                                                                            Download Resume{" "}
                                                                            <i className="bx bxs-cloud-download text-info" style={{ fontSize: '20px' }}></i>
                                                                        </span>
                                                                    </a>
                                                                </td>

                                                                <td>
                                                                    {item.STATUS_NAME == "On hold" ?
                                                                        <p><button type="button" className="btn btn-warning btn-sm waves-effect waves-light">{item.STATUS_NAME}</button></p> : "" || item.STATUS_NAME == null ? <p><button type="button" className="btn btn-danger btn-sm waves-effect waves-light">Pending</button></p> : ""
                                                                            || item.STATUS_NAME == "Rejected" ? <p><button type="button" className="btn btn-danger btn-sm waves-effect waves-light">{item.STATUS_NAME}</button></p> : ""
                                                                                || item.STATUS_NAME == "Shortlisted" ? <p><button type="button" className="btn btn-success btn-sm waves-effect waves-light">{item.STATUS_NAME}</button></p> : ""
                                                                                    || item.STATUS_NAME == "Application Sent" ? <p><button type="button" className="btn btn-info btn-sm waves-effect waves-light">{item.STATUS_NAME}</button></p> : ""
                                                                                        || item.STATUS_NAME == "Interview Round - 1" ? <p><button type="button" className="btn btn-info btn-sm waves-effect waves-light">{item.STATUS_NAME}</button></p> : ""
                                                                                            || item.STATUS_NAME == "Interview Round - 2" ? <p><button type="button" className="btn btn-info btn-sm waves-effect waves-light">{item.STATUS_NAME}</button></p> : ""
                                                                                                || item.STATUS_NAME == "Interview Round - 3" ? <p><button type="button" className="btn btn-info btn-sm waves-effect waves-light">{item.STATUS_NAME}</button></p> : ""
                                                                                                    || item.STATUS_NAME == "Offers & Joining - Offered" ? <p><button type="button" className="btn btn-success btn-sm waves-effect waves-light">{item.STATUS_NAME}</button></p> : ""
                                                                                                        || item.STATUS_NAME == "Offers & Joining - Joined" ? <p><button type="button" className="btn btn-success btn-sm waves-effect waves-light">{item.STATUS_NAME}</button></p> : ""
                                                                                                            || item.STATUS_NAME == "Duplicate" ? <p><button type="button" className="btn btn-danger btn-sm waves-effect waves-light">{item.STATUS_NAME}</button></p> : ""
                                                                                                                || item.STATUS_NAME == "In Process" ? <p><button type="button" className="btn btn-info btn-sm waves-effect waves-light">{item.STATUS_NAME}</button></p> : ""
                                                                                                                    || item.STATUS_NAME == "Future Prospects" ? <p><button type="button" className="btn btn-info btn-sm waves-effect waves-light">{item.STATUS_NAME}</button></p> : ""
                                                                                                                        || item.STATUS_NAME == "Offer Accepted" ? <p><button type="button" className="btn btn-success btn-sm waves-effect waves-light">{item.STATUS_NAME}</button></p> : ""

                                                                    }
                                                                    {/* <p><button type="button" className="btn btn-info btn-sm waves-effect waves-light"><i className="bx bx-list-check font-size-16 align-middle me-2"></i> Profile Shortlisted</button></p> */}
                                                                </td>
                                                                
                                                            </tr>

                                                        )
                                                    })
                                                    }

                                                    {
                                                        <>
                                                            {
                                                                props.list && props.list.length == 0 && props.showLoader == false &&
                                                                <td colSpan={10} className="text-center text-danger pt-3">No Search Record Found</td>
                                                            }
                                                        </>
                                                    }
                                                    {/* <tr>
                                                        <td style={{color: "#ff8000", fontSize: "15px", fontFamily: "nunito"}}>
                                                            RZG0000113
                                                        </td>
                                                        <td>
                                                            <h5 className="font-size-14 mb-1"><a href="#" className="text-dark">PHP Laravel Developer</a></h5>
                                                            <p className="text-muted mb-0" style={{color:" #dd006f !important", fontSize:"12px"}}>Premium Job</p>
                                                            <p className="text-muted mb-0" style={{color: "#00add2 !important"}}>₹ 4,25,000 - 9,00,000 P.A.</p>
                                                            <p className="text-muted mb-0" style={{color: "#0080ff !important"}}><i className="bx bx-street-view  font-size-16 align-middle me-1"></i> Noida( Sector-16A Noida )</p>
                                                            <p className="text-muted mb-0"><span style={{color: "#f46a6a"}}>Posted </span> 14 days ago </p>
                                                        </td>

                                                        <td>
                                                            <h5 className="font-size-14 mb-1"><a href="#" className="text-dark">Gyanwave</a></h5>
                                                            <p className="text-muted mb-0" style={{color: "#804000 !important"}}>GYANWAVE TECHNOLOGY PRIVATE LIMITED</p>
                                                            <p className="text-muted mb-0" style={{color: "#004080 !important"}}> <i style={{color: "#004080 !important"}} className="bx bx-envelope"></i> <a href="/cdn-cgi/l/email-protection#4f262129200f28362e21382e392a612c2022"><span className="__cf_email__" data-cfemail="325b5c545d72554b535c455344571c515d5f">[email&#160;protected]</span></a></p>
                                                            <p className="text-muted mb-0" style={{color: "#004080 !important"}}> <i style={{color: "#004080 !important"}} className="bx bx-phone"></i> <a href="tel:+91-99902343434">+91-99902343434</a></p>
                                                            <p className="text-muted mb-0" style={{color: "#004080 !important"}}> <i style={{color: "#004080 !important"}} className="bx bx-globe"></i> <a href="https://gyanwave.com" target="_blank">https://gyanwave.com</a></p>

                                                        </td>
                                                        <td>
                                                            <p><button type="button" className="btn btn-primary btn-sm waves-effect waves-light"><i className="bx bx bx-box font-size-16 align-middle me-2"></i> Application Received</button></p>
                                                            <p><button type="button" className="btn btn-success btn-sm waves-effect waves-light"><i className="bx bxs-plane-take-off font-size-16 align-middle me-2"></i> Profile Selected</button></p>
                                                        </td>
                                                        <td>
                                                            <button type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop41" className="btn btn-info waves-effect btn-label waves-light"><i className="bx bx-street-view label-icon "></i> View Details <span className="badge rounded-pill bg-danger">20</span> </button>

                                                            <div className="modal fade" id="staticBackdrop41" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                                                                    <div className="modal-content">
                                                                        <div className="modal-header">
                                                                            <h5 className="modal-title" id="staticBackdropLabel">Job Information</h5>
                                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                        </div>

                                                                        <div className="modal-body">
                                                                            <p>
                                                                                <table className="table table-striped mb-0">
                                                                                    <tbody>

                                                                                        <tr>
                                                                                            <td>Job description</td>
                                                                                            <td>Understand requirements & functional specifications<br />Develop web applications, dashboards,APIs<br />Troubleshoot, test, maintain code & its document<br />Analyse, design, code, debug, test, document & deploy changes<br />Ensure foolproof performance of deliverable</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>Required Candidate profile</td>
                                                                                            <td>Degree in B.TECH, BCA or equivalent<br />Excellent command on PHP/MySQL with Laravel Framework<br />Experienced in Vue, Laravel, Jquery & AJAX<br />Good OOP knowledge<br />API based development knowledge is must</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>Role</td>
                                                                                            <td>Software Development - Other</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>Industry Type</td>
                                                                                            <td>Advertising & Marketing</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>Functional Area</td>
                                                                                            <td>Engineering - Software</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>Employment Type</td>
                                                                                            <td>Full Time, Permanent</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>Role Category</td>
                                                                                            <td>Software Development</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>Education</td>
                                                                                            <td>UG : BCA in Any Specialization, B.Tech/B.E. in Any Specialization, Other Graduate</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>Key Skills</td>
                                                                                            <td>Mysql Database, Web Application Development, JQuery<br /> Laravel, Laravel Php, Vue.Js, Laravel, Php<br /> Framework, MySQL, Javascript, PHP Vue Framework, API<br /> Web Development, Ajax</td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </p>
                                                                        </div>
                                                                        <div className="modal-footer">
                                                                            <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <ul className="list-inline font-size-20 contact-links mb-0">
                                                                <li className="list-inline-item px-2">
                                                                    <a href="javascript: void(0);" title="View Client" data-bs-toggle="modal" data-bs-target="#staticBackdrop111"><i className="bx bxs-zoom-in"></i></a>
                                                                </li>
                                                                <li className="list-inline-item px-2">
                                                                    <a href="javascript: void(0);" title="Edit User"><i className="bx bx-edit"></i></a>
                                                                </li>
                                                                <li className="list-inline-item px-2">
                                                                    <a href="javascript: void(0);" style={{color: "red"}} title="Delete User"><i className="bx bx-trash"></i></a>
                                                                </li>
                                                            </ul>
                                                        </td>
                                                    </tr> */}
                                                    {/* <tr>
                                                        <td style={{color: "#ff8000", fontSize: "15px", fontFamily: "nunito"}}>
                                                            RZG0000113
                                                        </td>
                                                        <td>
                                                            <h5 className="font-size-14 mb-1"><a href="#" className="text-dark">PHP Laravel Developer</a></h5>
                                                            <p className="text-muted mb-0" style={{color: "#dd006f !important", fontSize:"12px"}}>Premium Job</p>
                                                            <p className="text-muted mb-0" style={{color: "#00add2 !important"}}>₹ 4,25,000 - 9,00,000 P.A.</p>
                                                            <p className="text-muted mb-0" style={{color: "#0080ff !important"}}><i className="bx bx-street-view  font-size-16 align-middle me-1"></i> Noida( Sector-16A Noida )</p>
                                                            <p className="text-muted mb-0"><span style={{color: "#f46a6a"}}>Posted </span> 14 days ago </p>
                                                        </td>

                                                        <td>
                                                            <h5 className="font-size-14 mb-1"><a href="#" className="text-dark">Gyanwave</a></h5>
                                                            <p className="text-muted mb-0" style={{color: "#804000 !important"}}>GYANWAVE TECHNOLOGY PRIVATE LIMITED</p>
                                                            <p className="text-muted mb-0" style={{color: "#004080 !important"}}> <i style={{color: "#004080 !important"}} className="bx bx-envelope"></i> <a href="/cdn-cgi/l/email-protection#20494e464f604759414e574156450e434f4d"><span className="__cf_email__" data-cfemail="bbd2d5ddd4fbdcc2dad5ccdacdde95d8d4d6">[email&#160;protected]</span></a></p>
                                                            <p className="text-muted mb-0" style={{color: "#004080 !important"}}> <i style={{color: "#004080 !important"}} className="bx bx-phone"></i> <a href="tel:+91-99902343434">+91-99902343434</a></p>
                                                            <p className="text-muted mb-0" style={{color: "#004080 !important"}}> <i style={{color: "#004080 !important"}} className="bx bx-globe"></i> <a href="https://gyanwave.com" target="_blank">https://gyanwave.com</a></p>

                                                        </td>
                                                        <td>
                                                            <p><button type="button" className="btn btn-primary btn-sm waves-effect waves-light"><i className="bx bx bx-box font-size-16 align-middle me-2"></i> Application Received</button></p>
                                                            <p><button type="button" className="btn btn-warning btn-sm waves-effect waves-light"><i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> Review in Progress</button></p>
                                                        </td>
                                                        <td>
                                                            <button type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop41" className="btn btn-info waves-effect btn-label waves-light"><i className="bx bx-street-view label-icon "></i> View Details <span className="badge rounded-pill bg-danger">20</span></button>

                                                            <div className="modal fade" id="staticBackdrop41" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                                                                    <div className="modal-content">
                                                                        <div className="modal-header">
                                                                            <h5 className="modal-title" id="staticBackdropLabel">Job Information</h5>
                                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                        </div>

                                                                        <div className="modal-body">
                                                                            <p>
                                                                                <table className="table table-striped mb-0">
                                                                                    <tbody>

                                                                                        <tr>
                                                                                            <td>Job description</td>
                                                                                            <td>Understand requirements & functional specifications<br />Develop web applications, dashboards,APIs<br />Troubleshoot, test, maintain code & its document<br />Analyse, design, code, debug, test, document & deploy changes<br />Ensure foolproof performance of deliverable</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>Required Candidate profile</td>
                                                                                            <td>Degree in B.TECH, BCA or equivalent<br />Excellent command on PHP/MySQL with Laravel Framework<br />Experienced in Vue, Laravel, Jquery & AJAX<br />Good OOP knowledge<br />API based development knowledge is must</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>Role</td>
                                                                                            <td>Software Development - Other</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>Industry Type</td>
                                                                                            <td>Advertising & Marketing</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>Functional Area</td>
                                                                                            <td>Engineering - Software</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>Employment Type</td>
                                                                                            <td>Full Time, Permanent</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>Role Category</td>
                                                                                            <td>Software Development</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>Education</td>
                                                                                            <td>UG : BCA in Any Specialization, B.Tech/B.E. in Any Specialization, Other Graduate</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>Key Skills</td>
                                                                                            <td>Mysql Database, Web Application Development, JQuery<br /> Laravel, Laravel Php, Vue.Js, Laravel, Php<br /> Framework, MySQL, Javascript, PHP Vue Framework, API<br /> Web Development, Ajax</td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </p>
                                                                        </div>
                                                                        <div className="modal-footer">
                                                                            <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <ul className="list-inline font-size-20 contact-links mb-0">
                                                                <li className="list-inline-item px-2">
                                                                    <a href="javascript: void(0);" title="View Client" data-bs-toggle="modal" data-bs-target="#staticBackdrop111"><i className="bx bxs-zoom-in"></i></a>
                                                                </li>
                                                                <li className="list-inline-item px-2">
                                                                    <a href="javascript: void(0);" title="Edit User"><i className="bx bx-edit"></i></a>
                                                                </li>
                                                                <li className="list-inline-item px-2">
                                                                    <a href="javascript: void(0);" style={{color: "red"}} title="Delete User"><i className="bx bx-trash"></i></a>
                                                                </li>
                                                            </ul>
                                                        </td>
                                                    </tr> */}
                                                    {/* <tr>
                                                        <td style={{color: "#ff8000", fontSize: "15px", fontFamily: "nunito"}}>
                                                            RZG0000113
                                                        </td>
                                                        <td>
                                                            <h5 className="font-size-14 mb-1"><a href="#" className="text-dark">PHP Laravel Developer</a></h5>
                                                            <p className="text-muted mb-0" style={{color: "#dd006f !important", fontSize:"12px"}}>Premium Job</p>
                                                            <p className="text-muted mb-0" style={{color: "#00add2 !important"}}>₹ 4,25,000 - 9,00,000 P.A.</p>
                                                            <p className="text-muted mb-0" style={{color: "#0080ff !important"}}><i className="bx bx-street-view  font-size-16 align-middle me-1"></i> Noida( Sector-16A Noida )</p>
                                                            <p className="text-muted mb-0"><span style={{color: "#f46a6a"}}>Posted </span> 14 days ago </p>
                                                        </td>

                                                        <td>
                                                            <h5 className="font-size-14 mb-1"><a href="#" className="text-dark">Gyanwave</a></h5>
                                                            <p className="text-muted mb-0" style={{color: "#804000 !important"}}>GYANWAVE TECHNOLOGY PRIVATE LIMITED</p>
                                                            <p className="text-muted mb-0" style={{color: "#004080 !important"}}> <i style={{color: "#004080 !important"}} className="bx bx-envelope"></i> <a href="/cdn-cgi/l/email-protection#462f28202906213f2728312730236825292b"><span className="__cf_email__" data-cfemail="b6dfd8d0d9f6d1cfd7d8c1d7c0d398d5d9db">[email&#160;protected]</span></a></p>
                                                            <p className="text-muted mb-0" style={{color: "#004080 !important"}}> <i style={{color: "#004080 !important"}} className="bx bx-phone"></i> <a href="tel:+91-99902343434">+91-99902343434</a></p>
                                                            <p className="text-muted mb-0" style={{color: "#004080 !important"}}> <i style={{color: "#004080 !important"}} className="bx bx-globe"></i> <a href="https://gyanwave.com" target="_blank">https://gyanwave.com</a></p>

                                                        </td>
                                                        <td>
                                                            <p><button type="button" className="btn btn-primary btn-sm waves-effect waves-light"><i className="bx bx bx-box font-size-16 align-middle me-2"></i> Application Received</button></p>
                                                            <p><button type="button" className="btn btn-danger btn-sm waves-effect waves-light"><i className="bx bxs-error-circle font-size-16 align-middle me-2"></i> Application Rejected</button></p>
                                                        </td>
                                                        <td>
                                                            <button type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop41" className="btn btn-info waves-effect btn-label waves-light"><i className="bx bx-street-view label-icon "></i> View Details <span className="badge rounded-pill bg-danger">20</span></button>

                                                            <div className="modal fade" id="staticBackdrop41" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                                                                    <div className="modal-content">
                                                                        <div className="modal-header">
                                                                            <h5 className="modal-title" id="staticBackdropLabel">Job Information</h5>
                                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                        </div>

                                                                        <div className="modal-body">
                                                                            <p>
                                                                                <table className="table table-striped mb-0">
                                                                                    <tbody>

                                                                                        <tr>
                                                                                            <td>Job description</td>
                                                                                            <td>Understand requirements & functional specifications<br />Develop web applications, dashboards,APIs<br />Troubleshoot, test, maintain code & its document<br />Analyse, design, code, debug, test, document & deploy changes<br />Ensure foolproof performance of deliverable</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>Required Candidate profile</td>
                                                                                            <td>Degree in B.TECH, BCA or equivalent<br />Excellent command on PHP/MySQL with Laravel Framework<br />Experienced in Vue, Laravel, Jquery & AJAX<br />Good OOP knowledge<br />API based development knowledge is must</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>Role</td>
                                                                                            <td>Software Development - Other</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>Industry Type</td>
                                                                                            <td>Advertising & Marketing</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>Functional Area</td>
                                                                                            <td>Engineering - Software</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>Employment Type</td>
                                                                                            <td>Full Time, Permanent</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>Role Category</td>
                                                                                            <td>Software Development</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>Education</td>
                                                                                            <td>UG : BCA in Any Specialization, B.Tech/B.E. in Any Specialization, Other Graduate</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>Key Skills</td>
                                                                                            <td>Mysql Database, Web Application Development, JQuery<br /> Laravel, Laravel Php, Vue.Js, Laravel, Php<br /> Framework, MySQL, Javascript, PHP Vue Framework, API<br /> Web Development, Ajax</td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </p>
                                                                        </div>
                                                                        <div className="modal-footer">
                                                                            <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <ul className="list-inline font-size-20 contact-links mb-0">
                                                                <li className="list-inline-item px-2">
                                                                    <a href="javascript: void(0);" title="View Client" data-bs-toggle="modal" data-bs-target="#staticBackdrop111"><i className="bx bxs-zoom-in"></i></a>
                                                                </li>
                                                                <li className="list-inline-item px-2">
                                                                    <a href="javascript: void(0);" title="Edit User"><i className="bx bx-edit"></i></a>
                                                                </li>
                                                                <li className="list-inline-item px-2">
                                                                    <a href="javascript: void(0);" style={{color: "red"}} title="Delete User"><i className="bx bx-trash"></i></a>
                                                                </li>
                                                            </ul>
                                                        </td>
                                                    </tr> */}
                                                    {/* <tr>
                                                        <td style={{color: "#ff8000", fontSize:" 15px", fontFamily: "nunito"}}>
                                                            RZG0000113
                                                        </td>
                                                        <td>
                                                            <h5 className="font-size-14 mb-1"><a href="#" className="text-dark">PHP Laravel Developer</a></h5>
                                                            <p className="text-muted mb-0" style={{color: "#dd006f !important", fontSize:"12px"}}>Premium Job</p>
                                                            <p className="text-muted mb-0" style={{color: "#00add2 !important"}}>₹ 4,25,000 - 9,00,000 P.A.</p>
                                                            <p className="text-muted mb-0" style={{color: "#0080ff !important"}}><i className="bx bx-street-view  font-size-16 align-middle me-1"></i> Noida( Sector-16A Noida )</p>
                                                            <p className="text-muted mb-0"><span style={{color: "#f46a6a"}}>Posted </span> 14 days ago </p>
                                                        </td>

                                                        <td>
                                                            <h5 className="font-size-14 mb-1"><a href="#" className="text-dark">Gyanwave</a></h5>
                                                            <p className="text-muted mb-0" style={{color: "#804000 !important"}}>GYANWAVE TECHNOLOGY PRIVATE LIMITED</p>
                                                            <p className="text-muted mb-0" style={{color: "#004080 !important"}}> <i style={{color: "#004080 !important"}} className="bx bx-envelope"></i> <a href="/cdn-cgi/l/email-protection#a9c0c7cfc6e9ced0c8c7dec8dfcc87cac6c4"><span className="__cf_email__" data-cfemail="98f1f6fef7d8ffe1f9f6eff9eefdb6fbf7f5">[email&#160;protected]</span></a></p>
                                                            <p className="text-muted mb-0" style={{color: "#004080 !important"}}> <i style={{color: "#004080 !important"}} className="bx bx-phone"></i> <a href="tel:+91-99902343434">+91-99902343434</a></p>
                                                            <p className="text-muted mb-0" style={{color: "#004080 !important"}}> <i style={{color: "#004080 !important"}} className="bx bx-globe"></i> <a href="https://gyanwave.com" target="_blank">https://gyanwave.com</a></p>

                                                        </td>
                                                        <td>
                                                            <p><button type="button" className="btn btn-primary btn-sm waves-effect waves-light"><i className="bx bx bx-box font-size-16 align-middle me-2"></i> Application Received</button></p>
                                                            <p><button type="button" className="btn btn-warning btn-sm waves-effect waves-light"><i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> Review in Progress</button></p>
                                                        </td>
                                                        <td>
                                                            <button type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop41" className="btn btn-info waves-effect btn-label waves-light"><i className="bx bx-street-view label-icon "></i> View Details<span className="badge rounded-pill bg-danger">20</span></button>

                                                            <div className="modal fade" id="staticBackdrop41" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                                                                    <div className="modal-content">
                                                                        <div className="modal-header">
                                                                            <h5 className="modal-title" id="staticBackdropLabel">Job Information</h5>
                                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                        </div>

                                                                        <div className="modal-body">
                                                                            <p>
                                                                                <table className="table table-striped mb-0">
                                                                                    <tbody>

                                                                                        <tr>
                                                                                            <td>Job description</td>
                                                                                            <td>Understand requirements & functional specifications<br />Develop web applications, dashboards,APIs<br />Troubleshoot, test, maintain code & its document<br />Analyse, design, code, debug, test, document & deploy changes<br />Ensure foolproof performance of deliverable</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>Required Candidate profile</td>
                                                                                            <td>Degree in B.TECH, BCA or equivalent<br />Excellent command on PHP/MySQL with Laravel Framework<br />Experienced in Vue, Laravel, Jquery & AJAX<br />Good OOP knowledge<br />API based development knowledge is must</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>Role</td>
                                                                                            <td>Software Development - Other</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>Industry Type</td>
                                                                                            <td>Advertising & Marketing</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>Functional Area</td>
                                                                                            <td>Engineering - Software</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>Employment Type</td>
                                                                                            <td>Full Time, Permanent</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>Role Category</td>
                                                                                            <td>Software Development</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>Education</td>
                                                                                            <td>UG : BCA in Any Specialization, B.Tech/B.E. in Any Specialization, Other Graduate</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>Key Skills</td>
                                                                                            <td>Mysql Database, Web Application Development, JQuery<br /> Laravel, Laravel Php, Vue.Js, Laravel, Php<br /> Framework, MySQL, Javascript, PHP Vue Framework, API<br /> Web Development, Ajax</td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </p>
                                                                        </div>
                                                                        <div className="modal-footer">
                                                                            <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <ul className="list-inline font-size-20 contact-links mb-0">
                                                                <li className="list-inline-item px-2">
                                                                    <a href="javascript: void(0);" title="View Client" data-bs-toggle="modal" data-bs-target="#staticBackdrop111"><i className="bx bxs-zoom-in"></i></a>
                                                                </li>
                                                                <li className="list-inline-item px-2">
                                                                    <a href="javascript: void(0);" title="Edit User"><i className="bx bx-edit"></i></a>
                                                                </li>
                                                                <li className="list-inline-item px-2">
                                                                    <a href="javascript: void(0);" style={{color: "red"}} title="Delete User"><i className="bx bx-trash"></i></a>
                                                                </li>
                                                            </ul>
                                                        </td>
                                                    </tr> */}
                                                </tbody>
                                            </table>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <ul className="pagination pagination-rounded justify-content-center mt-4">
                                                        <Pagination
                                                            activePage={qParams?.p ? +qParams?.p : currentPage}
                                                            itemsCountPerPage={20}
                                                            totalItemsCount={props.count}
                                                            pageRangeDisplayed={5}
                                                            onChange={handlePageChange}
                                                            itemClass="page-item"
                                                            linkClass="page-link"
                                                        />
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </React.Fragment >
    )
}
export default AppliedJobs;

