import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { skillById } from '../../../actions/employer'
import { updateSkills } from '../../../actions/utilitiesAction'
import Add from '../../../component/Utilities/Skills/AddSkills'
import constant from '../../../constant'

const AddSkills = (props) => {

    const [response, setResponse] = useState({})
    const [showMsg, setShowMsg] = useState(false)
    const navigate = useNavigate()
    const params = useParams()
    const [data, setData] = useState(null)

    useEffect(() => {
        skillById(params.id).then((res) => {
            if (res.status) {
                setData(res.result)
            } else {
                setResponse(res)
                setShowMsg(true)
                setTimeout(() => {
                    setShowMsg(false)
                }, 7000)
            }
        }).catch(err => {
            alert(err)
        })
    }, [])

    const onSubmit = (model) => {
        updateSkills(model).then((res) => {
            if (res.status) {
                navigate(constant.component.skilllist.url + "?p=" + model.pageno)
            } else {
                setResponse(res)
                setShowMsg(true)
                setTimeout(() => {
                    setShowMsg(false)
                }, 7000)
            }
        }).catch((err) => {
            alert(err)
        })
    }

    return (
        <React.Fragment>
            {data && <Add
                onSubmit={onSubmit}
                detail={data}
                response={response}
                showMsg={showMsg}
                title="Update Skills" />}
        </React.Fragment>
    )
}

export default AddSkills