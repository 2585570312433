import React from 'react'
import { addBlogCategory } from '../../../actions/utilitiesAction'
import Addcategory from '../../../component/Utilities/Blog/AddCategory'
export default function AddCategory() {
    const [response, setResponse] = React.useState({})
    const [showMsg, setShowMsg] = React.useState(false)



    const onSubmit = (model) => {
        addBlogCategory(model).then((res) => {
            // if (res.status)
            //     alert(res.messageCode)
            setResponse(res)
            setShowMsg(true)
            setTimeout(() => {
                setShowMsg(false)
            }, 7000)
        }).catch((err) => {
            alert(err)
        })
    }

    return (
        <React.Fragment>
            <Addcategory onSubmit={onSubmit} response={response} showMsg={showMsg} />
        </React.Fragment>
    )
}
