import React, { useEffect, useState } from "react";
import { Pagination } from "react-bootstrap";
import { Link } from "react-router-dom";
import { archivFaq, listFaq } from "../../../actions/utilitiesAction";
import nl2br from 'react-nl2br'
import constant from "../../../constant";


export default function FAQList(props) {
  const data = props.list
  const [show, setShow] = useState()
  const [selectedCategory, setSelectedCategory] = useState('')

  const updateStatus = (FAQ_ID, STATUS) => {
    const model = {
      FAQ_ID: FAQ_ID,
      STATUS: STATUS === 'U' ? 'A' : 'U'
    }
    props.updateStatus(model)
    let article_catgeory_data = props.faqList.filter((ele) => {
      if (ele.CATEGORY_ID == selectedCategory) {
        return ele
      }
    })
    setShow(article_catgeory_data)

  }


  function FAQList(value, article_cat) {
    let article_catgeory_data = props.faqList.filter((ele) => {
      if (ele.CATEGORY_ID == value) {
        return ele
      }
    })
    setSelectedCategory(value)
    setShow(article_catgeory_data)
  }


  useEffect(() => {
    props.list != undefined && props.list.list.map((item, index) => {
      if (index == 0) {
        FAQList(item.CATEGORY_ID, item.CATEGORY)
      }
    })
  }, [])
  console.log(show, "show");

  return (
    <React.Fragment>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0 font-size-18">FAQs List</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Apps</a>
                      </li>
                      <li className="breadcrumb-item active">FAQs List</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="checkout-tabs">
              <div className="row">
                <div className="col-lg-2">
                  <div
                    className="nav flex-column nav-pills"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    {
                      data != undefined ? data.list.map((item, index) => {
                        return (

                          <div onClick={() => FAQList(item.CATEGORY_ID, item.CATEGORY)} >
                            <a
                              className={show && show[0].CATEGORY_ID == item.CATEGORY_ID ? 'nav-link active' : 'nav-link'}

                              id="v-pills-support-tab"
                              data-bs-toggle="pill"
                              href="#v-pills-support"
                              role="button"
                              aria-label="menu"
                              aria-expanded="false"
                            >
                              {/* <li >
                                <i style={{ color: "red" }} className={item.ICON}></i>
                              </li> */}
                              <i className="bx bx-help-circle d-block check-nav-icon mt-4 mb-2" />
                              <p className="fw-bold mb-4" >  {item.CATEGORY}   </p>


                            </a>

                          </div>
                        )
                      }) : ""
                    }


                  </div>

                </div>
                <div className="col-lg-10">
                  <div className="card">
                    <div className="card-body">
                      <div className="tab-content" id="v-pills-tabContent">
                        <div
                          className="tab-pane fade show active"
                          id="v-pills-gen-ques"
                          role="tabpanel"
                          aria-labelledby="v-pills-gen-ques-tab"
                        >


                          <div>
                            {show && show.length > 0 &&

                              <h4 class="card-title mb-5">{show[show.length - 1].ARTICLE_CATEGORY}</h4>

                            }


                            {show && show.length > 0 &&
                              show.map((item) => {
                                return (
                                  <div className="faq-box d-flex mb-4">
                                    <div className="flex-shrink-0 me-3 faq-icon">
                                      <i className="bx bx-help-circle font-size-20 text-success" />
                                    </div>

                                    <div className="flex-grow-1">

                                      <h5 className="font-size-15">
                                        {item.QUESTION}
                                      </h5>
                                      <p className="text-muted"> {nl2br(item.ANSWER)}</p>
                                      <p className="text-muted mb-0">
                                        <i
                                          style={{ color: "#556ee6!important" }}
                                          className="bx bx-edit"
                                        />
                                        <span
                                          style={{ color: "#556ee6!important" }}
                                        >
                                          <Link
                                            to={constant.component.faqedit.url.replace(
                                              ":id",
                                              item.FAQ_ID
                                            )}
                                          >
                                            &nbsp;  Edit
                                          </Link>
                                        </span>
                                        &nbsp;
                                        <li className="list-inline-item px-2">

                                          <a href="javascript: void(0)" onClick={() => updateStatus(item.FAQ_ID, item.STATUS)} style={{ color: 'red' }} title={item.STATUS == 'U' ? "Archive Record" : "Unarchive Record"}>
                                            {
                                              item.STATUS == 'U' ? <i className="bx bx-trash">&nbsp;<a>Archive</a></i> : <i class="bx bx-log-in">&nbsp;<a>Unarchive</a></i>
                                            }


                                          </a>
                                        </li>
                                      </p>
                                    </div>
                                  </div>

                                )
                              })

                            }
                          </div>




                          <div className="row">
                            <div className="col-lg-12">
                              <ul className="pagination pagination-rounded justify-content-center mt-4">
                                {/* <Pagination
                                  activePage={currentPage}
                                  itemsCountPerPage={2}
                                  totalItemsCount={props.count}
                                  pageRangeDisplayed={5}
                                  onChange={handlePageChange}
                                  itemClass="page-item"
                                  linkClass="page-link"
                                /> */}
                              </ul>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
