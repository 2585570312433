import React, { useEffect, useState } from 'react'
import { archiveEmployerBenefitType, listEmployerBenefit } from '../../actions/employer'
import EmployeeBenefitslist from '../../component/Employers/EmployeeBenefitsList'
import constant from '../../constant'

export default function EmployeeBenefitsList() {

    const [data, setData] = useState([])
    const [showLoader, setShowLoader] = useState(true)
    const [count, setCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)

    useEffect(() => {
        document.title = constant.title.EmployeeBenefitsList
        getemployeBenefitList(currentPage)
    }, [currentPage])

    const getemployeBenefitList = (page) => {
        listEmployerBenefit({ page: page }).then(res => {
            if (res.status) {
                setData(res.result.list)
                setCount(res.result.count.total)
                setShowLoader(false)
            }
            else {
                alert(res.error)
            }
        })
            .catch(err => {
                alert(err)
            })
    }

    const updateStatus = (model) => {
        archiveEmployerBenefitType(model).then(res => {
            if (res.status) {
                getemployeBenefitList(currentPage)
            }
            else {
                alert(res.error)
            }
        }).catch(err => {
            alert(err)
        })
    }


    return (
        <React.Fragment>
            <EmployeeBenefitslist
                list={data}
                updateStatus={updateStatus}
                getemployeBenefitList={(page) => { getemployeBenefitList(page) }}
                count={count}
                setShowLoader={setShowLoader}
                showLoader={showLoader}
                setPage={setCurrentPage}
            />
        </React.Fragment>
    )
}
