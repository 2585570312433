import React, { useState } from 'react'
import jobzshala from "../../assets/images/jobzshala.png";
import constant from '../../constant'
import queryString from 'query-string';
import { Link } from 'react-router-dom';
const ResetPassword = (props) => {
    const [state, setState] = useState({
        PASSWORD: '',
        CONFIRM_PASSWORD: ''
    })

    const { detail } = props;
    const qParam = queryString.parse(detail.search)
    const handleChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    const onSubmit = () => {
        if (state.PASSWORD !== state.CONFIRM_PASSWORD) {
            alert('Password and Confirm Password Must be Same')
            return false;
        }
        const model = {
            PASSWORD: state.PASSWORD,
            EMAIL_ID: qParam.e,
            token: qParam.t

        }
        props.onSubmit(model)

    }
    return (
        <React.Fragment>
            <div className="auth-page-wrapper pt-5">
                {/* auth page bg */}
                <div className="auth-one-bg-position auth-one-bg" id="auth-particles">
                    <div className="bg-overlay" />
                    <div className="shape">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 1440 120"
                        >
                            <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z" />
                        </svg>
                    </div>
                    <canvas
                        className="particles-js-canvas-el"
                        width={1898}
                        height={475}
                        style={{ width: "100%", height: "100%" }}
                    />
                </div>
                {/* auth page content */}
                <div className="auth-page-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="text-center mt-sm-5 text-white-50">
                                    <div>
                                        <Link to={constant.component.login.url} className="d-inline-block auth-logo">
                                            <img src={jobzshala} alt="" height={60} />
                                        </Link>
                                    </div>
                                    <p className=" fs-15 fw-medium">
                                        Sign in to continue to JobzShala Administration Control Panel.
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* end row */}
                        <div className="row justify-content-center">
                            <div className="col-md-8 col-lg-6 col-xl-5">
                                <div className="card mt-4">
                                    <div className="card-body p-4">
                                        <div className="text-center mt-2">
                                            <h5 className="text-hd-log">Reset Password</h5>
                                            <p className="text-muted">Reset Password with <span className='rest-fg-text'>JobzShala</span>.</p>
                                        </div>
                                        <div className="p-2 mt-4">
                                            <div class="alert alert-success text-center mb-4" role="alert">Enter your Email and instructions will be sent to you!</div>
                                            <div className="mb-3">
                                                <input
                                                    type="text"
                                                    className="form-control mb-3"
                                                    placeholder="New Password"
                                                    name='PASSWORD'
                                                    value={state.PASSWORD}
                                                    onChange={handleChange}
                                                />
                                                <input
                                                    type="text"
                                                    className="form-control pt-2"
                                                    placeholder="Confirm Password"
                                                    name='CONFIRM_PASSWORD'
                                                    value={state.CONFIRM_PASSWORD}
                                                    onChange={handleChange}
                                                />  </div>


                                            <div className="mt-4">
                                                <button onClick={onSubmit} className="btn btn-success btn-successlogin w-100" type="submit">
                                                    Reset
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-4 text-center">
                                    <p className="mb-0">
                                        Remember It  ?{" "}
                                        <Link
                                            to={constant.component.login.url}
                                            className="fw-semibold text-primary text-decoration-underline"
                                        >
                                            {" "}
                                            Sign In here{" "}
                                        </Link>{" "}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <footer className="footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="text-center">
                                    <p className="mb-0 text-muted">
                                        © 2023 JobzShala.com {" "}
                                        <i className="mdi mdi-heart text-danger" /> Powered by Akshaki India (P) Limited.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>

        </React.Fragment>
    )
}

export default ResetPassword
