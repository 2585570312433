import React, { useState } from 'react'
import CandidateJobs from '../../component/jobs/CandidateAppliedJobs'
import { useEffect } from 'react'
import constant from '../../constant'
import { getcandidateJobs } from '../../actions/utilitiesAction'
import { useParams } from 'react-router-dom'

export default function CandidateAppliedJobs() {
  const [list, setList] = useState([])    
  const [currentPage, setCurrentPage] = useState(1)
  const [showLoader, setShowLoader] = useState(false)

  const params = useParams()
  
  useEffect(() => {
        document.title = constant.title.candidateAppliedJob
        candidateJobsList(currentPage)
    }, [currentPage])


    const candidateJobsList = (page) => {

      getcandidateJobs({ JOB_ID: parseInt(params.id), page:page })
          .then((res) => {
              if (res.status) {
                  setList(res.result.list)
              }
          })
          .catch((err) => {
              alert(err);
          });
  }

  return (
    <React.Fragment>

    <CandidateJobs
      list={list}
      candidateJobsList={(page)=>candidateJobsList(page)}
      setPage={setCurrentPage}
      showLoader={showLoader}

    />
      
    </React.Fragment>
  )
}
