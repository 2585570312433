import moment from 'moment'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import constant from '../../../constant'
import Shimmer from '../../common/Shimmer'

export default function LatestEmployers(props) {
    const [employerFullDetails, setEmployerFullDetails] = useState({})
    const { employerList } = props
    const updateStatus = (EMPLOYER_ID, STATUS) => {
        const model = {
            EMPLOYER_ID: EMPLOYER_ID,
            STATUS: STATUS === 'A' ? 'I' : 'A'
        }
        props.updateStatus(model);
    }
    return (
        <React.Fragment>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title mb-4">Latest Employers</h4>
                            <div className="table-responsive">
                                <table className="table align-middle table-nowrap table-hover">
                                    <thead className="table-light">
                                        <tr>
                                            <th scope="col" style={{ width: "70px" }}>ID</th>
                                            <th scope="col">Employer Information</th>
                                            <th scope="col">JobzShala Stats</th>
                                            <th scope="col">Profile Status</th>
                                            <th scope="col">Other Information </th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {employerList && employerList.length > 0 ? props.employerList.map((item, index) => {
                                            if (index <= 2) {
                                                return (
                                                    <tr>
                                                        <td style={{ color: "#ff8000", fontSize: "15px", fontFamily: "nunito" }}>
                                                            {item.CUSTOM_EMPLOYER_ID}
                                                        </td>
                                                        <td>
                                                            <h5 className="mb-1" style={{ fontSize: "14px" }}><a href="#" className="text-dark">{item.COMPANY_NAME}</a></h5>
                                                            <p className="text-muted mb-0" style={{ color: "#00add2" }}>{item.EMPLOYER_ROLE} <span className="badge border border-light rounded-circle bg-success p-1"><span className="visually-hidden">{item.EMPLOYER_ROLE}</span></span></p>
                                                            <p className="text-muted mb-0" style={{ color: "#0080ff" }}><i className="bx bx-user align-middle me-1" style={{ fontSize: "16px" }}></i> {item.CONTACT_PERSON}</p>
                                                            <p className="text-muted mb-0">{item.EMAIL}</p>
                                                            <p className="text-muted mb-0">{item.MOBILE}</p>
                                                            <p className="text-muted mb-0"><span style={{ color: "#f46a6a" }}>Member Since</span> | {moment(item.CREATED_ON).format('DD-MM-YYYY')} </p>
                                                        </td>
                                                        <td>
                                                            <h5 className="mb-1" style={{ fontSize: "14px" }}>{item.jobpost > 0 ? <td><a href="javascript:void(0);" ><Link to={constant.component.employerJobsPosted.url.replace(':id', item.EMPLOYER_ID)} className="text-dark">Jobs Posted ({item.jobpost})</Link></a></td>
                                                                        : <td><a data-bs-toggle="modal" data-bs-target="#staticBackdrop3" > Jobs Posted ({item.jobpost})</a></td>}</h5>
                                                            <p className="text-muted mb-0"><a href="javascript:void(0);" className="text-dark"><span style={{ color: "#dd006f" }}>Featured Jobs ({item.featuredjob}) </span></a> <br />
                                                                <a href="javascript:void(0);" className="text-dark"><span style={{ color: "#8000ff" }}>Premium Jobs ({item.premiumjob})</span></a> <br />
                                                                {/* <a href="javascript:void(0);" className="text-dark"><span style={{ color: "008040" }}>Shortlisted (0)</span></a>
                                                                <br />
                                                                <a href="javascript:void(0);" className="text-dark"><span style={{ color: "#009999" }}>Rejected (0) </span></a><br />
                                                                <a href="javascript:void(0);" className="text-dark"><span style={{ color: "#ff00ff" }}>Saved Candidates (0)</span></a> */}
                                                            </p>
                                                        </td>
                                                        <td>
                                                            Completeness <span className="badge rounded-pill bg-success">90%</span><br />
                                                            <a href="javascript:void(0);" className="text-dark"><span style={{ color: "008040" }}>Candidate Message (0) </span></a>
                                                            <br />
                                                            <a href="javascript:void(0);" className="text-dark"><span style={{ color: "#808040" }}>Subscription (0)</span></a>
                                                            <br />
                                                            <a href="javascript:void(0);" className="text-dark"><span style={{ color: "#444444" }}>Transaction History (0) </span></a>
                                                            <br />
                                                            <a href="javascript:void(0);" className="text-dark"><span style={{ color: "#c80064" }}>Reviews(0) </span></a>
                                                            <br />

                                                            {item.LISTING_TYPE !== 'R' && <a href="javascript:void(0);" className="text-dark"><span style={{ color: "#338F38" }}>{item.LISTING_TYPE === 'P' ? "Premium" : "Featured"}</span></a>}




                                                        </td>
                                                        <td>

                                                            {
                                                                item.STATUS == 'A' ?
                                                                    <button
                                                                        type="button"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#staticBackdrop4"
                                                                        className="btn btn-info waves-effect btn-label waves-light"
                                                                        onClick={(e) => { setEmployerFullDetails(item) }}
                                                                    ><i className="bx bx-street-view label-icon "></i> View Details </button>
                                                                    : <button
                                                                        type="button"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#staticBackdrop4"
                                                                        className="btn waves-effect btn-label waves-light text-light"
                                                                        style={{ backgroundColor: "#e81c28" }}
                                                                        onClick={(e) => { setEmployerFullDetails(item) }}
                                                                    ><i className="bx bx-street-view label-icon "></i> View Details </button>
                                                            }

                                                        </td>

                                                        <td>
                                                            <ul className="list-inline contact-links mb-0" style={{ fontSize: "20px" }}>
                                                                <li className="list-inline-item px-2">
                                                                    <a
                                                                        onClick={(e) => { setEmployerFullDetails(item) }}
                                                                        href="javascript: void(0);" title="View Client" data-bs-toggle="modal" data-bs-target="#staticBackdrop11"><i className="bx bxs-zoom-in"></i></a>
                                                                </li>
                                                                <li className="list-inline-item px-2">
                                                                    <Link to={constant.component.editemployers.url.replace(':id', item.EMPLOYER_ID)} title="Edit Employer"><i className="bx bx-edit"></i></Link>
                                                                </li>
                                                                <li className="list-inline-item px-2">
                                                                    <a href="javascript: void(0)"
                                                                        onClick={() => updateStatus(item.EMPLOYER_ID, item.STATUS)}
                                                                        style={{ color: 'red' }} title={item.STATUS == 'A' ? "Block Employer" : "Unblock Employer"}>
                                                                        {
                                                                            item.STATUS == 'A' ? <i className="bx bx-trash"></i> : <i class="bx bx-log-in"></i>
                                                                        }


                                                                    </a>
                                                                </li>
                                                            </ul>


                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        }) : (employerList && employerList.length == 0 ? < td colSpan={10} className="text-center text-danger pt-3">No Employer Record Found</td> : <Shimmer />)
                                        }

                                        <div className="modal fade" id="staticBackdrop4" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="staticBackdropLabel">{employerFullDetails.COMPANY_NAME} - Information</h5>
                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>

                                                    <div className="modal-body">
                                                        <p>
                                                            {employerFullDetails &&
                                                                <table className="table table-striped mb-0">
                                                                    <tbody>

                                                                        <tr>
                                                                            <td>Year Established</td>
                                                                            <td>{employerFullDetails.FOUNDED}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Location</td>
                                                                            <td>{employerFullDetails.LOCATION ? employerFullDetails.LOCATION : 'Not Available'}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Logo</td>
                                                                            <td><img src={`${process.env.REACT_APP_BASE_URL}/company/logo/${employerFullDetails.COMPANY_LOGO}`} style={{ width: "100px" }} /></td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>}
                                                        </p>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="modal fade" id="staticBackdrop11" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="staticBackdropLabel">Employer Details - {employerFullDetails.COMPANY_NAME}</h5>
                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <p>{employerFullDetails &&
                                                            <table className="table table-striped mb-0">
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{ width: "32%" }}>ID</td>
                                                                        <td>{employerFullDetails.CUSTOM_EMPLOYER_ID}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Company</td>
                                                                        <td>{employerFullDetails.COMPANY_NAME}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Industry</td>
                                                                        <td>Consultant & Recruiter</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Contact Person</td>
                                                                        <td>{employerFullDetails.CONTACT_PERSON}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Email</td>
                                                                        <td>{employerFullDetails.EMAIL}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Phone No.</td>
                                                                        <td>{employerFullDetails.MOBILE}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Member Since</td>
                                                                        <td>{moment(employerFullDetails.CREATED_ON).format('YYYY')}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Year Established</td>
                                                                        <td>{employerFullDetails.FOUNDED}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Address</td>
                                                                        <td>{employerFullDetails.ADDRESS ? employerFullDetails.ADDRESS : 'Not Available'}</td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td>Logo</td>
                                                                        <td><img src={`${process.env.REACT_APP_BASE_URL}/company/logo/${employerFullDetails.COMPANY_LOGO}`} style={{ width: "100px" }} /></td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td>Profile Status</td>
                                                                        <td><span className={employerFullDetails.STATUS === 'A' ? "badge rounded-pill bg-success" : "badge rounded-pill bg-danger"}>{employerFullDetails.STATUS === 'A' ? 'ACTIVE' : 'INACTIVE'}</span></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>}
                                                        </p>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </tbody>
                                </table>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <Link to={constant.component.employerslist.url} className="btn btn-primary waves-effect btn-label waves-light"><i className="bx bxs-right-arrow-square label-icon"></i> View All Employers</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
