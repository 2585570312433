import React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { resetPassword } from '../../actions/AuthActions'
import Reset from '../../component/common/resetPassword'
const ResetPassword = () => {
    const location = useLocation()
    const onSubmit = (model) => {
        resetPassword(model).then(res => {
            if (res.status) {
                alert(res.messageCode)
            }
            else {
                alert(res.error)
            }
        }).catch(err => {
            alert(err)
        })
    }
    return (
        <React.Fragment>
            <Reset
                onSubmit={onSubmit}
                detail={location} />

        </React.Fragment>

    )
}

export default ResetPassword;