import React, { useEffect, useState } from 'react'
import NumberFormat from 'react-number-format'
import SuccessMessage from '../../common/SuccessMessage'
import { emailValidation } from '../../../utils'
import ErrorMessage from '../../common/ErrorMessage'
import { useNavigate } from 'react-router-dom'
import constant from '../../../constant'

const AddAdminUser = (props) => {

    const navigate = useNavigate()
    const { roleList, detail } = props
    const [error, setError] = useState(false);
    const [emailError, setEmailError] = useState()

    const [data, setData] = useState({
        FIRST_NAME: "",
        LAST_NAME: "",
        PHONE: "",
        ROLE: "",
        EMAIL: "",
        PASSWORD: "",
        DETAILS: "",
    })

    const handleReset = (e) => {
        e.preventDefault();
        setError(false)
        setEmailError(false)
        detail && navigate(constant.component.adminuserlist.url)
        setData(PrevState => ({
            ...PrevState,
            FIRST_NAME: "",
            LAST_NAME: "",
            PHONE: "",
            ROLE: "",
            EMAIL: "",
            PASSWORD: "",
            DETAILS: "",
        }))

    }

    const handleChange = (e) => {
        const { name, value } = e.target
        if (name === 'EMAIL') {
            if (!emailValidation(data.EMAIL)) {
                setEmailError(true)
            }
            else {
                setEmailError(false)
            }
            setData({
                ...data,
                [name]: value
            })
        }
        else {
            setData({
                ...data,
                [name]: value
            })
        }

    }

    const onSubmit = (e) => {
        e.preventDefault();
        if (data.FIRST_NAME.trim().length == 0 || data.LAST_NAME.trim().length == 0 || data.PHONE.trim().length == 0 || data.ROLE.toString().trim().length == 0 || data.EMAIL.trim().length == 0) {
            if (!detail) {
                if (data.PASSWORD.trim().length == 0) {
                    setError(true)
                }
            }
            setError(true)
        }

        else {
            const model = {
                FIRST_NAME: data.FIRST_NAME,
                LAST_NAME: data.LAST_NAME,
                PHONE: data.PHONE,
                ROLE_ID: data.ROLE,
                EMAIL_ID: data.EMAIL,
                PASSWORD: data.PASSWORD,
                OTHER_DETAILS: data.DETAILS,
            };
            if (detail) {
                model.ADMIN_ID = detail.ADMIN_ID
            }
            props.onSubmit(model)
        }

    }

    useEffect(() => {
        if (detail) {
            setData({
                FIRST_NAME: detail.FIRST_NAME,
                LAST_NAME: detail.LAST_NAME,
                PHONE: detail.PHONE,
                ROLE: detail.ROLE_ID,
                EMAIL: detail.EMAIL_ID,
                PASSWORD: "",
                DETAILS: detail.OTHER_DETAILS,
            })
        }
    }, [])

    return (
        <React.Fragment>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 font-size-18">{detail ? 'Update Admin User' : 'Add Admin User'}</h4>
                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="javascript: void(0);">Admin User</a></li>
                                            <li className="breadcrumb-item active">{detail ? 'Update Admin User' : 'Add Admin User'}</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">Admin User Information</h4>
                                        {
                                            props.showMsg && (props.response.status ?
                                                <SuccessMessage message={props.response.messageCode} />
                                                :
                                                <ErrorMessage message={props.response.error} />)
                                        }
                                        <div id="basic-example">
                                            <section>
                                                <form>
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label for="basicpill-firstname-input">First name<span className='text-danger'>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    className={error && data.FIRST_NAME.trim().length == 0 ? 'form-control is-invalid ' : 'form-control'}
                                                                    id="basicpill-firstname-input"
                                                                    placeholder="Enter First Name"
                                                                    name="FIRST_NAME"
                                                                    value={data.FIRST_NAME}
                                                                    onChange={handleChange}

                                                                />
                                                                {error && data.FIRST_NAME.trim().length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label for="basicpill-lastname-input">Last name<span className='text-danger'>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    className={error && data.LAST_NAME.trim().length == 0 ? 'form-control is-invalid ' : 'form-control'}
                                                                    id="basicpill-lastname-input"
                                                                    placeholder="Enter Last Name"
                                                                    name="LAST_NAME"
                                                                    value={data.LAST_NAME}
                                                                    onChange={handleChange}
                                                                />
                                                                {error && data.LAST_NAME.trim().length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-lg-3">
                                                            <div className="mb-3">
                                                                <label for="basicpill-phoneno-input">Phone<span className='text-danger'>*</span></label>
                                                                <NumberFormat
                                                                    type="text"
                                                                    className={error && data.PHONE.toString().trim().length == 0 ? 'form-control is-invalid ' : 'form-control'}
                                                                    id="basicpill-phoneno-input"
                                                                    placeholder="Enter Phone No."
                                                                    name="PHONE"
                                                                    maxLength={10}
                                                                    minLength={10}
                                                                    value={data.PHONE}
                                                                    onChange={handleChange}
                                                                />
                                                                {error && data.PHONE.toString().trim().length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <div className="mb-3">
                                                                <label for="basicpill-phoneno-input">Role<span className='text-danger'>*</span></label>
                                                                <select
                                                                    className={error && data.ROLE.trim().length == 0 ? 'form-control is-invalid form-select' : 'form-control form-select'}
                                                                    name="ROLE"
                                                                    value={data.ROLE}
                                                                    onChange={handleChange}
                                                                >
                                                                    <option value=''>Select Role</option>
                                                                    {
                                                                        roleList && roleList.length > 0 && roleList.map((item, index) => {
                                                                            return (
                                                                                <option value={item.ROLE_ID}>{item.ROLE_NAME}</option>
                                                                            )

                                                                        })

                                                                    }

                                                                </select>
                                                                {error && data.ROLE.toString().trim().length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <div className="mb-3">
                                                                <label for="basicpill-email-input">Email<span className='text-danger'>*</span></label>
                                                                {
                                                                    detail ? <input
                                                                        type="text"
                                                                        className={error && data.EMAIL.trim().length == 0 ? 'form-control is-invalid ' : 'form-control'}
                                                                        id="basicpill-email-input"
                                                                        placeholder="Enter Email ID"
                                                                        name="EMAIL"
                                                                        value={data.EMAIL}
                                                                        onChange={handleChange}
                                                                        readOnly
                                                                    /> : <input
                                                                        type="text"
                                                                        className={error && data.EMAIL.trim().length == 0 ? 'form-control is-invalid ' : 'form-control'}
                                                                        id="basicpill-email-input"
                                                                        placeholder="Enter Email ID"
                                                                        name="EMAIL"
                                                                        value={data.EMAIL}
                                                                        onChange={handleChange}

                                                                    />
                                                                }
                                                                {emailError && <span className='text-danger ml-1'> This Email is not Valid</span>}
                                                                {error && data.EMAIL.trim().length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <div className="mb-3">
                                                                <label for="basicpill-email-input">Password<span className='text-danger'>*</span></label>
                                                                <input
                                                                    type="password"
                                                                    className={error && data.PASSWORD.trim().length == 0 ? 'form-control is-invalid ' : 'form-control'}
                                                                    id="basicpill-email-input"
                                                                    placeholder="Enter Password"
                                                                    name="PASSWORD"
                                                                    minLength={8}
                                                                    value={data.PASSWORD}
                                                                    onChange={handleChange}
                                                                />
                                                                {error && data.PASSWORD.length == 0 && <span className='text-danger ml-1'> This Field is Required</span>}

                                                                {/* {data.PASSWORD.length > 0 && data.PASSWORD.length < 8 && <span className='text-danger ml-1'> Password should be minimum 8 characters</span>} */}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="mb-3">
                                                                <label for="basicpill-address-input">Other Details</label>
                                                                <textarea
                                                                    id="basicpill-address-input"
                                                                    className="form-control"
                                                                    rows="2"
                                                                    placeholder="Enter Other Details"
                                                                    name="DETAILS"
                                                                    value={data.DETAILS}
                                                                    onChange={handleChange}
                                                                >
                                                                </textarea>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <button type="button" onClick={onSubmit} className="btn btn-info waves-effect btn-label waves-light"><i className="bx bx-check-double label-icon"></i>{detail ? 'Save Changes' : 'Create Admin User'}</button>
                                                                &nbsp;&nbsp;
                                                                <button type="button" onClick={handleReset} className="btn btn-danger waves-effect btn-label waves-light"><i className="bx bx-block label-icon "></i> Cancel</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </section>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default AddAdminUser;