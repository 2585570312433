import React, { useEffect, useState } from 'react'
import constant from '../../../constant'
import InterviewAnswerList from '../../../component/Utilities/ShareInterviewQuestions/InterviewAnswer'
import { answerlistbyQuestionId, archiveInterviewQuestions, archiveshareInterviewQuestions, archiveshareInterviewQuestions1, deleteAnswerByQuestionId, shareanswerlistbyQuestionId } from '../../../actions/utilitiesAction'
import { useParams } from 'react-router-dom'
import Swal from 'sweetalert2'

export default function InterviewAnswer() {

    const [list, setList] = useState([])
    const [count, setCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [showLoader, setShowLoader] = useState(false)
    const [questionTitle, setQuestionTitle] = useState("")
    const [list1, setList1] = useState([])
    const params = useParams()

    useEffect(() => {
        getInterviewAnswerList(currentPage)
        document.title = constant.title.InterviewAnswer
    }, [currentPage])

    const getInterviewAnswerList = (page) => {
        const INTERVIEW_QUESTIONS_ID = params.id
        setShowLoader(true)
        shareanswerlistbyQuestionId(INTERVIEW_QUESTIONS_ID, page).then((res) => {
            if (res.status) {
                setQuestionTitle(res.result.list1.QUESTION_TITLE)
                setShowLoader(false)
                setList(res.result.list)
                setList1(res.result.list1)
                setCount(res.result.count)
            }
            else {
                alert(res.error)
            }
        }).catch((err) => {
            alert(err)
        })
    }

    const updateStatus = (model) => {
        Swal.fire({
            title: 'Do you want to Approve the changes?',
            showCancelButton: true,
            confirmButtonText: 'Save',
            denyButtonText: `Don't save`,
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire('Approved!', '', 'success')
                archiveInterviewQuestions(model).then((res) => {
                    if (res.status) {
                        getInterviewAnswerList(currentPage, '')
                    } else {
                        alert(res.error)
                    }
                }).catch((err) => {
                    alert(err)
                })

            } else if (result.isDenied) {
                Swal.fire('Changes are not saved', '', 'info')
            }
        })
    }

    const updateStatus1 = (model) => {
        Swal.fire({
            title: 'Do you want to Approve the changes?',

            showCancelButton: true,
            confirmButtonText: 'Save',
            denyButtonText: `Don't save`,
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire('Approved!', '', 'success')
                archiveshareInterviewQuestions1(model).then((res) => {
                    if (res.status) {
                        getInterviewAnswerList(currentPage, '')
                    } else {
                        alert(res.error)
                    }
                }).catch((err) => {
                    alert(err)
                })

            } else if (result.isDenied) {
                Swal.fire('Changes are not saved', '', 'info')
            }
        })
    }
    const deleteAnswerId = (INTERVIEW_QUESTIONS_ANSWER_ID) => {
        deleteAnswerByQuestionId({ INTERVIEW_QUESTIONS_ANSWER_ID: INTERVIEW_QUESTIONS_ANSWER_ID }).then((res) => {
            if (res.status) {
                Swal.fire(
                    'Deleted!',
                    'Your Answer has been deleted.',
                    'success'
                )
                getInterviewAnswerList(currentPage)
            }
            else {
                Swal.fire(
                    (res.result.error)
                )
            }

        })
    }


    return (
        <React.Fragment>
            <InterviewAnswerList
                list={list}
                list1={list1}
                getInterviewAnswerList={(page => getInterviewAnswerList(page))}
                count={count}
                setPage={setCurrentPage}
                showLoader={showLoader}
                updateStatus={updateStatus}
                updateStatus1={updateStatus1}
                deleteAnswerId={deleteAnswerId}
                questionTitle={questionTitle}
            />
        </React.Fragment>
    )
}
