import React, { useState } from 'react'
import Modal from '../../../common/ModalWindow'
import { ShimmerText } from "react-shimmer-effects";

const Header = (props) => {
    const { basicDetails } = props;
    const [showVideoProfileModal, setShowVideoProfileModal] = useState(false)

    const baseUrl = process.env.REACT_APP_BASE_URL;
    const videoPath = `/cjzs-student-video-profile/${props.TYPE}/${basicDetails?.VIDEO_PROFILE_FILE_NAME}`;
    const initialVideoURL = `${baseUrl}${videoPath}`;
    return (
        <React.Fragment>
            {showVideoProfileModal && <Modal
                isOpen={showVideoProfileModal}
                onClose={() => { setShowVideoProfileModal(false) }}
            >
                <div style={{ width: '80%', margin: 'auto' }}>
                    <video controls style={{ width: '100%', height: 'auto' }}>
                        <source src={initialVideoURL} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>

            </Modal>}
            <div className="pt-4 profile-wrapper">
                <div className="row g-4">
                    <div className="col-md-6 col-12">
                        <div className="avatar-lg">
                            <img style={{ border: '4px solid #ffffff' }}
                                src={`${process.env.REACT_APP_BASE_URL}/cjzs-get-pp?tk=${props.token}`}
                                alt="user-img"
                                className="img-thumbnail rounded-circle"
                            />
                        </div>
                        {basicDetails?.VIDEO_PROFILE_FILE_NAME && (
                            <div className="hstack gap-1 customProfileVideoSection" style={{ cursor: 'pointer' }} onClick={() => { setShowVideoProfileModal(true) }}>
                                <i className="ri-camera-line me-1 fs-16 align-middle" />
                                Video Profile
                            </div>
                        )}

                        <div className="profileAlignItem p-0">
                            <div className="p-2 mt-1">
                                <h3 className="text-black mb-1">{basicDetails ? basicDetails.FIRST_NAME + ' ' + basicDetails.LAST_NAME : <ShimmerText line={1} />}</h3>
                                {props.TYPE === 'ON CAMPUS' && (
                                    <p className="text-black text-opacity-75 mb-1">{basicDetails ? basicDetails.COURSE_NAME + " (" + basicDetails.SPECIALIZATION_NAME + ")" : <ShimmerText line={1} />}</p>
                                )}
                                <div className="text-black-50 gap-1">
                                    {props.TYPE === 'ON CAMPUS' && (
                                        <div>
                                            <i className="ri-building-line me-1 text-black text-opacity-75 fs-16 align-middle" />
                                            {basicDetails ? basicDetails.COLLEGE_NAME : <ShimmerText line={1} />}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 col-12 order-lg-2 ProfileCustoQRCodeStyle">
                        <div className="ProfileCustoQRCodeInnerStyle">
                            {basicDetails?.RESUME_FILE_NAME && (
                                <a download href={`${process.env.REACT_APP_BASE_URL}/download/campus-resume/${basicDetails?.RESUME_FILE_NAME}`}>
                                    <div className="col-lg-6 col-4" >
                                        <div className="flex-shrink-0">
                                            <h3 className="text-white m-0 d-flex" style={{ fontSize: 18, paddingLeft: 4, }}>
                                                <i className="ri-file-download-line align-bottom" />Resume
                                            </h3>
                                        </div>
                                    </div>
                                </a>
                            )}
                            {/* <GenQRCode
                                data={JSON.stringify({ TYPE: ud.TYPE, EMAIL: ud.EMAIL })}
                                type={ud.TYPE}
                            /> */}
                        </div>
                    </div>

                </div>
            </div>

        </React.Fragment >

    )
}

export default Header