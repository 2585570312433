import React, { useState } from 'react'
import { useEffect } from 'react'
import NumberFormat from 'react-number-format'
import constant from '../../../constant'
import SuccessMessage from "../../common/SuccessMessage";
import ErrorMessage from "../../common/ErrorMessage";
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom';

function AddDiscussion(props) {
    const { detail } = props
    const { id } = useParams()
    const Navigate = useNavigate()
    const [error, setError] = useState(false)


    const [discussion, setDiscussion] = useState({
        DISCUSSION_CATEGORY_TITLE: "",
        DISCUSSION_CATEGORY_DETAILS: "",
        SORT_NUMBER: "",
    })

    const handleReset = (e) => {
        setError(false)
        e.preventDefault();
        id && Navigate(constant.component.DiscussionforumList.url)
        setDiscussion(PrevState => ({
            ...PrevState,
            DISCUSSION_CATEGORY_TITLE: "",
            DISCUSSION_CATEGORY_DETAILS: "",
            SORT_NUMBER: ""
        }))
    }

    const handleChange = e => {
        const { name, value } = e.target
        setDiscussion({
            ...discussion,
            [name]: value
        })

    }
    useEffect(() => {
        (id ? document.title = constant.title.EditDiscussionForum : document.title = constant.title.AddDiscussionForum)
    },[])

    const onSubmit = (e) => {
        e.preventDefault();
        if (discussion.DISCUSSION_CATEGORY_TITLE.trim().length == 0 || discussion.SORT_NUMBER.toString().trim().length == 0) {
            setError(true)
        }
        else {
            const model = {
                DISCUSSION_CATEGORY_TITLE: discussion.DISCUSSION_CATEGORY_TITLE,
                DISCUSSION_CATEGORY_DETAILS: discussion.DISCUSSION_CATEGORY_DETAILS,
                SORT_NUMBER: discussion.SORT_NUMBER
            };
            if (detail) {
                model.DISCUSSION_CATEGORY_ID = detail.DISCUSSION_CATEGORY_ID
            }
            props.onSubmit(model)
            {
                console.log(model, "model")
            }
        }
    }
    useEffect(() => {
        if (detail) {
            setDiscussion({
                DISCUSSION_CATEGORY_TITLE: detail.DISCUSSION_CATEGORY_TITLE,
                DISCUSSION_CATEGORY_DETAILS: detail.DISCUSSION_CATEGORY_DETAILS,
                SORT_NUMBER: detail.SORT_NUMBER
            })
        }
    }, [])
    return (

        <React.Fragment>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 " style={{ fontSize: '18px' }}>{id ? 'Update Discussion Forum' : 'Add Discussion Forum'}</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="javascript: void(0);">Utilities</a></li>
                                            <li className="breadcrumb-item active">{id ? 'Update Discussion Forum' : 'Add Discussion Forum'}</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">Category Information</h4>
                                        {
                                            props.showMsg && (props.response.status ?
                                                <SuccessMessage message={props.response.messageCode} />
                                                :
                                                <ErrorMessage message={props.response.error} />)
                                        }
                                        <div id="basic-example">
                                            <section>
                                                <form>
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label for="basicpill-firstname-input">Category Title <span className='text-danger'>*</span></label>
                                                                <input type="text"
                                                                    className={error && discussion.DISCUSSION_CATEGORY_TITLE.trim().length == 0 ? 'form-control is-invalid ' : 'form-control'}
                                                                    id="basicpill-firstname-input"
                                                                    placeholder="Enter Title "
                                                                    name="DISCUSSION_CATEGORY_TITLE"
                                                                    value={discussion.DISCUSSION_CATEGORY_TITLE}
                                                                    onChange={handleChange}
                                                                />
                                                                {error && discussion.DISCUSSION_CATEGORY_TITLE.trim().length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label for="basicpill-address-input">Sort Number <span className='text-danger'>*</span></label>
                                                                <NumberFormat
                                                                    className={error && discussion.SORT_NUMBER.toString().trim().length == 0 ? 'form-control is-invalid ' : 'form-control'}
                                                                    placeholder="Enter Sort Number"
                                                                    id="basicpill-firstname-input"
                                                                    name="SORT_NUMBER"
                                                                    value={discussion.SORT_NUMBER}
                                                                    onChange={handleChange}
                                                                    maxLength={10}
                                                                    allowLeadingZeros={false}
                                                                    allowNegative={false}
                                                                    thousandSeparator={false}
                                                                />
                                                                {error && discussion.SORT_NUMBER.toString().trim().length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="mb-3">
                                                                <label for="basicpill-address-input">Category Details</label>
                                                                <textarea id="basicpill-address-input"
                                                                    className="form-control"
                                                                    rows="2"
                                                                    placeholder="Enter Category Detail"
                                                                    name="DISCUSSION_CATEGORY_DETAILS"
                                                                    value={discussion.DISCUSSION_CATEGORY_DETAILS}
                                                                    onChange={handleChange}
                                                                ></textarea>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <button type="button" onClick={(e) => onSubmit(e)} className="btn btn-info waves-effect btn-label waves-light"> <i className="bx bx-check-double label-icon"></i>{detail ? 'Save Changes' : 'Create Discussion'}</button>
                                                                &nbsp;&nbsp;
                                                                <button type="button" onClick={(e) => handleReset(e)} className="btn btn-danger waves-effect btn-label waves-light"><i className="bx bx-block label-icon "></i> Cancel</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </section>
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>


                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}
export default AddDiscussion