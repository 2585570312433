import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useParams } from "react-router-dom";
import { getIndustryById } from "../../actions/JobsAction";
import SuccessMessage from "../common/SuccessMessage";
import ErrorMessage from "../common/ErrorMessage";
import constant from "../../constant";

function AddIndustrys(props) {
  const { id } = useParams();
  const [INDUSTRY, setINDUSTRY] = useState("");
  const [INDUSTRY_DETAILS, setINDUSTRY_DETAILS] = useState("");
  const [INDUSTRY_ID, setINDUSTRY_ID] = useState("");
  const [SORT_NUMBER, setSORT_NUMBER] = useState("");
  const [error, setError] = useState(false)
  const Navigate = useState()
  const params = useParams()

  const onSubmit = (e) => {
    let pn = params.pageno
    if (INDUSTRY.toString().trim().length === 0 || SORT_NUMBER.toString().trim().length === 0) {
      setError(true)
      return false
    }
    e.preventDefault();

    if (id) {
      const model = {
        INDUSTRY: INDUSTRY,
        INDUSTRY_DETAILS: INDUSTRY_DETAILS,
        INDUSTRY_ID: INDUSTRY_ID,
        SORT_NUMBER: SORT_NUMBER,
        type: "UPDATE",
      };
      props.onSubmit(model);
    } else {
      const model = {
        INDUSTRY: INDUSTRY,
        INDUSTRY_DETAILS: INDUSTRY_DETAILS,
        SORT_NUMBER: SORT_NUMBER,
        type: "ADD",
      };
      props.onSubmit(model);
      model.pageno = pn
    }
  };

  useEffect(() => {
    id && getIndDetails(id);
    (id ? document.title = constant.title.EditIndustry : document.title = constant.title.AddIndustry)
    //   getJobQuestionType();
  }, []);

  // const getJobQuestionType = () => {
  //   listJobQuestionType()
  //     .then((res) => {
  //       if (res.status) {
  //         setQUESTIONTYPE(res.result);
  //       }
  //     })
  //     .catch((err) => {
  //       alert(err);
  //     });
  // };

  const getIndDetails = (id) => {
    getIndustryById({ INDUSTRY_ID: id })
      .then((res) => {
        if (res.status) {
          setINDUSTRY(res.result[0].INDUSTRY);
          setINDUSTRY_DETAILS(res.result[0].INDUSTRY_DETAILS);
          setINDUSTRY_ID(res.result[0].INDUSTRY_ID);
          setSORT_NUMBER(res.result[0].SORT_NUMBER);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };
  const handleReset = () => {
    id && Navigate(constant.component.listIndustry)
    setError(false)
    setINDUSTRY('')
    setINDUSTRY_DETAILS('')
    setSORT_NUMBER("")
  }
  return (
    <React.Fragment>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0" style={{ fontSize: "18px" }}>
                    {id ? " Update  Industry" : "  Add  Industry"}
                  </h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Jobs</a>
                      </li>
                      <li className="breadcrumb-item active"> {id ? " Update  Industry" : "  Add  Industry"}</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title mb-4">Industry Information</h4>
                    {
                      props.showMsg && (props.response.status ?
                        <SuccessMessage message={props.response.messageCode} />
                        :
                        <ErrorMessage message={props.response.error} />)
                    }
                    <div id="basic-example">
                      <section>
                        <form method="POST">
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label for="basicpill-firstname-input">
                                  Industry <span className="text-danger"> *</span>
                                </label>
                                <input
                                  type="text"
                                  className={error && INDUSTRY.toString().trim().length <= 0 ? 'form-control is-invalid ' : 'form-control'}
                                  id="basicpill-firstname-input"
                                  placeholder="Enter Industry"
                                  value={INDUSTRY}
                                  onChange={e => { setINDUSTRY(e.target.value) }}
                                />
                                {
                                  error && INDUSTRY.toString().trim().length <= 0 ? <span className='text-danger ml-1'>This Field is Required</span>
                                    : ""}
                              </div>
                            </div>

                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label for="basicpill-address-input">
                                  Sort Number <span className="text-danger"> *</span>
                                </label>
                                <NumberFormat
                                  allowLeadingZeros={false}
                                  allowNegative={false}
                                  thousandSeparator={false}
                                  maxlength={10}
                                  className={error && SORT_NUMBER.toString().trim().length <= 0 ? 'form-control is-invalid ' : 'form-control'}
                                  id="basicpill-firstname-input"
                                  placeholder="Enter Sort Number"
                                  value={SORT_NUMBER}
                                  onChange={e => { setSORT_NUMBER(e.target.value) }}
                                />
                                {
                                  error && SORT_NUMBER.toString().trim().length <= 0 ? <span className='text-danger ml-1'>This Field is Required</span>
                                    : ""}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="mb-3">
                                <label for="basicpill-address-input">
                                  Industry Details
                                </label>
                                <textarea
                                  id="basicpill-address-input"
                                  className="form-control"
                                  rows="2"
                                  placeholder="Enter Industry Details"
                                  value={INDUSTRY_DETAILS}
                                  onChange={e => { setINDUSTRY_DETAILS(e.target.value) }}
                                ></textarea>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <button
                                  type="button"
                                  className="btn btn-info waves-effect btn-label waves-light"
                                  onClick={onSubmit}
                                >
                                  <i className="bx bx-check-double label-icon"></i>{" "}
                                  {id ? 'Save Changes' : 'Create Industry'}
                                </button>
                                &nbsp;&nbsp;
                                <button
                                  type="button"
                                  onClick={handleReset}
                                  className="btn btn-danger waves-effect btn-label waves-light"
                                >
                                  <i className="bx bx-block label-icon "></i>{" "}
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AddIndustrys;
