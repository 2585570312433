import React, { Children, useRef } from "react";
import constant from "../../constant";
import { createEmploymentType, updateEmploymentType } from "../../actions/JobsAction";
import AddEmployementTypes from "../../component/jobs/AddEmpType";
import { useNavigate } from "react-router-dom";

const AddEmployementType = () => {

    const [response, setResponse] = React.useState({})
    const [showMsg, setShowMsg] = React.useState(false)

    const navigation = useNavigate();

    const onSubmit = (model) => {
        if (model.type === 'ADD') {
            createEmploymentType(model).then((res) => {
                setResponse(res)
                setShowMsg(true)
                setTimeout(() => {
                    setShowMsg(false)
                }, 7000)
            }).catch((err) => {
                alert(err)
            })
        } else {
            updateEmploymentType(model).then((res) => {
                if (res.status) {
                    navigation(constant.component.listEmployementType.url)
                }
                else {
                    setResponse(res)
                    setShowMsg(true)
                    setTimeout(() => {
                        setShowMsg(false)
                    }, 7000)
                }
            }).catch((err) => {
                alert(err)
            })
        }

    }


    return (
        <React.Fragment>
            <AddEmployementTypes
                onSubmit={(data) =>
                    onSubmit(data)}
                response={response}
                showMsg={showMsg} />
        </React.Fragment>
    )
}

export default AddEmployementType;