import React, { useEffect, useState, useRef } from "react";
import Pagination from 'react-js-pagination';
import constant from "../../constant";
import { Link, useLocation, useNavigate } from "react-router-dom";
import moment from 'moment'
import Loader from '../common/Loader';
import Swal from 'sweetalert2'
import { capitalize } from "../../utils";
import Multiselect from "multiselect-react-dropdown";
import { Spinner } from "reactstrap";
import NumberFormat from "react-number-format";
import FileSaver from 'file-saver';
import queryString from 'query-string'
import { Typeahead } from "react-bootstrap-typeahead";
import SelectLocationInput from './SelectLocation'

function TotalCandidates(props) {

    const [ud, setUd] = useState({})
    const [currentPage, setCurrentPage] = useState(1);
    const [candidateFullDetails, setCandidateFullDetails] = useState({})
    const { list } = props
    const { setKeyword } = props
    const { setShowLoader } = props

    const skillSelect = props.Skilltype.list
    const location = useLocation()
    const navigate = useNavigate()

    const handlePageChange = (pageNumber) => {
        window.scroll(0, 0)
        setCurrentPage(pageNumber);
        navigate(location.pathname + '?p=' + pageNumber)
        props.setPage(pageNumber)
    }
    const qParams = queryString.parse(location.search)


    const receiveLocation = (textLocation) => {
        setUd({ ...ud, userLocation: textLocation })
    }
    const GetResume = () => {

        Swal.fire({
            icon: "error",
            text: "Resume Not Available",
            timer: 2000,
            showCancelButton: false,
            showConfirmButton: false,
        });
    }

    // const saveFile = (e, filename) => {
    //   console.log('download')
    //   const { RESUME_FILE, CANDIDATE_ID } = model
    //   FileSaver.saveAs(
    //     `${process.env.REACT_APP_BASE_URL}/candidate-resume/${CANDIDATE_ID}/${RESUME_FILE}`, RESUME_FILE
    //   );
    // }
    const downloadResume = (input) => {
        const RESUME_FILE = input.RESUME_FILE;
        const CANDIDATE_ID = input.CANDIDATE_ID;
        FileSaver.saveAs(
            `${process.env.REACT_APP_BASE_URL}/candidate-resume/${CANDIDATE_ID}/${RESUME_FILE}`,
            RESUME_FILE
        );
    };

    const searchList = () => {
        setShowLoader(true)
        props.totalCandidatesLists(currentPage)
    }

    return (
        <React.Fragment>
            <div className="main-content">
                <div className="page-content">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">Candidates List</h4>

                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item">
                                            <a href="https://sua.jobzseed.com/">
                                                Candiates
                                            </a>
                                        </li>
                                        <li className="breadcrumb-item active">Candidates List</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* -----------Filter Section------ */}
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body filter-section-bx">
                                    <form className="row gy-12 gx-12 align-items-center">
                                        <div className="row">
                                            <div className="col-lg-2">
                                                <div className="mb-3">
                                                    <label htmlFor="basicpill-firstname-input">
                                                        Search Candiates
                                                    </label>
                                                    <input type="text"
                                                        value={props.KEYWORD}
                                                        onChange={(e) => {
                                                            props.setKeyword(e.target.value)
                                                        }}
                                                        onKeyDown={
                                                            (e) => {
                                                                if (e.key == 'Enter') {
                                                                    e.preventDefault()
                                                                    searchList()
                                                                }
                                                            }
                                                        }
                                                        className="form-control"
                                                        id="autoSizingInputGroup"
                                                        placeholder="Enter Name, Email ID etc"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-1">
                                                <div className="mb-3">
                                                    <label htmlFor="basicpill-firstname-input">
                                                        Experience
                                                    </label>
                                                    <select
                                                        className="form-control"
                                                        value={props.expYear}
                                                        onChange={(e) => {
                                                            props.setExpYear(e.target.value)
                                                        }}
                                                        onKeyDown={
                                                            (e) => {
                                                                if (e.key == 'Enter') {
                                                                    e.preventDefault()
                                                                    searchList()
                                                                }
                                                            }
                                                        }>
                                                        <option value="">Experience</option>
                                                        <option value="0,1">0-1 Year</option>
                                                        <option value="1,3">1-3 Years</option>
                                                        <option value="3,5">3-5 Years</option>
                                                        <option value="5,7">5-7 Years</option>
                                                        <option value="7,10">7-10 Years</option>
                                                        <option value="10,12">10-12 Years</option>
                                                        <option value="12,14">12-14 Years</option>
                                                        <option value="14,16">14-16 Years</option>
                                                        <option value="16,18">16-18 Years</option>
                                                        <option value="18,20">18-20 Years</option>
                                                        <option value="20,25">20-25 Years</option>
                                                        <option value="25,30">25-30 Years</option>
                                                        <option value="30,">30+ Years</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-1">
                                                <div className="mb-3">
                                                    <label htmlFor="basicpill-firstname-input">Age</label>
                                                    <select className="form-control"
                                                        value={props.age}
                                                        onChange={(e) => {
                                                            props.setAge(e.target.value)
                                                        }}>
                                                        <option value="">Age</option>
                                                        <option value="17,20">17-20</option>
                                                        <option value="21,23">21-23</option>
                                                        <option value="24,26">24-26</option>
                                                        <option value="27,29">27-29</option>
                                                        <option value="30,35">30-35</option>
                                                        <option value="36,40">36-40</option>
                                                        <option value="41,45">41-45</option>
                                                        <option value="46,50">46-50</option>
                                                        <option value="50,55">50-55</option>
                                                        <option value="55,60">55-60</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-1">
                                                <div className="mb-3">
                                                    <label htmlFor="basicpill-firstname-input">Gender</label>
                                                    <select className="form-control"
                                                        value={props.gender}
                                                        onChange={(e) => {
                                                            props.setGender(e.target.value)
                                                        }}>
                                                        <option value="" >Gender</option>
                                                        {props.genderList && props.genderList.map((item) => {
                                                            return (
                                                                <option value={item.GENDER_ID}>{item.GENDER}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-1">
                                                <div className="mb-3">
                                                    <label htmlFor="basicpill-firstname-input">Education</label>
                                                    <select className="form-control"
                                                        value={props.education}
                                                        onChange={(e) => {
                                                            props.setEducation(e.target.value)
                                                        }}>
                                                        <option value="" >Education</option>
                                                        {props.educationtype && props.educationtype.map((item) => {
                                                            return (
                                                                <option value={item.QUALIFICATION_ID}>{item.QUALIFICATION_NAME}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-1">
                                                <div className="mb-3">
                                                    <label htmlFor="basicpill-firstname-input">Location</label>

                                                    <SelectLocationInput
                                                        receiveLocation={(textLocation) => props.setLocation(textLocation)}
                                                        options={props.locationList ? props.locationList : []}
                                                    />

                                                </div>
                                            </div>
                                            <div className="col-lg-1">
                                                <div className="mb-3">
                                                    <label htmlFor="basicpill-firstname-input">Pincode</label>
                                                    <NumberFormat
                                                        maxLength={6}
                                                        minLength={6}
                                                        type="text"
                                                        value={props.pincode}
                                                        onChange={(e) => {
                                                            props.setPincode(e.target.value)
                                                        }} className="form-control" id="autoSizingInputGroup" placeholder="Pincode"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-1">
                                                <div className="mb-3">
                                                    <label htmlFor="basicpill-firstname-input">Salary</label>
                                                    <select className="form-control"
                                                        value={props.salary}
                                                        onChange={(e) => {
                                                            props.setSalary(e.target.value)
                                                        }}>
                                                        <option value="" >Salary (in lakh)</option>
                                                        <option value="0">0</option>
                                                        <option value="1,4">1-4</option>
                                                        <option value="5,10">5-10</option>
                                                        <option value="11,15">11-15</option>
                                                        <option value="16,20">16-20</option>
                                                        <option value="20,30">20-30</option>
                                                        <option value="31,40">31-40</option>
                                                        <option value="40,50">40-50</option>
                                                        <option value="51,60">51-60</option>
                                                        <option value="61,70">61-70</option>
                                                        <option value="71,80">71-80</option>
                                                        <option value="81,90">81-90</option>
                                                        <option value="91,100">91-100</option>
                                                        <option value="100+">100+</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-1">
                                                <div className="mb-3">
                                                    <label htmlFor="basicpill-firstname-input">Category</label>
                                                    <select className="form-control"
                                                        value={props.cast}
                                                        onChange={(e) => {
                                                            props.setCast(e.target.value)
                                                        }}>
                                                        <option value="" >Category</option>
                                                        <option value="1" >General</option>
                                                        <option value="2" >OBC</option>
                                                        <option value="3" >SC/ST</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-2">
                                                <div className="mb-3">
                                                    <label htmlFor="basicpill-firstname-input">Physically disable?</label>
                                                    <select className="form-control"
                                                        value={props.PhysicallyDisabled}
                                                        onChange={(e) => {
                                                            props.setPhysicallyDisabled(e.target.value)
                                                        }}>
                                                        <option value="" >Select Physically abled?</option>
                                                        <option value="Y">Yes</option>
                                                        <option value="N">No</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-2">
                                                <div className="mb-3">
                                                    <label htmlFor="basicpill-firstname-input">Industry</label>
                                                    <select className="form-control"
                                                        value={props.industry}
                                                        onChange={(e) => {
                                                            props.setIndustry(e.target.value)
                                                            props.getRole(e.target.value);
                                                        }}>
                                                        <option value="" > Select Industry</option>
                                                        {props.industryList && props.industryList.map((item) => {
                                                            return (
                                                                <option value={item.FUNCTIONAL_AREA_ID}>{item.FUNCTIONAL_AREA}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-2">
                                                <div className="mb-3">
                                                    <label htmlFor="basicpill-firstname-input">Role Category</label>
                                                    <select className="form-control"
                                                        value={props.role}
                                                        onChange={(e) => {
                                                            props.setRole(e.target.value)
                                                        }}>
                                                        <option value="" > Select Role Category</option>
                                                        {props.roleList && props.roleList.map((item) => {
                                                            return (
                                                                <option value={item.ROLE_ID}>{item.ROLE_NAME}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-2">
                                                <div className="mb-3">
                                                    <label htmlFor="basicpill-firstname-input">
                                                        Date From
                                                    </label>
                                                    <input type="date"
                                                        value={props.dateFrom}
                                                        onChange={(e) => {
                                                            props.setDateFrom(e.target.value)
                                                        }}
                                                        className="form-control" id="autoSizingInputGroup" placeholder="Enter Candidate ID, Name, Email ID etc" />
                                                </div>
                                            </div>
                                            <div className="col-lg-2">
                                                <div className="mb-3">
                                                    <label htmlFor="basicpill-firstname-input">
                                                        Date To
                                                    </label>
                                                    <input type="date"
                                                        value={props.dateTo}
                                                        onChange={(e) => {
                                                            props.setDateTo(e.target.value)
                                                        }}
                                                        className="form-control" id="autoSizingInputGroup" placeholder="Enter Candidate ID, Name, Email ID etc" />
                                                </div>
                                            </div>
                                            <div className="col-lg-2">
                                                <div className="mb-3 mul-select-dro">
                                                    <label htmlFor="basicpill-firstname-input">
                                                        Area of Expertise
                                                    </label>
                                                    <Multiselect
                                                        id="Skill"
                                                        options={skillSelect}
                                                        displayValue="SKILL"
                                                        onSelect={(selectedValue) => {
                                                            props.setSkills([selectedValue[selectedValue.length - 1].SKILL_ID])
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-2">
                                                <div className="md-3 d-flex mt-4">
                                                    <button
                                                        type="button"
                                                        className="btn btn-success waves-effect btn-label waves-light"
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            searchList()
                                                        }}
                                                    >
                                                        <i className="bx bx-check-double label-icon"></i> Search
                                                    </button> &nbsp;{" "}
                                                    <button type="button" className="btn btn-outline-danger"
                                                        onClick={(e) => {
                                                            // props.totalCandidatesLists()
                                                            props.handleReset(e)
                                                        }}>
                                                        Reset
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* -------End------ */}

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex" style={{ justifyContent: "space-between" }}>
                                        <h4 className="card-title mb-4">Candidates</h4>
                                        <h4 className="card-title mb-4">Total Candidates - {props.count}</h4>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table align-middle table-nowrap table-hover">
                                            {!props.showLoader && <thead className="table-light">
                                                <tr>
                                                    <th scope="col" style={{ width: "70px" }}>
                                                        ID
                                                    </th>
                                                    <th scope="col">Candidate Information</th>
                                                    <th scope="col">Rozgar Stats</th>
                                                    <th scope="col">Profile Status</th>
                                                    <th scope="col">Other Information </th>
                                                    {/* <th scope="col">Action</th> */}
                                                </tr>
                                            </thead>}
                                            <tbody>
                                                {props.showLoader ? <Loader /> : list && list.length > 0 && list.map((ele, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td
                                                                style={{
                                                                    color: "#ff8000",
                                                                    fontFamily: "nunito",
                                                                }}
                                                            >
                                                                {25 * currentPage + (index + 1) - 25}
                                                            </td>
                                                            <td>
                                                                <h5 className="font-size-14 mb-1">
                                                                    <a href="" className="text-dark"><Link to={constant.component.CandidatesProfile.url.replace(':id', ele.CANDIDATE_ID)}>
                                                                        {ele.CANDIDATE_NAME !== null && <span style={{ color: "#000" }}>  {ele.CANDIDATE_NAME?.charAt(0)?.toUpperCase() + ele.CANDIDATE_NAME.slice(1)}</span>}</Link>
                                                                    </a>
                                                                </h5>
                                                                <p className="text-muted mb-0" style={{ color: "#00add2 !important" }}>
                                                                    <span className={ele.WORK_STATUS === 'F'}>{ele.WORK_STATUS === 'F' ? 'Fresher' : 'Experience'}</span>&nbsp;

                                                                </p>
                                                                <p className="text-muted mb-0">
                                                                    <i className="bx bxs-envelope" style={{ color: "#fae60f" }} />&nbsp;{ele.EMAIL_ID ? ele.EMAIL_ID : 'Not Available'} {" "}

                                                                    <i
                                                                        style={{ color: "green", fontSize: 15 }}
                                                                        className={ele.IS_EMAIL_VERIFIED === 'Y' ? "bx bx-check-circle text-success" : "bx bx-x-circle text-danger"}
                                                                        title={ele.IS_EMAIL_VERIFIED === 'Y' ? "Verified" : "Not verified"}
                                                                    />
                                                                </p>
                                                                <p className="text-muted mb-0">
                                                                    <i className="bx bxs-phone" style={{ color: "#299c0c" }} />&nbsp;{ele.PHONENO ? ele.PHONENO : 'Not Available'} {" "}<i
                                                                        style={{ color: "#4deb26", fontSize: 15 }}
                                                                        className={ele.IS_PHONE_VERIFIED === 'Y' ? "bx bx-check-circle text-success" : "bx bx-x-circle text-danger"}
                                                                        title={ele.IS_PHONE_VERIFIED === 'Y' ? "Verified" : "Not verified"}
                                                                    />
                                                                </p>
                                                                <p className="text-muted mb-0"><span style={{ color: "#f46a6a" }}>Member Since</span> | {moment(ele.CREATED_ON).format('DD-MM-YYYY')} </p>

                                                            </td>
                                                            <td>
                                                                <h5 className="font-size-14 mb-1">
                                                                    <a
                                                                        // href="?status=all"
                                                                        target="_blank"
                                                                        className="text-dark"
                                                                    ><Link to={constant.component.JobApplied.url.replace(':id', ele.CANDIDATE_ID)}>
                                                                            <span style={{ color: "#000" }}>
                                                                                Jobs Applied&nbsp;({ele.appliedJobsCount ? ele.appliedJobsCount : '0'})
                                                                            </span>
                                                                        </Link>
                                                                    </a>
                                                                </h5>
                                                                <p className="text-muted mb-0">
                                                                    <a
                                                                        className="text-dark"
                                                                    ><Link to={constant.component.JobApplied.url.replace(':id', ele.CANDIDATE_ID)}>
                                                                            <span style={{ color: "#31ad11" }}>
                                                                                Shortlisted&nbsp;({ele.shortlistedJobsCount ? ele.shortlistedJobsCount : '0'})
                                                                            </span></Link>
                                                                    </a>
                                                                </p>{" "}
                                                                <a
                                                                    className="text-dark"
                                                                ><Link to={constant.component.JobApplied.url.replace(':id', ele.CANDIDATE_ID)}>
                                                                        <span style={{ color: "#ff0000" }}>
                                                                            Rejected&nbsp;({ele.rejectJobsCount ? ele.rejectJobsCount : '0'})
                                                                        </span>
                                                                    </Link>
                                                                </a>{" "}
                                                                <br />

                                                                <Link to={constant.component.JobApplied.url.replace(':id', ele.CANDIDATE_ID)}>
                                                                    <span style={{ color: "#ed9418" }}>
                                                                        Saved Jobs&nbsp;({ele.savedJobsCount ? ele.savedJobsCount : '0'})
                                                                    </span>
                                                                </Link>
                                                                <br />{" "}
                                                                <p></p>
                                                            </td>
                                                            <td style={{ verticalAlign: "top" }} >
                                                                {" "}
                                                                {ele.RESUME_FILE == null ?
                                                                    <a className="text-dark">
                                                                        <span style={{ color: "#801d16" }}>
                                                                            Download Resume {" "}- Not Available
                                                                        </span>
                                                                    </a> : <a href='javascript:void(0)' onClick={() => { downloadResume(ele) }}
                                                                        className="text-dark"
                                                                    >
                                                                        <span style={{ color: "#801d16" }}>
                                                                            Download Resume{" "}
                                                                            <i className="bx bxs-cloud-download text-info" style={{ fontSize: '20px' }}></i>{" "}
                                                                        </span>
                                                                    </a>}
                                                                {" "}
                                                                <br />
                                                                <table cellspacing="2px" cellPadding="2px">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td style={{ paddingRight: "42px" }}>
                                                                                •{" "}
                                                                                <a
                                                                                    className="text-dark"
                                                                                >
                                                                                    <Link to={constant.component.CandidatesProfile.url.replace(':id', ele.CANDIDATE_ID)}>
                                                                                        <span style={{ color: "#004e9b" }}>
                                                                                            Education
                                                                                        </span></Link>
                                                                                </a>{" "}
                                                                                {ele.educationcount === 0 ? "" : <i className="bx bxs-badge-check text-success"></i>}
                                                                            </td>
                                                                            <td>&nbsp;</td>
                                                                            <td>
                                                                                •{" "}
                                                                                <a className="text-dark">
                                                                                    <Link to={constant.component.CandidatesProfile.url.replace(':id', ele.CANDIDATE_ID)}>
                                                                                        <span style={{ color: "#004e9b" }}>
                                                                                            Headline
                                                                                        </span>
                                                                                    </Link>
                                                                                </a> {" "}
                                                                                {ele.RESUME_HEADLINE == null ? '' : <i className="bx bxs-badge-check text-success"></i>}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                •{" "}
                                                                                <a
                                                                                    className="text-dark"
                                                                                ><Link to={constant.component.CandidatesProfile.url.replace(':id', ele.CANDIDATE_ID)}>
                                                                                        <span onClick={(ele.CANDIDATE_ID)} style={{ color: "#004e9b" }}>
                                                                                            Career Profile
                                                                                        </span></Link>
                                                                                </a>{" "}
                                                                                {ele.EXPECTED_SALARY_LACS === null ? '' : <i className="bx bxs-badge-check text-success"></i>}
                                                                            </td>
                                                                            <td>&nbsp;</td>
                                                                            <td>
                                                                                •{" "}
                                                                                <a
                                                                                    className="text-dark"
                                                                                ><Link to={constant.component.CandidatesProfile.url.replace(':id', ele.CANDIDATE_ID)}>
                                                                                        <span style={{ color: "#004e9b" }}>
                                                                                            IT Skills
                                                                                        </span></Link>
                                                                                </a>{" "}
                                                                                {ele.itskills === 0 ? "" : <i className="bx bxs-badge-check text-success"></i>}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                •{" "}
                                                                                <a
                                                                                    href="javascript:void(0);"
                                                                                    className="text-dark"
                                                                                ><Link to={constant.component.CandidatesProfile.url.replace(':id', ele.CANDIDATE_ID)}>
                                                                                        <span style={{ color: "#004e9b" }}>
                                                                                            Employment
                                                                                        </span></Link>
                                                                                </a>{" "}
                                                                                {ele.CompanyDeatails.length == 0 ? "" : <i className="bx bxs-badge-check text-success"></i>}
                                                                            </td>
                                                                            <td>&nbsp;</td>
                                                                            <td>
                                                                                •{" "}
                                                                                <a
                                                                                    className="text-dark"
                                                                                ><Link to={constant.component.CandidatesProfile.url.replace(':id', ele.CANDIDATE_ID)}>
                                                                                        <span style={{ color: "#004e9b" }}>
                                                                                            Key Skills
                                                                                        </span></Link>
                                                                                </a>{" "}
                                                                                {ele.skills.length == 0 ? "" : <i className="bx bxs-badge-check text-success"></i>}
                                                                            </td>
                                                                            <td></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                •{" "}
                                                                                <a
                                                                                    className="text-dark"
                                                                                ><Link to={constant.component.CandidatesProfile.url.replace(':id', ele.CANDIDATE_ID)}>
                                                                                        <span style={{ color: "#004e9b" }}>
                                                                                            Profile Summary
                                                                                        </span></Link>
                                                                                </a>{" "}
                                                                                {ele.PROFILE_SUMMARY === null ? '' : <i className="bx bxs-badge-check text-success"></i>}
                                                                            </td>
                                                                            <td>&nbsp;</td>
                                                                            <td>
                                                                                •{" "}
                                                                                <a
                                                                                    className="text-dark"
                                                                                ><Link to={constant.component.CandidatesProfile.url.replace(':id', ele.CANDIDATE_ID)}>
                                                                                        <span style={{ color: "#004e9b" }}>
                                                                                            Projects
                                                                                        </span></Link>
                                                                                </a>{" "}
                                                                                {ele.projectcount === 0 ? "" : <i className="bx bxs-badge-check text-success"></i>}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                •{" "}
                                                                                <a
                                                                                    className="text-dark"
                                                                                ><Link to={constant.component.CandidatesProfile.url.replace(':id', ele.CANDIDATE_ID)}>
                                                                                        <span style={{ color: "#004e9b" }}>
                                                                                            Personal Details
                                                                                        </span></Link>
                                                                                </a>{" "}
                                                                                {ele.DOB === null ? "" : <i className="bx bxs-badge-check text-success"></i>}
                                                                            </td>
                                                                            <td>&nbsp;</td>
                                                                            <td>
                                                                                •{" "}
                                                                                <a
                                                                                    className="text-dark"
                                                                                ><Link to={constant.component.CandidatesProfile.url.replace(':id', ele.CANDIDATE_ID)}>
                                                                                        <span style={{ color: "#004e9b" }}>
                                                                                            Accomplishments
                                                                                        </span></Link>
                                                                                </a>{" "}
                                                                                {ele.certifications.length == 0 ? "" : <i className="bx bxs-badge-check text-success"></i>}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>


                                                            <td>
                                                                <a
                                                                    type="button"
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#staticBackdrop4"
                                                                    className="btn btn-info waves-effect btn-label waves-light"
                                                                    onClick={(e) => { setCandidateFullDetails(ele) }}>
                                                                    <i className="bx bx-street-view label-icon "></i> View Details
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}


                                                {!props.showLoader && list.length == 0 && < td colSpan={10} className="text-center text-danger pt-3">No Candidate Record Found</td>}

                                                <div className="modal fade" id="staticBackdrop4" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title" id="staticBackdropLabel">Candidate Details - {candidateFullDetails.CANDIDATE_NAME}</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>
                                                            <div className="modal-body">
                                                                <p>{candidateFullDetails &&
                                                                    <table className="table table-striped mb-0 job-details-table skills-data-table">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td style={{ width: "32%" }}>ID</td>
                                                                                <td>{candidateFullDetails.CANDIDATE_ID}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Name</td>
                                                                                <td>{candidateFullDetails.CANDIDATE_NAME}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Email</td>
                                                                                <td>{candidateFullDetails.EMAIL_ID}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Contact Number</td>
                                                                                <td>{candidateFullDetails.PHONENO}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>DOB</td>
                                                                                <td>{candidateFullDetails.DOB ? candidateFullDetails.DOB : 'Not Available'}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>GENDER</td>
                                                                                <td>{candidateFullDetails.GENDER ? candidateFullDetails.GENDER : 'Not Available'}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Address</td>
                                                                                <td>{candidateFullDetails.HOME_TOWN ? candidateFullDetails.HOME_TOWN : 'Not Available'}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Skills</td>
                                                                                <td style={{ whiteSpace: 'initial' }}>
                                                                                    {candidateFullDetails.skills && candidateFullDetails.skills.length > 0 ? candidateFullDetails.skills.map(i => (
                                                                                        <p>&nbsp;{i.SKILL},</p>

                                                                                    )
                                                                                    ) : "Not Available "}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Certification Tittle</td>
                                                                                <td>
                                                                                    {candidateFullDetails.certifications && candidateFullDetails.certifications.length > 0 ? candidateFullDetails.certifications.map(i => (
                                                                                        <td>&nbsp;{i.CERTIFICATION_TITLE}
                                                                                        </td>
                                                                                    )
                                                                                    ) : "Not Available "}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Current Comapny</td>
                                                                                <td>
                                                                                    {candidateFullDetails.CompanyDeatails && candidateFullDetails.CompanyDeatails.length > 0 ? candidateFullDetails.CompanyDeatails.map(i => (
                                                                                        <td>{i.CURRENT_COMPANY}
                                                                                        </td>
                                                                                    )
                                                                                    ) : "Not Available "}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Email Verification Status</td>
                                                                                <td><span className={candidateFullDetails.IS_EMAIL_VERIFIED === 'Y' ? "badge rounded-pill bg-success" : "badge rounded-pill bg-warning"}>{candidateFullDetails.IS_EMAIL_VERIFIED === 'Y' ? 'VERIFY' : 'PENDING'}</span></td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td>Phone Verification Status</td>
                                                                                <td><span className={candidateFullDetails.IS_PHONE_VERIFIED === 'Y' ? "badge rounded-pill bg-success" : "badge rounded-pill bg-warning"}>{candidateFullDetails.IS_PHONE_VERIFIED === 'Y' ? 'VERIFY' : 'PENDING'}</span></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Created On</td>
                                                                                <td>{moment(candidateFullDetails.CREATED_ON).format('DD-MM-YYYY')}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>}
                                                                </p>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <ul className="pagination pagination-rounded justify-content-center mt-4">
                                                <Pagination
                                                    activePage={qParams?.p ? +qParams?.p : currentPage}
                                                    itemsCountPerPage={20}
                                                    totalItemsCount={props.count}
                                                    pageRangeDisplayed={5}
                                                    onChange={handlePageChange}
                                                    itemClass="page-item"
                                                    linkClass="page-link"
                                                />
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default TotalCandidates;
