import React, { Component } from 'react'
import Swal from 'sweetalert2';
import { onChange, validateForm } from '../../../utils';
import { uploadAnnouncementDocument } from '../../../actions/campus'
export default class addAnnouncement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            TITLE: { name: 'TITLE', value: '', error: '', isRequired: true },
            DESCRIPTION: { name: 'DESCRIPTION', value: '', error: '', isRequired: true },
            DOCUMENT: { name: 'DOCUMENT', value: '', error: '', isRequired: true }
        }
    }


    onChange(e) {
        const { name, value } = e.target;
        onChange(this, name, value)
    }


    onSubmit(e) {
        e.preventDefault();
        if (validateForm(this)) {
            const { TITLE, DESCRIPTION, DOCUMENT } = this.state;
            const model = {
                TITLE: TITLE.value,
                DESCRIPTION: DESCRIPTION.value,
                DOCUMENT: DOCUMENT.value
            }

            this.props.onSubmit(model)
        }

    }


    onFileChange = (e) => {
        let files = e.target.files
        if (files && files.length) {
            if (files[0].size <= 5000000) {
                const formData = new FormData();
                formData.append('file', files[0])
                uploadAnnouncementDocument(formData).then(res => {
                    if (res.status) {
                        onChange(this, this.state.DOCUMENT.name, res.result.fileName)
                    }
                })
            }
            else {
                onChange(this, this.state.logo.name, '')
                Swal.fire({
                    icon: "error",
                    text: "File size must be less than 5 MB.",
                    timer: 2000,
                    showCancelButton: false,
                    showConfirmButton: false,
                });
            }

        }
    }
    render() {
        const { TITLE, DESCRIPTION, DOCUMENT } = this.state;
        return (
            <React.Fragment>
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0">Add New Announcement
                                        </h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item active">Add New Announcement</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="live-preview">
                                                <form
                                                    onSubmit={(e) => this.onSubmit(e)}
                                                    className="row gy-4">
                                                    <div className="col-xxl-6 col-md-6">
                                                        <div>
                                                            <label htmlFor="basiInput" className="form-label">
                                                                Title<span className='text-danger'>*</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className={!TITLE.error ? "form-control" : "form-control is-invalid"}
                                                                name={TITLE.name}
                                                                value={TITLE.value}
                                                                onChange={(e) => { this.onChange(e) }}
                                                                placeholder='Enter Title'
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-xxl-6 col-md-6">
                                                        <div>
                                                            <label htmlFor="basiInput" className="form-label">
                                                                Document<span className='text-danger'>*</span>
                                                            </label>
                                                            <input
                                                                type="file"
                                                                className={!DOCUMENT.error ? "form-control" : "form-control is-invalid"}
                                                                name={DOCUMENT.name}
                                                                onChange={(e) => { this.onFileChange(e) }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-xxl-12 col-md-12">
                                                        <div>
                                                            <label htmlFor="basiInput" className="form-label">
                                                                Description<span className='text-danger'>*</span>
                                                            </label>
                                                            <textarea
                                                                type='text'
                                                                rows={10}
                                                                className={!DESCRIPTION.error ? "form-control" : "form-control is-invalid"}
                                                                name={DESCRIPTION.name}
                                                                value={DESCRIPTION.value}
                                                                onChange={(e) => { this.onChange(e) }}
                                                                placeholder='Enter Description'
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-xxl-6 col-md-6">
                                                        <div>
                                                            <label for="focus -ring-utilities" class="form-label">&nbsp;</label>
                                                            <button
                                                                className="btn btn-primary "
                                                                type="submit"
                                                            >
                                                                Submit
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>{" "}
                    </div>

                </div>

            </React.Fragment>

        )
    }
}
