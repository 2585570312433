import React, { useEffect, useState } from 'react'
import { adminUserList, updateAdminStatus } from '../../actions/AdminUserAction'
import List from '../../component/Administration/adminUser/AdminUserList'
import constant from '../../constant'

const AdminUserList = () => {

    const [list, setList] = useState([])
    const [currentPage, setcurrentPage] = useState(1)
    const [count, setCount] = useState(0)
    const [showLoader, setShowLoader] = useState(false)

    useEffect(() => {
        document.title = constant.title.AdminUserList
        getList(currentPage)

    }, [currentPage])

    const getList = (pageNumber) => {
        setShowLoader(true)
        adminUserList(pageNumber).then(res => {
            if (res.status) {
                setShowLoader(false)
                setList(res.result.list)
                setCount(res.result.count)
            }
        }).catch(err => {
            alert(err)
        })
    }

    const updateStatus = (model) => {
        updateAdminStatus(model).then(res => {
            if (res.status) {
                getList(currentPage)
            }
            else {
                alert(res.error)
            }
        }).catch(err => {
            alert(err)
        })
    }

    return (
        <React.Fragment>
            <List
                list={list}
                setList={setList}
                count={count}
                setPage={setcurrentPage}
                getList={(page) => { getList(page) }}
                updateStatus={updateStatus}
                showLoader={showLoader}
            />
        </React.Fragment>
    )
}

export default AdminUserList;