import React from 'react'
import Add from "../../../component/Utilities/InterviewQuestion/AddInterviewQuestion"
import { useEffect, useState } from 'react'
import { addInterviewQuestion, InterviewQuestionFormData } from '../../../actions/utilitiesAction'

export default function AddInterviewQuestions() {

    const [response, setResponse] = useState({})
    const [showMsg, setShowMsg] = useState(false)
    const [skills, setSkills] = useState([])
    const [roleList, setRoleList] = useState([])
    const [company, setCompany] = useState([])

    useEffect(() => {
        InterviewQuestionFormData().then((res) => {
            setSkills(res.result.list)
            setRoleList(res.result.list1)
            setCompany(res.result.list2)
        }).catch((err) => {
            alert(err)
        })

    }, [])

    const onSubmit = (model) => {
        addInterviewQuestion(model).then((res) => {
            if (res.status) {
                setResponse(res)
                setShowMsg(true)
                setTimeout(() => {
                    setShowMsg(false)
                }, 7000)
            }
            else {
                setResponse(res)
                setShowMsg(true)
                setTimeout(() => {
                    setShowMsg(false)
                }, 7000)
            }
        }).catch((err) => {
            alert(err)
        })
    }


    return (
        <React.Fragment>
            <Add
                skills={skills}
                onSubmit={onSubmit}
                response={response}
                showMsg={showMsg}
                roleList={roleList}
                company={company}
            />
        </React.Fragment>
    )
}
