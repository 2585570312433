import React from 'react'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import constant from '../../../constant'
import { ToSeoUrl } from '../../../utils'
import SuccessMessage from '../../common/SuccessMessage'
import ErrorMessage from '../../common/ErrorMessage'
import NumberFormat from 'react-number-format'
import { getStateLists } from '../../../actions/utilitiesAction'

const AddCity = (props) => {
    const { id } = useParams()
    const Navigate = useNavigate()
    const [error, setError] = useState(false)
    const [state, setState] = useState([])


    const [city, setCity] = useState({
        STATE_ID: "",
        COUNTRY_ID: "",
        CITY: "",
        CITY_HEADLINE: "",
        ABOUT_CITY: "",
        URL: "",
        SORT_NUMBER: ""
    })

    const handleChange = (e) => {
        const { name, value } = e.target

        if (name === 'CITY') {
            let url = ToSeoUrl(value);
            setCity({
                ...city,
                [name]: value,
                'URL': url
            })
        }
        else if (name === 'COUNTRY_ID') {

            getStateLists(value).then((res) => {
                if (res.status) {
                    setState(res.result.list)
                    setCity({
                        ...city,
                        [name]: value
                    })

                }
            }).catch((err) => {
                alert(err)
            })
        }
        else {
            setCity({
                ...city,
                [name]: value
            })
        }

    }

    const handleReset = (e) => {
        setError(false)
        e.preventDefault();
        id && Navigate(constant.component.addCity.url)
        setCity(PrevState => ({
            ...PrevState,
            STATE_ID: "",
            COUNTRY_ID: "",
            CITY: "",
            CITY_HEADLINE: "",
            ABOUT_CITY: "",
            URL: "",
            SORT_NUMBER: ""
        }))

    }

    const onSubmit = (e) => {
        e.preventDefault();
        setCity(PrevState => ({
            ...PrevState,
            STATE_ID: "",
            COUNTRY_ID: "",
            CITY: "",
            CITY_HEADLINE: "",
            ABOUT_CITY: "",
            URL: "",
            SORT_NUMBER: ""

        }))

        if (city.STATE_ID.toString().trim().length == 0 || city.COUNTRY_ID.toString().trim().length == 0 ||
            city.CITY.trim().length == 0 || city.CITY_HEADLINE.trim().length == 0 ||
            city.ABOUT_CITY.trim().length == 0 || city.URL.trim().length == 0 ||
            city.SORT_NUMBER.toString().trim().length == 0) {
            setError(true)
        } else {
            const model = {
                STATE_ID: city.STATE_ID,
                COUNTRY_ID: city.COUNTRY_ID,
                CITY: city.CITY,
                CITY_HEADLINE: city.CITY_HEADLINE,
                ABOUT_CITY: city.ABOUT_CITY,
                URL: city.URL,
                SORT_NUMBER: city.SORT_NUMBER

            }
            props.onSubmit(model)
            setError(false)

        }



    }

    return (
        <React.Fragment>

            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 " style={{ fontSize: '18px' }}> Add City</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="javascript: void(0);">Utilities</a></li>
                                            <li className="breadcrumb-item active"> Add City</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">City Information</h4>

                                        {
                                            props.showMsg && (props.response.status ?
                                                <SuccessMessage message={props.response.messageCode} />
                                                :
                                                <ErrorMessage message={props.response.error} />)
                                        }

                                        <div id="basic-example">
                                            <section>
                                                <form>
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label for="basicpill-firstname-input">Select Country <span style={{ color: "#FF0000" }}>*</span></label>
                                                                <select

                                                                    className={error && city.COUNTRY_ID.trim().length <= 0 ? 'form-control is-invalid ' : 'form-control'}
                                                                    name="COUNTRY_ID"
                                                                    value={city.COUNTRY_ID}
                                                                    onChange={handleChange}


                                                                >
                                                                    <option value="">Select  Country</option>
                                                                    {
                                                                        props.list && props.list.length > 0 &&
                                                                        props.list.map((item, index) => {
                                                                            return (
                                                                                <option key={index} value={item.COUNTRY_ID}>{item.COUNTRY}</option>
                                                                            )

                                                                        })
                                                                    }
                                                                </select>

                                                                {error && city.COUNTRY_ID.trim().length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}


                                                            </div>
                                                        </div>


                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label for="basicpill-firstname-input">Select State  <span style={{ color: "#FF0000" }}>*</span></label>
                                                                <select

                                                                    className={error && city.STATE_ID.trim().length <= 0 ? 'form-control is-invalid ' : 'form-control'}

                                                                    name="STATE_ID"
                                                                    value={city.STATE_ID}
                                                                    onChange={handleChange}
                                                                >
                                                                    <option value="">Select  State</option>
                                                                    {
                                                                        state && state.length > 0 &&
                                                                        state.map((item, index) => {
                                                                            return (
                                                                                <option key={index} value={item.STATE_ID}> {item.STATE}</option>
                                                                            )

                                                                        })

                                                                    }
                                                                </select>

                                                                {error && city.STATE_ID.trim().length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}


                                                            </div>
                                                        </div>

                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label for="basicpill-address-input">City  <span style={{ color: "#FF0000" }}>*</span></label>
                                                                <input
                                                                    className={error && city.CITY.trim().length <= 0 ? 'form-control is-invalid ' : 'form-control'}
                                                                    placeholder="Enter City "
                                                                    id="basicpill-firstname-input"
                                                                    name="CITY"
                                                                    value={city.CITY}
                                                                    onChange={handleChange}

                                                                />
                                                                {error && city.CITY.trim().length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}

                                                            </div>
                                                        </div>


                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label for="basicpill-firstname-input">City Headline <span style={{ color: "#FF0000" }}>*</span></label>
                                                                <input type="text"
                                                                    className={error && city.CITY_HEADLINE.trim().length <= 0 ? 'form-control is-invalid ' : 'form-control'}
                                                                    id="basicpill-firstname-input"
                                                                    placeholder="Enter City Headline"
                                                                    value={city.CITY_HEADLINE}
                                                                    name="CITY_HEADLINE"
                                                                    onChange={handleChange}
                                                                />
                                                                {error && city.CITY_HEADLINE.trim().length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}

                                                            </div>
                                                        </div>



                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label for="basicpill-firstname-input">About City <span style={{ color: "#FF0000" }}>*</span></label>
                                                                <input type="text"
                                                                    className={error && city.ABOUT_CITY.trim().length <= 0 ? 'form-control is-invalid ' : 'form-control'}
                                                                    id="basicpill-firstname-input"
                                                                    placeholder="About City"
                                                                    value={city.ABOUT_CITY}
                                                                    name="ABOUT_CITY"
                                                                    onChange={handleChange}
                                                                />

                                                                {error && city.ABOUT_CITY.trim().length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}

                                                            </div>
                                                        </div>



                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label for="basicpill-firstname-input">Url <span style={{ color: "#FF0000" }}>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="basicpill-firstname-input"
                                                                    placeholder="Enter Url"
                                                                    value={city.URL}
                                                                    name="URL"
                                                                    onChange={handleChange}

                                                                />


                                                            </div>
                                                        </div>



                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label for="basicpill-firstname-input">Sort Number <span style={{ color: "#FF0000" }}>*</span></label>
                                                                <NumberFormat
                                                                    className={error && city.SORT_NUMBER.trim().length <= 0 ? 'form-control is-invalid ' : 'form-control'}
                                                                    id="basicpill-firstname-input"
                                                                    placeholder="Enter Sort Number"
                                                                    value={city.SORT_NUMBER}
                                                                    name="SORT_NUMBER"
                                                                    onChange={handleChange}
                                                                />
                                                                {error && city.SORT_NUMBER.toString().trim().length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}

                                                            </div>
                                                        </div>

                                                    </div>


                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <button type="button" onClick={(e) => onSubmit(e)} className="btn btn-info waves-effect btn-label waves-light"> <i className="bx bx-check-double label-icon"></i>Create City</button>
                                                                &nbsp;&nbsp;
                                                                <button type="button" onClick={(e) => handleReset(e)} className="btn btn-danger waves-effect btn-label waves-light"><i className="bx bx-block label-icon "></i> Cancel</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </section>
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>


                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AddCity
