import { useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { Form } from "react-bootstrap";
const SelectionsExample = (props) => {
 
 
 
 
  const { options } = props

  return (<Typeahead

    clearButton={false}
    id="CURRENT_LOCATION"
    labelKey="CITY"
    onInputChange={(text, e) => {
      props.receiveLocation(e.target.value)
    }}
    onChange={e=>{
      if(e[0])props.receiveLocation(e[0].CITY)
    }}
    options={options}
    placeholder="Location"
  />)


};

export default SelectionsExample