import moment from 'moment';
import React, { useState } from 'react'
import { ShimmerText } from "react-shimmer-effects";

const Info = (props) => {
    const { basicDetails } = props;
    return (
        <React.Fragment>
            <div className="card profileInfoTabSection">
                <h5 className="card-title">Info</h5>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-borderless mb-0">
                            <tbody>
                                <tr>
                                    <th className="ps-0" scope="row">
                                        Full Name :
                                    </th>
                                    <td className="text-muted">{basicDetails ? basicDetails.FIRST_NAME + ' ' + basicDetails.LAST_NAME : <ShimmerText line={1} />}</td>
                                </tr>
                                <tr>
                                    <th className="ps-0" scope="row">
                                        Date Of Birth :
                                    </th>
                                    <td className="text-muted">{basicDetails ? moment(basicDetails.DOB).format('DD MMM YYYY') : <ShimmerText line={1} />}</td>
                                </tr>
                                <tr>
                                    <th className="ps-0" scope="row">
                                        Mobile :
                                    </th>
                                    <td className="text-muted">{basicDetails ? basicDetails.PHONE : <ShimmerText line={1} />}</td>
                                </tr>
                                <tr>
                                    <th className="ps-0" scope="row">
                                        E-mail :
                                    </th>
                                    <td className="text-muted">
                                        {basicDetails ? basicDetails.PERSONAL_EMAIL : <ShimmerText line={1} />}
                                    </td>
                                </tr>
                                <tr>
                                    <th className="ps-0" scope="row">
                                        Address :
                                    </th>
                                    <td className="text-muted">
                                        {basicDetails ? basicDetails.ADDRESS ? basicDetails.ADDRESS : '--' : <ShimmerText line={1} />}
                                    </td>
                                </tr>
                                {props.TYPE === 'ON CAMPUS' && <tr>
                                    <th className="ps-0" scope="row">
                                        Batch:
                                    </th>
                                    <td className="text-muted">{basicDetails ? basicDetails.BATCH : <ShimmerText line={1} />}</td>
                                </tr>}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Info