
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import constant from '../../constant'
import jobzshala from '../../assets/images/jobzshala.png'
import lightLogo from '../../assets/images/logo-light.svg'
import github from '../../assets/images/brands/github.png'
import bitbucket from '../../assets/images/brands/bitbucket.png'
import dribble from '../../assets/images/brands/dribbble.png'
import dropbox from '../../assets/images/brands/dropbox.png'
import mail_chimp from "../../assets/images/brands/mail_chimp.png"
import slack from "../../assets/images/brands/slack.png"
import { getStorage, removeStorage } from '../../utils'
import UserImage from "../../assets/images/users/avatar-2.webp"
const Header = ({ pp }) => {

  const ud = getStorage(constant.keys.userDetails)
  // console.log("props ud ", ud);

  const getFullScreenElement = () => {
    return document.fullscreenElement
      || document.webkitFullscreenElement
      || document.mozFullscreenElement
      || document.msFullscreenElement;
  }

  const toggleFullScreen = () => {
    if (getFullScreenElement) {
      document.exitFullscreen()
    }
    document.documentElement.requestFullscreen().catch((e) => {
    })
  }
  const lockScreen = (e) => {
    e.preventDefault();
    removeStorage(constant.keys.token);
    window.location.href = constant.component.lockScreen.url;
  }



  return (
    <header id="page-topbar">
      <div className="navbar-header">
        <div className="d-flex">
          {/* LOGO */}
          <div className="navbar-brand-box">

            <Link to={constant.component.dashboard.url} className="logo logo-dark">

              <span className="logo-sm">

                <img src={jobzshala} style={{ width: '112px', marginBottom: '5px', marginRight: '92px' }} />

              </span>

              <span className="logo-lg" style={{ fontSize: '21px', color: '#9ec035', fontFamily: 'oswald' }}>

                <span href={constant.component.dashboard.url} className="" style={{ color: '#fefcac', fonFamily: 'nunito', fontSize: '22px', fontWeight: '700' }}><img src={jobzshala} style={{ width: '180px', marginBottom: '5px', marginTop: '10px', marginRight: '92px' }} /></span>


              </span>

            </Link>



            <Link to={constant.component.dashboard.url} className="logo logo-light">

              <span className="logo-sm">

                <img src={lightLogo} alt="" height="22" />

              </span>

              <span className="logo-lg" style={{ fontSize: '21px', color: '#9ec035', fontFamily: 'oswald' }}>
                <span href={constant.component.dashboard.url} title="jobzshala" className="" style={{ color: '#fefcac', fontFamily: 'nunito', fontSize: '22px', fontWeight: '700' }}><img src={jobzshala} style={{ width: '180px', marginBottom: '5px', marginTop: '10px', marginRight: '92px' }} /></span>
              </span>

            </Link>

          </div>

          {/* App Search*/}

          <div className="dropdown dropdown-mega d-none d-lg-block ms-2">
            <button
              type="button"
              className="btn header-item waves-effect"
              data-bs-toggle="dropdown"
              aria-haspopup="false"
              aria-expanded="false"
            >
              <span className='mega-menu-btn' key="t-megamenu" style={{ fontSize: "15px" }}><i class="fa fa-fw fa-bars"></i> ADMINISTRATIVE CONTROL PANEL - QUICK LINKS  <i className="mdi mdi-chevron-down" /></span>

            </button>
            <div className="dropdown-menu dropdown-megamenu">
              <div className="row">
                <div className="col-sm-9">
                  <div className="row">
                    <div className="col-md-4 bordr-right">
                      <h5 className="font-size-14" key="t-ui-components">
                        EMPLOYERS
                      </h5>
                      <ul className="list-unstyled megamenu-list">
                        <li>
                          <Link to={constant.component.addemployers.url} key="t-ecommerce">
                            Add Employer
                          </Link>
                        </li>
                        <li>
                          <Link to={constant.component.employerslist.url} key="t-ecommerce">
                            Employer List
                          </Link>
                        </li>
                        <li>
                          <Link to={constant.component.archiveEmployerList.url} key="t-ecommerce">
                            Archived/Blocked Employers
                          </Link>
                        </li>
                        <li>
                          <Link to={constant.component.EmployersReview.url} key="t-ecommerce">
                            Employer Review
                          </Link>
                        </li>
                        <li>
                          <Link to={constant.component.featuredEmployerList.url} key="t-ecommerce">
                            Featured Employer
                          </Link>
                        </li>
                        <li>
                          <Link to={constant.component.premiumEmployerList.url} key="t-ecommerce">
                            Premium Employer
                          </Link>
                        </li>
                        <li>
                          <Link to={constant.component.employerKYC.url} key="t-ecommerce">
                            Employer KYC
                          </Link>
                        </li>
                      </ul>

                    </div>
                    <div className="col-md-4 bordr-right pdlft-30">
                      <h5 className="font-size-14" key="t-applications">
                        JOBS
                      </h5>
                      <ul className="list-unstyled megamenu-list">
                        <li>
                          <Link to={constant.component.addJobs.url} key="t-ecommerce">
                            Add Jobs
                          </Link>
                        </li>
                        <li>
                          <Link to={constant.component.listJobs.url} key="t-ecommerce">
                            Jobs List
                          </Link>

                        </li>
                        <li>
                          <Link to={constant.component.listJobQuest.url} key="t-contacts">
                            Job Questions List
                          </Link>

                        </li>
                      </ul>
                    </div>
                    <div className="col-md-4 pdlft-30">
                      <h5 className="font-size-14" key="t-extra-pages">
                        CANDIDATES
                      </h5>
                      <ul className="list-unstyled megamenu-list">
                        <li>
                          <Link to={constant.component.candidateslist.url} key="t-light-sidebar">
                            Candidates List
                          </Link>
                        </li>
                        <li>
                          <Link to={constant.component.jobAlert.url} key="t-timeline">
                            Job Alert List
                          </Link>
                        </li>
                        <li>
                          <Link to={constant.component.faqlist.url} key="t-faqs">
                            FAQs
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div>
                    <img style={{marginTop: "70px"}}
                      src="assets/images/megamenu-img.png"
                      alt=""
                      className="img-fluid mx-auto d-block"
                    />
                  </div>
                </div>
                {/* <div className="col-sm-4">
              <div className="row">
                <div className="col-sm-6">
                  <h5 className="font-size-14" key="t-ui-components">
                    UI Components
                  </h5>
                  <ul className="list-unstyled megamenu-list">
                    <li>
                      <Link to="javascript:void(0);" key="t-lightbox">
                        Lightbox
                      </Link>
                    </li>
                    <li>
                      <Link to="javascript:void(0);" key="t-range-slider">
                        Range Slider
                      </a>
                    </li>
                    <li>
                      <Link to="javascript:void(0);" key="t-sweet-alert">
                        Sweet Alert
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);" key="t-rating">
                        Rating
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);" key="t-forms">
                        Forms
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);" key="t-tables">
                        Tables
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);" key="t-charts">
                        Charts
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-sm-5">
                  <div>
                    <img
                      src="assets/images/megamenu-img.png"
                      alt=""
                      className="img-fluid mx-auto d-block"
                    />
                  </div>
                </div>
              </div>
            </div> */}
              </div>
            </div>
          </div>
           <button
              type="button"
              className="btn header-item waves-effect"
              onClick={()=>window.location.href=constant.component.collegeList.url}
            >
              <span className='mega-menu-btn' key="t-megamenu" style={{ fontSize: "15px" }}> CAMPUS  </span>

            </button>
        </div>
        <div className="d-flex">



          <div className="dropdown d-inline-block d-lg-none ms-2">

            <button type="button" className="btn header-item noti-icon waves-effect" id="page-header-search-dropdown"

              data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">

              <i className="mdi mdi-magnify"></i>

            </button>

            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"

              aria-labelledby="page-header-search-dropdown">



              <form className="p-3">

                <div className="form-group m-0">

                  <div className="input-group">

                    <input type="text" className="form-control" placeholder="Search ..." aria-label="Recipient's username" />

                    <div className="input-group-append">

                      <button className="btn btn-primary" type="submit"><i className="mdi mdi-magnify"></i></button>

                    </div>

                  </div>

                </div>

              </form>

            </div>

          </div>

          {/* <div className="dropdown d-none d-lg-inline-block ms-1">

    <button type="button" className="btn header-item noti-icon waves-effect"

        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">

        <i className="bx bx-customize"></i>

    </button>

    <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end">

        <div className="px-lg-2">

            <div className="row g-0">

                <div className="col">

                    <a className="dropdown-icon-item" href="#">

                        <img src={github} alt="Clients" />

                        <span>Candidates</span>

                    </a>

                </div>

                <div className="col">

                    <a className="dropdown-icon-item" href="#">

                        <img src={bitbucket} alt="Employers" />

                        <span>Employers</span>

                    </a>

                </div>

                <div className="col">

                    <a className="dropdown-icon-item" href="#">

                        <img src={dribble} alt="Jobs" />

                        <span>Jobs</span>

                    </a>

                </div>

            </div>



            <div className="row g-0">

                <div className="col">

                    <a className="dropdown-icon-item" href="#">

                        <img src={dropbox} alt="Ads" />

                        <span>Active ds</span>

                    </a>

                </div>

                <div className="col">

                    <a className="dropdown-icon-item" href="">

                        <img src={mail_chimp} alt="Admin User" />

                        <span>Admin User</span>

                    </a>

                </div>

                <div className="col">

                    <a className="dropdown-icon-item" href="#">

                        <img src={slack} alt="HRMS Subscriptions" />

                        <span>HRMS Subscriptions</span>

                    </a>

                </div>

            </div>

        </div>

    </div>

</div> */}



          <div className="dropdown d-none d-lg-inline-block ms-1">

            <button type="button" onClick={toggleFullScreen} className="btn header-item noti-icon waves-effect" data-bs-toggle="fullscreen">

              <i className="bx bx-fullscreen"></i>

            </button>

          </div>



          <div className="dropdown d-inline-block">

            {/* <Link to={constant.component.pushNotification.url} >
              <button onClick={() => window.location.href = constant.component.pushNotification.url} type="button" className="btn header-item noti-icon waves-effect" id="page-header-notifications-dropdown"

                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">


                <i className="bx bx-bell bx-tada"></i>


              </button>
            </Link> */}
            {/* <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"

        aria-labelledby="page-header-notifications-dropdown">

        <div className="p-3">

            <div className="row align-items-center">

                <div className="col">

                    <h6 className="m-0" key="t-notifications"> Notifications </h6>

                </div>

                <div className="col-auto">

                    <a href="#!" className="small" key="t-view-all"> View All</a>

                </div>

            </div>

        </div>

        <div data-simplebar style={{ maxHeight: '230px' }}>

            <Link to="" className="text-reset notification-item">

                <div className="d-flex">

                    <div className="avatar-xs me-3">

                        <span className="avatar-title bg-primary rounded-circle font-size-16">

                            <i className="bx bx-cart"></i>

                        </span>

                    </div>

                    <div className="flex-grow-1">

                        <h6 className="mb-1" key="t-your-order">Your order is placed</h6>

                        <div className="font-size-12 text-muted">

                            <p className="mb-1" key="t-grammer">If several languages coalesce the grammar</p>

                            <p className="mb-0"><i className="mdi mdi-clock-outline"></i> <span key="t-min-ago">3 min ago</span></p>

                        </div>

                    </div>

                </div>

            </Link>

            <Link to="" className="text-reset notification-item">

                <div className="d-flex">

                    <img src="../../assets/images/users/avatar-3.jpg"

                        className="me-3 rounded-circle avatar-xs" alt="user-pic" />

                    <div className="flex-grow-1">

                        <h6 className="mb-1">James Lemire</h6>

                        <div className="font-size-12 text-muted">

                            <p className="mb-1" key="t-simplified">It will seem like simplified English.</p>

                            <p className="mb-0"><i className="mdi mdi-clock-outline"></i> <span key="t-hours-ago">1 hours ago</span></p>

                        </div>

                    </div>

                </div>

            </Link>

            <Link to="" className="text-reset notification-item">

                <div className="d-flex">

                    <div className="avatar-xs me-3">

                        <span className="avatar-title bg-success rounded-circle font-size-16">

                            <i className="bx bx-badge-check"></i>

                        </span>

                    </div>

                    <div className="flex-grow-1">

                        <h6 className="mb-1" key="t-shipped">Your item is shipped</h6>

                        <div className="font-size-12 text-muted">

                            <p className="mb-1" key="t-grammer">If several languages coalesce the grammar</p>

                            <p className="mb-0"><i className="mdi mdi-clock-outline"></i> <span key="t-min-ago">3 min ago</span></p>

                        </div>

                    </div>

                </div>

            </Link>



            <Link to="" className="text-reset notification-item">

                <div className="d-flex">

                    <img src="../../assets/images/users/avatar-4.jpg"

                        className="me-3 rounded-circle avatar-xs" alt="user-pic" />

                    <div className="flex-grow-1">

                        <h6 className="mb-1">Salena Layfield</h6>

                        <div className="font-size-12 text-muted">

                            <p className="mb-1" key="t-occidental">As a skeptical Cambridge friend of mine occidental.</p>

                            <p className="mb-0"><i className="mdi mdi-clock-outline"></i> <span key="t-hours-ago">1 hours ago</span></p>

                        </div>

                    </div>

                </div>

            </Link>

        </div>

        <div className="p-2 border-top d-grid">

            <Link to="" className="btn btn-sm btn-link font-size-14 text-center" >

                <i className="mdi mdi-arrow-right-circle me-1"></i> <span key="t-view-more">View More..</span>

            </Link>

        </div>

    </div> */}

          </div>

          <div class="dropdown d-inline-block">

            <button type="button" class="btn header-item waves-effect" id="page-header-user-dropdown"

              data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">

              {pp.PROFILE_PICTURE ? <img src={`${process.env.REACT_APP_BASE_URL}/admin/profile/pic/${pp.PROFILE_PICTURE}`} className="img-thumbnail rounded-circle rounded-circle header-profile-user " style={{ objectFit: "cover" }} /> : <img src="assets/images/users/avatar-2.webp" alt="" style={{ width: '50px', height: "50px", objectFit: "cover", borderRadius: "50%" }} />}

              <span class="d-none d-xl-inline-block ms-1" key="t-henry">{pp.FIRST_NAME} {pp.LAST_NAME}</span>

              <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>

            </button>

            <div class="dropdown-menu dropdown-menu-end">
              <Link className='dropdown-item d-block' to={constant.component.profile.url} ><i class="bx bx-user font-size-16 align-middle me-1"></i> <span key="t-profile">Profile</span></Link>
              <Link className='dropdown-item d-block' to={constant.component.updateProfile.url} ><i class="bx bx-wrench font-size-16 align-middle me-1"></i> <span key="t-settings">Update Profile</span></Link>
              <a onClick={(e) => { lockScreen(e) }} className='dropdown-item d-block' to={constant.component.lockScreen.url} style={{ cursor: "pointer" }} ><i class="bx bx-lock-open font-size-16 align-middle me-1"></i> <span key="t-lock-screen">Lock screen</span></a>
              <div class="dropdown-divider"></div>
              <Link className='dropdown-item text-danger' to={constant.component.logout.url} ><i class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i> <span key="t-logout">Logout</span></Link>
            </div>

          </div>
          {/* <div class="dropdown d-inline-block">

    <button type="button" class="btn header-item noti-icon waves-effect" onclick="javascript:document.location.href='';">

        <i class="bx bx-cog bx-spin"></i>

    </button>

</div> */}



        </div>

      </div>
    </header>

  )
}

export default Header;