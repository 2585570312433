

import React, { Children, useEffect, useRef, useState } from "react";
import { addFaq, updateFaq } from "../../actions/utilitiesAction";
import constant from "../../constant";
import QualificationLists from "../../component/jobs/QualificationList";
import { archiveEducationQualification, archiveEmploymentType, createEducationQualification, createRole, employmentTypeList, updateEducationQualification, updateRole } from "../../actions/JobsAction";
import QuestionLists from "../../component/jobs/QuestionList";
import RoleLists from "../../component/jobs/RoleList";
import AddRoles from "../../component/jobs/AddRole";
import AddFunctionalAreas from "../../component/jobs/AddFunctionalArea";
import AddEmployementTypes from "../../component/jobs/AddEmpType";
import EmployementTypeLists from "../../component/jobs/ListEmpType";
import { useLocation } from "react-router-dom";
import queryString from 'query-string'

const EmployementTypeList = () => {
    const [currentPage, setCurrentPage] = useState(1)
    const [count, setCount] = useState(0)
    const [empTypeList, setEmpTypeList] = useState([]);
    const location = useLocation()

    useEffect(() => {
        const qParams = queryString.parse(location.search)
        getEmpTypeList(qParams?.p ? qParams?.p : qParams?.pageno ? qParams.pageno : currentPage);
        document.title = constant.title.listEmployementType
    }, [currentPage]);

    const getEmpTypeList = (page) => {
        employmentTypeList({ pageNumber: page })
            .then((res) => {
                if (res.status) {
                    setCount(res.result.count);
                    setEmpTypeList(res.result.list);
                }
            })
            .catch((err) => {
                alert(err);
            });
    };
    const updateStatus = (model) => {
        archiveEmploymentType(model).then((res) => {
            if (res.status) {
                getEmpTypeList(currentPage);
            }
            else {
                alert(res.error)
            }
        }).catch((err) => {
            alert(err)
        })
    }

    return (
        <React.Fragment>
            <EmployementTypeLists
                empTypeList={empTypeList}
                updateStatus={updateStatus}
                getEmpTypeList={(page => getEmpTypeList(page))}
                count={count} setPage={setCurrentPage}
                cpage={currentPage}
            />
        </React.Fragment>
    )
}

export default EmployementTypeList;