import React, { useEffect, useState } from 'react'
import { employerDetailsStatics, totalCollegeStatics, totalStatistics } from '../../actions/ProfileAction'
import Statisic from '../../component/Statisics/Statisic'

export default function Statisics() {

  const [statisicsList ,setstatisicsList]=useState()
  const [CollegeStatisicsList ,setCollegeStatisicsList]=useState()
  const[EmployerStaticsList,setEmployerStaticsList]=useState()

  useEffect(()=>{
    totalStatistics().then((res) => {
      setstatisicsList(res.result)
  }).catch((err) => {
      alert(err)
  })
  totalCollegeStatics().then((res) => {
    setCollegeStatisicsList(res.result)
}).catch((err) => {
    alert(err)
})
employerDetailsStatics().then((res) => {
  setEmployerStaticsList(res.result)
}).catch((err) => {
  alert(err)
})
  },[])

  return (
   <React.Fragment>

  <Statisic
  statisicsList={statisicsList}
  CollegeStatisicsList={CollegeStatisicsList}
  EmployerStaticsList={EmployerStaticsList}
  />
  
   </React.Fragment>
  )
}
