import React from 'react'
import { useState } from 'react';
import moment from 'moment/moment';
import Pagination from 'react-js-pagination';
import { nl2br } from 'react-js-nl2br'
import { Link } from 'react-router-dom';
import constant from '../../constant';

const SubscriptionPackageList = (props) => {

    // const [currentPage, setCurrentPage] = useState(1);

    // const handlePageChange = (pageNumber) => {
    //     setCurrentPage(pageNumber);
    //     props.setPage(pageNumber)
    // };


    const updateStatus = (PACKAGE_ID, STATUS) => {


        const model = {
            PACKAGE_ID: PACKAGE_ID,
            STATUS: STATUS === 'A' ? 'I' : 'A'
        }
        // props.setPage(currentPage)
        props.updateStatus(model)
    }
    return (
        <React.Fragment>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">

                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 font-size-18">Subscription List</h4>
                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item">
                                                <a href="">Subscription List</a>
                                            </li>
                                            <li className="breadcrumb-item active">Subscription List</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table align-middle table-nowrap table-hover">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col">Package Title</th>
                                                        <th scope="col">Package Sub title</th>
                                                        <th scope="col">Package for</th>
                                                        <th scope="col">Price Information</th>
                                                        <th scope="col">Added On</th>
                                                        <th scope="col" colspan="3">
                                                            Action
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {props.List && props.List.length > 0 ?
                                                        props.List.map((ele, index, list) => {

                                                            return (
                                                                <tr>
                                                                    <td>
                                                                        {ele.PACKAGE_TITE}<br></br>

                                                                        <span>Package Type : </span><span style={{ color: "red" }}> {ele.PACKAGE_TYPE}</span>

                                                                    </td>
                                                                    <td>

                                                                        {ele.SUB_TITLE}<br></br>



                                                                        <span>Regular Posting : </span><span style={{ color: "red" }}>{ele.REGULAR_POSTING}</span><br></br>

                                                                        <span>Featured Posting : </span><span style={{ color: "red" }}>{ele.FEATURED_POSTING}</span><br></br>

                                                                        <span>Premium Posting : </span><span style={{ color: "red" }}>{ele.PREMIUM_POSTING}</span>

                                                                    </td>
                                                                    <td>


                                                                        {ele.PACKAGE_FOR}

                                                                    </td>
                                                                    <td>


                                                                        ₹ {ele.PRICE}<br></br>


                                                                        <span>Discount : </span><span style={{ color: "red" }}>{ele.DISCOUNT} % </span><br></br>

                                                                        <span>Applicable  Discount : </span><span style={{ color: "red" }}>{ele.DISCOUNT_APPLICABLE}</span>



                                                                    </td>
                                                                    <td>
                                                                        {moment(ele.CREATED_ON).format(
                                                                            "DD.MM.YYYY on HH:MM A"
                                                                        )}
                                                                    </td>



                                                                    <td>
                                                                        <ul className="list-inline font-size-20 contact-links mb-0">
                                                                        <li className="list-inline-item px-2">
                                                                                <a href="javascript: void(0);" title="View Thread" style={{ cursor: "pointer" }} data-bs-toggle="modal" data-bs-target={"#MODELID" + ele.PACKAGE_ID} ><i className="bx bxs-zoom-in"></i></a>
                                                                                <div className="modal fade" id={"MODELID" + ele.PACKAGE_ID}  tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                                                                                        <div className="modal-content">
                                                                                            <div className="modal-header">
                                                                                                <h5 className="modal-title" id="staticBackdropLabel">Subscription Information</h5>

                                                                                            </div>

                                                                                            <div className="modal-body">
                                                                                                <p>
                                                                                                    <table className="table table-striped mb-0">
                                                                                                        <tbody >
                                                                                                            <tr>
                                                                                                                <td><h5>  Package Type :  </h5></td>
                                                                                                                <td style={{ whiteSpace: "normal", display: "flex", textAlign: "left", fontSize: "14px" }}><p>   {nl2br(ele.PACKAGE_TYPE)} </p></td>
                                                                                                            </tr>

                                                                                                            <tr>
                                                                                                                <td><h5>  Package Title :  </h5></td>
                                                                                                                <td style={{ whiteSpace: "normal", display: "flex", textAlign: "left", fontSize: "14px" }}><p>   {nl2br(ele.PACKAGE_TITE)} </p></td>
                                                                                                            </tr>



                                                                                                            <tr>
                                                                                                                <td><h5>  Package Sub_Title:  </h5></td>
                                                                                                                <td style={{ whiteSpace: "normal", display: "flex", textAlign: "left", fontSize: "14px" }}><p>   {nl2br(ele.SUB_TITLE)} </p></td>
                                                                                                            </tr>

                                                                                                            <tr>
                                                                                                                <td><h5>  Package  Price:  </h5></td>
                                                                                                                <td style={{ whiteSpace: "normal", display: "flex", textAlign: "left", fontSize: "14px" }}><p>   {nl2br(ele.PRICE)} </p></td>
                                                                                                            </tr>

                                                                                                            <tr>
                                                                                                                <td><h5>  Package  for:  </h5></td>
                                                                                                                <td style={{ whiteSpace: "normal", display: "flex", textAlign: "left", fontSize: "14px" }}><p>   {nl2br(ele.PACKAGE_FOR)} </p></td>
                                                                                                            </tr>

                                                                                                            <tr>
                                                                                                                <td><h5>  Regular Posting:  </h5></td>
                                                                                                                <td style={{ whiteSpace: "normal", display: "flex", textAlign: "left", fontSize: "14px" }}><p>   {nl2br(ele.REGULAR_POSTING)} </p></td>
                                                                                                            </tr>

                                                                                                            <tr>
                                                                                                                <td><h5>  Preminum Posting:  </h5></td>
                                                                                                                <td style={{ whiteSpace: "normal", display: "flex", textAlign: "left", fontSize: "14px" }}><p>   {nl2br(ele.PREMIUM_POSTING)} </p></td>
                                                                                                            </tr>


                                                                                                            <tr>
                                                                                                                <td><h5>  Featured Posting:  </h5></td>
                                                                                                                <td style={{ whiteSpace: "normal", display: "flex", textAlign: "left", fontSize: "14px" }}><p>   {nl2br(ele.FEATURED_POSTING)} </p></td>
                                                                                                            </tr>


                                                                                                            <tr>
                                                                                                                <td><h5>  Discount:  </h5></td>
                                                                                                                <td style={{ whiteSpace: "normal", display: "flex", textAlign: "left", fontSize: "14px" }}><p>   {nl2br(ele.DISCOUNT)} </p></td>
                                                                                                            </tr>


                                                                                                            <tr>
                                                                                                                <td><h5>  Discount Applicable:  </h5></td>
                                                                                                                <td style={{ whiteSpace: "normal", display: "flex", textAlign: "left", fontSize: "14px" }}><p>   {nl2br(ele.DISCOUNT_APPLICABLE)} </p></td>
                                                                                                            </tr>


                                                                                                            <tr>
                                                                                                                <td><h5>  Package  Details:  </h5></td>
                                                                                                                <td style={{ whiteSpace: "normal", display: "flex", textAlign: "left", fontSize: "14px" }}><p>   {nl2br(ele.PACKAGE_DETAILS)} </p></td>
                                                                                                            </tr>
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </p>
                                                                                            </div>

                                                                                            <div className="modal-footer">
                                                                                                <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </li>


                                                                            <li className="list-inline-item px-2">
                                                                                <Link to={constant.component.editSubscriptionList.url.replace(':id', ele.PACKAGE_ID)} title="Edit SubscriptionPacakge"><i className="bx bx-edit"></i></Link>

                                                                            </li>
                                                                            <li className="list-inline-item px-2">
                                                                                <a href="javascript: void(0)"
                                                                                    onClick={() => updateStatus(ele.PACKAGE_ID, ele.STATUS)}
                                                                                    style={{ color: 'red' }} title={ele.STATUS == 'A' ? "Archive Record" : "Unarchive Record"}>
                                                                                    {
                                                                                        ele.STATUS == 'A' ? <i className="bx bx-trash"></i> : <i class="bx bx-log-in"></i>


                                                                                    }
                                                                                </a>
                                                                            </li>
                                                                        </ul><br/> 
                                                                        <tr>
                                                                        <td>
                                                                        {
                                                                        ele.STATUS == 'A' ?
                                                                            <button type="button" className="btn btn-success btn-sm waves-effect waves-light">ACTIVE</button> : <button type="button" className="btn btn-danger btn-sm waves-effect waves-light">ARCHIVE</button>
                                                                    }
                                                                    </td>
                                                                    </tr>

                                                                    </td>
                                                                </tr>





                                                            )
                                                        }) : ""
                                                    }
                                                </tbody>
                                            </table>
                                        </div>


                                        {/* <div className="row">
                                            <div className="col-lg-12">
                                                <div className="pagination pagination-rounded justify-content-center mt-4">
                                                    <Pagination
                                                        activePage={currentPage}
                                                        itemsCountPerPage={10}
                                                        totalItemsCount={props.count}
                                                        pageRangeDisplayed={5}
                                                        onChange={handlePageChange}
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                    />
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default SubscriptionPackageList
