import React, { useEffect, useState } from 'react'
import AddInterviewAnswer from '../../../component/Utilities/InterviewQuestion/AddInterviewQuestion'
import constant from '../../../constant'
import { useNavigate, useParams } from 'react-router-dom'
import { updateInterviewQuestion, InterviewQuestionById, activeSkillList, InterviewQuestionFormData } from '../../../actions/utilitiesAction'
import { activeRoleList } from '../../../actions/AdminUserAction'
import { getEmployerCompanyListsForJobs } from '../../../actions/JobsAction'

export default function EditInterviewQuestion() {

    const [skills, setSkills] = useState([])
    const [roleList, setRoleList] = useState([])
    const [company, setCompany] = useState([])
    const [response, setResponse] = useState({})
    const [showMsg, setShowMsg] = useState(false)
    const navigate = useNavigate()
    const params = useParams()
    const [data, setData] = useState(null)

    useEffect(() => {
        InterviewQuestionById(params.id).then((res) => {

            if (res.status) {
                setData(res.result)
            }
            else {
                setResponse(res)
                setShowMsg(true)
                setTimeout(() => {
                    setShowMsg(false)
                }, 7000)
            }
        }).catch(err => {
            alert(err)
        })
        activeSkillList().then((res) => {
            if (res.status) {
                setSkills(res.result.list)
            }
            else {
                alert(res.error)
            }
            getEmployerCompanyListsForJobs().then((res) => {
                if (res.status) {
                    setCompany(res.result.list)
                }
                else {
                    alert(res.error)
                }
            })
            InterviewQuestionFormData().then((res) => {
                if (res.status) {
                    setRoleList(res.result.list1)
                }
            })
        }).catch((err) => {
            alert(err)
        })
    }, [])

    const onSubmit = (model) => {
        updateInterviewQuestion(model).then((res) => {
            if (res.status) {
                navigate(constant.component.interviewQuestionList.url + '?p=' + model.pageno)
            }
            else {
                setResponse(res)
                setShowMsg(true)
                setTimeout(() => {
                    setShowMsg(false)
                }, 7000)
            }
        }).catch(err => {
            alert(err)
        })
    }


    return (
        <React.Fragment>
            {data && <AddInterviewAnswer
                skills={skills}
                roleList={roleList}
                company={company}
                onSubmit={onSubmit}
                detail={data}
                response={response}
                showMsg={showMsg}
            />}
        </React.Fragment>
    )
}
