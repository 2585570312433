import React, { useEffect } from 'react'
import { useState } from 'react'
import Multiselect from 'multiselect-react-dropdown'
import SuccessMessage from '../../common/SuccessMessage';
import ErrorMessage from '../../common/ErrorMessage';
import NumberFormat from 'react-number-format';
import constant from '../../../constant';
import { useNavigate, useParams } from 'react-router-dom';
export default function AddPracticeQuestion(props) {
    const { id } = useParams()
    const { detail } = props
    const Navigate = useNavigate()
    const params = useParams()

    const [error, setError] = useState(false)
    const [data, setData] = useState({
        LEVEL: "",
        QUESTION_TYPE: "",
        QUESTION: "",
        SKILL_ID: [],
        OPTION1: "",
        OPTION2: "",
        OPTION3: "",
        OPTION4: "",
        ANSWER: "",
        SORT_NUMBER: "",
        MODIFIED_BY: "",

    })
    const handleChange = e => {

        const { name, value } = e.target
        setData({
            ...data,
            [name]: value
        })
        console.log(data)
    }


    const handleReset = (e) => {
        e.preventDefault();
        id && Navigate(constant.component.practiceQuestionList.url)
        window.location.reload();
        setError(false)
        setData(prevState => ({
            ...prevState,
            LEVEL: "",
            QUESTION_TYPE: "",
            QUESTION: "",
            SKILL_ID: [],
            OPTION1: "",
            OPTION2: "",
            OPTION3: "",
            OPTION4: "",
            ANSWER: "",
            SORT_NUMBER: ""
        }))
        //  { console.log(data.SKILL_ID, "SKILL_ID"); }
    }
    const select = props.list

    const onSubmit = (e) => {
        let pn = params.pageno
        console.log("pagetest", pn)
        let previousSkill = detail && detail.SKILL && detail.SKILL.length > 0 && detail.SKILL.map((d, i) => { return d.SKILL_ID })
        if (data.ANSWER.trim().length == 0 || data.LEVEL.length == 0 ||
            data.OPTION1.trim().length == 0 || data.OPTION2.trim().length == 0 ||
            data.OPTION3.trim().length == 0 || data.OPTION4.trim().length == 0 || data.QUESTION.trim().length == 0 ||
            data.QUESTION_TYPE.length == 0 || data.SORT_NUMBER.toString().trim().length == 0 || data.SKILL_ID.length == 0) {
            setError(true)
            return false
        }



        const model = {
            LEVEL: data.LEVEL,
            QUESTION_TYPE: data.QUESTION_TYPE,
            QUESTION: data.QUESTION.replace(/'/g, "\\'"),
            SKILL_ID: data.SKILL_ID,
            OPTION1: data.OPTION1.replace(/'/g, "\\'"),
            OPTION2: data.OPTION2.replace(/'/g, "\\'"),
            OPTION3: data.OPTION3.replace(/'/g, "\\'"),
            OPTION4: data.OPTION4.replace(/'/g, "\\'"),
            ANSWER: data.ANSWER.replace(/'/g, "\\'"),
            SORT_NUMBER: data.SORT_NUMBER
        }
        if (detail) {
            model.PRACTICE_QUESTION__ID = detail.PRACTICE_QUESTION__ID
            model.MODIFIED_BY = detail.MODIFIED_BY
        }
        model.pageno = pn

        props.onSubmit(model)

    }


    useEffect(() => {
        (id ? document.title = constant.title.EditPracticeQuestion : document.title = constant.title.AddPracticeQuestion)

        if (detail) {
            setData({
                LEVEL: detail.LEVEL,
                QUESTION_TYPE: detail.QUESTION_TYPE,
                QUESTION: detail.QUESTION,
                SKILL_ID: detail && detail.SKILL && detail.SKILL.length > 0 && detail.SKILL.map((d, i) => {
                    return d.SKILL_ID
                }),
                OPTION1: detail.OPTION1,
                OPTION2: detail.OPTION2,
                OPTION3: detail.OPTION3,
                OPTION4: detail.OPTION4,
                ANSWER: detail.ANSWER,
                SORT_NUMBER: detail.SORT_NUMBER
            })
        }
    }, [])



    return (

        <React.Fragment>
            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">

                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0" style={{ fontSize: "18px" }}>{id ? "Update Practise Questions" : "Add Practise Questions"}</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="javascript: void(0);">Utilities</a></li>
                                            <li className="breadcrumb-item active">{id ? "Update Practise Questions" : "Add Practise Question"}</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">Practice Questions Information</h4>
                                        {
                                            props.showMsg && (props.response.status ?
                                                <SuccessMessage message={props.response.messageCode} />
                                                :
                                                <ErrorMessage message={props.response.error} />)
                                        }
                                        <div id="basic-example">
                                            <section>
                                                <form method="POST">
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label for="basicpill-address-input">Level <span className="text-danger">*</span></label>
                                                                <select className='form-select'
                                                                    name='LEVEL'
                                                                    value={data.LEVEL}
                                                                    onChange={handleChange}
                                                                >
                                                                    <option >Select Level Type</option>
                                                                    <option value="B">Beginner</option>
                                                                    <option value="I" >Intermediate</option>
                                                                    <option value="A" >Advance</option>
                                                                </select>
                                                                {error && data.LEVEL.length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}

                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label for="basicpill-firstname-input">Question Type <span className="text-danger">*</span></label>
                                                                <select className='form-select'
                                                                    name='QUESTION_TYPE'
                                                                    value={data.QUESTION_TYPE}
                                                                    onChange={handleChange}
                                                                    id="qt">
                                                                    <option  >Select Question Type</option>
                                                                    <option value="S">Single Choice</option>
                                                                    <option value="M" >Multiple Choice</option>
                                                                </select>
                                                                {error && data.QUESTION_TYPE.length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}

                                                            </div>
                                                        </div>
                                                    </div>




                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label for="basicpill-address-input">Type your question <span className="text-danger">*</span></label>
                                                                <input type="text"
                                                                    className='form-control'
                                                                    name='QUESTION'
                                                                    value={data.QUESTION}
                                                                    onChange={handleChange}
                                                                    id="basicpill-firstname-input" placeholder="Enter Type your question" />
                                                                <input type="hidden" name="MODIFIED_BY" value={data.OPTION1} />
                                                                {error && data.QUESTION.trim().length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}
                                                            </div>

                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label for="basicpill-firstname-input">Skills <span className="text-danger">*</span></label>

                                                                <Multiselect
                                                                    options={select}
                                                                    onSelect={(selectedValue) => {
                                                                        setData({ ...data, SKILL_ID: [...data.SKILL_ID, selectedValue[selectedValue.length - 1].SKILL_ID] })
                                                                    }}
                                                                    displayValue={"SKILL"}
                                                                    name={"SKILL_ID"}
                                                                    placeholder='choose.....'
                                                                    selectedValues={detail ? detail.SKILL : []}

                                                                    onRemove={(selectedValue) => {
                                                                        setData({
                                                                            ...data, SKILL_ID: selectedValue.map((d, i) => {
                                                                                return d.SKILL_ID
                                                                            })
                                                                        })

                                                                    }}
                                                                />{
                                                                    console.log(data.SKILL_ID, "skil_ID")
                                                                }



                                                                {error && data.SKILL_ID.length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label for="basicpill-address-input">Add Answer Options <span className="text-danger">*</span></label>
                                                                <input type="text"
                                                                    className='form-control'
                                                                    name='OPTION1'
                                                                    value={data.OPTION1}
                                                                    onChange={handleChange}
                                                                    id="basicpill-firstname-input" placeholder="Option 1" />
                                                                {error && data.OPTION1.trim().length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}

                                                            </div>

                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label for="basicpill-address-input">&nbsp;</label>
                                                                <input type="text"
                                                                    name='OPTION2'
                                                                    value={data.OPTION2}
                                                                    onChange={handleChange}
                                                                    className='form-control' id="basicpill-firstname-input" placeholder="Option 2" />
                                                                {error && data.OPTION2.trim().length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}

                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <input type="text"
                                                                    name='OPTION3'
                                                                    value={data.OPTION3}
                                                                    onChange={handleChange}
                                                                    className='form-control' id="basicpill-firstname-input" placeholder="Option 2" />
                                                                {error && data.OPTION3.trim().length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}

                                                            </div>

                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <input type="text"
                                                                    name='OPTION4'
                                                                    value={data.OPTION4}
                                                                    onChange={handleChange}
                                                                    className='form-control' id="basicpill-firstname-input" placeholder="Option 3" />
                                                                {error && data.OPTION4.trim().length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}

                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label for="basicpill-address-input">Correct Answer <span className="text-danger">*</span></label>
                                                                <input type="text"
                                                                    name='ANSWER'
                                                                    value={data.ANSWER}
                                                                    onChange={handleChange}
                                                                    className='form-control' id="basicpill-firstname-input" placeholder="Correct Answer" />
                                                                {error && data.ANSWER.trim().length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}

                                                            </div>

                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label for="basicpill-address-input">Sort Number <span style={{ color: "#FF0000" }}>*</span></label>
                                                                <NumberFormat
                                                                    name="SORT_NUMBER"
                                                                    onChange={handleChange}
                                                                    value={data.SORT_NUMBER}
                                                                    allowLeadingZeros={false}
                                                                    allowNegative={false}
                                                                    thousandSeparator={false}
                                                                    className='form-control'
                                                                    id="basicpill-firstname-input" placeholder="Enter Sort Number"
                                                                    maxLength={10} />
                                                                {error && data.SORT_NUMBER.toString().trim().length <= 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <button type="button"
                                                                    onClick={(e) => onSubmit(e)}
                                                                    className="btn btn-info waves-effect btn-label waves-light"><i className="bx bx-check-double label-icon"></i> {id ? "Save Changes" : "Create Practise Question"}</button>&nbsp;&nbsp;
                                                                <button type="button"
                                                                    onClick={(e) => handleReset(e)}
                                                                    className="btn btn-danger waves-effect btn-label waves-light"><i className="bx bx-block label-icon "></i> Cancel</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </section>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
