import React from 'react'
import { useState } from 'react'
import Select from 'react-dropdown-select'
import NumberFormat from 'react-number-format'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import constant from '../../constant'
import SuccessMessage from '../common/SuccessMessage'
import ErrorMessage from '../common/ErrorMessage'
import Swal from 'sweetalert2'


const AddSubscriptionPackage = (props) => {

    const [error, setError] = useState(false);
    const Navigate = useNavigate();
    const { detail } = props


    const { id } = useParams()

    const [data, setData] = useState({
        PACKAGE_TYPE: '',
        PACKAGE_TITE: '',
        SUB_TITLE: '',
        PACKAGE_DETAILS: '',
        PACKAGE_FOR: '',
        PRICE: '',
        DISCOUNT: '',
        DISCOUNT_APPLICABLE: '',
        REGULAR_POSTING: '',
        JD_POSTING: '',
        FEATURED_POSTING: '',
        SORT_NUMBER: '',
    })


    const handleChange = (e) => {
        const { name, value } = e.target
        setData(prevData => ({
            ...prevData,
            [name]: value
        }))
    }

    const onSubmit = (e) => {
        e.preventDefault()
        if (data.PACKAGE_TITE.trim().length === 0 ||
            data.PACKAGE_TYPE.trim().length === 0 ||
            data.SUB_TITLE.trim().length === 0 ||
            data.PACKAGE_DETAILS.trim().length === 0 ||
            data.PACKAGE_FOR.trim().length == 0 ||
            data.PRICE.toString().trim().length === 0 ||
            data.SORT_NUMBER.toString().trim().length === 0) {
            setError(true)
            // return false
        } else {
            const model = {
                PACKAGE_TYPE: data.PACKAGE_TYPE,
                PACKAGE_TITE: data.PACKAGE_TITE,
                SUB_TITLE: data.SUB_TITLE,
                PACKAGE_DETAILS: data.PACKAGE_DETAILS,
                PACKAGE_FOR: data.PACKAGE_FOR,
                PRICE: data.PRICE,
                DISCOUNT: data.DISCOUNT.length ? data.DISCOUNT : 0,
                DISCOUNT_APPLICABLE: data.DISCOUNT_APPLICABLE.length ? data.DISCOUNT_APPLICABLE : 0,
                REGULAR_POSTING: data.REGULAR_POSTING.length ? data.REGULAR_POSTING : 0,
                JD_POSTING: data.JD_POSTING.length ? data.JD_POSTING : 0,
                FEATURED_POSTING: data.FEATURED_POSTING.length ? data.FEATURED_POSTING : 0,
                SORT_NUMBER: data.SORT_NUMBER,
            };
            
            if (detail) {
                model.PACKAGE_ID = detail.PACKAGE_ID
            }
              
        (data.PACKAGE_TYPE=='Package Type' || data.PACKAGE_FOR =='Select package' ) ? Swal.fire(" please select package ") :props.onSubmit(model) 
            


        }
    }

    useEffect(() => {
        if (detail) {
            setData({
                PACKAGE_TYPE: detail.PACKAGE_TYPE,
                PACKAGE_TITE: detail.PACKAGE_TITE,
                SUB_TITLE: detail.SUB_TITLE,
                PACKAGE_FOR: detail.PACKAGE_FOR,
                PACKAGE_DETAILS: detail.PACKAGE_DETAILS,
                PRICE: detail.PRICE,
                DISCOUNT: detail.DISCOUNT,
                DISCOUNT_APPLICABLE: detail.DISCOUNT_APPLICABLE,
                JD_POSTING: detail.JD_POSTING,
                REGULAR_POSTING: detail.REGULAR_POSTING,
                FEATURED_POSTING: detail.FEATURED_POSTING,
                SORT_NUMBER: detail.SORT_NUMBER,

            })
        }

    }, [])

    useEffect(() => {
        (id ? document.title = constant.title.UpdateSubscriptionPackage : document.title = constant.title.Addsubscription)
    }, [])


    const handleReset = (e) => {
        e.preventDefault();
        // id && Navigate(constant.component.addSubscription.url)
        setError(false)
        setData({
            PACKAGE_TYPE: '',
            PACKAGE_TITE: '',
            SUB_TITLE: '',
            PACKAGE_DETAILS: '',
            PACKAGE_FOR: '',
            PRICE: '',
            DISCOUNT: '',
            DISCOUNT_APPLICABLE: '',
            REGULAR_POSTING: '',
            JD_POSTING: '',
            FEATURED_POSTING: '',
            SORT_NUMBER: '',
        })

    }

    return (
        <React.Fragment>

            <div className="main-content">
             
              {
                 console.log( JSON.stringify(data) +">>>>>>>>>>>>")
              }
                <div className="page-content">
                    <div className="container-fluid">

                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 font-size-18">{id ? 'Update Subscription' : 'Add Subscription'}</h4>
                                    
                                     {
                                        props.showMsg && (props.response.status ?
                                            <SuccessMessage message={props.response.messageCode} />
                                            : <ErrorMessage message={props.response.error} />)
                                     }

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item">
                                                <a href="javascript: void(0);">subscription Content </a> </li>
                                            <li className="breadcrumb-item active">{id ? 'Update Subscription' : 'Add Subscription'}</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">Create Subscription</h4>
                                        <div id="basic-example">
                                            <section>
                                                <form method="POST">
                                                    <div className="row">

                                                        <div className="col-lg-4">
                                                            <div className="mb-3">
                                                                <label htmlFor="basicpill-firstname-input">
                                                                    Package Type <span style={{ color: "#FF0000" }}> * </span>
                                                                </label>
                                                                <select
                                                                    className={error && data.PACKAGE_TYPE.trim().length === 0 ? "form-select is-invalid" : "form-select"}
                                                                    onChange={handleChange}
                                                                    name='PACKAGE_TYPE'
                                                                    value={data.PACKAGE_TYPE}
                                                                >
                                                                    <option selected> Package Type</option>
                                                                    <option value="SINGLE">SINGLE</option>
                                                                    <option value="COMBO">COMBO</option>
                                                                </select>
                                                                {
                                                                    error && data.PACKAGE_TYPE.trim().length <= 0 ? <span className='text-danger ml-1'>This Field is Required</span>
                                                                        : ""}
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-4">
                                                            <div className="mb-3">
                                                                <label htmlFor="basicpill-firstname-input">
                                                                    Package Title <span style={{ color: "#FF0000" }}> * </span>
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className={error && data.PACKAGE_TITE.trim().length === 0 ? 'form-control is-invalid ' : 'form-control'}
                                                                    name="PACKAGE_TITE"
                                                                    id="basicpill-firstname-input"
                                                                    placeholder="Enter Package Title"
                                                                    onChange={handleChange}
                                                                    value={data.PACKAGE_TITE}

                                                                />
                                                                {
                                                                    error && data.PACKAGE_TITE.trim().length <= 0 ? <span className='text-danger ml-1'>This Field is Required</span>
                                                                        : ""}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <div className="mb-3">
                                                                <label htmlFor="basicpill-firstname-input">
                                                                    Sub Title<span style={{ color: "#FF0000" }}> * </span>
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className={error && data.SUB_TITLE.trim().length === 0 ? 'form-control is-invalid ' : 'form-control'}
                                                                    name="SUB_TITLE"
                                                                    value={data.SUB_TITLE}
                                                                    id="basicpill-firstname-input"
                                                                    placeholder="Enter Sub Title"
                                                                    onChange={handleChange}
                                                                />
                                                                {
                                                                    error && data.SUB_TITLE.trim().length <= 0 ? <span className='text-danger ml-1'>This Field is Required</span>
                                                                        : ""}
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="row">
                                                        <div className="col-lg-4">
                                                            <div className="mb-3">
                                                                <label htmlFor="basicpill-firstname-input">
                                                                    Price<span style={{ color: "#FF0000" }}> * </span>
                                                                </label> 

                                                                <NumberFormat
                                                                    type="text"
                                                                    className={error && data.PRICE.length === 0 ? 'form-control is-invalid ' : 'form-control'}
                                                                    value={data.PRICE}
                                                                    name="PRICE"
                                                                    id="basicpill-firstname-input"
                                                                    placeholder="Enter Price"
                                                                    onChange={handleChange}


                                                                />
                                                                {
                                                                    error && data.PRICE.length <= 0 ? <span className='text-danger ml-1'> This Field is Required</span>
                                                                        : ""}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <div className="mb-3">
                                                                <label htmlFor="basicpill-firstname-input">
                                                                    Package for<span style={{ color: "#FF0000" }}> * </span>
                                                                </label>
                                                                <select
                                                                    className={error && data.PACKAGE_FOR.trim().length === 0 ? "form-select is-invalid" : "form-select"}
                                                                    onChange={handleChange}
                                                                    name='PACKAGE_FOR'
                                                                    value={data.PACKAGE_FOR}
                                                                   > 
                                                                    <option selected={true}  > Select package</option>
                                                                    <option value="E">Employer</option>

                                                                </select>

                                                                {error && data.PACKAGE_FOR.trim().length === 0 && <span className='text-danger'>This field is required</span>}
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-4">
                                                            <div className="mb-3">
                                                                <label htmlFor="basicpill-firstname-input">
                                                                    Regular Posting<span style={{ color: "#FF0000" }}> * </span>
                                                                </label>
                                                                <NumberFormat
                                                                    type="text"
                                                                    className={error && data.REGULAR_POSTING.length === 0 ? 'form-control is-invalid ' : 'form-control'}
                                                                    value={data.REGULAR_POSTING}
                                                                    name="REGULAR_POSTING"
                                                                    id="basicpill-firstname-input"
                                                                    placeholder="Enter Regular Posting"
                                                                    onChange={handleChange}
                                                                />
                                                                {
                                                                    error && data.REGULAR_POSTING.length <= 0 ? <span className='text-danger ml-1'>This Field is Required</span>
                                                                        : ""}
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="row">
                                                        <div className="col-lg-4">
                                                            <div className="mb-3">
                                                                <label htmlFor="basicpill-firstname-input">
                                                                    JD Posting
                                                                </label>
                                                                <NumberFormat
                                                                    type="text"
                                                                    class="form-control"
                                                                    value={data.JD_POSTING}
                                                                    name="JD_POSTING"
                                                                    id="basicpill-firstname-input"
                                                                    placeholder="Enter JD Posting"
                                                                    onChange={handleChange}

                                                                />

                                                            </div>
                                                        </div>

                                                        <div className="col-lg-4">
                                                            <div className="mb-3">
                                                                <label htmlFor="basicpill-firstname-input">
                                                                    Featured Posting
                                                                </label>
                                                                <NumberFormat
                                                                    type="text"
                                                                    class="form-control"
                                                                    value={data.FEATURED_POSTING}
                                                                    name="FEATURED_POSTING"
                                                                    id="basicpill-firstname-input"
                                                                    placeholder="Enter Featured Posting"
                                                                    onChange={handleChange}
                                                                />

                                                            </div>
                                                        </div>


                                                        <div className="col-lg-4">
                                                            <div className="mb-3">
                                                                <label htmlFor="basicpill-firstname-input">
                                                                    Discount
                                                                </label>
                                                                <NumberFormat
                                                                    type="text"
                                                                    class="form-control"
                                                                    onChange={handleChange}
                                                                    value={data.DISCOUNT}
                                                                    name="DISCOUNT"
                                                                    id="basicpill-firstname-input"
                                                                    placeholder="Enter Discount"
                                                                />
                                                                {/* {
                                                                    error && data.DISCOUNT.trim().length <= 0 ? <span className='text-danger ml-1'>This Field is Required</span>
                                                                        : ""} */}
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="row">
                                                        <div className="col-lg-4">
                                                            <div className="mb-3">
                                                                <label htmlFor="basicpill-firstname-input">
                                                                    Discount Applicable (Jobs)
                                                                </label>
                                                                <NumberFormat
                                                                    type="text"
                                                                    class="form-control"
                                                                    value={data.DISCOUNT_APPLICABLE}
                                                                    onChange={handleChange}
                                                                    name="DISCOUNT_APPLICABLE"
                                                                    id="basicpill-firstname-input"
                                                                    placeholder="Enter Discount Applicable"

                                                                />
                                                                {/* {
                                                                    error && data.DISCOUNT_APPLICABLE.trim().length <= 0 ? <span className='text-danger ml-1'>This Field is Required</span>
                                                                        : ""} */}
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-4">
                                                            <div className="mb-3">
                                                                <label htmlFor="basicpill-firstname-input">
                                                                    Sort Number<span style={{ color: "#FF0000" }}> * </span>
                                                                </label>
                                                                <NumberFormat
                                                                    type="text"
                                                                    className={error && data.SORT_NUMBER.length === 0 ? 'form-control is-invalid ' : 'form-control'}
                                                                    value={data.SORT_NUMBER}
                                                                    onChange={handleChange}
                                                                    name="SORT_NUMBER"
                                                                    id="basicpill-firstname-input"
                                                                    placeholder="Enter Sort Number"
                                                                />
                                                                {
                                                                    error && data.SORT_NUMBER.length <= 0 ? <span className='text-danger ml-1'>This Field is Required</span>
                                                                        : ""}
                                                            </div>
                                                        </div>


                                                        <div className="col-lg-4">
                                                            <div className="mb-3">
                                                                <label htmlFor="basicpill-firstname-input">
                                                                    Package Details <span style={{ color: "#FF0000" }}> * </span>
                                                                </label>
                                                                <textarea
                                                                    type="text"
                                                                    className={error && data.PACKAGE_DETAILS.trim().length === 0 ? 'form-control is-invalid ' : 'form-control'}
                                                                    onChange={handleChange}
                                                                    value={data.PACKAGE_DETAILS}
                                                                    name="PACKAGE_DETAILS"
                                                                    id="basicpill-firstname-input"
                                                                    placeholder="Enter Package Details"

                                                                />

                                                                {
                                                                    error && data.PACKAGE_DETAILS.trim().length <= 0 ? <span className='text-danger ml-1'>This Field is Required</span>
                                                                        : ""}
                                                            </div>
                                                        </div>

                                                    </div>


                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <button
                                                                    type="button"
                                                                    onClick={onSubmit}
                                                                    className="btn btn-info waves-effect btn-label waves-light"
                                                                >
                                                                    <i className="bx bx-check-double label-icon" />{" "}

                                                                    {detail ? 'Save Changes' : 'Create Subscriptions'}
                                                                </button>
                                                                &nbsp;&nbsp;
                                                                <button
                                                                    type="button"
                                                                    onClick={(e) => handleReset(e)}
                                                                    className="btn btn-danger waves-effect btn-label waves-light"
                                                                >
                                                                    <i className="bx bx-block label-icon " /> Cancel
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </section>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </div>



        </React.Fragment>

    )
}

export default AddSubscriptionPackage
