import React from 'react'
import Add from '../../../component/Components/PressRelease/AddPressRelease'
import { useNavigate, useParams } from 'react-router-dom'
import constant from '../../../constant'
import { useState, useEffect } from 'react'
import { updatePressRelease, PressReleaseById } from '../../../actions/components'

const EditPressRelease = () => {

    const [response, setResponse] = useState({})
    const [showMsg, setShowMsg] = useState(false)
    const navigate = useNavigate()
    const params = useParams();
    const { id } = useParams()



    const [data, setData] = useState(null)


    useEffect(() => {

        id && getDetailsById(id)
        document.title = constant.title.Update
    }, [])


    const getDetailsById = (id) => {
        PressReleaseById(id).then((res) => {
            if (res.status) {
                setData(res.result)
            } else {
                setResponse(res)
                setShowMsg(true)
                setTimeout(() => {
                    setShowMsg(false)
                }, 7000)
            }
        }).catch(err => {
            alert(err)
        })
    }

    const onSubmit = (model) => {
        updatePressRelease(model).then((res) => {
            if (res.status) {
                navigate(constant.component.pressReleaseList.url)
            } else {
                setResponse(res)
                setShowMsg(true)
                setTimeout(() => {
                    setShowMsg(false)
                }, 7000)
            }
        }).catch((err) => {
            alert(err)
        })
    }

    return (
        <React.Fragment>
            {data && <Add
                onSubmit={onSubmit}
                details={data}
                response={response}
                showMsg={showMsg}
                title="Update PressReleaseList" />}
        </React.Fragment>
    )
}

export default EditPressRelease
