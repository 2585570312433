import React from 'react'
import StateList from '../../../component/Utilities/CountryCityAndState/States'
import constant from '../../../constant'
import { useEffect, useState } from 'react'
import { getStateList } from '../../../actions/utilitiesAction'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

export default function States() {

    const [List, setList] = useState([])
    const [count, setCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [KEYWORDS, setKeyword] = useState('')
    const location = useLocation()

    useEffect(() => {
        const qParams = queryString.parse(location.search)
        getStateLists(qParams?.p ? qParams?.p : currentPage)
        document.title = constant.title.States
    }, [currentPage])

    const getStateLists = (page) => {

        getStateList(page).then((res) => {
            // console.log(res, 'ssfd');
            if (res.status) {
                setList(res.result.list)
                setCount(res.result.count.total)
            }
        }).catch((err) => {
            alert(err)
        })
    }

    return (
        <React.Fragment>
            <StateList
                List={List}
                setList={setList}
                getStateLists={(page) => { getStateLists(page) }}
                count={count}
                setPage={setCurrentPage}
                setKeyword={setKeyword}
            />
        </React.Fragment>
    )
}
