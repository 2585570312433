import React, { useEffect, useState } from 'react'
import { addForumCategory } from '../../../actions/AddForumAction'
import CreateForumCategory from '../../../component/Utilities/DiscussionForm/CreateForumCategory'
import constant from '../../../constant'

export default function AddForumCategory() {

    const [response, setResponse] = React.useState({})
    const [showMsg, setShowMsg] = React.useState(false)


    useEffect(() => {

        document.title = constant.title.AddDiscussionForum

    }, [])

    const onSubmit = (model) => {
        addForumCategory(model).then((res) => {
            setResponse(res)
            setShowMsg(true)
            setTimeout(() => {
                setShowMsg(false)
            }, 7000)
        }).catch((err) => {
            alert(err)
        })
    }


    return (
        <React.Fragment>
            <CreateForumCategory onSubmit={onSubmit} response={response} showMsg={showMsg} />
        </React.Fragment>
    )
}