import React from 'react'
import Add from '../../component/SubscriptionManagement/AddSubscriptionPackage'
import { updatePackage, packageById } from '../../actions/Subscription'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import constant from '../../constant'


const UpdateSubscriptionPackage = () => {

    const [response, setResponse] = useState({})
    const [showMsg, setShowMsg] = useState(false)
    const navigate = useNavigate()
    const params = useParams()
    const { id } = useParams()
    const [data, setData] = useState(null)


    useEffect(() => {
        id && getDetailsById(id)
        document.title = constant.title.UpdateSubscriptionPackage
    }, [])

    const getDetailsById = (id) => {
        packageById({ PACKAGE_ID: id }).then((res) => {
            if (res.status) {
                setData(res.result)
            }
            else {
                setResponse(res)
                setShowMsg(true)
                setTimeout(() => {
                    setShowMsg(false)
                }, 7000)
            }
        }).catch(err => {
            alert(err)
        })
    }

    const onSubmit = (model) => {
        updatePackage(model).then((res) => {
            if (res.status) {
                navigate(constant.component.SubscriptionList.url)
            } else {
                setResponse(res)
                setShowMsg(true)
                setTimeout(() => {
                    setShowMsg(false)
                }, 7000)
            }
        }).catch((err) => {
            alert(err)
        })
    }


    return (
        <React.Fragment>
            {data && <Add
                onSubmit={onSubmit}
                detail={data}
                response={response}
                showMsg={showMsg}
                title="Update Package"
            />}
        </React.Fragment>
    )
}

export default UpdateSubscriptionPackage
