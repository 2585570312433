import React, { useState } from 'react'
import Pagination from 'react-js-pagination'
import Loader from '../common/Loader'
import Swal from 'sweetalert2'
import { useLocation, useNavigate } from 'react-router-dom'
import queryString from 'query-string'

export default function EnquiryList(props) {
    const [currentPage, setCurrentPage] = useState(1)
    const location = useLocation()
    const navigate = useNavigate()

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber)
        navigate(location.pathname + '?p=' + pageNumber)
        props.setPage(pageNumber)
    }

    const deleteEnquiry = (ENQUIRY_ID) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to delete this Enquiry? ",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                props.deleteSalesEnquiryList(ENQUIRY_ID)
            }
        })

    }

    const qParams = queryString.parse(location.search)


    return (
        <React.Fragment>
            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0" style={{ fontSize: "18px" }}>Sales Enquiries List</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="#">Operations</a></li>
                                            <li className="breadcrumb-item active">Sales Enquiries List</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table align-middle table-nowrap table-hover">
                                                {
                                                    props.showLoader &&
                                                    <Loader />
                                                }
                                                {!props.showLoader && <thead className="table-light">
                                                    <tr>
                                                        <th scope="col" style={{ width: "70px" }}>#</th>
                                                        <th scope="col">User Information</th>
                                                        <th scope="col">Company Name/ Designation</th>
                                                        <th scope="col">Looking For</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>}
                                                <tbody>
                                                    {props.list && props.list.length > 0 &&
                                                        props.list.map((item, index, list) => {
                                                            return (
                                                                <tr>
                                                                    <td>
                                                                        <div className="avatar-xs">
                                                                            <span className="avatar-title rounded-circle">
                                                                                {item?.NAME?.charAt(0)?.toUpperCase()}
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <h5 className="mb-1" style={{ fontSize: "14px", textTransform: "capitalize" }}><a href="javascript:void(0);" className="text-dark">{item.NAME}</a></h5>
                                                                            <span><i class="fa fa-envelope" style={{color:"orange"}}></i> {item.EMAIL_ID}</span><br/>
                                                                            <i class="fa fa-mobile" style={{color:"orange"}}></i> <span>{item.MOBILE_NUMBER}</span>

                                                                    </td>
                                                                    <td> <span style={{color:"blue", fontSize:"16px"}}>{item.COMPANY_NAME}</span><br/>
                                                                     <span >{item.DESIGNATION_NAME}</span><br/>
                                                                     <i class="fa fa-building" style={{color:"orange"}}></i> <span>{item.CITY}</span>
                                                                    </td>

                                                                    <td>
                                                                        <span style={{ color: "#f46a6a" }}>{item.LOOKING_FOR}</span>
                                                                    </td>
                                                                    <td>
                                                                        <ul className="list-inline contact-links mb-0" style={{ fontSize: "20px" }}>
                                                                            <li className="list-inline-item px-2">
                                                                                <a href="javascript:void(0);" onClick={() => deleteEnquiry(item.ENQUIRY_ID)} style={{ color: "red" }} title="Delete Enquiry"><i className="bx bx-trash"></i></a>
                                                                            </li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        <>
                                                            {
                                                                props.list && props.list.length == 0 && props.showLoader == false &&
                                                                <td colSpan={10} className="text-center text-danger pt-3">No Record Found</td>
                                                            }
                                                        </>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <ul className="pagination pagination-rounded justify-content-center mt-4">
                                                    <Pagination
                                                        activePage={qParams?.p? +qParams?.p: currentPage}
                                                        itemsCountPerPage={10}
                                                        totalItemsCount={props.count}
                                                        pageRangeDisplayed={5}
                                                        onChange={handlePageChange}
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                    />
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
