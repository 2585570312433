import React from 'react'
import CategoryList from "../../../component/Utilities/Article/ArticleCategoryList"
import { useState, useEffect } from 'react'
import constant from '../../../constant'
import { getArticleCategory, archiveArticleCategory } from '../../../actions/utilitiesAction'

export default function ArticleCategoryList() {

    const [list, setList] = useState([])
    const [count, setCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [keyword, setKeyword] = useState('')
    const [showLoader, setShowLoader] = useState(false)

    useEffect(() => {
        getArticleCategorys(currentPage)
        document.title = constant.title.ArticleCategoryList
    }, [currentPage])

    const getArticleCategorys = (page) => {
        setShowLoader(true)
        getArticleCategory({ page: page, KEYWORD: keyword }).then((res) => {
            if (res.status) {
                setShowLoader(false)
                setList(res.result.list)
                setCount(res.result.count)
            }
            else {
                alert(res.error)
            }
        }).catch((err) => {
            alert(err)
        })
    }
    const updateStatus = (model) => {
        archiveArticleCategory(model).then((res) => {
            if (res.status) {
                getArticleCategorys(currentPage, "")
            } else {
                alert(res.error)
            }
        }).catch((err) => {
            alert(err)
        })
    }
    return (
        <React.Fragment>
            <CategoryList
                list={list}
                updateStatus={updateStatus}
                getArticleCategorys={(page => getArticleCategorys(page))}
                count={count}
                setPage={setCurrentPage}
                keyword={setKeyword}
                showLoader={showLoader}
            />
        </React.Fragment>
    )
}
