import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { archiveIndustry, industryList } from '../../actions/JobsAction';
import constant from '../../constant';
import moment from "moment";
import Pagination from "react-js-pagination";
import { Spinner } from 'react-bootstrap';

function IndustryLists(props) {
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    props.setPage(pageNumber)
  };

  const updateStatus = (INDUSTRY_ID, STATUS) => {
    const model = {
      INDUSTRY_ID: INDUSTRY_ID,
      STATUS: STATUS === "U" ? "A" : "U",
    };
    props.setPage(currentPage)
    props.updateStatus(model)
  };

  return (
    <React.Fragment>
      <div className="main-content">

        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0" style={{ fontSize: "18px" }}>Industry List</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item"><a href="javascript: void(0);">Jobs</a></li>
                      <li className="breadcrumb-item active">Industry List</li>
                    </ol>
                  </div>

                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <form className="row gy-12 gx-12 align-items-center">
                      <div className="col-sm-2">
                        <label className="visually-hidden" for="autoSizingInput">Name</label>
                        Search Industry
                      </div>
                      <div className="col-sm-6">
                        <label className="visually-hidden" for="autoSizingInputGroup">Username</label>
                        <div className="input-group">
                          <input type="text" className="form-control" id="autoSizingInputGroup" placeholder="Enter Employer ID, Name, Email ID, Phone etc" />
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <button type="button" className="btn btn-success waves-effect btn-label waves-light"><i className="bx bx-check-double label-icon"></i> Search</button>&nbsp; <button type="button" className="btn btn-outline-danger">Reset</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table align-middle table-nowrap table-hover">
                        <thead className="table-light">
                          <tr>
                            <th scope="col">S.NO.</th>
                            <th scope="col">Industry</th>
                            <th scope="col">Linked to Jobs</th>
                            <th scope="col">Added On</th>
                            <th scope="col">Status</th>
                            <th scope="col" style={{ width: "100px" }}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            props.industryLists && props.industryLists.length > 0
                              ? props.industryLists.map((data, index) => {
                                return (
                                  <tr>
                                    <td>{20 * currentPage + (index + 1) - 20}</td>

                                    <td>
                                      <p className="text-muted mb-0">
                                        {data.INDUSTRY}
                                      </p>
                                    </td>
                                    <td>1</td>
                                    <td>{moment(data.CREATED_ON).format("lll")}</td>

                                    <td>
                                      {
                                        data.STATUS == 'U' ?
                                          <button type="button" className="btn btn-success btn-sm waves-effect waves-light">ACTIVE</button> : <button type="button" className="btn btn-danger btn-sm waves-effect waves-light">ARCHIVE</button>
                                      }

                                    </td>
                                    <td>
                                      <ul className="list-inline font-size-20 contact-links mb-0" >
                                        <li className="list-inline-item px-2">
                                          <Link
                                            to={constant.component.editIndustry.url.replace(
                                              ":id",
                                              data.INDUSTRY_ID
                                            )}
                                          >
                                            <i className="bx bx-edit" title="Edit Industry " />
                                          </Link>                                                                               </li>
                                        <li className="list-inline-item px-2">
                                          <a href="javascript: void(0);" onClick={() => updateStatus(data.INDUSTRY_ID, data.STATUS)} style={{ color: 'red' }} title={data.STATUS == 'U' ? "Archive Record" : "Unarchive Record"}>
                                            {
                                              data.STATUS == 'U' ? <i className="bx bx-trash" ></i> : <i class="bx bx-log-in"></i>
                                            }
                                          </a>
                                        </li>
                                      </ul>
                                    </td>
                                  </tr>
                                );
                              })
                              : <React.Fragment>
                                <div style={{
                                  width: "80vw", height: "70vh", display: "flex", alignItems: "center",
                                  justifyContent: "center"
                                }}>
                                  <Spinner animation="border" variant="info"
                                    style={{ width: '3rem', height: '3rem' }} />

                                </div>
                              </React.Fragment>
                          }

                        </tbody>
                      </table>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="pagination pagination-rounded justify-content-center mt-4">
                          <Pagination
                            activePage={currentPage}
                            itemsCountPerPage={20}
                            totalItemsCount={props.count}
                            pageRangeDisplayed={5}
                            onChange={handlePageChange}
                            itemClass="page-item"
                            linkClass="page-link"
                          />
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default IndustryLists;