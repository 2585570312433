import React, { Component } from 'react'
import { employerKYC } from '../../actions/employer';
import Spinner from 'react-bootstrap/Spinner'
import Pagination from "react-js-pagination";
import Moment from 'moment';
import { employerKycUpdateStatus } from '../../actions/employer';
import { onChange, setOptions } from '../../utils';
import { onKeywordSearch } from '../../actions/employer';
import Loader from '../common/Loader';

export default class EpmloyerKYCDetail extends Component {

    constructor(props) {
        super()
        this.state = {
            kycData: undefined,
            currentPage: 1,
            kycUpdate: "kycUpdate",
            USERNAME: " ",
            CUSTOM_EMPLOYER_ID: " ",
            keyword: '',
            isLoading: true
        }
    }

    handlePageChange = (pageNumber) => {


        this.setState({ currentPage: pageNumber }, (res) => {
            employerKYC({ KEYWORD: '', page: this.state.currentPage }).then((res) => {
                if (res.status) {
                    this.setState({ kycData: res.result.list })
                } else {
                    alert(res.error);
                }

            }).catch(err => {
                alert(err)
            })

        })
    };

    componentDidMount() {

        const { currentPage } = this.state
        this.setState({isLoading: true})
        employerKYC({ KEYWORD: '', page: currentPage }).then((res) => {
            if (res.status) {

                let totalPages = Math.ceil(res.result.count.total / 10)
                this.setState({ totalPages })
                this.setState({isLoading: false})

                this.setState({ kycData: res.result.list })
            } else {
                alert(res.error);
            }
        }).catch(err => {
            alert(err)
        })


    }

    status = (status, EMPLOYER_ID) => {

        try {
            const model = {
                EMPLOYER_ID: EMPLOYER_ID,
                IS_KYC_VERIFIED: status
            }
            employerKycUpdateStatus(model).then((res) => {

                if (res.status) {
                    alert(res.result.message)
                }

            }).catch(() => {

            })
        } catch (err) {

        }

    }

    onSearch = (keyword, e) => {

        const { currentPage } = this.state
        e.preventDefault()
        employerKYC({ KEYWORD: keyword.trim(), page: currentPage }).then((res) => {

            if (res.status) {
                let totalPages = Math.ceil(res.result.count.total / 10)
                this.setState({ totalPages })

                this.setState({ kycData: res.result.list })
            }

        }).catch(() => {

        })

    }


    render() {
        const { currentPage, USERNAME, keyword } = this.state
        const updateStatus = this
        return (
            <React.Fragment>
                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">

                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0 " style={{ fontSize: "18px" }}>Employer eKYC Lists</h4>

                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item"><a href="javascript:void(0)">Employers</a></li>
                                                <li className="breadcrumb-item active">Employer eKYC Lists</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="row" >
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">

                                            <form className="row gy-12 gx-12 align-items-center" onSubmit={e => this.onSearch(keyword, e)}>
                                                <div className="col-sm-2">
                                                    <label className="visually-hidden" for="autoSizingInput">Name</label>
                                                    Search Employers
                                                </div>
                                                <div className="col-sm-6" >
                                                    <label className="visually-hidden" for="autoSizingInputGroup">Username</label>
                                                    <div className="input-group">
                                                        <input type="type" className="form-control" id="autoSizingInputGroup" onChange={e => this.setState({ keyword: e.target.value })}
                                                            value={keyword} placeholder="Enter Employer ID, Name, Email ID, Phone etc" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <button type="submit" className="btn btn-success waves-effect btn-label waves-light"   ><i className="bx bx-check-double label-icon"></i> Search</button>&nbsp; <button type="submit" className="btn btn-outline-danger" onClick={e => this.setState({ keyword: '' })}> Reset</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title mb-4">Employers</h4>
                                            <div className="table-responsive">
                                                <table className="table align-middle  table-hover ">
                                                    <thead className="table-light">
                                                        <tr >
                                                            <th scope="col" >ID</th>
                                                            <th scope="col">Employer Information</th>
                                                            <th scope="col">Address Details</th>
                                                            <th scope="col">KYC Details</th>
                                                            <th scope="col">Tax Information </th>
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                    </thead>

                                                    {this.state.isLoading ? <Loader /> : this.state.kycData != undefined && this.state.kycData.length > 0 ?
                                                        this.state.kycData.map(function (ele, index, arr) {

                                                            return (
                                                                <React.Fragment>
                                                                    <tbody>

                                                                        <tr style={{ whiteSpace: "inherit" }} >

                                                                            <td style={{ color: "#ff8000", fontSize: "14px", fontFamily: "nunito" }}>
                                                                                {ele.CUSTOM_EMPLOYER_ID}
                                                                            </td>

                                                                            <td  >
                                                                                <h5 className=" mb-1" style={{ fontSize: "14px", width: "200px" }}><a href="" className="text-dark">{ele.COMPANY_NAME}</a></h5>
                                                                                <p className="text-muted mb-0"> <span style={{ color: "#f26a0f " }}>{ele.INDUSTRY}</span></p>
                                                                                <p className="text-muted mb-0">  <i className="bx bx-user font-size-16 align-middle me-1"> <span style={{ color: "#0080ff ", fontFamily: " sans-serif", fontSize: "15px" }}> {ele.USERNAME.charAt(0).toUpperCase() + ele.USERNAME.slice(1).toLowerCase()}</span></i></p>
                                                                                <p className="text-muted mb-0"><i className="bx bxs-envelope" style={{ color: "#fae60f" }} />&nbsp;{ele.EMAIL}</p>
                                                                                <p className="text-muted mb-0"><i className="bx bxs-phone" style={{ color: "#299c0c" }} />&nbsp;{ele.MOBILE}</p>
                                                                                <p className="text-muted mb-0"><span style={{ color: "#f46a6a" }}>Member Since</span> | {Moment(ele.CREATED_ON).format('DD.MM.YYYY')} </p>
                                                                            </td>
                                                                            <td >

                                                                                <p className="text-muted mb-0" style={{ width: "120px" }}><i className="bx bx-street-view  font-size-16 align-middle me-1" style={{ color: "#0080ff " }} />{" "}{ele.ADDRESS ? ele.ADDRESS : 'Not Available'}</p>
                                                                            </td>
                                                                            <td >
                                                                                Address Proof Type : <span style={{ color: " #008040" }}>{ele.ADDRESS_PROOF_TYPE}</span><br />
                                                                                Address Proof File : {ele.UPLOAD_ADDRESS_PROOF == null ? <a className="text-dark">
                                                                                    <span style={{ color: "#801d16" }}>
                                                                                        Download File {" "}- Not Available
                                                                                    </span>
                                                                                </a> : <a target='_blank' href={`${process.env.REACT_APP_BASE_URL}/company/address-proof/${ele.UPLOAD_ADDRESS_PROOF}`} download ><span style={{ color: "#801d16" }}> Downlaod File{' '}  <i className="bx bxs-cloud-download text-info" style={{ fontSize: '20px' }}></i></span></a>}<br />
                                                                                PAN Card Status : <span style={{ color: " #8811ff" }}>{ele.PAN_CARD_STATUS}</span><br />
                                                                                Name on PAN : <span style={{ color: "#00b3b3" }}>{ele.NAME_ON_PAN ? ele.NAME_ON_PAN : 'NA'}</span><br />
                                                                                PAN Number : <span style={{ color: "#2d68aa" }}>{ele.PAN_NUMBER ? ele.PAN_NUMBER : 'NA'}</span><br />
                                                                                PAN File : {ele.UPLOAD_PAN == null ? <a className="text-dark">
                                                                                    <span style={{ color: "#801d16" }}>
                                                                                        Download File {" "}- Not Available
                                                                                    </span>
                                                                                </a> : <a target='_blank' href={`${process.env.REACT_APP_BASE_URL}/company/pan/${ele.UPLOAD_PAN}`} download>   <span style={{ color: "#801d16" }}> Downlaod File{' '}  <i className="bx bxs-cloud-download text-info" style={{ fontSize: '20px' }}></i></span> </a>}

                                                                            </td>
                                                                            <td >
                                                                                GST Status : <span style={{ color: "#008040" }}>{ele.GST == 'N' ?
                                                                                    <span >Registered</span> :
                                                                                    ele.GST == 'Y' ?
                                                                                        <span >Unregistered</span> : ''}
                                                                                </span><br />
                                                                                GSTIN Number : <span style={{ color: " #b35900" }}>{ele.GSTIN ? ele.GSTIN : 'NA'}</span><br />
                                                                                GST File : {ele.UPLOAD_GSTIN_CERTIFICATE == null ? <a className="text-dark">
                                                                                    <span style={{ color: "#801d16" }}>
                                                                                        Download File {" "}- Not Available
                                                                                    </span>
                                                                                </a> : <a target='_blank' href={`${process.env.REACT_APP_BASE_URL}/company/gst-certificate/${ele.UPLOAD_GSTIN_CERTIFICATE}`} download ><span style={{ color: "#801d16" }}> Downlaod File{' '}  <i className="bx bxs-cloud-download text-info" style={{ fontSize: '20px' }}></i></span></a>}<br />
                                                                                TAN Number : <span style={{ color: " #9f40ff" }}> {ele.TAN_NUMBER} </span><br /><br />

                                                                            </td>

                                                                            <td >

                                                                                {ele.IS_KYC_VERIFIED == 'N' ?
                                                                                    <span className="badge rounded-pill bg-warning" style={{ fontSize: "13px" }}>PENDING</span> :
                                                                                    ele.IS_KYC_VERIFIED == 'Y' ?
                                                                                        <span className="badge rounded-pill bg-success" style={{ fontSize: "13px" }}>VERIFIED</span> :
                                                                                        ele.IS_KYC_VERIFIED == 'R' ?
                                                                                            <span className="badge rounded-pill bg-danger" style={{ fontSize: "13px" }}>REJECTED</span> :
                                                                                            ''
                                                                                }<br /><br />
                                                                                <select style={{ padding: "3px" }} name="IS_KYC_VERIFIED"
                                                                                    onChange={(e) => updateStatus.status(e.target.value, ele.EMPLOYER_ID)}   >

                                                                                    <option value="N" selected={ele.IS_KYC_VERIFIED == 'N' ? true : false} style={{ backgroundColor: "orange", color: "white" }} >Pending</option>

                                                                                    <option value="Y" selected={ele.IS_KYC_VERIFIED == 'Y' ? true : false} style={{ backgroundColor: "Purple", color: "white" }}>Verify</option>

                                                                                    <option value="R" selected={ele.IS_KYC_VERIFIED == 'R' ? true : false} style={{ backgroundColor: "#00add2", color: "white" }}>Rejected</option>

                                                                                </select>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>

                                                                </React.Fragment>
                                                            )

                                                        }) :

                                                    

                                                        this.state.isLoading && this.state.kycData.length == 0 && < td colSpan={10} className="text-center text-danger">No Employer Record Found</td>

                                                    }


                                                </table>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-12">

                                                    {<div className="pagination pagination-rounded justify-content-center mt-4">

                                                        <Pagination
                                                            activePage={currentPage}
                                                            itemsCountPerPage={10}
                                                            totalItemsCount={this.state.totalPages}
                                                            pageRangeDisplayed={5}
                                                            onChange={this.handlePageChange}
                                                            itemClass="page-item"
                                                            linkClass="page-link"
                                                        />

                                                    </div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }






}

