import React, { useState } from 'react'
import constant from '../../../constant'
import moment from 'moment'
import { Link, useParams } from 'react-router-dom'
import Pagination from 'react-js-pagination'
import Loader from '../../common/Loader'

const AdminRoleList = (props) => {

    const [currentPage, setCurrentPage] = useState(1);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        props.setPage(pageNumber)
    };
    const updateStatus = (ROLE_ID, STATUS) => {
        const model = {
            ROLE_ID: ROLE_ID,
            STATUS: STATUS === 'A' ? 'I' : 'A'
        }
        props.setPage(currentPage)
        props.updateStatus(model)
    }

    return (
        <React.Fragment>
            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">


                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0" style={{ fontSize: '18px' }}>Role List</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="">Role List</a></li>
                                            <li className="breadcrumb-item active">Role List</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table align-middle table-nowrap table-hover">
                                                {
                                                    props.showLoader &&
                                                    <Loader />
                                                }
                                                {!props.showLoader && <thead className="table-light">
                                                    <tr>
                                                        <th scope="col">S.No.</th>
                                                        <th scope="col">Role</th>
                                                        <th scope="col">Other Details</th>
                                                        <th scope="col">Added On</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>}
                                                <tbody>
                                                    {props.list && props.list.length > 0 &&
                                                        props.list.map((ele, index, list) => {
                                                            return (
                                                                <>
                                                                    <tr>
                                                                        <td>{currentPage == 1 ? `${index + 1}` : `${index == 9 ? currentPage : currentPage - 1}` + `${index == 9 ? 0 : index + 1}`}</td>
                                                                        <td>
                                                                            <p className="text-muted mb-0">{ele.ROLE_NAME}</p>
                                                                        </td>
                                                                        <td>{ele.OTHER_DETAILS} </td>
                                                                        <td>
                                                                            {moment(ele.CREATED_ON).format('DD.MM.YYYY on hh:mma')}
                                                                        </td>

                                                                        <td>{
                                                                            ele.STATUS == 'A' ?
                                                                                <button type="button" className="btn btn-success btn-sm waves-effect waves-light">ACTIVE</button> : <button type="button" className="btn btn-danger btn-sm waves-effect waves-light">ARCHIVE</button>
                                                                        }
                                                                        </td>
                                                                        <td>
                                                                            <ul className="list-inline font-size-20 contact-links mb-0" >
                                                                                <li className="list-inline-item px-2">
                                                                                    <Link to={constant.component.editadminrolelist.url.replace(':id', ele.ROLE_ID)} title="Edit Role"><i className="bx bx-edit"></i></Link>                                                                                </li>
                                                                                <li className="list-inline-item px-2">
                                                                                    <a href="javascript: void(0)" onClick={() => updateStatus(ele.ROLE_ID, ele.STATUS)} style={{ color: 'red' }} title={ele.STATUS == 'U' ? "Archive Record" : "Unarchive Record"}>
                                                                                        {
                                                                                            ele.STATUS == 'A' ? <i className="bx bx-trash"></i> : <i class="bx bx-log-in"></i>
                                                                                        }


                                                                                    </a>
                                                                                </li>
                                                                            </ul>
                                                                        </td>

                                                                    </tr>
                                                                </>
                                                            )

                                                        })
                                                    }
                                                    {
                                                        <>
                                                            {
                                                                props.list && props.list.length == 0 && props.showLoader == false &&
                                                                <td colSpan={10} className="text-center text-danger pt-3">No Record Found</td>
                                                            }
                                                        </>
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="pagination pagination-rounded justify-content-center mt-4">
                                                    <Pagination
                                                        activePage={currentPage}
                                                        itemsCountPerPage={10}
                                                        totalItemsCount={props.count}
                                                        pageRangeDisplayed={5}
                                                        onChange={handlePageChange}
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                    />
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default AdminRoleList;