import React,{useState,useEffect} from 'react'
import EmpFeaturedJobs from '../../component/Employers/EmpFeaturedJobs'
import { empfeaturedJobList } from '../../actions/JobsAction';
import { listFeaturedEmployer } from '../../actions/employer';
import { useParams } from 'react-router-dom';
import constant from '../../constant';

export default function EmpFeaturedJob() {

    const [currentPage, setCurrentPage] = useState(1);
    const [list, setList] = useState([]);
    const [Profile, setProfile] = useState([])
    const [showLoader, setShowLoader] = useState(false)
    const params = useParams()


    useEffect(() => {
        featurejobs(currentPage)
        document.title = constant.title.employerfeaturedjobs
    }, [currentPage])


    const featurejobs = (page) => {
        setShowLoader(true)
        empfeaturedJobList({EMPLOYER_ID: parseInt( params.id) ,page:page})
            .then((res) => {
                if (res.status) {
                    setList(res.result.featuredJobList);
                    // setCount(res.result.count.total);
                    setShowLoader(false)
                }
            })
            .catch((err) => {
                alert(err);
            });

            // listFeaturedEmployer({EMPLOYER_ID : parseInt( params.id) }).then(res => {
            //     if (res.status) {
            //         setProfile(res.result.list)
            //     }
            //     else {
            //         alert(res.error)
            //     }
            // }).catch(err => {
            //     alert(err)
            // })
    };
  return (
    <React.Fragment>
      <EmpFeaturedJobs
        list={list}
                premiumjob={(page) => featurejobs(page)}
                // Profile={Profile}
                setPage={(page) => setCurrentPage(page)}
                setShowLoader={setShowLoader}
                showLoader={showLoader}
      />
    </React.Fragment>
  )
}

