import React, { useEffect, useState } from 'react'
import { listAddressProofType, archiveAddressProofType } from '../../actions/employer';
import AddressProofTypelist from '../../component/Employers/AddressProofTypeList'
import constant from '../../constant';

export default function AddressProofTypeList() {

    const [list, setList] = useState([]);
    const [showLoader, setShowLoader] = useState(true)
    const [count, setCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)

    useEffect(() => {
        document.title = constant.title.AddressProofTypeList
        getAddressProofTypeList(currentPage)

    }, [currentPage])

    const getAddressProofTypeList = (page) => {
        listAddressProofType({ page: page }).then((res) => {
            if (res.status) {
                setList(res.result.list)
                setCount(res.result.count.total)
                setShowLoader(false)
            }
            else {
                alert(res.error)
            }
        }).catch((err) => {
            alert(err)
        })
    }

    const updateStatus = (model) => {
        archiveAddressProofType(model).then((res) => {
            if (res.status) {
                getAddressProofTypeList(currentPage)
            }
            else {
                alert(res.error)
            }
        })
    }
    return (
        <React.Fragment>
            <AddressProofTypelist
                getAddressProofTypeList={getAddressProofTypeList}
                count={count}
                updateStatus={updateStatus}
                setShowLoader={setShowLoader}
                showLoader={showLoader}
                list={list}
                setPage={setCurrentPage}
            />

        </React.Fragment>
    )
}
