import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { EmployerIndutryById, updateEmployerIndustry } from '../../actions/employer'
import Addindustry from '../../component/Employers/AddIndustry'
import constant from '../../constant'
export default function AddIndustry() {

    const [response, setResponse] = React.useState({})
    const [showMsg, setShowMsg] = React.useState(false)

    const [data, setData] = useState(null)
    const params = useParams()

    const navigation = useNavigate();


    useEffect(() => {
        document.title = constant.title.EditIndustry

        EmployerIndutryById(params.id).then(res => {
            if (res.status) {
                setData(res.result)
            }
            else {
                alert(res.error)
            }
        }).catch(err => {
            alert(err)
        })

    }, [])
    const onSubmit = (model) => {
        updateEmployerIndustry(model).then((res) => {
            if (res.status) {
                navigation(constant.component.industrylist.url)
            }
            else {
                setResponse(res)
                setShowMsg(true)
                setTimeout(() => {
                    setShowMsg(false)
                }, 7000)
            }
        }).catch((err) => [
            alert(err)
        ])
    }
    return (
        <React.Fragment>
            {
                data && <Addindustry onSubmit={onSubmit} detail={data} response={response} showMsg={showMsg} />
            }
        </React.Fragment>
    )
}
