import * as axios from "axios";
import { getAuthHeader } from "./../utils";

export const uploadPicture = (file) => {
    const url = `${process.env.REACT_APP_BASE_URL}/upload-admin-profile-pic`;
    return axios.post(url, file, getAuthHeader()).then((res) => {
        return res.data
    })
};
export const getProfileInfromation = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/profile-information`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};
export const updateProfileInfromation = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/update-profile-information`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}

/****Add by Jitender*** */
export const updateCandidateStatus = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/update-candidate-status`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};
/****End*** */

export const totalJobAlertCount = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/total-job-alert`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}
export const appliedJobCount = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/total-job-applied-count`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const totalEnquiryCount = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/total-enquiry-count`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}

// export const totalFutureHiringCount = () => {
//     const url = `${process.env.REACT_APP_BASE_URL}/future-hiring-count`;
//     return axios.get(url, getAuthHeader()).then((res) => {
//         return res.data;
//     })
// }

export const totalJob = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/total-job`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}

/***Add by Jitender*** */
export const totalCollege = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/total-college-count`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}

/****End*** */

export const totalPostJob = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/total-post-job`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}


export const totalFAQ = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/total-faq`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const totalEmployer = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/total-employer`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}


export const totalBlog = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/total-blog`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const totalCandidate = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/cp-total-candidate`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}



export const totalCandidatethisMonth = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/cp-total-candidates-jobs-month`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}
export const getCandidateandjobemployerCount = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/job-employer-candidate-monthly-stats`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}

// export const totalcandidatesList = (model) => {
//     const url = `${process.env.REACT_APP_BASE_URL}/candidate-list`;
//     return axios.post(url, model, getAuthHeader()).then((res) => {
//         return res.data;
//     })
// }

/***Chnage By Jitedner** */
export const totalcandidatesList = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/candidate-list`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const totalcandidatesListOld = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/candidate-list-old`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const KeywordSearch = (KEYWORD) => {
    const url = `${process.env.REACT_APP_BASE_URL}/keyword-search?KEYWORD=${KEYWORD}`;
    return axios.get(url).then((res) => {
        return res.data;
    })
};
/***End** */


/***Add By Jitender*** */
export const Getonlineprofile = (Model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/admin/candidate-online-profile?CANDIDATE_ID=${Model.CANDIDATE_ID}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        if (res.status) {
            return res.data;
        }
    })
};

export const GetProfileInformation = (Model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/admin/getCandidateData?CANDIDATE_ID=${Model.CANDIDATE_ID}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        if (res.status) {
            return res.data;
        }
    })
};

export const Getemployement = (Model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/admin/candidate-employement?CANDIDATE_ID=${Model.CANDIDATE_ID}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        if (res.status) {
            return res.data;
        }
    })
};

export const GetPersonalDetail = (Model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/admin/candidate-personal-profile?CANDIDATE_ID=${Model.CANDIDATE_ID}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const GetCareerProfile = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/admin/candidate-career-profile?CANDIDATE_ID=${model.CANDIDATE_ID}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const JobApplyStatus = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/admin/candidate-jobs-applied?CANDIDATE_ID=${model.CANDIDATE_ID}&pageNumber=${model.pageNumber}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const GetRejectedJob = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/admin/candidate-jobs-rejected?CANDIDATE_ID=${model.CANDIDATE_ID}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const GetCertifications = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/admin/candidate-certifications?CANDIDATE_ID=${model.CANDIDATE_ID}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const GetITSkillsList = (Model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/admin/candidate-it-skills?CANDIDATE_ID=${Model.CANDIDATE_ID}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        if (res.status) {
            return res.data;
        }
    })
};


export const getITSkills = (Model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/admin/candidate-skills?CANDIDATE_ID=${Model.CANDIDATE_ID}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        if (res.status) {
            return res.data;
        }
    })
};

export const GetEducation = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/admin/candidate-education?CANDIDATE_ID=${model.CANDIDATE_ID}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        if (res.status) {
            return res.data;
        }
    })
};


export const educationType = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/jzsh/options/education`;
    return axios.get(url, getAuthHeader()).then((res) => {
        if (res.status) {
            return res.data;
        }
    })
};


export const GetWorkSample = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/admin/candidate-work-sample?CANDIDATE_ID=${model.CANDIDATE_ID}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        if (res.status) {
            return res.data;
        }
    })
};

export const getProfilePic = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/GetProfilePic`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}
export const GetProject = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/admin/candidate-projects?CANDIDATE_ID=${model.CANDIDATE_ID}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        if (res.status) {
            return res.data;
        }
    })
};

export const getProfileSummary = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/admin/candidate-profile-summary?CANDIDATE_ID=${model.CANDIDATE_ID}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        if (res.status) {
            return res.data;
        }
    })
};

// export const GetResume = (model) => {
//     const url = `${process.env.REACT_APP_BASE_URL}/GetResume`;
//     return axios.post(url, model, getAuthHeader()).then((res) => {
//         return res.data;
//     })
// };

export const GetResume = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/admin/GetResume?CANDIDATE_ID=${model.CANDIDATE_ID}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        if (res.status) {
            return res.data;
        }
    })
};


export const getResumeHeadLine = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/admin/candidate-resume-headline?CANDIDATE_ID=${model.CANDIDATE_ID}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        if (res.status) {
            return res.data;
        }
    })
};

export const GetITSkillslist = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/admin/candidate-it-skills?CANDIDATE_ID=${model.CANDIDATE_ID}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        if (res.status) {
            return res.data;
        }
    })
};

export const RecommendedJobs = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/recommended-jobs`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};
export const listEducationCandidate = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/ListEducation`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};
export const getOnlineProfileList = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/GetOnlineProfileList`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};
/****End**** */
export const archieveCandidateProfile = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/archive-candidate`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};


export const totalStatistics = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/cp-total-statistics`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}
export const totalCollegeStatics = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/college-statics`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}
export const employerDetailsStatics = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/employer-details-statics`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}


export const totalCollegeCount = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/total-college-count`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}
export const entityType = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/all-entity-type`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}
export const accrediationList = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/all-accrediation`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}
export const affiliationList = (Status) => {
    const url = `${process.env.REACT_APP_BASE_URL}/all-affiliation?status=${Status}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}
export const updateEntityDetail = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/update-entity-details`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const addEntityDetail = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/add-entity-details`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const placementDetail = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/get-Company-List`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}
export const removeaccrediation = (data) => {
    const url = `${process.env.REACT_APP_BASE_URL}/remove-accrediation?id=${data.id}&value=${data.value}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}
export const removeaffilation = (data) => {
    const url = `${process.env.REACT_APP_BASE_URL}/remove-affilation?id=${data.id}&value=${data.value}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}
export const facilityList = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/get-facility-list`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}
export const cityList = (STATE_ID) => {
    const url = `${process.env.REACT_APP_BASE_URL}/college-city-list?STATE_ID=${STATE_ID}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}
export const uploadCollegeLogo = (file) => {
    const url = `${process.env.REACT_APP_BASE_URL}/upload-college-logo-pic`;
    return axios.post(url, file, getAuthHeader()).then((res) => {
        return res.data
    })
};
export const uploadCompanyCoupon = (file) => {
    const url = `${process.env.REACT_APP_BASE_URL}/upload-company-coupon`;
    return axios.post(url, file, getAuthHeader()).then((res) => {
        return res.data
    })
};
export const uploadGovtLogo = (file) => {
    const url = `${process.env.REACT_APP_BASE_URL}/upload-govt-logo`;
    return axios.post(url, file, getAuthHeader()).then((res) => {
        return res.data
    })
};
export const uploadCollegePrimaryImage = (file) => {
    const url = `${process.env.REACT_APP_BASE_URL}/upload-college-primary-pic`;
    return axios.post(url, file, getAuthHeader()).then((res) => {
        return res.data
    })
};
export const collegecountryList = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/college-country-list`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}
export const collegestateList = (COUNTRY_ID) => {
    const url = `${process.env.REACT_APP_BASE_URL}/college-state-list?COUNTRY_ID=${COUNTRY_ID}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}
export const updateCollegeStatus = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/update-college-status`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};
export const EntityType = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/get-entity-type-list`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}
export const CourseList = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/cro-get-course-list`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}
export const CourseFee = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/cro-get-fee-list`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}
export const courseAssign = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/cro-post-courseAssign-list`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}
export const courseAssignList = (COLLEGE_ID) => {
    const url = `${process.env.REACT_APP_BASE_URL}/cro-get-courseAssign-list?COLLEGE_ID=${COLLEGE_ID}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}
export const updateCourseStatus = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/carrier-update-course-status`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};
export const courseLists = (CourseId) => {
    const url = `${process.env.REACT_APP_BASE_URL}/cro-get-courseAssignModal-list?CourseId=${CourseId}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}
export const collegeheading = (id) => {
    const url = `${process.env.REACT_APP_BASE_URL}/cro-get-collegetitle?Id=${id}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}
export const facilities = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/get-all-facilitiesList`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}
export const uploadfacilityImages = (body) => {
    const url = `${process.env.REACT_APP_BASE_URL}/post-facilities-images`;
    return axios.post(url, body, getAuthHeader()).then((res) => {
        return res.data;
    })
}
export const facilitiesImages = (COLLEGE_ID) => {
    const url = `${process.env.REACT_APP_BASE_URL}/get-images-by-facility?COLLEGE_ID=${COLLEGE_ID}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}
export const Imagedelete = (COLLEGE_IMAGE_ID) => {
    const url = `${process.env.REACT_APP_BASE_URL}/delete-college-image?COLLEGE_IMAGE_ID=${COLLEGE_IMAGE_ID}`;
    return axios.delete(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}
export const entityList = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/get-entityType-list`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}
export const totalCollegeCounttoday = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/total-updated-college-count`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const totalReviewCount = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/total-review`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}


