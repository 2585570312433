import React from 'react'
import { useState } from 'react';
import Pagination from 'react-js-pagination';
import { Spinner } from 'reactstrap';
import Swal from 'sweetalert2';
export default function JobAlert(props) {

    const [currentPage, setCurrentPage] = useState(1)
    const [employerFullDetails, setEmployerFullDetails] = useState({})


    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        props.setPage(pageNumber)
    };

    const deleteAlert = (ALERT_ID) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to delete this Answer? ",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                props.deleteAlertId(ALERT_ID)
            }
        })

    }
    return (
        <React.Fragment>
            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">

                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 font-size-18">Job Alert List</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="">Candidates</a></li>
                                            <li className="breadcrumb-item active">Job ALert List</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">


                                        <form className="row gy-12 gx-12 align-items-center">
                                            <div className="col-sm-2">
                                                <label className="visually-hidden" for="autoSizingInput">Name</label>
                                                Search Candiates
                                            </div>
                                            <div className="col-sm-6">
                                                <label className="visually-hidden" for="autoSizingInputGroup">Username</label>
                                                <div className="input-group">
                                                    <input type="text" className="form-control" id="autoSizingInputGroup" placeholder="Enter Candidate ID, Name, Email ID, Phone etc" />
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <button type="button" className="btn btn-success waves-effect btn-label waves-light"><i className="bx bx-check-double label-icon"></i> Search</button>&nbsp; <button type="button" className="btn btn-outline-danger">Reset</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">Job Alert</h4>
                                        <div className="table-responsive" style={{ display: "contents" }}>
                                            <table className="table align-middle table-nowrap table-hover">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col">Candidate Information</th>
                                                        <th scope="col">Job Alert Details</th>
                                                        <th scope="col">Other Information </th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>


                                                    {props?.alertList?.map((item, index) => {
                                                        return (
                                                            <tr>
                                                                <td>
                                                                    <h5 className="font-size-14 mb-1"><a href="" className="text-dark">{item.NAME}</a></h5>
                                                                    <p className="text-muted mb-0">{item.EMAIL_ID}</p>
                                                                    <p className="text-muted mb-0">{item.MOBILE}</p>

                                                                </td>
                                                                <td style={{ verticalAlign: "top" }}>
                                                                    <table cellspacing="2px" cellpadding="2px">

                                                                        {item.PREFFERED_DEPARTMENT !== "undefined" && <tr>
                                                                            <td>&bull; <a href="javascript:void(0);" className="text-dark"><span style={{ color: "#004e9b" }}>Department</span>: {item.PREFFERED_DEPARTMENT}</a></td>
                                                                            <td>&nbsp;</td>
                                                                            <td></td>
                                                                        </tr>}
                                                                        {item.KEYWORDS !== "undefined" && <tr>
                                                                            <td>&bull; <a href="javascript:void(0);" className="text-dark"><span style={{ color: "#004e9b" }}>Key Skills</span> : {item.KEYWORDS}</a></td>
                                                                            <td>&nbsp;</td>
                                                                        </tr>}
                                                                        {item.TOTAL_EXPERIENCE !== "undefined" && <tr>
                                                                            <td>&bull; <a href="javascript:void(0);" className="text-dark"><span style={{ color: "#004e9b" }}>Experience</span> : {item.TOTAL_EXPERIENCE}</a></td>
                                                                            <td>&nbsp;</td>
                                                                        </tr>}
                                                                        {item.PREFFERED_LOCATION !== "undefined" && <tr>
                                                                            <td>&bull; <a href="javascript:void(0);" className="text-dark"><span style={{ color: "#004e9b" }}>Location</span> : {item.PREFFERED_LOCATION ? item.PREFFERED_LOCATION : ""}</a></td>
                                                                            <td>&nbsp;</td>
                                                                        </tr>}
                                                                        {item.PREFFERED_INDUSTRY !== "undefined" && <tr>
                                                                            <td>&bull; <a href="javascript:void(0);" className="text-dark"><span style={{ color: "#004e9b" }}>Industry</span> : {item.PREFFERED_INDUSTRY}</a></td>
                                                                            <td>&nbsp;</td>
                                                                        </tr>
                                                                        }
                                                                        {item.KEYWORDS !== "undefined" && <tr>

                                                                            <td>&bull; <a href="javascript:void(0);" className="text-dark"><span style={{ color: "#004e9b" }}>Search Term</span> : {item.KEYWORDS ? item.KEYWORDS + "," : ""}{item.LOCATION !== "undefined" && item.LOCATION + ","}{item.MIN_EXPERIENCE !== "undefined" ? item.MIN_EXPERIENCE + "-" : ""}{item.MAX_EXPERIENCE !== "undefined" ? item.MAX_EXPERIENCE : ""} </a></td>
                                                                            <td>&nbsp;</td>
                                                                        </tr>}


                                                                    </table>

                                                                </td>

                                                                <td>
                                                                    <ul className="list-inline font-size-20 contact-links mb-0">
                                                                        <button type="button" data-bs-toggle="modal" data-bs-target={"#staticBackdrop" + item.ALERT_ID}
                                                                            className="btn btn-info waves-effect btn-label waves-light"

                                                                        >
                                                                            <i className="bx bx-street-view label-icon "></i> View Details</button>


                                                                    </ul>

                                                                    <div className="modal fade" id={"staticBackdrop" + item.ALERT_ID} data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                                                                            <div className="modal-content">
                                                                                <div className="modal-header">
                                                                                    <h5 className="modal-title" id="staticBackdropLabel">Candidate Details</h5>
                                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                </div>
                                                                                <div className="modal-body">
                                                                                    <p>
                                                                                        <table className="table table-striped mb-0">
                                                                                            <tbody>

                                                                                                <tr>
                                                                                                    <td>Name</td>
                                                                                                    <td>{item.NAME}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>Skills</td>
                                                                                                    <td>{item.KEYWORDS}</td>
                                                                                                </tr>

                                                                                                <tr>
                                                                                                    <td>Email</td>
                                                                                                    <td>{item.EMAIL_ID}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>Phone No.</td>
                                                                                                    <td>{item.MOBILE}</td>
                                                                                                </tr>
                                                                                                {item.PREFFERED_DEPARTMENT !== "undefined" && <tr>
                                                                                                    <td>Department</td>
                                                                                                    {<td>{item.PREFFERED_DEPARTMENT}</td>
                                                                                                    }                                                                                                </tr>}
                                                                                                {item.PREFFERED_LOCATION !== "undefined" && <tr>
                                                                                                    <td>Location</td>
                                                                                                    <td>{item.PREFFERED_LOCATION}</td>
                                                                                                </tr>}
                                                                                                {item.TOTAL_EXPERIENCE !== "undefined" && <tr>
                                                                                                    <td>Experience</td>
                                                                                                    <td>{item.TOTAL_EXPERIENCE}</td>
                                                                                                </tr>}
                                                                                                {item.PREFFERED_INDUSTRY !== "undefined" && <tr>
                                                                                                    <td>Industry</td>
                                                                                                    <td>{item.PREFFERED_INDUSTRY}</td>
                                                                                                </tr>}

                                                                                            </tbody>
                                                                                        </table>
                                                                                    </p>
                                                                                </div>
                                                                                <div className="modal-footer">
                                                                                    <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <ul className="list-inline font-size-20 contact-links mb-0">

                                                                        <li className="list-inline-item px-2">
                                                                            <a href="javascript: void(0);" onClick={() => deleteAlert(item.ALERT_ID)} style={{ color: "red" }} title="Delete User"><i className="bx bx-trash"></i></a>
                                                                        </li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    }

                                                    {props?.alertList == undefined && <React.Fragment>
                                                        < td colSpan={10} className="text-center text-danger pt-3">  <div style={{
                                                            width: "80vw", height: "70vh", display: "flex", alignItems: "center",
                                                            justifyContent: "center"
                                                        }}>
                                                            <Spinner animation="border" variant="info"
                                                                style={{ width: '3rem', height: '3rem' }} />

                                                        </div></td>
                                                    </React.Fragment>
                                                    }
                                                    {props?.alertList?.length == 0 && < td colSpan={10} className="text-center text-danger pt-3">No Job Alert Record Found</td>
                                                    }

                                                </tbody>
                                            </table>

                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <ul className="pagination pagination-rounded justify-content-center mt-4">
                                                        <Pagination
                                                            activePage={currentPage}
                                                            itemsCountPerPage={10}
                                                            totalItemsCount={props.count}
                                                            pageRangeDisplayed={5}
                                                            onChange={handlePageChange}
                                                            itemClass="page-item"
                                                            linkClass="page-link"
                                                        />
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="right-bar">
                <div data-simplebar className="h-100">
                    <div className="rightbar-title d-flex align-items-center px-3 py-4">

                        <h5 className="m-0 me-2">Settings</h5>

                        <a href="javascript:void(0);" className="right-bar-toggle ms-auto">
                            <i className="mdi mdi-close noti-icon"></i>
                        </a>
                    </div>

                    <hr className="mt-0" />
                    <h6 className="text-center mb-0">Choose Layouts</h6>

                    <div className="p-4">
                        <div className="mb-2">
                            <img src="assets/images/layouts/layout-1.jpg" className="img-thumbnail" alt="layout images" />
                        </div>

                        <div className="form-check form-switch mb-3">
                            <input className="form-check-input theme-choice" type="checkbox" id="light-mode-switch" checked />
                            <label className="form-check-label" for="light-mode-switch">Light Mode</label>
                        </div>

                        <div className="mb-2">
                            <img src="assets/images/layouts/layout-2.jpg" className="img-thumbnail" alt="layout images" />
                        </div>
                        <div className="form-check form-switch mb-3">
                            <input className="form-check-input theme-choice" type="checkbox" id="dark-mode-switch" />
                            <label className="form-check-label" for="dark-mode-switch">Dark Mode</label>
                        </div>

                        <div className="mb-2">
                            <img src="assets/images/layouts/layout-3.jpg" className="img-thumbnail" alt="layout images" />
                        </div>
                        <div className="form-check form-switch mb-3">
                            <input className="form-check-input theme-choice" type="checkbox" id="rtl-mode-switch" />
                            <label className="form-check-label" for="rtl-mode-switch">RTL Mode</label>
                        </div>

                        <div className="mb-2">
                            <img src="assets/images/layouts/layout-4.jpg" className="img-thumbnail" alt="layout images" />
                        </div>
                        <div className="form-check form-switch mb-5">
                            <input className="form-check-input theme-choice" type="checkbox" id="dark-rtl-mode-switch" />
                            <label className="form-check-label" for="dark-rtl-mode-switch">Dark RTL Mode</label>
                        </div>


                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}
