import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import constant from '../../../constant'
import { ToSeoUrl } from '../../../utils'
import { getStateLists, cityList } from '../../../actions/utilitiesAction'
import NumberFormat from 'react-number-format'
import SuccessMessage from '../../common/SuccessMessage'
import ErrorMessage from '../../common/ErrorMessage'

const AddLocalities = (props) => {

    const [error, setError] = useState(false)
    const [state, setState] = useState([])
    const [city, setCity] = useState([])
    const { id } = useParams()
    const Navigate = useNavigate()

    const [locality, setLocality] = useState({
        STATE_ID: "",
        COUNTRY_ID: "",
        CITY_ID: "",
        LOCALITY: "",
        URL: "",
        SORT_NUMBER: ""
    })


    const handleChange = (e) => {

        const { name, value } = e.target

        if (name === 'LOCALITY') {
            let url = ToSeoUrl(value);
            setLocality({
                ...locality,
                [name]: value,
                'URL': url
            })
        }
        else if (name === 'COUNTRY_ID') {

            getStateLists(value).then((res) => {
                if (res.status) {
                    setState(res.result.list)
                    setLocality({
                        ...locality,
                        [name]: value
                    })
                }
            }).catch((err) => {
                alert(err)
            })
        }


        else if (name === 'STATE_ID') {

            cityList(value).then((res) => {
                if (res.status) {
                    setCity(res.result.list)
                    setLocality({
                        ...locality,
                        [name]: value
                    })
                }
            }).catch((err) => {
                alert(err)
            })

        }
        else {
            setLocality({
                ...locality,
                [name]: value
            })
        }


    }

    const handleReset = (e) => {
        setError(false)
        e.preventDefault();
        id && Navigate(constant.component.addLocality.url)
        setLocality(PrevState => ({
            ...PrevState,
            STATE_ID: "",
            COUNTRY_ID: "",
            CITY_ID: "",
            LOCALITY: "",
            URL: "",
            SORT_NUMBER: ""

        }))

    }


    const onSubmit = (e) => {
        e.preventDefault();
        setLocality(PrevState => ({
            ...PrevState,
            STATE_ID: "",
            COUNTRY_ID: "",
            CITY_ID: "",
            LOCALITY: "",
            URL: "",
            SORT_NUMBER: ""

        }))

        if (locality.COUNTRY_ID.toString().trim().length == 0 ||
            locality.STATE_ID.toString().trim().length == 0 ||
            locality.CITY_ID.toString().trim().length == 0 ||
            locality.LOCALITY.trim().length == 0 ||
            locality.URL.trim().length == 0 ||
            locality.SORT_NUMBER.toString().trim().length == 0) {
            setError(true)

        } else {
            const model = {
                COUNTRY_ID: locality.COUNTRY_ID,
                STATE_ID: locality.STATE_ID,
                CITY_ID: locality.CITY_ID,
                LOCALITY: locality.LOCALITY,
                URL: locality.URL,
                SORT_NUMBER: locality.SORT_NUMBER
            }

            props.onSubmit(model)
            setError(false)
            // console.log(model, "hhhhhhhhhhhhhhh")


        }

    }


    return (
        <React.Fragment>

            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 " style={{ fontSize: '18px' }}> Add Locality</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="javascript: void(0);">Utilities</a></li>
                                            <li className="breadcrumb-item active"> Add Locality</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">Locality Information</h4>
                                        {
                                            props.showMsg && (props.response.status ?
                                                <SuccessMessage message={props.response.messageCode} />
                                                :
                                                <ErrorMessage message={props.response.error} />)
                                        }

                                        <div id="basic-example">
                                            <section>
                                                <form>
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label for="basicpill-firstname-input">Select Country <span style={{ color: "#FF0000" }}>*</span></label>
                                                                <select

                                                                    className='form-control'
                                                                    name="COUNTRY_ID"
                                                                    value={locality.COUNTRY_ID}
                                                                    onChange={handleChange}


                                                                >
                                                                    <option value="">Select  Country </option>

                                                                    {
                                                                        props.list && props.list.length > 0 &&
                                                                        props.list.map((item, index) => {
                                                                            return (
                                                                                <option key={index} value={item.COUNTRY_ID} >{item.COUNTRY}</option>
                                                                            )

                                                                        })

                                                                    }

                                                                </select>
                                                                {error && locality.COUNTRY_ID.trim().length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}

                                                            </div>
                                                        </div>


                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label for="basicpill-firstname-input"> Select State<span style={{ color: "#FF0000" }}>*</span></label>
                                                                <select

                                                                    className='form-control'
                                                                    name="STATE_ID"
                                                                    value={locality.STATE_ID}
                                                                    onChange={handleChange}


                                                                >
                                                                    <option value="">Select State </option>

                                                                    {
                                                                        state && state.length > 0 &&
                                                                        state.map((item, index) => {
                                                                            return (
                                                                                <option key={index} value={item.STATE_ID}>{item.STATE}</option>
                                                                            )

                                                                        })

                                                                    }

                                                                </select>

                                                                {error && locality.STATE_ID.trim().length === 0 && <span className='text-danger ml-1'> This Field is Required</span>}


                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className='row'>



                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label for="basicpill-firstname-input"> Select City<span style={{ color: "#FF0000" }}>*</span></label>
                                                                <select

                                                                    className='form-control'
                                                                    name="CITY_ID"
                                                                    value={locality.CITY_ID}
                                                                    onChange={handleChange}


                                                                >
                                                                    <option value="">Select  City </option>
                                                                    {
                                                                        city && city.length > 0 &&
                                                                        city.map((item, index) => {
                                                                            return (
                                                                                <option key={index} value={item.CITY_ID}>{item.CITY}</option>
                                                                            )

                                                                        })
                                                                    }

                                                                </select>
                                                                {error && locality.CITY_ID.trim().length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}


                                                            </div>
                                                        </div>


                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label for="basicpill-address-input">Locality Name <span style={{ color: "#FF0000" }}>*</span></label>
                                                                <input
                                                                    className='form-control'
                                                                    placeholder="Enter Locality Name"
                                                                    id="basicpill-firstname-input"
                                                                    onChange={handleChange}
                                                                    name="LOCALITY"
                                                                    value={locality.LOCALITY}

                                                                />

                                                                {error && locality.LOCALITY.trim().length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}


                                                            </div>

                                                        </div>

                                                    </div>

                                                    <div className="row">



                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label for="basicpill-address-input">URL <span style={{ color: "#FF0000" }}>*</span></label>
                                                                <input
                                                                    className='form-control'
                                                                    placeholder="Enter Locality URL"
                                                                    id="basicpill-firstname-input"
                                                                    onChange={handleChange}
                                                                    name="URL"
                                                                    value={locality.URL}
                                                                />


                                                            </div>

                                                        </div>



                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label for="basicpill-address-input">Sort Number <span style={{ color: "#FF0000" }}>*</span></label>
                                                                <NumberFormat
                                                                    className='form-control'
                                                                    placeholder="Enter Sort Number"
                                                                    id="basicpill-firstname-input"
                                                                    onChange={handleChange}
                                                                    name="SORT_NUMBER"
                                                                    value={locality.SORT_NUMBER}

                                                                />
                                                                {error && locality.SORT_NUMBER.trim().length == 0 ? <span className='text-danger ml-1'> This Field is Required</span> : ''}


                                                            </div>

                                                        </div>


                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <button type="button" onClick={(e) => onSubmit(e)} className="btn btn-info waves-effect btn-label waves-light"> <i className="bx bx-check-double label-icon"></i>Create Locality</button>
                                                                &nbsp;&nbsp;
                                                                <button type="button" onClick={(e) => handleReset(e)} className="btn btn-danger waves-effect btn-label waves-light"><i className="bx bx-block label-icon "></i> Cancel</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>

                                            </section>
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>


                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AddLocalities
