import EditAffiliate from "./pages/Affiliate/EditAffiliate"

const component = {
    login: { url: '/' },
    pushNotification: { url: '/push-notification' },
    pushNotificationList: { url: '/push-notification-list' },
    resetPassword: { url: '/reset-password' },
    forgotPassword: { url: '/forgot-password' },
    Statisics: { url: '/statistics' },
    geo_stats: { url: '/geo-stats' },
    logout: { url: '/logout' },
    home: { url: '/' },
    dashboard: { url: '/dashboard' },
    addskills: { url: '/create-skills' },
    editSkill: { url: '/update-skills-:id:pageno' },
    skilllist: { url: '/skill-list' },
    adddesignation: { url: '/create-designation' },
    editDesignation: { url: '/update-designation-:id:pageno' },
    designationlist: { url: '/designation-list' },
    addjobcategory: { url: '/create-job-category' },
    editjobcategory: { url: '/update-job-category-:id:pageno' },
    jobcategorylist: { url: '/job-category-list' },
    addsalary: { url: '/create-salary' },
    editSalary: { url: '/update-salary-:id' },
    salarylist: { url: '/salary-list' },
    addjobtype: { url: '/create-job-type' },
    editjobtype: { url: '/update-job-type-:id:pageno' },
    jobtypelist: { url: '/job-type-list' },
    addgovtresult: { url: '/add-govt-result' },
    govtjobsresult: { url: '/govt-jobs-result' },

    addgovtdepartment: { url: '/create-govt-dept' },
    addgovtjobs: { url: '/create-govt-jobs' },
    editgovtjobs: { url: '/update-govt-jobs-:id:pageno' },
    editgovtresult: { url: '/update-govt-result-:id:pageno' },
    editgovtdepartment: { url: '/update-govt-dept-:id:pageno' },
    govtdepartmentlist: { url: '/govt-dept-list' },
    govtjobslist: { url: '/govt-jobs-list' },
    addadminuser: { url: '/create-admin-user' },
    editAdminUser: { url: '/update-admin-user-:id' },
    adminuserlist: { url: '/admin-user-list' },
    addadminrole: { url: '/create-admin-role' },
    editadminrolelist: { url: '/update-admin-role-:id' },
    adminrolelist: { url: '/admin-role-list' },
    adminuserlog: { url: '/user-log' },
    totalcandidates: { url: '/candidates-list' },
    /****Add By Jitender */
    CandidatesProfile: { url: '/CandidatesProfile-:id' },
    EmployersReview: { url: '/Employers-Review-:id' },
    EmployerSubscription: { url: '/Employers-Subscription-:id' },
    JobApplied: { url: '/JobApplied-:id' },
    CandidatesInformaton: { url: '/CandidatesInformation-:id' },
    /****End** */
    totalemployers: { url: '/total-employers' },
    totaljobs: { url: '/total-jobs' },
    fourzerofour: { url: '/not-found' },
    addblogcategory: { url: '/create-blog-category' },
    blogcategorylist: { url: '/blog-category-list' },
    addblog: { url: '/create-blog' },
    editblogcategory: { url: '/update-blog-category-:id' },
    bloglist: { url: '/blog-list' },
    DiscountandCoupons: { url: '/discount-and-coupons' },
    DiscountandCouponsList: { url: '/discount-and-coupons-list' },
    EditDiscountandCoupons: { url: '/edit-discount-and-coupons-:id' },

    addemployers: { url: '/create-employer' },
    editemployers: { url: '/update-employer-:id:pageNo' },
    addcompanytype: { url: '/create-company-type' },
    editcompanytype: { url: '/update-company-type-:id' },
    companytypelist: { url: '/company-type-list' },
    addindustry: { url: '/create-industry' },
    editindustry: { url: '/update-industry-:id:pageno' },
    industrylist: { url: '/industry-list' },
    employerslist: { url: '/employer-list' },
    addemployeebenefits: { url: '/create-employee-benefits' },
    editEmployeeBenefitTytpe: { url: '/update-employee-benefits-:id' },
    employeebenefitslist: { url: '/employee-benefits-list' },
    addentitytype: { url: '/create-entity-type' },
    editentitytype: { url: '/update-entity-type-:id' },
    entitytypelist: { url: '/entity-type-list' },
    addaddressprooftype: { url: '/create-address-proof-type' },
    editaddressprooftype: { url: '/update-address-proof-type-:id' },
    addressprooftypelist: { url: '/address-proof-type-list' },
    faqaddcategory: { url: '/create-faq-category' },
    faqeditcategory: { url: '/update-faq-category-:id' },
    faqlistcategory: { url: '/faq-category-list' },
    faqadd: { url: '/create-faq' },
    faqedit: { url: '/update-faq-:id' },
    faqlist: { url: '/faq-list' },
    jobsfaqaddcategory: { url: '/create-jobs-faq-category' },
    jobsfaqeditcategory: { url: '/update-jobs-faq-category-:id' },
    jobsfaqlistcategory: { url: '/jobs-faq-category-list' },
    jobsfaqadd: { url: '/create-jobs-faq' },
    jobsfaqedit: { url: '/update-jobs-faq-:id' },
    jobsfaqlist: { url: '/jobs-faq-list' },
    addQualification: { url: '/qualification-add' },
    editQualification: { url: '/qualification-edit-:id:pageno' },
    listQualification: { url: '/qualification-list' },
    addJobQuest: { url: '/jobQuest-add' },
    editJobQuest: { url: '/jobQuest-edit-:id' },
    listJobQuest: { url: '/jobQuest-list' },
    addRole: { url: '/role-add' },
    editRole: { url: '/role-edit-:id:pageno' },
    listRole: { url: '/role-list' },
    addFunctionalArea: { url: '/functional-area-add' },
    editFunctionalArea: { url: '/functional-area-edit-:id:pageno' },
    listFunctionalArea: { url: '/functional-area-list' },
    addIndustry: { url: '/industry-add' },
    editIndustry: { url: '/industry-edit-:id:pageno' },
    listIndustry: { url: '/industry-list' },
    addEmployementType: { url: '/employement-type-add' },
    editEmployementType: { url: '/employement-type-edit-:id:pageno' },
    listEmployementType: { url: '/employement-type-list' },
    addJobs: { url: '/jobs-add' },
    editJobs: { url: '/jobs-edit-:id:pageno' },
    listJobs: { url: '/jobs-list' },
    addCourseSpecialization: { url: '/course-specz-add' },
    editCourseSpecialization: { url: '/course-specz-edit-:id:pageno' },
    listCourseSpecialization: { url: '/course-specz-list' },
    candidateslist: { url: '/candidates-list' },
    addArticle: { url: '/create-article' },
    listArticle: { url: '/article-list' },
    articleAddCategory: { url: '/create-article-category' },
    articleCategoryList: { url: '/article-category-list' },
    editArticleCategory: { url: '/update-article-category-:id' },
    profile: { url: '/admin-profile' },
    updateProfile: { url: '/update-profile' },
    lockScreen: { url: '/lock-screen' },
    addmodule: { url: '/create-module' },
    modulelist: { url: '/module-list' },
    archiveEmployerList: { url: '/archive-employer-list' },
    featuredEmployerList: { url: '/featured-employer-list' },
    premiumEmployerList: { url: '/premium-employer-list' },
    addTestimonial: { url: '/create-testimonial' },
    testimonialList: { url: '/testimonial-list' },
    editTestimonial: { url: '/update-testimonial-:id' },
    addInterviewQuestion: { url: '/create-interviewquestion' },
    interviewQuestionList: { url: '/interviewquestion-list' },
    addPracticeQuestion: { url: '/create-practicequestion' },
    practiceQuestionList: { url: '/practicequestion-list' },
    addCity: { url: '/add-cities' },
    cityList: { url: '/cities-list' },
    stateList: { url: '/state-list' },
    addLocality: { url: 'localities' },
    localityList: { url: 'localities-list' },
    countryList: { url: '/country-list' },
    premiumCityList: { url: '/premium-cities-list' },
    addCmsPage: { url: '/create-cmspage' },
    cmsPageList: { url: '/cmspage-list' },
    editCms: { url: '/editCms-:id' },
    addDownloads: { url: '/create-downloads' },
    downloadList: { url: '/download-list' },
    adddownloadcategory: { url: '/download-category' },
    downloadcategorylist: { url: '/download-categorylist' },
    editDownloadCategory: { url: '/update-download-category-:id' },
    addsubcategory: { url: '/download-subcategory' },
    addsubcategorylist: { url: '/download-subcategorylist' },
    editsubcategory: { url: '/update-download-subcategory-:id' },
    Editdownload: { url: '/Editdownload-:id' },
    addPressRelease: { url: '/create-pressrelease' },
    pressReleaseList: { url: '/pressrelease-list' },
    editPressRelease: { url: '/EditPressRelease-:id' },
    addNews: { url: '/create-news' },
    newsedit: { url: '/update-news-:id' },
    newsList: { url: '/news-list' },
    editblog: { url: '/update-blog-:id' },
    editarticle: { url: '/update-article-:id' },
    blogCommentList: { url: '/blog-comments-:id' },
    allCommentList: { url: '/blog-comments' },
    enquiryList: { url: '/enquiries' },
    ComplaintList: { url: '/complaintList' },
    UnderMaintainanceEnquiryList: { url: '/maintainance-enquiry-list' },
    CourseSubscription: { url: '/course-subscription' },
    editPracticequestion: { url: '/update-questions-:id:pageno' },
    interviewAnswer: { url: '/interview-qns-answers-:id' },
    editInterviewQuestion: { url: '/update-interview-question-:id:pageno' },
    salesEnqueryList: { url: '/list-sales-enqueries' },
    ReportIssueList: { url: '/report-a-problem' },
    JobPostRequestList: { url: '/approved-jobs' },
    addSkillLearning: { url: '/create-skill-learning' },
    skillLearningList: { url: '/skill-learning-list' },
    editSkillLearning: { url: '/update-skill-learning-:id' },
    addToolLearning: { url: '/create-tool-learning' },
    toolLearningList: { url: '/tool-learning-list' },
    editToolLearning: { url: '/update-tool-learning-:id' },
    addCourseContent: { url: '/create-course-content' },
    coursecontentList: { url: '/course-content-list' },
    editCourseContent: { url: '/update-course-content-:id' },
    addCourseContentModule: { url: '/create-course-content-module' },
    coursecontentmoduleList: { url: '/course-content-module-list' },
    editCourseContentModule: { url: '/update-course-content-module-:id' },
    addCourse: { url: '/create-course' },
    courseList: { url: '/course-list' },
    editCourse: { url: '/update-course-:id' },
    employerKYC: { url: '/employer-kyc-list' },
    Discussionforum: { url: '/discussion-forum' },
    DiscussionforumList: { url: '/discussion-forum-list' },
    editDiscussionForm: { url: '/forum-list-:id' },
    manageEmployer: { url: '/setting-manage-employer' },
    addglobalsettings: { url: '/global-settings' },
    // settinglist: { url: '/global-list' },
    // editGlobalSetting: { url: '/update-global-settings-list-:id' },

    manageEmployer: { url: '/setting-manage-employer' },
    PendingJobPostRequestList: { url: '/pending-job-requests' },
    scrappedJobs: { url: '/scrapped-jobs-list' },

    recruiterEnquiryList: { url: '/recruiter-enquiry' },
    Discussionforumactivethread: { url: '/discussion-forum-active-thread' },
    EditDiscussionThread: { url: '/discussion-forum-active-thread-edit-:id' },
    addSubscription: { url: '/create-subscription-package' },
    SubscriptionList: { url: '/subscription-List' },
    editSubscriptionList: { url: '/subscriptions-list-:id' },
    Subscriptionpackage: { url: '/subscriptionpackage-list' },
    Companyreview: { url: '/company-review' },
    addCollege: { url: '/create-college' },
    CollegeListing: { url: '/college-listing' },
    editCollege: { url: '/update-college-:id' },
    collegeCourses: { url: '/college-courses' },
    jobAlert: { url: '/job-alert-list' },
    collegeCourses: { url: '/college-courses-:id' },
    collegeImageMapper: { url: '/college-image-mapper-:id' },
    appliedJObs: { url: '/applied-jobs' },
    futureHiringList: { url: '/future-event-hiring-list' },
    shareInterviewQuestion: { url: '/shareInterviewQuestion-list' },
    shareInterviewAnswer: { url: '/shareInterview-qns-answers-:id' },
    editShareInterviewQuestion: { url: '/update-share-interview-question-:id' },
    OutplacementList: { url: '/outplacement-list' },
    premiumJobsList: { url: '/premium-Jobs-list' },
    featuredJobsList: { url: '/featured-Jobs-list' },
    // HRMSSubscriptionpackage: { url: '/hrmssubscription-list' },
    jobPackageList: { url: '/jobpackage-list' },
    employerpremiumjobs: { url: '/employer-premium-jobs-:id' },
    employerfeaturedjobs: { url: '/employer-featured-jobs-:id' },
    employerJobsPosted: { url: '/employer-jobs-Posted-:id' },
    addbanner: { url: '/create-banner' },
    addthirdpartybanner: { url: '/create-third-party-banner' },
    bannerlist: { url: '/banner-list' },
    editbanner: { url: '/update-banner-:id:pageno' },
    banneranalytics: { url: '/banner-analytics-:id' },
    addAffiliation: { url: '/add-affiliate' },
    affiliationList: { url: '/affiliate-list' },

    banneranalytics: { url: '/banner-analytics-:id' },
    atsJobPosting: { url: '/ats-jobs' },




    candidateAppliedJob: { url: '/candidate-applied-jobs-:id' },
    updateIndustry: { url: '/update-employers-industry' },
    loanApplications: { url: '/loan-applications' },
    EditAffiliate: { url: '/edit-affiliate-:id' },
    affiliateAnalytics: { url: '/affiliateAnalticsList-:id' },


    addUniversity: { url: '/create-university' },
    universityList: { url: '/university-list' },
    editUniversity: { url: '/edit-university-:id' },
    updateLatestNews: { url: '/campus-update-latest-news' },
    addNewCampusAnnouncement: { url: '/campus-add-announcement' },
    listCampusAnnouncement: { url: '/campus-list-announcement' },
    collegeList: { url: '/college-list' },
    collegeDetail: { url: '/college/:id' },
    editCollegeDetail: { url: '/edit/college/:id' },


    collegeStudentList: { url: '/college-student-list-:id' },
    CampusStudentDetil: { url: '/:type/student/:id' },
    offCampusStudentList: { url: '/offcampus-student-list' }






}

const title = {
    Login: 'Login | JobzShala.com | Administration Control Panel',
    DiscountandCoupons: 'Add Discount and Coupons | JobzShala.com | Administration Control Panel',
    EditDiscountandCoupons: 'Edit Discount and Coupons | JobzShala.com | Administration Control Panel',

    DiscountandCouponsList: 'Discount and Coupons List | JobzShala.com | Administration Control Panel',
    AdminUserList: 'Admin User List | JobzShala.com | Administration Control Panel',
    AddRole: 'Add Role |  JobzShala.com | Administration Control Panel',
    UpdateRole: 'Update Role |  JobzShala.com | Administration Control Panel',
    RoleList: 'Role List |  JobzShala.com | Administration Control Panel',
    AddSkills: 'Add skills | JobzShala.com | Administration Control Panel',
    EditSkills: 'Update skills | JobzShala.com | Administration Control Panel',
    SkillList: 'Skill List | JobzShala.com | Administration Control Panel',
    AddDesignation: 'Add Designation | JobzShala.com | Administration Control Panel',
    EditDesignation: 'Update Designation | JobzShala.com | Administration Control Panel',
    DesignationList: 'Designation List | JobzShala.com | Administration Control Panel',
    AddJobCategory: 'Add Job Category | JobzShala.com | Administration Control Panel',
    EditJobCategory: 'Update Job Category | JobzShala.com | Administration Control Panel',
    JobCategoryList: 'Job Category List | JobzShala.com | Administration Control Panel',
    AddSalary: 'Add Salary | JobzShala.com | Administration Control Panel',
    EditSalary: 'Update Salary | JobzShala.com | Administration Control Panel',
    SalaryList: 'Salary List | JobzShala.com | Administration Control Panel',
    AddJobType: 'Add Job Type | JobzShala.com | Administration Control Panel',
    EditJobType: 'Update Job Type | JobzShala.com | Administration Control Panel',
    JobTypeList: 'Job Type List | JobzShala.com | Administration Control Panel',
    AddGovtDepartment: 'Add Govt Department | JobzShala.com | Administration Control Panel',
    AddGovtJobs: 'Add Govt Jobs | JobzShala.com | Administration Control Panel',
    editgovtjobs: 'Update Govt Job | JobzShala.com | Administration Control Panel',

    EditGovtDepartment: 'Update Govt Department | JobzShala.com | Administration Control Panel',
    GovtDepartmentList: 'Govt Department List | JobzShala.com |Administration Control Panel',
    GovtJobsList: 'Govt Jobs List | JobzShala.com |Administration Control Panel',
    editgovtresult: 'Edit Govt Result| JobzShala.com |Administration Control Panel',
    GovtResult: 'Govt Result| JobzShala.com |Administration Control Panel',
    GovtResultList: 'Govt Result List | JobzShala.com |Administration Control Panel',
    AddAdminUser: 'Add Admin user | JobzShala.com | Administration Control Panel',
    UpdateAdminUSer: 'Update Admin user | JobzShala.com | Administration Control Panel',
    AdminUserList: 'Admin User List | JobzShala.com | Administration Control Panel',
    AddAdminRole: 'Add Admin Role | JobzShala.com | Administration Control Panel',
    AdminRoleList: 'Admin Role List | JobzShala.com | Administration Control Panel',
    AdminUserLog: 'Admin User Log | JobzShala.com | Administration Control Panel',
    AddModules: 'Add Module | JobzShala.com | Administration Control Panel',
    EditModule: 'Edit Module | JobzShala.com | Administration Control Panel',
    Dashboard: 'Dashboard | JobzShala.com | Administration Control Panel',
    TotalCandidates: 'Candidates List | JobzShala.com | Administration Control Panel',
    /**Add By Jitender** */
    CandidatesProfile: 'Profile Information | JobzShala.com | Administration Control Panel',
    EmployersReview: 'Employers Review Information | JobzShala.com | Administration Control Panel',
    EmployerSubscription: 'Employers Subscription Information | JobzShala.com | Administration Control Panel',
    JobApplied: 'Jobs Applied | JobzShala.com | Administration Control Panel',
    /***End** */
    TotalEmployers: 'Total Employers | JobzShala.com | Administration Control Panel',
    TotalJobs: 'Total Jobs | JobzShala.com | Administration Control Panel',
    AddBlogCategory: 'Add Blog Category | JobzShala.com | Administration Control Panel',
    EditBlogCategory: 'Update Blog Category | JobzShala.com | Administration Control Panel',
    BlogCategoryList: 'Blog Category List | JobzShala.com | Administration Control Panel',
    AddBlog: 'Add Blog | JobzShala.com |  Administration Control Panel',
    BlogList: 'Blog List | JobzShala.com | Administration Control Panel',
    AddEmployers: 'Add Employers | JobzShala.com | Administration Control Panel',
    EditEmployers: 'Update Employers | JobzShala.com | Administration Control Panel',
    AddCompanyType: 'Add Company Type | JobzShala.com | Administration Control Panel',
    UpdateCompanyType: 'Update Company Type | JobzShala.com | Administration Control Panel',
    CompanyTypeList: 'Company Type List | JobzShala.com | Administration Control Panel',
    AddIndustry: 'Add Industry | JobzShala.com | Administration Control Panel',
    EditIndustry: 'Update Industry | JobzShala.com | Administration Control Panel',
    IndustryList: 'Industry List | JobzShala.com | Administration Control Panel',
    EmployersList: 'Employers List | JobzShala.com | Administration Control Panel',
    AddEmployeeBenefits: 'Add Employee Benefits | JobzShala.com | Administration Control Panel',
    EditEmployeeBenefits: 'Update Employee Benefits | JobzShala.com | Administration Control Panel',
    EmployeeBenefitsList: 'Employee Benefits List | JobzShala.com | Administration Control Panel',
    AddEntityType: 'Add Entity Type | JobzShala.com | Administration Control Panel',
    EditEntityType: 'Update Entity Type | JobzShala.com | Administration Control Panel',
    EntityTypeList: 'Entity Type List | JobzShala.com | Administration Control Panel',
    AddAddressProofType: 'Add Address Proof Type | JobzShala.com | Administration Control Panel',
    EditAddressProofType: 'Update Address Proof Type | JobzShala.com | Administration Control Panel',
    AddressProofTypeList: 'Address Proof Type List | JobzShala.com | Administration Control Panel',
    FaqAddCategory: 'Add FAQs Category | JobzShala.com | Administration Control Panel',
    FaqListCategory: 'FAQs Category List | JobzShala.com | Administration Control Panel',
    UpdateFaqCategory: 'Update FAQs Category  | JobzShala.com | Administration Control Panel',
    FaqAdd: 'Add FAQs | JobzShala.com | Administration Control Panel',
    EditFAQ: 'Update FAQs | JobzShala.com | Administration Control Panel',
    FaqList: 'FAQs List | JobzShala.com | Administration Control Panel',
    CandidatesList: 'Candidates List | JobzShala.com | Administration Control Panel',
    AddEmployementType: 'Add Employement Type | JobzShala.com | Administration Control Panel',
    listEmployementType: 'Employement Type List | JobzShala.com | Administration Control Panel',
    UpdateEmployementType: 'Update Employement Type | JobzShala.com | Administration Control Panel',
    AddQualification: 'Add Educational Qualification | JobzShala.com | Administration Control Panel',
    listQualification: 'Educational Qualification List | JobzShala.com | Administration Control Panel',
    UpdateQualification: 'Update Educational Qualification | JobzShala.com | Administration Control Panel',
    AddFunctionalArea: 'Add Functional Area | JobzShala.com | Administration Control Panel',
    ListFunctionalArea: 'Functional Area  List | JobzShala.com | Administration Control Panel',
    UpdateFunctionalArea: 'Update Functional Area  | JobzShala.com | Administration Control Panel',
    AddRole: 'Add Role| JobzShala.com | Administration Control Panel',
    RoleList: 'Role List | JobzShala.com | Administration Control Panel',
    UpdateRole: 'Update Role | JobzShala.com | Administration Control Panel',
    AddCourseSpecialization: 'Add Course Specialization | JobzShala.com | Administration Control Panel',
    ListCourseSpecialization: 'Course Specialization List | JobzShala.com | Administration Control Panel',
    UpdateCourseSpecialization: 'Update Course Specialization  | JobzShala.com | Administration Control Panel',
    AddJobQuestion: 'Add Job Question  | JobzShala.com | Administration Control Panel',
    ListJobQuestion: 'Job Question List  | JobzShala.com | Administration Control Panel',
    UpdateJobQuestion: 'Update job Question | JobzShala.com | Administration Control Panel',
    EditBlogCategory: 'Update Blog Category | JobzShala.com | Administration Control Panel',
    AddArticle: 'Add Article | JobzShala.com |  Administration Control Panel',
    ListArticle: 'Article List | JobzShala.com |  Administration Control Panel',
    AddArticleCategory: 'Add Article Category | JobzShala.com |  Administration Control Panel',
    ArticleCategoryList: 'Article Category List | JobzShala.com |  Administration Control Panel',
    UpdateArticleCategory: 'Update Article List | JobzShala.com |  Administration Control Panel',
    Profile: 'Profile | JobzShala.com |  Administration Control Panel',
    UpdateProfile: 'Update Profile | JobzShala.com |  Administration Control Panel',
    LockScreen: 'Lock Screen | JobzShala.com |  Administration Control Panel',
    ArchiveEmployerList: 'Archive Employer List | JobzShala.com |  Administration Control Panel',
    FeaturedEmployerList: 'Featured Employer List | JobzShala.com |  Administration Control Panel',
    PremiumEmployerList: 'Premium Employer List | JobzShala.com |  Administration Control Panel',
    AddTestimonial: 'Add Testimonial | JobzShala.com |  Administration Control Panel',
    ListTestimonial: 'List Testimonial | JobzShala.com |  Administration Control Panel',
    UpdateTestimonial: 'Update Testimonial | JobzShala.com |  Administration Control Panel',
    AddInterviewQuestion: 'Add Interview Questions | JobzShala.com |  Administration Control Panel',
    ListInterviewQuestion: 'Interview Questions List | JobzShala.com |  Administration Control Panel',
    AddPracticeQuestion: 'Add Practice Questions | JobzShala.com |  Administration Control Panel',
    ListPracticeQuestion: 'Practice Questions List | JobzShala.com |  Administration Control Panel',
    CmsPage: 'Add CMS Page | JobzShala.com |  Administration Control Panel',
    ListCms: 'CMS Page List | JobzShala.com |  Administration Control Panel',
    UpdateCMS: 'CMS Update | JobzShala.com |  Administration Control Panel',
    AddDownload: 'Add Downloads | JobzShala.com |  Administration Control Panel',
    ListDownload: 'Downloads List | JobzShala.com |  Administration Control Panel',
    UpdateDownload: 'Downloads List | JobzShala.com |  Administration Control Panel',
    AddDownloadCategory: 'Download category| JobzShala.com |  Administration Control Panel',
    DownloadCategoryList: 'Download Category List | JobzShala.com |  Administration Control Panel',
    UpdateDownloadCategory: 'Update Category | JobzShala.com |  Administration Control Panel',
    AddSubCategory: 'Download SubCategory  | JobzShala.com |  Administration Control Panel',
    AddSubCategoryList: 'Download SubCategory List | JobzShala.com |  Administration Control Panel',
    UpdateDownloadSubCategory: ' Update Download SubCategory  | JobzShala.com |  Administration Control Panel',
    AddNews: 'Add News & Updates | JobzShala.com |  Administration Control Panel',
    ListNews: 'News & Updates List | JobzShala.com |  Administration Control Panel',
    EditNews: 'News Update | JobzShala.com |  Administration Control Panel',
    AddPressRelease: 'Add Press Release | JobzShala.com |  Administration Control Panel',
    ListPressRelease: 'Press Release List | JobzShala.com |  Administration Control Panel',
    Update: 'PressRelease Update | JobzShala.com |  Administration Control Panel',
    States: 'State List | JobzShala.com |  Administration Control Panel',
    addLocalities: 'Add Locality List | JobzShala.com |  Administration Control Panel',
    Localities: 'Locality List | JobzShala.com |  Administration Control Panel',
    addCities: ' Add Cities List | JobzShala.com |  Administration Control Panel',
    Cities: 'Cities List | JobzShala.com |  Administration Control Panel',
    Country: 'Country List | JobzShala.com |  Administration Control Panel',
    PremiumCities: 'Premium Cities List | JobzShala.com |  Administration Control Panel',
    EditBlog: 'Update Blog | JobzShala.com |  Administration Control Panel',
    EditArticle: 'Update Article | JobzShala.com |  Administration Control Panel',
    BlogComment: 'Blog Comments | JobzShala.com |  Administration Control Panel',
    EnquiryList: 'Enquiries | JobzShala.com |  Administration Control Panel',
    OutplacementList: 'OutPlacement | JobzShala.com |  Administration Control Panel',
    ComplaintList: 'complaint | JobzShala.com |  Administration Control Panel',
    UnderMaintainanceEnquiryList: 'Maintainance | JobzShala.com |  Administration Control Panel',
    CourseSubscription: 'COURSE MODULE | JobzShala.com |  Administration Control Panel',
    EditPracticeQuestion: 'Update Practise Question | JobzShala.com |  Administration Control Panel',
    InterviewAnswer: 'Interview Question Answers | JobzShala.com |  Administration Control Panel',
    UpdateInterviewQuestion: 'Update Interview Question Answers | JobzShala.com |  Administration Control Panel',
    ReportIssueList: 'Report A Problem | JobzShala.com |  Administration Control Panel',
    salesEnqueryList: 'Sales Enquiry List | JobzShala.com |  Administration Control Panel',
    JobPostRequestList: 'Sales Enquiry List | JobzShala.com |  Administration Control Panel',
    AddSkillLearning: 'Add Skill Learning | JobzShala.com |  Administration Control Panel',
    SkillLearningList: 'Skill Learning List | JobzShala.com |  Administration Control Panel',
    UpdateSkillLearning: 'Update Skill Learning | JobzShala.com |  Administration Control Panel',
    AddToolLearning: 'Add Tool Learning | JobzShala.com |  Administration Control Panel',
    ToolLearningList: 'Tool Learning List | JobzShala.com |  Administration Control Panel',
    UpdateToolLearning: 'Update Tool Learning | JobzShala.com |  Administration Control Panel',
    AddCourseContent: 'Add Course Content | JobzShala.com |  Administration Control Panel',
    CourseContentList: 'Course Content List | JobzShala.com |  Administration Control Panel',
    UpdateCourseContent: 'Update Course Content | JobzShala.com |  Administration Control Panel',
    AddCourseContentModule: 'Course Content Module | JobzShala.com |  Administration Control Panel',
    CourseContentModuleList: 'Course Content module List | JobzShala.com |  Administration Control Panel',
    UpdateCourseContentModule: 'Update Course Content Module| JobzShala.com |  Administration Control Panel',
    AddCourse: ' Add Course  | JobzShala.com |  Administration Control Panel',
    CourseList: 'Course  List | JobzShala.com |  Administration Control Panel',
    UpdateCourse: 'Update Course | JobzShala.com |  Administration Control Panel',
    JobPostRequestList: 'Job Post List | JobzShala.com |  Administration Control Panel',
    EmployerKYC: 'Employer Kyc List | JobzShala.com |  Administration Control Panel',
    AddDiscussionForum: 'Create Discussion Forum | JobzShala.com |  Administration Control Panel',
    DiscussionForumList: 'Discussion Forum List | JobzShala.com | Administration Control Panel',
    EditDiscussionForum: 'Update Discussion Forum | JobzShala.com | Administration Control Panel',
    AddGlobalSettings: ' GlobalSettings | JobzShala.com |  Administration Control Panel',
    // GlobalSettingList: ' Global Setting List | JobzShala.com | Administration Control Panel',
    // EditGlobalSetting: 'Update Global Setting | JobzShala.com | Administration Control Panel',
    Discussionforumactivethread: 'Discussion Thread | JobzShala.com | Administration Control Panel',
    AddJobs: 'Add Jobs | JobzShala.com | Administration Control Panel',
    EditJobs: 'Edit Jobs | JobzShala.com | Administration Control Panel',
    EditDiscussionforumactivethread: 'Discussion Thread Edit| JobzShala.com | Administration Control Panel',
    Addsubscription: 'Create subscription| JobzShala.com |  Administration Control Panel',
    subscriptionList: ' subscription List| JobzShala.com |  Administration Control Panel',
    UpdateSubscriptionPackage: 'Update Subscription | JobzShala.com |  Administration Control Panel',
    subscriptionpackage: ' Subscription package| JobzShala.com |  Administration Control Panel',
    AddCollege: 'Add College | JobzShala.com | Administration Control Panel',
    EditCollege: 'Update College | JobzShala.com | Administration Control Panel',
    CollegeList: 'College List | JobzShala.com | Administration Control Panel',
    collegeCourses: 'Course | JobzShala.com | Administration Control Panel',
    JobAlertList: 'JobAlertList | JobzShala.com | Administration Control Panel',
    Companyreview: 'CompanyReview | JobzShala.com | Administration Control Panel',
    FutureEventHiringList: 'Future Hiring List | JobzShala.com | Administration Control Panel',
    PremiumJobList: 'Premium Job List | JobzShala.com |  Administration Control Panel',
    featuredJobList: 'featured Job List | JobzShala.com |  Administration Control Panel',
    hrmssubscription: ' hrmsSubscription package| JobzShala.com |  Administration Control Panel',
    jobPackage: ' job package list| JobzShala.com |  Administration Control Panel',
    employerpremiumjobs: ' Employer Premium jobs | JobzShala.com |  Administration Control Panel',
    employerfeaturedjobs: ' Employer Featured jobs | JobzShala.com |  Administration Control Panel',
    employerJobsPosted: 'Employer Jobs Posted | JobzShala.com |  Administration Control Panel',
    AddBanner: 'Add Banner | JobzShala.com |  Administration Control Panel',
    BannerList: 'Banner List| JobzShala.com |  Administration Control Panel',
    EditBanner: 'Add Banner | JobzShala.com |  Administration Control Panel',
    addAffiliation: 'Add Affiliate | JobzShala.com |  Administration Control Panel',
    affiliationList: 'Add Affiliate | JobzShala.com |  Administration Control Panel',
    candidateAppliedJob: 'Candidate Applied Jobs | JobzShala.com | Administration Control Panel',
    updateIndustry: 'Update Employer Industry | JobzShala.com | Administration Control Panel',
    updateAffiliate: 'Update Affiliate | JobzShala.com | Administration Control Panel',
    affiliationAnalytics: 'Affliation Analytics List | JobzShala.com | Administration Control Panel',




}


const keys = {
    token: 'tk.JobzShala.com',
    userDetails: 'ud.JobzShala.com',
    loggedIn: 'logged_in.JobzShala.com',
    remeber_me: 'remeber.JobzShala.com'
}

const version = {
    web: 1.0
}

const currency = {
    indianRupee: '₹'
}



export default { component, keys, version, currency, title }