import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { GetPersonalDetail,GetCareerProfile,JobApplyStatus, GetRejectedJob,GetWorkSample, GetITSkillsList, GetProject, getITSkills, GetResume, getResumeHeadLine,GetProfileInformation, GetEducation, GetCertifications, getProfileSummary, Getonlineprofile, Getemployement} from '../../actions/ProfileAction'
import CandidatesPro from '../../component/Menu/CandidatesProfile'
import constant from '../../constant'
 
 

export default function CandidatesProfile() {
    const [PersonalDetails, setpersonaldetails] = useState(null)
    const [itskills, setItSkills] = useState([])
    const [Profile, setProfile] = useState([])
    const [resumeheadline, setresumeheadline] = useState([])
    const [Project, setproject] = useState([])
    const [profilesummary, setprofilesummary] = useState([])
    const [onlineprofile, setonlineprofile] = useState([])
    const [WorkSample, setworksample] = useState([])
    const [certifications, setcertifications] = useState([])
    const [employement, setemployement] = useState([])
    const [education, seteducation] = useState([])
    const [Resume, setresume] = useState([])
    const [Skillslist, setSkillslist] = useState([])
    const [CareerProfile, setCareer] = useState([])
    const [Rejected, setrejected] = useState([])
    const [JobApply, setjobapply] = useState([])
    
    
    const location = useLocation()
    
     const params = useParams()
    useEffect(() => {
      document.title = constant.title.CandidatesProfile
         
        GetPersonalDetail({CANDIDATE_ID : parseInt( params.id) }).then((res) => {
            if (res.status) {
                setpersonaldetails(res.result)
            }
        }).catch(err => {
            alert(err)
        })

        GetCareerProfile({CANDIDATE_ID : parseInt( params.id) }).then((res) => {
         if (res.status) {
          setCareer(res.result)
         }
     }).catch(err => {
         alert(err)
     })

        GetProfileInformation({CANDIDATE_ID : parseInt( params.id) }).then((res) => {
         if (res.status) {
             setProfile(res.result)
         }
     }).catch(err => {
         alert(err)
     })

        GetCertifications({CANDIDATE_ID : parseInt( params.id) }).then((res) => {
         if (res.status) {
             setcertifications(res.result)
         }
     }).catch(err => {
         alert(err)
     })

     
     JobApplyStatus({CANDIDATE_ID : parseInt( params.id) }).then((res) => {
     if (res.status) {
      setjobapply(res.result.list)
     }
 }).catch(err => {
     alert(err)
 })


     GetRejectedJob({CANDIDATE_ID : parseInt( params.id) }).then((res) => {
     if (res.status) {
         setrejected(res.result)
     }
 }).catch(err => {
     alert(err)
 })

     GetITSkillsList({CANDIDATE_ID : parseInt( params.id) }).then((res) => {
    if (res.status) {
      setSkillslist(res.result)
    }
}).catch(err => {
    alert(err)
})
        
        getITSkills({CANDIDATE_ID : parseInt( params.id) }).then((res) => {
        if (res.status) {
          setItSkills(res.result)
        }
    }).catch(err => {
        alert(err)
    })

    GetWorkSample({CANDIDATE_ID : parseInt( params.id) }).then((res) => {
    if (res.status) {
      setworksample(res.result)
    }
}).catch(err => {
    alert(err)
})


    
    Getonlineprofile({CANDIDATE_ID : parseInt( params.id) }).then((res) => {
    if (res.status) {
      setonlineprofile(res.result)
    }
}).catch(err => {
    alert(err)
})

    GetResume({CANDIDATE_ID : parseInt( params.id) }).then((res) => {
    if (res.status) {
      setresume(res.result)
    }
}).catch(err => {
    alert(err)
})

    getResumeHeadLine({CANDIDATE_ID : parseInt( params.id) }).then((res) => {
    if (res.status) {
      setresumeheadline(res.result)
    }
}).catch(err => {
    alert(err)
})

GetProject({CANDIDATE_ID : parseInt( params.id) }).then((res) => {
if (res.status) {
  setproject(res.result)
}
}).catch(err => {
alert(err)
})

getProfileSummary({CANDIDATE_ID : parseInt( params.id) }).then((res) => {
if (res.status) {
  setprofilesummary(res.result)
}
}).catch(err => {
alert(err)
})

Getonlineprofile({CANDIDATE_ID : parseInt( params.id) }).then((res) => {
if (res.status) {
  setonlineprofile(res.result)
}
}).catch(err => {
alert(err)
})

Getemployement({CANDIDATE_ID : parseInt( params.id) }).then((res) => {
if (res.status) {
  setemployement(res.result)
}
}).catch(err => {
alert(err)
})

GetEducation({CANDIDATE_ID : parseInt( params.id) }).then((res) => {
if (res.status) {
  seteducation(res.result)
}
}).catch(err => {
alert(err)
})
    }, [])

   
  return (
    <div>
      <CandidatesPro PersonalDetails={PersonalDetails}  itskills={itskills} resumeheadline={resumeheadline} Project={Project} profilesummary={profilesummary}
      onlineprofile={onlineprofile} certifications={certifications} employement={employement} education={education} Profile={Profile} Resume={Resume}
      Skillslist={Skillslist} CareerProfile={CareerProfile} WorkSample={WorkSample} Rejected={Rejected} Jobapply={JobApply}
      />
    </div>
  )
}
