import React from 'react'
import { useEffect, useState } from 'react'
import List from '../../component/Enquiry/ComplaintList'
import constant from '../../constant'
import { getComplaintList, deleteComplaint } from '../../actions/utilitiesAction'
import Swal from 'sweetalert2'

const ComplaintList = () => {

    const [list, setList] = useState([])
    const [count, setCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [showLoader, setShowLoader] = useState(false)


    useEffect(() => {
        ComplaintLists(currentPage)
        document.title = constant.title.ComplaintList
    }, [currentPage])

    const ComplaintLists = (page) => {
        setShowLoader(true)
        getComplaintList({ page: page }).then((res) => {
            if (res.status) {
                setShowLoader(false)
                setList(res.result.list)
                setCount(res.result.count)
            }
            else {
                alert(res.error)
            }
        }).catch((err) => {
            alert(err)
        })
    }

    const deleteComplaintList = (COMPLAINT_ID) => {
        deleteComplaint({ COMPLAINT_ID: COMPLAINT_ID }).then((res) => {
            if (res.status) {
                Swal.fire(
                    'Deleted!',
                    'Your enquiry has been deleted.',
                    'success'
                )
                ComplaintLists(currentPage)
            }
            else {
                Swal.fire(
                    (res.result.error)
                )
            }
        })
    }

    return (
        <React.Fragment>


            <List
                list={list}
                setList={setList}
                ComplaintLists={(page => ComplaintLists(page))}
                count={count}
                setPage={setCurrentPage}
                showLoader={showLoader}
                deleteComplaintList={deleteComplaintList}

            />
        </React.Fragment>
    )
}

export default ComplaintList



