import React, { useEffect, useState } from 'react'
import { getDesignationList, archiveDesignation } from '../../../actions/utilitiesAction'
import Designationlist from '../../../component/Utilities/Designation/DesignationList'
import constant from '../../../constant'
import queryString from 'query-string'
import { useLocation } from "react-router-dom";

const DesignationList = () => {

    const [list, setList] = useState(null)
    const [count, setCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [keyword, setKeyword] = useState('')
    const [showLoader, setShowLoader] = useState(false)
    const location = useLocation()

    useEffect(() => {
        const qParams = queryString.parse(location.search)
        getDesignationLists(qParams?.p ? qParams?.p : qParams?.pageno ? qParams.pageno : currentPage)
        document.title = constant.title.DesignationList
    }, [currentPage])

    const getDesignationLists = (page) => {
        setShowLoader(true)
        getDesignationList({ pageNumber: page, KEYWORD: keyword }).then((res) => {
            if (res.status) {
                setShowLoader(false)
                setList(res.result.list)
                setCount(res.result.count)
            }
            else {
                alert(res.error)
            }
        }).catch((err) => {
            alert(err)
        })
    }
    const updateStatus = (model) => {
        archiveDesignation(model).then((res) => {
            if (res.status) {
                getDesignationLists(currentPage, "")
            } else {
                alert(res.error)
            }
        }).catch((err) => {
            alert(err)
        })
    }


    return (
        <React.Fragment>
            <Designationlist
                list={list}
                updateStatus={updateStatus}
                getDesignationLists={(page => getDesignationLists(page))}
                count={count}
                setPage={setCurrentPage}
                keyword={setKeyword}
                showLoader={showLoader}
                cpage={currentPage}
            />
        </React.Fragment>
    )
}
export default DesignationList;