import React, { useState } from 'react'
import moment from 'moment'
import constant from '../../../constant'
import { Link } from 'react-router-dom'
import Pagination from "react-js-pagination";
import Loader from '../../common/Loader';

export default function List(props) {


    const { college_list, page, count, showLoader } = props
    const updateStatus = (UNIVERSITY_ID, STATUS) => {
        props.updateStatus(UNIVERSITY_ID, STATUS);
    }

    return (
        <React.Fragment>

            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">College List</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="javascript: void(0);">Campus</a></li>
                                            <li className="breadcrumb-item active">College List</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table align-middle table-hover">
                                                <thead className="table-light">
                                                    {!showLoader && (
                                                        <tr>
                                                            <th scope="col">S.No.</th>
                                                            <th scope="col">College Name</th>
                                                            <th scope="col">College Detail</th>
                                                            <th scope="col">Added On</th>
                                                            <th scope="col">Students</th>
                                                            <th className="col">Other information</th>
                                                            <th scope="col">Status</th>
                                                            <th scope="col" style={{ width: "100px" }}>Action</th>
                                                        </tr>
                                                    )}
                                                </thead>
                                                {showLoader && <Loader />}
                                                <tbody>
                                                    {college_list && college_list.length > 0 &&
                                                        college_list.map((item, index) => (
                                                            <tr key={item.COLLEGE_ID}>
                                                                <td>{page === 1 ? `${index + 1}` : `${index === 9 ? page : page - 1}${index === 9 ? 0 : index + 1}`}</td>
                                                                <td>
                                                                    <p className="text-muted mb-0">{item.COLLEGE_NAME}</p>
                                                                    <p><b>College Code: </b>{item.COLLEGE_CODE}</p>
                                                                </td>
                                                                <td>
                                                                  
                                                                    <div style={{ wordWrap: 'break-word' }}>
                                                                        <b>Address: </b>{item.ADDRESS}
                                                                    </div>
                                                                    <div style={{ wordWrap: 'break-word' }}>
                                                                        {item.CITY + ', ' + item.STATE}
                                                                    </div>
                                                                    <div style={{ wordWrap: 'break-word' }}>
                                                                        {item.PIN_CODE}
                                                                    </div>
                                                                </td>
                                                                <td>{moment(item.CREATED_ON).format('DD.MM.YYYY on HH:MM A')}</td>
                                                                <td>
                                                                    <Link
                                                                        to={constant.component.collegeStudentList.url.replace(':id', item.COLLEGE_ID)}
                                                                        className="btn btn-info waves-effect btn-label waves-light"
                                                                    >
                                                                        <i className="bx bx-street-view label-icon" /> View Students ({item.STUDENT_COUNT})
                                                                    </Link>
                                                                </td>
                                                                <td>
                                                                    <a
                                                                        type="button"
                                                                        className="btn btn-info waves-effect btn-label waves-light"
                                                                        href={constant.component.collegeDetail.url.replace(":id", item.COLLEGE_ID)}
                                                                    >
                                                                        <i className="bx bx-street-view label-icon"></i> View Profile
                                                                    </a>
                                                                </td>
                                                                <td>
                                                                    {item.STATUS === 'A' ? (
                                                                        <button type="button" className="btn btn-success btn-sm waves-effect waves-light">ACTIVE</button>
                                                                    ) : (
                                                                        <button type="button" className="btn btn-danger btn-sm waves-effect waves-light">ARCHIVE</button>
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    <ul className="list-inline contact-links mb-0" style={{ fontSize: "18px" }}>
                                                                        <li className="list-inline-item px-2">
                                                                            <a href="javascript:void(0)"
                                                                                onClick={() => updateStatus(item.COLLEGE_ID, item.STATUS === 'A' ? 'I' : 'A')}
                                                                                style={{ color: 'red' }}
                                                                            >
                                                                                {item.STATUS === 'A' ? <i className="bx bx-trash"></i> : <i className="bx bx-log-in"></i>}
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>

                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="pagination pagination-rounded justify-content-center mt-4">
                                                    <Pagination
                                                        activePage={page}
                                                        itemsCountPerPage={20}
                                                        totalItemsCount={count}
                                                        pageRangeDisplayed={5}
                                                        onChange={(newPage) => props.onPageChange(newPage)}
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >)
}



