import React, { useState } from "react";
import constant from "../../../constant";
import moment from "moment";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import Loader from "../../common/Loader";
import { getSkillList } from "../../../actions/utilitiesAction";
import { keyboard } from "@testing-library/user-event/dist/keyboard";
import queryString from 'query-string'
import { useLocation, useNavigate } from "react-router-dom";

const SkillList = (props) => {

  const [currentPage, setCurrentPage] = useState(1);
  const location = useLocation()
  const Navigate = useNavigate()

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    Navigate(location.pathname + '?p=' + pageNumber)
    props.setPage(pageNumber)
  };

  const updateStatus = (SKILL_ID, STATUS) => {
    const model = {
      SKILL_ID: SKILL_ID,
      STATUS: STATUS === 'U' ? 'A' : 'U'
    }
    props.setPage(currentPage)
    props.updateStatus(model)
  }


  const searchList = () => {
    props.getSkillsLists(currentPage)
  }

  const qParams = queryString.parse(location.search)
  const { cpage } = props

  return (
    < React.Fragment >
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 " style={{ fontSize: "18px" }}>
                    Skills List
                  </h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Utilities</a>
                      </li>
                      <li className="breadcrumb-item active">Skills List</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <form className="row gy-12 gx-12 align-items-center">
                      <div className="col-sm-2">
                        <label className="visually-hidden" for="autoSizingInput">Name</label>
                        Search Skill
                      </div>
                      <div className="col-sm-6">
                        <label className="visually-hidden" for="autoSizingInputGroup">Username</label>
                        <div className="input-group">
                          <input type="text" onChange={(e) => {
                            props.setKeyword(e.target.value)
                          }} className="form-control" id="autoSizingInputGroup" placeholder="Enter Skill" />
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <button type="submit" onClick={(e) => {
                          e.preventDefault()
                          searchList()
                        }} className="btn btn-success waves-effect btn-label waves-light"><i className="bx bx-check-double label-icon"></i> Search</button>
                        &nbsp;
                        {/* <button type="button" onClick={resetList} className="btn btn-outline-danger">Reset</button> */}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table align-middle table-nowrap table-hover">
                        {
                          props.showLoader &&
                          <Loader />
                        }
                        {!props.showLoader && <thead className="table-light">
                          <tr>
                            <th scope="col">S.No.</th>
                            <th scope="col">Skills</th>
                            <th scope="col">Linked to Jobs</th>
                            <th scope="col">Added On</th>
                            <th scope="col">Status</th>
                            <th scope="col" style={{ width: "100px" }}>
                              Action
                            </th>
                          </tr>
                        </thead>}
                        <tbody>

                          {props.List && props.List.length > 0
                            && props.List.map((ele, index, list) => {
                              return (
                                <tr>
                                  <td>{currentPage == 1 ? `${index + 1}` : `${index == 9 ? currentPage : currentPage - 1}` + `${index == 9 ? 0 : index + 1}`}</td>
                                  <td>
                                    <p className="text-muted mb-0">
                                      {ele.SKILL}
                                    </p>
                                  </td>
                                  <td>0</td>
                                  <td>
                                    {moment(ele.CREATED_ON).format(
                                      "DD.MM.YYYY on HH:MM A"
                                    )}
                                  </td>

                                  <td>{
                                    ele.STATUS == 'U' ?
                                      <button type="button" className="btn btn-success btn-sm waves-effect waves-light">ACTIVE</button> : <button type="button" className="btn btn-danger btn-sm waves-effect waves-light">ARCHIVE</button>
                                  }
                                  </td>
                                  <td>
                                    <ul className="list-inline contact-links mb-0" style={{ fontSize: "18px" }}>
                                      <li className="list-inline-item px-2">
                                        <Link to={constant.component.editSkill.url.replace(':id', ele.SKILL_ID).replace(':pageno', cpage)
                                        } title="Edit Skill"><i className="bx bx-edit"></i></Link>
                                      </li>
                                      <li className="list-inline-item px-2">
                                        <a href="javascript: void(0)" onClick={() => updateStatus(ele.SKILL_ID, ele.STATUS)} style={{ color: 'red' }} title={ele.STATUS == 'U' ? "Archive Record" : "Unarchive Record"}>
                                          {
                                            ele.STATUS == 'U' ? <i className="bx bx-trash"></i> : <i class="bx bx-log-in"></i>
                                          }
                                        </a>
                                      </li>
                                    </ul>
                                  </td>
                                </tr>
                              )
                            })
                          }
                          {<>{props.List && props.List.length == 0 && props.showLoader == false && <td colSpan={10} className="text-center text-danger pt-3">No Record Found</td>}</>}
                        </tbody>
                      </table>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="pagination pagination-rounded justify-content-center mt-4">
                          <Pagination
                            activePage={qParams?.p ? +qParams?.p :
                              qParams?.pageno ? Number(qParams.pageno) : currentPage}
                            itemsCountPerPage={10}
                            totalItemsCount={props.count}
                            pageRangeDisplayed={5}
                            onChange={handlePageChange}
                            itemClass="page-item"
                            linkClass="page-link"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment >
  );
};

export default SkillList;
