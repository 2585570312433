import React from 'react'
import List from '../../../component/Utilities/Blog/CommentList'
import constant from '../../../constant'
import { useEffect, useState } from 'react'
import { commentByBlogId, getBlogCommentList, updateCommentStatus, deleteBlogComments } from '../../../actions/utilitiesAction'
import { useParams } from 'react-router-dom'
import Swal from "sweetalert2";

export default function CommentList() {
    const [list, setList] = useState([])
    const [count, setCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [showLoader, setShowLoader] = useState(false)
    const params = useParams();

    useEffect(() => {
        getBlogCommentLists(currentPage)
        document.title = constant.title.BlogComment
    }, [currentPage])


    const getBlogCommentLists = (page) => {
        if (params.id) {
            const BLOG_ID = params.id
            setShowLoader(true)
            commentByBlogId({ BLOG_ID, page }).then((res) => {
                if (res.status) {
                    setShowLoader(false)
                    setList(res.result.list)
                    setCount(res.result.count)
                }
            }).catch((err) => {
                alert(err)
            })
        }
        else {
            setShowLoader(true)
            getBlogCommentList(page).then((res) => {
                if (res.status) {
                    setShowLoader(false)
                    setList(res.result.list)
                    setCount(res.result.count)
                }
            }).catch((err) => {
                alert(err)
            })
        }


    }

    const updateStatus = (model) => {
        updateCommentStatus(model).then((res) => {
            if (res.status) {
                getBlogCommentLists(currentPage, '')
            } else {
                alert(res.error)
            }
        }).catch((err) => {
            alert(err)
        })
    }

    const deleteBlogCommentList = (COMMENT_ID) => {
        deleteBlogComments({ COMMENT_ID: COMMENT_ID }).then((res) => {
            if (res.status) {
                Swal.fire(
                    'Deleted!',
                    'Your comment has been deleted.',
                    'success'
                )
                getBlogCommentLists(currentPage)
            }
            else {
                Swal.fire(
                    (res.result.error)
                )
            }

        })
    }


    return (
        <React.Fragment>
            <List
                list={list}
                updateStatus={updateStatus}
                getBlogCommentLists={(page => getBlogCommentLists(page))}
                count={count}
                setPage={setCurrentPage}
                showLoader={showLoader}
                params={params}
                deleteBlogCommentList={deleteBlogCommentList}
            />

        </React.Fragment>
    )
}
