import React, { useState } from 'react'
import Pagination from "react-js-pagination";
import moment from 'moment';
import { Link } from 'react-router-dom';
import constant from '../../../constant';
import Loader from '../../common/Loader';


const AdminUserList = (props) => {

    const { list, count, setPage } = props
    const [currentPage, setCurrentPage] = useState(1)

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        setPage(pageNumber)
        // props.getEntityTypeList(pageNumber)
    };
    const updateStatus = (ADMIN_ID, STATUS) => {
        const model = {
            ADMIN_ID: ADMIN_ID,
            STATUS: STATUS === 'A' ? 'I' : 'A'
        }
        props.updateStatus(model)
        props.setPage(currentPage)
    }

    return (
        <React.Fragment>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 " style={{ fontSize: "18px" }}>Admin User List</h4>
                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a >Admin User</a></li>
                                            <li className="breadcrumb-item active">Admin User List</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table align-middle table-nowrap table-hover">
                                                {
                                                    props.showLoader &&
                                                    <Loader />
                                                }
                                                {!props.showLoader && <thead className="table-light">
                                                    <tr>
                                                        <th scope="col" style={{ width: '70px' }}>#</th>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">Email</th>
                                                        <th scope="col">Last Login</th>
                                                        {/* <th scope="col">Activity Log</th> */}
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>}
                                                <tbody>
                                                    {
                                                        list && list.length > 0 && list.map((item, index) => {
                                                            return (
                                                                <tr>
                                                                    <td>
                                                                        <div className="avatar-xs">
                                                                            <span className="avatar-title rounded-circle">
                                                                                {item.FIRST_NAME.charAt(0)}
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <h5 className=" mb-1" style={{ fontSize: "14px" }}><a className="text-dark">{item.FIRST_NAME} {item.LAST_NAME}</a></h5>
                                                                        <p className="text-muted mb-0">{item.ROLE_NAME} <span className={moment(item.LAST_ACTIVITY).add(5, 'minutes') > moment() ? "badge border border-light rounded-circle bg-success p-1" : "badge border border-light rounded-circle bg-danger p-1"}><span className="visually-hidden">Online</span></span></p>

                                                                    </td>
                                                                    <td>{item.EMAIL_ID}</td>
                                                                    <td>
                                                                        {item.DUMMY ? moment(item.LOGIN_TIME).format('DD.M.YYYY on hh:mm A') : 'Not Available'}
                                                                    </td>
                                                                    {/* <td>
                                                                        125
                                                                    </td> */}
                                                                    <td>
                                                                        {
                                                                            item.STATUS == 'A' ?
                                                                                <button type="button" className="btn btn-success btn-sm waves-effect waves-light">ACTIVE</button> : <button type="button" className="btn btn-danger btn-sm waves-effect waves-light">ARCHIVE</button>
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        <ul className="list-inline contact-links mb-0" style={{ fontSize: "20px" }}>
                                                                            <li className="list-inline-item px-2">
                                                                                <Link to={constant.component.editAdminUser.url.replace(':id', item.ADMIN_ID)} title="Edit User"><i className="bx bx-edit"></i></Link>
                                                                            </li>

                                                                            <li className="list-inline-item px-2">
                                                                                <a style={{ color: "red" }} onClick={() => updateStatus(item.ADMIN_ID, item.STATUS)} title={item.STATUS === 'A' ? "Delete User" : 'Active User'}><i className={item.STATUS === 'I' ? "bx bx-log-in" : "bx bx-trash"}></i></a>
                                                                            </li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        <>
                                                            {
                                                                props.list && props.list.length == 0 && props.showLoader == false &&
                                                                <td colSpan={10} className="text-center text-danger pt-3">No Record Found</td>
                                                            }
                                                        </>
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="pagination pagination-rounded justify-content-center mt-4">
                                                    <Pagination
                                                        activePage={currentPage}
                                                        itemsCountPerPage={10}
                                                        totalItemsCount={count}
                                                        pageRangeDisplayed={5}
                                                        onChange={handlePageChange}
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default AdminUserList;