import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { getStorage } from "./utils";
import Statisics from "./pages/Statisics/Statisics";
import Sidebar from "./component/common/Sidebar";
import constant from "./constant";
import Login from "./pages/auth/Login";
import Header from "./component/common/Header";
import Footer from "./component/common/Footer";
import Dashboard from "./pages/Menu/Dashboard";
import Logout from "./component/common/Logout";
import AddSkills from "./pages/Utilities/Skills/AddSkills";
import SkillList from "./pages/Utilities/Skills/SkillList";
import FourZeroFour from "./component/common/FourZeroFour";
import AddDesignation from "./pages/Utilities/Designation/AddDesignation";
import DesignationList from "./pages/Utilities/Designation/DesignationList";
import AddJobCategory from "./pages/Utilities/JobCategory/AddJobCategory";
import JobCategoryList from "./pages/Utilities/JobCategory/JobCategoryList";
import AddSalary from "./pages/Utilities/Salary/AddSalary";
import SalaryList from "./pages/Utilities/Salary/SalaryList";
import AddJobType from "./pages/Utilities/JobType/AddJobType";
import JobTypeList from "./pages/Utilities/JobType/JobTypeList";
import AddGovtDepartment from "./pages/Utilities/GovtDepartment/AddGovtDepartment";
import GovtJobsList from "./pages/Utilities/GovernmentJobs/GovtJobsList";
import GovtDepartmentList from "./pages/Utilities/GovtDepartment/GovtDepartmentList";
import AddAdminUser from "./pages/Administration/AddAdminUser";
import AdminUserList from "./pages/Administration/AdminUserList";
import AddAdminRole from "./pages/Administration/AddAdminRole";
import AdminRoleList from "./pages/Administration/AdminRoleList";
// import AdminUserLog from "./pages/Administration/AdminUserLog";
import TotalCandidates from "./pages/Menu/TotalCandidates";
import CandidatesProfile from "./pages/Menu/CandidatesProfile";
import EmployersReview from "./pages/Employers/EmployersReview";
import JobApplied from "./pages/Menu/JobApplied";
import CandidatesInformation from "./component/Menu/CandidatesInformation";
import TotalEmployers from "./pages/Menu/TotalEmployers";
import TotalJobs from "./pages/Menu/TotalJobs";
import AddCategory from "./pages/Utilities/Blog/AddCategory";
import CategoryList from "./pages/Utilities/Blog/CategoryList";
import AddBlog from "./pages/Utilities/Blog/AddBlog";
import BlogList from "./pages/Utilities/Blog/BlogList";
import AddEmployers from "./pages/Employers/AddEmployers";
import AddCompanyType from "./pages/Employers/AddCompanyType";
import CompanyTypeList from "./pages/Employers/CompanyTypeList";
import AddIndustry from "./pages/Employers/AddIndustry";
import IndustryList from "./pages/Employers/IndustryList";
import EmployersList from "./pages/Employers/EmployersList";
import AddEmployeeBenefit from "./pages/Employers/AddEmployeeBenefit";
import EmployeeBenefitsList from "./pages/Employers/EmployeeBenefitsList";
import AddEntityType from "./pages/Employers/AddEntityType";
import EntityTypeList from "./pages/Employers/EntityTypeList";
import AddAddressProofType from "./pages/Employers/AddAddressProofType";
import AddressProofTypeList from "./pages/Employers/AddressProofTypeList";
import FAQAddCategory from "./pages/Utilities/FAQs/AddCategory";
import FAQEditCategory from "./pages/Utilities/FAQs/AddCategory";
import FAQListCategory from "./pages/Utilities/FAQs/CategoryList";
import FAQAdd from "./pages/Utilities/FAQs/AddFAQ";
import FAQEdit from "./pages/Utilities/FAQs/AddFAQ";
import FAQList from "./pages/Utilities/FAQs/FAQList";

import JObsFAQAddCategory from "./pages/Utilities/JobsFAQs/AddCategory";
import JObsFAQEditCategory from "./pages/Utilities/JobsFAQs/AddCategory";
import JObsFAQListCategory from "./pages/Utilities/JobsFAQs/CategoryList";
import JObsFAQAdd from "./pages/Utilities/JobsFAQs/AddFAQ";
import JObsFAQEdit from "./pages/Utilities/JobsFAQs/AddFAQ";
import JObsFAQList from "./pages/Utilities/JobsFAQs/FAQList";
import AddQualification from "./pages/Jobs/AddQualification";
import EditQualification from "./pages/Jobs/EditQualification";
import QualificationList from "./pages/Jobs/QualificationList";
import AddQuestion from "./pages/Jobs/AddQuestion";
import QuestionList from "./pages/Jobs/QuestionList";
import EditQuestion from "./pages/Jobs/EditQuestion";
import AddRole from "./pages/Jobs/AddRole";
import EditRole from "./pages/Jobs/EditRole";
import RoleList from "./pages/Jobs/RoleList";
import FunctionalAreaList from "./pages/Jobs/FunctionalAreaLists";
import AddFunctionalArea from "./pages/Jobs/AddFunctionalArea";
import EditFunctionalArea from "./pages/Jobs/EditFunctionalArea";
import AddJobIndustry from "./pages/Jobs/AddIndustry";
import EditJobIndustry from "./pages/Jobs/EditIndustry";
import ListJobIndustry from "./pages/Jobs/ListIndustry";
import AddEmployementType from "./pages/Jobs/AddEmpType";
import EditEmployementType from "./pages/Jobs/EditEmpType";
import EmployementTypeList from "./pages/Jobs/ListEmpType";
import AddJob from "./pages/Jobs/AddJobs";
import EditJob from "./pages/Jobs/EditJob";
import ListJob from "./pages/Jobs/ListJobs";
import ScrappedJobs from "./pages/Jobs/ScrappedJobs";

import AddCourseSpecz from "./pages/Jobs/AddCourseSpecz";
import ListCourseSpecz from "./pages/Jobs/ListCourseSpecz";
import EditCourseSpecz from "./pages/Jobs/EditCourseSpecz";
import EditCompanyType from './pages/Employers/EditCompanyType';
import EditIndustsry from './pages/Employers/EditIndustry';
import EditEntityType from './pages/Employers/EditEntitytype';
import EditEmployeeBenefit from './pages/Employers/EditEmployeeBenefitType';
import EditAddressProofType from './pages/Employers/EditAddressProofType';
import EditSkills from './pages/Utilities/Skills/editSkill';
import EditDesgnation from './pages/Utilities/Designation/EditDesignation';
import EditSalary from './pages/Utilities/Salary/EditSalary';
import ResetPassword from "./pages/auth/resetPassword";
import EditJobCategory from './pages/Utilities/JobCategory/EditJobCategory';
import EditJobType from './pages/Utilities/JobType/EditJobType';
import EditGovtDepartment from './pages/Utilities/GovtDepartment/EditGovtDepartment';
import ForgotPassword from './pages/auth/forgotPassword';
import CandidateList from "./pages/Candidates/CandidateList";
import AddArticle from "./pages/Utilities/Article/AddArticle";
import ArticleList from "./pages/Utilities/Article/ArticleList";
import AddArticleCategory from './pages/Utilities/Article/AddArticleCategory';
import ArticleCategoryList from "./pages/Utilities/Article/ArticleCategoryList";
import EditArticleCategory from "./pages/Utilities/Article/EditArticleCategory";
import Profile from "./pages/Menu/Profile";
import UpdateProfile from "./pages/Menu/UpdateProfile";
import LockScreen from "./pages/Menu/LockScreen";
import EditBlogCategory from "./pages/Utilities/Blog/EditBlogCategory";
import { Layout } from "./Layout";
import EditEmployer from './pages/Employers/EditEmployer';
import AddModule from './pages/Administration/AddModules';
import EditAdminUser from './pages/Administration/EditAdminUser';
import EditAdminRoleList from './pages/Administration/EditAdminRoleList';
import ModulesList from "./pages/Administration/ModulesList";
import ArchiveList from "./pages/Employers/ArchiveList";
import FeaturedList from "./pages/Employers/FeaturedList";
import PremiumList from "./pages/Employers/PremiumList";
import AddTestimonial from "./pages/Utilities/Testimonial/AddTestimonial";
import ListTestimonial from "./pages/Utilities/Testimonial/ListTestimonial";
import EditTestimonial from "./pages/Utilities/Testimonial/EditTestimonial";
import AddInterviewQuestion from './pages/Utilities/InterviewQuestions/AddInterviewQuestions';
import InterviewQuestionList from './pages/Utilities/InterviewQuestions/InterviewQuestionsList';
import AddPracticeQuestion from './pages/Utilities/PracticeQuestion/AddPracticeQuestion';
import PracticeQuestionList from './pages/Utilities/PracticeQuestion/PracticeQuestionList';
import AddCmsPage from './pages/Components/CmsPage/AddPage';
import CmsPageList from './pages/Components/CmsPage/PageList';
import EditCms from "./pages/Components/CmsPage/EditCms";
import AddDownloads from './pages/Components/Downloads/AddDownloads';
import DownloadList from './pages/Components/Downloads/DownloadList';
import EditDownload from './pages/Components/Downloads/EditDownload';
import AddDownloadCategory from './pages/Components/Downloads/AddDownloadCategory';
import DownloadCategoryList from './pages/Components/Downloads/DownloadCategoryList';
import EditDownloadCategory from "./pages/Components/Downloads/EditDownloadCategory";
import AddSubCategory from './pages/Components/Downloads/AddSubCategory';
import AddSubcategoryList from './pages/Components/Downloads/AddSubcategoryList';
import EditSubCategoryDownload from './pages/Components/Downloads/EditSubCategory';
import AddNews from './pages/Components/NewsAndUpdate/AddNews';
import NewsList from './pages/Components/NewsAndUpdate/ListNews';
import EditNews from './pages/Components/NewsAndUpdate/EditNews';
import AddPressRelase from './pages/Components/PressRelease/AddPressRelease';
import PressReleaseList from './pages/Components/PressRelease/ListPressRelease';
import EditPressRelease from "./pages/Components/PressRelease/EditPressRelease";
import AddCity from "./pages/Utilities/CountryStatesAndCity/AddCity";
import City from './pages/Utilities/CountryStatesAndCity/City';
import Country from './pages/Utilities/CountryStatesAndCity/Country';
import Locality from './pages/Utilities/CountryStatesAndCity/Localities';
import AddLocalities from "./pages/Utilities/CountryStatesAndCity/AddLocalities";
import PremiumCities from './pages/Utilities/CountryStatesAndCity/PremiumCities';
import States from './pages/Utilities/CountryStatesAndCity/States'
import EditBlog from './pages/Utilities/Blog/EditBlogList'
import EditArticle from './pages/Utilities/Article/EditArticleList'
import CommentList from './pages/Utilities/Blog/CommentList'
import EnquiryList from './pages/Enquiry/EnquiryList'
import ComplaintList from "./pages/Enquiry/ComplaintList";
import UnderMaintainanceEnquiryList from "./pages/Enquiry/UnderMaintainanceEnquiryList";
import EditPracticeQuestionList from './pages/Utilities/PracticeQuestion/EditPractiseQuestionList'
import InterviewAnswerList from './pages/Utilities/InterviewQuestions/InterviewAnswer'
import EditInterviewQuestion from "./pages/Utilities/InterviewQuestions/EditInterviewQuestion";
import SalesEnqueryList from './pages/Enquiry/salesEnquiryList'
import ReportIssueList from './pages/Enquiry/ReportIssueList'
import JobPostList from './pages/Jobs/PostJobRequest'
import PendingJobPostList from './pages/Jobs/PendingJobRequest'

import EpmloyerKYCDetail from "./component/Employers/EpmloyerKYC";

import DiscussionForum from './pages/Utilities/DisscussionForm/AddFormCategoryList'
import DiscussionforumList from "./pages/Utilities/DisscussionForm/forumCategorylist";
import ForumList from "./component/Utilities/DiscussionForm/editForumList";
import RecruiterEnquiryList from "./pages/Enquiry/RecruiterEnquiryList";
import Discussionforumactivethread from "./pages/Utilities/DisscussionForm/Discussionforumactivethread";
import EditDiscussionThread from "./pages/Utilities/DisscussionForm/EditDiscussionThread";
import AddSubscriptionPackage from "./pages/SubscriptionManagement/AddSubscriptionPackage";
import SubscriptionPackageList from "./pages/SubscriptionManagement/SubscriptionPackageList";
import UpdateSubscriptionPackage from "./pages/SubscriptionManagement/UpdateSubscriptionPackage";
import SubscriptionPackage from "./pages/SubscriptionPackageDetails/SubscriptionPackage";
import JobAlert from "./pages/JobAlert/jobAlert";

import ShareInterviewQuestionList from './pages/Utilities/ShareInterviewQuestions/InterviewQuestionsList';
import ShareInterviewAnswerList from './pages/Utilities/ShareInterviewQuestions/InterviewAnswer'

import AppliedJobs from "./pages/JobAlert/AppliedJobs"
import EditShareInterviewQuestion from "./pages/Utilities/ShareInterviewQuestions/EditShareInterviewQuestion";
import PremiumJobList from "./pages/PremiumJobs/premiumJobList";
import FeaturedjobList from "./pages/Featuredjob/FeaturedjobList";
import EmployerSubscription from "./pages/Employers/EmployerSubscription";
import JobPostingList from './pages/JobPostingPackage/jobPostingList';
import EmpPremiumJob from "./pages/Employers/EmpPremiumJobs";
import EmpFeaturedJob from "./pages/Employers/EmpFeaturedJobs";
import EmployerJobPosted from "./pages/Employers/EmpJobsPosted";
import AddGovtJobs from "./pages/Utilities/GovernmentJobs/AddGovtJobs";
import EditGovtJob from "./pages/Utilities/GovernmentJobs/EditGovtJobs";
import AddBanner from "./pages/Banner/AddBanner";
import BannerList from "./pages/Banner/BannerList";
import EditBanner from "./pages/Banner/EditBanner";
import CandidateAppliedJobs from "./pages/Jobs/CandidateAppliedJobs";
import UpdateEmployerIndustry from "./pages/Employers/UpdateEmployerIndustry";
import AddGovtResult from "./pages/Utilities/GovernmentResult/AddGovtResult";
import GovtJobsResult from "./pages/Utilities/GovernmentResult/GovtResultList";
import EditGovtResult from "./pages/Utilities/GovernmentResult/EditGovtResult";
import AddThirdPartyBanner from "./pages/Banner/AddThirdPartyBanner";
import AddAffiliate from "./pages/Affiliate/AddAffiliate";
import BannerAnalytics from "./pages/Banner/BannerAnalytics";
import AffilateList from "./pages/Affiliate/AffilateList";
import EditAffiliate from "./pages/Affiliate/EditAffiliate";
import AffiliateAnalytics from "./pages/Affiliate/AffiliateAnalytics";
import AddUniversity from "./pages/campus/university/addUniversity";
import UniversityList from "./pages/campus/university/universityList";
import EditUniversity from "./pages/campus/university/editUniversity";
import UpdateLatestNews from "./pages/campus/university/UpdateLatestNews";
import AddCampusAnnouncement from "./pages/campus/university/addAnnouncement";
import ListCampusAnnouncement from './pages/campus/university/listAnnouncement';
import CollegeList from './pages/campus/college/collegeList';
import CollegeStudentList from './pages/campus/student/collegeStudentList';
import CampusStudentDetail from './pages/campus/student/StudentDetail'
import OffCampusStudentList from './pages/campus/student/offCampusStudents';
import CollegeDetail from './pages/campus/college/collegeDetail';
import EditCollegeDetail from './pages/campus/college/EditCollegeDetail';








const CustomRoute = () => {

    const token = getStorage(constant.keys.token);
    const ud = getStorage(constant.keys.userDetails)
    return (
        <>
            <Layout>


                <Routes >


                    {ud && !token && (
                        <React.Fragment>

                            <Route path={constant.component.lockScreen.url} element={<LockScreen />} />

                            <Route
                                path={constant.component.fourzerofour.url}

                                element={<FourZeroFour />}
                            />


                        </React.Fragment>
                    )}

                    {!ud && !token && (
                        <React.Fragment>

                            <Route
                                path={constant.component.login.url}
                                element={<Login />} />
                            <Route
                                path={constant.component.resetPassword.url}
                                element={<ResetPassword />}
                            />

                            <Route
                                path={constant.component.forgotPassword.url}
                                element={<ForgotPassword />}
                            />
                            <Route
                                path={constant.component.home.url}
                                element={<Navigate to={constant.component.login.url} replace />}
                            />
                            <Route
                                path={constant.component.fourzerofour.url}
                                element={<FourZeroFour />}
                            />
                        </React.Fragment>
                    )}

                    {ud && token && (
                        <React.Fragment>
                            <Route
                                path={constant.component.updateLatestNews.url}
                                element={<UpdateLatestNews />}
                            />
                            <Route
                                path={constant.component.addNewCampusAnnouncement.url}
                                element={<AddCampusAnnouncement />}
                            />
                            <Route
                                path={constant.component.listCampusAnnouncement.url}
                                element={<ListCampusAnnouncement />}
                            />

                            <Route
                                path={constant.component.addUniversity.url}
                                element={<AddUniversity />}
                            />
                            <Route
                                path={constant.component.universityList.url}
                                element={<UniversityList />}
                            />
                            <Route
                                path={constant.component.editUniversity.url}
                                element={<EditUniversity />}
                            />
                            <Route
                                path={constant.component.collegeList.url}
                                element={<CollegeList />}
                            />
                            <Route
                                path={constant.component.collegeDetail.url}
                                element={<CollegeDetail />}
                            />

                            <Route
                                path={constant.component.editCollegeDetail.url}
                                element={<EditCollegeDetail />}
                            />

                            <Route
                                path={constant.component.collegeStudentList.url}
                                element={<CollegeStudentList />}
                            />
                            <Route
                                path={constant.component.CampusStudentDetil.url}
                                element={<CampusStudentDetail />}
                            />
                            <Route
                                path={constant.component.offCampusStudentList.url}
                                element={<OffCampusStudentList />}
                            />


                            {/* <Route
                                path={constant.component.loanApplications.url}
                                element={<LoanApplication />}
                            /> */}
                            {/* <Route
                                path={constant.component.pushNotification.url}
                                element={<Broadcast />}
                            />
                            <Route
                                path={constant.component.pushNotificationList.url}
                                element={<BroadcastList />}
                            /> */}

                            <Route
                                path={constant.component.dashboard.url}
                                element={<Dashboard />}
                            />
                            {/* <Route
                                path={constant.component.DiscountandCoupons.url}
                                element={<DiscountandCoupons />}
                            /> */}
                            <Route path={constant.component.logout.url} element={<Logout />} />
                            <Route
                                path={constant.component.addskills.url}
                                element={<AddSkills />}
                            />
                            <Route
                                path={constant.component.editSkill.url}
                                element={<EditSkills />}
                            />
                            <Route
                                path={constant.component.skilllist.url}
                                element={<SkillList />}
                            />
                            <Route
                                path={constant.component.adddesignation.url}
                                element={<AddDesignation />}
                            />
                            <Route
                                path={constant.component.editDesignation.url}
                                element={<EditDesgnation />}
                            />

                            <Route
                                path={constant.component.designationlist.url}
                                element={<DesignationList />}
                            />
                            <Route
                                path={constant.component.addsalary.url}
                                element={<AddSalary />}
                            />
                            <Route
                                path={constant.component.editSalary.url}
                                element={<EditSalary />}
                            />

                            <Route
                                path={constant.component.salarylist.url}
                                element={<SalaryList />}
                            />
                            <Route
                                path={constant.component.addjobcategory.url}
                                element={<AddJobCategory />}
                            />
                            {/* <Route
                                path={constant.component.DiscountandCouponsList.url}
                                element={<DiscountandCouponsList />}
                            /> */}
                            {/* <Route
                                path={constant.component.EditDiscountandCoupons.url}
                                element={<EditDiscountandCoupons />}
                            /> */}
                            <Route
                                path={constant.component.editjobcategory.url}
                                element={<EditJobCategory />}
                            />
                            <Route
                                path={constant.component.jobcategorylist.url}
                                element={<JobCategoryList />}
                            />
                            <Route
                                path={constant.component.addjobtype.url}
                                element={<AddJobType />}
                            />
                            <Route
                                path={constant.component.editjobtype.url}
                                element={<EditJobType />}
                            />
                            <Route
                                path={constant.component.jobtypelist.url}
                                element={<JobTypeList />}
                            />
                            <Route
                                path={constant.component.addgovtresult.url}
                                element={<AddGovtResult />}
                            />
                            <Route
                                path={constant.component.govtjobsresult.url}
                                element={<GovtJobsResult />}
                            />
                            <Route
                                path={constant.component.editgovtresult.url}
                                element={<EditGovtResult />}
                            />
                            <Route
                                path={constant.component.addgovtdepartment.url}
                                element={<AddGovtDepartment />}
                            />
                            <Route
                                path={constant.component.addgovtjobs.url}
                                element={<AddGovtJobs />}
                            />
                            <Route
                                path={constant.component.editgovtdepartment.url}
                                element={<EditGovtDepartment />}
                            />
                            <Route
                                path={constant.component.editgovtjobs.url}
                                element={<EditGovtJob />}
                            />
                            <Route
                                path={constant.component.govtdepartmentlist.url}
                                element={<GovtDepartmentList />}
                            />
                            <Route
                                path={constant.component.govtjobslist.url}
                                element={<GovtJobsList />}
                            />
                            <Route
                                path={constant.component.addadminuser.url}
                                element={<AddAdminUser />}
                            />
                            <Route
                                path={constant.component.editAdminUser.url}
                                element={<EditAdminUser />}
                            />
                            <Route
                                path={constant.component.adminuserlist.url}
                                element={<AdminUserList />}
                            />
                            <Route
                                path={constant.component.addadminrole.url}
                                element={<AddAdminRole />}
                            />
                            <Route
                                path={constant.component.adminrolelist.url}
                                element={<AdminRoleList />}
                            />
                            {/* <Route
                                path={constant.component.adminuserlog.url}
                                element={<AdminUserLog />}
                            /> */}
                            {/* <Route
                                path={constant.component.addmodule.url}
                                element={< AddModule />}
                            /> */}
                            <Route
                                path={constant.component.totalcandidates.url}
                                element={<TotalCandidates />}
                            />
                            <Route
                                path={constant.component.CandidatesProfile.url} element={<CandidatesProfile />}
                            />
                            {/* <Route
                                path={constant.component.EmployersReview.url} element={<EmployersReview />}
                            /> */}

                            <Route
                                path={constant.component.EmployerSubscription.url} element={<EmployerSubscription />}
                            />
                            <Route
                                path={constant.component.CandidatesInformaton.url} element={<CandidatesInformation />}
                            />
                            <Route
                                path={constant.component.JobApplied.url} element={<JobApplied />}
                            />
                            <Route
                                path={constant.component.totalemployers.url}
                                element={<TotalEmployers />}
                            />
                            <Route
                                path={constant.component.totaljobs.url}
                                element={<TotalJobs />}
                            />
                            <Route
                                path={constant.component.addblogcategory.url}
                                element={<AddCategory />}
                            />
                            <Route
                                path={constant.component.editblogcategory.url}
                                element={<EditBlogCategory />}
                            />
                            <Route
                                path={constant.component.blogcategorylist.url}
                                element={<CategoryList />}
                            />
                            <Route
                                path={constant.component.addblog.url}
                                element={<AddBlog />}
                            />
                            <Route
                                path={constant.component.bloglist.url}
                                element={<BlogList />}
                            />
                            <Route
                                path={constant.component.addemployers.url}
                                element={<AddEmployers />}
                            />
                            <Route
                                path={constant.component.editemployers.url}
                                element={<EditEmployer />}
                            />

                            <Route
                                path={constant.component.addcompanytype.url}
                                element={<AddCompanyType />}
                            />
                            <Route
                                path={constant.component.editcompanytype.url}
                                element={<EditCompanyType />}
                            />
                            <Route
                                path={constant.component.companytypelist.url}
                                element={<CompanyTypeList />}
                            />
                            <Route
                                path={constant.component.addindustry.url}
                                element={<AddIndustry />}
                            />
                            {/* <Route
                                path={constant.component.updateIndustry.url}
                                element={<UpdateEmployerIndustry />}
                            /> */}
                            <Route
                                path={constant.component.industrylist.url}
                                element={<IndustryList />}
                            />
                            <Route
                                path={constant.component.editindustry.url}
                                element={<EditIndustsry />}
                            />

                            <Route
                                path={constant.component.employerslist.url}
                                element={<EmployersList />}
                            />
                            <Route
                                path={constant.component.addemployeebenefits.url}
                                element={<AddEmployeeBenefit />}
                            />
                            <Route
                                path={constant.component.editEmployeeBenefitTytpe.url}
                                element={<EditEmployeeBenefit />}
                            />
                            <Route
                                path={constant.component.employeebenefitslist.url}
                                element={<EmployeeBenefitsList />}
                            />
                            <Route
                                path={constant.component.addentitytype.url}
                                element={<AddEntityType />}
                            />
                            <Route
                                path={constant.component.editentitytype.url}
                                element={<EditEntityType />}
                            />

                            <Route
                                path={constant.component.entitytypelist.url}
                                element={<EntityTypeList />}
                            />
                            <Route
                                path={constant.component.addaddressprooftype.url}
                                element={<AddAddressProofType />}
                            />
                            <Route
                                path={constant.component.editaddressprooftype.url}
                                element={<EditAddressProofType />}
                            />

                            <Route
                                path={constant.component.addressprooftypelist.url}
                                element={<AddressProofTypeList />}
                            />
                            <Route
                                path={constant.component.faqaddcategory.url}
                                element={<FAQAddCategory />}
                            />
                            <Route
                                path={constant.component.faqeditcategory.url}
                                element={<FAQEditCategory />}
                            />
                            <Route
                                path={constant.component.faqlistcategory.url}
                                element={<FAQListCategory />}
                            />
                            <Route
                                path={constant.component.faqadd.url}
                                element={<FAQAdd />}
                            />
                            <Route
                                path={constant.component.faqedit.url}
                                element={<FAQEdit />}
                            />
                            <Route
                                path={constant.component.faqlist.url}
                                element={<FAQList />}
                            />
                            

                            <Route
                                path={constant.component.jobsfaqaddcategory.url}
                                element={<JObsFAQAddCategory />}
                            />
                            <Route
                                path={constant.component.jobsfaqeditcategory.url}
                                element={<JObsFAQEditCategory />}
                            />
                            <Route
                                path={constant.component.jobsfaqlistcategory.url}
                                element={<JObsFAQListCategory />}
                            />
                            <Route
                                path={constant.component.jobsfaqadd.url}
                                element={<JObsFAQAdd />}
                            />
                            <Route
                                path={constant.component.jobsfaqedit.url}
                                element={<JObsFAQEdit />}
                            />
                            <Route
                                path={constant.component.jobsfaqlist.url}
                                element={<JObsFAQList />}
                            />
                            <Route
                                path={constant.component.addQualification.url}
                                element={<AddQualification />}
                            />
                            <Route
                                path={constant.component.listQualification.url}
                                element={<QualificationList />}
                            />
                            <Route
                                path={constant.component.editQualification.url}
                                element={<EditQualification />}
                            />
                            <Route
                                path={constant.component.addJobQuest.url}
                                element={<AddQuestion />}
                            />
                            <Route
                                path={constant.component.listJobQuest.url}
                                element={<QuestionList />}
                            />
                            <Route
                                path={constant.component.editJobQuest.url}
                                element={<EditQuestion />}
                            />
                            <Route
                                path={constant.component.addRole.url}
                                element={<AddRole />}
                            />
                            <Route
                                path={constant.component.listRole.url}
                                element={<RoleList />}
                            />
                            <Route
                                path={constant.component.editRole.url}
                                element={<EditRole />}
                            />
                            <Route
                                path={constant.component.addFunctionalArea.url}
                                element={<AddFunctionalArea />}
                            />
                            <Route
                                path={constant.component.listFunctionalArea.url}
                                element={<FunctionalAreaList />}
                            />
                            <Route
                                path={constant.component.editFunctionalArea.url}
                                element={<EditFunctionalArea />}
                            />
                            <Route
                                path={constant.component.addIndustry.url}
                                element={<AddJobIndustry />}
                            />
                            <Route
                                path={constant.component.listIndustry.url}
                                element={<ListJobIndustry />}
                            />
                            <Route
                                path={constant.component.editIndustry.url}
                                element={<EditJobIndustry />}
                            />
                            <Route
                                path={constant.component.addEmployementType.url}
                                element={<AddEmployementType />}
                            />
                            <Route
                                path={constant.component.listEmployementType.url}
                                element={<EmployementTypeList />}
                            />
                            <Route
                                path={constant.component.editEmployementType.url}
                                element={<EditEmployementType />}
                            />
                            <Route
                                path={constant.component.addJobs.url}
                                element={<AddJob />}
                            />
                            <Route
                                path={constant.component.listJobs.url}
                                element={<ListJob />}
                            />

                            {/* <Route
                                exact
                                path={constant.component.scrappedJobs.url}
                                element={<ScrappedJobs />}
                            /> */}


                            <Route
                                path={constant.component.candidateslist.url}
                                element={<CandidateList />}
                            />
                            <Route
                                path={constant.component.addArticle.url}
                                element={<AddArticle />}
                            />
                            <Route
                                path={constant.component.listArticle.url}
                                element={<ArticleList />}
                            />
                            <Route
                                path={constant.component.articleAddCategory.url}
                                element={<AddArticleCategory />}
                            />
                            <Route
                                path={constant.component.articleCategoryList.url}
                                element={<ArticleCategoryList />}
                            />
                            <Route
                                path={constant.component.editArticleCategory.url}
                                element={<EditArticleCategory />}
                            />

                            <Route
                                path={constant.component.profile.url}
                                element={<Profile />}
                            />

                            <Route
                                path={constant.component.updateProfile.url}
                                element={<UpdateProfile />}
                            />
                            <Route
                                path={constant.component.editadminrolelist.url}
                                element={<EditAdminRoleList />}
                            />
                            <Route
                                path={constant.component.modulelist.url}
                                element={<ModulesList />}
                            />
                            <Route
                                path={constant.component.archiveEmployerList.url}
                                element={<ArchiveList />}
                            />
                            <Route
                                path={constant.component.featuredEmployerList.url}
                                element={<FeaturedList />}
                            />
                            <Route
                                path={constant.component.premiumEmployerList.url}
                                element={<PremiumList />}
                            />
                            <Route
                                path={constant.component.fourzerofour.url}
                                element={<FourZeroFour />}
                            />
                            {/* <Route
                                path={constant.component.addTestimonial.url}
                                element={<AddTestimonial />}
                            />
                            <Route
                                path={constant.component.testimonialList.url}
                                element={<ListTestimonial />}
                            />
                            <Route
                                path={constant.component.editTestimonial.url}
                                element={<EditTestimonial />}
                            /> */}
                            <Route
                                path={constant.component.addInterviewQuestion.url}
                                element={<AddInterviewQuestion />}
                            />
                            <Route
                                path={constant.component.interviewQuestionList.url}
                                element={<InterviewQuestionList />}
                            />
                            <Route
                                path={constant.component.addPracticeQuestion.url}
                                element={<AddPracticeQuestion />}
                            />
                            <Route
                                path={constant.component.practiceQuestionList.url}
                                element={<PracticeQuestionList />}
                            />
                            {/* <Route
                                path={constant.component.addCmsPage.url}
                                element={<AddCmsPage />}
                            />
                            <Route
                                path={constant.component.cmsPageList.url}
                                element={<CmsPageList />}
                            />
                            <Route
                                path={constant.component.editCms.url}
                                element={<EditCms />}
                            /> */}
                            {/* <Route
                                path={constant.component.addDownloads.url}
                                element={<AddDownloads />}
                            /> */}
                            {/* <Route
                                path={constant.component.downloadList.url}
                                element={<DownloadList />}
                            />
                            <Route
                                path={constant.component.Editdownload.url}
                                element={<EditDownload />}
                            /> */}


                            {/* <Route
                                path={constant.component.adddownloadcategory.url}
                                element={<AddDownloadCategory />}
                            />
                            <Route
                                path={constant.component.downloadcategorylist.url}
                                element={<DownloadCategoryList />}
                            />

                            <Route
                                path={constant.component.editDownloadCategory.url}
                                element={<EditDownloadCategory />}
                            />
                            <Route
                                path={constant.component.addsubcategory.url}
                                element={<AddSubCategory />}
                            />
                            <Route
                                path={constant.component.addsubcategorylist.url}
                                element={<AddSubcategoryList />}
                            />

                            <Route
                                path={constant.component.editsubcategory.url}
                                element={<EditSubCategoryDownload />}

                            /> */}
                            {/* <Route
                                path={constant.component.addNews.url}
                                element={<AddNews />}
                            />
                            <Route
                                path={constant.component.newsList.url}
                                element={<NewsList />}
                            />
                            <Route
                                path={constant.component.newsedit.url}
                                element={<EditNews />}
                            /> */}
                            {/* <Route
                                path={constant.component.addPressRelease.url}
                                element={<AddPressRelase />}
                            />
                            <Route
                                path={constant.component.pressReleaseList.url}
                                element={<PressReleaseList />}
                            />
                            <Route
                                path={constant.component.editPressRelease.url}
                                element={<EditPressRelease />}
                            /> */}
                            <Route
                                path={constant.component.cityList.url}
                                element={<City />}
                            />

                            <Route
                                path={constant.component.addCity.url}
                                element={<AddCity />}
                            />
                            <Route
                                path={constant.component.countryList.url}
                                element={<Country />}
                            />

                            <Route
                                path={constant.component.localityList.url}
                                element={<Locality />}
                            />

                            <Route
                                path={constant.component.addLocality.url}
                                element={<AddLocalities />}
                            />
                            <Route
                                path={constant.component.stateList.url}
                                element={<States />}
                            />
                            <Route
                                path={constant.component.premiumCityList.url}
                                element={<PremiumCities />}
                            />
                            <Route
                                path={constant.component.editblog.url}
                                element={<EditBlog />}
                            />
                            <Route
                                path={constant.component.editarticle.url}
                                element={<EditArticle />}
                            />
                            <Route
                                path={constant.component.allCommentList.url}
                                element={<CommentList />}
                            />
                            <Route
                                path={constant.component.blogCommentList.url}
                                element={<CommentList />}
                            />
                            <Route
                                path={constant.component.enquiryList.url}
                                element={<EnquiryList />}
                            />
                            {/* <Route
                                path={constant.component.OutplacementList.url}
                                element={<OutplacementList />}
                            /> */}

                            <Route
                                path={constant.component.ComplaintList.url}
                                element={<ComplaintList />}
                            />
                            <Route
                                path={constant.component.UnderMaintainanceEnquiryList.url}
                                element={<UnderMaintainanceEnquiryList />}
                            />
                            <Route
                                path={constant.component.editPracticequestion.url}
                                element={<EditPracticeQuestionList />}
                            />
                            <Route
                                path={constant.component.interviewAnswer.url}
                                element={<InterviewAnswerList />}
                            />
                            <Route
                                path={constant.component.editInterviewQuestion.url}
                                element={<EditInterviewQuestion />}
                            />


                            <Route
                                path={constant.component.salesEnqueryList.url}
                                element={<SalesEnqueryList />}
                            />


                            <Route
                                path={constant.component.ReportIssueList.url}
                                element={<ReportIssueList />}
                            />
                            <Route
                                path={constant.component.employerKYC.url}
                                element={<EpmloyerKYCDetail />}
                            />


                            <Route
                                path={constant.component.JobPostRequestList.url}
                                element={<JobPostList />}
                            />

                            <Route path={constant.component.Statisics.url}
                                element={<Statisics />} />



                            <Route
                                path={constant.component.PendingJobPostRequestList.url}
                                element={<PendingJobPostList />}
                            />


                            <Route
                                path={constant.component.Discussionforum.url}
                                element={<DiscussionForum />}
                            />

                            <Route
                                path={constant.component.Discussionforumactivethread.url}
                                element={<Discussionforumactivethread />}
                            />



                            {/* <Route
                                path={constant.component.Discussionforum.url}
                                element={<DiscussionForum />}
                            /> */}


                            {/* <Route
                                path={constant.component.DiscussionforumList.url}
                                element={<ForumCategoryList />}
                            /> */}

                            <Route
                                path={constant.component.DiscussionforumList.url}
                                element={<DiscussionforumList />}
                            />

                            <Route
                                path={constant.component.EditDiscussionThread.url}
                                element={<EditDiscussionThread />}
                            />



                            {/* <DiscussionforumList /> */}



                            <Route
                                path={constant.component.editDiscussionForm.url}
                                element={<ForumList />}
                            />
                            <Route
                                path={constant.component.recruiterEnquiryList.url}
                                element={<RecruiterEnquiryList />}
                            />

                            <Route
                                path={constant.component.addSubscription.url}
                                element={<AddSubscriptionPackage />}
                            />

                            <Route
                                path={constant.component.SubscriptionList.url}
                                element={<SubscriptionPackageList />}
                            />



                            <Route
                                path={constant.component.editSubscriptionList.url}
                                element={<UpdateSubscriptionPackage />}
                            />

                            <Route
                                path={constant.component.Subscriptionpackage.url}
                                element={<SubscriptionPackage />}
                            />

                            <Route
                                path={constant.component.jobPackageList.url}
                                element={<JobPostingList />}
                            />

                            {/* <Route
                                path={constant.component.Companyreview.url}
                                element={<CompanyReview />}
                            /> */}

                            {/* <Route
                                path={constant.component.addCollege.url}
                                element={<AddCollege />}
                            />

                            <Route
                                path={constant.component.CollegeListing.url}
                                element={<CollegeListing />}
                            />
                            <Route
                                path={constant.component.editCollege.url}
                                element={<EditCollege />}
                            />
                            <Route
                                path={constant.component.collegeCourses.url}
                                element={<CollegeCourse />}
                            /> */}

                            <Route
                                path={constant.component.jobAlert.url}
                                element={<JobAlert />}
                            />

                            {/* <Route path={constant.component.collegeImageMapper.url}
                                element={<CollegeImages />}
                            /> */}
                            <Route
                                path={constant.component.shareInterviewQuestion.url}
                                element={<ShareInterviewQuestionList />}
                            />
                            <Route
                                path={constant.component.shareInterviewAnswer.url}
                                element={<ShareInterviewAnswerList />}
                            />
                            <Route path={constant.component.appliedJObs.url}
                                element={<AppliedJobs />}
                            />
                            {/* <Route path={constant.component.futureHiringList.url}
                                element={<FutureEventHiringList />}
                            /> */}
                            <Route
                                path={constant.component.editShareInterviewQuestion.url}
                                element={<EditShareInterviewQuestion />}
                            />

                            <Route
                                path={constant.component.premiumJobsList.url}
                                element={<PremiumJobList />}
                            />

                            <Route
                                path={constant.component.employerpremiumjobs.url}
                                element={<EmpPremiumJob />}
                            />

                            <Route
                                path={constant.component.employerfeaturedjobs.url}
                                element={<EmpFeaturedJob />}
                            />
                            <Route
                                path={constant.component.employerJobsPosted.url}
                                element={<EmployerJobPosted />}
                            />
                            <Route
                                path={constant.component.candidateAppliedJob.url}
                                element={<CandidateAppliedJobs />}
                            />

                            <Route
                                path={constant.component.featuredJobsList.url}
                                element={<FeaturedjobList />}
                            />
                            {/* <Route
                                path={constant.component.HRMSSubscriptionpackage.url}
                                element={<HRMSSubscriptionList />}
                            /> */}

                            <Route
                                path={constant.component.addbanner.url}
                                element={<AddBanner />}
                            />

                            <Route
                                path={constant.component.addthirdpartybanner.url}
                                element={<AddThirdPartyBanner />}
                            />
                            <Route
                                path={constant.component.banneranalytics.url}
                                element={<BannerAnalytics />}
                            />

                            <Route
                                path={constant.component.bannerlist.url}
                                element={<BannerList />}
                            />


                            <Route
                                path={constant.component.editbanner.url}
                                element={<EditBanner />}
                            />
                            {/* 
                            <Route
                                path={constant.component.addAffiliation.url}
                                element={<AddAffiliate />}
                            />

                            <Route
                                path={constant.component.affiliationList.url}
                                element={<AffilateList />}
                            />


                            <Route
                                path={constant.component.EditAffiliate.url}
                                element={<EditAffiliate />}
                            />




                            <Route
                                path={constant.component.affiliateAnalytics.url}
                                element={<AffiliateAnalytics />}
                            /> */}




                        </React.Fragment>
                    )}

                    <React.Fragment>
                        <Route
                            path="*"
                            element={
                                ud && token ? <Dashboard /> :
                                    ud && !token ?
                                        <Navigate to={constant.component.lockScreen.url} replace />
                                        : !ud && !token ? <Navigate to={constant.component.login.url} replace /> :
                                            <Navigate to={constant.component.fourzerofour.url} replace />
                            }
                        />
                    </React.Fragment>
                </Routes>

            </Layout>


        </>
    )

};

export default CustomRoute;
