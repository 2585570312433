import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getEmploymentTypeById } from "../../actions/JobsAction";
import SuccessMessage from "../common/SuccessMessage";
import ErrorMessage from "../common/ErrorMessage";
import NumberFormat from "react-number-format";
import constant from "../../constant";

function AddEmployementTypes(props) {
  const { id } = useParams();
  const [error, setError] = useState()
  const [EMPLOYMENT_TYPE, setEMPLOYMENT_TYPE] = useState("");
  const [EMPLOYMENT_TYPE_DETAILS, setEMPLOYMENT_TYPE_DETAILS] = useState("");
  const [EMPLOYMENT_TYPE_ID, setEMPLOYMENT_TYPE_ID] = useState("");
  const [SORT_NUMBER, setSORT_NUMBER] = useState("");
  const Navigate = useNavigate()
  const params = useParams()
  const onSubmit = (e) => {
    let pn = params.pageno
    e.preventDefault();
    if (EMPLOYMENT_TYPE.toString().trim().length === 0 || SORT_NUMBER.toString().trim().length === 0) {
      setError(true)
      return false
    }
    if (id) {
      const model = {
        EMPLOYMENT_TYPE: EMPLOYMENT_TYPE,
        EMPLOYMENT_TYPE_DETAILS: EMPLOYMENT_TYPE_DETAILS,
        EMPLOYMENT_TYPE_ID: EMPLOYMENT_TYPE_ID,
        SORT_NUMBER: SORT_NUMBER,
        type: "UPDATE",
      };
      props.onSubmit(model);
      model.pageno = pn
    } else {
      const model = {
        EMPLOYMENT_TYPE: EMPLOYMENT_TYPE,
        EMPLOYMENT_TYPE_DETAILS: EMPLOYMENT_TYPE_DETAILS,
        SORT_NUMBER: SORT_NUMBER,
        type: "ADD",
      };
      props.onSubmit(model);
      model.pageno = pn
    }
  };

  useEffect(() => {
    id && getEmpTypeDetails(id);
    (id ? document.title = constant.title.UpdateEmployementType : document.title = constant.title.AddEmployementType)

  }, []);


  const getEmpTypeDetails = (id) => {
    getEmploymentTypeById({ EMPLOYMENT_TYPE_ID: id })
      .then((res) => {
        if (res.status) {
          setEMPLOYMENT_TYPE(res.result[0].EMPLOYMENT_TYPE);
          setEMPLOYMENT_TYPE_DETAILS(res.result[0].EMPLOYMENT_TYPE_DETAILS);
          setEMPLOYMENT_TYPE_ID(res.result[0].EMPLOYMENT_TYPE_ID);
          setSORT_NUMBER(res.result[0].SORT_NUMBER);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const handleReset = () => {

    setError(false)
    id && Navigate(constant.component.listEmployementType.url)
    // setEMPLOYMENT_TYPE(PrevState => ({
    //   ...PrevState,
    //   EMPLOYMENT_TYPE: "",
    //   EMPLOYMENT_TYPE_DETAILS: "",
    //   SORT_NUMBER: ""



    // }))

    setEMPLOYMENT_TYPE('')
    setEMPLOYMENT_TYPE_DETAILS('')
    setEMPLOYMENT_TYPE_ID("")
    setSORT_NUMBER("")
  }
  return (
    <React.Fragment>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0" style={{ fontSize: "18px" }}>
                    {id ? " Update Employment Type" : "  Add Employment Type"}
                  </h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Jobs</a>
                      </li>
                      <li className="breadcrumb-item active">
                        {id ? " Update Employment Type" : "  Add Employment Type"}
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title mb-4">Employment Type Information</h4>
                    {
                      props.showMsg && (props.response.status ?
                        <SuccessMessage message={props.response.messageCode} />
                        :
                        <ErrorMessage message={props.response.error} />)
                    }
                    <div id="basic-example">
                      <section>
                        <form method="POST">
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label for="basicpill-firstname-input">
                                  Employment Type <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  className={error && EMPLOYMENT_TYPE.toString().trim().length <= 0 ? 'form-control is-invalid ' : 'form-control'}
                                  id="basicpill-firstname-input"
                                  placeholder="Enter Employment Type"
                                  value={EMPLOYMENT_TYPE}
                                  onChange={e => { setEMPLOYMENT_TYPE(e.target.value) }}
                                />
                                {
                                  error && EMPLOYMENT_TYPE.toString().trim().length <= 0 ? <span className='text-danger ml-1'>This Field is Required</span>
                                    : ""}
                              </div>
                            </div>

                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label for="basicpill-address-input">
                                  Sort Number <span className="text-danger">*</span>
                                </label>
                                <NumberFormat
                                  allowLeadingZeros={false}
                                  allowNegative={false}
                                  thousandSeparator={false}
                                  maxlength={10}
                                  className={error && SORT_NUMBER.toString().trim().length <= 0 ? 'form-control is-invalid ' : 'form-control'}
                                  id="basicpill-firstname-input"
                                  placeholder="Enter Sort Number"
                                  value={SORT_NUMBER}
                                  onChange={e => { setSORT_NUMBER(e.target.value) }}
                                />
                                {
                                  error && SORT_NUMBER.toString().trim().length <= 0 ? <span className='text-danger ml-1'>This Field is Required</span>
                                    : ""}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="mb-3">
                                <label for="basicpill-address-input">
                                  Employment Type Details
                                </label>
                                <textarea
                                  id="basicpill-address-input"
                                  className="form-control"
                                  rows="2"
                                  placeholder="Enter Employment Type Details"
                                  value={EMPLOYMENT_TYPE_DETAILS}
                                  onChange={e => { setEMPLOYMENT_TYPE_DETAILS(e.target.value) }}
                                ></textarea>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <button
                                  type="button"
                                  className="btn btn-info waves-effect btn-label waves-light"
                                  onClick={onSubmit}
                                >
                                  <i className="bx bx-check-double label-icon"></i>{" "}
                                  {id ? 'Save Changes' : 'Create Employment Type'}
                                </button>
                                &nbsp;&nbsp;
                                <button
                                  type="button"
                                  onClick={handleReset}
                                  className="btn btn-danger waves-effect btn-label waves-light"
                                >
                                  <i className="bx bx-block label-icon "></i>{" "}
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AddEmployementTypes;
