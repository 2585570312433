import React, { Children, useEffect, useRef, useState } from "react";
import { addFaq, updateFaq } from "../../actions/utilitiesAction";
import constant from "../../constant";
import QualificationLists from "../../component/jobs/QualificationList";
import { archiveEducationQualification, archiveIndustry, createEducationQualification, createRole, industryList, updateEducationQualification, updateRole } from "../../actions/JobsAction";
import QuestionLists from "../../component/jobs/QuestionList";
import RoleLists from "../../component/jobs/RoleList";
import AddRoles from "../../component/jobs/AddRole";
import AddFunctionalAreas from "../../component/jobs/AddFunctionalArea";
import AddIndustrys from "../../component/jobs/AddIndustry";
import IndustryJobLists from "../../component/jobs/ListIndustry";
import IndustryLists from "../../component/jobs/ListIndustry";

const ListJobIndustry = () => {
    const [industryLists, setIndustryLists] = useState([]);
    const [currentPage, setCurrentPage] = useState(1)
    const [count, setCount] = useState(0)

    useEffect(() => {
        getIndList(currentPage);
        document.title = constant.title.IndustryList
    }, [currentPage]);

    const getIndList = (page) => {
        industryList({ pageNumber: page })
            .then((res) => {
                if (res.status) {
                    setIndustryLists(res.result.list);
                    setCount(res.result.count)
                }
            })
            .catch((err) => {
                alert(err);
            });
    };


    const updateStatus = (model) => {
        archiveIndustry(model)
            .then((res) => {
                if (res.status) {
                    getIndList();
                }
            })
            .catch((err) => {
                alert(err);
            });
    }


    return (
        <React.Fragment>
            <IndustryLists
                industryLists={industryLists}
                updateStatus={updateStatus}
                getIndList={(page => getIndList(page))}
                count={count} setPage={setCurrentPage}
            />
        </React.Fragment>
    )
}

export default ListJobIndustry;