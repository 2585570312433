import React from 'react'
import List from '../../component/Components/FeaturedJobs/FeaturedjobList'
import { featuredJobList, listJob } from '../../actions/JobsAction'
import { useState, useEffect } from 'react'
import constant from '../../constant'

const FeaturedjobList = () => {

    const [currentPage, setCurrentPage] = useState(1);
    const [list, setList] = useState([]);
    const [count, setCount] = useState(0);
    const [showLoader, setShowLoader] = useState(false)


    useEffect(() => {
        featurejob(currentPage)
        document.title = constant.title.featuredJobList
    }, [currentPage])


    const featurejob = (page) => {
        setShowLoader(true)
        featuredJobList(page)
            .then((res) => {
                if (res.status) {
                    setList(res.result.featuredJobList);
                    setCount(res.result.count.total);
                    setShowLoader(false)
                }
            })
            .catch((err) => {
                alert(err);
            });
    };





    return (
        <React.Fragment>
            <List
                list={list}
                premiumjob={(page) => featurejob(page)}
                count={count}
                // getJobList={(page) => getJobList(page)}
                setPage={(page) => setCurrentPage(page)}
                handleSearch={(searchkey, page) => { featurejob(searchkey, page) }}
                setShowLoader={setShowLoader}
                showLoader={showLoader}
            />
        </React.Fragment>
    )
}

export default FeaturedjobList
