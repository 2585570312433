import React from 'react'

function ModulesList() {
    return (
        <React.Fragment>
            <div className='main-content'>
                <div className='page-content'>
                    <div class="container-fluid">

                        <div class="row">
                            <div class="col-12">
                                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 class="mb-sm-0 font-size-18">Module &amp; Page List</h4>

                                    <div class="page-title-right">
                                        <ol class="breadcrumb m-0">
                                            <li class="breadcrumb-item"><a href="javascript:void(0)">Module List</a></li>
                                            <li class="breadcrumb-item active">Module List</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-12">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="table-responsive">
                                            <table class="table align-middle table-nowrap table-hover">
                                                <thead class="table-light">
                                                    <tr>
                                                        <th scope="col">Module ID</th>
                                                        <th scope="col">Module Name</th>
                                                        <th scope="col">Module Page</th>
                                                        <th scope="col">Module Parent</th>
                                                        <th scope="col">Module Class</th>
                                                        <th scope="col">Position</th>
                                                        <th scope="col">Added On</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>210</td>
                                                        <td>
                                                            <p class="text-muted mb-0">Admin User</p>
                                                        </td>
                                                        <td>void(0)</td>
                                                        <td>
                                                            Root
                                                        </td>
                                                        <td>
                                                            bx bxs-user
                                                        </td>
                                                        <td>
                                                            8
                                                        </td>
                                                        <td>
                                                            12.12.2021 on 14:12 PM
                                                        </td>
                                                        <td>
                                                            <ul class="list-inline font-size-20 contact-links mb-0">
                                                                <li class="list-inline-item px-2">
                                                                    <a href="javascript: void(0);" title="Edit User"><i class="bx bx-edit"></i></a>
                                                                </li>
                                                                <li class="list-inline-item px-2">
                                                                    <a href="javascript: void(0);" style={{ color: "red" }} title="Delete User"><i class="bx bx-trash"></i></a>
                                                                </li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>210</td>
                                                        <td>
                                                            <p class="text-muted mb-0">Admin User</p>
                                                        </td>
                                                        <td></td>
                                                        <td>
                                                            Admin User
                                                        </td>
                                                        <td>
                                                            mm-active
                                                        </td>
                                                        <td>
                                                            1
                                                        </td>
                                                        <td>
                                                            12.12.2021 on 14:12 PM
                                                        </td>
                                                        <td>
                                                            <ul class="list-inline font-size-20 contact-links mb-0">
                                                                <li class="list-inline-item px-2">
                                                                    <a href="javascript: void(0);" title="Edit User"><i class="bx bx-edit"></i></a>
                                                                </li>
                                                                <li class="list-inline-item px-2">
                                                                    <a href="javascript: void(0);" style={{ color: "red" }} title="Delete User"><i class="bx bx-trash"></i></a>
                                                                </li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>210</td>
                                                        <td>
                                                            <p class="text-muted mb-0">Admin User List</p>
                                                        </td>
                                                        <td></td>
                                                        <td>
                                                            Admin User
                                                        </td>
                                                        <td>
                                                            mm-active
                                                        </td>
                                                        <td>
                                                            2
                                                        </td>
                                                        <td>
                                                            12.12.2021 on 14:12 PM
                                                        </td>
                                                        <td>
                                                            <ul class="list-inline font-size-20 contact-links mb-0">
                                                                <li class="list-inline-item px-2">
                                                                    <a href="javascript: void(0);" title="Edit User"><i class="bx bx-edit"></i></a>
                                                                </li>
                                                                <li class="list-inline-item px-2">
                                                                    <a href="javascript: void(0);" style={{ color: "red" }} title="Delete User"><i class="bx bx-trash"></i></a>
                                                                </li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <ul class="pagination pagination-rounded justify-content-center mt-4">
                                                    <li class="page-item disabled">
                                                        <a href="javascript: void(0);" class="page-link"><i class="mdi mdi-chevron-left"></i></a>
                                                    </li>
                                                    <li class="page-item">
                                                        <a href="javascript: void(0);" class="page-link">1</a>
                                                    </li>
                                                    <li class="page-item active">
                                                        <a href="javascript: void(0);" class="page-link">2</a>
                                                    </li>
                                                    <li class="page-item">
                                                        <a href="javascript: void(0);" class="page-link">3</a>
                                                    </li>
                                                    <li class="page-item">
                                                        <a href="javascript: void(0);" class="page-link">4</a>
                                                    </li>
                                                    <li class="page-item">
                                                        <a href="javascript: void(0);" class="page-link">5</a>
                                                    </li>
                                                    <li class="page-item">
                                                        <a href="javascript: void(0);" class="page-link"><i class="mdi mdi-chevron-right"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default ModulesList;