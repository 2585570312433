import React from 'react'
import TotalCandidates from '../Menu/TotalCandidates'

export default function CandidateList() {
    return (
        <React.Fragment>
            <TotalCandidates />
        </React.Fragment>
    )
}

