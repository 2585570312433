import React, { useState } from 'react'
import Parser from "react-html-parser"
import { getDateParts } from '../../utils';
import Loader from '../common/Loader';
import { useNavigate } from 'react-router-dom';

export default function EmpJobsPosted(props) {
const {Profile} = props
    let navigate = useNavigate()
    const [jobFullDetails, setJobFullDetails] = useState({});
    const [employerFullDetails, setEmployerFullDetails] = useState({})

    return (
        <React.Fragment>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4"> Profile{Profile.CONTACT_PERSON}</h4>
                                        <div className="table-responsive">
                                            {props.showLoader && <Loader />}

                                            <table className="table align-middle table-nowrap table-hover">
                                                {!props.showLoader && (
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th scope="col" style={{ width: "70px" }}>
                                                                ID
                                                            </th>
                                                            <th scope="col">Job Information</th>
                                                            <th scope="col">JobzShala Stats</th>
                                                            <th scope="col">Employer Details</th>
                                                            <th scope="col">Other Information </th>

                                                        </tr>
                                                    </thead>
                                                )}

                                                <tbody>
                                                    {props &&
                                                        props.list.length > 0 &&
                                                        props.list.map((data, index) => {

                                                            var date1 = new Date(data.CREATED_ON);
                                                            var date2 = new Date();
                                                            const diffTime = Math.abs(date2 - date1);
                                                            const diffDays = Math.ceil(
                                                                diffTime / (1000 * 60 * 60)
                                                            );
                                                            data.diffDays = diffDays;
                                                            return (
                                                                <tr>
                                                                    <td
                                                                        style={{
                                                                            color: "#ff8000",
                                                                            fontSize: "15px",
                                                                            fontFamily: "nunito",
                                                                        }}
                                                                    >
                                                                        {data.CUSTOM_JOB_ID}
                                                                    </td>

                                                                    <td>
                                                                        <h5
                                                                            className="mb-1"
                                                                            style={{ fontSize: "14px" }}
                                                                            title={data.JOB_TITLE}
                                                                        >
                                                                            <a href="#" className="text-dark">
                                                                                {data.JOB_TITLE.length > 25
                                                                                    ? data.JOB_TITLE.slice(0, 30) + "..."
                                                                                    : data.JOB_TITLE}
                                                                            </a>
                                                                        </h5>
                                                                        <p
                                                                            className="text-muted mb-0"
                                                                            style={{
                                                                                color: "#dd006f",
                                                                                fontSize: "12px",
                                                                            }}
                                                                        >
                                                                            {data.LISTNING_TYPE == "1"
                                                                                ? "REGULAR"
                                                                                : data.LISTNING_TYPE == "2"
                                                                                    ? "FEATURED"
                                                                                    : data.LISTNING_TYPE == "2"
                                                                                        ? "PREMIUM"
                                                                                        : ""}
                                                                        </p>
                                                                        {data.CTC_MIN && data.CTC_MAX && (
                                                                            <p
                                                                                className="text-muted mb-0"
                                                                                style={{ color: "#00add2" }}
                                                                            >
                                                                                &#x20b9;{" "}
                                                                                {` ${data.CTC_MIN} -  ${data.CTC_MAX}  L.P.A.`}
                                                                            </p>
                                                                        )}

                                                                        <p
                                                                            className="text-muted mb-0"
                                                                            style={{ color: "#0080ff", whiteSpace: "break-spaces" }}
                                                                        >
                                                                            <div className="tabledataflow">
                                                                                <i
                                                                                    className="bx bx-street-view align-middle me-1"
                                                                                    style={{ fontSize: "16px", color: "#0080ff" }}
                                                                                ></i>{" "}
                                                                                {data.JOB_LOCATIONS &&
                                                                                    data.JOB_LOCATIONS.length > 0
                                                                                    ? data.JOB_LOCATIONS.map(
                                                                                        (data, index) => {
                                                                                            return <span style={{ paddingRight: '6px', whiteSpace: 'inherit' }}>{data.CITY + " "}</span>
                                                                                        }
                                                                                    )
                                                                                    : "Not Available "}
                                                                            </div>
                                                                        </p>
                                                                        <p className="text-muted mb-0">
                                                                            <span style={{ color: "#f46a6a" }}>
                                                                                Posted{" "}
                                                                            </span>{" "}
                                                                            {getDateParts(data.CREATED_ON).relativeTime.split(' ')[0] == 'a' ? '1 ' + getDateParts(data.CREATED_ON).relativeTime.split(' ').splice(1).join(' ') : getDateParts(data.CREATED_ON).relativeTime}
                                                                        </p>
                                                                    </td>

                                                                    <td>
                                                                        <h5
                                                                            className="mb-1"
                                                                            style={{ fontSize: "14px" }}
                                                                        >
                                                                            <a
                                                                                href="javascript:void(0);"
                                                                                className="text-dark"
                                                                            >
                                                                                Candidates Applied {data.APPLIED
                                                                                }
                                                                            </a>
                                                                        </h5>
                                                                        <p className="text-muted mb-0">
                                                                            <a
                                                                                href="javascript:void(0);"
                                                                                className="text-dark"
                                                                            >
                                                                                <span style={{ color: "#009999" }}>
                                                                                    Shortlisted :  {data.SHORTLISTED}
                                                                                </span>
                                                                            </a>{" "}
                                                                            <br />



                                                                            <a
                                                                                href="javascript:void(0);"
                                                                                className="text-dark"
                                                                            >
                                                                                <span style={{ color: "#FF0000" }}>
                                                                                    Rejected :  {data.REJECTED}
                                                                                </span>
                                                                            </a>{" "}


                                                                            <br />
                                                                            <a
                                                                                href="javascript:void(0);"
                                                                                className="text-dark"
                                                                            >
                                                                                <span style={{ color: "#FFA500" }}>
                                                                                    Candidates Hold : {data.HOLD}
                                                                                </span>
                                                                            </a>{" "}
                                                                            <br />
                                                                            <a style={{ cursor: "pointer", color: "#000000" }} data-bs-toggle="modal" data-bs-target={"#MODELID" + data.QUESTION_ID}  >Questions : <span style={{
                                                                                color: "A1EDD5",
                                                                                background: "#00c694",
                                                                                padding: "1px 8px",
                                                                                borderRadius: "30px",
                                                                                color: "#fff",
                                                                                lineHeight: "9px",
                                                                                fontSize: "12px",
                                                                                paddingBottom: "3px"
                                                                            }} >View Questions</span></a>
                                                                            <div className="modal fade" id={"MODELID" + data.QUESTION_ID} data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                                                                                    <div className="modal-content">
                                                                                        <div className="modal-header">
                                                                                            <h5 className="modal-title" id="staticBackdropLabel">Question</h5>
                                                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                        </div>

                                                                                        <div className="modal-body">
                                                                                            <p>
                                                                                                <table className="table table-striped mb-0">
                                                                                                    <tbody>
                                                                                                        <tr>
                                                                                                            <td><p><span style={{ color: "#e81c28" }}>
                                                                                                            </span>  {Array.isArray(data.QUESTION) && data.QUESTION.length > 0 ? data.QUESTION.map((item, index) => {
                                                                                                                return (
                                                                                                                    <span>
                                                                                                                        <div>  {" "}{item.QUESTION}</div>
                                                                                                                    </span>
                                                                                                                )
                                                                                                            }) : <span> Not Available</span>} </p></td>
                                                                                                        </tr>




                                                                                                    </tbody>

                                                                                                </table>
                                                                                            </p>
                                                                                        </div>
                                                                                        <div className="modal-footer">
                                                                                            <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>


                                                                        </p>
                                                                    </td>
                                                                    <td
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#staticBackdrop42"
                                                                        style={{ cursor: 'pointer' }}
                                                                        onClick={(e) => {
                                                                            setEmployerFullDetails(data);
                                                                        }}
                                                                    >
                                                                        <h5
                                                                            className="mb-1"
                                                                            style={{ fontSize: "14px" }}
                                                                        >
                                                                            <a href="#" className="text-dark">

                                                                                <i className="bx bx-user align-middle me-1" style={{ fontSize: "16px", color: "#1894ed" }}></i> {data.CONTACT_PERSON} {" "}
                                                                            </a>
                                                                        </h5>
                                                                        <p
                                                                            className="text-muted mb-0"
                                                                            style={{ color: "#804000" }}
                                                                        >

                                                                            <i className="bx bx-building-house align-middle me-1" style={{ fontSize: "16px", color: "#940c35" }}></i> {data.COMPANY_NAME} {" "}
                                                                        </p>
                                                                        <p
                                                                            className="text-muted mb-0"
                                                                            style={{ color: "#0080ff" }}
                                                                        >
                                                                            <i className="bx bxs-envelope" style={{ color: "#fae60f" }} />&nbsp; {data.EMAIL} {" "}


                                                                        </p>
                                                                        <p
                                                                            className="text-muted mb-0"
                                                                            style={{ color: "#0080ff" }}
                                                                        >
                                                                            <i className="bx bxs-phone" style={{ color: "#299c0c" }} />&nbsp; {data.MOBILE} {" "}


                                                                        </p>
                                                                        <p>
                                                                            {data.ADDRESS && data.ADDRESS.length > 30 ? data.ADDRESS.slice(0, 30) + "..." : data.ADDRESS}
                                                                        </p>
                                                                    </td>
                                                                    <td>
                                                                        <button
                                                                            type="button"
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#staticBackdrop41"
                                                                            className="btn btn-info waves-effect btn-label waves-light"
                                                                            onClick={(e) => {
                                                                                setJobFullDetails(data);
                                                                            }}
                                                                        >
                                                                            <i className="bx bx-street-view label-icon "></i>{" "}
                                                                            View Details
                                                                        </button>
                                                                    </td>

                                                                </tr>
                                                            );
                                                        })}

                                                    {<>

                                                        {props.list.length == 0 &&
                                                            <td colSpan={10} className="text-center text-danger pt-3"> No Jobs Available</td>
                                                        }
                                                    </>
                                                    }

                                                    <div
                                                        className="modal fade"
                                                        id="staticBackdrop41"
                                                        tabindex="-1"
                                                        role="dialog"
                                                        aria-labelledby="staticBackdropLabel"
                                                        aria-hidden="true"
                                                        style={{ overflowY: "initial" }}
                                                    >
                                                        <div
                                                            className="modal-dialog modal-dialog-centered modal-lg"
                                                            role="document"
                                                        >
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5
                                                                        className="modal-title"
                                                                        id="staticBackdropLabel"
                                                                    >
                                                                        Job Information
                                                                    </h5>
                                                                    <button
                                                                        type="button"
                                                                        className="btn-close"
                                                                        data-bs-dismiss="modal"
                                                                        aria-label="Close"
                                                                    ></button>
                                                                </div>

                                                                <div
                                                                    className="modal-body weightauto"
                                                                    style={{ height: "80vh", overflow: "auto" }}
                                                                >
                                                                    {jobFullDetails && (
                                                                        <p>
                                                                            <table className="table table-striped mb-0">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td style={{ width: "20%" }}>
                                                                                            Job description
                                                                                        </td>
                                                                                        <td>
                                                                                            {jobFullDetails.JOB_DETAILS &&
                                                                                                Parser(
                                                                                                    jobFullDetails.JOB_DETAILS
                                                                                                )}
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>Required Candidate profile</td>
                                                                                        <td>
                                                                                            {jobFullDetails.COURSE_SPECIALIZATION &&
                                                                                                jobFullDetails
                                                                                                    .COURSE_SPECIALIZATION
                                                                                                    .length > 0 &&
                                                                                                jobFullDetails
                                                                                                    .COURSE_SPECIALIZATION[0]
                                                                                                    .COURSE_STREAM &&
                                                                                                ` Degree in ${jobFullDetails.COURSE_SPECIALIZATION &&
                                                                                                [...new Set(jobFullDetails.COURSE_SPECIALIZATION.map(
                                                                                                    (data, index) => {
                                                                                                        return data.COURSE_STREAM;
                                                                                                    }
                                                                                                ))]
                                                                                                }`}
                                                                                            <br />
                                                                                            <br />
                                                                                            {`Excellent command on  ${jobFullDetails &&
                                                                                                jobFullDetails.KEYWORDS
                                                                                                }`}

                                                                                            <br />
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>Role</td>
                                                                                        <td>{jobFullDetails.ROLE_NAME}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>Industry Type</td>
                                                                                        <td>{jobFullDetails.INDUSTRY}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>Functional Area</td>
                                                                                        <td>
                                                                                            {jobFullDetails.FUNCTIONAL_AREA}
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>Employment Type</td>
                                                                                        <td>
                                                                                            {jobFullDetails.EMPLOYMENT_TYPE}
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>Role Category</td>
                                                                                        <td>{jobFullDetails.JOB_TITLE}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>Education</td>
                                                                                        <td>
                                                                                            {`  ${jobFullDetails.COURSE_SPECIALIZATION &&
                                                                                                [...new Set(jobFullDetails.COURSE_SPECIALIZATION.map(
                                                                                                    (data, index) => {
                                                                                                        return data.QUALIFICATION_NAME;
                                                                                                    }
                                                                                                ))]
                                                                                                }`}
                                                                                            <br />
                                                                                            {`  ${jobFullDetails.COURSE_SPECIALIZATION &&
                                                                                                [...new Set(jobFullDetails.COURSE_SPECIALIZATION.map(
                                                                                                    (data, index) => {
                                                                                                        return data.COURSE_STREAM;
                                                                                                    }
                                                                                                ))]
                                                                                                }`}
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>Key Skills</td>
                                                                                        <td>{jobFullDetails.KEYWORDS}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>
                                                                                            {jobFullDetails.STATUS == "U" ? (
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="btn btn-success btn-sm waves-effect waves-light"
                                                                                                >
                                                                                                    ACTIVE
                                                                                                </button>
                                                                                            ) : (
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="btn btn-danger btn-sm waves-effect waves-light"
                                                                                                >
                                                                                                    ARCHIVE
                                                                                                </button>
                                                                                            )}
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </p>
                                                                    )}
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-light"
                                                                        data-bs-dismiss="modal"
                                                                    >
                                                                        Close
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="modal fade"
                                                        id="staticBackdrop42"
                                                        tabindex="-1"
                                                        role="dialog"
                                                        aria-labelledby="staticBackdropLabel"
                                                        aria-hidden="true"
                                                        style={{ overflowY: "initial" }}
                                                    >
                                                        <div
                                                            className="modal-dialog modal-dialog-centered modal-lg"
                                                            role="document"
                                                        >
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5
                                                                        className="modal-title"
                                                                        id="staticBackdropLabel"
                                                                    >
                                                                        Employer Details -<span style={{ color: "red" }}>{employerFullDetails.COMPANY_NAME}</span>
                                                                    </h5>
                                                                    <button
                                                                        type="button"
                                                                        className="btn-close"
                                                                        data-bs-dismiss="modal"
                                                                        aria-label="Close"
                                                                    ></button>
                                                                </div>

                                                                <div
                                                                    className="modal-body weightauto"
                                                                    style={{ overflow: "auto" }}
                                                                >
                                                                    {employerFullDetails && (
                                                                        <p>
                                                                            <table className="table table-striped mb-0">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td style={{ width: "20%" }}>Employer Name</td>
                                                                                        <td>{employerFullDetails.CONTACT_PERSON}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>Company Name</td>
                                                                                        <td>{employerFullDetails.COMPANY_NAME}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>Industry</td>
                                                                                        <td>{employerFullDetails.INDUSTRY}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>Role</td>
                                                                                        <td>{employerFullDetails.ROLE_NAME}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>Functional Area</td>
                                                                                        <td>{employerFullDetails.FUNCTIONAL_AREA}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>Description</td>
                                                                                        <td>{employerFullDetails.CLIENT_DETAILS}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>Employer Address</td>
                                                                                        <td>{employerFullDetails.ADDRESS}</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </p>
                                                                    )}
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-light"
                                                                        data-bs-dismiss="modal"
                                                                    >
                                                                        Close
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="mb-3">
                                                    <a href="javascript: void(0)" style={{ color: '#0080ff', fontSize:"36px" }}  onClick={() => navigate(-1)} ><i className='fa fa-arrow-circle-left'></i></a>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="row">
                      <div className="col-lg-12">
                        <div className="pagination pagination-rounded justify-content-center mt-4">

                          <Pagination
                            activePage={qParams?.p ? +qParams?.p :
                              qParams?.pageno ? Number(qParams.pageno) : currentPage}
                            itemsCountPerPage={20}
                            totalItemsCount={props.count}
                            pageRangeDisplayed={5}
                            onChange={handlePageChange}
                            itemClass="page-item"
                            linkClass="page-link"
                          />

                        </div>
                      </div>
                    </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

