import React, { useState } from 'react'
import constant from '../../constant';
import { removeStorage } from '../../utils';

const Logout = () => {

    useState(() => {
        document.title = 'Logout';
        removeStorage('rsc_cache')
        removeStorage(constant.keys.token);
        removeStorage(constant.keys.userDetails);
        localStorage.clear();

        window.location.href = '/';

    }, [])

    return (
        <div>Logout</div>
    )
}

export default Logout;