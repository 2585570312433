import React from 'react'
import { useState } from 'react';
import Pagination from 'react-js-pagination';
import moment from 'moment';
import Loader from '../../common/Loader';
import { useParams } from 'react-router-dom';
import Swal from "sweetalert2";

export default function CommentList(props) {

    const { id } = useParams()
    const [currentPage, setCurrentPage] = useState(1);
    const [showData, setShowData] = useState({})
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        props.setPage(pageNumber)
    }

    const updateStatus = (COMMENT_ID, STATUS) => {

        const model = {
            COMMENT_ID: COMMENT_ID,
            STATUS: STATUS == '1' ? '0' : '1'
        }
        props.setPage(currentPage)
        props.updateStatus(model)
    }

    const deleteBlog = (COMMENT_ID) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to delete this comment? ",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                props.deleteBlogCommentList(COMMENT_ID)
            }
        })

    }


    return (
        <React.Fragment>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0" style={{ fontSize: "18px" }}>
                                        Blog Comments
                                    </h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="#">Blog</a></li>
                                            <li className="breadcrumb-item active">Blog Comments</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table align-middle table-nowrap table-hover">
                                                {
                                                    props.showLoader &&
                                                    <Loader />
                                                }
                                                {!props.showLoader && <thead className="table-light">
                                                    <tr>
                                                        <th scope="col" style={{ width: "70px" }}>S.No.</th>
                                                        <th scope="col">Blog & Comment By</th>
                                                        <th scope="col" width="500px">Blog Comment</th>
                                                        <th scope="col" width="100px">Added On</th>
                                                        <th scope="col" width="100px">Action</th>
                                                    </tr>
                                                </thead>}
                                                <tbody>
                                                    {props.list && props.list.length > 0 &&
                                                        props.list.map((item, index, list) => {

                                                            return (
                                                                <tr>
                                                                    <td>
                                                                        <td>{currentPage == 1 ? `${index + 1}` : `${index == 9 ? currentPage : currentPage - 1}` + `${index == 9 ? 0 : index + 1}`}</td>
                                                                    </td>
                                                                    <td>
                                                                        <h5 className="mb-1" style={{ fontSize: "14px" }}><a href="javascript: void(0);" className="text-dark">{item.BLOG_TITLE}</a></h5>
                                                                        <p className="text-muted mb-0" style={{ textTransform: "capitalize" }}><i className="bx bx-user"></i> {item.NAME} &nbsp;<i className="bx bx-envelope"></i>{item.EMAIL}</p>
                                                                    </td>
                                                                    <td>{item.MESSAGE.slice(0, 40)}
                                                                        {item.MESSAGE.length > 30 && <a href="#" onClick={() => setShowData(item)} data-bs-toggle="modal" data-bs-target=".bs-example-modal-lg">...read more</a>}


                                                                    </td>
                                                                    <td>
                                                                        {moment(item.MODIFIED_ON).format('DD.MM.YYYY on hh:mma')}
                                                                    </td>
                                                                    <td>
                                                                        <ul className="list-inline contact-links mb-0" style={{ fontSize: "20px" }}>

                                                                            <li className="list-inline-item px-2" >
                                                                                <a href="javascript: void(0)" onClick={() => updateStatus(item.COMMENT_ID, item.STATUS)}>
                                                                                    {item.STATUS == '1' ?
                                                                                        <i className="bx bx-check-circle" style={{ color: "#00ae00" }} title="Approved Comment! Reject Now"></i> : <i className="bx bx bxs-error" style={{ color: "#ff0f87" }} title="Pending Comment! Approve Now"></i>
                                                                                    }
                                                                                </a>
                                                                            </li>

                                                                            <li className="list-inline-item px-2">
                                                                                <a href="javascript: void(0);" onClick={() => deleteBlog(item.COMMENT_ID)} style={{ color: "red" }} title="Delete User"><i className="bx bx-trash"></i></a>
                                                                            </li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>

                                                            )

                                                        })

                                                    }
                                                    {
                                                        <>
                                                            {
                                                                props.list && props.list.length == 0 && props.showLoader == false &&
                                                                <td colSpan={10} className="text-center text-danger pt-3">No Comment Record Found</td>
                                                            }
                                                        </>
                                                    }
                                                    {showData && <div className="modal fade bs-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                                                        <div className="modal-dialog modal-lg">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title" id="myLargeModalLabel">Comment Details</h5>
                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                </div>
                                                                <div className="modal-body">
                                                                    <div className="mb-3" style={{ color: "red" }}>
                                                                        <label for="recipient-name" className="col-form-label"></label>{showData.BLOG_TITLE}
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <p className="text-muted mb-0"><i className="bx bx-user"></i>{showData.NAME} &nbsp;<i className="bx bx-envelope"></i>{showData.EMAIL}</p>
                                                                    </div>
                                                                    <div className="mb-3" style={{ whiteSpace: "normal" }}>
                                                                        <p>{showData.MESSAGE}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>}
                                                </tbody>

                                            </table>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <ul className="pagination pagination-rounded justify-content-center mt-4">
                                                    <Pagination
                                                        activePage={currentPage}
                                                        itemsCountPerPage={10}
                                                        totalItemsCount={props.count}
                                                        pageRangeDisplayed={10}
                                                        onChange={handlePageChange}
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                    />
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}
