import * as axios from "axios";
import { getAuthHeader } from "./../utils";


export const addsubscription = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/create-jobpost-package`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const getSubscriptionList = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/job-package-list?page=${model.page}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const archiveSubsCriptionPackage = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/update-jobpackage-status`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
};


export const updatePackage = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/update-job-packages`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })

}

export const packageById = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/job-packages-By-Id?PACKAGE_ID=${model.PACKAGE_ID}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const SubscriptionList = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/job-package-subscription-list?page=${model.page ? model.page : 1}&KEYWORD=${model.KEYWORD}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};

export const TransactionList = (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/job-package-transaction-bycompany?USER_ID=${model.USER_ID}&page=${model.page}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
};