import React, { Component } from 'react'
import Update from '../../../component/campus/university/UpdateLatestNews'
import { getAdminCampusLatestNews, updateAdminCampusLatestNews } from '../../../actions/campus';
import Swal from 'sweetalert2';
export default class UpdateLatestNews extends Component {
    constructor(props) {
        super(props);
        this.state = {
            NEWS: undefined
        }
    }

    componentDidMount() {
        getAdminCampusLatestNews().then(res => {
            if (res.status) {
                this.setState({ NEWS: res.result })
            }
            else {
                console.error(res.error)
            }
        })
    }
    onSubmit(model) {
        updateAdminCampusLatestNews(model).then(res => {
            if (res.status) {
                Swal.fire({
                    icon: 'success',
                    text: 'News Successfully Updated',
                    timer: 1500,
                    showCancelButton: false,
                    showConfirmButton: false
                })
            }
            else {
                console.error(res.error)
            }
        })
    }
    render() {
        const { NEWS } = this.state;
        return (
            <React.Fragment>
                {NEWS !== undefined && <Update
                    NEWS={NEWS}
                    onSubmit={(model) => { this.onSubmit(model) }}
                />}
            </React.Fragment>
        )
    }
}
