import React from 'react'
import Add from '../../../component/campus/university/addUniversity'
import { addUniversity } from '../../../actions/campus'
import Swal from 'sweetalert2'
const AddUniversity = () => {

    const onSubmit = (model) => {
        addUniversity(model).then(res => {
            if (res.status) {
                Swal({
                    icon: "success",
                    text: "University Added Successfully",
                    timer: 2000,
                    showCancelButton: false,
                    showConfirmButton: false,
                });
            }
        })
    }
    return (
        <React.Fragment>
            <Add
                onSubmit={(e) => { onSubmit(e) }}
            />
        </React.Fragment>
    )
}

export default AddUniversity