import React, { useState } from 'react'
import Pagination from 'react-js-pagination'
import Loader from '../common/Loader'
import Swal from 'sweetalert2'
import Select from "react-dropdown-select"
import { onTypeChangeData } from '../../actions/utilitiesAction'
import ReactReadMoreReadLess from "react-read-more-read-less";
import nl2br from 'react-nl2br'
export default function EnquiryList(props) {

    const [currentPage, setCurrentPage] = useState(1)

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber)
        props.setPage(pageNumber)
    }

    const deleteEnquiry = (ENQUIRY_ID) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to delete this comment? ",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                props.deleteEnquiryList(ENQUIRY_ID)
            }
        })

    }



    const onTypeChange = (type) => {

        //  onTypeChangeData(type).then(res=>{
        //      console.log("data >>>>" , res.result );
        //      }).catch(err=>{
        //      console.log(err);
        //  })
        if (!type) {
            props.EnquiryLists(1)
        }
        else {
            props.getType(type)

        }



    }
    return (
        <React.Fragment>
            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0" style={{ fontSize: "18px" }}>Enquiries List</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="#">Operations</a></li>
                                            <li className="breadcrumb-item active">Enquiries List</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <select style={{
                                        width: "30%",
                                        float: "right",
                                        marginLeft: "auto",
                                        marginTop: "10px",
                                        height: "30px",
                                        padding: "0px 10px",
                                        border: "1px solid #ddd",
                                        borderRadius: "20px",
                                        marginRight: "20px"
                                    }} onChange={e => { onTypeChange(e.target.value) }}>

                                        <option value="">Select Enquiry Types</option>
                                        <option value='Study Abroad'  >Study Abroad</option>
                                        <option value='International Work Visa'>International Work Visa</option>
                                        <option value='Carrer Astrology'> Career Astrology</option>
                                        <option value='Getting around JobzShala' > Getting around JobzShala</option>
                                        <option value='Study in usa' > Study in Usa</option>
                                    </select>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table align-middle table-nowrap table-hover">
                                                {
                                                    props.showLoader &&
                                                    <Loader />
                                                }
                                                {!props.showLoader && <thead className="table-light">
                                                    <tr>
                                                        <th scope="col" style={{ width: "70px" }}>#</th>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">Email</th>
                                                        <th scope="col">Phone</th>
                                                        <th scope="col" width={{ width: "300px" }}>Description</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>}
                                                <tbody>
                                                    {props.list && props.list.length > 0 &&
                                                        props.list.map((item, index) => {
                                                            return (
                                                                <tr>
                                                                    <td>
                                                                        <div className="avatar-xs">
                                                                            <span className="avatar-title rounded-circle">
                                                                                {item?.NAME?.charAt(0)?.toUpperCase()}
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <h5 className="mb-1" style={{ fontSize: "14px", textTransform: "capitalize" }}><a href="javascript:void(0);" className="text-dark">{item.NAME}</a></h5>

                                                                    </td>
                                                                    <td>{item.EMAIL}</td>
                                                                    <td>
                                                                        {item.CONTACT_NUMBER}
                                                                    </td>
                                                                    {/* <td>
                                                                        <span style={{ color: "#f46a6a" }}>{item.MESSAGE}</span>
                                                                        <p className="text-muted mb-0" style={{ whiteSpace: "normal" }}>{item.MESSAGE}</p>
                                                                    </td> */}
                                                                    <td style={{ maxWidth: '200px' }}>

                                                                        {item?.DESCRIPTION && <ReactReadMoreReadLess
                                                                            charLimit={50}
                                                                            readMoreText={<a href="javascript: void(0);" className="text-primary">  </a>}
                                                                            readLessText={<a href="javascript: void(0);" className="text-primary"> </a>}
                                                                        >
                                                                            {item?.DESCRIPTION?.slice(0, 20)}
                                                                        </ReactReadMoreReadLess>}
                                                                        <a title="Click to View Download Details" data-bs-toggle="modal" style={{ cursor: "pointer", color: "blue" }} data-bs-target={"#MODELID" + item.ENQUIRY_ID} >See Details</a>

                                                                        <div className="modal fade" id={"MODELID" + item.ENQUIRY_ID}  tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                                                                                <div className="modal-content">
                                                                                    <div className="modal-header">
                                                                                        <h5 className="modal-title" id="staticBackdropLabel">Recruiter Enquiries </h5>

                                                                                    </div>
                                                                                    <div className="modal-body">
                                                                                        <p>
                                                                                            <table className="table table-striped mb-0">
                                                                                                <tbody>
                                                                                                    <tr>
                                                                                                        <td><p>  User Information : </p></td>
                                                                                                        <td style={{ textAlign: "left" }}>
                                                                                                            <p> <i className="bx bx-user font-size-16 align-middle me-1"> <span>  {item.NAME}</span></i> </p>
                                                                                                            <p> <i className="bx bx-envelope font-size-16 align-middle me-1"> <span>   {item.EMAIL}</span></i> </p>
                                                                                                            <p> <i className="bx bxs-contact font-size-16 align-middle me-1"> <span>   {item.CONTACT_NUMBER}</span></i> </p>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className="modal-body">
                                                                                        <p>
                                                                                            <table className="table table-striped mb-0">
                                                                                                <tbody>
                                                                                                    <tr>
                                                                                                        <td style={{ width: '36%' }}><p>  Description :  </p></td>
                                                                                                        <td style={{ whiteSpace: 'initial' }}><p>   {nl2br(item.DESCRIPTION)} </p></td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </p>
                                                                                    </div>

                                                                                    <div className="modal-footer">
                                                                                        <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <ul className="list-inline contact-links mb-0" style={{ fontSize: "20px" }}>
                                                                            <li className="list-inline-item px-2">
                                                                                <a href="javascript:void(0);" onClick={() => deleteEnquiry(item.ENQUIRY_ID)} style={{ color: "red" }} title="Delete User"><i className="bx bx-trash"></i></a>
                                                                            </li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        <>
                                                            {
                                                                props.list && props.list.length == 0 && props.showLoader == false &&
                                                                <td colSpan={10} className="text-center text-danger pt-3">No Record Found</td>
                                                            }
                                                        </>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <ul className="pagination pagination-rounded justify-content-center mt-4">
                                                    <Pagination
                                                        activePage={currentPage}
                                                        itemsCountPerPage={10}
                                                        totalItemsCount={props.count}
                                                        pageRangeDisplayed={6}
                                                        onChange={handlePageChange}
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                    />
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
