import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import constant from '../../constant';
import HtmlParser from "react-html-parser";
import { capitalize } from "../../utils";
import Loader from "../common/Loader";
export default function JobApplied(props) {
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    props.setPage(pageNumber)
    //props.totalCandidatesLists(currentPage)
  }

  const { JobApply, Profile } = props
  console.log("Job", JobApply);

  return (
    <React.Fragment>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Jobs Applied</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a>
                          <Link to={constant.component.totalcandidates.url}>Job</Link></a>
                      </li>
                      <li className="breadcrumb-item active">Jobs Applied</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* end page title */}
            {/* SEARCH */}
            {/* <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <form className="row gy-12 gx-12 align-items-center">
                      <div className="col-sm-2">
                        <label className="visually-hidden" htmlFor="autoSizingInput">
                          Name
                        </label>
                        Search Candidate Jobs
                      </div>
                      <div className="col-sm-5">
                        <label
                          className="visually-hidden"
                          htmlFor="autoSizingInputGroup"
                        >
                          Username
                        </label>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            id="autoSizingInputGroup"
                            placeholder="Enter JOB ID, Job Title, Email ID, Phone etc"
                          />
                        </div>
                      </div>
                    
                    
                      <div className="col-sm-3">
                        <button
                          type="button"
                          className="btn btn-primary waves-effect btn-label waves-light"
                        >
                          <i className="bx bx bx-box label-icon" /> Search
                        </button>
                        &nbsp;{" "}
                     
                     
                      </div>
                    </form>
                  </div>

                </div>

              </div>

            </div> */}
            {/* SEARCH */}
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div> <h4
                      className="card-title mb-4"
                      style={{
                        fontSize: 18,
                        fontFamily: '"Nunito" !important',
                        fontWeight: 500
                      }}
                    >
                      <strong>{capitalize(Profile.CANDIDATE_NAME)}</strong> |{" "}

                      {Profile.EMAIL_ID ? Profile.EMAIL_ID + ' ' + '|' : 'NA'}&nbsp;{Profile.PHONENO ? Profile.PHONENO : 'NA'}

                    </h4>
                    </div>
                    <div>
                      <h6><b>Total Jobs Applied:</b> &nbsp;{JobApply.length ? JobApply.length : '0'}</h6>
                    </div>
                    <div className="table-responsive">
                      <table className="table align-middle table-nowrap table-hover">
                        <thead className="table-light">
                          <tr>
                            <th scope="col" style={{ width: 70 }}>
                              ID
                            </th>
                            <th scope="col">Job Information</th>
                            <th scope="col">Employer Details</th>
                            <th scope="col">Status</th>
                            <th scope="col">Other Information </th>
                            {/* <th scope="col">Action</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {props.showLoader ? <Loader /> : JobApply != undefined && JobApply.length > 0 ?
                            JobApply.map((ele) => {
                              var date1 = new Date(ele.APPLIED_DATE);
                              var date2 = new Date();
                              const diffTime = Math.abs(date2 - date1);
                              const diffDays = Math.ceil(
                                diffTime / (1000 * 60 * 60 * 24)
                              );
                              ele.diffDays = diffDays;
                              return (<React.Fragment>
                                <tr>
                                  <td
                                    style={{
                                      color: "#ff8000",
                                      fontSize: 15,
                                      fontFamily: "nunito"
                                    }}
                                  >
                                    {ele.CUSTOM_JOB_ID ? ele.CUSTOM_JOB_ID : 'NA'}
                                  </td>
                                  <td>
                                    <h5 className="font-size-14 mb-1">
                                      <a href="#" className="text-dark">
                                        {ele.JOB_TITLE ? ele.JOB_TITLE : 'NA'}
                                      </a>
                                    </h5>
                                    <p
                                      className="text-muted mb-0"
                                      style={{ color: "#f03818 " }}
                                    >
                                      <i className="bx bx-rupee" style={{ color: "#f03818 " }} />
                                      {ele.CTC_MIN ? ele.CTC_MIN + ' ' + '-' : 'NA'}{ele.CTC_MAX ? ele.CTC_MAX : 'NA'}
                                    </p>
                                    <p
                                      className="text-muted mb-0"
                                      style={{ color: "#0080ff " }}
                                    >
                                      <i className="bx bx-street-view  font-size-16 align-middle me-1" style={{ color: "#0080ff " }} />{" "}
                                      {ele.ADDRESS ? ele.ADDRESS : 'NA'}
                                    </p>

                                    <p className="text-muted mb-0">
                                      <span style={{ color: "#f46a6a" }}>Posted </span>
                                      {ele.diffDays}&nbsp;days ago{" "}
                                    </p>
                                  </td>
                                  <td>
                                    <h5 className="font-size-14 mb-1">
                                      <a href="#" className="text-dark">
                                        {ele.COMPANY_NAME ? ele.COMPANY_NAME : 'NA'}
                                      </a>
                                    </h5>
                                    <p
                                      className="text-muted mb-0"
                                      style={{ color: "#004080 !important" }}
                                    >
                                      <a href="#" className="text-dark">
                                        {" "}
                                        <i
                                          style={{ color: "#fae60f " }}
                                          className="bx bx-envelope"
                                        />{" "}
                                      </a>
                                      <a href="mailto:info@gyanwave.com">
                                        {ele.EMAIL ? ele.EMAIL : ' NA'}
                                      </a>
                                    </p>
                                    <p
                                      className="text-muted mb-0"
                                      style={{ color: "#004080 !important" }}
                                    >
                                      {" "}
                                      <i
                                        style={{ color: "#299c0c " }}
                                        className="bx bx-phone"
                                      />{" "}
                                      <a href="tel:+91-99902343434">{ele.MOBILE ? ele.MOBILE : 'NA'}</a>
                                    </p>
                                    <p
                                      className="text-muted mb-0"
                                      style={{ color: "#004080 !important" }}
                                    >
                                      {" "}
                                      <i
                                        style={{ color: "#004080 !important" }}
                                        className="bx bx-globe text-danger"
                                      />{" "}
                                      {ele?.WEBSITE == 'https://something.com' || ele?.WEBSITE == '' ? 'NA' : <span><a href={ele?.WEBSITE} target='_blank'>{ele?.WEBSITE.slice(0, 30)}</a></span>}

                                    </p>
                                  </td>
                                  <td>
                                    <p>
                                      <button
                                        type="button"
                                        className="btn btn-primary btn-sm waves-effect waves-light"
                                      >
                                        <i className="bx bx bx-box font-size-16 align-middle me-2" />{" "}
                                        {ele.APPLICATION_STATUS ? ele.APPLICATION_STATUS : 'IN PROCESS'}
                                      </button>
                                    </p>
                                  </td>
                                  <td>
                                    <a
                                      type="button"
                                      data-bs-toggle="modal"
                                      data-bs-target={"#staticBackdrop41" + ele.JOB_ID}
                                      className="btn btn-info waves-effect btn-label waves-light">
                                      <i className="bx bx-street-view label-icon " /> View
                                      Details{" "}
                                    </a>
                                    <div
                                      className="modal fade"
                                      id={"staticBackdrop41" + ele.JOB_ID}
                                      tabIndex={-1}
                                      role="dialog"
                                      aria-labelledby="staticBackdropLabel"
                                      aria-hidden="true"
                                    >
                                      <div
                                        className="modal-dialog modal-dialog-centered modal-lg"
                                        role="document"
                                      >
                                        <div className="modal-content">
                                          <div className="modal-header">
                                            <h5
                                              className="modal-title"
                                              id="staticBackdropLabel"
                                            >{ele.JOB_TITLE}-
                                              Job Information
                                            </h5>
                                            <button
                                              type="button"
                                              className="btn-close"
                                              data-bs-dismiss="modal"
                                              aria-label="Close"
                                            />
                                          </div>
                                          <div className="modal-body">
                                            <p></p>
                                            <table className="table table-striped mb-0 job-details-table">
                                              <tbody>
                                                <tr>
                                                  <td>Logo</td>
                                                  <td><img src={`${process.env.REACT_APP_BASE_URL}/company/logo/${ele.COMPANY_LOGO}`} style={{ width: "100px" }} /></td>
                                                </tr>
                                                <tr>
                                                  <td style={{ width: "180px" }}>Job description</td>
                                                  <td style={{ whiteSpace: 'initial' }}>
                                                    <p>
                                                      {HtmlParser(ele.JOB_DETAILS)}
                                                    </p>
                                                  </td>
                                                </tr>

                                                <tr>
                                                  <td>Role</td>
                                                  <td>
                                                    {ele.ROLE_NAME}
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>Industry Type</td>
                                                  <td>{ele.INDUSTRY}</td>
                                                </tr>
                                                <tr>
                                                  <td>Functional Area</td>
                                                  <td>{ele.FUNCTIONAL_AREA}</td>
                                                </tr>
                                                <tr>
                                                  <td>Employment Type</td>
                                                  <td>{ele.EMPLOYMENT_TYPE}</td>
                                                </tr>
                                                <tr>
                                                  <td>Key Skills</td>
                                                  <td>
                                                    {ele.KEYWORDS}
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                            <p />
                                          </div>
                                          <div className="modal-footer">
                                            <button
                                              type="button"
                                              className="btn btn-light"
                                              data-bs-dismiss="modal"
                                            >
                                              Close
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {/* MODEL CLOSED */}
                                  </td>
                                </tr>
                              </React.Fragment>)
                            }) : " "
                          }
                          {<>{props.JobApply && props.JobApply.length == 0 && <td colSpan={10} className="text-center text-danger pt-3">No Record Found</td>}</>}
                        </tbody>
                      </table>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <ul className="pagination pagination-rounded justify-content-center mt-4">
                          <Pagination
                            activePage={currentPage > 0 ? currentPage : 1}
                            itemsCountPerPage={10}
                            totalItemsCount={props.count}
                            pageRangeDisplayed={5}
                            onChange={handlePageChange}
                            itemClass="page-item"
                            linkClass="page-link"
                          />
                        </ul>
                      </div>
                    </div>
                    {/* end table-responsive */}
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
          {/* container-fluid */}
        </div>
        {/* End Page-content */}
      </div>
    </React.Fragment>
  )
}
