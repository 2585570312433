import React, { useEffect } from 'react'
import Add from '../../../component/Utilities/CountryCityAndState/AddLocalities'
import constant from '../../../constant'
import { useState } from 'react'
import { addLocality, getCountryList } from '../../../actions/utilitiesAction'


const AddLocalities = () => {

    const [response, setResponse] = React.useState({})
    const [showMsg, setShowMsg] = React.useState(false)
    const [list, setList] = useState([])

    const onSubmit = (model) => {
        addLocality(model).then((res) => {
            setResponse(res)
            setShowMsg(true)
            setTimeout(() => {
                setShowMsg(false)
            }, 2000)
        }).catch((err) => {
            alert(err)
        })
    }

    useEffect(() => {
        document.title = constant.title.addLocalities
        getCountryList().then((res) => {
            setList(res.result.list)
        }).catch((err) => {
            alert(err)
        })
    }, [])




    return (
        <React.Fragment>
            <Add
                onSubmit={onSubmit}
                response={response}
                showMsg={showMsg}
                list={list}
            />

        </React.Fragment>
    )
}

export default AddLocalities
