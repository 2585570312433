import React, { useEffect, useState } from 'react'
import EmpJobsPosted from '../../component/Employers/EmpJobsPosted'
import { employersjobs, employersjobprofile } from '../../actions/JobsAction'
import constant from '../../constant';
import { useParams } from 'react-router-dom';


export default function EmployerJobPosted() {

    const [currentPage, setCurrentPage] = useState(1);
    const [list, setList] = useState([]);
    const [Profile, setProfile] = useState([])
    const [showLoader, setShowLoader] = useState(false)
    const params = useParams()
    useEffect(() => {
        document.title = constant.title.employerJobsPosted
        jobsposted(currentPage)

    }, [currentPage])

    const jobsposted = (page) => {

        employersjobs({ EMPLOYER_ID: parseInt(params.id), page:page })
            .then((res) => {
                if (res.status) {
                    setList(res.result.list);
                }
            })
            .catch((err) => {
                alert(err);
            });

            employersjobprofile({ EMPLOYER_ID: parseInt(params.id)})
            .then((res) => {
                if (res.status) {
                    setProfile(res.result.list);
                }
            })
            .catch((err) => {
                alert(err);
            });
    }

    return (
        <React.Fragment>

            <EmpJobsPosted
                list={list}
                Profile={Profile}
                jobsposted={(page) => jobsposted(page)}
                setPage={setCurrentPage}
                setShowLoader={setShowLoader}
                showLoader={showLoader}


            />
        </React.Fragment>
    )
}

