import React from 'react'
import { useEffect, useState } from 'react'
import List from '../../component/Enquiry/UnderMaintainanceEnquiryList'
import { MaintainanceEnquiryList, deleteMaintainanceEnquiry } from '../../actions/utilitiesAction'
import constant from '../../constant'
import Swal from 'sweetalert2'

export default function UnderMaintainanceEnquiryList(){
    const [list, setList] = useState([])
    const [count, setCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [showLoader, setShowLoader] = useState(false)

    useEffect(() => {
        UnderMaintainanceEnquiryList(currentPage)
        document.title = constant.title.UnderMaintainanceEnquiryList
    }, [currentPage])


const UnderMaintainanceEnquiryList = (page) => { 
    
    MaintainanceEnquiryList({ page: page }).then((res) => {
        if (res.status) {
           
            setList(res.result.list)
            setCount(res.result.count)
        }
        else {
            alert(res.error)
        }
    }).catch((err) => {
        alert(err)
    })
}

const deleteMaintainanceEnquiryLists = (ENQUIRY_ID) => {
    deleteMaintainanceEnquiry({ ENQUIRY_ID: ENQUIRY_ID }).then((res) => {
        if (res.status) {
            Swal.fire(
                'Deleted!',
                'Your enquiry has been deleted.',
                'success'
            )
            UnderMaintainanceEnquiryList(currentPage)
        }
        else {
            Swal.fire(
                (res.result.error)
            )
        }
    })
}


  return (

    <React.Fragment>
<List
                list={list}
                setList={setList}
                UnderMaintainanceEnquiryList={(page => UnderMaintainanceEnquiryList(page))}
                count={count}
                setPage={setCurrentPage}
                showLoader={showLoader}
                deleteMaintainanceEnquiryLists={deleteMaintainanceEnquiryLists}
            />
    </React.Fragment>
  )
}

