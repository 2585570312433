import React, { Children, useEffect, useRef, useState } from "react";
import { addFaq, updateFaq } from "../../actions/utilitiesAction";
import constant from "../../constant";
import QualificationLists from "../../component/jobs/QualificationList";
import {
  archiveEducationQualification,
  archiveJob,
  createEducationQualification,
  createRole,
  listJob,
  updateEducationQualification,
  updateRole,
} from "../../actions/JobsAction";
import QuestionLists from "../../component/jobs/QuestionList";
import RoleLists from "../../component/jobs/RoleList";
import AddRoles from "../../component/jobs/AddRole";
import AddFunctionalAreas from "../../component/jobs/AddFunctionalArea";
import AddIndustrys from "../../component/jobs/AddIndustry";
import AddJobs from "../../component/jobs/AddJobs";
import ListJobs from "../../component/jobs/ListJobs";
import { useLocation } from "react-router-dom";
import queryString from 'query-string'

const ListJob = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [list, setList] = useState([]);
  const [count, setCount] = useState(0);
  const [showLoader, setShowLoader] = useState(false)
  const [keywords, setKeywords] = useState('')
  const location = useLocation()

  useEffect(() => {
    const qParams = queryString.parse(location.search)
    getJobList(qParams?.p ? qParams?.p :qParams?.pageno ? qParams.pageno  : currentPage);
  }, [currentPage]);

  const getJobList = (pageNumber) => {

    setShowLoader(true)
    listJob({ KEYWORD: keywords, pageNumber: pageNumber })
      .then((res) => {

        if (res.status) {
          setList(res.result.list);
          setCount(res.result.count);
          setShowLoader(false)
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const updateStatus = (model) => {

    archiveJob(model)
      .then((res) => {
        if (res.status) {
          getJobList(currentPage);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  return (
    <React.Fragment>
      <ListJobs
        list={list}
        updateStatus={(data) => updateStatus(data)}
        getJobList={(pageNumber) => getJobList(pageNumber)}
        count={count}
        setPage={setCurrentPage}
        handleSearch={(searchkey, pageNumber) => { getJobList(searchkey, pageNumber) }}
        setShowLoader={setShowLoader}
        showLoader={showLoader}
        cpage={currentPage}
        setKeywords={setKeywords}
      />
    </React.Fragment>
  );
};

export default ListJob;
