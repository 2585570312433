import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { jobTypeById, updateJobType } from '../../../actions/utilitiesAction'
import Add from '../../../component/Utilities/JobType/AddJobType'
import constant from '../../../constant'
const AddJobType = (props) => {
    const [response, setResponse] = React.useState({})
    const [showMsg, setShowMsg] = React.useState(false)
    const navigate = useNavigate()
    const params = useParams()
    const [data, setData] = useState(null)

    useEffect(() => {

        jobTypeById(params.id).then((res) => {

            if (res.status) {
                setData(res.result)
            }
            else {
                setResponse(res)
                setShowMsg(true)
                setTimeout(() => {
                    setShowMsg(false)
                }, 7000)
            }
        }).catch(err => {
            alert(err)
        })
    }, [])

    const onSubmit = (model) => {

        updateJobType(model).then((res) => {

            if (res.status) {
                navigate(constant.component.jobtypelist.url + "?p=" + model.pageno)
            } else {
                setResponse(res)
                setShowMsg(true)
                setTimeout(() => {
                    setShowMsg(false)
                }, 7000)
            }
        }).catch((err) => {
            alert(err)
        })
    }

    return (
        <React.Fragment>
            {data && <Add onSubmit={onSubmit} detail={data} response={response} showMsg={showMsg} />}
        </React.Fragment>
    )
}

export default AddJobType