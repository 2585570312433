import React, { useEffect, useState } from 'react'
import constant from '../../../constant';
import { getStorage, removeStorage, setError } from '../../../utils';
import moment from 'moment';


export default function LockScreen(props) {
  const ud = getStorage(constant.keys.userDetails)
  const [data, setData] = useState({
    PASSWORD: "",
    EMAIL_ID: ""
  })
  const [error, setError] = useState(false)
  const onSubmit = (e) => {
    e.preventDefault();
    if (data.PASSWORD.length > 0) {
      const model = {
        Password: data.PASSWORD,
      };
      if (ud) {
        model.EMAIL_ID = ud.EMAIL_ID
      }
      props.onSubmit(model)
    }
    else {
      setError(true)
    }

  }

  const gotoSignIn = (e) => {
    e.preventDefault();
    removeStorage('rsc_cache')
    removeStorage(constant.keys.userDetails);
    localStorage.clear();
    window.location.href = '/';
  }


  const handleChange = (e) => {
    const { name, value } = e.target
    setData({
      ...data,
      [name]: value
    })
  }
  console.log(ud)
  return (
    <React.Fragment>

      <div className="auth-page-wrapper pt-5">
        <div className="auth-one-bg-position auth-one-bg" id="auth-particles">
          <div className="bg-overlay" />
          <div className="shape">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 1440 120"
            >
              <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z" />
            </svg>
          </div>
          <canvas
            className="particles-js-canvas-el"
            width={1898}
            height={475}
            style={{ width: "100%", height: "100%" }}
          />
        </div>
        <div className="auth-page-content">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="text-center mt-sm-5 text-white-50">
                  <div>
                    <a className="d-inline-block auth-logo" href="/">
                      <img
                        src="/static/media/jobzshala.c520a775807a094cab8d.png"
                        alt=""
                        height={60}
                      />
                    </a>
                  </div>
                  <p className=" fs-15 fw-medium">
                    Log in to access the JobzShala Administration Control Panel.
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-8 col-lg-6 col-xl-5">
                <div className="card mt-4">
                  <div className="card-body p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-hd-log">Screen Unlock</h5>
                      <p className="text-muted">
                        Screen your password to unlock the screen{" "}
                        <span className="rest-fg-text">JobzShala</span>.
                      </p>
                    </div>
                    <div className="p-2 mt-4">
                      <div className="user-thumb text-center mb-4">
                        <img src={ud.PROFILE_PICTURE.length ? `${process.env.REACT_APP_BASE_URL}/admin/profile/pic/${ud.PROFILE_PICTURE}` : "assets/images/users/avatar-2.webp"} className="rounded-circle img-thumbnail avatar-md" alt={ud?.FIRST_NAME + " " + ud?.LAST_NAME} />
                        <h5 className="font-size-15 mt-3">{ud?.FIRST_NAME + " " + ud?.LAST_NAME}</h5>
                      </div>
                      <form onSubmit={onSubmit}>
                        <div className="mb-3">
                          <label htmlFor="userpassword">Password</label>
                          <input
                            type="password"
                            className={error && data.PASSWORD.length == 0 ? 'form-control is-invalid' : "form-control"}
                            id="userpassword"
                            placeholder="Enter password"
                            name='PASSWORD'
                            value={data.PASSWORD}
                            onChange={handleChange}
                          />
                          {error && data.PASSWORD.length == 0 && <p className='text-danger'>Enter Password</p>}
                        </div>
                        <div className="mt-4">
                          <button onClick={onSubmit}
                            className="btn btn-success btn-successlogin w-100"
                            type="submit"
                          >
                            Unlock
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="mt-4 text-center">
                  <p className="mb-0">
                    Not you ?{" "}
                    <a
                      className="fw-semibold text-primary text-decoration-underline"
                      onClick={(e) => { gotoSignIn(e) }}
                      style={{ cursor: "pointer" }}
                    >
                      {" "}
                      Sign In here{" "}
                    </a>{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className="footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="text-center">
                  <p className="mb-0 text-muted">
                    © {moment().format('YYYY')} JobzShala.com <i className="mdi mdi-heart text-danger" />{" "}
                    Powered by Akshaki India (P) Limited.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>


      {/* <div className="account-pages my-5 pt-sm-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-lg-6 col-xl-5">
                            <div className="card overflow-hidden">
                                <div className="bg-primary bg-soft">
                                    <div className="row">
                                        <div className="col-7">
                                            <div className="text-primary p-4">
                                                <a href="javascript:void(0)" className style={{ color: '#dc4237', fontFamily: 'nunito', fontSize: '22px', fontWeight: 700 }}>JobzShala<span style={{ color: '#014d87' }}>.COM</span></a>
                                                <h5 className="text-primary">Screen Locked</h5>
                                                <p>Enter your password to unlock the screen!</p>
                                            </div>
                                        </div>
                                        <div className="col-5 align-self-end">
                                            <img src="assets/images/profile-img.png" alt="" className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body pt-0">
                                    <div>
                                        <a href="/">
                                            <div className="avatar-md profile-user-wid mb-4">
                                                <span className="avatar-title rounded-circle bg-light">
                                                    <img src="assets/images/admin-icon.png" alt="" className="rounded-circle" height={80} />
                                                </span>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="p-2">
                                        <form action="" method="POST">
                                            <div className="user-thumb text-center mb-4">
                                                <img src={ud.PROFILE_PICTURE.length ? `${process.env.REACT_APP_BASE_URL}/admin/profile/pic/${ud.PROFILE_PICTURE}` : "assets/images/users/avatar-2.webp"} className="rounded-circle img-thumbnail avatar-md" alt={ud?.FIRST_NAME + " " + ud?.LAST_NAME} />
                                                <h5 className="font-size-15 mt-3">{ud?.FIRST_NAME + " " + ud?.LAST_NAME}</h5>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="userpassword">Password</label>
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    id="userpassword"
                                                    placeholder="Enter password"
                                                    name='PASSWORD'
                                                    value={data.PASSWORD}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="text-end">
                                                <button
                                                    onClick={onSubmit}
                                                    className="btn btn-primary w-md waves-effect waves-light"
                                                    type="submit">Unlock</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5 text-center">
                                <p>Not you ? return <a onClick={(e) => { gotoSignIn(e) }} className="fw-medium text-primary " style={{ cursor: "pointer" }}> Sign In </a> </p>
                                <p> Powered by Akshaki India (P) Limited.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

    </React.Fragment>
  )
}
