import React, { useEffect } from 'react'
import { addJobCategory } from '../../../actions/utilitiesAction'
import Add from "../../../component/Utilities/JobCategory/AddJobCategory"


const AddJobCategory = () => {
    const [response, setResponse] = React.useState({})
    const [showMsg, setShowMsg] = React.useState(false)


    const onSubmit = (model) => {
        addJobCategory(model).then((res) => {
            // if (res.status)
            //     alert(res.messageCode)
            setResponse(res)
            setShowMsg(true)
            setTimeout(() => {
                setShowMsg(false)
            }, 7000)
        }).catch((err) => {
            alert(err)
        })


    }

    return (
        <React.Fragment>
            <Add onSubmit={onSubmit} response={response} showMsg={showMsg} />
        </React.Fragment>
    )
}
export default AddJobCategory;