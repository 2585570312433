import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {JobApplyStatus, GetProfileInformation}from '../../actions/ProfileAction';
import JobApplied from '../../component/Menu/JobApplied';
import constant from '../../constant'

export default function JobApplie()
 {
    const [JobApply, setjobapply] = useState([])
    const [Profile, setProfile] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [count, setCount] = useState(0)
    const [showLoader, setShowLoader] = useState(false)

   
    document.title = constant.title.JobApplied
    const params = useParams()
     
    useEffect(() => {
      JobsApplyStatus(currentPage)
    }, [currentPage])

      const JobsApplyStatus = (pageNumber) => {
        JobApplyStatus({pageNumber:pageNumber,CANDIDATE_ID : parseInt( params.id) }).then((res) => {
           if (res.status) {
            setjobapply(res.result.list)
            setCount(res.result.count)
            setShowLoader(false);

           }
       }).catch(err => {
           alert(err)
       })

       GetProfileInformation({CANDIDATE_ID : parseInt( params.id) }).then((res) => {
       if (res.status) {
           setProfile(res.result)
           setShowLoader(false);

       }
   }).catch(err => {
       alert(err)
   })
       
    }

  return (
    <div>
      <JobApplied JobApply={JobApply} Profile={Profile}
        JobApplyStatus={(page => JobApplyStatus(page))}
        count={count}
        showLoader={showLoader}
        setPage={setCurrentPage}
      />
    </div>
  )
 }
