import React, { useEffect } from 'react'
import { addSkill, addSkills } from '../../../actions/utilitiesAction'
import Add from '../../../component/Utilities/Skills/AddSkills'

const AddSkills = () => {
    const [response, setResponse] = React.useState({})
    const [showMsg, setShowMsg] = React.useState(false)

    const onSubmit = (model) => {
        addSkills(model).then((res) => {
            setResponse(res)
            setShowMsg(true)
            setTimeout(() => {
                setShowMsg(false)
            }, 2000)
        }).catch((err) => {
            alert(err)
        })
    }

    return (
        <React.Fragment>
            <Add onSubmit={onSubmit} response={response} showMsg={showMsg} />
        </React.Fragment>
    )
}

export default AddSkills