import React, { useEffect, useState } from 'react'
import List from '../../../component/campus/student/collegeStudentList'
import { collegeStudentList } from '../../../actions/campus';
import { useParams } from 'react-router-dom';

const CollegeStudentList = (props) => {
    const params = useParams()
    const [page, setPage] = useState(1)
    const [list, setList] = useState(null)
    const [count, setCount] = useState(null)
    const [showLoader, setShowLoader] = useState(false)


    useEffect(() => {
        setShowLoader(true)
        collegeStudentList(page, params.id).then((res) => {
            setShowLoader(false)
            if (res.status) {
                setList(res.result.list)
                setCount(res.result.count)
            }
        })
    }, [page])
    return (
        <React.Fragment>

            {<List
                title={'ON Campus Student List'}
                student_list={list}
                count={count}
                page={page}
                onPageChange={(newPage) => { setPage(newPage) }}
                showLoader={showLoader}
            />
            }
        </React.Fragment>
    )
}

export default (CollegeStudentList)