import React, { Component } from 'react'
import { onChange, setOptions, validateForm } from '../../../utils';
import { cityList, stateList } from '../../../actions/utilitiesAction';
import Swal from 'sweetalert2';
import { uploadUniversityLogo } from '../../../actions/campus';
import NumberFormat from 'react-number-format';

export default class UpdateLatestNews extends Component {
    constructor(props) {
        super(props);
        this.state = {
            NEWS: { name: 'NEWS', value: props.NEWS ? props.NEWS : '', error: '', isRequired: true }
        }
    }
    onChange(e) {
        const { name, value } = e.target;
        onChange(this, name, value)
    }


    onSubmit(e) {
        e.preventDefault();
        if (validateForm(this)) {
            const { NEWS } = this.state;
            const model = {
                NEWS: NEWS.value
            }
            this.props.onSubmit(model)
        }

    }


    render() {
        const { NEWS } = this.state;
        return (
            <React.Fragment>
                <div className='main-content'>
                    <div className='page-content'>
                        <div className='container-fluid'>
                            <div class="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0">Update Latest News</h4>

                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item"><a href="javascript: void(0);">Campus</a></li>
                                                <li className="breadcrumb-item active"> Update Latest News</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div id="basic-example">
                                                <section>
                                                    <form onSubmit={(e) => { this.onSubmit(e) }} autocomplete="on">
                                                        <h4 className="card-title mb-4" style={{ marginTop: "16px", color: "#f46d6d" }}>New Update</h4>


                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="mb-3">
                                                                    {/* <label for="basicpill-address-input">Description</label> */}
                                                                    <textarea
                                                                        type="text"
                                                                        row={10}
                                                                        className={!NEWS.error.length ? "form-control" : "form-control is-invalid"}
                                                                        name={NEWS.name}
                                                                        value={NEWS.value}
                                                                        onChange={(e) => { this.onChange(e) }}
                                                                        placeholder="Enter Latest News"
                                                                    ></textarea>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <div className="mb-3">
                                                                    <button type="submit" className="btn btn-info waves-effect btn-label waves-light"><i className="bx bx-check-double label-icon"></i> Update Latest News</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </section>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </React.Fragment >
        )
    }
}
