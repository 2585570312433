import React, { useEffect } from 'react'
import { createCompanyType } from '../../actions/employer'
import AddCompanytype from '../../component/Employers/AddCompanyType'
import constant from '../../constant'
export default function AddCompanyType() {

    const [response, setResponse] = React.useState({})
    const [showMsg, setShowMsg] = React.useState(false)

    useEffect(() => {
        document.title = constant.title.AddCompanyType
    })

    const onSubmit = (model) => {
        createCompanyType(model).then((res) => {
            setResponse(res)
            setShowMsg(true)
            setTimeout(() => {
                setShowMsg(false)
            }, 7000)
        }).catch((err) => [
            alert(err)
        ])
    }

    
    return (
        <React.Fragment>
            <AddCompanytype onSubmit={onSubmit} response={response} showMsg={showMsg} />
        </React.Fragment>
    )
}
