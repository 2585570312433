import React, { useEffect, useState } from 'react'
import { archiveEntityType, ListEntityType } from '../../actions/employer'
import EntityTypelist from '../../component/Employers/EntityTypeList'

export default function EntityTypeList() {

    const [List, setList] = useState([])
    const [count, setCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [showLoader, setShowLoader] = useState(true)

    useEffect(() => {
        getEntityTypeList(currentPage)
    }, [])
    const getEntityTypeList = (page) => {
        ListEntityType({ page: page }).then(res => {

            if (res.status) {
                setList(res.result.list)
                setCount(res.result.count.total)
                setShowLoader(false)

            }
            else {
                alert(res.error)
            }
        }).catch(err => {
            alert(err)
        })

    }

    const updateStatus = (model) => {
        archiveEntityType(model).then(res => {
            if (res.status) {
                getEntityTypeList(currentPage)

            }
            else {
                alert(res.error)
            }
        }).catch(err => {
            alert(err)
        })
    }

    return (
        <React.Fragment>
            <EntityTypelist
                list={List}
                updateStatus={updateStatus}
                getEntityTypeList={(page) => { getEntityTypeList(page) }}
                count={count}
                setShowLoader={setShowLoader}
                showLoader={showLoader}
            />
        </React.Fragment>
    )
}
