import React, { useEffect } from 'react'
import { createEmployer } from '../../actions/employer'
import Addemployers from '../../component/Employers/AddEmployers'
import constant from '../../constant'
export default function AddEmployers() {

    const [response, setResponse] = React.useState({})
    const [showMsg, setShowMsg] = React.useState(false)

    useEffect(() => {
        document.title = constant.title.AddEmployers
    }, [])
    
    const onSubmit = (model) => {
        createEmployer(model).then((res) => {
            window.scrollTo(0, 0)
            setResponse(res)
            setShowMsg(true)
            setTimeout(() => {
                setShowMsg(false)
            }, 7000)
        }).catch((err) => {
            alert(err)
        })
    }

    return (
        <React.Fragment>
            <Addemployers onSubmit={onSubmit} response={response} showMsg={showMsg} />
        </React.Fragment>
    )
}
