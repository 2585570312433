import React, { useEffect, useState } from 'react'
import Profiles from '../../component/Menu/Dashboard/Profile'
import constant from '../../constant'
import { getProfileInfromation, totalEmployer, totalJob } from '../../actions/ProfileAction'
import { useParams } from 'react-router-dom'
import { totalCandidate, totalPostJob } from '../../actions/ProfileAction'
import { archiveJob, listJob } from '../../actions/JobsAction'

export default function Profile() {
    const [data, setData] = useState()
    const params = useParams();
    const [list, setList] = useState([]);
    const [employerCount, setEmployerCount] = useState(0);
    const [jobCount, setJobCount] = useState(0);
    const [pendingjobCount, setPendingJobCount] = useState(0);
    const [candidateCount, setCandidateCount] = useState(0)



    useEffect(() => {
        document.title = constant.title.Profile
        getJobList()
        getProfileInfromation(params.id).then((res) => {
            if (res.status) {
                setData(res.result)
            }
            else {
                alert(res.error)
            }
        }).catch((err) => {
            alert(err)
        })

        totalCandidate().then(res => {
            if (res.status) {
                setCandidateCount(res.result.count)
            }
            else {
                alert(res.error)
            }
        })

        totalPostJob().then(res => {
            if (res.status) {                
                setPendingJobCount(res.result.count1)
            }
            else {
                alert(res.error)
            }
        })

        totalJob().then(res => {
            if (res.status) {
                setJobCount(res.result.count)
            }
            else {
                alert(res.error)
            }
        })


        


        totalEmployer().then(res => {
            if (res.status) {
                setEmployerCount(res.result.count)
            }
            else {
                alert(res.error)
            }
        })

    }, [])


    const updateStatus = (model) => {
        archiveJob(model)
          .then((res) => {
            if (res.status) {
              getJobList()
            }
          })
          .catch((err) => {
            alert(err);
          });
      };


const  getJobList = (keywords,pageNumber) => {
        listJob({ KEYWORD: keywords, pageNumber: pageNumber })
          .then((res) => {   
            if (res.status) {
              setList(res.result.list);
            }
          })
          .catch((err) => {
            alert(err);
          });
      };
    return (
        <React.Fragment>

            {data && <Profiles
                detail={data}
                list={list}
                updateStatus={(data)=>updateStatus(data)}
                employerCount={employerCount}
                jobCount={jobCount}
                pendingjobCount={pendingjobCount}
                candidateCount={candidateCount}
            />}
        </React.Fragment>
    )
}
