import React, { useState } from 'react'
import { useEffect } from 'react'
import List from '../../component/PremiumJobs/premiumJobList'
import { premiumJobList } from '../../actions/JobsAction'
import constant from '../../constant'
export default function PremiumJobList() {
    const [count, setCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [list, setList] = useState([]);
    const [showLoader, setShowLoader] = useState(false)


    useEffect(() => {
        premiumjob(currentPage)
        document.title = constant.title.PremiumJobList
    }, [currentPage])



    const premiumjob = (page) => {
        setShowLoader(true)
        premiumJobList(page)
            .then((res) => {
                if (res.status) {
                    setList(res.result.premiumJobList);
                    setCount(res.result.count.total);
                    setShowLoader(false)
                }
            })
            .catch((err) => {
                alert(err);
            });
    };

    return (
        <React.Fragment>
            <List
                list={list}
                premiumjob={(page) => premiumjob(page)}
                count={count}
                setPage={(page) => setCurrentPage(page)}
                handleSearch={(searchkey, page) => { premiumjob(searchkey, page) }}
                setShowLoader={setShowLoader}
                showLoader={showLoader}

            />
        </React.Fragment>
    )
}



