import React, { useEffect, useRef, useState } from "react";
import constant from "../../constant";
import QualificationLists from "../../component/jobs/QualificationList";
import { archiveEducationQualification, createEducationQualification, educationalQualificationList, updateEducationQualification } from "../../actions/JobsAction";
import { useLocation } from "react-router-dom";
import queryString from 'query-string'

const QualificationList = () => {
    const [qualificationLists, setQualificationLists] = useState([]);
    const [currentPage, setCurrentPage] = useState(1)
    const [count, setCount] = useState(0)
    const location = useLocation()


    useEffect(() => {
        const qParams = queryString.parse(location.search)
        getJobCategoryList(qParams?.p ? qParams?.p : qParams?.pageno ? qParams.pageno : currentPage);
        document.title = constant.title.listQualification
    }, [currentPage]);

    const getJobCategoryList = (page) => {
        educationalQualificationList({ pageNumber: page })
            .then((res) => {
                if (res.status) {
                    setQualificationLists(res.result.list);
                    setCount(res.result.count)
                }
            })
            .catch((err) => {
                alert(err);
            });
    };

    const updateStatus = (model) => {
        archiveEducationQualification(model)
            .then((res) => {
                if (res.status) {
                    getJobCategoryList(currentPage);
                }
            })
            .catch((err) => {
                alert(err);
            });
    }

    return (
        <React.Fragment>
            <QualificationLists
                qualificationLists={qualificationLists}
                updateStatus={updateStatus}
                getJobCategoryList={(page => getJobCategoryList(page))}
                count={count} setPage={setCurrentPage}
                cpage={currentPage}
            />
        </React.Fragment>
    )
}

export default QualificationList;