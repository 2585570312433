import React, { useState } from "react";
import constant from "../../constant";
import moment from "moment";
import queryString from 'query-string'
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";

import { useLocation, useNavigate } from "react-router-dom";

const BannerAnalys = (props) => {

    const [currentPage, setCurrentPage] = useState(1);
    const location = useLocation()
    const Navigate = useNavigate()

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        Navigate(location.pathname + '?p=' + pageNumber)
        props.setPage(pageNumber)
    };


    const qParams = queryString.parse(location.search)
    const { cpage } = props

    return (
        < React.Fragment >
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                           
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 " style={{ fontSize: "18px" }}>
                                        Banner Analytics  |  <a style={{color:"orange"}}>{props.List&&props.List[0].TITLE}</a> | <a style={{color:"blue"}}>{props.List&&props.List[0].BANNER_ID}</a> | AD Location - <a style={{color:"blue"}}>{props.List&&props.List[0].PAGE_LOCATION}</a>
                                    </h4>

                                    {/* <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">
                                                    <a href="javascript: void(0);">Utilities</a>
                                                </li>
                                                <li className="breadcrumb-item active">Skills List</li>
                                            </ol>
                                        </div> */}
                                </div>
                               
                            </div>
                        </div>



                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table align-middle table-nowrap table-hover">
                                                {/* {
                          props.showLoader &&
                          <Loader />
                        } */}
                                                {!props.showLoader && <thead className="table-light">
                                                    <tr>
                                                        <th scope="col">S.No.</th>
                                                        <th scope="col">IP Address </th>
                                                        <th scope="col">City</th>
                                                        <th scope="col">Geographic Location</th>
                                                        <th scope="col">Track on </th>
                                                       
                                                    </tr>
                                                </thead>}
                                                <tbody>

                                                    {props.List && props.List.length > 0
                                                        && props.List.map((ele, index, list) => {
                                                            return (
                                                                <tr>
                                                                    <td>{currentPage == 1 ? `${index + 1}` : `${index == 9 ? currentPage : currentPage - 1}` + `${index == 9 ? 0 : index + 1}`}</td>
                                                                    <td>
                                                                        <p className="mb-2" style={{ color: "blue" }}> <b>{ele.IP_ADDRRESS}</b></p>



                                                                    </td>
                                                                    <td>{ele.CITY}</td>
                                                                    <td>
                                                                        <p> <b>Longitude:</b>  <span style={{ color: "red" }}>{ele.LONGT}</span></p>
                                                                        <p> <b>Latitude:</b>  <span style={{ color: "red" }}>{ele.LATT}</span></p>
                                                                    </td>
                                                                     <td>
                                                                     <p style={{ color: "green" }}>  {moment(
                                                                        ele.TRACK_ON
                                                                      ).format("DD.MM.YYYY") 
                                                                    }</p>
                                                                     </td>


                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                    {<>{props.List && props.List.length == 0 && props.showLoader == false && <td colSpan={10} className="text-center text-danger pt-3">No Record Found</td>}</>}
                                                </tbody>
                                            </table>
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >

    )
}

export default BannerAnalys
