import React from 'react'
import { addRole } from '../../actions/AdminUserAction'
import Role from '../../component/Administration/adminUser/AddAdminRole'
import constant from '../../constant'

const AddAdminRole = () => {

    const [response, setResponse] = React.useState({})
    const [showMsg, setShowMsg] = React.useState(false)


    const onSubmit = (model) => {
        addRole(model).then((res) => {
            setResponse(res)
            setShowMsg(true)
            setTimeout(() => {
                setShowMsg(false)
            }, 7000)
            window.location.href = constant.component.adminrolelist.url;
        }).catch((err) => {
            alert(err)
        })
    }
    return (
        <React.Fragment>
            <Role onSubmit={onSubmit} response={response} showMsg={showMsg} />
        </React.Fragment>
    )
}

export default AddAdminRole;