import React from 'react'
import { useState, useEffect } from 'react'
import constant from '../../../constant'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import queryString from 'query-string'
import { editGovtJob } from '../../../actions/utilitiesAction'
import EditGovtJob from '../../../component/Utilities/GovernmentJobs/EditGovtJob'


const EditGovtJobs = () => {

    const [response, setResponse] = React.useState({})
    const [showMsg, setShowMsg] = React.useState(false)
    const [list, setList] = useState([])
    const navigate = useNavigate()
    useEffect(() => {
        document.title = constant.title.editgovtjobs
    })
    const onSubmit = (model) => {
        editGovtJob(model).then((res) => {
            setResponse(res)
            setShowMsg(true)
            
            setTimeout(() => {
                setShowMsg(false)
            }, 2000)
            navigate(constant.component.govtjobslist.url)
                }).catch((err) => {
            alert(err)
        })
    }


    return (
        <React.Fragment>
            <EditGovtJob
             onSubmit={(model)=>onSubmit(model)}
             response={response}
             showMsg={showMsg}
             list={list}
            />
        </React.Fragment>
    )
}

export default EditGovtJobs
