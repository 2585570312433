import React from 'react'
import { useState, useRef } from 'react';
import Pagination from 'react-js-pagination';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import constant from '../../constant';
import { toast } from 'react-toastify';


const AffilateList = (props) => {



    const [currentPage, setCurrentPage] = useState(1);
    const [affiliateDetails, setAffiliateDetails] = useState({})
    const [copySuccess, setCopySuccess] = useState('')


    const copyLinkRef = useRef(null);

    const handleCopyLink = (link, e) => {

        navigator.clipboard.writeText(link)
            .then(() => {
                setCopySuccess(link)
                setTimeout(() => {
                    setCopySuccess('');
                }, 1000);
            })
            .catch((error) => {
                alert('Failed to copy link: ' + error);
            });

    };


    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        props.setPage(pageNumber)
    };


    const updateStatus = (ID, STATUS) => {
        const model = {
            ID: ID,
            STATUS: STATUS === "U" ? "A" : "U",
        };
        props.setPage(currentPage)
        props.updateStatus(model)
    };


    return (
        <React.Fragment>

            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">

                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 font-size-18">Affiliate </h4>
                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item">
                                                <a href="">Affiliate List</a>
                                            </li>
                                            {/* <li className="breadcrumb-item active"> Affilation List</li> */}
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">Affiliate  List</h4>
                                        <div className="table-responsive">
                                            <table className="table align-middle  table-hover ">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col"> Affiliate ID</th>
                                                        {/* <th scope="col">Name</th> */}


                                                        <th scope="col">
                                                            Affiliate Details
                                                        </th>
                                                        <th scope="col">
                                                            Other Information
                                                        </th>
                                                        <th scope="col">
                                                            Action
                                                        </th>

                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {props && props.List.length > 0
                                                        && props.List.map((ele) => {

                                                            return (
                                                                <tr>

                                                                    <td style={{
                                                                        color: "#ff8000",
                                                                        fontSize: "15px",
                                                                        fontFamily: "nunito",
                                                                    }}>
                                                                        {ele.AFFLIATE_ID}
                                                                    </td>


                                                                    <td>
                                                                        <p className="text-muted mb-0"> <i className="fa fa-user"></i> {ele.NAME}</p>
                                                                        <p className="text-muted mb-0"> <i class="fa fa-building"></i> {ele.COMPANY}</p>
                                                                        <p className="text-muted mb-0"><i class=" fa fa-envelope " > </i> {ele.EMAIL}</p>
                                                                        <p className="text-muted mb-0"> <i class="fa fa-phone-alt"></i> {ele.PHONE}</p>
                                                                    </td>


                                                                    <td>
                                                                        {/* <p className="text-muted mb-0"> CPL(Cost Per lead) : {ele.COST_PER_LEAD} </p> */}

                                                                        {/* ₹ {ele.COST_PER_LEAD} ?


                                                                        <span>Cost Per Lead : </span><span style={{ color: "red" }}>{ele.COST_PER_LEAD} % </span>

                                                                        <span>Cost Per Lead : </span><span style={{ color: "red" }}>{ele.COST_PER_LEAD}</span> */}



                                                                        CPL(Cost Per lead) : <span style={{ color: "#008040" }}>
                                                                            {ele.COST_TYPE == 'FIXED' ?
                                                                                <span >₹{ele.COST_PER_LEAD}</span> :
                                                                                ele.COST_TYPE == 'PERCENTAGE' ?
                                                                                    <span >{ele.COST_PER_LEAD}%</span> :
                                                                                    ''
                                                                            }
                                                                        </span><br />

                                                                        <p>{ele.POST_BACK_URL}</p>
                                                                        Affliate URL: <a target="_blank" href={`https://jobzshala.com/affiliate/${ele.AFFLIATE_ID}`}>https://jobzshala.com/affiliate/{ele.AFFLIATE_ID}</a>  <i
                                                                            className="fa fa-copy"
                                                                            style={{ cursor: 'pointer' }}
                                                                            onClick={() => handleCopyLink(`https://jobzshala.com/affiliate/${ele.AFFLIATE_ID}`)}
                                                                            ref={copyLinkRef}
                                                                            title="Copy Link"

                                                                        ></i>
                                                                        {copySuccess === `https://jobzshala.com/affiliate/${ele.AFFLIATE_ID}` && (
                                                                            <span style={{ color: 'green' }}>
                                                                                {' '}
                                                                                Copied!
                                                                            </span>
                                                                        )}


                                                                        <p className="mt-5"><Link to={constant.component.affiliateAnalytics.url.replace(':id', ele.ID)}><button className="btn btn-info" style={{
                                                                            padding: "4px 8px",
                                                                            borderRadius: "30px",
                                                                            lineHeight: "12px",
                                                                            fontSize: "12px",
                                                                            paddingBottom: "5px",
                                                                            marginTop: "-30px"

                                                                        }}>View analytics</button>   </Link>  </p>

                                                                    </td>

                                                                    <td>
                                                                        <ul className="list-inline font-size-20 contact-links mb-0" >
                                                                            <li className="list-inline-item px-2">
                                                                                <a onClick={(e) => { setAffiliateDetails(ele) }} href="javascript: void(0);" title="View Client" data-bs-toggle="modal" data-bs-target={"#staticBackdrop12" + ele.ID}><i className="bx bxs-zoom-in"></i></a>
                                                                                <div className="modal fade" id={"staticBackdrop12" + ele.ID} tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                                                                                        <div className="modal-content">
                                                                                            <div className="modal-header">
                                                                                                <h5 className="modal-title" id="staticBackdropLabel">Affiliate Information</h5>
                                                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                            </div>

                                                                                            <div className="modal-body weightauto" style={{ overflow: "auto" }}>
                                                                                                <p>{affiliateDetails &&
                                                                                                    <table className="table table-striped mb-0">
                                                                                                        <tbody style={{ fontSize: "15px", whiteSpace: "normal" }}>

                                                                                                            <tr>
                                                                                                                <td>Affiliate ID</td>
                                                                                                                <td>{affiliateDetails.AFFLIATE_ID}</td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td>Name</td>
                                                                                                                <td>{affiliateDetails.NAME}</td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td>Email</td>
                                                                                                                <td>{affiliateDetails.EMAIL}</td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td>Phone No.</td>
                                                                                                                <td>{affiliateDetails.PHONE}</td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td>Company</td>
                                                                                                                <td>{affiliateDetails.COMPANY}</td>
                                                                                                            </tr>

                                                                                                            <tr>
                                                                                                                <td>Cost Type</td>
                                                                                                                <td>{affiliateDetails.COST_TYPE} {' - '} {affiliateDetails.COST_TYPE == 'FIXED' ?
                                                                                                                    <span >₹{affiliateDetails.COST_PER_LEAD}</span> :
                                                                                                                    affiliateDetails.COST_TYPE == 'PERCENTAGE' ?
                                                                                                                        <span >{affiliateDetails.COST_PER_LEAD}%</span> :
                                                                                                                        ''}</td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td>Image </td>
                                                                                                                <td><img src={`${"https://jobzshala.s3.ap-south-1.amazonaws.com/component/page_header/images/"}${affiliateDetails.IMAGE}`} style={{ width: "100px" }} /></td>
                                                                                                            </tr>
                                                                                                        </tbody>
                                                                                                    </table>}
                                                                                                </p>
                                                                                            </div>
                                                                                            <div className="modal-footer">
                                                                                                <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                            <li className="list-inline-item px-2">

                                                                                <Link
                                                                                    to={constant.component.EditAffiliate.url.replace(":id", ele.ID)
                                                                                    }
                                                                                >
                                                                                    <i className="bx bx-edit" title="Edit Affiliate" />
                                                                                </Link>                                                                                </li>
                                                                            <li className="list-inline-item px-2">
                                                                                <a href="javascript: void(0);" onClick={() => updateStatus(ele.ID, ele.STATUS)} style={{ color: 'red' }} title={ele.STATUS == 'U' ? "Archive Record" : "Unarchive Record"}>
                                                                                    {
                                                                                        ele.STATUS == 'U' ? <i className="bx bx-trash" ></i> : <i class="bx bx-log-in"></i>
                                                                                    }
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </td>

                                                                </tr>

                                                            )
                                                        })
                                                    }

                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="pagination pagination-rounded justify-content-center mt-4">
                                                    <Pagination
                                                        activePage={currentPage}
                                                        itemsCountPerPage={10}
                                                        totalItemsCount={props.count}
                                                        pageRangeDisplayed={5}
                                                        onChange={handlePageChange}
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                    />
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>




    )
}

export default AffilateList
