import React, { useState } from 'react'
import moment from 'moment'
import constant from '../../../constant'
import { Link } from 'react-router-dom'
import Pagination from "react-js-pagination";
import Loader from '../../common/Loader';

const CollegeStudentList = (props) => {
    const { student_list, page, count, showLoader } = props
    return (
        <React.Fragment>

            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Off Campus Student List</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="javascript: void(0);">Campus</a></li>
                                            <li className="breadcrumb-item active">Off Campus Student List</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table align-middle table-nowrap table-hover">
                                                <thead className="table-light">
                                                    {!showLoader && < tr >
                                                        <th scope="col">S.No.</th>
                                                        <th scope="col">Student Name</th>
                                                        <th scope="col">Student Detail</th>
                                                        <th scope="col">Added On</th>
                                                        <th scope="col">Other Information</th>

                                                        <th scope="col">Status</th>
                                                        {/* <th scope="col" style={{ width: "100px" }}>Action</th> */}
                                                    </tr>}
                                                </thead>
                                                {showLoader && <Loader />}
                                                <tbody>

                                                    {student_list && student_list.length > 0 &&
                                                        student_list.map((item, index) => {
                                                            debugger
                                                            return (
                                                                <tr>
                                                                    <td>{page == 1 ? `${index + 1}` : `${index == 9 ? page : page - 1}` + `${index == 9 ? 0 : index + 1}`}</td>
                                                                    <td>
                                                                        <p className="text-muted mb-0">{item.FIRST_NAME} {item.LAST_NAME}</p>
                                                                    </td>
                                                                    <td>
                                                                        <tr><b>Email : </b>{item.PERSONAL_EMAIL}</tr>
                                                                        <tr><b>Phone : </b>{item.PHONE}</tr>
                                                                    </td>
                                                                    <td>
                                                                        {moment(item.CREATED_ON).format('DD.MM.YYYY on HH:MM A')}
                                                                    </td>
                                                                    <td>
                                                                        <a
                                                                            type="button"
                                                                            className="btn btn-info waves-effect btn-label waves-light"
                                                                            href={constant.component.CampusStudentDetil.url.replace(":id", item.STUDENT_ID).replace(":type", 'off_campus')}
                                                                        >
                                                                            <i className="bx bx-street-view label-icon"></i> View Profile
                                                                        </a>
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.STATUS == 'A' ?
                                                                                <button type="button" className="btn btn-success btn-sm waves-effect waves-light">ACTIVE</button> : <button type="button" className="btn btn-danger btn-sm waves-effect waves-light">ARCHIVE</button>
                                                                        }
                                                                    </td>

                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                    {<>{props.student_list && props.student_list.length == 0 && props.showLoader == false && <td colSpan={10} className="text-center text-danger pt-3">No Record Found</td>}</>}


                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="pagination pagination-rounded justify-content-center mt-4">
                                                    <Pagination
                                                        activePage={page}
                                                        itemsCountPerPage={10}
                                                        totalItemsCount={count}
                                                        pageRangeDisplayed={5}
                                                        onChange={(newPage) => props.onPageChange(newPage)}
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >)
    console.log(count)
}

export default CollegeStudentList