import React, { useEffect, useState } from 'react'
import NumberFormat from 'react-number-format'
import constant from '../../../constant'
import SuccessMessage from '../../common/SuccessMessage'
import ErrorMessage from '../../common/ErrorMessage'
import { useNavigate, useParams } from 'react-router-dom'


const AddJobType = (props) => {
    const { id } = useParams()
    const details = props.detail;
    const params = useParams()
    useEffect(() => {
        document.title = constant.title.AddJobType
    }, [])

    const [error, setError] = useState(false)
    const Navigate = useNavigate()
    const [data, setData] = useState({
        JOBTYPE: "",
        SORT_NUMBER: "",
        JOBTYPE_DETAILS: "",
    })

    const onSubmit = (e) => {
        let pn = params.pageno
        e.preventDefault();
        if (data.JOBTYPE.trim() == 0 || data.SORT_NUMBER.toString().trim() == 0) {
            setError(true)
        }
        else {
            const model = {
                JOB_TYPE: data.JOBTYPE,
                SORT_NUMBER: data.SORT_NUMBER,
                JOB_TYPE_DETAILS: data.JOBTYPE_DETAILS,
            };
            if (details) {
                model.JOB_TYPE_ID = details.JOB_TYPE_ID
            }
            props.onSubmit(model)
            model.pageno = pn
        }

    }
    useEffect(() => {
        (id ? document.title = constant.title.EditJobType : document.title = constant.title.AddJobType)
    }, [])
    useEffect(() => {

        if (details) {
            setData({
                JOBTYPE: details.JOB_TYPE,
                SORT_NUMBER: details.SORT_NUMBER,
                JOBTYPE_DETAILS: details.JOB_TYPE_DETAILS
            })
        }
    }, [])


    const handleChange = (e) => {
        const { name, value } = e.target
        setData({
            ...data,
            [name]: value
        })
    }

    const handleReset = (e) => {
        setError(false)
        e.preventDefault();
        id && Navigate(constant.component.jobtypelist.url)
        setData(PrevState => ({
            ...PrevState,
            JOBTYPE: "",
            SORT_NUMBER: "",
            JOBTYPE_DETAILS: "",
        }))
    }


    return (
        <React.Fragment>
            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 " style={{ fontSize: '18px' }}>{id ? "Update Job Type" : "Add Job Type"}</h4>
                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="javascript: void(0);">Utilities</a></li>
                                            <li className="breadcrumb-item active">{id ? "Update Job Type" : "Add Job Type"}</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">Jobs Type Information</h4>
                                        {
                                            props.showMsg && (props.response.status ?
                                                <SuccessMessage message={props.response.messageCode} />
                                                :
                                                <ErrorMessage message={props.response.error} />)
                                        }
                                        <div id="basic-example">
                                            <section>
                                                <form method="POST">
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label for="basicpill-firstname-input">Jobs Type <span style={{ color: "#FF0000" }}>*</span></label>
                                                                <input type="text"
                                                                    className={error && data.JOBTYPE.trim().length <= 0 ? 'form-control is-invalid ' : 'form-control'}
                                                                    id="basicpill-firstname-input"
                                                                    placeholder="Enter Jobs Type"
                                                                    name="JOBTYPE"
                                                                    value={data.JOBTYPE}
                                                                    onChange={handleChange}
                                                                />
                                                                {
                                                                    error && data.JOBTYPE.trim().length <= 0 ? <span className='text-danger ml-1'>This Field is Required</span> : ""
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label for="basicpill-address-input">Sort Number <span style={{ color: "#FF0000" }}>*</span></label>
                                                                <NumberFormat type="text"
                                                                    className={error && data.SORT_NUMBER.toString().trim().length <= 0 ? 'form-control is-invalid ' : 'form-control'}
                                                                    id="basicpill-firstname-input"
                                                                    placeholder="Enter Sort Number"
                                                                    maxlength={10}
                                                                    allowLeadingZeros={false}
                                                                    allowNegative={false}
                                                                    thousandSeparator={false}
                                                                    name="SORT_NUMBER"
                                                                    value={data.SORT_NUMBER}
                                                                    onChange={handleChange}
                                                                />
                                                                {
                                                                    error && data.SORT_NUMBER.toString().trim().length <= 0 ? <span className='text-danger ml-1'>This Field is Required</span>
                                                                        : ""}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">

                                                        <div className="col-lg-12">
                                                            <div className="mb-3">
                                                                <label for="basicpill-address-input">Jobs Type Details</label>
                                                                <textarea id="basicpill-address-input"
                                                                    className="form-control"
                                                                    rows="2"
                                                                    placeholder="Enter Jobs Type Details"
                                                                    name="JOBTYPE_DETAILS"
                                                                    value={data.JOBTYPE_DETAILS}
                                                                    onChange={handleChange}
                                                                ></textarea>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <button type="button" onClick={onSubmit} className="btn btn-info waves-effect btn-label waves-light"><i className="bx bx-check-double label-icon"></i>{details ? "Save Changes" : "Create Jobs Type"}</button>
                                                                &nbsp;&nbsp;
                                                                <button type="button" onClick={handleReset} className="btn btn-danger waves-effect btn-label waves-light"><i className="bx bx-block label-icon "></i> Cancel</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AddJobType;